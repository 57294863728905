import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import setTableIsInCheckedMode from '../internal/mutators/setTableIsInCheckedMode';
import tableExitVirtualSelectAllMode from '../internal/mutators/tableExitVirtualSelectAllMode';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import { prefetchAdjacentRowsOnDelay } from 'owa-mail-prefetch';
import onListViewSelectionChange from '../internal/onListViewSelectionChange';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointGetterAndSetter';

// Used to cancel in-flight propagation if another selection change occurs.
let lastCall = 0;

/**
 * Called when there is a selection change in list view. This function will then trigger event to external
 * owa-mail-module-orchestrator to propagate selection change event
 * @param tableView The tableview
 * @param isUserNavigation Did user initiate selection
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export default function onSelectionChange(
    tableView: TableView,
    isUserNavigation: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource
): Promise<void> {
    const selectedItemsCount = tableView.selectedRowKeys.size;
    if (selectedItemsCount > 0 && tableView.isInVirtualSelectAllMode) {
        // We have items selected, exit virtual selection mode if we're in it
        tableExitVirtualSelectAllMode(tableView);
    } else if (selectedItemsCount == 0 && !tableView.isInVirtualSelectAllMode) {
        // Nothing selected - reset checked mode
        setTableIsInCheckedMode(tableView, false);
    }

    const myCall = ++lastCall;

    if (mailListItemSelectionSource === MailListItemSelectionSource.SearchSuggestionClick) {
        // do not yield to let browser render list view. Search suggestion
        // opens into immersive reading pane directly with reading pane off.
        // The setTimeout causes list view to render briefly before
        // immersive reading pane is shown
        onListViewSelectionChange(tableView, isUserNavigation, mailListItemSelectionSource);
        const rowKey = [...tableView.selectedRowKeys.keys()]?.[0];
        if (selectedItemsCount == 1) {
            prefetchAdjacentRowsOnDelay(rowKey, tableView);
        }
        return Promise.resolve();
    }

    const promiseToReturn = new Promise<void>((resolve, reject) => {
        // Propagate the listview selection on delay so we allow the browser to render
        // listview selection change separately. setTimeout releases the javascript thread
        const selectMailItemDatapoint = getSelectMailItemDatapoint();
        selectMailItemDatapoint && selectMailItemDatapoint?.addCheckpoint('PRE_ONVSC');
        if (isFeatureEnabled('rp-skip-selection-delay')) {
            performSelectionChangeActions(
                tableView,
                isUserNavigation,
                mailListItemSelectionSource,
                resolve,
                reject
            );
        } else {
            Promise.resolve().then(() => {
                if (myCall === lastCall) {
                    performSelectionChangeActions(
                        tableView,
                        isUserNavigation,
                        mailListItemSelectionSource,
                        resolve,
                        reject
                    );
                }
            });
        }
    });

    return promiseToReturn;
}

function performSelectionChangeActions(
    tableView: TableView,
    isUserNavigation: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource,
    resolve: () => void,
    reject: (reason?: any) => void
) {
    onListViewSelectionChange(tableView, isUserNavigation, mailListItemSelectionSource)
        .then(() => {
            resolve();
        })
        .catch((error: Error) => {
            reject(error);
        });
    const rowKey = [...tableView.selectedRowKeys.keys()]?.[0];
    if (tableView.selectedRowKeys.size == 1) {
        prefetchAdjacentRowsOnDelay(rowKey, tableView);
    }
}
