import type OwsOptionsStore from '../store/schema/OwsOptionsStore';
import getStore from '../store/store';

export function getServiceOptionStoreData(): {
    [key: string]: OwsOptionsStore;
} {
    const storeData: {
        [key: string]: OwsOptionsStore;
    } = {};

    getStore().accounts.forEach((value, key) => {
        storeData[key] = value;
    });

    return storeData;
}
