import mapItemTypeToItemRelation from '../../store-factory/mapItemTypeToItemRelation';
import { shouldShowAttachmentPreviewsForItem } from 'owa-mail-attachment-previews';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import {
    type TableQueryType,
    getStore as getListViewStore,
    type SortColumn,
} from 'owa-mail-list-store';
import getTableToRowRelationKey from 'owa-mail-list-store/lib/utils/getTableToRowRelationKey';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import getShouldShowRSVPForItemAndPrepareItem from 'owa-listview-rsvp/lib/utils/getShouldShowRSVPForItemAndPrepareItem';
import type { ItemRow } from 'owa-graph-schema';
import type Message from 'owa-service/lib/contract/Message';
import { getUserConfiguration } from 'owa-session-store';
import { errorThatWillCauseAlert } from 'owa-trace';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { updateCategorySortCategoryForRowKey } from 'owa-mail-category-sort';

function partialUpdateItem(itemToUpdate: ClientItem, item: ClientItem, tableView: TableView) {
    /**
     * Skip property updates in this block if table is a search table because
     * these items are fetched from 3S APIs and do not include these properties
     * in the response.
     */
    if (tableView.tableQuery.type !== 1) {
        itemToUpdate.ReceivedOrRenewTime = item.ReceivedOrRenewTime;
        itemToUpdate.shouldShowRSVP = item.shouldShowRSVP;
    }

    // Common partial update
    itemToUpdate.Subject = item.Subject;
    itemToUpdate.Importance = item.Importance;
    itemToUpdate.Sensitivity = item.Sensitivity;
    itemToUpdate.HasAttachments = item.HasAttachments;
    itemToUpdate.ItemClass = item.ItemClass;
    itemToUpdate.Flag = item.Flag;
    itemToUpdate.Categories = item.Categories;

    // Mail folder specific updates
    itemToUpdate.ParentFolderId = item.ParentFolderId;
    itemToUpdate.ParentFolderDisplayName = item.ParentFolderDisplayName;
    itemToUpdate.ConversationId = item.ConversationId;
    itemToUpdate.DateTimeReceived = item.DateTimeReceived;
    itemToUpdate.LastModifiedTime = item.LastModifiedTime;
    itemToUpdate.IsDraft = item.IsDraft;
    itemToUpdate.Size = item.Size;
    itemToUpdate.Preview = item.Preview;
    itemToUpdate.DisplayTo = item.DisplayTo;
    itemToUpdate.IconIndex = item.IconIndex;
    itemToUpdate.shouldShowAttachmentPreviews = item.shouldShowAttachmentPreviews;

    // Only update return time if item provides value
    if (!!item.ReturnTime) {
        itemToUpdate.ReturnTime = item.ReturnTime;
    }

    if (!!item.HasProcessedSharepointLink) {
        itemToUpdate.HasProcessedSharepointLink = item.HasProcessedSharepointLink;
    }

    // Message specific updates
    if (
        !itemToUpdate?.ItemClass || // server treats undefined or null ItemClass as a Message type by default
        itemToUpdate.ItemClass.indexOf('IPM.Schedule.Meeting') !== -1 || // Meeting request messages
        itemToUpdate.ItemClass.indexOf('IPM.Note') !== -1 // Normal messages
    ) {
        const messageToUpdate = itemToUpdate as Message;
        const message = item as Message;

        messageToUpdate.IsRead = message.IsRead;

        if (tableView.tableQuery.type !== 1) {
            // Skip updating the following properties in search as they're not returned
            messageToUpdate.IsReadReceiptRequested = message.IsReadReceiptRequested;
            messageToUpdate.IsDeliveryReceiptRequested = message.IsDeliveryReceiptRequested;
        }

        if (message.CopilotInboxScore && message.CopilotInboxScore > -1) {
            messageToUpdate.CopilotInboxScore = message.CopilotInboxScore;
        }

        if (!!message.CopilotInboxHeadline) {
            messageToUpdate.CopilotInboxHeadline = message.CopilotInboxHeadline;
        }

        if (!!message.CopilotInboxScoreReason) {
            itemToUpdate.CopilotInboxReason = message.CopilotInboxScoreReason;
        }
    }
}

/**
 * Adds or updates the item in the mail store
 * This is called whenever a data is fetched from the server and is being stored. This is also called when
 * a rowAdd or rowModified notification occurs
 * @param item to add or update
 * @param tableView where to add or update the item
 * @param state for unit testing
 */
export default action('addOrUpdateItemData')(function addOrUpdateItemData(
    serviceItem: ItemRow,
    tableView: TableView
) {
    const itemId = serviceItem.ItemId.Id;
    const clientItemToMerge = <ClientItem>{
        ...serviceItem,
        MailboxInfo: getMailboxInfo(tableView),
        shouldShowAttachmentPreviews:
            getUserConfiguration().UserOptions?.ShowInlinePreviews &&
            shouldShowAttachmentPreviewsForItem(serviceItem, tableView.tableQuery),
        shouldShowRSVP: getShouldShowRSVPForItemAndPrepareItem(serviceItem, tableView),
        CopilotInboxReason: (serviceItem as Message)?.CopilotInboxScoreReason ?? '',
    };
    if (mailStore.items.has(itemId)) {
        // If the item is in the cache, partial update it with the new properties
        const itemInCache = mailStore.items.get(itemId) as ClientItem;
        partialUpdateItem(itemInCache, clientItemToMerge, tableView);
    } else {
        // Otherwise add it to the item cache
        mailStore.items.set(itemId, clientItemToMerge);
        // There is a server-side issue where FindItem is not sending conversationId for certain items
        // that are part of long conversations that have more than 100 messages.
        if (!clientItemToMerge.ConversationId) {
            errorThatWillCauseAlert('addOrUpdateItemData: item.ConversationId is null.');
        }
    }

    const tableItemRelationKey = getTableToRowRelationKey(
        serviceItem.InstanceKey ?? '',
        tableView.id
    );
    // Create or update the relation object
    const newRelation = mapItemTypeToItemRelation(serviceItem, tableView);

    const tableViewItemRelations = getListViewStore().tableViewItemRelations;
    const oldRelation = tableViewItemRelations.get(tableItemRelationKey);
    if (oldRelation) {
        // if we already have the relation then just update the values so we don't have to
        // make the whole object observable
        Object.assign(oldRelation, newRelation);
    } else {
        getListViewStore().tableViewItemRelations.set(tableItemRelationKey, newRelation);
    }

    // Add category data specific to the item (for Category sort table)
    if (tableView?.tableQuery.type === 0) {
        const mailTableQuery = tableView?.tableQuery as MailFolderTableQuery;

        if (mailTableQuery.sortBy?.sortColumn === 14 && serviceItem.InstanceKey) {
            updateCategorySortCategoryForRowKey(
                getMailboxInfo(tableView),
                serviceItem.InstanceKey,
                serviceItem.Categories?.[0] ?? ''
            );
        }
    }
});
