import { isFeatureEnabled } from 'owa-feature-flags';

export function getPartnerParam(): {
    [paramName: string]: string;
} {
    if (isFeatureEnabled('sea-includePartnerIn3SRequest')) {
        return { ['partner']: 'exchangeshared' };
    }

    return {};
}
