import type { ResolverContext, ListCitiesResponse, SchedulingCity } from 'owa-graph-schema';
import { makeGetRequest } from 'owa-ows-gateway';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
interface CitiesResponse {
    value: string[];
}

/**
 * query web resolver responsible for cities
 */
export const citiesQueryWeb = async (
    _parent: any,
    _args: {},
    context: ResolverContext,
    _info: any
): Promise<ListCitiesResponse> => {
    const mailboxInfo: MailboxInfo =
        context.requestOptions?.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const endpoint: string = 'Locations/odata/api/v1.0/Localities/Places.GetCities';

    return makeGetRequest(
        endpoint,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        'cities', // actionName
        mailboxInfo
    ).then(response => {
        if (!isSuccessStatusCode(response.status)) {
            return Promise.resolve({ cities: [] });
        }
        return response.json().then((citiesResponse: CitiesResponse) => {
            const schedulingCities: SchedulingCity[] = citiesResponse?.value.map(
                (cityName: string) => {
                    return { id: cityName, name: cityName };
                }
            );

            return { cities: schedulingCities ?? [] };
        });
    });
};
