// extracted by mini-css-extract-plugin
export var allFolderIconsRootText = "bDaK0";
export var chevronAnimation = "ggxax";
export var chevronAnimationCollapse = "PpEZ_";
export var chevronAnimationCollapseFull = "PaAhL";
export var chevronIconButton = "JeKbj";
export var chevronIconButtonCompact = "RANg4";
export var chevronIconButtonFull = "NID7Q";
export var chevronIconButtonMedium = "A01Ao";
export var chevronIconCompact = "M8c4E";
export var chevronIconFull = "YGWfc";
export var chevronIconMedium = "UABOG";
export var chevronPadding = "Kslv4";
export var compact = "i_YGv";
export var customActionNode = "dE8Fb";
export var densityFontSize = "cxHVn";
export var densityNodeHeight = "Dnv7K";
export var densityRootNodeHeight = "zAzli";
export var disabledCursor = "mzroW";
export var displayName = "FyKl6";
export var ellipsesHovered = "lTeD2";
export var ellipsesIcon = "RQerG";
export var ellipsesOnHover = "yDgz8";
export var ellipsesOnHoverTreatmentPresent = "vACln";
export var ellipsesPadding = "dupde";
export var focusStyling = "g9Rua";
export var folderIcons = "HwrGp";
export var folderWithChevron = "Br03z";
export var full = "n4G7O";
export var hoverPadding = "LSXNh";
export var icon = "_yjh5";
export var iconsNext = "TSTwK";
export var iconsNextFull = "t3PMt";
export var isBeingDragged = "fBX6B";
export var isDisabled = "EsEdh";
export var leftNavAllFoldersPadding = "NaUra";
export var ltrStyles = "EfpoE";
export var medium = "zC7De";
export var overridenFont = "eCOrJ";
export var regularNode = "HvmPh";
export var regularNodeChevronIcon = "xp0_o";
export var rightCharm = "fWQAv";
export var rightCharmHover = "STXuw";
export var rightCharmHoverTreatmentPresent = "_9F_n";
export var rootHover = "dHvAf";
export var rootIcon = "uNKDy";
export var rootNode = "wKtqB";
export var rootNodeFont = "CSKUF";
export var roundedCorners = "JBQ8a";
export var rtlStyles = "NJ580";
export var selected = "g5Rb3";
export var showAsHoverOnDroppedOver = "ib85c";
export var showBorderOnDroppedOver = "CP_LS";
export var withContextMenuOpen = "x7CO3";