import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SurfaceActionsOption" */ './lazyIndex')
);

export const lazyInitializeHoverSurfaceAction = new LazyAction(
    lazyModule,
    m => m.initializeHoverSurfaceAction
);
export const lazySaveHoverSurfaceActionService = new LazyAction(
    lazyModule,
    m => m.saveHoverSurfaceActionService
);

export { default as getStore } from './store/store';
export {
    default as getHoverSurfaceAction,
    getHoverActionKeysFromMailTriageActions,
} from './utils/hoverSurfaceActionHelper';
export { default as SurfaceActionsOptionState } from './store/schema/SurfaceActionsOptionState';
