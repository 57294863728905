import type { MailboxInfo } from 'owa-client-types';
import { areFeatureFlagsInitialized } from './initializeFeatureFlags';
import getFeatureFlagsIndexerForMailboxInfo from '../utils/getFeatureFlagsIndexerForMailboxInfo';

const callbackMap: Map<string, (() => unknown)[]> = new Map();

/**
 * Run the given callback when feature flags are initialized for the given mailbox. Runs the
 * callback immediately if feature flags are already initialized.
 *
 * @param mailboxInfo
 * @param callback
 */
export function registerForFeatureFlagsInitialized(
    mailboxInfo: MailboxInfo,
    callback: () => unknown
) {
    if (areFeatureFlagsInitialized(mailboxInfo)) {
        callback();
    } else {
        const indexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
        let callbacks = callbackMap.get(indexer);
        if (!callbacks) {
            callbacks = [];
            callbackMap.set(indexer, callbacks);
        }
        callbacks.push(callback);
    }
}

export function onFeatureFlagsInitialized(mailboxInfo: MailboxInfo | undefined) {
    const indexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    const callbacks = callbackMap.get(indexer);
    if (callbacks) {
        callbacks.forEach(callback => callback());
        callbackMap.delete(indexer);
    }
}
