import type { MailboxInfo } from 'owa-client-types';
import {
    getIndexerValueForMailboxInfo,
    isSameCoprincipalAccountMailboxAndIndexer,
} from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import favoritesStore from '../store/store';
import removeFavoriteDataFromStore from './helpers/removeFavoriteDataFromStore';

export default mutatorAction(
    'removeFavoritesForAccount',
    function removeFavoritesForAccount(mailboxInfo: MailboxInfo) {
        const mailboxInfoKey = getIndexerValueForMailboxInfo(mailboxInfo);

        favoritesStore.outlookFavorites.forEach(favoriteData => {
            if (
                isSameCoprincipalAccountMailboxAndIndexer(favoriteData.mailboxInfo, mailboxInfoKey)
            ) {
                removeFavoriteDataFromStore(favoriteData);
            }
        });

        favoritesStore.favoriteTreeData.delete(mailboxInfoKey);
    }
);
