import React from 'react';
import { useFocusVisible } from '@fluentui/react-tabster';
import { useMergedRefs } from '@fluentui/react-utilities';
import { useFocusRects } from '@fluentui/utilities';
import classNames from 'owa-classnames';
import { getDensity } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import { observer } from 'owa-mobx-react';
import { isShySuiteHeaderMode } from 'owa-suite-header-store';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import type { AppPaneUnderlayViewState } from '../store/store';
import AppPaneUnderlay from './AppPaneUnderlay';
import { OwaThemeProvider } from 'owa-react-dom';

import {
    appContainer,
    monarchAwareBackground,
    mainContainer,
    main,
    bleedThrough,
} from './App.scss';

export interface AppProps {
    renderHeader?: () => React.ReactElement<{}> | undefined;
    children?: React.ReactNode;
    renderSkipLinkControl?: () => React.ReactElement<{}> | undefined;
    underlay?: AppPaneUnderlayViewState;
}

export default observer(
    React.forwardRef(function App(
        { renderHeader, children, renderSkipLinkControl, underlay }: AppProps,
        ref: React.Ref<HTMLElement>
    ) {
        const rootRef = React.useRef<HTMLDivElement>(null);
        // If the root element's window is different than the global window, pass in
        // the root element ref to the function so we initialize the focus rects in popout windows.
        const isRootElementInPopoutWindow = window != rootRef.current?.ownerDocument.defaultView;
        useFocusRects(isRootElementInPopoutWindow ? rootRef : undefined);

        const useMonarchStyles = isFeatureEnabled('mon-transparency-usingSystemBackdrop');
        const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

        // Initializing the polyfill for :focus-visible
        const targetDocument = isRootElementInPopoutWindow
            ? rootRef.current?.ownerDocument
            : window.document;
        const focusVisibleRef = useFocusVisible<HTMLDivElement>({ targetDocument });

        return (
            <OwaThemeProvider
                id={'appContainer'}
                ref={ref}
                className={classNames(
                    appContainer,
                    useMonarchStyles && monarchAwareBackground,
                    isBleedThroughEnabled && bleedThrough
                )}
                partialTheme={getDensity()}
            >
                {renderSkipLinkControl?.()}
                {renderHeader?.()}
                <div className={classNames(mainContainer, 'customScrollBar')}>
                    <div
                        // `mainApp` id is used to handle dynamic layout scrollbars behavior and appearance
                        id="mainApp"
                        className={classNames(main, {
                            isShyHeaderMode: isShySuiteHeaderMode(),
                        })}
                        ref={useMergedRefs(rootRef, focusVisibleRef)}
                    >
                        {children}
                        <AppPaneUnderlay underlay={underlay} />
                    </div>
                </div>
            </OwaThemeProvider>
        );
    }),
    'App'
);
