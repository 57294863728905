import { action } from 'satcheljs';
import type { AppHostEntryPoint, AppHostError } from '../utils/telemetry';

type InvalidNavigationAction = {
    appId: string;
    callerFn: AppHostEntryPoint;
    error: AppHostError;
    entityId?: string;
    subEntityId?: string;
    appManifest?: {
        name: string;
        staticTabs: object[];
    };
};

const onInvalidAppNavigation = action(
    'onInvalidAppNavigation',
    ({ appId, entityId, subEntityId, callerFn, appManifest, error }: InvalidNavigationAction) => ({
        appId,
        entityId,
        subEntityId,
        callerFn,
        appManifest,
        error,
    })
);

export default onInvalidAppNavigation;
