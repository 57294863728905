import getMailboxInfoForArchive from './getMailboxInfoForArchive';
import isResultFromArchiveFanout from './isResultFromArchiveFanout';
import type { ClientItemId } from 'owa-client-ids';
import type { ItemRow } from 'owa-graph-schema';
import type { TableView } from 'owa-mail-list-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import getMailboxInfoForAuxArchive from 'owa-mailbox-info/lib/getMailboxInfoForShared';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';

export default function getItemClientIdForSearchResult(
    item: ItemRow,
    tableView: TableView
): ClientItemId {
    if (item.MailboxGuids && isResultFromArchiveFanout(item.MailboxGuids)) {
        return {
            Id: item.ItemId.Id,
            mailboxInfo: isMailboxPackageFlightEnabled()
                ? getMailboxInfoForAuxArchive(getModuleContextMailboxInfo(), item.MailboxGuids[0])
                : getMailboxInfoForArchive(getModuleContextMailboxInfo(), item.MailboxGuids[0]),
        };
    } else {
        return {
            Id: item.ItemId.Id,
            mailboxInfo: getMailboxInfo(tableView),
        };
    }
}
