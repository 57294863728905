import selectNodeInFolderForest from './selectNodeInFolderForest';
import onAfterSelectingNode from './helpers/onAfterSelectingNode';
import { folderForestStore, getSelectedNode } from 'owa-mail-folder-forest-store';
import type { GroupFolderForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderForestTreeType } from 'owa-graph-schema';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { lazySelectGroup } from '../lazyFunctions';
import { type GroupFolderWellKnownName, GROUPS_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import { getGroupFolderId } from 'owa-groups-shared-folders-store';
import type { MailboxInfo } from 'owa-client-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export interface SelectGroupFolderState {
    selectedNode: GroupFolderForestNode;
}

/**
 * General select group folder action
 * @param groupId the groupId
 * @param groupFolderId the group folder id
 * @param treeType the type of folder tree
 */
const selectGroupFolderInternal = action('selectGroupFolderInternal')(
    function selectGroupFolderInternal(
        groupId: string,
        groupFolderId: string,
        treeType: FolderForestTreeType,
        mailboxInfo: MailboxInfo
    ): Promise<void> {
        return selectNodeInFolderForest(<GroupFolderForestNode>{
            id: groupFolderId,
            type: 6,
            treeType,
            groupId,
            mailboxInfo,
        });
    }
);

/**
 * Select a group folder
 * @param groupId the groupId
 * @param groupFolderId the group folder Id
 * @param treeType the type of folder tree
 */
export default action('selectGroupFolder')(async function selectGroupFolder(
    groupId: string,
    groupFolderId: string,
    treeType: FolderForestTreeType,
    mailboxInfo: MailboxInfo,
    state: SelectGroupFolderState = {
        selectedNode: folderForestStore.selectedNode as GroupFolderForestNode,
    }
) {
    let folderId = groupFolderId;
    if (groupFolderId === 'inbox' || groupFolderId === 'deleteditems') {
        folderId = getGroupFolderId(groupId, groupFolderId, mailboxInfo);
    }

    const currentNode = getSelectedNode() as GroupFolderForestNode;

    if (
        currentNode.mailboxInfo &&
        currentNode.mailboxInfo.userIdentity.toLowerCase() !==
            mailboxInfo.userIdentity.toLowerCase()
    ) {
        await lazySelectGroup.importAndExecute(groupId, GROUPS_FOLDERS_TREE_TYPE, mailboxInfo);
        selectGroupFolderInternal(groupId, folderId, treeType, mailboxInfo);
    } else if (
        currentNode.type === 6 &&
        currentNode.groupId &&
        transformGroupSmtp(currentNode.groupId) === transformGroupSmtp(groupId) &&
        currentNode.id &&
        currentNode.id.toLowerCase() === folderId.toLowerCase()
    ) {
        // Do not perform the rest of selecting group folder logic when
        // user navigates to the node with the same id
        state.selectedNode.treeType = treeType;
        state.selectedNode.groupId = groupId;
        state.selectedNode.id = folderId;
    } else if (
        currentNode.type === 6 &&
        currentNode.groupId &&
        transformGroupSmtp(currentNode.groupId) === transformGroupSmtp(groupId) &&
        currentNode.id &&
        currentNode.id.toLowerCase() !== folderId.toLowerCase()
    ) {
        // Same group different folder
        selectGroupFolderInternal(groupId, folderId, treeType, mailboxInfo);
    } else if (
        // Different group different folder
        currentNode.type === 6 &&
        currentNode.groupId &&
        transformGroupSmtp(currentNode.groupId) !== transformGroupSmtp(groupId)
    ) {
        // Switch group
        await lazySelectGroup.importAndExecute(groupId, GROUPS_FOLDERS_TREE_TYPE, mailboxInfo);
        selectGroupFolderInternal(groupId, folderId, treeType, mailboxInfo);
    } else {
        await lazySelectGroup.importAndExecute(groupId, GROUPS_FOLDERS_TREE_TYPE, mailboxInfo);
        selectGroupFolderInternal(groupId, folderId, treeType, mailboxInfo);
    }

    onAfterSelectingNode();
});
