import { observer } from 'owa-mobx-react';
import { navigationPaneLabel } from './MailModule.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import {
    getDataMinMaxWidthsForLeftNav,
    shouldShowFolderPaneAsOverlay,
    shouldShowFolderPane,
    getLeftPaneStyles,
} from 'owa-mail-layout';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { MailLeftPaneAsOverlay } from '../index'; // lazy import overlay left pane component
import FolderPaneContainer from './FolderPaneContainer';

import { leftPaneContainer, leftPanelSecondRow, leftPaneContainerHidden } from './MailModule.scss';

import classnames from 'owa-classnames';
import { useLeftPaneContentStyle } from 'owa-left-pane-layout/lib/utils/useLeftPaneContentStyle';

export default observer(function MailLeftPane() {
    const showFolderPane = shouldShowFolderPane();
    const showFolderPaneAsOverlay = shouldShowFolderPaneAsOverlay();
    const leftPaneClasses = classnames(
        leftPaneContainer,
        getLeftPaneStyles(),
        !showFolderPane ? leftPaneContainerHidden : ''
    );
    const { dataMin, dataMax } = getDataMinMaxWidthsForLeftNav();

    const memoizedLeftPaneStyle = useLeftPaneContentStyle(showFolderPane);

    return (
        <div
            tabIndex={-1}
            role={'region'}
            aria-label={loc(navigationPaneLabel)}
            data-app-section="NavigationPane"
            className={leftPaneClasses}
            data-min-width={dataMin}
            data-max-width={dataMax}
        >
            <div className={classnames(leftPanelSecondRow, memoizedLeftPaneStyle)}>
                {renderFolderPane(showFolderPaneAsOverlay)}
            </div>
        </div>
    );
}, 'MailLeftPane');

/**
 * Renders the folder pane as an overlay or non-overlay
 */
function renderFolderPane(showFolderPaneAsOverlay: boolean) {
    if (!showFolderPaneAsOverlay) {
        return <FolderPaneContainer />;
    }
    return <MailLeftPaneAsOverlay />;
}
