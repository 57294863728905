import addPublicFolderToStore from '../actions/addPublicFolderToStore';
import { mutator } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(addPublicFolderToStore, actionMessage => {
    const { state, item } = actionMessage;
    const folderId = item.id;

    if (!state.folderTable.has(folderId)) {
        state.folderTable.set(folderId, item);
    }
});
