import { mutatorAction } from 'satcheljs';
import getMailFavoritesViewState from '../selectors/getMailFavoritesViewState';

export default mutatorAction(
    'showFindFavoritesPicker',
    function showFindFavoritesPicker(mailboxKey: string, shouldShow: boolean) {
        const mailFavoritesViewState = getMailFavoritesViewState(mailboxKey);
        if (mailFavoritesViewState) {
            mailFavoritesViewState.shouldShowFindFavoritesPicker = shouldShow;
        }
    }
);
