import type { OneJs_PersonCardActionsModel } from 'owa-graph-schema';
import loc from 'owa-localize';

import {
    deleteButtonDisabledInfoLabel,
    deleteButtonLabel,
    editButtonDisabledInfoLabel,
    editButtonLabel,
    unlinkButtonLabel,
} from './personSourcesLabels.locstring.json';

export const personCardActionsModel = async (): Promise<OneJs_PersonCardActionsModel> => ({
    deleteButtonDisabledInfoLabel: loc(deleteButtonDisabledInfoLabel),
    deleteButtonLabel: loc(deleteButtonLabel),
    editButtonDisabledInfoLabel: loc(editButtonDisabledInfoLabel),
    editButtonLabel: loc(editButtonLabel),
    unlinkButtonLabel: loc(unlinkButtonLabel),
});
