// This file was automatically generated from outlookwebcontext.schema.d.ts
// and contains types and methods for calling PIE

import * as PIEBridge from '@microsoft/pie.sharedbridge';

export function GetPIEScriptVersion(): number {
    return 1.2;
}

export enum CommandLineType {
    FlagOnly = 'FlagOnly',
    FileContents = 'FileContents',
}

export enum PhysicalRingEnum {
    Unknown = 'Unknown',
    WW = 'WW',
    Dogfood = 'Dogfood',
    SIP = 'SIP',
    BlackForest = 'BlackForest',
    DONMT = 'DONMT',
    MSIT = 'MSIT',
    Gallatin = 'Gallatin',
    SDFV2 = 'SDFV2',
    PDT = 'PDT',
    TDF = 'TDF',
    ITAR = 'ITAR',
}

export enum VariantEnvironmentEnum {
    Unknown = 'Unknown',
    AG08 = 'AG08',
    AG09 = 'AG09',
    BlackForest = 'BlackForest',
    DITAR = 'DITAR',
    DoD = 'DoD',
    Dogfood = 'Dogfood',
    Gallatin = 'Gallatin',
    GCCModerate = 'GCCModerate',
    GccHigh = 'GccHigh',
    GovCloud = 'GovCloud',
    ITAR = 'ITAR',
    Prod = 'Prod',
}

export enum LogicalRingEnum {
    Dogfood = 'Dogfood',
    Microsoft = 'Microsoft',
    FirstRelease = 'FirstRelease',
    WW = 'WW',
    Unknown = 'Unknown',
}

export enum UserType {
    Business = 'Business',
    Consumer = 'Consumer',
}

export enum LegacyApp {
    OutlookDesktop = 'OutlookDesktop',
    Universal = 'Universal',
}

export enum Options {
    removeAllAccounts = 1,
    importAccounts = 2,
    importSettings = 3,
}

export enum PreferredColorScheme {
    SystemDefault = 'SystemDefault',
    Light = 'Light',
    Dark = 'Dark',
}

export enum ToggleProgressState {
    AbortOnError = 'AbortOnError',
    AbortOnGoBackClicked = 'AbortOnGoBackClicked',
    ToggledIn = 'ToggledIn',
    ToggledOut = 'ToggledOut',
}

export enum MakeDefaultAppAssociations {
    MailAndCalendarOnly = 'MailAndCalendarOnly',
    AllAssociations = 'AllAssociations',
}

export interface UserContext {
    physicalRing: PhysicalRingEnum;
    variantEnv: VariantEnvironmentEnum;
    logicalRing: LogicalRingEnum;
    userType: UserType;
    isCd: boolean;
    userLocale?: string;
    windowBackgroundColor: number;
    preferredColorScheme?: PreferredColorScheme;
    owaVersion?: string;
    clientId?: string;
}

export interface NewOutlookToggleState {
    isToggleOn?: boolean;
    legacyApp?: LegacyApp;
}

export interface NativeContextProperty {
    name: string;
    value: string;
}

export interface NativeContext {
    userUpn?: string;
    errorContext?: NativeContextProperty[];
    sessionID?: string;
    deviceID?: string;
}

export interface CommandLineArgument {
    flagName: string;
    type: CommandLineType;
    comment: string;
}

export interface OsSettings {
    userLocale: string;
    timeZoneKeyName: string;
}

export interface PinningSettings {
    isPinnedToTaskbar: boolean;
    isPinningApiAvailable: boolean;
    userDeclinedToPin?: boolean;
    userDeclinedToPinTimestampUtc?: string;
}

export interface ImportProperties {
    foundImport: boolean;
    legacyApp?: LegacyApp;
    handoffSize?: number;
    importContentsLength?: number;
    importContents?: string;
}

export interface SetNewOutlookToggleStateParams {
    state: NewOutlookToggleState;
}

export interface LaunchLegacyAppParams {
    legacyApp?: LegacyApp;
}

export interface LogToDiskParams {
    content: string;
}

export interface LaunchVersionParams {
    version: string;
    revert?: boolean;
}

export interface SearchBuildsForRegressionParams {
    startDate: string;
    endDate: string;
    revert?: boolean;
}

export interface UserContextParams {
    context: UserContext;
}

export interface GetOptionSettingsParams {
    option: Options;
}

export interface SetOptionCompleteParams {
    option: Options;
}

export interface MakeOutlookDefaultAppForSupportedAssociationsParams {
    makeDefaultType?: MakeDefaultAppAssociations;
}

export interface SetToggleProgressStateParams {
    state: ToggleProgressState;
    toLegacyApp?: LegacyApp;
}

export class JsonPostProcessing {
    private static ArrayOfNativeContextPropertyValue: PIEBridge.PostProcessing;
    public static ArrayOfNativeContextPropertyProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.ArrayOfNativeContextPropertyValue) {
            JsonPostProcessing.ArrayOfNativeContextPropertyValue = {
                process: function (value: any) {
                    const postProcessor = JsonPostProcessing.NativeContextPropertyProcessor();
                    for (let index = 0; index < value.length; index++) {
                        if (postProcessor.process) {
                            postProcessor.process(value[index]);
                        } else if (postProcessor.convert) {
                            value[index] = postProcessor.convert(value[index]);
                        }
                    }
                },
            };
        }
        return JsonPostProcessing.ArrayOfNativeContextPropertyValue;
    }

    private static ArrayOfLegacyAppValue: PIEBridge.PostProcessing;
    public static ArrayOfLegacyAppProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.ArrayOfLegacyAppValue) {
            JsonPostProcessing.ArrayOfLegacyAppValue = {
                process: function (value: any) {
                    const postProcessor = JsonPostProcessing.LegacyAppProcessor();
                    for (let index = 0; index < value.length; index++) {
                        if (postProcessor.process) {
                            postProcessor.process(value[index]);
                        } else if (postProcessor.convert) {
                            value[index] = postProcessor.convert(value[index]);
                        }
                    }
                },
            };
        }
        return JsonPostProcessing.ArrayOfLegacyAppValue;
    }

    private static ArrayOfOptionsValue: PIEBridge.PostProcessing;
    public static ArrayOfOptionsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.ArrayOfOptionsValue) {
            JsonPostProcessing.ArrayOfOptionsValue = {
                process: function (value: any) {
                    const postProcessor = JsonPostProcessing.OptionsProcessor();
                    for (let index = 0; index < value.length; index++) {
                        if (postProcessor.process) {
                            postProcessor.process(value[index]);
                        } else if (postProcessor.convert) {
                            value[index] = postProcessor.convert(value[index]);
                        }
                    }
                },
            };
        }
        return JsonPostProcessing.ArrayOfOptionsValue;
    }

    private static CommandLineTypeValue: PIEBridge.PostProcessing;
    public static CommandLineTypeProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.CommandLineTypeValue) {
            JsonPostProcessing.CommandLineTypeValue = {
                convert: function (value: string): CommandLineType {
                    return value as CommandLineType;
                },
            };
        }
        return JsonPostProcessing.CommandLineTypeValue;
    }

    private static PhysicalRingEnumValue: PIEBridge.PostProcessing;
    public static PhysicalRingEnumProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.PhysicalRingEnumValue) {
            JsonPostProcessing.PhysicalRingEnumValue = {
                convert: function (value: string): PhysicalRingEnum {
                    return value as PhysicalRingEnum;
                },
            };
        }
        return JsonPostProcessing.PhysicalRingEnumValue;
    }

    private static VariantEnvironmentEnumValue: PIEBridge.PostProcessing;
    public static VariantEnvironmentEnumProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.VariantEnvironmentEnumValue) {
            JsonPostProcessing.VariantEnvironmentEnumValue = {
                convert: function (value: string): VariantEnvironmentEnum {
                    return value as VariantEnvironmentEnum;
                },
            };
        }
        return JsonPostProcessing.VariantEnvironmentEnumValue;
    }

    private static LogicalRingEnumValue: PIEBridge.PostProcessing;
    public static LogicalRingEnumProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.LogicalRingEnumValue) {
            JsonPostProcessing.LogicalRingEnumValue = {
                convert: function (value: string): LogicalRingEnum {
                    return value as LogicalRingEnum;
                },
            };
        }
        return JsonPostProcessing.LogicalRingEnumValue;
    }

    private static UserTypeValue: PIEBridge.PostProcessing;
    public static UserTypeProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.UserTypeValue) {
            JsonPostProcessing.UserTypeValue = {
                convert: function (value: string): UserType {
                    return value as UserType;
                },
            };
        }
        return JsonPostProcessing.UserTypeValue;
    }

    private static LegacyAppValue: PIEBridge.PostProcessing;
    public static LegacyAppProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.LegacyAppValue) {
            JsonPostProcessing.LegacyAppValue = {
                convert: function (value: string): LegacyApp {
                    return value as LegacyApp;
                },
            };
        }
        return JsonPostProcessing.LegacyAppValue;
    }

    private static OptionsValue: PIEBridge.PostProcessing;
    public static OptionsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.OptionsValue) {
            JsonPostProcessing.OptionsValue = {
                convert: function (value: number): Options {
                    return value as Options;
                },
            };
        }
        return JsonPostProcessing.OptionsValue;
    }

    private static PreferredColorSchemeValue: PIEBridge.PostProcessing;
    public static PreferredColorSchemeProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.PreferredColorSchemeValue) {
            JsonPostProcessing.PreferredColorSchemeValue = {
                convert: function (value: string): PreferredColorScheme {
                    return value as PreferredColorScheme;
                },
            };
        }
        return JsonPostProcessing.PreferredColorSchemeValue;
    }

    private static ToggleProgressStateValue: PIEBridge.PostProcessing;
    public static ToggleProgressStateProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.ToggleProgressStateValue) {
            JsonPostProcessing.ToggleProgressStateValue = {
                convert: function (value: string): ToggleProgressState {
                    return value as ToggleProgressState;
                },
            };
        }
        return JsonPostProcessing.ToggleProgressStateValue;
    }

    private static MakeDefaultAppAssociationsValue: PIEBridge.PostProcessing;
    public static MakeDefaultAppAssociationsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.MakeDefaultAppAssociationsValue) {
            JsonPostProcessing.MakeDefaultAppAssociationsValue = {
                convert: function (value: string): MakeDefaultAppAssociations {
                    return value as MakeDefaultAppAssociations;
                },
            };
        }
        return JsonPostProcessing.MakeDefaultAppAssociationsValue;
    }

    private static UserContextValue: PIEBridge.PostProcessing;
    public static UserContextProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.UserContextValue) {
            JsonPostProcessing.UserContextValue = {
                process: function (value: any) {
                    const processorFor_physicalRing =
                        JsonPostProcessing.PhysicalRingEnumProcessor();
                    if (processorFor_physicalRing.process) {
                        processorFor_physicalRing.process(value.physicalRing);
                    } else if (processorFor_physicalRing.convert) {
                        value.physicalRing = processorFor_physicalRing.convert(value.physicalRing);
                    }

                    const processorFor_variantEnv =
                        JsonPostProcessing.VariantEnvironmentEnumProcessor();
                    if (processorFor_variantEnv.process) {
                        processorFor_variantEnv.process(value.variantEnv);
                    } else if (processorFor_variantEnv.convert) {
                        value.variantEnv = processorFor_variantEnv.convert(value.variantEnv);
                    }

                    const processorFor_logicalRing = JsonPostProcessing.LogicalRingEnumProcessor();
                    if (processorFor_logicalRing.process) {
                        processorFor_logicalRing.process(value.logicalRing);
                    } else if (processorFor_logicalRing.convert) {
                        value.logicalRing = processorFor_logicalRing.convert(value.logicalRing);
                    }

                    const processorFor_userType = JsonPostProcessing.UserTypeProcessor();
                    if (processorFor_userType.process) {
                        processorFor_userType.process(value.userType);
                    } else if (processorFor_userType.convert) {
                        value.userType = processorFor_userType.convert(value.userType);
                    }

                    const processorFor_preferredColorScheme =
                        value.preferredColorScheme === undefined
                            ? {}
                            : JsonPostProcessing.PreferredColorSchemeProcessor();
                    if (processorFor_preferredColorScheme.process) {
                        processorFor_preferredColorScheme.process(value.preferredColorScheme);
                    } else if (processorFor_preferredColorScheme.convert) {
                        value.preferredColorScheme = processorFor_preferredColorScheme.convert(
                            value.preferredColorScheme
                        );
                    }
                },
            };
        }
        return JsonPostProcessing.UserContextValue;
    }

    private static NewOutlookToggleStateValue: PIEBridge.PostProcessing;
    public static NewOutlookToggleStateProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.NewOutlookToggleStateValue) {
            JsonPostProcessing.NewOutlookToggleStateValue = {
                process: function (value: any) {
                    const processorFor_legacyApp =
                        value.legacyApp === undefined
                            ? {}
                            : JsonPostProcessing.LegacyAppProcessor();
                    if (processorFor_legacyApp.process) {
                        processorFor_legacyApp.process(value.legacyApp);
                    } else if (processorFor_legacyApp.convert) {
                        value.legacyApp = processorFor_legacyApp.convert(value.legacyApp);
                    }
                },
            };
        }
        return JsonPostProcessing.NewOutlookToggleStateValue;
    }

    public static NativeContextPropertyProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    private static NativeContextValue: PIEBridge.PostProcessing;
    public static NativeContextProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.NativeContextValue) {
            JsonPostProcessing.NativeContextValue = {
                process: function (value: any) {
                    const processorFor_errorContext =
                        value.errorContext === undefined
                            ? {}
                            : JsonPostProcessing.ArrayOfNativeContextPropertyProcessor();
                    if (processorFor_errorContext.process) {
                        processorFor_errorContext.process(value.errorContext);
                    } else if (processorFor_errorContext.convert) {
                        value.errorContext = processorFor_errorContext.convert(value.errorContext);
                    }
                },
            };
        }
        return JsonPostProcessing.NativeContextValue;
    }

    private static CommandLineArgumentValue: PIEBridge.PostProcessing;
    public static CommandLineArgumentProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.CommandLineArgumentValue) {
            JsonPostProcessing.CommandLineArgumentValue = {
                process: function (value: any) {
                    const processorFor_type = JsonPostProcessing.CommandLineTypeProcessor();
                    if (processorFor_type.process) {
                        processorFor_type.process(value.type);
                    } else if (processorFor_type.convert) {
                        value.type = processorFor_type.convert(value.type);
                    }
                },
            };
        }
        return JsonPostProcessing.CommandLineArgumentValue;
    }

    public static OsSettingsProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    public static PinningSettingsProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    private static ImportPropertiesValue: PIEBridge.PostProcessing;
    public static ImportPropertiesProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.ImportPropertiesValue) {
            JsonPostProcessing.ImportPropertiesValue = {
                process: function (value: any) {
                    const processorFor_legacyApp =
                        value.legacyApp === undefined
                            ? {}
                            : JsonPostProcessing.LegacyAppProcessor();
                    if (processorFor_legacyApp.process) {
                        processorFor_legacyApp.process(value.legacyApp);
                    } else if (processorFor_legacyApp.convert) {
                        value.legacyApp = processorFor_legacyApp.convert(value.legacyApp);
                    }
                },
            };
        }
        return JsonPostProcessing.ImportPropertiesValue;
    }

    private static SetNewOutlookToggleStateParamsValue: PIEBridge.PostProcessing;
    public static SetNewOutlookToggleStateParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.SetNewOutlookToggleStateParamsValue) {
            JsonPostProcessing.SetNewOutlookToggleStateParamsValue = {
                process: function (value: any) {
                    const processorFor_state = JsonPostProcessing.NewOutlookToggleStateProcessor();
                    if (processorFor_state.process) {
                        processorFor_state.process(value.state);
                    } else if (processorFor_state.convert) {
                        value.state = processorFor_state.convert(value.state);
                    }
                },
            };
        }
        return JsonPostProcessing.SetNewOutlookToggleStateParamsValue;
    }

    private static LaunchLegacyAppParamsValue: PIEBridge.PostProcessing;
    public static LaunchLegacyAppParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.LaunchLegacyAppParamsValue) {
            JsonPostProcessing.LaunchLegacyAppParamsValue = {
                process: function (value: any) {
                    const processorFor_legacyApp =
                        value.legacyApp === undefined
                            ? {}
                            : JsonPostProcessing.LegacyAppProcessor();
                    if (processorFor_legacyApp.process) {
                        processorFor_legacyApp.process(value.legacyApp);
                    } else if (processorFor_legacyApp.convert) {
                        value.legacyApp = processorFor_legacyApp.convert(value.legacyApp);
                    }
                },
            };
        }
        return JsonPostProcessing.LaunchLegacyAppParamsValue;
    }

    public static LogToDiskParamsProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    public static LaunchVersionParamsProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    public static SearchBuildsForRegressionParamsProcessor(): PIEBridge.PostProcessing {
        return {};
    }

    private static UserContextParamsValue: PIEBridge.PostProcessing;
    public static UserContextParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.UserContextParamsValue) {
            JsonPostProcessing.UserContextParamsValue = {
                process: function (value: any) {
                    const processorFor_context = JsonPostProcessing.UserContextProcessor();
                    if (processorFor_context.process) {
                        processorFor_context.process(value.context);
                    } else if (processorFor_context.convert) {
                        value.context = processorFor_context.convert(value.context);
                    }
                },
            };
        }
        return JsonPostProcessing.UserContextParamsValue;
    }

    private static GetOptionSettingsParamsValue: PIEBridge.PostProcessing;
    public static GetOptionSettingsParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.GetOptionSettingsParamsValue) {
            JsonPostProcessing.GetOptionSettingsParamsValue = {
                process: function (value: any) {
                    const processorFor_option = JsonPostProcessing.OptionsProcessor();
                    if (processorFor_option.process) {
                        processorFor_option.process(value.option);
                    } else if (processorFor_option.convert) {
                        value.option = processorFor_option.convert(value.option);
                    }
                },
            };
        }
        return JsonPostProcessing.GetOptionSettingsParamsValue;
    }

    private static SetOptionCompleteParamsValue: PIEBridge.PostProcessing;
    public static SetOptionCompleteParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.SetOptionCompleteParamsValue) {
            JsonPostProcessing.SetOptionCompleteParamsValue = {
                process: function (value: any) {
                    const processorFor_option = JsonPostProcessing.OptionsProcessor();
                    if (processorFor_option.process) {
                        processorFor_option.process(value.option);
                    } else if (processorFor_option.convert) {
                        value.option = processorFor_option.convert(value.option);
                    }
                },
            };
        }
        return JsonPostProcessing.SetOptionCompleteParamsValue;
    }

    private static MakeOutlookDefaultAppForSupportedAssociationsParamsValue: PIEBridge.PostProcessing;
    public static MakeOutlookDefaultAppForSupportedAssociationsParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.MakeOutlookDefaultAppForSupportedAssociationsParamsValue) {
            JsonPostProcessing.MakeOutlookDefaultAppForSupportedAssociationsParamsValue = {
                process: function (value: any) {
                    const processorFor_makeDefaultType =
                        value.makeDefaultType === undefined
                            ? {}
                            : JsonPostProcessing.MakeDefaultAppAssociationsProcessor();
                    if (processorFor_makeDefaultType.process) {
                        processorFor_makeDefaultType.process(value.makeDefaultType);
                    } else if (processorFor_makeDefaultType.convert) {
                        value.makeDefaultType = processorFor_makeDefaultType.convert(
                            value.makeDefaultType
                        );
                    }
                },
            };
        }
        return JsonPostProcessing.MakeOutlookDefaultAppForSupportedAssociationsParamsValue;
    }

    private static SetToggleProgressStateParamsValue: PIEBridge.PostProcessing;
    public static SetToggleProgressStateParamsProcessor(): PIEBridge.PostProcessing {
        if (!JsonPostProcessing.SetToggleProgressStateParamsValue) {
            JsonPostProcessing.SetToggleProgressStateParamsValue = {
                process: function (value: any) {
                    const processorFor_state = JsonPostProcessing.ToggleProgressStateProcessor();
                    if (processorFor_state.process) {
                        processorFor_state.process(value.state);
                    } else if (processorFor_state.convert) {
                        value.state = processorFor_state.convert(value.state);
                    }

                    const processorFor_toLegacyApp =
                        value.toLegacyApp === undefined
                            ? {}
                            : JsonPostProcessing.LegacyAppProcessor();
                    if (processorFor_toLegacyApp.process) {
                        processorFor_toLegacyApp.process(value.toLegacyApp);
                    } else if (processorFor_toLegacyApp.convert) {
                        value.toLegacyApp = processorFor_toLegacyApp.convert(value.toLegacyApp);
                    }
                },
            };
        }
        return JsonPostProcessing.SetToggleProgressStateParamsValue;
    }
}

export async function getNewOutlookToggleState(): Promise<NewOutlookToggleState> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.2);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getNewOutlookToggleState',
        {},
        JsonPostProcessing.NewOutlookToggleStateProcessor()
    );
}

export async function setNewOutlookToggleState(state: NewOutlookToggleState): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.2);
    const args = { state: state } as SetNewOutlookToggleStateParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.setNewOutlookToggleState',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function getToggledOutlooks(): Promise<LegacyApp[]> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.06);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getToggledOutlooks',
        {},
        JsonPostProcessing.ArrayOfLegacyAppProcessor()
    );
}

export async function resetBootAttempts(): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.2);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.resetBootAttempts',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function launchWin32Outlook(): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.2);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.launchWin32Outlook',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function launchLegacyApp(legacyApp?: LegacyApp): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.8);
    const args = { legacyApp: legacyApp } as LaunchLegacyAppParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.launchLegacyApp',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function isLegacyOutlookInstalled(): Promise<boolean> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.03);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.isLegacyOutlookInstalled',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function logToDisk(content: string): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.3);
    const args = { content: content } as LogToDiskParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.logToDisk',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function launchVersion(version: string, revert?: boolean): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.01);
    const args = { version: version, revert: revert } as LaunchVersionParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.launchVersion',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function searchBuildsForRegression(
    startDate: string,
    endDate: string,
    revert?: boolean
): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.01);
    const args = {
        startDate: startDate,
        endDate: endDate,
        revert: revert,
    } as SearchBuildsForRegressionParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.searchBuildsForRegression',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function userContext(context: UserContext): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.1);
    const args = { context: context } as UserContextParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.userContext',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function getNativeContext(): Promise<NativeContext> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.4);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getNativeContext',
        {},
        JsonPostProcessing.NativeContextProcessor()
    );
}

export async function getOptions(): Promise<Options[]> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.7);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getOptions',
        {},
        JsonPostProcessing.ArrayOfOptionsProcessor()
    );
}

export async function getOptionSettings(option: Options): Promise<string> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.7);
    const args = { option: option } as GetOptionSettingsParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getOptionSettings',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function setOptionComplete(option: Options): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.7);
    const args = { option: option } as SetOptionCompleteParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.setOptionComplete',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function getLegacyAppImportProperties(): Promise<ImportProperties> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.07);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getLegacyAppImportProperties',
        {},
        JsonPostProcessing.ImportPropertiesProcessor()
    );
}

export async function getOsSettings(): Promise<OsSettings> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 0.9);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getOsSettings',
        {},
        JsonPostProcessing.OsSettingsProcessor()
    );
}

export async function launchInAppStoreReview(): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.04);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.launchInAppStoreReview',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function getPinningSettings(): Promise<PinningSettings> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.05);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.getPinningSettings',
        {},
        JsonPostProcessing.PinningSettingsProcessor()
    );
}

export async function pinOutlookToTaskbar(): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.05);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.pinOutlookToTaskbar',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function isOutlookDefaultAppForSupportedAssociations(): Promise<boolean> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.05);
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.isOutlookDefaultAppForSupportedAssociations',
        {},
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function makeOutlookDefaultAppForSupportedAssociations(
    makeDefaultType?: MakeDefaultAppAssociations
): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.05);
    const args = {
        makeDefaultType: makeDefaultType,
    } as MakeOutlookDefaultAppForSupportedAssociationsParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.makeOutlookDefaultAppForSupportedAssociations',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export async function setToggleProgressState(
    state: ToggleProgressState,
    toLegacyApp?: LegacyApp
): Promise<void> {
    await PIEBridge.throwIfVersionIsNotSupported('OutlookWebContext', 1.09);
    const args = { state: state, toLegacyApp: toLegacyApp } as SetToggleProgressStateParams;
    return PIEBridge.HostBridge.invokeHost(
        'OutlookWebContext.setToggleProgressState',
        args,
        PIEBridge.NoopPostProcessingFactory()
    );
}

export function registerForProcessImportOptions(
    callback: () => Promise<void>
): PIEBridge.DisposeInvokableRegistration {
    const callbackHandler: () => Promise<void> = function () {
        return callback();
    };

    return PIEBridge.HostBridge.registerForInvokable(
        'OutlookWebContext.processImportOptions',
        callbackHandler
    );
}

export function registerForwardingForProcessImportOptions(
    forwarder: PIEBridge.PIEInvokableForwarder
): PIEBridge.DisposeInvokableRegistration {
    return PIEBridge.HostBridge.registerInvokableForwarder(
        'OutlookWebContext.processImportOptions',
        forwarder
    );
}
