import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyConversationConversationNodesWebResolver = createLazyResolver(
    'CONVERSATION_CONVERSATION_NODES_WEB',
    () =>
        import(
            /* webpackChunkName: "ConversationNodesWebResolver"*/ './conversationNodesWebResolver'
        ),
    m => m.conversationNodesWebResolver
);
