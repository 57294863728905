import type RecurrenceType from 'owa-service/lib/contract/RecurrenceType';
import type { RecurrenceType as RecurrenceTypeGql } from 'owa-graph-schema';
import { convertRecurrenceTypeToGql } from './convertRecurrenceTypeToGql';

// Sometimes, the recurrenceType passed in is graphQl type but actual it is a Ows type (no __typename)
// It is because, in type CalendarEvent, ReccurecType is defined with graphQl type but when CalendarItem
// from Ows service is implicit converted to CalendarEvent, Recurrectype is not converted.
export function convertRecurrenceTypeToGqlWithTypeNameCheck(
    recurrenceType: RecurrenceTypeGql | undefined
): RecurrenceTypeGql | undefined {
    return !recurrenceType || recurrenceType.__typename
        ? recurrenceType
        : convertRecurrenceTypeToGql(recurrenceType as RecurrenceType);
}
