// this mutator is needed for deleteConversationItemParts
import './tryRemoveFromMailStoreItemsMutator';

import loadFolderForest from 'owa-mail-folder-forest-actions/lib/actions/loadFolderForest';
import { initializeAlwaysInCacheTableViews } from 'owa-mail-triage-local-updates/lib/actions/tableCache/alwaysInCacheTableViews';
import { loadDefaultTable } from 'owa-mail-table-loading-actions/lib/actions/loadDefaultTable';
import { initializeMailLayout } from 'owa-mail-layout';
import mailStore from 'owa-mail-store/lib/store/Store';
import conversationCache from 'owa-mail-store/lib/store/conversationCache';
import deleteConversationItemParts from 'owa-mail-store-actions/lib/actions/deleteConversationItemParts';
import { initializeOptionValues } from 'owa-outlook-service-option-store';
import { getPrimeBootSettingsOptionsValues } from 'owa-session-store';
import { loadCommandBarActions } from 'owa-mail-commandbar-actions';
import loadMailRibbonConfiguration from 'owa-mail-ribbon-store/lib/util/loadMailRibbonConfiguration';
import { initializeCommandingViewMode } from 'owa-command-ribbon-store';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { InMemoryCache } from '@apollo/client';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Legacy usage of apolloClient
 *	> 'getApolloClient' import from 'owa-apollo' is restricted. Use exports of query, mutate, subscribe, etc. If you're are wrapping a component, use wrapInApolloProvider */
import { getApolloClient } from 'owa-apollo';
import { folderTypePolicies } from 'owa-folder-typepolicies/lib/folderTypePolicies';
import { bootstrapFolderHierarchyCache } from 'owa-folders-hierarchy-bootstrap';
import { initializeSuiteHeaderPinnedPane } from 'owa-suite-header-auto-open';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { initializeRestoreMailListener } from 'owa-freeze-dry-mail/lib/utils/handleRestoreScrollPosition';
import { initializePersistMailListener } from 'owa-freeze-dry-mail/lib/utils/handlePersistScrollPosition';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function initializeMailModuleState(sessionData?: SessionData | undefined) {
    // Passing a delegate here as the delete callback to allow deleteConversationItemParts to be fully loaded by webpack.
    conversationCache.initialize(mailStore.conversations, key => {
        deleteConversationItemParts(key);
    });

    initializePersistMailListener();
    initializeRestoreMailListener();

    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const options = getPrimeBootSettingsOptionsValues(mailboxInfo);

    initializeOptionValues(options);

    initializeCommandingViewMode(options);

    initializeSuiteHeaderPinnedPane(options);

    loadCommandBarActions();

    initializeAlwaysInCacheTableViews(mailboxInfo);

    loadDefaultTable(mailboxInfo, sessionData);

    loadFolderForest(sessionData);

    initializeMailLayout();

    loadMailRibbonConfiguration();
    if (!isFeatureEnabled('fp-remove-apollo')) {
        const apolloClient = getApolloClient();
        (apolloClient.cache as InMemoryCache).policies.addTypePolicies(folderTypePolicies);
        bootstrapFolderHierarchyCache(sessionData);
    }
}
