import {
    IsAnyUserPremiumConsumerOrBusiness,
    IsChildConsumerUser,
    IsShadowMailboxUser,
    embargoedMarkets,
    getAdMarketPublishGroupCode,
} from './sharedAdsUtils';
import { isConsumer } from 'owa-session-store';
import type NativeAdPlacement from './NativeAdPlacement';
import { emailadsOptInStore } from 'owa-emailadsoptin-store';
import getCachedEUEmailPolicyOptInBit from './getCachedEUEmailPolicyOptInBit';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import isEUEmailAdsPolicyMarket from './isEUEmailAdsPolicyMarket';
import { isFeatureEnabled } from 'owa-feature-flags';
import overallPremiumStatusStore from './store/overallPremiumStatusStore';
import isHeaderAdSpecialMarket from './isHeaderAdSpecialMarket';
import { logUsage } from 'owa-analytics';
import { getImportHistory } from 'owa-account-import-history/lib/getImportHistory';
import { getItem } from 'owa-local-storage';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

let nativeAdEnableOrNot: boolean | undefined;
let nativeAdInitialPlacement: NativeAdPlacement | undefined;
let secondaryPaidAccountLogged: boolean | undefined;

// To avoid bundle size increase and duplication, copy the LegacyApp enum from outlookwebcontext.g.ts
enum LegacyApp {
    OutlookDesktop = 'OutlookDesktop',
    Universal = 'Universal',
}

export function getNativeAdPlacement(): NativeAdPlacement {
    const nativeAdSessionNonChangePlacement = getNativeAdInitialPlacement();

    // If the native ad initial placement is none, we do not need to check the premium status & EU Ads policy as the Ad is disabled anyway
    if (nativeAdSessionNonChangePlacement == 0) {
        return 0;
    }

    // The following properties could change in one app session
    // 1. Premium status
    // 2. EU Ads policy

    // For premium status, the order of the check is
    // 1. Check the premium status store value as this is source of the truth. If the value is 2 (isPremium), set the ads placement to None.
    // 2. If the premium status store value is -1 (not set), retrieve from the cached premium status value.
    // 3. If the cached premium status value is 1 (NonPremium), let the nativeAdSessionNonChangePlacement continue to flow.
    if (
        overallPremiumStatusStore.overallPremiumBit == 2 ||
        (overallPremiumStatusStore.overallPremiumBit == -1 && IsAnyUserPremiumConsumerOrBusiness())
    ) {
        // Log the metrics if ads is hidden due to the seconary account premium status.
        if (!secondaryPaidAccountLogged) {
            logUsage('NoAdsSecondaryPaidAccount', {});
            secondaryPaidAccountLogged = true;
        }

        return 0;
    }

    if (
        isEUEmailAdsPolicyMarket() &&
        (emailadsOptInStore.emailAdsOptInBit == 1 ||
            emailadsOptInStore.emailAdsOptInBit == 0 ||
            (emailadsOptInStore.emailAdsOptInBit == -1 && getCachedEUEmailPolicyOptInBit() == 1))
    ) {
        // The order for the user who selects banner ad
        // 1. If any special treatment market, we will check a seperate flight for the header ad.
        // 2. For other markets, we will show the header ads based on the flight.
        if (isHeaderAdSpecialMarket() || isFeatureEnabled('ads-native-Euads-HeaderImage-Bottom')) {
            return 3;
        } else if (isFeatureEnabled('ads-native-Euads-HeaderImage-Top')) {
            return 2;
        }

        return 1;
    }

    return nativeAdSessionNonChangePlacement;
}

/*
Get the Ad initial placement. The flow is below:
1. Users which are in the following categories do not get native Ads
-- None of the native ads flight is enabled for this user
-- Child account
-- User is in a non-supported market
2. Regular user gets OtherPrefer treatment.
   When the user has Focused Inbox on, the ads is only shown in the Other folder. For
   For Non Focus/Other Inbox enabled users, we will show the Ad in the Inbox as well.
Note: Premium status needs to be calculated outside of this function as it could change during the app session.
*/
export function getNativeAdInitialPlacement(): NativeAdPlacement {
    if (!nativeAdInitialPlacement) {
        if (!getNativeAdEnabledOrNotFromUserProperties()) {
            nativeAdInitialPlacement = 0;
        } else if (isFeatureEnabled('adsexp-nativead-showinfocus')) {
            nativeAdInitialPlacement = isFeatureEnabled('adsExp-nativeads-showsubfolder-tr')
                ? 7
                : 6;
        } else {
            nativeAdInitialPlacement = isFeatureEnabled('adsExp-nativeads-showsubfolder-tr')
                ? 5
                : 4;
        }
    }

    return nativeAdInitialPlacement || 0;
}

export function shouldShowAdsInFocusedPivot(): boolean {
    // Show the focus ads when all the following conditions are met
    // 1. User has not seen any ads excluding the ad in Focused for 14 days, which means most likely they have not visited Other in 14 days.
    // 2. user has not seen an ad at all in the last 2 days
    // 3. User is not new to client in the last 14 days
    const daysDaysSinceLastAdSeenExFocused = getDaysDifference('dlaef');
    const daysSinceLastAdSeen = getDaysDifference('dla');
    if (
        daysDaysSinceLastAdSeenExFocused > 14 &&
        daysSinceLastAdSeen > 2 &&
        isNewUserGreater14Days()
    ) {
        return true;
    } else {
        return false;
    }
}

function getDaysDifference(lastAdSeenDateProperty: string): number {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const currentDate = new Date();
    const lastAdSeenDate = new Date(getItem(window, lastAdSeenDateProperty) || 0);
    const timeDifference = currentDate.getTime() - lastAdSeenDate.getTime();

    return Math.floor(timeDifference / millisecondsPerDay);
}

function isNewUserGreater14Days() {
    // milliseconds in 14 days
    const FOURTEEN_DAYS_TIMESPAN_IN_MS = 1000 * 60 * 60 * 24 * 14;
    const mailboxCreateDateString = getUserConfiguration().MailboxCreateDate;
    if (!mailboxCreateDateString) {
        return false;
    }

    const mailboxCreateDate = new Date(mailboxCreateDateString);
    return Date.now() - mailboxCreateDate.getTime() > FOURTEEN_DAYS_TIMESPAN_IN_MS;
}

/*
Some checks are on the user which will never change during an OWA session
Cache this value so that it will not be calculated again and again
*/
function getNativeAdEnabledOrNotFromUserProperties(): boolean {
    if (typeof nativeAdEnableOrNot != 'undefined') {
        return nativeAdEnableOrNot;
    }

    nativeAdEnableOrNot = isNativeAdsEnabledPrimaryAccount();

    return nativeAdEnableOrNot;
}

// The following check checks the primary account's properties to determine if the native ads is enabled or not
// These properties will not change during the whole session and we can cache these values.
function isNativeAdsEnabledPrimaryAccount(): boolean {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (
        !isConsumer(undefined, mailboxInfo) ||
        isFeatureEnabled('ads-shutdown-nativeAds') ||
        (!isFeatureEnabled('ads-cloudcacheaccount-honor-check') &&
            IsShadowMailboxUser(mailboxInfo)) ||
        IsChildConsumerUser(mailboxInfo) ||
        embargoedMarkets.indexOf(getAdMarketPublishGroupCode()) > -1 ||
        isToggledFromWin32ForAds()
    ) {
        return false;
    }

    return true;
}

export function isToggledFromWin32ForAds(): boolean {
    return (
        isFeatureEnabled('ads-hideAdsForWin32Toggle') &&
        getImportHistory().lastImportApp === LegacyApp.OutlookDesktop
    );
}

// This is only used for test
export function resetNativeAdCacheValueForTest() {
    nativeAdEnableOrNot = undefined;
    nativeAdInitialPlacement = undefined;
}
