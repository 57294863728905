import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyIgnoreConversationMutationWeb = createLazyResolver(
    'IGNORECONVERSATION_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "IgnoreConversationMutationWeb" */ './ignoreConversationMutationWeb'
        ),
    m => m.ignoreConversationMutationWeb
);
