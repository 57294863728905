import type { MutationFunction, MutationFunctionOptions, FetchResult } from '@apollo/client';
import type {
    ToggleFolderTreeCollapsedMutation,
    ToggleFolderTreeCollapsedMutationVariables,
} from '../graphql/__generated__/ToggleFolderTreeCollapsedStateMutation.interface';
import { ToggleFolderTreeCollapsedDocument } from '../graphql/__generated__/ToggleFolderTreeCollapsedStateMutation.interface';
import { updateUserConfiguration } from 'owa-session-store';
import { mutate } from 'owa-apollo';
import type { ResolverContext } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { DEFAULT_USER_CONFIG } from 'owa-graph-schema';
import type { QueuedActionOptions } from 'owa-queued-actions';

/**
 * Toggle the Collapsed of folder tree and update both local and server user config
 */
export function toggleFolderTreeCollapsed(
    toggleFolderTreeCollapsedMutationFunction: MutationFunction<
        ToggleFolderTreeCollapsedMutation,
        ToggleFolderTreeCollapsedMutationVariables,
        ResolverContext
    > | null,
    isCollapsed: boolean,
    mailboxInfo: MailboxInfo
) {
    const newIsCollapsedState = !isCollapsed;

    const mutationOptions: MutationFunctionOptions<
        ToggleFolderTreeCollapsedMutation,
        ToggleFolderTreeCollapsedMutationVariables,
        ResolverContext
    > &
        QueuedActionOptions = {
        variables: {
            input: {
                mailboxInfo,
                updates: {
                    UserOptions: { IsMailRootFolderTreeCollapsed: newIsCollapsedState },
                },
            },
        },
        optimisticResponse: {
            __typename: 'Mutation',
            updateUserConfiguration: {
                id: DEFAULT_USER_CONFIG,
                __typename: 'UserConfiguration',
                UserOptions: {
                    IsMailRootFolderTreeCollapsed: newIsCollapsedState,
                    __typename: 'UserOptions',
                },
            },
        },
        queuedAction: {
            blockingKeys: [],
            resultProcessor: 'Default',
            flight: 'fwk-aq-userConfig',
            replacesKey: 'FolderTreeCollapsed',
        },
    };

    /**
     * Satchel update
     */
    updateUserConfiguration(config => {
        if (config?.UserOptions?.IsMailRootFolderTreeCollapsed !== undefined) {
            config.UserOptions.IsMailRootFolderTreeCollapsed = newIsCollapsedState;
        }
    });

    /**
     * Execution
     */
    let toggleFolderTreeCollapsedMutationFunction_0 = toggleFolderTreeCollapsedMutationFunction;
    if (!toggleFolderTreeCollapsedMutationFunction_0) {
        toggleFolderTreeCollapsedMutationFunction_0 = (
            options?: MutationFunctionOptions<
                ToggleFolderTreeCollapsedMutation,
                ToggleFolderTreeCollapsedMutationVariables,
                ResolverContext
            > &
                QueuedActionOptions
        ): Promise<FetchResult> => {
            return mutate(ToggleFolderTreeCollapsedDocument, options ?? {});
        };
    }

    toggleFolderTreeCollapsedMutationFunction_0(mutationOptions);
}
