import type ExtendedPropertyUri from 'owa-service/lib/contract/ExtendedPropertyUri';
import { getExtendedPropertyUri } from 'owa-service/lib/ServiceRequestUtils';

const COMMON_PROPERTY_SET_GUID = '00020329-0000-0000-C000-000000000046';

// Extended property that holds the value for SchedulingServiceUpdateUrl
export const SchedulingServiceUpdateUrlExtendedProperty: ExtendedPropertyUri =
    getExtendedPropertyUri(COMMON_PROPERTY_SET_GUID, 'SchedulingServiceUpdateUrl', 'String');

// Extended property that holds the value for SchedulingServiceMeetingOptionsUrl
export const SchedulingServiceMeetingOptionsUrlExtendedProperty: ExtendedPropertyUri =
    getExtendedPropertyUri(
        COMMON_PROPERTY_SET_GUID,
        'SchedulingServiceMeetingOptionsUrl',
        'String'
    );
