import type { GovernFunction } from 'owa-bundling-light';
import { setConfig } from 'owa-bundling-light';
import markImportAsLoaded from './mutators/markImportAsLoaded';
import isImportLoaded from './selectors/isImportLoaded';
import type { LogUsageFunction } from 'owa-analytics-types';
import { errorThatWillCauseAlert, trace } from 'owa-trace';
import { type FeatureName, isFeatureEnabled } from 'owa-feature-flags';

export function setBundlingConfig(logUsage: LogUsageFunction, govern: GovernFunction) {
    setConfig({
        markImportAsLoaded,
        isImportLoaded,
        logUsage,
        logError: errorThatWillCauseAlert,
        govern,
        trace: (message: string) => trace.info(message, 'bundling'),
        // We can not import featureFlags in LazyImport because of dependency issue. Passing it as parameter.
        isFeatureEnabled: val => isFeatureEnabled(val as FeatureName, undefined, true),
    });
}
