import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { MenuItemControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/Menu';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

/**
 * Add to this type when you want to have a new Ribbon button defer its menu loading via stores.
 * Any string added to this array will become an initialized store of schema MenuDefinitionStore.
 */
export type MenuDefinitionStoreNames =
    | 'SnoozeMenuDefinitionStore'
    | 'FlagUnflagMenuDefinitionStore'
    | 'QuickStepsMenuDefinitionStore'
    | 'BlockMenuDefinitionStore'
    | 'RulesMenuDefinitionStore'
    | 'DeleteMenuDefinitionStore'
    | 'ReportMenuDefinitionStore'
    | 'ForwardMenuDefinitionStore'
    | 'AssignPolicyMenuDefinitionStore'
    | 'ChangeNoteColorMenuDefinitionStore'
    | 'RespondMenuDefinitionStore'
    | 'DensityMenuDefinitionStore'
    | 'LayoutMenuDefinitionStore'
    | 'MessagesMenuDefinitionStore'
    | 'NewGroupMenuDefinitionStore'
    | 'ConversationsMenuDefinitionStore'
    | 'MessagePreviewMenuDefinitionStore'
    | 'ReadingPaneOptionsMenuDefinitionStore'
    | 'FolderMenuOptionsMenuDefinitionStore'
    | 'ViewGroupsMenuDefinitionStore'
    | 'MoveToMenuDefinitionStore'
    | 'CategorizeMenuDefinitionStore'
    | 'RibbonModeOptionsMenuDefinitionStore'
    | 'CopilotMeetingMenuDefinitionStore'
    | 'TeamsChatMenuDefinitionStore'
    | 'CopyToMenuDefinitionStore'
    | 'MyDayOptionsMenuDefinitionStore';

/**
 * MenuDefinitionMap maps the static string names of stores to their corresponding MenuDefinition.
 * It maps to a () => MenuDefinition such to avoid stale copies of MenuDefinition.
 */
interface MenuDefinitionMap {
    MenuDefinitionMap: ObservableMap<MenuDefinitionStoreNames, () => MenuDefinition>;
}

/**
 * MenuDefinitionStore is the overall store interface that holds a map of maps.
 * The outer map is a map of either <"main_window" | projectionId> to MenuDefinitionMap. This allows for popout
 * projection ribbon scenarios, in order to let each popout control its own menu definition state without colliding.
 * The inner map is a map of storeNames (e.g. "SnoozeMenuDefinitionStore") to the actual MenuDefinition.
 */
interface MenuDefinitionStore {
    menuDefinitionMapManager: ObservableMap<string, MenuDefinitionMap>;
}

/**
 * Should there not be a projectionTabId, we assume this is the main window and use this
 * as the string key for the menuDefinitionMapManager.
 */
export const MAIN_WINDOW_ID: string = 'main_window';

/**
 * Initialize the main store with a single entry in the map for the main window.
 * Any popouts will be added to this map as they are created.
 */
const store = createStore<MenuDefinitionStore>('RibbonMenuDefinitionStore', {
    menuDefinitionMapManager: new ObservableMap<string, MenuDefinitionMap>([
        [
            // Initializing base case for the main window
            MAIN_WINDOW_ID,
            {
                MenuDefinitionMap: new ObservableMap<
                    MenuDefinitionStoreNames,
                    () => MenuDefinition
                >(),
            },
        ],
    ]),
})();
export const getStore = () => store;

/**
 * Getter function to retrieve a menu definition given a storeName and a ProjectionTabId.
 * Should there not be a store provisioned already for that storeName, we return an empty menu definition.
 * Stores are provisioned during the click event of the Ribbon button, not in this function.
 * @param storeName MenuDefinitionStoreNames e.g. "SnoozeMenuDefinitionStore"
 * @param projectionTabId For read-only projection scenarios.
 * @returns MenuDefinition
 */
export const getMenuDefinition = (
    storeName: MenuDefinitionStoreNames,
    projectionTabId?: string
): MenuDefinition => {
    const id = projectionTabId || MAIN_WINDOW_ID;

    if (
        !store.menuDefinitionMapManager.has(id) ||
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        !store.menuDefinitionMapManager.get(id)!.MenuDefinitionMap.has(storeName)
    ) {
        // Either no store has been created for this contextual instance (projectionTabId)
        // or no store has yet to be created for this store name (which is usually done on click).
        // In either case, we return a default empty menu definition.
        // In main window, we simply return an empty sections.
        // In popout scenarios, we want to return a placeholder content as a workaround
        // to avoid flyout dropdowns in overflow rendering off screen due to sizing miscalculations.
        if (id === MAIN_WINDOW_ID) {
            return {
                sections: [],
            };
        } else {
            return {
                sections: [
                    {
                        controls: [
                            {
                                type: 'AppButtonProps',
                                id: 'controlPlaceHolder',
                                label: '',
                            } as MenuItemControlProps,
                        ],
                    },
                ],
            };
        }
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion.
     *	> Forbidden non-null assertion. */
    return store.menuDefinitionMapManager.get(id)!.MenuDefinitionMap.get(storeName)!();
};
