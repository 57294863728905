import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "UpsellModuleAsync" */ './lazyIndex')
);
export const TopUpsellBannerComponent = createLazyComponent(
    lazyModule,
    m => m.TopUpsellBanner,
    undefined,
    undefined,
    { govern: true }
);
