import { default as CategoryColor, type CategoryColorValue } from '../schema/CategoryColor';

export const lightColorCodeValueMap: {
    [color: string]: CategoryColorValue;
} = {
    [CategoryColor.None]: {
        primaryColor: '#CCCCCC',
        secondaryColor: '#A6A6A6',
        iconColor: '#000000',
        textColor: '#000000',
        hoverColor: '#000000',
        calendarColor: '#000000',
    },

    [CategoryColor.DarkRed]: {
        primaryColor: '#E9C7CD',
        secondaryColor: '#AC4F5E',
        iconColor: '#750B1C',
        textColor: '#420610',
        hoverColor: '#D69CA5',
        calendarColor: '#750B1C',
    },

    [CategoryColor.Cranberry]: {
        primaryColor: '#F6D1D5',
        secondaryColor: '#DC626D',
        iconColor: '#C50F1F',
        textColor: '#6E0811',
        hoverColor: '#EEACB2',
        calendarColor: '#C50F1F',
    },

    [CategoryColor.DarkOrange]: {
        primaryColor: '#F9DCD1',
        secondaryColor: '#E9835E',
        iconColor: '#C43501',
        textColor: '#7A2101',
        hoverColor: '#F4BFAB',
        calendarColor: '#DA3B01',
    },

    [CategoryColor.Bronze]: {
        primaryColor: '#F1D9CC',
        secondaryColor: '#CA8057',
        iconColor: '#A74109',
        textColor: '#5E2405',
        hoverColor: '#E5BBA4',
        calendarColor: '#A74109',
    },

    [CategoryColor.Peach]: {
        primaryColor: '#FFDDB3',
        secondaryColor: '#FFBA66',
        iconColor: '#8F4E00',
        textColor: '#4D2A00',
        hoverColor: '#FFBA66',
        calendarColor: '#FF8C00',
    },

    [CategoryColor.Marigold]: {
        primaryColor: '#FCEFD3',
        secondaryColor: '#F2C661',
        iconColor: '#835B00',
        textColor: '#463100',
        hoverColor: '#F2C661',
        calendarColor: '#EAA300',
    },

    [CategoryColor.Gold]: {
        primaryColor: '#F5EECE',
        secondaryColor: '#DAC157',
        iconColor: '#6C5700',
        textColor: '#3A2F00',
        hoverColor: '#DAC157',
        calendarColor: '#C19C00',
    },

    [CategoryColor.DarkBrown]: {
        primaryColor: '#CAADA3',
        secondaryColor: '#946B5C',
        iconColor: '#4D291C',
        textColor: '#2B1710',
        hoverColor: '#E3D2CB',
        calendarColor: '#4D291C',
    },

    [CategoryColor.Lime]: {
        primaryColor: '#E5F1D3',
        secondaryColor: '#A4CC6C',
        iconColor: '#405F14',
        textColor: '#22330B',
        hoverColor: '#A4CC6C',
        calendarColor: '#73AA24',
    },

    [CategoryColor.Forest]: {
        primaryColor: '#BDD99B',
        secondaryColor: '#85B44C',
        iconColor: '#294903',
        textColor: '#162702',
        hoverColor: '#85B44C',
        calendarColor: '#498205',
    },

    [CategoryColor.LightGreen]: {
        primaryColor: '#CEF0CD',
        secondaryColor: '#5EC75A',
        iconColor: '#0E7A0B',
        textColor: '#063004',
        hoverColor: '#5EC75A',
        calendarColor: '#13A10E',
    },

    [CategoryColor.DarkGreen]: {
        primaryColor: '#9AD29A',
        secondaryColor: '#4DA64D',
        iconColor: '#085108',
        textColor: '#032003',
        hoverColor: '#4DA64D',
        calendarColor: '#0B6A0B',
    },

    [CategoryColor.LightTeal]: {
        primaryColor: '#A6E9ED',
        secondaryColor: '#58D3DB',
        iconColor: '#00666D',
        textColor: '#00373A',
        hoverColor: '#58D3DB',
        calendarColor: '#00B7C3',
    },

    [CategoryColor.DarkTeal]: {
        primaryColor: '#92D1D1',
        secondaryColor: '#41A3A3',
        iconColor: '#003939',
        textColor: '#003939',
        hoverColor: '#C2E7E7',
        calendarColor: '#006666',
    },

    [CategoryColor.Steel]: {
        primaryColor: '#94C8D4',
        secondaryColor: '#4496A9',
        iconColor: '#005B70',
        textColor: '#00333F',
        hoverColor: '#C3E1E8',
        calendarColor: '#005B70',
    },

    [CategoryColor.SkyBlue]: {
        primaryColor: '#D4F4FD',
        secondaryColor: '#63D7F7',
        iconColor: '#006A88',
        textColor: '#004659',
        hoverColor: '#63D7F7',
        calendarColor: '#00BCF2',
    },

    [CategoryColor.DarkBlue]: {
        primaryColor: '#C2D6E7',
        secondaryColor: '#4178A3',
        iconColor: '#003966',
        textColor: '#002039',
        hoverColor: '#92B5D1',
        calendarColor: '#003966',
    },

    [CategoryColor.Lavender]: {
        primaryColor: '#E7E4FB',
        secondaryColor: '#A79CF1',
        iconColor: '#6656D1',
        textColor: '#3F3682',
        hoverColor: '#D2CCF8',
        calendarColor: '#7160E8',
    },

    [CategoryColor.DarkPurple]: {
        primaryColor: '#B9A3D3',
        secondaryColor: '#7E5CA7',
        iconColor: '#512B7E',
        textColor: '#240F3C',
        hoverColor: '#D8CCE7',
        calendarColor: '#401B6C',
    },

    [CategoryColor.Pink]: {
        primaryColor: '#FBDDF0',
        secondaryColor: '#EF85C8',
        iconColor: '#AD2D7E',
        textColor: '#80215D',
        hoverColor: '#F7C0E3',
        calendarColor: '#E43BA6',
    },

    [CategoryColor.Plum]: {
        primaryColor: '#D696C0',
        secondaryColor: '#AD4589',
        iconColor: '#87105D',
        textColor: '#240017',
        hoverColor: '#E9C4DC',
        calendarColor: '#77004D',
    },

    [CategoryColor.Beige]: {
        primaryColor: '#EAE8E8',
        secondaryColor: '#AFABAA',
        iconColor: '#6E6968',
        textColor: '#444241',
        hoverColor: '#D7D4D4',
        calendarColor: '#7A7574',
    },

    [CategoryColor.Mink]: {
        primaryColor: '#CECCCB',
        secondaryColor: '#9E9B99',
        iconColor: '#474443',
        textColor: '#343231',
        hoverColor: '#9E9B99',
        calendarColor: '#5D5A58',
    },

    [CategoryColor.Silver]: {
        primaryColor: '#D8DFE0',
        secondaryColor: '#B3BFC2',
        iconColor: '#657174',
        textColor: '#282D2E',
        hoverColor: '#B3BFC2',
        calendarColor: '#859599',
    },

    [CategoryColor.Charcoal]: {
        primaryColor: '#C4C4C4',
        secondaryColor: '#888888',
        iconColor: '#333333',
        textColor: '#202020',
        hoverColor: '#888888',
        calendarColor: '#393939',
    },
};
