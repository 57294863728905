import React from 'react';
import { observer } from 'owa-mobx-react';
import { AppBar } from '@office-start/appbar-control';
import { AccountSwitcher } from 'owa-account-switcher';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isCloudCacheScenario } from 'owa-left-rail-utils/lib/isCloudCacheScenario';
import { isEasiIdUser } from 'owa-cloud-cache-accounts-option';
import { FluentProvider } from '@fluentui/react-components';
import { AppStoreModal, AppStoreDetailsDialog } from 'owa-app-host-components';
import { getPalette } from 'owa-theme';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { createFluentTheme } from '../utils/createFluentTheme';
import { RegisterForAccountStateChange } from 'owa-account-source-list';
import { bootstrapM365AcquisitionsCache } from 'owa-m365-acquisitions-cache';
import type { AccountSource } from 'owa-account-source-list-store';
import onSwitchMailboxM365Acquisitions from 'owa-appbar-state/lib/actions/onSwitchMailboxM365Acquisitions';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { monarchAwareLeftRail, leftRail, multiAccountContainer, bleedThrough } from './AppBar.scss';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { trySetBposNavBarData } from 'owa-bpos-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyInitializeAppBarPinnedAppsUserSetting } from 'owa-appbar-pinnedapps';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import className from 'owa-classnames';

const FluentProviderStyle = { backgroundColor: 'transparent', height: '100%' };

export default observer(function WrappedAppBar() {
    const isCloudCacheMultiAccount = isCloudCacheScenario() || isEasiIdUser();
    const mailboxInfo = isAddinMultiAccountEnabled() ? getModuleContextMailboxInfo() : undefined;
    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (isAddinMultiAccountEnabled() && mailboxInfo) {
            onSwitchMailboxM365Acquisitions(mailboxInfo);
        }
    }, [mailboxInfo?.userIdentity]);

    React.useEffect(() => {
        if (isAddinMultiAccountEnabled()) {
            // After initial render, initialze app bar data for each connected account
            // 1. Initialize pinned apps settings
            // 2. Populate bpos store with data from user settings
            // 3. Prime acquisitions cache with static data
            RegisterForAccountStateChange(
                (accountSource: AccountSource) => {
                    const accountMailboxInfo = accountSource.mailboxInfo;
                    lazyInitializeAppBarPinnedAppsUserSetting.importAndExecute(accountMailboxInfo);
                    // The global settings account's static acquisitions data is already in cache. It's done pre-render
                    if (
                        getIndexerValueForMailboxInfo(accountMailboxInfo) !==
                        getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo())
                    ) {
                        const userSettings = getAccountScopeUserSettings(accountMailboxInfo);
                        if (userSettings?.NavBarData) {
                            trySetBposNavBarData(userSettings.NavBarData, accountMailboxInfo);
                        }
                        // Prime acquisitions cache with static data for each connected account
                        bootstrapM365AcquisitionsCache(accountMailboxInfo);
                    }
                },
                (_accountSource: AccountSource) => {} // no-op the pre-account removed callback
            );
        }
    }, []);

    const useMonarchAwareStyles = isFeatureEnabled('mon-transparency-usingSystemBackdrop');
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    return (
        <>
            <AppStoreModal mailboxInfo={mailboxInfo} />
            <AppStoreDetailsDialog mailboxInfo={mailboxInfo} />
            <div
                className={className(
                    useMonarchAwareStyles ? monarchAwareLeftRail : leftRail,
                    isBleedThroughEnabled && bleedThrough
                )}
                id="LeftRail"
            >
                {isCloudCacheMultiAccount && (
                    <div className={multiAccountContainer}>
                        {isFeatureEnabled('auth-cloudCache') && <AccountSwitcher />}
                    </div>
                )}
                <FluentProvider
                    theme={createFluentTheme(getPalette())}
                    style={FluentProviderStyle}
                    dir={isCurrentCultureRightToLeft() ? 'rtl' : 'ltr'}
                >
                    <AppBar />
                </FluentProvider>
            </div>
        </>
    );
}, 'WrappedAppBar');
