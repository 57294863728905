import { LazyAction, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailSafeLinkShared" */ './lazyIndex'),
    {
        name: 'MailSafeLinkShared',
    }
);

export const lazyOnClickComposeLink = new LazyAction(lazyModule, m => m.onClickComposeLink);
export const lazyHandleLinkClickViaSafelinks = new LazyAction(
    lazyModule,
    m => m.handleLinkClickViaSafelinks
);
export const lazyHandleLinkClick = new LazyAction(lazyModule, m => m.handleLinkClick);
