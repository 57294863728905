import { observer } from 'owa-mobx-react';
import { useComputed } from 'owa-react-hooks/lib/useComputed';
import SearchRegularV8 from 'owa-fluent-icons-svg/lib/icons/SearchRegular';
import { SearchRegular, SearchFilled, bundleIcon } from '@fluentui/react-icons';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type DropViewState from 'owa-dnd/lib/store/schema/DropViewState';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { lazySelectFavoriteSearch } from 'owa-mail-folder-forest-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET } from 'owa-mail-folder-view';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import type { FavoriteNodeCommonProps } from './FavoriteNode';
import React from 'react';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';
import type { MailboxInfo } from 'owa-client-types';
import { SourceMap } from 'owa-folders-constants';

import { dragPreview } from './FavoriteNode.scss';

const Search = bundleIcon(SearchFilled, SearchRegular);

export interface FavoriteSearchNodeProps extends FavoriteNodeCommonProps {
    searchQuery: string;
    dropViewState: DropViewState;
    mailboxInfo: MailboxInfo;
    ellipsesOnHover?: boolean;
}

export default observer(function FavoriteSearchNode(props: FavoriteSearchNodeProps) {
    const isSelected = useComputed((): boolean => {
        return props.searchQuery == getSelectedNode().id;
    });
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClick = (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        lazySelectFavoriteSearch.importAndExecute(props.searchQuery);
    };
    // VSO 23480 - [FavoritesDND]Refactor the common DND code into a base component
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const getDragData = () => {
        const favoriteNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId: props.favoriteId,
            favoriteType: 3,
            displayName: props.searchQuery,
            itemData: {
                mailboxInfo: props.mailboxInfo,
            },
        };
        return favoriteNodeDragData;
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const canDrop = (dragData: DragData) => {
        switch (dragData.itemType) {
            case DraggableItemTypes.FavoriteNode:
                return canDropFavorite(dragData, props.mailboxInfo);
            case DraggableItemTypes.MailFolderNode:
                return canDropFolder(dragData, props.mailboxInfo);
            default:
                return false;
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDrop = async (dragData: DragData) => {
        if (!canDrop(dragData)) {
            return;
        }
        const itemType = dragData.itemType;
        switch (itemType) {
            case DraggableItemTypes.MailFolderNode:
                {
                    await onDropFolder(dragData, props.mailboxInfo, props.favoriteId);
                }
                break;
            case DraggableItemTypes.FavoriteNode:
                {
                    await onDropFavorite(dragData, props.favoriteId);
                }
                break;
        }
    };
    return (
        <DragAndDroppable
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={props.dropViewState}
            onDrop={onDrop}
            canDrop={canDrop}
        >
            <TreeNode
                customIcon={SearchRegularV8}
                customIconComponent={Search}
                displayName={props.searchQuery}
                isRootNode={false}
                key={props.searchQuery}
                isDroppedOver={props.dropViewState.isDragOver}
                onClick={onClick}
                isSelected={isSelected.get()}
                ellipsesOnHover={props.ellipsesOnHover}
                source={SourceMap.favorite}
            />
        </DragAndDroppable>
    );
}, 'FavoriteSearchNode');

function getDragPreview(dragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (dragData as FavoriteNodeDragData)?.displayName ?? '';
    return elem;
}
