import { observer } from 'owa-mobx-react';
import { useComputed } from 'owa-react-hooks/lib/useComputed';
import React from 'react';
import getPlaceholderAndAriaLabel from '../selectors/getPlaceholderAndAriaLabel';
import type KeyboardCharCodes from 'owa-hotkeys/lib/utils/keyboardCharCodes';
import type { SearchScenarioId } from 'owa-search-store';
import { getScenarioStore } from 'owa-search-store';
import {
    SEARCHBOX_INPUT_ELEMENT_ID,
    SUGGESTION_ID_PREFIX,
    SUGGESTIONS_CALLOUT_ID,
    FILTERS_BUTTON_ID,
    SEARCHBOX_COLUMN_CONTAINER_ID,
    SUGGESTION_QUICKACTION_ID_PREFIX,
} from 'owa-search-constants';
import {
    getSuggestions,
    lazyOnBackspacePressedSearchInput,
    lazyOnDownArrowPressedSearchInput,
    lazyOnLeftArrowPressedSearchInput,
    lazyOnUpArrowPressedSearchInput,
    lazyOnRightArrowPressedSearchInput,
    onEnterPressedSearchInput,
    onEscapePressedSearchInput,
    onKeyDownSearchInput,
    onSearchTextChanged,
    onSearchInputFocused,
    setIsSuggestionsDropdownVisible,
    setSearchTextForSuggestion,
    setInKeyboardSelectionMode,
    resetFocusFromSuggestionsToSearchInput,
} from 'owa-search-actions';
import { isFeatureEnabled } from 'owa-feature-flags';

import classNames from 'owa-classnames';
import {
    searchBoxInput,
    isDisabled as styles_isDisabled,
    inSearchInputPlaceholder,
    inputPlaceholder,
    searchBoxInputContainer,
} from './SearchInput.scss';
import { shouldUseAppHostSearch } from 'owa-app-host-search/lib/utils/shouldUseAppHostSearch';
import { getSelectedApp } from 'owa-appbar-state';
import { LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';

import { addShadow, removeShadow } from '../utils/manageShadowBox';
import hasSuggestionPills from '../selectors/hasSuggestionPills';

export interface SearchInputProps {
    searchPlaceHolderText?: string;
    isDisabled?: boolean; // Boolean indicating if search box is disabled (default is false)
    scenarioId: SearchScenarioId;
    isSuggestionsCalloutVisible: boolean;
    ariaLabel?: string;
}

export interface SearchInputHandle {
    setFocus(): void;
    unFocusShadow(): void;
}

export default observer(
    React.forwardRef(function SearchInput(
        props: SearchInputProps,
        ref: React.Ref<SearchInputHandle>
    ) {
        const searchInput = React.useRef<HTMLInputElement>();

        /**
         * This computed returns the text to be displayed in the actual input
         * element.
         */
        const searchInputText = useComputed((): string => {
            return getScenarioStore(props.scenarioId).searchText;
        });

        const activeDescendant = useComputed((): string => {
            const { isSuggestionsCalloutVisible, scenarioId } = props;
            const { selectedSuggestionIndex, selectedSuggestionQuickActionSelected } =
                getScenarioStore(scenarioId);
            /**
             * If search suggestions are visible and the user is focused
             * on a quick action, then return the ID for the quick action
             * to be passed down for the aria-activedescendant attribute.
             * Otherwise, if search suggestions are visible and the user has a suggestion
             * selected, return the ID for the suggestion.
             */
            if (isSuggestionsCalloutVisible && selectedSuggestionQuickActionSelected) {
                return `${SUGGESTION_ID_PREFIX}${selectedSuggestionIndex}-${SUGGESTION_QUICKACTION_ID_PREFIX}`;
            } else if (isSuggestionsCalloutVisible && selectedSuggestionIndex >= 0) {
                return `${SUGGESTION_ID_PREFIX}${selectedSuggestionIndex}`;
            }
            return '';
        });

        /**
         * Sets focus on the search input. This is exposed publicly so parent component
         * (SearchBox) can set focus on the input element as a result of clicks on other
         * parts of the search box.
         */
        React.useImperativeHandle(
            ref,
            () => ({
                setFocus() {
                    if (searchInput.current) {
                        searchInput.current.focus();
                    }
                },
                unFocusShadow() {
                    const searchBoxColumnContainer = document.getElementById(
                        SEARCHBOX_COLUMN_CONTAINER_ID
                    );
                    searchBoxColumnContainer?.removeAttribute('style');
                    removeShadow();
                },
            }),
            []
        );

        /**
         * Key handler for special key input into the input element.
         */
        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> JSX attribute values should not contain functions created in the same scope */
        const onKeyDown = async (evt: React.KeyboardEvent<unknown>) => {
            evt.stopPropagation();
            const evtKeyCode = evt.keyCode;
            const scenarioId = props.scenarioId;
            const { selectedSuggestionIndex, inKeyboardSelectionMode } =
                getScenarioStore(scenarioId);

            // Dispatch action to modify store based on key down.
            onKeyDownSearchInput(evtKeyCode, scenarioId);

            switch (evtKeyCode) {
                case 13: {
                    // Clear selection (non-default behavior of IE/Edge).
                    clearSelection();
                    evt.preventDefault();
                    onEnterPressedSearchInput(selectedSuggestionIndex, scenarioId);
                    break;
                }
                case 8: {
                    const onBackspacePressedSearchInput =
                        await lazyOnBackspacePressedSearchInput.import();
                    onBackspacePressedSearchInput(getCursorPosition(), scenarioId);
                    break;
                }
                case 38:
                    // Prevents cursor from going to beginning of input.
                    evt.preventDefault();
                    const onUpArrowPressedSearchInput =
                        await lazyOnUpArrowPressedSearchInput.import();
                    onUpArrowPressedSearchInput(scenarioId);
                    break;
                case 40:
                    const onDownArrowPressedSearchInput =
                        await lazyOnDownArrowPressedSearchInput.import();
                    onDownArrowPressedSearchInput(scenarioId);
                    break;
                case 37: {
                    const onLeftArrowPressedSearchInput =
                        await lazyOnLeftArrowPressedSearchInput.import();
                    onLeftArrowPressedSearchInput(getCursorPosition(), scenarioId, evt);
                    break;
                }
                case 27: {
                    // Prevents IE/Edge behavior of putting last text back into the input.
                    evt.preventDefault();
                    onEscapePressedSearchInput(scenarioId);
                    break;
                }
                case 39: {
                    const onRightArrowPressedSearchInput =
                        await lazyOnRightArrowPressedSearchInput.import();
                    onRightArrowPressedSearchInput(scenarioId, evt);
                    break;
                }
                default: {
                    // Special case when in the OrgExplorer app.  Should not allow the user to type in the search box if there are suggestion pills
                    if (
                        shouldUseAppHostSearch() &&
                        getSelectedApp() === LaunchPageAppId.OrgExplorer &&
                        hasSuggestionPills(scenarioId)
                    ) {
                        evt.preventDefault();
                    }

                    if (inKeyboardSelectionMode) {
                        setInKeyboardSelectionMode(scenarioId, false);
                    }
                    break;
                }
            }
        };

        /**
         * Click handler when user clicks in input.
         */
        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> JSX attribute values should not contain functions created in the same scope */
        const onClick = (evt: React.MouseEvent<unknown>) => {
            evt.stopPropagation();
            setIsSuggestionsDropdownVisible(true, props.scenarioId);
            resetFocusFromSuggestionsToSearchInput(props.scenarioId);
        };

        /**
         * Focus handler for when the SearchInput component receives focus.
         *
         * We want to swallow the focus event if we are coming from
         * a suggestion (child component) within the SearchInput container.
         */
        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> JSX attribute values should not contain functions created in the same scope */
        const onFocus = (e: React.FocusEvent<EventTarget>) => {
            let relatedTargetElement = e.relatedTarget as HTMLElement;

            // Fallback for IE, which doesn't always set relatedTarget.
            if (!relatedTargetElement) {
                relatedTargetElement = document.activeElement as HTMLElement;
            }

            const isTargetElementWithinSearchBox =
                relatedTargetElement.id.indexOf(SUGGESTION_ID_PREFIX) > -1;

            //Check if the previous element focused was the Advanced Search Icon
            const isFocusAfterClickingWhileHover = relatedTargetElement.id === FILTERS_BUTTON_ID;

            // Truly "focus" only if event is from outside of search box.
            // And the Focus event was not triggered by a hover state
            if (!isTargetElementWithinSearchBox && !isFocusAfterClickingWhileHover) {
                setIsSuggestionsDropdownVisible(true, props.scenarioId);
                onSearchInputFocused(props.scenarioId);
            }

            if (isFeatureEnabled('sea-focusSearchBox')) {
                const searchBoxColumnContainer = document.getElementById(
                    SEARCHBOX_COLUMN_CONTAINER_ID
                );
                searchBoxColumnContainer?.setAttribute('style', 'z-index:9999999;');
                addShadow('O365_NavHeader', 'fluent-default-layer-host');
            }
        };

        const { searchSessionGuid, isSuggestionsDropdownVisible } = getScenarioStore(
            props.scenarioId
        );
        /**
         * Need to do this synchronously or there'll be rendering issues and the value
         * of the input element will be empty.
         */
        const onSearchTextChange = React.useCallback(() => {
            const scenarioId = props.scenarioId;
            const searchText = searchInput?.current?.value ?? '';
            onSearchTextChanged(searchText, scenarioId);
            setSearchTextForSuggestion(searchText, scenarioId);
            getSuggestions(scenarioId);
            // If suggestions dropdown is hidden, show it.
            if (!isSuggestionsDropdownVisible) {
                setIsSuggestionsDropdownVisible(true, scenarioId);
            }
        }, [props.scenarioId, searchInput.current?.value, isSuggestionsDropdownVisible]);

        /**
         * Gets cursor position if no text is selected. If text is selected, then
         * -1 is returned.
         */
        const getCursorPosition = (): number => {
            /**
             * If reference to input is null (component unmounted), return -1
             * indicating that cursor position shouldn't be used.
             */
            if (
                !searchInput.current ||
                searchInput.current.selectionStart === undefined ||
                searchInput.current.selectionStart === null
            ) {
                return -1;
            }
            const isTextSelected =
                searchInput.current.selectionStart !== searchInput.current.selectionEnd;
            return isTextSelected ? -1 : searchInput.current.selectionStart;
        };
        /**
         * Clears selected text in the input.
         */
        const clearSelection = () => {
            const searchInputEnd = searchInput.current?.value?.length ?? 0;
            searchInput.current?.setSelectionRange(searchInputEnd, searchInputEnd);
        };
        const { scenarioId, searchPlaceHolderText, isDisabled, isSuggestionsCalloutVisible } =
            props;

        const isSearchBoxExpanded = !!searchSessionGuid;
        const searchInputClasses = classNames(
            searchBoxInput,
            isDisabled && styles_isDisabled,
            isSearchBoxExpanded ? inSearchInputPlaceholder : inputPlaceholder
        );
        const { ariaLabel, placeholder } = getPlaceholderAndAriaLabel(
            scenarioId,
            searchPlaceHolderText ?? ''
        );

        return (
            <div className={searchBoxInputContainer}>
                <input
                    aria-activedescendant={activeDescendant.get()}
                    aria-autocomplete="list"
                    aria-label={props.ariaLabel ?? ariaLabel}
                    aria-owns={isSuggestionsCalloutVisible ? SUGGESTIONS_CALLOUT_ID : ''}
                    autoComplete="off"
                    className={searchInputClasses}
                    disabled={isDisabled}
                    onChange={onSearchTextChange}
                    onClick={onClick}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    /* eslint-disable @typescript-eslint/no-shadow --
                     * See https://typescript-eslint.io/rules/no-shadow
                     *	> 'ref' is already declared in the upper scope. */
                    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> JSX attribute values should not contain functions created in the same scope */
                    ref={ref => ref && (searchInput.current = ref)}
                    /* eslint-enable @typescript-eslint/no-shadow */
                    value={searchInputText.get()}
                    spellCheck={false}
                    autoCorrect="off"
                    id={SEARCHBOX_INPUT_ELEMENT_ID}
                />
            </div>
        );
    }),
    'SearchInput'
);
