import type { MailboxInfo } from 'owa-client-types';
import getCopilotPrioritizeSettingsStore from '../store/store';
import fetchCopilotPrioritizeSettingsFromService from '../services/fetchCopilotPrioritizeSettingsFromService';
import onCopilotPrioritizeSettingsFetched from '../mutators/onCopilotPrioritizeSettingsFetched';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function fetchCopilotPrioritizeSettings(
    mailboxInfo: MailboxInfo,
    forceRefresh: boolean = false
): Promise<boolean> {
    if (!isFeatureEnabled('mon-copilot-inbox', mailboxInfo)) {
        return Promise.resolve(true);
    }

    const store = getCopilotPrioritizeSettingsStore(mailboxInfo);
    if (store.isInitialized && !forceRefresh) {
        return Promise.resolve(true);
    }

    const response = await fetchCopilotPrioritizeSettingsFromService(mailboxInfo);
    if (response) {
        onCopilotPrioritizeSettingsFetched(mailboxInfo, response);
    }

    return Promise.resolve(true);
}
