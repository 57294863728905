import type { MutationFunction } from '@apollo/client';
import type { ResolverContext, MailFolder } from 'owa-graph-schema';
import type {
    DeleteFolderMutation,
    DeleteFolderMutationVariables,
} from 'owa-folder-deletefolder/lib/graphql/__generated__/DeleteFolderMutation.interface';
import type {
    RenameFolderMutation,
    RenameFolderMutationVariables,
} from 'owa-folder-rename/lib/graphql/__generated__/RenameFolderMutation.interface';
import type { MailboxInfo } from 'owa-client-types';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { lazyDeleteFolder } from 'owa-folder-deletefolder';
import folderStore from 'owa-folders';
import type { ActionSource } from 'owa-analytics-types';

export const onDeleteFolder = (
    folderId: string,
    deletedItemsDistinguishedId: string,
    mailboxInfo: MailboxInfo,
    actionSource: ActionSource,
    deleteFolderMutation: MutationFunction<
        DeleteFolderMutation,
        DeleteFolderMutationVariables,
        ResolverContext
    >,
    renameFolderMutation: MutationFunction<
        RenameFolderMutation,
        RenameFolderMutationVariables,
        ResolverContext
    >,
    isSearchFolder?: boolean,
    distinguishedFolderParentIds?: string[]
) => {
    const folder: MailFolder | undefined = folderStore.folderTable.get(folderId);
    if (!folder) {
        return;
    }

    // Can't delete some folder types
    const isDistinguishedFolder = folder.distinguishedFolderType;
    const folderClassOutlookHomepage = folder.FolderClass === 'IPF.Note.OutlookHomepage';
    if (isDistinguishedFolder || folderClassOutlookHomepage) {
        return;
    }

    let isFolderUnderDeletedItemsFolder = false;
    if (
        distinguishedFolderParentIds &&
        distinguishedFolderParentIds.indexOf(deletedItemsDistinguishedId) > -1
    ) {
        isFolderUnderDeletedItemsFolder = true;
    }

    // sayalitodo - add vso to fix the archive\shared cases
    const deletedItemsFolderId = folderNameToId(deletedItemsDistinguishedId, folder?.mailboxInfo);

    lazyDeleteFolder.importAndExecute(
        deleteFolderMutation,
        folder,
        actionSource,
        deletedItemsFolderId,
        isFolderUnderDeletedItemsFolder,
        mailboxInfo,
        renameFolderMutation,
        isSearchFolder
    );
};
