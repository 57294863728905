import viewStateStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export function getShouldOverrideToSortBySizeForMailFolder(): boolean {
    return (
        !!viewStateStore?.shouldOverrideToSortBySizeBasedOnURL &&
        isFeatureEnabled('storage-msq', getModuleContextMailboxInfo())
    );
}
