import './orchestrators/onHideFullOptions';
import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(() => import('./lazyIndex'));

export { default as OwaSuiteHeader } from './components/OwaSuiteHeader';

export const lazyUpdateEmbedChatOptions = new LazyAction(
    lazyModule,
    m => m.updateEmbedChatLastUsedDate
);

export const lazyShouldLoadEmbedPreAuthOnIdle = new LazyAction(
    lazyModule,
    m => m.shouldLoadEmbedPreAuthOnIdle
);

export const lazyGetChatUnreadMessageCount = new LazyAction(
    lazyModule,
    m => m.getChatUnreadMessageCount
);
