import { LazyAction, LazyModule } from 'owa-bundling';
export {
    default as copilotSettingsStore,
    elaborateSettingsCache,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
    type CopilotCapableAddinInfo,
    type ElaborateFeatureCapabilities,
    type RewriteFeatureCapabilities,
    type CopilotFeatureCapabilities,
    type AddinSettings,
    FetchState,
} from './store/store';

export {
    LicenseType,
    ElaborateCapabilitiesType,
    RewriteCapabilitiesType,
} from './services/types/serviceTypes';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotSettings" */ './lazyIndex'),
    { name: 'CopilotSettings' }
);

export const lazyFetchCopilotSettings = new LazyAction(lazyModule, m => m.fetchCopilotSettings);
export const lazySetElaborateSettingsToOcs = new LazyAction(
    lazyModule,
    m => m.setElaborateSettingsToOcs
);
export const lazySetAddinSettingsToOcs = new LazyAction(lazyModule, m => m.setAddinSettingsToOcs);

export { isSalesCopilotAddin } from './utils/isSalesCopilotAddin';
export { default as isGeneric1PAddinSupported } from './utils/isGeneric1PAddinSupported';
export {
    OUTLOOK_ADDIN_TITLE_ID,
    OUTLOOK_ADDIN_MANIFEST_ID,
    SALES_COPILOT_METAOS_ADDIN_IDS,
} from './constants/copilotAddinIds';
export { Scenario } from './services/types/serviceTypes';

export { getAddinSettingsForScenario } from './selectors/getAddinSettingsForScenario';
export { getTenantDefaultAddin } from './selectors/getTenantDefaultAddin';
export { isOldModelAddin } from './selectors/isOldModelAddin';
export { isScenarioExtensible } from './utils/isScenarioExtensible';
export { isSalesLicensePresent } from './selectors/isSalesLicensePresent';
