import { LazyModule, createLazyComponent, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MonarchWin32Toggle" */ './lazyIndex')
);

export const MonarchWin32Toggle = createLazyComponent(lazyModule, m => m.MonarchWin32Toggle);

export const lazyIsToggleEnabled = new LazyAction(lazyModule, m => m.isToggleEnabled);

export const lazyUntoggleAndProvideFeedbackIfSupported = new LazyAction(
    lazyModule,
    m => m.untoggleAndProvideFeedbackIfSupported
);
