import type { FolderPermissionsStore } from './schema/FolderPermissionsStore';
import { createStore } from 'satcheljs';

/**
 * Selector to get UI store for folder permissions package
 */
const store = createStore<FolderPermissionsStore>('folderPermissionsStore', {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (11,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    folderId: null,
    permissions: [],
    selectedPermissionIndex: 0,
    showAddUserDialog: false,
    showFolderPermissionsDialog: false,
    showLoader: false,
    shouldDisableDefaultPermissions: false,
})();
export default store;
export const getStore = () => store;
