import { flexPaneOpened } from 'owa-suite-header-apis';
import { orchestrator } from 'satcheljs';
import { getPinnablePaneByFlexPaneId } from '../utils/getPinnablePaneRegistrations';
import { isPanePinningEnabled } from '../utils/isPanePinningEnabled';
import { savePinnedPaneSetting } from '../utils/savePinnedPaneSetting';

// if opened flex pane is a pinnable pane, silently mark the pane as pinned in user settings
orchestrator(flexPaneOpened, ({ flexPaneId }) => {
    if (!isPanePinningEnabled()) {
        return;
    }

    const pinnablePane = getPinnablePaneByFlexPaneId(flexPaneId);
    if (!!pinnablePane) {
        savePinnedPaneSetting(pinnablePane);
    }
});
