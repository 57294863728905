import mailGroupFilesRouteHandler from './mailGroupFilesRouteHandler';
import { mailSxSCleanupRouteHandler } from './mailSxSRouteHandler';
import type { MailGroupRouteParameters } from './utils/mailGroupRouteSettings';
import { resetSxSViewStore } from 'owa-groups-recent-files-store/lib/lazyFunctions';

export function mailGroupFilesSPCleanupRouteHandler(
    _oldRoute?: string[],
    _oldParameters?: any,
    _newRoute?: string[],
    _newParameters?: any
): boolean {
    resetSxSViewStore('browserNavigation');
    return false;
}

export async function mailGroupFilesSxSCleanupRouteHandler() {
    return mailSxSCleanupRouteHandler();
}

export async function mailGroupFilesSXSNavigationRouteHandler(
    parameters: MailGroupRouteParameters,
    _allParameters?: string[] | undefined
) {
    await mailGroupFilesRouteHandler(parameters, undefined);
}
