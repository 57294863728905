import { ThemeConstants } from 'owa-theme-shared';

const modernImageThemes = [
    ThemeConstants.MODERN_ARCTIC_SOLITUDE_THEME_ID,
    'natureundefined',
    'readyconfetti',
    'magneticmood',
    'futureplus',
    'ribbonrelease',
];

const modernColorThemes = [
    ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID,
    'neworange',
    'red',
    'green',
    'purple',
    'newpink',
];

export const getModernThemes = () => [...modernImageThemes, ...modernColorThemes];

export const getModernImageThemes = () => modernImageThemes;

export const getModernColorThemes = () => modernColorThemes;
