import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

const updateCategorySortCategoryForRowKey = mutatorAction(
    'updateCategorySortCategoryForRowKeyAction',
    (mailboxInfo: MailboxInfo, rowKey: string, category: string) => {
        getStore(mailboxInfo).categoryByRowKey.set(rowKey, category);
    }
);

export default updateCategorySortCategoryForRowKey;
