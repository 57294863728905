import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostState"*/ './lazyIndex')
);

export const lazyInitializeSharePointSiteInfo = new LazyAction(
    lazyModule,
    m => m.intializeSharePointSiteInfo
);

export const lazyOpenAppStoreModal = new LazyAction(lazyModule, m => m.openAppStoreModal);
export const lazyCloseAppStoreModal = new LazyAction(lazyModule, m => m.closeAppStoreModal);
