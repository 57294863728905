import type {
    MutationUpdateUserConfigurationArgs,
    UserConfiguration,
    UpdateUserConfigurationInput,
    ReactListViewType,
    UserConfigurationArgs,
    KeyboardShortcutsMode,
} from 'owa-graph-schema';
import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';
import type { default as OwsReactListViewType } from 'owa-service/lib/contract/ReactListViewType';
import type { default as UserConfigurationDictionaryObjectType } from 'owa-service/lib/contract/UserConfigurationDictionaryObjectType';
import type { default as OwaViewStateConfiguration } from 'owa-service/lib/contract/OwaViewStateConfiguration';
import type { default as UserOptionsType } from 'owa-service/lib/contract/UserOptionsType';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import { type default as OwsKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';
import { assertNever } from 'owa-assert';

import { getServiceType } from './utils/getServiceType';
import { mapSettingKeysToService } from './utils/mapSettingKeysToService';

export const updateUserConfigurationWeb = async (
    _parent: any,
    args: MutationUpdateUserConfigurationArgs,
    _context: any,
    _info: any
): Promise<UserConfiguration> => {
    const input: UpdateUserConfigurationInput = args.input;

    // Update service
    await Promise.all(
        Object.entries(input.updates).map(
            ([config, values]: [keyof UserConfigurationArgs, any]) => {
                const serviceUpdates = Object.entries(values).map(([setting, value]) => {
                    let valuetype = getServiceType(value) as UserConfigurationDictionaryObjectType;

                    // Convert the GQL type (string enum) to OWS list view type (integer)
                    if (setting === 'GlobalListViewTypeReact') {
                        value = convertToOwsListViewType(value);
                        valuetype = 'Integer32';
                    }
                    // Convert the GQL type (string) to OWS density mode (integer)
                    if (setting === 'DisplayDensityMode') {
                        value = densityOptionSelectedValue(value);
                        valuetype = 'Integer32';
                    }
                    // Convert the GQL type (string enum) to OWS KeyboardShortcutsMode (integer)
                    if (setting === 'KeyboardShortcutsMode') {
                        value = convertToOwsKeyboardShortcutsMode(value);
                        valuetype = 'Integer32';
                    }

                    if (valuetype !== 'StringArray') {
                        value = [`${value}`];
                    }

                    // Transform updates to service data structure
                    return {
                        key: mapSettingKeysToService(setting) as
                            | keyof OwaViewStateConfiguration
                            | keyof UserOptionsType,
                        valuetype,
                        value,
                    };
                });

                return updateUserConfigurationService(serviceUpdates, `OWA.${config}`, {
                    mailboxInfo: input.mailboxInfo,
                });
            }
        )
    );

    return {
        id: input.mailboxInfo.userIdentity,
        ...input.updates,
        __typename: 'UserConfiguration',
    };
};

function convertToOwsListViewType(input: ReactListViewType): OwsReactListViewType {
    switch (input) {
        case 'Conversation':
            return 0;
        case 'Message':
            return 1;
        case 'CalendarItems':
            return 2;
        default:
            return assertNever(input);
    }
}

function densityOptionSelectedValue(densityOptionSelected: DisplayDensityMode) {
    switch (densityOptionSelected) {
        case 'Simple':
            return 1;
        case 'Compact':
            return 2;
        case 'Full':
        default:
            return 0;
    }
}

function convertToOwsKeyboardShortcutsMode(input: KeyboardShortcutsMode): OwsKeyboardShortcutsMode {
    switch (input) {
        case 'Hotmail':
            return 0;
        case 'Off':
            return 1;
        case 'Yahoo':
            return 2;
        case 'Gmail':
            return 3;
        case 'Owa':
            return 4;
    }
}
