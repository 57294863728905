import { getItem, setItem } from 'owa-local-storage';

const localStorageKeys = {
    OwaSessionCount: 'OwaSessionCount',
    NpsDimissCount: 'NpsDismissCount',
};

/**
 * Internal usage
 * Owa session count
 */
export function getandIncreaseSessionCount(): number {
    const sessionCount = getSessionCount();

    setItem(self, localStorageKeys.OwaSessionCount, (sessionCount + 1).toString());

    return sessionCount;
}

export function getSessionCount(): number {
    return parseInt(getItem(self, localStorageKeys.OwaSessionCount) ?? '0');
}

/**
 * Internal usage
 * Nps Dismiss count
 */
export function getNpsDismissCount(): number {
    return parseInt(getItem(self, localStorageKeys.NpsDimissCount) ?? '0');
}

export function incrementNpsDismissCount(): void {
    const npsDismissCount = getNpsDismissCount();

    setItem(self, localStorageKeys.NpsDimissCount, (npsDismissCount + 1).toString());
}
