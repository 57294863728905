import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import virtualizedMailGroupHeadersStore from '../store/store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import type { TableView } from 'owa-mail-list-store';

// Gets a flat array of item ids where status is not collapsed.
export default function getRowKeysForRenderFromVirtualizedGroupHeaders(
    tableView: TableView,
    tableSupportsPinning: boolean
) {
    const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;
    const rowKeysToRender: string[] = [...tableView.rowKeys];
    // Looks for item ids where the state of corresponding header is collapsed and removes it from the list of row keys to be rendered
    groupHeadersFromStore.forEach((header: VirtualizedGroupHeader) => {
        if (header.isCollapsed) {
            header.rowKeys.forEach((rowKey: string) => {
                const itemIndex = rowKeysToRender.indexOf(rowKey);
                const isItemPinned =
                    MailRowDataPropertyGetter.getIsPinned(rowKey, tableView) &&
                    tableSupportsPinning;
                if (itemIndex > -1 && !isItemPinned) {
                    rowKeysToRender.splice(itemIndex, 1);
                }
            });
        }
    });
    return rowKeysToRender; // Array of row keys to be rendered.
}
