import setTableIsInCheckedMode from './mutators/setTableIsInCheckedMode';
import updateVirtualSelectAllExclusionList from './mutators/updateVirtualSelectAllExclusionList';
import type { TableView } from 'owa-mail-list-store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { setSelectionOnRow } from 'owa-mail-actions/lib/setSelectionOnRow';
import updateCheckedStateOfConversationChildren from '../mutators/updateCheckedStateOfConversationChildren';
import { addCustomDataToSelectMailItemDatapoint } from '../utils/selectMailItemDatapointUtils';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function toggleSelectRowInternal(
    tableView: TableView,
    rowKey: string,
    mailListItemSelectionSource: MailListItemSelectionSource
): void {
    const isCheckboxSelect =
        mailListItemSelectionSource === MailListItemSelectionSource.MailListItemCheckbox;
    const isTargetRowSelected = tableView.selectedRowKeys.has(rowKey);

    // If this was a select on an existing major row
    // just enter checked mode and set all conversation children as checked.
    // No other selection state should change.
    if (isTargetRowSelected && !tableView.isInCheckedMode) {
        setTableIsInCheckedMode(tableView, true);
        updateCheckedStateOfConversationChildren(tableView, rowKey, true);
        return;
    }

    // Invalidate select mail item since there is no RP load if already in checked mode:
    if (tableView.isInCheckedMode) {
        endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
    } else {
        // Add custom data to selectMailItem datapoint
        addCustomDataToSelectMailItemDatapoint(mailListItemSelectionSource, tableView, rowKey);
    }

    const hasOneSelection = tableView.selectedRowKeys.size == 1;

    // If the table currently has a single item selected (and non-checked) and we're clicking checkbox to select
    // another item, we want to unselect the existing selected item first.
    if (hasOneSelection && !tableView.isInCheckedMode && !isTargetRowSelected && isCheckboxSelect) {
        setSelectionOnRow(
            [...tableView.selectedRowKeys.keys()][0],
            tableView.id,
            false /* shouldSelect */
        );
    }
    // Proceed with toggling selection on the target row
    const shouldSelect = !isTargetRowSelected;

    // If only the target row is selected and is checked, clicking on the same row should uncheck it, but keep the selection
    if (
        isFeatureEnabled('tri-reselect-checkbox-behavior') &&
        hasOneSelection &&
        isTargetRowSelected &&
        tableView.isInCheckedMode &&
        mailListItemSelectionSource != MailListItemSelectionSource.MailListItemTwisty &&
        mailListItemSelectionSource != MailListItemSelectionSource.MailListItemCheckbox
    ) {
        setTableIsInCheckedMode(tableView, false);
        updateCheckedStateOfConversationChildren(tableView, rowKey, shouldSelect);
        return;
    }

    if (shouldSelect && !tableView.isInCheckedMode) {
        setTableIsInCheckedMode(tableView, true);
    }

    if (tableView.isInVirtualSelectAllMode) {
        updateVirtualSelectAllExclusionList(tableView, rowKey);
    } else {
        setSelectionOnRow(rowKey, tableView.id, shouldSelect /* shouldSelect */);
    }
    updateCheckedStateOfConversationChildren(tableView, rowKey, shouldSelect);
}
