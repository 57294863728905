import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';

export interface FocusedItemPartState {
    conversationReadingPaneState: ConversationReadingPaneViewState;
}

export function getFocusedItemPart(
    state: FocusedItemPartState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(),
    }
): ItemPartViewState | undefined {
    return state.conversationReadingPaneState?.focusedItemPart
        ? state.conversationReadingPaneState.focusedItemPart.itemPart
        : undefined;
}

export function getFocusedItemArea(
    state: FocusedItemPartState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(),
    }
): FocusedItemArea | undefined {
    return state.conversationReadingPaneState?.focusedItemPart
        ? state.conversationReadingPaneState.focusedItemPart.focusedItemArea
        : undefined;
}

export const getInitiallySelectedItemPart = function getInitiallySelectedItemPart(
    state: FocusedItemPartState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(),
    }
): ItemPartViewState | undefined {
    return state.conversationReadingPaneState?.initiallySelectedItemPart
        ? state.conversationReadingPaneState.initiallySelectedItemPart.itemPart
        : undefined;
};

export const isItemPartSelected = function isItemPartSelected(
    itemPart?: ItemPartViewState,
    state: FocusedItemPartState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(),
    }
): boolean {
    const selectedItemPart = getFocusedItemPart(state);
    const selectedItemArea = getFocusedItemArea(state);
    return selectedItemPart == itemPart && selectedItemArea == FocusedItemArea.Item;
};
