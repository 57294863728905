import { observer } from 'owa-mobx-react';
import { useComputed } from 'owa-react-hooks/lib/useComputed';
import { unreadCountScreenReaderOnlyText } from 'owa-locstrings/lib/strings/unreadcountscreenreaderonlytext.locstring.json';
import loc from 'owa-localize';
import type { FavoriteNodeCommonProps } from './FavoriteNode';
import { AnimationClassNames } from '@fluentui/style-utilities';
import { logUsage } from 'owa-analytics';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type DropViewState from 'owa-dnd/lib/store/schema/DropViewState';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import folderStore, { buildUnreadTotalCountTooltip } from 'owa-folders';
import { showFavoritesContextMenu } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET } from 'owa-mail-folder-view';
import { getAnchorForContextMenu } from 'owa-positioning';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { isGuid } from 'owa-guid';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import {
    lazyMarkFavoritePersonaNodeAsAdded,
    isFavoritingInProgress,
} from 'owa-mail-favorites-store';
import { lazySelectPersona } from 'owa-mail-folder-forest-actions';
import { UnreadReadCountBadge } from 'owa-unreadread-count-badge';
import { removeFavoritePersona } from '../util/removeFavorite';
import PersonRegularV8 from 'owa-fluent-icons-svg/lib/icons/PersonRegular';
import { PersonRegular, PersonFilled, bundleIcon } from '@fluentui/react-icons';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';
import type { MailboxInfo } from 'owa-client-types';
import { SourceMap } from 'owa-folders-constants';
import { isFeatureEnabled } from 'owa-feature-flags';

import classnames from 'owa-classnames';

const Person = bundleIcon(PersonFilled, PersonRegular);

export interface FavoritePersonaNodeProps extends FavoriteNodeCommonProps {
    displayName: string;
    emailAddress: string;
    personaId: string;
    searchFolderId: string;
    dropViewState: DropViewState;
    isJustAdded: boolean;
    isSearchFolderPopulated: boolean;
    mailboxInfo: MailboxInfo;
    ellipsesOnHover?: boolean;
}

import {
    newCount as styles_newCount,
    unreadCount as styles_unreadCount,
    addedNode,
    dragPreview,
} from './FavoriteNode.scss';

export default observer(function FavoritePersonaNode(props: FavoritePersonaNodeProps) {
    React.useEffect(() => {
        markNodeAsAdded();
    }, []);
    const isSelected = useComputed((): boolean => {
        return props.favoriteId === getSelectedNode().id;
    });
    const isSearchFolderReady = (): boolean => {
        return props.isSearchFolderPopulated && folderStore.folderTable.has(props.searchFolderId);
    };
    const unreadCount = (): number => {
        if (isSearchFolderReady()) {
            const folder = folderStore.folderTable.get(props.searchFolderId);
            return folder ? folder.UnreadCount : 0;
        }
        return 0;
    };
    const totalCount = (): number => {
        if (isSearchFolderReady()) {
            const folder = folderStore.folderTable.get(props.searchFolderId);
            return folder ? folder.totalMessageCount : 0;
        }
        return 0;
    };
    const markNodeAsAdded = (): void => {
        if (props.isJustAdded) {
            setTimeout(() => {
                lazyMarkFavoritePersonaNodeAsAdded.importAndExecute(props.favoriteId);
            }, 666 /* animation duration */);
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const renderUnreadOrNewCount = (): JSX.Element | null => {
        let newCount;
        let folderUnreadCount = 0;
        if (isSearchFolderReady()) {
            const folder = folderStore.folderTable.get(props.searchFolderId);
            folderUnreadCount = folder?.UnreadCount || 0;
        }
        if (folderUnreadCount === 0 && !newCount) {
            return null;
        }
        return (
            <UnreadReadCountBadge
                count={newCount || folderUnreadCount}
                screenReaderText={loc(unreadCountScreenReaderOnlyText)}
                customStyle={newCount ? styles_newCount : styles_unreadCount}
                isSelected={isSelected.get()}
            />
        );
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onPersonaSelected = (evt: React.MouseEvent<EventTarget>) => {
        evt.stopPropagation();
        const idToLog = isGuid(props.favoriteId) ? props.favoriteId : '';
        logUsage('PersonaClicked', [idToLog, unreadCount()]);
        lazySelectPersona.importAndExecute(props.favoriteId);
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onContextMenu = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        const isInProgress =
            isFavoritingInProgress(props.personaId) || isFavoritingInProgress(props.emailAddress);
        if (!isInProgress) {
            showFavoritesContextMenu(
                props.favoriteId,
                1,
                getAnchorForContextMenu(evt),
                props.searchFolderId
            );
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const canDrop = (dragData: DragData) => {
        switch (dragData.itemType) {
            case DraggableItemTypes.FavoriteNode:
                return canDropFavorite(dragData, props.mailboxInfo);
            case DraggableItemTypes.MailFolderNode:
                return canDropFolder(dragData, props.mailboxInfo);
            default:
                return false;
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDrop = async (dragData: DragData) => {
        if (!canDrop(dragData)) {
            return;
        }
        const itemType = dragData.itemType;
        switch (itemType) {
            case DraggableItemTypes.MailFolderNode:
                {
                    await onDropFolder(dragData, props.mailboxInfo, props.favoriteId);
                }
                break;
            case DraggableItemTypes.FavoriteNode:
                {
                    await onDropFavorite(dragData, props.favoriteId);
                }
                break;
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const getDragData = () => {
        const folderNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId: props.favoriteId,
            favoriteType: 1,
            displayName: props.displayName,
            itemData: {
                mailboxInfo: props.mailboxInfo,
            },
        };
        return folderNodeDragData;
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const toggleFavorite = () => {
        removeFavoritePersona(props.favoriteId);
    };
    return (
        <DragAndDroppable
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={props.dropViewState}
            onDrop={onDrop}
            canDrop={canDrop}
        >
            <div
                className={
                    props.isJustAdded
                        ? classnames(AnimationClassNames.slideDownIn20, addedNode)
                        : ''
                }
            >
                <TreeNode
                    customIcon={PersonRegularV8}
                    customIconComponent={Person}
                    displayName={props.displayName}
                    key={props.favoriteId}
                    isDroppedOver={props.dropViewState.isDragOver}
                    isRootNode={false}
                    isSelected={isSelected.get()}
                    onClick={onPersonaSelected}
                    onContextMenu={onContextMenu}
                    renderRightCharm={renderUnreadOrNewCount}
                    showAsHoverOnDroppedOver={false}
                    isFavorited={true}
                    toggleFavorite={toggleFavorite}
                    customTreeNodeTooltip={buildUnreadTotalCountTooltip(
                        props.displayName,
                        totalCount(),
                        unreadCount()
                    )}
                    ellipsesOnHover={props.ellipsesOnHover}
                    source={SourceMap.favorite}
                    shouldShowFocusBorder={isFeatureEnabled('fp-jump-folder')}
                />
            </div>
        </DragAndDroppable>
    );
}, 'FavoritePersonaNode');

function getDragPreview(dragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (dragData as FavoriteNodeDragData)?.displayName ?? '';
    return elem;
}
