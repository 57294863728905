import isGlobalStartupComplete from './isGlobalStartupComplete';
import {
    BootState,
    getCoprincipalAccounts,
    onCoprincipalBootStateChanged,
    onPreCoprincipalAccountRemoved,
} from 'owa-account-source-list-store';
import { orchestrator } from 'satcheljs';
import type { OrchestratorFunction } from 'satcheljs';
import type { AccountSource } from 'owa-account-source-list-store';

/**
 * Callback used when an account reaches pre-render complete state
 */
export type AccountStateChangeCallback = (accountSource: AccountSource) => void;

/**
 * Callback function used to filter accounts
 */
export type AccountFilterCallback = (accountSource: AccountSource) => boolean;

/**
 * Interface used to dispose of an registration for account state change
 */
export interface DisposeAccountStateChangeRegisteration {
    (): void;
}

/**
 * Internal object used to handle changes to state
 */
interface StateChangeObject {
    // Returns true is the change should be processed
    shouldProcessChange: () => boolean;

    // Caller supplied callback for when an account is ready to be used
    accountStartupComplete?: AccountStateChangeCallback;

    // Caller supplied callback for when an account is removed
    preAccountRemoved?: AccountStateChangeCallback;

    // Caller supplied callback that filters the types of accounts
    accountFilter?: AccountFilterCallback;

    // Tracks when new accounts are added
    onCoprincipalAccountAddedOrchestrator?: OrchestratorFunction<{
        updatedAccount: AccountSource;
        newBootState: BootState;
    }>;

    // Tracks when accounts are removed
    onPreCoprincipalAccountRemovedOrchestrator?: OrchestratorFunction<{
        removedAccount: AccountSource;
    }>;
}

/**
 * Registers callbacks that will be notified when the state of any accounts changes. This allows
 * the caller to run code when any account's pre-render is complete, or when any account is removed.
 * Additionally, any accounts whose pre-render completed before this method is called will also be
 * returned via the callbacks.
 *
 * @param accountStartupComplete Callback for accounts completing the pre-render process. This includes
 * when the global settings account completes it's startup process. Or in other words this callback will
 * be called for all accounts including any that are currently setup.
 * @param preAccountRemoved Callback for when accounts are removed
 * @returns object that can be used to dispose of the callback registration
 */
export default function RegisterForAccountStateChange(
    accountStartupComplete: AccountStateChangeCallback,
    preAccountRemoved: AccountStateChangeCallback,
    accountFilter?: AccountFilterCallback
): DisposeAccountStateChangeRegisteration {
    const stateChange: StateChangeObject = {
        shouldProcessChange: () => true,
        accountStartupComplete,
        preAccountRemoved,
        accountFilter,
    };

    // Raise the callback for all the coprincipal accounts that are currently in the pre-render complete state
    const callForInitialAccounts = () => {
        getCoprincipalAccounts().forEach(account => {
            if (
                account.bootState === BootState.StartupComplete &&
                // stateChange.accountStartupComplete will be undefined if DisposeAccountStateChangeRegisteration has been called
                stateChange.accountStartupComplete &&
                (!stateChange.accountFilter || stateChange.accountFilter(account))
            ) {
                stateChange.accountStartupComplete(account);
            }
        });
    };

    if (isGlobalStartupComplete()) {
        callForInitialAccounts();
    } else {
        // Delay the call for initial accounts until the global account's startup work is complete
        stateChange.shouldProcessChange = () => {
            if (isGlobalStartupComplete()) {
                // processs the accounts and let future calls process changes
                callForInitialAccounts();
                stateChange.shouldProcessChange = () => true;
            }

            // We either just processed the change above or still need to wait
            return false;
        };
    }

    // Setup the orchestrator so the callback is called when new accounts are pre render compelte
    stateChange.onCoprincipalAccountAddedOrchestrator = orchestrator(
        onCoprincipalBootStateChanged,
        (args: { updatedAccount: AccountSource; newBootState: BootState }) => {
            if (
                stateChange.shouldProcessChange() &&
                // stateChange.accountStartupComplete will be undefined if DisposeAccountStateChangeRegisteration has been called
                stateChange.accountStartupComplete &&
                isGlobalStartupComplete() &&
                args.newBootState == BootState.StartupComplete &&
                (!stateChange.accountFilter || stateChange.accountFilter(args.updatedAccount))
            ) {
                stateChange.accountStartupComplete(args.updatedAccount);
            }
        }
    );

    // Setup the orchestrator to be notified when an account is removed
    stateChange.onPreCoprincipalAccountRemovedOrchestrator = orchestrator(
        onPreCoprincipalAccountRemoved,
        (args: { removedAccount: AccountSource }) => {
            if (
                // stateChange.preAccountRemoved will be undefined if DisposeAccountStateChangeRegisteration has been called
                stateChange.preAccountRemoved &&
                isGlobalStartupComplete() &&
                (!stateChange.accountFilter || stateChange.accountFilter(args.removedAccount))
            ) {
                stateChange.preAccountRemoved(args.removedAccount);
            }
        }
    );

    return () => {
        // Allow the caller to stop getting callbacks by calling the returned DisposeAccountStateChangeRegisteration
        // instance, which sets all values to undefined
        stateChange.accountStartupComplete = undefined;
        stateChange.preAccountRemoved = undefined;
        stateChange.accountFilter = undefined;
        stateChange.onCoprincipalAccountAddedOrchestrator = undefined;
        stateChange.onPreCoprincipalAccountRemovedOrchestrator = undefined;
    };
}
