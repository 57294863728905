import { observer } from 'owa-mobx-react';
import { FluentMenu } from 'owa-fluent-v9-shims';
import React from 'react';
import { hideAppContextMenu } from '../actions/setAppContextMenuProps';
import getAppContextMenuStateStore from '../store/store';

/**
 * AppContextMenu only works in Monarch
 */
function AppContextMenu() {
    const store = getAppContextMenuStateStore();

    return (
        <FluentMenu
            items={store.contextualMenuItems}
            hidden={!store.showContextMenu}
            target={store.point}
            onItemClick={hideAppContextMenu}
            onDismiss={hideAppContextMenu}
        />
    );
}

export default observer(AppContextMenu, 'AppContextMenu');
