import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppBarControlQueryWeb" */ './lazyIndex')
);

export const lazyAppBarControlM365AppsQueryWeb = createLazyResolver(
    'APPBARCONTROL_M365APPS_QUERY_WEB',
    lazyModule,
    m => m.appBarControlM365AppsQueryWeb
);

export const lazyMessageExtensionsQueryWeb = createLazyResolver(
    'APPBARCONTROL_MESSAGE_EXTENSIONS_QUERY_WEB',
    lazyModule,
    m => m.messageExtensionsQueryWeb
);

export const lazyControlModelQueryWeb = createLazyResolver(
    'APPBAR_CONTROL_MODEL_QUERY_WEB',
    lazyModule,
    m => m.controlModelQueryWeb
);
