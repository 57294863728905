import type { AttachmentTypeUnion, ProviderType } from 'owa-graph-schema';
import type AttachmentType from 'owa-service/lib/contract/AttachmentType';
import type ReferenceAttachmentType from 'owa-service/lib/contract/ReferenceAttachment';
import { REFERENCE_ATTACHMENT_TYPE } from 'owa-attachment-constants/lib/attachmentTypes';

export const convertAttachmentToGql = (
    serviceAttachment: AttachmentType | ReferenceAttachmentType
): AttachmentTypeUnion => {
    if (!serviceAttachment || !serviceAttachment.__type) {
        return serviceAttachment as AttachmentTypeUnion;
    }

    switch (serviceAttachment.__type) {
        case REFERENCE_ATTACHMENT_TYPE: {
            return {
                ...(serviceAttachment as typeof serviceAttachment & {
                    ProviderType: ProviderType;
                    AttachmentThumbnailUrl: string;
                    AttachmentPreviewUrl: string;
                }),
                OwsTypeName: serviceAttachment.__type,
                __typename: 'ReferenceAttachment',
            };
        }
        default: {
            return {
                ...serviceAttachment,
                OwsTypeName: serviceAttachment.__type,
                __typename: 'BasicAttachment',
            };
        }
    }
};
