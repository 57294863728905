import { ApolloLink, Observable } from '@apollo/client';
import type { DataSource } from 'owa-analytics-types';
import type { Resolvers, ResolverContext } from 'owa-graph-schema';
import { lazyExecute, lazySubscribe } from 'owa-apollo-execute';
import { asyncIteratorToObservable } from '../util/asyncIteratorToObservable';
import { getOperationType } from '../util/getOperationType';

export default function executeGraphLink(
    resolvers: Resolvers,
    globalContext: any,
    dataSource?: DataSource
) {
    const link = new ApolloLink(operation => {
        if (getOperationType(operation) === 'subscription') {
            return asyncIteratorToObservable(() =>
                lazySubscribe.importAndExecute(resolvers, operation, globalContext)
            );
        }

        return new Observable(observer => {
            lazyExecute
                .importAndExecute(resolvers, operation, globalContext)
                .then(result => {
                    if (dataSource) {
                        const context = operation.getContext() as ResolverContext;
                        const perfDatapoint = context?.perfDatapoint;
                        if (perfDatapoint) {
                            perfDatapoint.dataSource = dataSource;
                        }
                    }
                    observer.next(result);
                    observer.complete();
                })
                .catch(e => {
                    observer.error(e);
                });
        });
    });

    return link;
}
