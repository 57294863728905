import onSelectionChange from '../events/onSelectionChange';
import getNextRowIndexInternal from '../internal/getNextRowIndexInternal';
import singleSelectRowInternal from '../internal/singleSelectRowInternal';

import {
    selectRowInDirection,
    setExpansionForRow,
    onAfterSelectionChanged,
} from 'owa-mail-actions/lib/mailListSelectionActions';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import { orchestrator } from 'satcheljs';
import { createSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointUtils';

/////////////////////////////////////// PLEASE READ ///////////////////////////////////////////////////////////
// This is a top level selection action, as such, it should never call other top-level selection
// actions in the same directory, but only call into internal subdirectories where the core logic is implemented.
// This prevents double logging for CTQs as well as prevents onSelectionChange from being fired multiple times
// for a single user action.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default <(actionMessage: ReturnType<typeof selectRowInDirection>) => void | Promise<void>>(
    orchestrator(selectRowInDirection, actionMessage => {
        const { tableView, selectionDirection, mailListItemSelectionSource } = actionMessage;
        createSelectMailItemDatapoint(
            mailListItemSelectionSource,
            tableView,
            undefined /* rowKey */,
            selectionDirection
        );
        // Before resetting selection, need to store currently selected row key to be marked as read
        const oldSelectedRowKey =
            tableView.selectedRowKeys.size == 1
                ? [...tableView.selectedRowKeys.keys()][0]
                : undefined;

        const rowIndexToSelect = getNextRowIndexInternal(tableView, selectionDirection);

        // We should check if rowIndexToSelect is within valid range
        // when user delete/move messages from Junk Mail folder, the focus was set to maillist container,
        // which caused the rowIndexToSelect becoming invalid
        if (rowIndexToSelect !== null && rowIndexToSelect >= 0) {
            const rowKey = tableView.rowKeys[rowIndexToSelect];
            // Mark the previously selected row as read
            if (oldSelectedRowKey && rowKey !== oldSelectedRowKey) {
                lazyOnSingleSelectionChanged.importAndExecute(
                    oldSelectedRowKey,
                    tableView,
                    true /* isUserNavigation */
                );
            }

            setExpansionForRow(rowKey, mailListItemSelectionSource);

            singleSelectRowInternal(tableView, rowKey, mailListItemSelectionSource);
        }

        // Propagate selection change event
        return onSelectionChange(
            tableView,
            true /* isUserNavigation */,
            mailListItemSelectionSource
        ).then(() => {
            if (rowIndexToSelect !== null && rowIndexToSelect >= 0) {
                onAfterSelectionChanged(
                    tableView.rowKeys[rowIndexToSelect],
                    tableView,
                    mailListItemSelectionSource
                );
            }
        });
    })
);
