import {
    errorThatWillCauseAlert,
    errorThatWillCauseAlertAndThrow,
    type TraceErrorObject,
} from 'owa-trace';
import type {
    AccountActionOrigin,
    AccountSourceType,
    AccountSourceProtocolData,
} from 'owa-account-source-list-types';
import {
    getIndexerValueForMailboxInfo,
    type MailboxInfo,
    defaultIndexerValue,
} from 'owa-client-types';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { CreateAccountErrors, type CreateAccountResult, type RemoveAccountResult } from '../types';
import {
    accountRankTypeChecker,
    getCoprincipalAccountForIndexerValue,
    getAccountBySourceId,
} from 'owa-account-source-list-store';
import type { AuthUXCallbacks } from 'owa-auth-callbacks';
import type { IdentityInfo } from 'owa-auth-types';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';

/**
 * The interface that defined the roaming metadata
 */
export interface RoamingMetadata {
    uuid: string;
    mailboxType: AccountSourceType;
    smtpAddress: string;
    userIdentity: string;
}

/**
 * Defines the interface that the hosting application must implement
 */
export interface PluggableHostAccountSource {
    addM365Account?: (
        correlationId: string,
        email: string,
        userIdentity: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks,
        isAnotherMailbox?: boolean
    ) => Promise<CreateAccountResult>;
    addOutlookDotComAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addGoogleAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addYahooAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addImapAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addPop3Account?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addICloudAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addPstFileAccount?: (
        correlationId: string,
        filepath: string,
        displayName: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    removeAccount?: (sourceId: string, origin: AccountActionOrigin) => Promise<RemoveAccountResult>;
    removePendingAccount?: (
        emailAddress: string,
        origin: AccountActionOrigin
    ) => Promise<RemoveAccountResult>;
    disablePendingAccount?: (
        emailAddress: string,
        origin: AccountActionOrigin
    ) => Promise<RemoveAccountResult>;
    createAndAddOutlookDotComAccount?: (
        correlationId: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    setGlobalSettingsAccountAndRunOOBE?: (
        sourceId: string,
        origin: AccountActionOrigin
    ) => Promise<boolean>;
    syncRoamingMetadata?: (data: RoamingMetadata[]) => void;
    // The getTokenForIdentity function is used when it is necessary to get a token
    // for an M365 identity for which there is not an account configured.
    getAuthTokenForIdentityInfo?: (
        identityInfo: IdentityInfo,
        headers?: HeadersWithoutIterator,
        isInteractive?: boolean,
        isFirstUsage?: boolean
    ) => Promise<string | undefined>;
}

// The pluggable host instance
let thePluggableHost: PluggableHostAccountSource = {};

/**
 * Sets the pluggable host
 * @param host provides the host specific implementation for getting account information
 */
export function updatePluggableHost(host?: Partial<PluggableHostAccountSource>) {
    thePluggableHost = { ...thePluggableHost, ...host };
}

/**
 * Restores the pluggable host to the default pluggable host
 */
export function testHookToResetPluggableHost() {
    thePluggableHost = {};
}

/**
 * Adds a Microsoft 365 enterprise account
 * @param email Email address of the mailbox to be connected to
 * @param userIdentity The user principal named that identifies how we will be authenticating
 * @param origin The source of the request to add the Microsoft 365 account
 * @returns The result of creating the account
 */
export async function addM365Account(
    correlationId: string,
    email: string,
    userIdentity: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks,
    isAnotherMailbox?: boolean
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addM365Account) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddM365Account');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addM365Account!(
        correlationId,
        email,
        userIdentity,
        origin,
        authUxCallbacks,
        isAnotherMailbox
    );
}

export async function addOutlookDotComAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addOutlookDotComAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddOutlookDotComAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addOutlookDotComAccount!(correlationId, email, origin, authUxCallbacks);
}

export async function addGoogleAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addGoogleAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddGoogleAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addGoogleAccount!(correlationId, email, origin, authUxCallbacks);
}

export async function addYahooAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addYahooAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddYahooAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addYahooAccount!(correlationId, email, origin, authUxCallbacks);
}

export async function addImapAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addImapAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddImapAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addImapAccount!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addPop3Account(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addPop3Account) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddPop3Account');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addPop3Account!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addICloudAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addICloudAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddiCloudAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addICloudAccount!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addPstFileAccount(
    correlationId: string,
    filepath: string,
    displayName: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addPstFileAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddPstFileAccount');
    }

    return (
        thePluggableHost?.addPstFileAccount(
            correlationId,
            filepath,
            displayName,
            origin,
            authUxCallbacks
        ) ??
        Promise.resolve({
            wasSuccessful: false,
            error: {
                error: CreateAccountErrors.ProviderNotSupported,
            },
        })
    );
}

export async function removeAccount(
    sourceId: string,
    origin: AccountActionOrigin
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.removeAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodRemoveAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.removeAccount!(sourceId, origin);
}

export async function removePendingAccount(
    emailAddress: string,
    origin: AccountActionOrigin
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.removePendingAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodRemoveAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.removePendingAccount!(emailAddress, origin);
}

export async function disablePendingAccount(
    emailAddress: string,
    origin: AccountActionOrigin
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.disablePendingAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodDisableAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.disablePendingAccount!(emailAddress, origin);
}

export async function createAndAddOutlookDotComAccount(
    correlationId: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.createAndAddOutlookDotComAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddOutlookDotComAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.createAndAddOutlookDotComAccount!(
        correlationId,
        origin,
        authUxCallbacks
    );
}

export function isSettingGlobalSettingsAccountAllowed(): boolean {
    return !!thePluggableHost.setGlobalSettingsAccountAndRunOOBE;
}

export function setGlobalSettingsAccountAndRunOOBE(
    sourceId: string,
    origin: AccountActionOrigin
): Promise<boolean> {
    if (thePluggableHost.setGlobalSettingsAccountAndRunOOBE) {
        return thePluggableHost.setGlobalSettingsAccountAndRunOOBE(sourceId, origin);
    }

    errorThatWillCauseAlert('NoPluggableMethodSetGlobalSettingsAccountAndRunOOBE');
    return Promise.resolve(false);
}

/**
 * When the account cannot be found when getting the persistence Id we raise an alert
 * and include diagnostic data about the MailboxInfo.
 * @param mailboxInfo MailboxInfo for which an account could not be found
 */
function alertOnAccountNotFound(mailboxInfo: MailboxInfo): void {
    const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
    const info = {
        mailboxType: mailboxInfo?.type,
        hasUserId: !!mailboxInfo?.userIdentity,
        hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
        rank: mailboxInfo?.mailboxRank,
        isAnonymous: mailboxInfo?.isAnonymous,
        isRemoved: mailboxInfo?.isRemoved,
        ...accountDiagnostic,
    };

    const error: TraceErrorObject = new Error('FailedToFindCoprincipalAccount');
    error.diagnosticInfo = JSON.stringify(info);
    errorThatWillCauseAlert(error);
}

export function getCoprincipalAccountPersistenceId(
    mailboxInfo: MailboxInfo,
    noAlertOnFailure?: boolean
): string {
    if (isPersistenceIdIndexerEnabled()) {
        // When the indexer is based on the persistenceId we can just get the indexer value
        const persistenceId = getIndexerValueForMailboxInfo(mailboxInfo);
        if (persistenceId !== defaultIndexerValue) {
            return persistenceId;
        }

        if (!noAlertOnFailure) {
            alertOnAccountNotFound(mailboxInfo);
        }

        return '';
    }

    // If sourceId (the key in the store) is present try and use it to find the coprincipal account
    if (!!mailboxInfo.sourceId) {
        const accountViaSourceId = getAccountBySourceId(mailboxInfo.sourceId);
        if (
            accountRankTypeChecker.isCoprincipal(accountViaSourceId) &&
            accountViaSourceId.persistenceId
        ) {
            return accountViaSourceId.persistenceId;
        }
    }

    // The second option is to use the indexer value to try and find the coprincipal account
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const account = getCoprincipalAccountForIndexerValue(indexer);
    if (account?.persistenceId) {
        return account.persistenceId;
    }

    if (!noAlertOnFailure) {
        alertOnAccountNotFound(mailboxInfo);
    }

    return '';
}

export function syncRoamingMetadata(data: RoamingMetadata[]): void {
    if (thePluggableHost.syncRoamingMetadata) {
        thePluggableHost.syncRoamingMetadata(data);
        return;
    }

    errorThatWillCauseAlert('NoPluggableMethodSyncRoamingMetadata');
}

export function getAuthTokenForIdentityInfo(
    identityInfo: IdentityInfo,
    headers?: HeadersWithoutIterator,
    isInteractive?: boolean,
    isFirstUsage?: boolean
): Promise<string | undefined> {
    if (!thePluggableHost.getAuthTokenForIdentityInfo) {
        errorThatWillCauseAlert('NoPluggableGetAuthTokenForIdentityInfo');
        return Promise.resolve(undefined);
    }

    return thePluggableHost.getAuthTokenForIdentityInfo(
        identityInfo,
        headers,
        isInteractive,
        isFirstUsage
    );
}
