import { createLazyResolver } from 'owa-lazy-resolver';

export const lazySetInferenceClassificationOnItemWeb = createLazyResolver(
    'MUTATION_SET_INFERENCE_CLASSIFICATION_ITEM_WEB',
    () =>
        import(
            /* webpackChunkName: "SetInferenceClassificationOnItemMutationWebResolver"*/ './setInferenceClassificationOnItemWeb'
        ),
    m => m.setInferenceClassificationOnItemWeb
);
