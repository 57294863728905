import isDeepLink from 'owa-url/lib/isDeepLink';
import { initTableSelectionOnLoad } from 'owa-mail-actions/lib/initTableSelectionOnLoad';
import type { TableView } from 'owa-mail-list-store';
import {
    getSelectedTableView,
    type LoadErrorStatus,
    type TableQueryType,
} from 'owa-mail-list-store';
import { onInitialTableLoadComplete as onInitialTableLoadCompleteAction } from 'owa-mail-triage-table-utils';
import { prefetchFirstN } from 'owa-mail-prefetch';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';

const FIRST_N_ROW_PREFETCH_COUNT = 10;

/**
 * Callback for table load complete
 * @param tableView the table view
 * @param isSuccessResponseClass indicates if the response is success
 * @param isTablePrefetched indicates if the table is prefetched
 */
export default action('onInitialTableLoadComplete')(function onInitialTableLoadComplete(
    tableView: TableView,
    isSuccessResponseClass: boolean,
    responseStatusMessage: string,
    isTablePrefetched: boolean
) {
    // Set loading state to complete
    tableView.isLoading = false;

    // To avoid making unnessessary service calls, only prefetch top N if the initial load has not already completed or if the previous load ended in error
    const shouldPrefetchTopN = !tableView.isInitialLoadComplete || tableView.loadErrorStatus != 0;

    tableView.isInitialLoadComplete = true;

    if (isTablePrefetched) {
        return;
    }

    // If the response class is success then prefetch and perform auto selection
    if (isSuccessResponseClass) {
        tableView.loadErrorStatus = 0;
        const hasSelection = tableView.selectedRowKeys.size > 0;

        // do auto selection and prefetch first N rows for Folder and Group tables
        if (
            tableView.tableQuery.type == 0 ||
            tableView.tableQuery.type == 2 ||
            tableView.tableQuery.type == 3
        ) {
            // Init table selection if selected table doesn't already have any selected rows
            if (!hasSelection && tableView == getSelectedTableView()) {
                initTableSelectionOnLoad(tableView);
            }

            if (shouldPrefetchTopN && !isDeepLink()) {
                prefetchFirstN(tableView, FIRST_N_ROW_PREFETCH_COUNT);
            }
        }

        if (tableView.tableQuery.type == 1) {
            // Queue the work to guarantee that focus has been reset before updating
            // selection in the table so that the focus is not lost.
            setTimeout(() => {
                // Initialize table selection
                initTableSelectionOnLoad(tableView);
            }, 0);
        }

        onInitialTableLoadCompleteAction(tableView);
    } else {
        setTableViewErrorState(tableView, responseStatusMessage);
    }
});

/**
 * Callback for set tableview error state
 * @param tableView the table view
 * @param responseStatusMessage indicates the error response status
 */
export const setTableViewErrorState = action('setTableViewErrorState')(
    function setTableViewErrorState(tableView: TableView, responseStatusMessage: string) {
        switch (responseStatusMessage) {
            case 'ErrorAccessDenied':
                tableView.loadErrorStatus = 1;
                break;
            case 'UnexpectedClientError':
                tableView.loadErrorStatus = 2;
                break;
            default:
                tableView.loadErrorStatus = 4;
        }
    }
);
