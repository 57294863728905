import type { NovaEvent } from '@nova/types';
import type { MailboxInfo } from 'owa-client-types';
import type { AppHostServiceEventOriginator } from './originator';

export const OpenLivePersonaEditorEventType = 'openLivePersonaEditor' as const;

//TODO: Shrink this object to only include the necessary fields for LPE
export type PersonaIdentifiers = {
    contactId?: string | null;
    directoryObjectId?: string | null;
    hostAppPersonaId?: string | null;
    messagingResourceId?: string | null;
    outlookServicesPersonaId?: string | null;
    smtp?: string | null;
    displayName?: string | null;
};

export type OpenLivePersonaEditorData = {
    personaIdentifiers?: PersonaIdentifiers;
    mailboxInfo: MailboxInfo;
    contactPrompt?: string;
};

export interface OpenLivePersonaEditorEvent extends NovaEvent<OpenLivePersonaEditorData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof OpenLivePersonaEditorEventType;
    data: () => OpenLivePersonaEditorData;
}
