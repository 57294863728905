import nudgeStore from '../store/Store';

export function getNudgeItemId(rowKey: string): string {
    const nudgedRows = nudgeStore.nudgedRows.filter(nudgedRow => nudgedRow.rowKey === rowKey);

    if (nudgedRows.length > 0) {
        return nudgedRows[0].itemId;
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (13,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    return null;
}
