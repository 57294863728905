import type { ObservableMap } from 'mobx';

export enum CoercionType {
    Text = 0,
    Html = 3,
}
export interface EditOnSend {
    txt: string;
    typ: CoercionType;
    id: string;
}
export type ComposeType = any;
export type LastFocusedControl = any;

export interface SubjectViewState {
    selectionStart: number;
    selectionEnd: number;
}

export interface SessionStoreSchema {
    size: number;
    dataStore: Map<string, string>;
}

export interface AddinViewState {
    appendOnSend?: EditOnSend[];
    prependOnSend?: EditOnSend[];
    draftComposeType?: ComposeType;
    lastFocusedControl: LastFocusedControl;
    subject: SubjectViewState;
    internetHeaders: ObservableMap<string, string>;
    keysOfInternetHeadersToBeRemoved: string[];
    isAutorunComposeEventTriggered: boolean;
    sessionStore: ObservableMap<string, SessionStoreSchema>;
    isOnSendEventTriggered: boolean;
}
