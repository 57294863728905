import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RemindersMutationWeb" */ './lazyIndex')
);

export const lazyDismissReminderMutationWeb = createLazyResolver(
    'DISMISS_REMINDER_MUTATION',
    lazyModule,
    m => m.dismissReminderMutationWeb
);

export const lazySnoozeReminderMutationWeb = createLazyResolver(
    'SNOOZE_REMINDER_MUTATION',
    lazyModule,
    m => m.snoozeReminderMutationWeb
);
