import {
    getOptionsForFeature,
    OwsOptionsFeatureType,
    lazyCreateOrUpdateOptionsForFeature,
} from 'owa-outlook-service-options';
import type { ListViewColumnHeadersOptions } from 'owa-outlook-service-options';
import {
    onSenderColumnWidthChangedInternal,
    onSubjectColumnWidthChangedInternal,
} from '../mutators/columnWidthMutators';

export function onFirstColumnHandleChanged(senderColumnWidth: number, subjectColumnWidth: number) {
    // Get existing settings on server
    const userOptions = getOptionsForFeature<ListViewColumnHeadersOptions>(
        OwsOptionsFeatureType.ListViewColumnHeaders
    );

    // Update settings on server
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(
        OwsOptionsFeatureType.ListViewColumnHeaders,
        {
            ...userOptions,
            senderColumnWidth,
            subjectColumnWidth,
        } as ListViewColumnHeadersOptions
    );

    // Update store
    onSenderColumnWidthChangedInternal(senderColumnWidth);
    onSubjectColumnWidthChangedInternal(subjectColumnWidth);
}
