import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OneGqlViewSchemaMutation" */ './mutation')
);

export const lazyUpdateColumnPreferencesMutation = createLazyResolver(
    'UPDATE_COLUMN_PREFERENCES_MUTATION',
    lazyModule,
    m => m.updateColumnsPreferences
);
