import { mutator, orchestrator } from 'satcheljs';
import { initializeArchiveFolderTree } from '../actions/publicActions';
import type { MailFolder } from 'owa-graph-schema';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import getMailboxFolderTreeDataTable from '../selectors/getMailboxFolderTreeDataTable';
import { removeFolderTreeData } from '../actions/removeFolderTreeData';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { setFolderTreeData } from './setFolderTreeData';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { generateMailboxSmtpAddress } from '../util/mailboxUtility';

/**
 * Initializes the archive mailbox folder tree.
 */
/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(initializeArchiveFolderTree, actionMessage => {
    // Initialize the archive root folder using the data received in session data such as display name and set it in the store.
    const mailboxInfo = actionMessage.mailboxInfo;
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    const folder = <MailFolder>{
        distinguishedFolderType: ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        displayName: sessionSettings?.ArchiveDisplayName,
        mailboxInfo: {
            type: 'ArchiveMailbox',
            userIdentity: mailboxInfo.userIdentity,
            mailboxSmtpAddress: generateMailboxSmtpAddress(
                sessionSettings?.ArchiveMailboxGuid ?? '',
                sessionSettings?.OrganizationDomain ?? ''
            ),
        },
    };

    // update the archive folder tree root and loading state.
    setFolderTreeData(
        ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        folder,
        FolderTreeLoadStateEnum.Uninitialized,
        false,
        0,
        mailboxInfo,
        undefined
    );
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(removeFolderTreeData, actionMessage => {
    getMailboxFolderTreeDataTable(actionMessage.mailboxInfo)?.delete(actionMessage.folderTreeId);
});
