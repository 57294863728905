import type { Getter, LazyModuleOptions } from 'owa-bundling-light';
import { LazyModule, LazyImport } from 'owa-bundling-light';
import type {
    SubscriptionResolverObject,
    SubscriptionResolveFn,
    GraphQLResolveInfo,
} from 'owa-graph-schema';
import type { GraphQLError } from 'graphql';
import type { IdbFallbackResult } from 'owa-graph-idb-fallback-result';

type CustomSubscriptionResult<TResult> = AsyncIterable<TResult> | GraphQLError | IdbFallbackResult;

/** Graphql-codegen does allow us to customize the subscription resolver type to include errors
 * so we add them manually here
 */
type SubscriptionSubscribeFnWithHxFallbackResult<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => CustomSubscriptionResult<TResult> | Promise<CustomSubscriptionResult<TResult>>;

interface SubscriptionResolverObjectWithHxFallbackResult<TResult, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFnWithHxFallbackResult<any, TParent, TContext, TArgs>;
    resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export function createLazySubscriptionResolver<TModule, TResult, TParent, TContext, TArgs>(
    importCbOrlazyModule: (() => Promise<TModule>) | LazyModule<TModule>,
    getter: Getter<
        SubscriptionResolverObjectWithHxFallbackResult<TResult, TParent, TContext, TArgs>,
        TModule
    >,
    moduleOptions?: LazyModuleOptions<TModule>
): SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
    const lazyModule =
        typeof importCbOrlazyModule === 'function'
            ? new LazyModule(importCbOrlazyModule, moduleOptions)
            : importCbOrlazyModule;
    const lazyImport = new LazyImport(lazyModule, getter);

    const lazyResolver = {
        subscribe: (parent: any, args: any, context: any, info: any) => {
            return lazyImport
                .import()
                .then(resolver => resolver.subscribe(parent, args, context, info));
        },
        resolve: (payload: any, args: any, context: any, info: any) => {
            return lazyImport
                .import()
                .then(resolver => resolver.resolve(payload, args, context, info));
        },
    };

    return lazyResolver as SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;
}
