import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import { isEmailAccountType } from '../utils/isEmailAccount';
import getPendingAccountsFromStore from './getPendingAccountsFromStore';

/**
 * Returns the list of pending email accounts
 */
export default function getPendingEmailAccounts(): PendingAccountInfo[] {
    return getPendingAccountsFromStore().filter(pendingAccount =>
        isEmailAccountType(pendingAccount.sourceType)
    );
}
