import type { SearchScenarioId } from 'owa-search-store';
import { action } from 'satcheljs';

export const setIsFocusOnScopeTabs = action(
    'SET_SHOULD_MOVE_FOCUS_TO_SCOPE_TABS',
    (scenarioId: SearchScenarioId, newValue: boolean) => ({
        scenarioId,
        newValue,
    })
);
