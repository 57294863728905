import type {
    AccountSourceType,
    AccountSourceProtocolMetadata,
} from 'owa-account-source-list-types';
import type { ConfigState } from './ConfigState';
import type { SyncOptions } from './SyncOptions';
import isString from './predicates/isString';
import isStringArray from './predicates/isStringArray';
import isObject from './predicates/isObject';
import isNumber from './predicates/isNumber';
import isBoolean from './predicates/isBoolean';

export const AccountMetadata_Version1 = 1;

/**
 * The purpose of the AccountMetadata is to provide the information needed
 * to create the account.
 */
export interface AccountMetadata {
    /**
     * Unique value for the metadata on the local machine, this value will remain
     * unchanged on the local machine for the life of the metadata.
     */
    uuid: string;

    /**
     * Version of the metadata, the version is updated when the required metadata
     * properties change. If only optional properties are added then the version
     * will not be updated.
     */
    version: number;

    /**
     * Sync options for the account, this will determine if the account can be roamed.
     */
    syncOptions: SyncOptions;

    /**
     * True if the account metadata has been synced to the server.
     */
    isSyncedToServer: boolean;

    /**
     * Optionally specifies the unique value for the metadata on the server. Normally
     * the server will use the metadata, if however two systems add the same account
     * then the server will use the first uuid supplied and the second system will have
     * a different uuid than the server.
     */
    serverUuid?: string;

    /**
     * The type of mailbox.
     */
    mailboxType: AccountSourceType;

    /**
     * The SMTP address for mailbox associated with the account.
     */
    smtpAddress: string;

    /**
     * The user principle name (or user identity) that is used to authenticate
     * to the mailbox associated with the account.
     */
    userIdentity: string;

    /**
     * Identifies the state of the account configuration on the local machine.
     */
    configState: ConfigState;

    /**
     * The sources (such a Win32, Universal) from which the account source was imported
     */
    sources: string[];

    /**
     * Some accounts, such as IMAP and iCloud accounts, need additional protocol specific
     * information to be able to create the account. This property optionally contains the
     * protocol specific metadata for the account.
     */
    protocolData?: AccountSourceProtocolMetadata[];

    /**
     * The SMTP aliases for the mailbox associated with the account.
     */
    aliases?: string[];
}

// Determine if the value is an AccountSourceProtocolMetadata
function isAccountSourceProtocolMetadata(value: any): value is AccountSourceProtocolMetadata {
    return (
        isObject(value) &&
        isString(value.protocol) &&
        isString(value.hostname) &&
        isNumber(value.port) &&
        isString(value.encryption) &&
        isString(value.username) &&
        (value.needsPassword === undefined || isBoolean(value.needsPassword))
    );
}

// Determine if the value is an array of AccountSourceProtocolMetadata
function isAccountSourceProtocolMetadataArray(
    value: any
): value is AccountSourceProtocolMetadata[] {
    if (Array.isArray(value)) {
        return value.every(isAccountSourceProtocolMetadata);
    }

    return false;
}

// Determine if the value is an AccountMetadata
export function isAccountMetadata(value: any): value is AccountMetadata {
    return (
        isObject(value) &&
        isString(value.uuid) &&
        isNumber(value.version) &&
        isString(value.syncOptions) &&
        isBoolean(value.isSyncedToServer) &&
        (value.serverUuid === undefined || isString(value.serverUuid)) &&
        isString(value.mailboxType) &&
        isString(value.smtpAddress) &&
        isString(value.userIdentity) &&
        isString(value.configState) &&
        isStringArray(value.sources) &&
        (value.protocolData === undefined ||
            isAccountSourceProtocolMetadataArray(value.protocolData)) &&
        (value.aliases === undefined || isStringArray(value.aliases))
    );
}

// Use a Record so we know that we have all of the key names
const AccountMetadataKeys: Record<keyof AccountMetadata, string> = {
    uuid: 'uuid',
    version: 'version',
    syncOptions: 'syncOptions',
    isSyncedToServer: 'isSyncedToServer',
    serverUuid: 'serverUuid',
    mailboxType: 'mailboxType',
    smtpAddress: 'smtpAddress',
    userIdentity: 'userIdentity',
    configState: 'configState',
    sources: 'sources',
    protocolData: 'protocolData',
    aliases: 'aliases',
};

/**
 * The name of the known object keys in the AccountMetadata interface
 */
export const AccountMetadataKeyNames: string[] = Object.keys(AccountMetadataKeys);

/**
 * The name of the known array keys in the AccountMetadata interface
 */
export const AccountMetadataArrayKeyNames: (keyof AccountMetadata)[] = [
    'sources',
    'protocolData',
    'aliases',
];

// Use a record so that we know that we have all of the key names
const AccountSourceProtocolMetadataKey: Record<keyof AccountSourceProtocolMetadata, string> = {
    protocol: 'protocol',
    hostname: 'hostname',
    port: 'port',
    encryption: 'encryption',
    username: 'username',
    needsPassword: 'needsPassword',
};

/**
 * The name of the known object keys in the AccountSourceProtocolMetadata interface
 */
export const AccountSourceProtocolMetadataKeyNames: string[] = Object.keys(
    AccountSourceProtocolMetadataKey
);
