import { getStore } from '../store/Store';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import type { TableView } from 'owa-mail-list-store';
import { isConversationView } from 'owa-mail-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getListViewColumnWidths(
    tableView?: TableView,
    showRelocatedHoverActions?: boolean
): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    const defaultSenderColumnWidth = showRelocatedHoverActions ? 236 : 332;
    const defaultSubjectColumnWidth = 1000;
    const defaultReceivedColumnWidth = showRelocatedHoverActions ? 80 : 150;
    let senderColumnWidth = getStore().senderColumnWidth || defaultSenderColumnWidth;
    let subjectColumnWidth = getStore().subjectColumnWidth || defaultSubjectColumnWidth;
    let receivedColumnWidth = getStore().receivedColumnWidth || defaultReceivedColumnWidth;

    const isSenderImageOff = getIsBitSet(8, getGlobalSettingsAccountMailboxInfo());
    const _isConversationView = tableView && isConversationView(tableView);

    if (_isConversationView && !isSenderImageOff) {
        senderColumnWidth += 68;
    }

    if (_isConversationView && isSenderImageOff) {
        senderColumnWidth += 61;
    }

    if (!_isConversationView && !isSenderImageOff) {
        senderColumnWidth += 48;
    }

    if (!_isConversationView && isSenderImageOff) {
        senderColumnWidth += 52;
    }

    // Remove 6 pixels for column spacing
    subjectColumnWidth -= 6;

    // Remove 44px for big hover action spacing
    if (!showRelocatedHoverActions) {
        receivedColumnWidth -= 44;
    }

    return {
        senderColumnWidth,
        subjectColumnWidth,
        receivedColumnWidth,
    };
}
