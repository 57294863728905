import type { MailboxInfo, MailboxType } from 'owa-client-types';
import getFolderTable from '../selectors/getFolderTable';
import {
    ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { generateMailboxSmtpAddress } from './mailboxUtility';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    getGlobalSettingsAccountMailboxInfo,
    getArchiveMailboxInfo,
    getSharedMailboxInfo,
} from 'owa-account-source-list-store';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import getMailboxInfoForArchive from 'owa-mailbox-info/lib/getMailboxInfoForArchive';
import getMailboxInfoForAuxArchive from 'owa-mailbox-info/lib/getMailboxInfoForAuxArchive';
import getMailboxInfoForCoprincipalAccount from 'owa-mailbox-info/lib/getMailboxInfoForCoprincipalAccount';
import getMailboxInfoForShared from 'owa-mailbox-info/lib/getMailboxInfoForShared';

/**
 * Fetches mailbox info for a given folder
 * @param folderId Id of the folder
 * @param routeToAuxIfAuxArchive whether to route the request to the the aux archive if the folder
 * belongs to aux archive mailbox (should only be false for some folder operations).
 */
export function getMailboxInfoForFolderId(
    folderId: string,
    routeToAuxIfAuxArchive: boolean
): MailboxInfo {
    const folderTable = getFolderTable();
    const folder = folderTable?.get(folderId);

    let mailboxSmtpAddress: string;
    let mailboxGuid: string | null | undefined; // set to archive mailbox for archive folders, null otherwise
    let auxArchiveMailboxGuid;
    let mailboxType: MailboxType | undefined;
    let sourceId: string | null | undefined;

    const mailboxInfo: MailboxInfo = folder?.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    if (folder?.mailboxInfo.type === 'SharedMailbox') {
        if (isMailboxPackageFlightEnabled()) {
            return getMailboxInfoForShared(mailboxInfo, folder.mailboxInfo.mailboxSmtpAddress);
        }

        mailboxSmtpAddress = folder.mailboxInfo.mailboxSmtpAddress;
        mailboxType = 'SharedMailbox';
        sourceId = getSharedMailboxInfo(mailboxInfo, mailboxSmtpAddress)?.sourceId;
    } else if (
        folderId == ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID ||
        folderId == ARCHIVE_DUMPSTER_DISTINGUISHED_ID ||
        folder?.mailboxInfo.type == 'ArchiveMailbox'
    ) {
        // folderId check is required because when fetching the folder list for the archive, the root folder is not
        // yet present in the folder table
        auxArchiveMailboxGuid = folder?.replicaList?.[0];
        if (isMailboxPackageFlightEnabled()) {
            // Most requests should be routed to the mailbox the folder is in.
            // However, for folder operations on auxilliary archive folders,
            // the request always needs to go to the main archive mailbox.
            if (auxArchiveMailboxGuid && routeToAuxIfAuxArchive) {
                return getMailboxInfoForAuxArchive(mailboxInfo, auxArchiveMailboxGuid);
            }

            return getMailboxInfoForArchive(mailboxInfo);
        }

        // Most requests should be routed to the mailbox the folder is in.
        // However, for folder operations on auxilliary archive folders,
        // the request always needs to go to the main archive mailbox.
        if (auxArchiveMailboxGuid && routeToAuxIfAuxArchive) {
            mailboxGuid = auxArchiveMailboxGuid;
        } else {
            mailboxGuid = getAccountScopeUserSettings(
                folder?.mailboxInfo ?? getModuleContextMailboxInfo()
            ).SessionSettings?.ArchiveMailboxGuid;
            auxArchiveMailboxGuid = undefined;
        }

        mailboxType = 'ArchiveMailbox';
        mailboxSmtpAddress = generateMailboxSmtpAddress(
            mailboxGuid,
            getAccountScopeUserSettings(folder?.mailboxInfo ?? getModuleContextMailboxInfo())
                .SessionSettings?.OrganizationDomain
        );

        sourceId = getArchiveMailboxInfo(mailboxInfo, mailboxGuid || '')?.sourceId;
    } else {
        if (isMailboxPackageFlightEnabled()) {
            return getMailboxInfoForCoprincipalAccount(mailboxInfo);
        }

        // oneMailView - folder cleanup
        mailboxSmtpAddress = mailboxInfo.mailboxSmtpAddress;
        sourceId = mailboxInfo.sourceId;
    }

    return {
        type: mailboxType ?? mailboxInfo.type,
        sourceId,
        userIdentity: mailboxInfo.userIdentity,
        mailboxSmtpAddress,
        auxiliaryMailboxGuid: auxArchiveMailboxGuid,
    };
}
