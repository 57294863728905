import type { CoercionType as Schema_CoercionType } from 'owa-graph-schema';
import { CoercionType } from 'owa-editor-addin-plugin-types/lib/utils/AddinViewState';
import { assertNever } from 'owa-assert';

export function mapToGqlTyp(typ: CoercionType): Schema_CoercionType {
    //Input typ can be 'Html' or Text' when from graphql and 0 or 3 when from OWS.
    //Irrespective of any input we return graphql coercion type from this function
    const coercionType = typ.toString();
    switch (coercionType) {
        case 'Text':
            return 'Text';
        case 'Html':
            return 'Html';
    }
    switch (typ) {
        case CoercionType.Text:
            return 'Text';
        case CoercionType.Html:
            return 'Html';
        default:
            return assertNever(typ);
    }
}

export function mapToOwsTyp(typ: Schema_CoercionType): CoercionType {
    //Input typ can be 'Html' or Text' when from graphql and 0 or 3 when from OWS.
    //Irrespective of any input we return OWS coercion type from this function
    const coercionType = typ.toString();
    switch (coercionType) {
        case '0':
            return CoercionType.Text;
        case '3':
            return CoercionType.Html;
    }
    switch (typ) {
        case 'Text':
            return CoercionType.Text;
        case 'Html':
            return CoercionType.Html;
        default:
            return assertNever(typ);
    }
}
