import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type {
    LicenseType,
    ElaborateFilterScenarios,
    ElaborateFilterSettings,
    AddinCopilotSettings,
    AddinCapabilityNames,
    ExtensibilitySettingsResponse,
} from '../services/types/serviceTypes';
import { ELABORATE_FILTER_SCENARIOS } from '../services/types/serviceTypes';
import createDefaultScenarioOption from '../services/helpers/createDefaultScenarioOption';
import registerDiagDataCollector from '../utils/registerDiagDataCollector';

const mailboxIndexerToCopilotSettings = new Map<string, CopilotSettingsStore>();
const mailboxIndexerToElaborateSettings = new Map<string, ElaborateSettingsCache>();

export type ImplementedCopilotFeatures =
    | 'Coach'
    | 'Elaborate'
    | 'Summarize'
    | 'SuggestedDrafts'
    | 'SuggestCategory'
    | 'ExplainPlacesReport'
    | 'Rewrite'
    | 'ReplyByMeeting'
    | 'MeetingPrepSummary'
    | 'Chat'
    | 'CoachApply'
    | 'Inbox'
    | 'TimeCopilotChat';

export const ScenarioToCapabilityMap: Map<ImplementedCopilotFeatures, AddinCapabilityNames> =
    new Map([
        ['Summarize', 'emailSummary'],
        ['Elaborate', 'emailDraft'],
        ['SuggestedDrafts', 'emailReplySuggestions'],
    ]);

export enum FetchState {
    NOT_STARTED,
    IN_PROGRESS,
    SUCCEEDED,
    FAILED,
}
export interface FeatureInfo {
    isEnabled: true;
    langauges: string[];
    capabilities?: CopilotFeatureCapabilities;
}

export type CopilotFeatureCapabilities =
    | ElaborateFeatureCapabilities
    | RewriteFeatureCapabilities
    | TimeCopilotChatFeatureCapabilities
    | InboxPrioritizationFeatureCapabilities;

export interface ElaborateFeatureCapabilities {
    tonePersonalization: boolean;
    calendarElaborate: boolean;
}

export interface RewriteFeatureCapabilities {
    highlightAndRewrite: boolean;
    calendarRewrite: boolean;
}

export interface TimeCopilotChatFeatureCapabilities {
    triage: boolean;
    schedulingCompactCard: boolean;
    workHoursLocation: boolean;
    speedyMeetings: boolean;
    darkMode: boolean;
    categorizationUpdates: boolean;
    calTimeSuggestionsV2: boolean;
    monCopilotChatContextIq: boolean;
    timeExpression: boolean;
}

export interface InboxPrioritizationFeatureCapabilities {
    backgroundProcessing: boolean;
}

export interface CopilotCapableAddinInfo {
    titleId: string;
    manifestId?: string;
    isOldModelAddin?: boolean;
    acquisitionDate?: string;
    isAdminInstalled: boolean;
    scenarioStrings: Map<ImplementedCopilotFeatures, AddinScenarioStrings>;
    menuIcon?: string;
    fallbackAddinName: string;
    copilotSettings?: AddinCopilotSettings;
}

export interface AddinScenarioStrings {
    menuLabelText?: string;
    menuSelectedText?: string;
    launchButtonText?: string;
}

export interface AddinSettings {
    tenantDefault?: string;
    lastUsedAddin?: string;
}

export type ScenarioToAddinSettingsMap = {
    [key in ImplementedCopilotFeatures]?: AddinSettings;
};

export type FeatureMap = Map<ImplementedCopilotFeatures, FeatureInfo>;

export type AddinsByScenario = Map<ImplementedCopilotFeatures, CopilotCapableAddinInfo[]>;
export type AddinInfoById = Map<string, CopilotCapableAddinInfo>;
interface Extensibility {
    addinsInfo: AddinsByScenario;
    addinInfoById: AddinInfoById;
    scenarioToAddinSettingsMap: ScenarioToAddinSettingsMap;
    settingsFromService?: ExtensibilitySettingsResponse;
}

export interface CopilotSettingsStore {
    isEnabled: boolean;
    featureInfo: FeatureMap;
    extensibility: Extensibility;
    licenses: LicenseType[];
    fetchState: FetchState;
    isBetaUser: boolean;
}

export interface ElaborateSettingsCache {
    elaborateFilterOptions: Map<ElaborateFilterScenarios, ElaborateFilterSettings>;
    tonePersonalizationSeen: boolean;
}

function buildElaborateFilterOptionsDefaultMap() {
    const filterOptionMap = new Map<ElaborateFilterScenarios, ElaborateFilterSettings>();
    ELABORATE_FILTER_SCENARIOS.map(scenario => {
        filterOptionMap.set(scenario, createDefaultScenarioOption());
    });
    return filterOptionMap;
}

export default function copilotSettingsStore(mailboxInfo: MailboxInfo): CopilotSettingsStore {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_copilotSettingsStore';
    let cachedStore = mailboxIndexerToCopilotSettings.get(name);
    if (!cachedStore) {
        const store = createStore<CopilotSettingsStore>(name, {
            isEnabled: false,
            licenses: [],
            featureInfo: new Map(),
            extensibility: {
                addinsInfo: new Map(),
                addinInfoById: new Map(),
                scenarioToAddinSettingsMap: {},
            },
            fetchState: FetchState.NOT_STARTED,
            isBetaUser: false,
        })();
        mailboxIndexerToCopilotSettings.set(name, store);
        cachedStore = store;
    }
    return cachedStore;
}

export function elaborateSettingsCache(mailboxInfo: MailboxInfo): ElaborateSettingsCache {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_elaborateSettingsCache';
    let cachedStore = mailboxIndexerToElaborateSettings.get(name);
    if (!cachedStore) {
        const defaultStore = {
            elaborateFilterOptions: buildElaborateFilterOptionsDefaultMap(),
            tonePersonalizationSeen: false,
        };
        mailboxIndexerToElaborateSettings.set(name, defaultStore);
        cachedStore = defaultStore;
    }
    return cachedStore;
}

export function getAllStoreDataForDiagnostics(): string[] {
    const returnArray: string[] = [];
    for (const entry of mailboxIndexerToCopilotSettings.entries()) {
        const [key, storeObj] = entry;
        returnArray.push(
            JSON.stringify({
                storeIndexer: key,
                store: storeObj,
            })
        );
    }
    return returnArray;
}

registerDiagDataCollector();
