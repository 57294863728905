import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';

export const conversationViewCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (mailboxInfo) {
            const mailboxInfoType = mailboxInfo.type;
            if (
                mailboxInfoType === 'ArchiveMailbox' ||
                mailboxInfoType === 'SharedMailbox' ||
                mailboxInfoType === 'PstFile'
            ) {
                return false;
            }
        }

        return true;
    },
};
