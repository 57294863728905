import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SettingsQueryWeb" */ './lazyIndex')
);

export const lazyMobileDevicesStatisticsQueryWeb = createLazyResolver(
    'MOBILEDEVICESSTATISTICS_QUERY_WEB',
    lazyModule,
    m => m.mobileDevicesStatisticsQueryWeb
);

export const lazyAllowedSettingsQueryWeb = createLazyResolver(
    'ALLOWEDSETTINGS_QUERY_WEB',
    lazyModule,
    m => m.allowedSettingsQueryWeb
);

export const lazyReadOcsSettingsQueryWeb = createLazyResolver(
    'READOCSSETTINGS_QUERY_WEB',
    lazyModule,
    m => m.readOcsSettingsQueryWeb
);
