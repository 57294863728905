import type { InboxReminder, InboxReminderInput } from 'owa-graph-schema';

export function convertInboxRemindersGqlTypeToGqlInput(
    inboxReminder: InboxReminder
): InboxReminderInput {
    return {
        Id: inboxReminder.Id,
        ReminderOffset: inboxReminder.ReminderOffset,
        Message: inboxReminder.Message,
        IsOrganizerReminder: inboxReminder.IsOrganizerReminder,
        OccurrenceChange: inboxReminder.OccurrenceChange,
        IsImportedFromOLC: inboxReminder.IsImportedFromOLC,
        SendOption: inboxReminder.SendOption,
    };
}
