import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used for show/hide the archive and shared with me folders
 */
export const archiveAndSharedCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (isBusiness(mailboxInfo)) {
            // Business accounts that support service request will have
            // archive/shared mailboxes
            return isServiceRequestSupportedForMailbox(mailboxInfo);
        }

        return false;
    },
};
