import type { TableView } from 'owa-mail-list-store';
import { getSelectedTableView } from 'owa-mail-list-store';
import { type ListRestrictionType } from '../Behaviors.types';
import { assertNever } from 'owa-assert';

export const listRestrictionType = (restrictionType: ListRestrictionType) => () => {
    const tableView: TableView = getSelectedTableView();

    // Check if we have the right number of items on listview;
    switch (restrictionType) {
        case 0:
            return tableView.rowKeys.length == 0;

        case 1:
            return tableView.rowKeys.length > 0;

        default:
            throw assertNever(restrictionType);
    }
};
