export const closeButtonLabelString = "Vc7Und";
export const dialogTitleString = "JnwvS";
export const dropdownMenuHeaderString = "q9QuSb";
export const saveButtonLabelString = "Spnyc";
export const savingButtonLabelString = "drDsub";
export const confirmationDialogLabelString = "YrG8b";
export const confirmationDialogTextString = "NEONac";
export const confirmationDialogContinueButtonString = "DpnGzb";
export const confirmationDialogDiscardButtonString = "QhkZ3c";
export const genericErrorMessageString = "ZEwCAc";
export const adminLockedTooltipString = "xMhvyc";
export const labelLockedTooltipString = "Y2v_7c";
export const townhallLockedTooltipString = "RoIsM";
export const webinarLockedTooltipString = "OKsSS";
export const copyConfirmationString = "$5rILd";
export const copyLinkString = "y8k3pe";
export const copyKeyString = "VI6iUd";
export const learnMoreString = "SDv_Lc";
export default {closeButtonLabelString,dialogTitleString,dropdownMenuHeaderString,saveButtonLabelString,savingButtonLabelString,confirmationDialogLabelString,confirmationDialogTextString,confirmationDialogContinueButtonString,confirmationDialogDiscardButtonString,genericErrorMessageString,adminLockedTooltipString,labelLockedTooltipString,townhallLockedTooltipString,webinarLockedTooltipString,copyConfirmationString,copyLinkString,copyKeyString,learnMoreString};