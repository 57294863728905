import type { UserConfiguration, QueryUserConfigurationArgs } from 'owa-graph-schema';
import getOwaUserConfigurationOperation from 'owa-service/lib/operation/getOwaUserConfigurationOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

import { convertUserConfigToGraphQLSchema } from 'owa-userconfiguration/lib/utils/convertUserConfigToGraphQLSchema';

/**
 * Web resolver responsible for fetching owa user configuration
 */
export const userConfigurationWeb = async (
    _parent: any,
    args: QueryUserConfigurationArgs,
    _context: any,
    _info: any
): Promise<UserConfiguration> => {
    const userConfig = await getOwaUserConfigurationOperation(
        getMailboxRequestOptions(args.mailboxInfo)
    );

    return convertUserConfigToGraphQLSchema(userConfig, args.mailboxInfo.userIdentity);
};
