import React from 'react';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import LineHorizontal3 from 'owa-fluent-icons-svg/lib/icons/LineHorizontal3Regular';
import { makeStyles, tokens, Button, Tooltip } from '@fluentui/react-components';
import { NavigationRegular } from '@fluentui/react-icons';
import Strings from '../strings/toggleLeftPaneButton.locstring.json';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { getPalette } from 'owa-theme';

import { toggleButton, toggleButtonIcon } from './ExtraTopRibbonControlsBeforeTabs.scss';
import { isFeatureEnabled } from 'owa-feature-flags';

interface ExtraTopRibbonControlsBeforeTabsProps {
    onClick: () => void;
    isExpanded: boolean;
}

const useStyles = makeStyles({
    toggleButton: {
        margin: '0px 0px 2px 10px',
        backgroundColor: tokens.colorNeutralBackground3,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover,
            color: tokens.colorNeutralForeground1,
        },
        borderRadius: 0,
        padding: '8px 10px',
        maxWidth: 'fit-content',
        border: 0,
    },
    toggleButtonIcon: {
        color: tokens.colorNeutralForeground1,
        display: 'flex',
        alignItems: 'center',
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightRegular,
    },
});

const defaultIconProps = {
    iconName: LineHorizontal3,
};

export const ExtraTopRibbonControlsBeforeTabs = observer(function ExtraTopRibbonControlsBeforeTabs(
    props: ExtraTopRibbonControlsBeforeTabsProps
) {
    const { onClick, isExpanded } = props;
    const displayText: string = isExpanded
        ? loc(Strings.hideLeftPaneButton)
        : loc(Strings.showLeftPaneButton);

    const palette = getPalette();

    const classes = useStyles();
    const v9RibbonEnabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');

    const navigationRegularIcon = React.useMemo(
        () => <NavigationRegular className={classes.toggleButtonIcon} />,
        [classes.toggleButtonIcon]
    );

    const defaultToggleButtonButtonStyles = React.useMemo(
        () => ({
            root: toggleButton,
            icon: toggleButtonIcon,
            iconHovered: { color: palette.neutralDark },
        }),
        [palette]
    );

    return v9RibbonEnabled ? (
        <Tooltip
            content={displayText}
            key="ToggleButton_ExtraTopRibbonControlsBeforeTabs"
            relationship="label"
            withArrow={true}
            positioning="below"
        >
            <Button
                aria-label={displayText}
                icon={navigationRegularIcon}
                appearance="transparent"
                className={classes.toggleButton}
                onClick={onClick}
            />
        </Tooltip>
    ) : (
        <TooltipHost content={displayText} key="ToggleButton_ExtraTopRibbonControlsBeforeTabs">
            <CommandBarButton
                aria-label={displayText}
                iconProps={defaultIconProps}
                styles={defaultToggleButtonButtonStyles}
                onClick={onClick}
            />
        </TooltipHost>
    );
},
'ExtraTopRibbonControlsBeforeTabs');
