import React from 'react';
import { SearchHeader } from 'owa-mail-search';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { getSelectedTableView } from 'owa-mail-list-store';
import { SelectAllCheckbox } from 'owa-mail-list-view';
import { lazyGetIsNonMailEntitySearchTab, TabbedSearchPane } from 'owa-tabbed-search';
import MailView from './MailView';
import { observer } from 'owa-mobx-react';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import {
    searchResults,
    searchResultsFromArchive,
} from 'owa-locstrings/lib/strings/searchresults.locstring.json';
import loc from 'owa-localize';
import {
    searchHeaderBorders,
    searchResultsContainer,
    searchResultsTabbedContainer,
    newSearchHeaderContainer,
    fullBleed,
} from './MailModule.scss';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { isCapabilityEnabled } from 'owa-capabilities';
import classNames from 'owa-classnames';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';

export default observer(function SearchView() {
    const tableView = getSelectedTableView();
    const tableViewId = tableView.id;
    const searchTableQuery = tableView?.tableQuery as SearchTableQuery;
    const getIsNonMailEntitySearchTab = lazyGetIsNonMailEntitySearchTab.tryImportForRender();
    const showTabbedSearchPane = getIsNonMailEntitySearchTab
        ? getIsNonMailEntitySearchTab()
        : false;
    const isPrimaryMailBox = searchTableQuery.searchScope?.kind === SearchScopeKind.PrimaryMailbox;
    const searchResultHeaderText =
        searchTableQuery.searchScope?.kind == SearchScopeKind.ArchiveMailbox
            ? loc(searchResultsFromArchive)
            : loc(searchResults);
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    const getSearchHeader = React.useMemo(() => {
        // SearchView should render the header if MailView and ListPane have not rendered it.
        // The Copilot Chat header is rendered by ListPane, so SearchView should not render it.
        const shouldRenderHeader = searchTableQuery.scenarioType !== 'copilotChatBulkTriage';
        if (searchTableQuery == undefined || !shouldRenderHeader) {
            return;
        } else {
            return (
                <div className={isBleedThroughEnabled ? fullBleed : ''}>
                    <div className={searchHeaderBorders}>
                        <SearchHeader
                            searchScenarioId={searchTableQuery.searchScenarioId}
                            noTabsEnabledTitleText={searchResultHeaderText}
                            isPrimaryMailBox={isPrimaryMailBox}
                            isLocalSearch={
                                searchTableQuery.searchProvider === SearchProvider.Offline ||
                                searchTableQuery.searchProvider === SearchProvider.PST
                            }
                        >
                            <SelectAllCheckbox
                                tableViewId={tableViewId}
                                isInboxWithPivots={true}
                                isSearchRender={true}
                            />
                        </SearchHeader>
                    </div>
                </div>
            );
        }
    }, [tableViewId, searchTableQuery]);

    const getSearchResultsContainer = React.useMemo(() => {
        if (showTabbedSearchPane) {
            return (
                <div className={classNames(searchResultsContainer, searchResultsTabbedContainer)}>
                    <TabbedSearchPane header={undefined} />
                </div>
            );
        } else {
            return (
                <div className={searchResultsContainer}>
                    <MailView />
                </div>
            );
        }
    }, [showTabbedSearchPane]);

    return (
        <div className={newSearchHeaderContainer}>
            {getSearchHeader}
            {getSearchResultsContainer}
        </div>
    );
}, 'SearchView');
