import { makeGetRequest } from 'owa-ows-gateway';
import { parseReportSubmissionPolicyFromPrime } from './getReportSubmissionPolicyFromPrimeSettings';
import onReportSubmissionPolicyFetched from '../mutators/onReportSubmissionPolicyFetched';
import type { MailboxInfo } from 'owa-client-types';

// Ensures that we only make one request to OWS' to fetch the report submission policy
let isFetching = false;

export default async function fetchReportSubmissionPolicy(mailboxInfo: MailboxInfo) {
    // Early return if we're already fetching the report submission policy
    if (isFetching) {
        return;
    }

    /**
     * If the report submission policy information isn't available from PrimeSettings,
     * then fetch them from OWS'.
     */
    isFetching = true;
    const response = await makeGetRequest(
        '/ows/api/beta/BootSettings/ReportSubmissionPolicy' /* requestUrl */,
        undefined /* correlationId */,
        false /* returnFullResponse */,
        undefined /* customHeaders */,
        true /* throwServiceError */,
        undefined /* includeCredentials */,
        undefined /* actionName */,
        mailboxInfo /* mailboxInfo */
    );
    isFetching = false;

    if (response?.id === 'ReportSubmissionPolicy' && response?.value) {
        const fetchedReportSubmissionPolicy = parseReportSubmissionPolicyFromPrime(response.value);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        onReportSubmissionPolicyFetched(mailboxInfo, fetchedReportSubmissionPolicy!);
    }
}
