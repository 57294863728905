import type TabViewState from '../store/schema/TabViewState';
import { type TabState, TabType } from '../store/schema/TabViewState';
import { getStore } from '../store/tabStore';

export default function getActiveComposeTab(): TabViewState[] | null {
    const store = getStore();
    const composeTabs: TabViewState[] = [];

    for (const tab of store.tabs) {
        if (tab.type === TabType.MailCompose && (tab.state === 2 || tab.state === 1)) {
            composeTabs.push(tab);
        }
    }
    return composeTabs;
}
