import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyUninstallM365AcquisitionMutationWeb = createLazyResolver(
    'APPHOST_UNINSTALL_M365_ACQUISITION_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "UninstallM365AcquisitionMutationWeb" */ './uninstallM365AcquisitionMutationWeb'
        ),
    m => m.uninstallM365AcquisitionMutationWeb
);
