import { getCategoryNameFromId } from 'owa-categories/lib/utils/categoryIdNameConverter';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { favoritesStore, getFavoriteIdFromCategoryId } from 'owa-favorites';
import type {
    FavoritePersonaNode,
    FolderForestNode,
    GroupForestNode,
    GroupFolderForestNode,
} from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import {
    createGroupFolderMailTableQuery,
    createGroupMailTableQuery,
    getListViewTypeForGroup,
} from 'owa-group-mail-list-actions';
import { isFavoritingInProgress } from 'owa-mail-favorites-store';
import {
    getListViewTypeForFolder,
    getShouldOverrideToSortBySizeForMailFolder,
} from 'owa-mail-folder-store';
import getFolderViewStateFromId from 'owa-mail-folder-store/lib/selectors/getFolderViewStateFromId';
import type MailFolderNodeViewState from 'owa-mail-folder-store/lib/store/schema/MailFolderNodeViewState';
import type { TableQuery } from 'owa-mail-list-store';
import {
    lazyCreateFallbackCategorySearchTableQuery,
    lazyCreateFallbackPersonaSearchTableQuery,
} from 'owa-mail-search/lib/lazyFunctions';
import type { ActionSource } from 'owa-mail-store';
import { loadTableViewFromTableQuery } from 'owa-mail-table-loading-actions';
import createMailCategoryFolderTableQuery from 'owa-mail-triage-table-utils/lib/createMailCategoryFolderTableQuery';
import createMailFolderTableQuery from 'owa-mail-triage-table-utils/lib/createMailFolderTableQuery';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { SessionData } from 'owa-service/lib/types/SessionData';

/**
 * Called when a folder forest node is selected
 * @param node the folder forest node
 * @param actionSource action that initiated the switch folder action
 * @return a promise that resolves whether the select node has completed
 */
export default async function onFolderForestNodeSelected(
    node: FolderForestNode,
    actionSource?: ActionSource,
    sessionData?: SessionData,
    isSearchFolder?: boolean
): Promise<void> {
    let tableQuery: TableQuery | undefined;

    switch (node.type) {
        case 0:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (52,19): Type 'MailFolderNodeViewState | undefined' is not assignable to type 'MailFolderNodeViewState'.
            // @ts-expect-error
            const viewState: MailFolderNodeViewState = getFolderViewStateFromId(node.id);
            const shouldOverrideToSortBySize: boolean =
                getShouldOverrideToSortBySizeForMailFolder();
            tableQuery = createMailFolderTableQuery(
                node.id,
                getListViewTypeForFolder(node.id),
                shouldOverrideToSortBySize
                    ? 'mailFolderSortBySize'
                    : isSearchFolder
                    ? 'searchFolder'
                    : 'mail',
                undefined /* apply default focused view filter for the selected folder*/,
                undefined /* viewFilter */,
                undefined /* categoryName */,
                viewState.sortColumn && viewState.sortOrder
                    ? {
                          sortColumn: viewState.sortColumn,
                          sortDirection: viewState.sortOrder,
                      }
                    : undefined /* sortBy */
            );
            break;

        case 4:
            const categoryId = node.id;
            const favoriteCategory = favoritesStore.outlookFavorites.get(
                getFavoriteIdFromCategoryId(categoryId)
            );
            const mailboxInfo = getModuleContextMailboxInfo();
            if (isFavoritingInProgress(categoryId)) {
                // Execute one-off search while the category search folder is being populated, because the server
                // search folder hasn't been created yet
                const categoryName = getCategoryNameFromId(
                    categoryId,
                    getMasterCategoryList(favoriteCategory?.mailboxInfo ?? mailboxInfo)
                );
                tableQuery = (await lazyCreateFallbackCategorySearchTableQuery.import())(
                    categoryName,
                    getListViewTypeForFolder(categoryId)
                );
            } else {
                tableQuery = createMailCategoryFolderTableQuery(
                    categoryId,
                    favoriteCategory?.mailboxInfo ?? mailboxInfo
                );
            }
            break;

        case 1:
            const personaNode = node as FavoritePersonaNode;

            if (!personaNode.isSearchFolderPopulated) {
                // Execute search while the folder is being populated
                tableQuery = (await lazyCreateFallbackPersonaSearchTableQuery.import())(
                    personaNode.displayName,
                    personaNode.allEmailAddresses,
                    getListViewTypeForFolder(node.id)
                );
            } else {
                tableQuery = createMailFolderTableQuery(
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2345 (99,21): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                    // @ts-expect-error
                    personaNode.searchFolderId,
                    getListViewTypeForFolder(personaNode.searchFolderId),
                    'persona'
                );
            }
            break;

        case 2:
            const groupNode: GroupForestNode = node as GroupForestNode;
            tableQuery = createGroupMailTableQuery(
                groupNode.id,
                getListViewTypeForGroup(),
                groupNode.mailboxInfo
            );
            break;
        case 6:
            const groupFolderNode: GroupFolderForestNode = node as GroupFolderForestNode;
            tableQuery = createGroupFolderMailTableQuery(
                groupFolderNode.groupId,
                groupFolderNode.id,
                getListViewTypeForGroup(),
                groupFolderNode.mailboxInfo,
                undefined,
                undefined
            );
            break;

        case 5:
            tableQuery = createMailFolderTableQuery(node.id, 1, 'mail');
            break;

        default:
            // No-op for other node type
            break;
    }

    if (!tableQuery) {
        return;
    }

    return loadTableViewFromTableQuery(
        tableQuery,
        undefined /* loadTableViewDataPoint */,
        actionSource,
        sessionData
    );
}
