import type { EnhancedLocation, EnhancedLocationInput } from 'owa-graph-schema';

export function convertEnhancedLocationGqlTypeToGqlInput(
    location: EnhancedLocation
): EnhancedLocationInput {
    return {
        DisplayName: location.DisplayName,
        Annotation: location.Annotation,
        PostalAddress: location.PostalAddress && {
            Street: location.PostalAddress.Street,
            City: location.PostalAddress.City,
            State: location.PostalAddress.State,
            Country: location.PostalAddress.Country,
            PostalCode: location.PostalAddress.PostalCode,
            PostOfficeBox: location.PostalAddress.PostOfficeBox,
            Type: location.PostalAddress.Type,
            Latitude: location.PostalAddress.Latitude,
            Longitude: location.PostalAddress.Longitude,
            Accuracy: location.PostalAddress.Accuracy,
            Altitude: location.PostalAddress.Altitude,
            AltitudeAccuracy: location.PostalAddress.AltitudeAccuracy,
            FormattedAddress: location.PostalAddress.FormattedAddress,
            LocationUri: location.PostalAddress.LocationUri,
            LocationSource: location.PostalAddress.LocationSource,
        },
        Id: location.Id,
        IdType: location.IdType,
        LocationType: location.LocationType,
    };
}
