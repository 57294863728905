import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MoveFolderWeb" */ './lazyIndex')
);

export const lazyMoveFolderWeb = createLazyResolver(
    'MUTATION_MOVE_FOLDER_WEB',
    lazyModule,
    m => m.moveFolderWeb
);

export const lazyMoveFolderPositionWeb = createLazyResolver(
    'MUTATION_MOVE_FOLDER_POSITION_WEB',
    lazyModule,
    m => m.moveFolderPositionWeb
);
