import { isFeatureEnabled } from 'owa-feature-flags';
import { DESCENDING_SORT_DIR } from 'owa-mail-list-store/lib/utils/sort/mailSortHelper';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { TableView } from 'owa-mail-list-store';
import {
    getSelectedTableView,
    getViewFilterForTable,
    getFocusedFilterForTable,
    type SortColumn,
    getStore as getListViewStore,
    getSortByForTable,
    type TableQueryType,
} from 'owa-mail-list-store';
import {
    getIsBitSet,
    type FocusedInboxBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/focusedInboxBitFlagUtil';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { logUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';

let isNudgeEnabledForTenantLogged: boolean;

// Check flight, user and tenant settings
export function isNudgeEnabled(mailboxInfo?: MailboxInfo) {
    const isNudgeEnabledForEnterprise =
        !isConsumer(undefined /* smtpAddress */, mailboxInfo) && isFeatureEnabled('tri-nudge');
    const isNudgeEnabledForTenant = getUserConfiguration().UserOptions?.MessageRemindersEnabled;

    if (!isNudgeEnabledForTenantLogged) {
        isNudgeEnabledForTenantLogged = true;
        logUsage('Nudge_TenantSetting', {
            owa_1: isNudgeEnabledForTenant,
        });
    }

    return isNudgeEnabledForTenant && isNudgeEnabledForEnterprise && !getIsBitSet(32);
}

function isNudgeEnabledForFolder(folderId: string) {
    // nudge is only supported in inbox or sent items folder
    const folderName = folderIdToName(folderId);
    return folderName === 'inbox' || folderName === 'sentitems';
}

function isNudgeEnabledForView(tableView: TableView | undefined) {
    if (!tableView) {
        return false;
    }

    // Nudge is only supported in folder table types (and not in Search/Groups)
    if (tableView.tableQuery.type != 0) {
        return false;
    }

    // nudge is only supported in Inbox or Focused views with no view filter
    if (getViewFilterForTable(tableView) != 'All') {
        return false;
    }

    const focusedViewFilter = getFocusedFilterForTable(tableView);
    if (focusedViewFilter != 0 && focusedViewFilter != -1) {
        return false;
    }

    const sortBy = getSortByForTable(tableView);

    return sortBy.sortColumn == 1 && sortBy.sortDirection == DESCENDING_SORT_DIR;
}

export function isNudgeSupportedInTable(tableViewId: string) {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const folderId = tableView?.tableQuery.folderId;
    if (!folderId || !isNudgeEnabledForFolder(folderId)) {
        return false;
    }

    return isNudgeEnabledForView(tableView);
}

export default function isNudgeSupportedInFolder(folderId: string): boolean {
    if (!isNudgeEnabledForFolder(folderId)) {
        return false;
    }

    const tableView = getSelectedTableView();
    return isNudgeEnabledForView(tableView);
}
