import type { ClientItemId } from 'owa-client-ids';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';

export function modifyIdsForRead(
    idList: RibbonControlId[],
    itemId?: ClientItemId
): (number | string)[] {
    if (itemId === undefined) {
        return idList;
    }

    const returnIdList: (number | string)[] = [];

    idList.forEach(id => {
        returnIdList.push(getReadRibbonId(id, itemId));
    });
    return returnIdList;
}
