import getMailboxInfoForArchive from './getMailboxInfoForArchive';
import isResultFromArchiveFanout from './isResultFromArchiveFanout';
import type { ClientItemId } from 'owa-client-ids';
import type { ConversationType } from 'owa-graph-schema';
import type { TableView } from 'owa-mail-list-store';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import getMailboxInfoForAuxArchive from 'owa-mailbox-info/lib/getMailboxInfoForShared';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';

export default function getConversationClientIdForSearchResult(
    conversationType: ConversationType,
    tableView: TableView
): ClientItemId {
    if (
        conversationType?.MailboxGuids &&
        isResultFromArchiveFanout(conversationType.MailboxGuids)
    ) {
        return {
            Id: conversationType.ConversationId.Id,
            mailboxInfo: isMailboxPackageFlightEnabled()
                ? getMailboxInfoForAuxArchive(
                      getModuleContextMailboxInfo(),
                      conversationType.MailboxGuids[0]
                  )
                : getMailboxInfoForArchive(
                      getModuleContextMailboxInfo(),
                      conversationType.MailboxGuids[0]
                  ),
        };
    } else {
        return {
            Id: conversationType.ConversationId.Id,
            mailboxInfo: getMailboxInfo(tableView),
        };
    }
}
