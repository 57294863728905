import type { EditOnSend as Schema_EditOnSend } from 'owa-graph-schema';
import type { EditOnSend } from 'owa-editor-addin-plugin-types/lib/utils/AddinViewState';
import { mapToGqlTyp } from './mapCoercionType';

export function convertEditOnSendToGql(editOnSend: EditOnSend): Schema_EditOnSend {
    return {
        __typename: 'EditOnSend',
        id: editOnSend.id,
        txt: editOnSend.txt,
        typ: mapToGqlTyp(editOnSend.typ),
    };
}
