import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MoveConversationMutationWeb" */ './lazyIndex')
);

export const lazyMoveConversationWeb = createLazyResolver(
    'MUTATION_MOVE_CONVERSATION_WEB',
    lazyModule,
    m => m.moveConversationWeb
);

export const lazyUndoMoveConversationWeb = createLazyResolver(
    'MUTATION_UNDO_MOVE_CONVERSATION_WEB',
    lazyModule,
    m => m.undoMoveConversationWeb
);
