import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isMeetingMessage, isMeetingResponse } from 'owa-meeting-message-utils';
import type MeetingMessage from 'owa-service/lib/contract/MeetingMessage';
import { hasExtendedCard } from 'owa-mail-reading-pane-store/lib/utils/extendedCardUtils';
import { ExtendedCardType } from 'owa-mail-reading-pane-store/lib/store/schema/ExtendedCardViewState';
import { isFeatureEnabled } from 'owa-feature-flags';
import type Message from 'owa-service/lib/contract/Message';

export default action('markConversationCollapsedItemsRollUp')(
    function markConversationCollapsedItemsRollUp(
        conversationReadingPaneViewState: ConversationReadingPaneViewState,
        loadedConversationItemParts: ItemPartViewState[]
    ) {
        if (hasExtendedCard(conversationReadingPaneViewState, ExtendedCardType.CalendarCard)) {
            populateRollUpForMeetingFeed(loadedConversationItemParts);
        }
    }
);

function populateRollUpForMeetingFeed(loadedConversationItemParts: ItemPartViewState[]) {
    const { length } = loadedConversationItemParts;

    const continuousCollapsedItemNodeIdsExcludingRSVP: string[] = [];
    const startIndex = isNewestOnBottom() ? 0 : length - 1;
    const indexIncrement = isNewestOnBottom() ? 1 : -1;

    for (let index = startIndex; index <= length - 1 && index >= 0; index += indexIncrement) {
        if (loadedConversationItemParts[index].isExpanded) {
            break;
        } else if (
            isFeatureEnabled('rp-citiCardV2') &&
            shouldCollapseItemPartForMeetingFeed(loadedConversationItemParts[index])
        ) {
            const item = mailStore.items.get(loadedConversationItemParts[index].itemId) as Message;
            if (!isMeetingResponse(item.ItemClass)) {
                continuousCollapsedItemNodeIdsExcludingRSVP.push(
                    loadedConversationItemParts[index].conversationNodeId
                );
            }
        }
    }
}

function shouldCollapseItemPartForMeetingFeed(viewState: ItemPartViewState): boolean {
    const item = mailStore.items.get(viewState.itemId);

    if (item && isMeetingMessage(item)) {
        return !!(item as MeetingMessage).IsOutOfDate;
    }

    return false;
}
