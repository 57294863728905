import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyMarkItemAsPhishingWeb = createLazyResolver(
    'MUTATION_MARK_ITEM_AS_PHISHING_WEB',
    () =>
        import(
            /* webpackChunkName: "ItemMarkAsPhishingMutationWebResolver"*/ './markItemAsPhishingWeb'
        ),
    m => m.markItemAsPhishingWeb
);
