import { default as setPendingAccounts } from '../mutators/setPendingAccounts';
import type { PendingAccountInfo } from '../store/schema/pendingAccounts';

/**
 * Default account loader, this should be overridden by the host specific loader
 * @returns empty list of pending account
 */
function defaultPendingAccountLoader(): Promise<PendingAccountInfo[]> {
    return Promise.resolve([]);
}

/**
 * Stores the host specific pending account loader
 */
let pendingAccountLoader = defaultPendingAccountLoader;

/**
 * Sets the host specific loader for the host
 * @param loader loader to be used by the host
 */
export function setPluggablePendingAccountLoader(loader: () => Promise<PendingAccountInfo[]>) {
    pendingAccountLoader = loader;
}

/**
 * Loads the pending accounts list from PIE, and replaces any existing pending
 * accounts in the store.
 */
export default async function reloadStore(): Promise<void> {
    const accounts = await pendingAccountLoader();
    setPendingAccounts(accounts);
}
