import { type MainWindow } from 'owa-freeze-dry';
import { getSelectedTableView, getFocusedFilterForTable } from 'owa-mail-list-store';
import { getSelectedNode } from 'owa-mail-folder-forest-store';

export function createMainWindowItem() {
    const mainWindow: Partial<MainWindow> = {
        folderId: getSelectedNode()?.id,
        rowKey: [...getSelectedTableView()?.selectedRowKeys?.keys()]?.[0],
        focusedViewFilter: getFocusedFilterForTable(getSelectedTableView()),
    };
    return mainWindow;
}
