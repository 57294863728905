import { getUserConfiguration, updateUserConfiguration } from 'owa-session-store';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
export type FocusedInboxBitFlagsMasks = any;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
export function getIsBitSet(bitMask: FocusedInboxBitFlagsMasks): boolean {
    const focusedOtherBitFlags =
        getUserConfiguration().ViewStateConfiguration?.FocusedInboxBitFlags || 0;
    return !!(bitMask & focusedOtherBitFlags);
}

/**
 * Set bit flag
 * @param value set to 1 if true, 0 otherwise
 * @param bitMask represents the nth bit
 */
export function setBit(value: boolean, bitMask: FocusedInboxBitFlagsMasks) {
    // Already same value, no need to set again and update user config
    if (getIsBitSet(bitMask) === value) {
        return;
    }

    updateUserConfiguration(userConfig => {
        updateViewStateConfiguration(value, userConfig, bitMask);
    });
}

export function updateViewStateConfiguration(
    value: boolean,
    userConfig: OwaUserConfiguration,
    bitMask: FocusedInboxBitFlagsMasks
) {
    // FocusedInboxBitFlags = 0 is a valid value so check for the undefined explicitly
    // to avoid
    if (userConfig.ViewStateConfiguration?.FocusedInboxBitFlags == undefined) {
        return;
    }

    if (value) {
        // Set the bit to 1
        userConfig.ViewStateConfiguration.FocusedInboxBitFlags |= bitMask;
    } else {
        // Clear the bit to 0
        userConfig.ViewStateConfiguration.FocusedInboxBitFlags &= ~bitMask;
    }
}
