import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FoldersNav" */ './lazyIndex'),
    {
        name: 'FoldersNav',
    }
);

export const lazyInitializeFolderNavigation = new LazyAction(
    lazyModule,
    m => m.FolderPaneNavigationManager.init
);
export const lazyHandleFolderPaneInput = new LazyAction(
    lazyModule,
    m => m.FolderPaneNavigationManager.handleInput
);
export const lazyResetFolderPaneSearchResults = new LazyAction(
    lazyModule,
    m => m.FolderPaneNavigationManager.resetSearchResults
);

export { getMessage } from './store';
