import type { MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { LicenseType } from '../services/types/serviceTypes';

export function isSalesLicensePresent(mailboxInfo: MailboxInfo): boolean {
    return (
        copilotSettingsStore(mailboxInfo).licenses.indexOf(LicenseType.SalesCopilot) >= 0 ||
        isFeatureEnabled('mon-copilot-ext-debug-addins', mailboxInfo)
    );
}
