import getAppContextMenuStateStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { IContextualMenuItem, Point } from '@fluentui/react';

export const hideAppContextMenu = mutatorAction(
    'hideAppContextMenu',
    function hideAppContextMenu() {
        getAppContextMenuStateStore().showContextMenu = false;
    }
);

export const setAppContextMenuProps = mutatorAction(
    'setAppContextMenuProps',
    function setAppContextMenuProps(
        mouseEvent: MouseEvent,
        contextualMenuItems: IContextualMenuItem[],
        showContextMenu: boolean = false
    ) {
        const mouseEventPoint: Point = { x: mouseEvent.x, y: mouseEvent.y };
        const store = getAppContextMenuStateStore();
        store.point = mouseEventPoint;
        store.contextualMenuItems = contextualMenuItems;
        store.showContextMenu = showContextMenu;
    }
);
