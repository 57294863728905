import { action } from 'satcheljs';
import type { SearchScenarioId } from 'owa-search-store';
import type React from 'react';

export const onLeftArrowPressedSearchInput = action(
    'ON_LEFT_ARROW_PRESSED_SEARCH_INPUT',
    (cursorPosition: number, scenarioId: SearchScenarioId, evt: React.KeyboardEvent<unknown>) => ({
        cursorPosition,
        scenarioId,
        evt,
    })
);
