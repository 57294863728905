import type EmailReminderSendOption from 'owa-service/lib/contract/EmailReminderSendOption';
import type EmailReminderChangeType from 'owa-service/lib/contract/EmailReminderChangeType';
import type InboxReminderType from 'owa-service/lib/contract/InboxReminderType';
import type {
    InboxReminder,
    EmailReminderChangeType as Schema_EmailReminderChangeType,
    EmailReminderSendOption as Schema_EmailReminderSendOption,
} from 'owa-graph-schema';
import { assertNever } from 'owa-assert';
import { bindTypename } from 'bind-typenames';

export function convertInboxRemindersToGql(inboxReminders?: InboxReminderType[]): InboxReminder[] {
    return inboxReminders ? inboxReminders.map(convertAnInboxReminderToGql, []) : [];
}

function convertAnInboxReminderToGql(inboxReminder: InboxReminderType): InboxReminder {
    const { SendOption, OccurrenceChange } = inboxReminder;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return bindTypename(
        {
            ...inboxReminder,
            SendOption: SendOption ? mapEmailReminderSendOption(SendOption) : undefined,
            OccurrenceChange:
                OccurrenceChange != undefined
                    ? mapEmailReminderChangeType(OccurrenceChange)
                    : undefined,
        },
        'InboxReminder'
    )!;
}

function mapEmailReminderChangeType(
    emailReminderChangeType: EmailReminderChangeType
): Schema_EmailReminderChangeType {
    switch (emailReminderChangeType) {
        case 0:
            return 'None';
        case 1:
            return 'Added';
        case 2:
            return 'Override';
        case 3:
            return 'Deleted';
        default:
            return assertNever(emailReminderChangeType);
    }
}

function mapEmailReminderSendOption(
    emailReminderSendOption: EmailReminderSendOption
): Schema_EmailReminderSendOption {
    switch (emailReminderSendOption) {
        case 0:
            return 'NotSet';
        case 1:
            return 'User';
        case 2:
            return 'AllAttendees';
        case 3:
            return 'Staff';
        case 4:
            return 'Customer';
        default:
            return assertNever(emailReminderSendOption);
    }
}
