import { resetFocus } from 'owa-mail-focus-manager';
import { getSelectedTableView } from 'owa-mail-list-store';
import { lazyShowSweepDialog } from 'owa-mail-sweep-view';
import { getIsSharedMailbox } from 'owa-shared-mailbox';
import type { MailboxInfo } from 'owa-client-types';

export default function onSweep(mailboxInfo: MailboxInfo) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    getIsSharedMailbox(mailboxInfo);
    lazyShowSweepDialog.importAndExecute(rowKeys, tableView, mailboxInfo);
    resetFocus('sweepCommand');
}
