import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { getAddIns } from 'owa-mail-compose-controls/lib/components/addins';
import { getM365Apps } from 'owa-mail-compose-controls/lib/components/m365apps';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getIsReadButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsReadButtonDisabled';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import {
    getDiscoverGroupsControl,
    getQuickStepsControl,
    getPinUnpinControl,
    getRestoreControl,
    getRestoreAllControl,
    getRibbonCustomizerControl,
    getSnoozeControl,
    getSweepControl,
    getUndoControl,
    getViewGroupsPanelControl,
} from '../../sharedControls/readSharedControlDefinitions';
import {
    getDeleteControl,
    getMoveControl,
    getNewMessageControl,
} from './slrHomeTabControlDefinitions';
import {
    getArchiveControl,
    getAssignPolicyControl,
    getBlockControl,
    getCategorizeControl,
    getFlagUnflagControl,
    getPrintControl,
    getUnreadReadControl,
    getReportControl,
    getReplyWithMeetingControl,
    getCopilotReplyWithMeetingFlyoutControl,
    getCopilotReplyWithMeetingControl,
    getForwardControl,
    getRespondFlyoutControl,
    getReplyControl,
    getReplyAllControl,
    getRulesControl,
    getMessageRecallControl,
    getTrackReadReceiptsControl,
    getChatAroundEmailControl,
    getMessageResendControl,
    getShareToTeamsChatControl,
    getReadMessageExtensionsControl,
} from '../../sharedControls/readProjectionSharedControlDefinitions';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

/**
 * This function supplies a mapping from RibbonControlId to their respective control definition function.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getSLRHomeTabControlDefinition = owaComputedFn(function getSLRHomeTabControlDefinition(
    controlId: RibbonControlId,
    tabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps | undefined {
    const isControlDisabled: boolean = getIsReadButtonDisabled(
        controlId,
        props.isPopout,
        getRibbonMailboxInfo(props),
        undefined /*itemId*/
    );
    switch (controlId) {
        case 529:
            return getAddIns(
                undefined /* editorId */,
                getDefaultRibbonStyles(),
                tabId,
                isControlDisabled
            );
        case 7022:
            return getM365Apps(
                undefined /* editorId */,
                getDefaultRibbonStyles(),
                tabId,
                isControlDisabled,
                props
            );
        case 7016:
            return getReadMessageExtensionsControl(true /*isSLR*/, 1, props);
        case 505:
            return getArchiveControl(true /*isSLR*/, 1, props);
        case 616:
            return getAssignPolicyControl(true /*isSLR*/, 1, props);
        case 658:
            return getBlockControl(true /*isSLR*/, 1, props);
        case 508:
            return getDiscoverGroupsControl(1, props);
        case 509:
            return getCategorizeControl(true /*isSLR*/, 1, props);
        case 519:
            return getDeleteControl(props);
        case 527:
            return getFlagUnflagControl(true /*isSLR*/, 1, props);
        case 540:
            return getMoveControl(1, props);
        case 587:
            return getNewMessageControl(1, props);
        case 550:
            return getQuickStepsControl(true /*isSLR*/, props);
        case 548:
            return getPinUnpinControl(true /*isSLR*/, 1, props);
        case 549:
            return getPrintControl(true /*isSLR*/, 1, props);
        case 552:
            return getUnreadReadControl(true /*isSLR*/, 1, props);
        case 657:
            return getReportControl(true /*isSLR*/, 1, props);
        case 553:
            return getReplyWithMeetingControl(true /*isSLR*/, 1, props);

        case 698:
            return getCopilotReplyWithMeetingFlyoutControl(true /*isSLR*/, 1, props);
        case 696:
            return getCopilotReplyWithMeetingControl(true /*isSLR*/, 1, props);

        case 555:
            return getForwardControl(true /*isSLR*/, 1, props);
        case 678:
            return getRespondFlyoutControl(true /*isSLR*/, 1, props);
        case 556:
            return getReplyControl(true /*isSLR*/, 1, props);
        case 557:
            return getReplyAllControl(true /*isSLR*/, 1, props);
        case 683:
            return getRestoreControl(true /*isSLR*/, 1, props);
        case 684:
            return getRestoreAllControl(true /*isSLR*/, 1, props);
        case 558:
            return getRibbonCustomizerControl(1, props);
        case 559:
            return getRulesControl(true /*isSLR*/, 1, props);
        case 571:
            return getSnoozeControl(true /*isSLR*/, 1, props);
        case 572:
            return getSweepControl(true /*isSLR*/, 1, props);
        case 580:
            return getUndoControl(true /*isSLR*/, 1, props);
        case 663:
            return getViewGroupsPanelControl(true /*isSLR*/, 1, props);
        case 686:
            return getMessageRecallControl(true /*isSLR*/, 1, props);
        case 697:
            return getTrackReadReceiptsControl(true /*isSLR*/, 1, props);
        case 702:
            return getChatAroundEmailControl(true /*isSLR*/, 1, props);
        case 712:
            return getMessageResendControl(true /*isSLR*/, 1, props);
        case 709:
            return getShareToTeamsChatControl(true /*isSLR*/, 1, props);
        default:
            debugErrorThatWillShowErrorPopupOnly(
                'getSLRHomeTabControlDefinition UnknownControl: ' + controlId
            );
            return undefined;
    }
});
