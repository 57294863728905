import type {
    MenuItemControlProps,
    RibbonButtonProps,
    RibbonFlyoutAnchorProps,
    RibbonSplitButtonProps,
} from '@1js/acui-ribbon-like';
import {
    createAppButton,
    createAppFlyoutAnchor,
    createAppSearchBox,
    createAppSplitButton,
    createGeneratedToggleMenuItem,
    getRibbonIconColor,
} from 'owa-command-ribbon';
import {
    getDefaultRibbonStyles,
    getDefaultRibbonStylesForSelected,
} from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import type { AppSearchBoxProps } from '@1js/acui-search-box';
import Checkmark from 'owa-fluent-icons-svg/lib/icons/CheckmarkFilled';
import type { ClientItemId } from 'owa-client-ids';
import type { IButtonStyles, IKeytipProps } from '@fluentui/react';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { MenuDefinition } from '@1js/acui-menu';
import type { RibbonControlDefinitionProps } from './RibbonControlDefinitionProps';
import { getIsReadButtonDisabled } from './getIsReadButtonDisabled';
import { getRibbonMailboxInfo } from './getRibbonMailboxInfo';
import type { AppSplitButtonOnExecuteParameter } from '@1js/acui-button';

/**
 * creates RibbonButtonProps object for use with MailRibbon
 * @param id Must be unique and may be used to determine enabledness and icon color
 * @param label Label displayed to the user
 * @param tooltip Hover tooltip
 * @param props control props
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for button
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object
 * @param itemId Optional itemId, used in read pop outs
 * @param overrides Optional object/array of other RibbonButtonProps props to override.
 */
export const createMailAppButton = (
    id: number | string,
    label: string,
    tooltip: string | undefined,
    props: RibbonControlDefinitionProps,
    onExecute: () => void,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    itemId?: ClientItemId,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7006 (55,5): Parameter 'overrides' implicitly has an 'any' type.
    // @ts-expect-error
    overrides?
): RibbonButtonProps => {
    return createAppButton(
        id,
        label,
        onExecute,
        keytipProps,
        icon,
        iconColor,
        styles ?? getDefaultRibbonStyles(),
        {
            customTooltip: tooltip,
            disabled: getIsReadButtonDisabled(
                id,
                props.isPopout,
                getRibbonMailboxInfo(props),
                itemId
            ),
            ...overrides,
        }
    );
};

/**
 * creates RibbonButtonProps object for use with MailRibbon
 * @param id Must be unique and may be used to determine enabledness and icon color
 * @param label Label displayed to the user
 * @param tooltip Hover tooltip
 * @param props control props
 * @param menuDefinition Object defining the menu shown when the flyout is invoked
 * @param keytipProps Optional keytip for button
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object
 * @param itemId Optional itemId, used in read pop outs
 * @param overrides Optional object/array of other RibbonFlyoutAnchorProps props to override.
 */
export const createMailAppFlyoutAnchor = (
    id: number | string,
    label: string,
    tooltip: string | undefined,
    props: RibbonControlDefinitionProps,
    menuDefinition: MenuDefinition,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    itemId?: ClientItemId,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7006 (137,5): Parameter 'overrides' implicitly has an 'any' type.
    // @ts-expect-error
    overrides?
): RibbonFlyoutAnchorProps => {
    return createAppFlyoutAnchor(
        id,
        label,
        menuDefinition,
        keytipProps,
        icon,
        iconColor,
        styles ?? getDefaultRibbonStyles(),
        {
            customTooltip: tooltip,
            disabled: getIsReadButtonDisabled(
                id,
                props.isPopout,
                getRibbonMailboxInfo(props),
                itemId
            ),
            ...overrides,
        }
    );
};

/**
 * creates RibbonButtonProps object for use with MailRibbon
 * @param id Must be unique and may be used to determine enabledness and icon color
 * @param label Label displayed to the user
 * @param splitButtonAriaLabel Accessible label for the dropdown chevron button.
 * @param tooltip Hover tooltip
 * @param props control props
 * @param menuDefinition Object defining the menu shown when the split button chevron is invoked
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for button
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object
 * @param itemId Optional itemId, used in read pop outs
 * @param overrides Optional object/array of other RibbonSplitButtonProps props to override.
 */
export const createMailAppSplitButton = (
    id: number | string,
    label: string,
    splitButtonAriaLabel: string | undefined,
    tooltip: string | undefined,
    props: RibbonControlDefinitionProps,
    menuDefinition: MenuDefinition,
    onExecute: (param?: AppSplitButtonOnExecuteParameter) => void,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    itemId?: ClientItemId,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7006 (183,5): Parameter 'overrides' implicitly has an 'any' type.
    // @ts-expect-error
    overrides?
): RibbonSplitButtonProps => {
    return createAppSplitButton(
        id,
        label,
        menuDefinition,
        onExecute,
        keytipProps,
        icon,
        iconColor,
        styles ?? getDefaultRibbonStyles(),
        {
            customTooltip: tooltip,
            disabled: getIsReadButtonDisabled(
                id,
                props.isPopout,
                getRibbonMailboxInfo(props),
                itemId
            ),
            splitButtonAriaLabel,
            ...overrides,
        }
    );
};

/**
 * creates MenuItemControlProps object for use with the WAC Ribbon package. Used for generated menu items (i.e. from a custom list of categories)
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed ot the user
 * @param tooltip Optional Hover tooltip
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for button
 * @param isSelected boolean. When `true`, a checkmark icon will be used
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time. Defaults to checkmark if not provide and isSelected is true.
 * @param iconColor Optional icon foreground color override. Defaults to green if color not provided and isSelected is true.
 * @param styles Optional styles object.
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createMailGeneratedMenuItem = (
    id: number | string,
    label: string,
    tooltip: string | undefined,
    onExecute: () => void,
    keytipProps: IKeytipProps | undefined,
    isSelected: boolean,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    overrides?: Partial<MenuItemControlProps>
): MenuItemControlProps => {
    const selectedIconColor = getRibbonIconColor(625);

    return createGeneratedToggleMenuItem(
        id.toString(),
        label,
        onExecute,
        isSelected,
        icon ?? isSelected ? Checkmark : undefined,
        iconColor ?? isSelected ? selectedIconColor : undefined,
        styles ?? (isSelected ? getDefaultRibbonStylesForSelected() : getDefaultRibbonStyles()),
        {
            customTooltip: tooltip,
            keytipProps,
            ...overrides,
        }
    );
};

/**
 * creates RibbonFlyoutAnchorProps object for use with MailRibbon
 * @param id Must be unique and may be used to determine enabledness and icon color
 * @param label Label displayed to the user
 * @param tooltip Hover tooltip
 * @param menuDefinition Object defining the menu shown when the flyout is invoked
 * @param keytipProps Optional keytip for button
 * @param isSelected Optional boolean. If provided, the icon will be displayed only if true.
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time. Defaults to checkmark if not provide and isSelected is true.
 * @param iconColor Optional icon foreground color override. Defaults to green if color not provided and isSelected is true.
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createMailGeneratedMenuFlyoutAnchor = (
    id: number | string,
    label: string,
    tooltip: string | undefined,
    menuDefinition: MenuDefinition,
    keytipProps?: IKeytipProps,
    isSelected?: boolean,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7006 (319,5): Parameter 'overrides' implicitly has an 'any' type.
    // @ts-expect-error
    overrides?
): RibbonFlyoutAnchorProps => {
    return createAppFlyoutAnchor(
        id,
        label,
        menuDefinition,
        keytipProps,
        icon ?? (isSelected === true ? Checkmark : undefined),
        iconColor ?? (isSelected === true ? getRibbonIconColor(625) : undefined),
        styles ??
            (isSelected === true ? getDefaultRibbonStylesForSelected() : getDefaultRibbonStyles()),
        {
            customTooltip: tooltip,
            ...overrides,
        }
    );
};

/**
 * creates AppSearchBoxProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Placeholder text for the search box
 * @param onChangeCallback Callback when the searchbox contents change. Empty box returns "undefined" newValue
 * @param keytipProps Optional keytip for search box
 * @param icon Optional id of an icon to be displayed. Typically ControlIcons. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param typeProp Optional search box type override
 * @param overrides Optional object/array of other AppSearchBoxProps props to override.
 */
export const createMailAppSearchBox = (
    id: number | string,
    label: string,
    onChangeCallback: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    typeProp?: string,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7006 (398,5): Parameter 'overrides' implicitly has an 'any' type.
    // @ts-expect-error
    overrides?
): AppSearchBoxProps => {
    return createAppSearchBox(id, label, onChangeCallback, keytipProps, icon, iconColor, typeProp, {
        autoComplete: 'off',
        ...overrides,
    });
};
