import React from 'react';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { observer } from 'owa-mobx-react';
import { backgroundImage, backgroundGradient } from './AppBackground.scss';
import getBackgroundImage from '../selectors/getBackgroundImage';
import getBackgroundColor from '../selectors/getBackgroundColor';

const AppBackground = observer(function AppBackground() {
    const image = getBackgroundImage();
    const backgroundColor = getBackgroundColor();
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    const backgroundImageStyles = React.useMemo(() => {
        return {
            backgroundColor,
            backgroundImage: image ? `url(${image})` : 'none',
        };
    }, [image, backgroundColor]);

    return isBleedThroughEnabled ? (
        <div style={backgroundImageStyles} className={backgroundImage}>
            <div className={backgroundGradient} />
        </div>
    ) : null;
}, 'AppBackground');

export default AppBackground;
