import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyCreateCalendarEventMutationWeb = createLazyResolver(
    'CREATECALENDAREVENT_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "CreateCalendarEventMutationWeb" */ './createCalendarEventMutationWeb'
        ),
    m => m.createCalendarEventMutationWeb
);
