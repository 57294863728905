import { isFeatureEnabled } from 'owa-feature-flags';
import { updateServiceConfig } from 'owa-service/lib/config';
import { lazyExecuteMsalNestedAppAuthRequestProxy } from 'owa-msaljs/lib/lazyAppBoot';

export default async function initializeNestedAppAuthExecutor(): Promise<void> {
    if (isFeatureEnabled('auth-naa-web')) {
        // Nested App Auth allows hosted MetaOS/add-ins to get tokens from the host using MSAL; enable
        // this by setting the callback that will be used for fulfilling NAA requests
        updateServiceConfig({
            executeNaaRequest: (mailboxInfo, request, origin, scenarioName, callerId) =>
                lazyExecuteMsalNestedAppAuthRequestProxy.importAndExecute(
                    mailboxInfo,
                    request,
                    origin,
                    scenarioName,
                    callerId
                ),
        });
    }
}

export type InitializeNestedAppAuthExecutorFunc = typeof initializeNestedAppAuthExecutor;
