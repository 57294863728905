import { isFeatureEnabled } from 'owa-feature-flags';
import getIsCurrentThemeModern from './getIsCurrentThemeModern';
import getIsCurrentThemeModernImage from './getIsCurrentThemeModernImage';

export default function getIsMicaEffectEnabled(): boolean {
    if (isFeatureEnabled('mon-transparency-usingSystemBackdrop')) {
        return getIsCurrentThemeModern(); // theme check
    } else {
        return isFeatureEnabled('fwk-bleed-through-themes') && getIsCurrentThemeModernImage();
    }
}
