import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver, createLazySubscriptionResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OutlookFoldersWeb"*/ './lazyIndex')
);

// TODO: Naming and directory structure - to be finalized.
export const lazyUpdateFolderPermissionsWeb = createLazyResolver(
    'MUTATION_UPDATE_FOLDER_PERMISSIONS_WEB',
    lazyModule,
    m => m.updateFolderPermissionsWeb
);

export const lazySubscribeToHierarchyNotificationsWeb = createLazySubscriptionResolver(
    lazyModule,
    m => m.subscribeToHierarchyNotificationsWeb
);
