import type MailFavoritesViewState from '../store/schema/FavoritesViewState';
import store from '../store/store';
import { mutatorAction } from 'satcheljs';

export default mutatorAction(
    'setMailFavoritesViewState',
    function setMailFavoritesViewState(userIdentity: string, viewState: MailFavoritesViewState) {
        store.mailFavoritesViewState.set(userIdentity, viewState);
    }
);
