/** DO NOT EXPORT ANY OTHER TYPES **/
/* eslint-disable-next-line owa-custom-rules/no-export-star  -- (https://aka.ms/OWALintWiki)
 * Baseline; do not copy this comment. Exports should use explicit named exports.
 *	> Do not use export *; instead, use explicit named exports. */
export * from './__generated__/schema.all.interface';
/* eslint-disable-next-line owa-custom-rules/no-export-star  -- (https://aka.ms/OWALintWiki)
 * Baseline; do not copy this comment. Exports should use explicit named exports.
 *	> Do not use export *; instead, use explicit named exports. */
export * from './types/partialTypes';
export type { ResolverContext } from './types/resolverContext';
export type { GraphQLResolveInfo } from './types/graphQLResolveInfo';
export type { GraphQLResolverFn } from './types/graphQLResolverFn';
export type { LocalStateResolverCallbacks } from './types/LocalStateResolverCallbacks';
export type { LocalStateMutationFn } from './types/LocalStateMutationFn';
export type { LocalStateEventContext } from './types/LocalStateEventContext';
export type { SessionDataWithGraphQL } from './types/SessionDataWithGraphQL';

type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export function partialCast<T>(p: DeepPartial<T>): T {
    return p as T;
}
export const DEFAULT_USER_CONFIG = 'DefaultUserConfig';

export { loadTypes } from './schema/loadTypes';
