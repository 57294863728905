import type React from 'react';
import { useRef } from 'react';
import { useContextMenuEvent } from 'owa-react-hooks/lib/useContextMenuEvent';
import type { HTMLTags } from '../schema/HTMLTags';
import type { AppContextMenuItem } from '../schema/AppContextMenuItem';
import type { handleNativeContextMenu as HandleNativeContextMenu } from '../utils/handleNativeContextMenu';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyHandleNativeContextMenu } from '../lazyFunctions';
import {
    type ContextMenuRequestedArguments,
    getEnhancedHostExtendedHost,
} from 'owa-config/lib/enhancedHost';

let isUseContextMenuEventTriggered = false;

export function useAppContextMenu(
    containerReference: React.RefObject<any>,
    customMenuItemsByTag?: Map<HTMLTags, AppContextMenuItem[]>
): void {
    const contextMenuEvent = useRef<MouseEvent | null>(null);
    let handleNativeContextMenu: typeof HandleNativeContextMenu | undefined = undefined;

    useContextMenuEvent((e: MouseEvent) => {
        contextMenuEvent.current = e;
        isUseContextMenuEventTriggered = true;
    }, containerReference);

    if (isFeatureEnabled('fwk-contextMenu')) {
        const extendedHost = getEnhancedHostExtendedHost();
        if (extendedHost) {
            const loadPromise = lazyHandleNativeContextMenu
                .import()
                .then(fn => (handleNativeContextMenu = fn));
            extendedHost.onContextMenuRequested(
                async (contextMenuRequestedArguments: ContextMenuRequestedArguments) => {
                    if (isUseContextMenuEventTriggered) {
                        await loadPromise;
                        handleNativeContextMenu?.(
                            contextMenuRequestedArguments,
                            contextMenuEvent,
                            customMenuItemsByTag
                        );
                        isUseContextMenuEventTriggered = false;
                    } else {
                        /**
                         * Every invocation of onContextMenuRequested needs to be paired with a call to
                         * selectedCommandId on the parameter, otherwise could create unexpected issues with the call
                         * to the Native Context Menu commands
                         */
                        contextMenuRequestedArguments.selectedCommandId = -1;
                    }
                }
            );
        }
    }
}
