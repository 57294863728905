import { mutatorAction } from 'satcheljs';
import { setSelectedTab, setSelectedTabMultiWindow } from '../store/store';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type HelpRibbonTabId } from 'owa-ribbon-ids/lib/helpControlId';
import { isFeatureEnabled } from 'owa-feature-flags';

export default mutatorAction(
    'saveSelectedTab',
    (tabId: string | undefined, targetWindow: Window = window) => {
        if (tabId == undefined) {
            if (isFeatureEnabled('cmp-multiWindows-selectedTab')) {
                setSelectedTabMultiWindow(undefined /* tabId */, targetWindow);
            }
            return;
        }

        switch (parseInt(tabId)) {
            case 1:
                setSelectedTab(1, targetWindow);
                break;
            case 10:
                setSelectedTab(10, targetWindow);
                break;
            case 3:
                setSelectedTab(3, targetWindow);
                break;
            case 5:
                setSelectedTab(5, targetWindow);
                break;
            case 4:
                setSelectedTab(4, targetWindow);
                break;
            case 6:
                setSelectedTab(6, targetWindow);
                break;
            case 7:
                setSelectedTab(7, targetWindow);
                break;
            case 8:
                setSelectedTab(8, targetWindow);
                break;
            case 11:
                setSelectedTab(11, targetWindow);
                break;
            case 11000:
                setSelectedTab(11000, targetWindow);
                break;
            case 12:
                setSelectedTab(12, targetWindow);
                break;
            case 13:
                setSelectedTab(13, targetWindow);
                break;

            default:
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * Error constructor names can only be a string literals.
                 *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                throw new Error(
                    'the tab ' + tabId + ' has not been added to the file saveSelectedTabMutator.ts'
                );
        }
    }
);
