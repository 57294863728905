import { orchestrator } from 'satcheljs';
import { onSelectFolderComplete } from 'owa-mail-shared-actions/lib/onSelectFolderComplete';
import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import { getFolderByDistinguishedId } from 'owa-folders';
import setUnseenCount from '../mutators/setUnseenCount';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

orchestrator(onSelectFolderComplete, () => {
    if (
        getSelectedFolder() === getFolderByDistinguishedId('inbox', getModuleContextMailboxInfo())
    ) {
        // clear the unseen count
        setUnseenCount(false);
    }
});
