import { action } from 'satcheljs';
import type { PublicFolderStore } from '../store/publicFolderFavoriteStore';
import type { MailFolder } from 'owa-graph-schema';

export default action(
    'ADD_PUBLIC_FOLDER_TO_STORE',
    (state: PublicFolderStore, item: MailFolder) => {
        return {
            state,
            item,
        };
    }
);
