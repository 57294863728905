import type NoEndRecurrence from 'owa-service/lib/contract/NoEndRecurrence';
import type NumberedRecurrence from 'owa-service/lib/contract/NumberedRecurrence';
import type RecurrencePatternBaseType from 'owa-service/lib/contract/RecurrencePatternBaseType';
import type RecurrenceRangeBaseType from 'owa-service/lib/contract/RecurrenceRangeBaseType';
import type RecurrenceType from 'owa-service/lib/contract/RecurrenceType';
import type RegeneratingPatternBaseType from 'owa-service/lib/contract/RegeneratingPatternBaseType';
import type RelativeMonthlyRecurrence from 'owa-service/lib/contract/RelativeMonthlyRecurrence';
import type RelativeYearlyRecurrence from 'owa-service/lib/contract/RelativeYearlyRecurrence';
import type WeeklyRecurrence from 'owa-service/lib/contract/WeeklyRecurrence';
import type AbsoluteMonthlyRecurrence from 'owa-service/lib/contract/AbsoluteMonthlyRecurrence';
import type AbsoluteYearlyRecurrence from 'owa-service/lib/contract/AbsoluteYearlyRecurrence';
import type EndDateRecurrence from 'owa-service/lib/contract/EndDateRecurrence';
import type {
    RecurrencePatternType,
    RecurrenceRangeType,
    RecurrenceType as Schema_RecurrenceType,
} from 'owa-graph-schema';
import type DailyRecurrence from 'owa-service/lib/contract/DailyRecurrence';
import {
    REGENERATING_PATTERN_BASE_RECURRENCE_TYPE_NAME,
    NUMBERED_RECURRENCE,
    END_DATE_RECURRENCE,
    NO_END_RECURRENCE,
    RELATIVE_YEARLY_RECURRENCE_TYPE_NAME,
    ABSOLUTE_MONTHLY_RECURRENCE_TYPE_NAME,
    DAILY_RECURRENCE_TYPE_NAME,
    WEEKLY_RECURRENCE_TYPE_NAME,
    RELATIVE_MONTHLY_RECURRENCE_TYPE_NAME,
    ABSOLUTE_YEARLY_RECURRENCE_TYPE_NAME,
} from 'owa-recurrence-types';

/**
 * We need to handle this manually because
 * RecurrencePatternBaseType is an empty object that any number of classes may
 * subclass, but Schema.RecurrencePatternType is a union of possible object types.
 * They can't be manually mapped between.
 *
 * We also have to add explicit __typename fields, since the client has to be able to
 * distinguish the value into one of the union members.
 */
function recurrencePattern(pattern: RecurrencePatternBaseType): RecurrencePatternType {
    switch (pattern.__type) {
        case ABSOLUTE_YEARLY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'AbsoluteYearlyRecurrence',
                ...(pattern as AbsoluteYearlyRecurrence),
            };
        }
        case ABSOLUTE_MONTHLY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'AbsoluteMonthlyRecurrence',
                ...(pattern as AbsoluteMonthlyRecurrence),
            };
        }
        case WEEKLY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'WeeklyRecurrence',
                ...(pattern as WeeklyRecurrence),
            };
        }
        case DAILY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'DailyRecurrence',
                ...(pattern as DailyRecurrence),
            };
        }
        case REGENERATING_PATTERN_BASE_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'RegeneratingPatternBaseType',
                ...(pattern as RegeneratingPatternBaseType),
            };
        }
        case RELATIVE_YEARLY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'RelativeYearlyRecurrence',
                ...(pattern as RelativeYearlyRecurrence),
            };
        }
        case RELATIVE_MONTHLY_RECURRENCE_TYPE_NAME: {
            return {
                __typename: 'RelativeMonthlyRecurrence',
                ...(pattern as RelativeMonthlyRecurrence),
            };
        }
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(`unhandled recurrence pattern ${pattern.__type}`);
}

/**
 * Maps between known implementers of the OWS base class RecurrenceRangeBaseType and
 * the union of supported subtypes from the SChema
 *
 * See the docstring on recurrencePattern() for details
 */
function recurrenceRange(range: RecurrenceRangeBaseType): RecurrenceRangeType {
    switch (range.__type) {
        case END_DATE_RECURRENCE: {
            return { __typename: 'EndDateRecurrence', ...(range as EndDateRecurrence) };
        }
        case NO_END_RECURRENCE: {
            return { __typename: 'NoEndRecurrence', ...(range as NoEndRecurrence) };
        }
        case NUMBERED_RECURRENCE: {
            return { __typename: 'NumberedRecurrence', ...(range as NumberedRecurrence) };
        }
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(`unhandled recurrence range ${range.__type}`);
}

export function convertRecurrenceTypeToGql(recurrenceType: RecurrenceType): Schema_RecurrenceType {
    return {
        __typename: 'RecurrenceType',
        RecurrencePattern: recurrenceType.RecurrencePattern.hasOwnProperty('__typename')
            ? (recurrenceType.RecurrencePattern as RecurrencePatternType) //When recurrence is already in Gql format
            : recurrencePattern(recurrenceType.RecurrencePattern),
        RecurrenceRange: recurrenceType.RecurrenceRange.hasOwnProperty('__typename')
            ? (recurrenceType.RecurrenceRange as RecurrenceRangeType) //When recurrence is already in Gql format
            : recurrenceRange(recurrenceType.RecurrenceRange),
    };
}
