import { store, multiAccountStore } from './store';
import type ExtensibilityState from './schema/ExtensibilityState';
import type TaskPaneRunningInstance from './schema/TaskPaneRunningInstance';
import type { ExtensibilityHostItem } from './schema/ExtensibilityHostItem';
import { initializeCompliance } from './schema/Compliance';
import type { ContextualCalloutState } from './schema/ContextualCalloutState';
import type RunningInstance from './schema/RunningInstance';
import { ObservableMap } from 'mobx';
import type IExtendedAddinCommand from './schema/interfaces/IExtendedAddinCommand';
import type TaskPaneType from './schema/TaskPaneType';
import type { ActiveDialog } from './schema/interfaces/Dialog';
import type { SoftBlockDialogObservableState } from './schema/enums/SoftBlockOnSendConstants';
import type { OwaDate } from 'owa-datetime';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { trace } from 'owa-trace';
import type { IEnabledAddinCommands } from 'owa-addins-types';
import type { Extension, AddInAcquisition } from 'owa-addins-contracts';
import { isAddinMultiAccountEnabled } from 'owa-feature-flags';
import type { ExtensibilityHostItemInstance } from './schema/ExtensibilityHostItemInstance';

export default function getExtensibilityState(mailboxInfo?: MailboxInfo): ExtensibilityState {
    if (!isAddinMultiAccountEnabled()) {
        return store;
    }
    if (!mailboxInfo) {
        trace.warn('[ExtensibilityState] - No mailboxInfo provided, using global settings');
        mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let extState = multiAccountStore.get(key);
    if (!extState) {
        const defaultExtState = {
            /*
            Remove the typecast below while removing ts-expect in the EnabledAddinCommands object creation
            at owa-addins-store/src/store/store.ts
            */
            /* eslint-disable-next-line owa-custom-rules/no-recasting-null-undefined  -- (https://aka.ms/OWALintWiki)
             * Baseline; please fix or update justification when touching this code.
             *	> Do not cast null as another type. */
            EnabledAddinCommands: null as any as IEnabledAddinCommands,
            HostItems: new ObservableMap<string, ExtensibilityHostItem>(),
            IsPreinstalledTeachingUIAvailable: false,
            Context: null,
            taskPanes: new ObservableMap<
                string /*key: tabId*/,
                ObservableMap<TaskPaneType, TaskPaneRunningInstance>
            >(),
            activeDialogs: new ObservableMap<string /*key: tabId*/, ActiveDialog>(),
            ExtensibilityStateIsDirty: false,
            contextualCalloutState: new ObservableMap<
                string /*key: tabId*/,
                ContextualCalloutState
            >(),
            runningContextualAddinCommand: new ObservableMap<
                string /*key: tabId*/,
                RunningInstance
            >(),
            runningUILessExtendedAddinCommands: new ObservableMap<
                string /*key: tabId*/,
                ObservableMap<string, IExtendedAddinCommand>
            >(),
            autoRunAddinCommandWaitingQueue: new ObservableMap<
                string /*key: tabId*/,
                Array<IExtendedAddinCommand>
            >(),
            compliance: initializeCompliance(),
            activeAutorunUilessFrames: new ObservableMap<string, any>(),
            currentSoftBlockDialogState: new ObservableMap<
                string /*key: tabId*/,
                SoftBlockDialogObservableState
            >(),
            telemetryLoggedInfo: new Map<string, Map<string, OwaDate>>(),
            addinsMap: new Map<string, Extension | AddInAcquisition>(),
            multiSelectEnabledAddinsCommand: [],
            innerPersistedAddins: {},
            HostItemInstance: new Map<string, ExtensibilityHostItemInstance>(),
            isOnSendPromptUserAddinInstalled: false,
            onSendAddinsCompletedExecutionForHostItemIndex: new Map<string, string[]>(), // key: hostItemIndex, value: addinId[]
            hasAppDeeplink: false,
        };

        multiAccountStore.set(key, defaultExtState);
        extState = multiAccountStore.get(key) as ExtensibilityState;
        trace.info(
            'getExtensibilityState for identity' +
                mailboxInfo.userIdentity +
                ' is undefined, creating. Total states:' +
                multiAccountStore.size
        );
    }
    return extState;
}
