import type { TableView, GroupFolderTableQuery } from 'owa-mail-list-store';
import { getSelectedTableView, type TableQueryType } from 'owa-mail-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';

export function getSelectedGroupId(): string {
    const tableView = getSelectedTableView();
    return getGroupId(tableView);
}

export function getGroupId(tableView: TableView): string {
    let groupId = null;

    if (tableView) {
        if (tableView.tableQuery.type === 2) {
            groupId = tableView.tableQuery.folderId;
        } else if (isFeatureEnabled('grp-loadFolders') && tableView.tableQuery.type === 3) {
            groupId = (tableView.tableQuery as GroupFolderTableQuery).groupId;
        }
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (31,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    return groupId;
}
