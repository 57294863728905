import getGroupHeaderForSearchTable from './getGroupHeaderForSearchTable';
import getGroupHeaderForSortByDateTime from './getGroupHeaderForSortByDateTime';
import getGroupHeaderForSortByFromTo from './getGroupHeaderForSortByFromTo';
import getGroupHeaderForSortBySize from './getGroupHeaderForSortBySize';
import getGroupHeaderForImportance from './getGroupHeaderForImportance';
import type GroupHeaderGenerator from '../type/GroupHeaderGenerator';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import {
    type TableView,
    type TableQuery,
    type MailFolderTableQuery,
    type SortColumn,
} from 'owa-mail-list-store';
import { mapTableQueryToTableViewOptions } from 'owa-mail-tableview-options';
import { assertNever } from 'owa-assert';
import { isFeatureEnabled } from 'owa-feature-flags';
import { owaComputedFn } from 'owa-computed-fn';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isGroupTableQuery } from 'owa-group-utils';
import getGroupHeaderForSortByCategory from './getGroupHeaderForSortByCategory';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { SearchTab } from 'owa-search-types/lib/types/SearchTab';

/**
 * Gets the header generator depending on the current settings
 * @param tableView for which to get the header generator
 * @returns GroupHeaderGenerator
 */
export default function getMailListGroupHeaderGenerator(
    tableView: TableView
): GroupHeaderGenerator | null {
    const headerGeneratorType = mapTableQueryToTableViewOptions(
        tableView.tableQuery
    ).headerGeneratorType;

    switch (headerGeneratorType) {
        case 'RelevanceHybrid':
            return getMailListGroupHeaderGenerator_Search(tableView.tableQuery);

        case 'FromTo':
            return mailListGroupHeaderGenerator_FromTo;

        case 'Size':
            return mailListGroupHeaderGenerator_Size;

        case 'Importance':
            return mailListGroupHeaderGenerator_Importance;

        case 'Subject':
            // No group headers for subject
            return mailListGroupHeaderGenerator_None;

        case 'Category':
            return mailListGroupHeaderGenerator_Category;

        case 'DateTime':
            return getMailListGroupHeaderGenerator_DateTime(tableView.tableQuery);

        default:
            assertNever(headerGeneratorType);
    }
}

const getMailListGroupHeaderGenerator_Search = (tableQuery: TableQuery): GroupHeaderGenerator => {
    // Hide the first [and only] header when the table is showing the Mail tab SERP
    const hideFirstHeader = (tableQuery as SearchTableQuery).searchTab == SearchTab.Mail;

    return {
        getGroupHeader: getGroupHeaderForSearchTable,
        hideFirstHeader,
    };
};

const mailListGroupHeaderGenerator_FromTo: GroupHeaderGenerator = {
    getGroupHeader: getGroupHeaderForSortByFromTo,
    hideFirstHeader: false,
};

const mailListGroupHeaderGenerator_Size: GroupHeaderGenerator = {
    getGroupHeader: getGroupHeaderForSortBySize,
    hideFirstHeader: false,
};

const mailListGroupHeaderGenerator_Importance: GroupHeaderGenerator = {
    getGroupHeader: getGroupHeaderForImportance,
    hideFirstHeader: false,
};

const mailListGroupHeaderGenerator_Category: GroupHeaderGenerator = {
    getGroupHeader: getGroupHeaderForSortByCategory,
    hideFirstHeader: false,
};

const mailListGroupHeaderGenerator_None = null;

const getMailListGroupHeaderGenerator_DateTime = owaComputedFn(
    (tableQuery: TableQuery): GroupHeaderGenerator | null => {
        if (getIsBitSet(2, getGlobalSettingsAccountMailboxInfo())) {
            return mailListGroupHeaderGenerator_None;
        }

        if (
            folderIdToName(tableQuery.folderId) === 'scheduled' &&
            (tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn === 15
        ) {
            return mailListGroupHeaderGenerator_None;
        }

        // hide first header only when table supports pinning unless in monarch, where we have a header for pinned.
        const hideFirstHeader = isFeatureEnabled('mon-tri-monarchPinnedGroupHeader')
            ? false
            : isGroupTableQuery(tableQuery) || shouldTableSortByRenewTime(tableQuery);

        return {
            getGroupHeader: getGroupHeaderForSortByDateTime,
            hideFirstHeader,
        };
    }
);
