import { mutatorAction } from 'satcheljs';
import { default as getPendingAccountStore } from '../store/pendingAccountsStore';
import type { PendingAccountInfo } from '../store/schema/pendingAccounts';

const addPendingAccount = mutatorAction(
    'ADD_PENDING_ACCOUNT',
    function addPendingAccount(account: PendingAccountInfo) {
        const store = getPendingAccountStore();
        if (
            !store.pendingAccounts.find(
                pendingAccount => pendingAccount.emailAddress === account.emailAddress
            )
        ) {
            store.pendingAccounts.push(account);
        }
    }
);

export default addPendingAccount;
