import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from './getGroupInformation';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getGroupDisplayName(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): string | null {
    const group = getGroupInformation(mailboxInfo, transformGroupSmtp(groupSmtpAddress));
    return group?.groupDetails?.DisplayName ?? group?.basicInformation.DisplayName ?? null;
}
