import type AttachmentPermissionType from 'owa-service/lib/contract/AttachmentPermissionType';
import type { AttachmentPermission } from 'owa-graph-schema';

export const attachmentPermissionType = (
    type: AttachmentPermissionType | undefined
): AttachmentPermission | null => {
    switch (type) {
        case 0:
            return 'None';
        case 1:
            return 'View';
        case 2:
            return 'Edit';
        case 3:
            return 'AnonymousView';
        case 4:
            return 'AnonymousEdit';
        case 5:
            return 'OrganizationView';
        case 6:
            return 'OrganizationEdit';
        case undefined:
            return null;
    }
};

export const attachmentPermissionTypeToOws = (
    type: AttachmentPermission | null | undefined
): AttachmentPermissionType => {
    switch (type) {
        case 'None':
            return 0;
        case 'View':
            return 1;
        case 'Edit':
            return 2;
        case 'AnonymousView':
            return 3;
        case 'AnonymousEdit':
            return 4;
        case 'OrganizationView':
            return 5;
        case 'OrganizationEdit':
            return 6;
        default:
            return 0;
    }
};
