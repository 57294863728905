import loc from 'owa-localize';
import {
    goToMailCommand,
    goToCalendarCommand,
    goToPeopleCommand,
    goToToDoCommand,
    goToTasksCommand,
    goToDevToolsCommand,
    goToEdgeTracingToolsCommand,
    jumpFocus,
} from './AppModuleHotKeys.locstring.json';
import { canShowToDoModule, getModuleForTasks, ModuleForTasks } from 'owa-todo-module-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { HotkeyCommand, Hotkeys } from 'owa-hotkeys';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { ResourceId } from 'owa-localize';
import { F6_HOTKEY_COMMANDS } from 'owa-accessibility-loop-nav/lib/utils/f6HotkeysCommands';

export const GoToCategory = 'goTo';

export function getAppModuleCommands() {
    const commands: {
        [index: string]: HotkeyCommand;
    } = {
        gotoMail: createHotkey(goToMailCommand, 'ctrl+shift+1', 'ctrl+1'),
        gotoCalendar: createHotkey(goToCalendarCommand, 'ctrl+shift+2', 'ctrl+2'),
        gotoPeople: createHotkey(goToPeopleCommand, 'ctrl+shift+3', 'ctrl+3'),
    };

    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (getModuleForTasks(mailboxInfo) !== ModuleForTasks.None) {
        commands.gotoToDo = createHotkey(
            canShowToDoModule(mailboxInfo) ? goToToDoCommand : goToTasksCommand,
            'ctrl+shift+4',
            'ctrl+4'
        );
    }

    if (isFeatureEnabled('fwk-customDevToolsHotKeys')) {
        commands.goToDevTools = createHotkey(goToDevToolsCommand, 'ctrl+shift+d', 'ctrl+shift+d');
        commands.goToEdgeTracingTools = createHotkey(
            goToEdgeTracingToolsCommand,
            'ctrl+shift+t',
            'ctrl+shift+t'
        );
    }

    return commands;
}

export function getF6Hotkeys() {
    const commands: {
        [index: string]: HotkeyCommand;
    } = {
        f6: wrapF6Hotkeys(F6_HOTKEY_COMMANDS.f6),
        shiftF6: wrapF6Hotkeys(F6_HOTKEY_COMMANDS.shiftF6),
        ctrlTab: wrapF6Hotkeys(F6_HOTKEY_COMMANDS.ctrlTab),
        ctrlShiftTab: wrapF6Hotkeys(F6_HOTKEY_COMMANDS.ctrlShiftTab),
    };
    return commands;
}

function wrapF6Hotkeys(hotkey: HotkeyCommand) {
    return {
        ...hotkey,
        category: GoToCategory,
        description: loc(jumpFocus),
    };
}

function createHotkey(
    descriptionResourceId: ResourceId,
    hotkey: Hotkeys,
    outlookHotkey: Hotkeys
): HotkeyCommand {
    return {
        category: GoToCategory,
        description: loc(descriptionResourceId),
        hotmail: hotkey,
        yahoo: hotkey,
        gmail: hotkey,
        owa: hotkey,
        outlook: outlookHotkey,
    };
}
