import { orchestrator } from 'satcheljs';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { newMailNotificationAction } from 'owa-app-notifications-core';
import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import { getFolderByDistinguishedId } from 'owa-folders';
import setUnseenCount from '../mutators/setUnseenCount';
import type NewMailNotificationPayload from 'owa-service/lib/contract/NewMailNotificationPayload';
import { getAccountScopeUserSettings } from 'owa-session-store';

orchestrator(newMailNotificationAction, actionMessage => {
    const { notification, mailboxInfo } = actionMessage;
    const mailboxInfoInternal = mailboxInfo ?? getModuleContextMailboxInfo();
    if (
        (notification as NewMailNotificationPayload).EventType != 'Reload' && // Dont set the unseen count if event type is reload
        (getSelectedFolder() !== getFolderByDistinguishedId('inbox', mailboxInfoInternal) ||
            !document.hasFocus()) && // If Focused inbox is enabled, we should only add "Focused" mails to the unseen count
        (notification.InferenceClassification === 'Focused' ||
            !getAccountScopeUserSettings(mailboxInfoInternal).UserOptions?.IsFocusedInboxEnabled)
    ) {
        setUnseenCount(true);
    }
});
