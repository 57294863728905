import type {
    MutationUpdateCalendarHexColorArgs,
    ResolverContext,
    CalendarEntry,
    UpdateCalendarHexColorMutation_CalendarEntry,
    CalendarsUnionType,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';
import type CalendarActionFolderIdResponse from 'owa-service/lib/contract/CalendarActionFolderIdResponse';
import setCalendarHexColorOperation from 'owa-service/lib/operation/setCalendarHexColorOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import {
    validateHexColorString,
    convertHexColorStringToNumber,
} from 'calendar-color-resolver-utils';
import itemId from 'owa-service/lib/factory/itemId';

/**
 * Mutation Web resolver responsible for updateCalendarHexColor
 */
export const updateCalendarHexColorMutationWeb = async (
    _parent: any,
    args: MutationUpdateCalendarHexColorArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarEntry> => {
    const { calendarId, hexColor } = args.input;

    if (!validateHexColorString(hexColor)) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `updateCalendarHexColorMutationWeb: invalid hex color provided. Color value: ${hexColor}`
        );
    }

    const hexInt = convertHexColorStringToNumber(hexColor);

    const response: CalendarActionFolderIdResponse = await setCalendarHexColorOperation(
        {
            itemId: itemId({ Id: calendarId.id, ChangeKey: calendarId.changeKey ?? undefined }),
            hexColor: hexInt,
        },
        getMailboxRequestOptions(calendarId.mailboxInfo)
    );

    if (response == null || !response.WasSuccessful) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `updateCalendarHexColorMutationWeb: failed to update calendar color. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }

    const partialCalendarEntry: UpdateCalendarHexColorMutation_CalendarEntry = {
        __typename: args.input.calendarTypename as CalendarsUnionType['__typename'],
        calendarId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            id: response.NewCalendarEntryId!.Id,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            changeKey: response.NewCalendarEntryId!.ChangeKey,
        },
    };

    return partialCast<CalendarEntry>(partialCalendarEntry);
};
