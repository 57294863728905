import { errorThatWillCauseAlert } from 'owa-trace';

export const getServiceType = (value: any) => {
    switch (typeof value) {
        case 'boolean':
            return 'Boolean';
        case 'string':
            return 'String';
        case 'number':
            return 'Integer32';
        case 'object':
            if (Array.isArray(value) && (typeof value[0] === 'string' || value.length === 0)) {
                return 'StringArray';
            }
            return 'DateTime';
        default:
            errorThatWillCauseAlert('Unknown user configuration setting type');
            return '';
    }
};
