import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MeetingTemplatesWebResolvers" */ './lazyIndex')
);

export const lazyReadMeetingTemplatesQueryWeb = createLazyResolver(
    'READMEETINGTEMPLATES_QUERY_WEB',
    lazyModule,
    m => m.readMeetingTemplatesQueryWeb
);
