import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import { getMailAccountSources } from 'owa-account-source-list-store';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import loc, { format } from 'owa-localize';
import {
    favoritesHeaderText,
    favoritesWithAccountNameHeaderText,
} from 'owa-locstrings/lib/strings/favoritesheadertext.locstring.json';
import {
    lazyToggleFavoritesTreeExpansion,
    getMailFavoritesViewState,
} from 'owa-mail-favorites-store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import FavoritesList from './FavoritesList';
import {
    getStore as getSharedFavoritesStore,
    isMultiAccountsCombinedFavoritesEnabled,
    isPrimaryAccountFromMailboxInfo,
    loadFavorites,
} from 'owa-favorites';
import { FolderTreeLoadStateEnum, getPrimaryMailFolders } from 'owa-folders';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyGetMoveToMruList } from 'owa-mail-move-mru';
import { lazyInitializeCache } from 'owa-recipient-cache';
import { logUsage } from 'owa-analytics';
import {
    setContextMenuState,
    showFavoritesContextMenu,
} from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import FavoritesRootContextMenu from './FavoritesRootContextMenu';
import { getAnchorForContextMenu } from 'owa-positioning';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { SourceMap } from 'owa-folders-constants';

const FAVORITES_ROOT_NODE_ID = 'favoritesRoot';

export interface FavoritesProps extends React.HTMLProps<HTMLDivElement> {
    setSize: number;
    positionInSet: number;
    mailboxInfo: MailboxInfo;
}

const Favorites = observer(function Favorites(props: FavoritesProps) {
    if (isFeatureEnabled('fp-memo-favoritesList')) {
        return <FavoritesInner {...props} />;
    } else {
        return <FavoritesOld {...props} />;
    }
}, 'Favorites');

const ariaProps: AriaProperties = {
    role: AriaRoles.tree,
    labelledBy: FAVORITES_ROOT_NODE_ID,
};

const FavoritesInner = observer(function FavoritesInner(props: FavoritesProps) {
    const { mailboxInfo } = props;

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const isPrimaryAccount = isPrimaryAccountFromMailboxInfo(mailboxInfo);
    const [isTreeExpanded, setIsTreeExpanded] = React.useState<boolean>(false);
    const onClick = React.useCallback(
        async (evt: React.MouseEvent<unknown> | KeyboardEvent) => {
            evt.stopPropagation();
            // Toggle setting only for primary account
            // otherwise setIsTreeExpanded
            if (!isPrimaryAccount) {
                // We have to await so favorites is loaded correctly
                await getPrimaryMailFolders(mailboxInfo);

                // Should be a no-op if favorites are loaded
                loadFavorites(mailboxInfo);

                // Should be a no-op if folder MRU are loaded
                lazyGetMoveToMruList.importAndExecute(mailboxInfo);

                // Initialize cache for people suggestions
                lazyInitializeCache.importAndExecute(mailboxInfo);

                // Expand the tree
                setIsTreeExpanded(!isTreeExpanded);
            } else {
                lazyToggleFavoritesTreeExpansion.importAndExecute();
            }
        },
        [isPrimaryAccount, mailboxInfo, isTreeExpanded]
    );

    const getSelectedEmailAddress = () => {
        if (getMailAccountSources().length > 0 && isMonarchMultipleAccountsEnabled()) {
            return mailboxInfo.mailboxSmtpAddress;
        }

        return null;
    };
    const selectedEmailAddress = getSelectedEmailAddress();
    const displayName =
        selectedEmailAddress && !isMultiAccountsCombinedFavoritesEnabled()
            ? format(loc(favoritesWithAccountNameHeaderText), selectedEmailAddress)
            : loc(favoritesHeaderText);

    const onContextMenu = React.useCallback((evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        logUsage('FP_ShowFavoriteRootCtxMenu');
        showFavoritesContextMenu(
            'favoritesRoot',
            0, // not used by this context menu
            getAnchorForContextMenu(evt),
            undefined /*folderId*/,
            true /*showRootMenu*/
        );
    }, []);

    let shouldShowTreeExpanded: boolean;
    // We will show the tree expanded/collapsed based on setting only for primary account
    // Primary accounts will always have favorites loaded as part of sessiondata
    // other accounts will be collapsed and favorites will loaded on demand
    if (!isPrimaryAccount) {
        shouldShowTreeExpanded =
            isTreeExpanded &&
            getSharedFavoritesStore().favoriteTreeData.get(key)?.loadingState ===
                FolderTreeLoadStateEnum.Loaded;
    } else {
        shouldShowTreeExpanded =
            !getUserConfiguration()?.UserOptions?.IsFavoritesFolderTreeCollapsed;
    }

    logUsage('TnS_FavoriteFoldersExpandedState', [isPrimaryAccount, shouldShowTreeExpanded]);

    const chevronProps = React.useMemo(() => {
        return {
            isExpanded: shouldShowTreeExpanded,
            onClick,
            isAnimated: true,
        };
    }, [shouldShowTreeExpanded, onClick]);

    return (
        <div
            style={props.style}
            className={props.className}
            {...generateDomPropertiesForAria(ariaProps)}
        >
            <>
                <TreeNode
                    id={FAVORITES_ROOT_NODE_ID}
                    chevronProps={chevronProps}
                    displayName={displayName}
                    depth={0}
                    isRootNode={true}
                    isSelected={false}
                    key="favoritesRoot"
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    setSize={props.setSize}
                    positionInSet={props.positionInSet}
                    ellipsesOnHover={true}
                    source={SourceMap.account}
                    shouldShowFocusBorder={isFeatureEnabled('fp-jump-folder')}
                />
                <FavoritesRootContextMenuWrapper mailboxInfo={props.mailboxInfo} />
            </>

            <CSSTransition
                in={shouldShowTreeExpanded}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <FavoritesList key="favoriteFolderList" mailboxInfo={mailboxInfo} />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}, 'FavoritesInner');

const FavoritesRootContextMenuWrapper = observer(function FavoritesRootContextMenuWrapper(props: {
    mailboxInfo: MailboxInfo;
}) {
    const { mailboxInfo } = props;

    const key = getIndexerValueForMailboxInfo(mailboxInfo);

    const onContextMenuDismissed = React.useCallback(() => {
        setContextMenuState(key, null);
    }, [key]);

    const contextMenuState = getMailFavoritesViewState(key)?.contextMenuState;
    // Render the context menu if it is set to be visible
    if (!contextMenuState || !contextMenuState.showRootMenu) {
        return null;
    }

    return (
        <FavoritesRootContextMenu
            anchorPoint={contextMenuState.anchor}
            onDismiss={onContextMenuDismissed}
            mailboxInfo={props.mailboxInfo}
        />
    );
},
'FavoritesRootContextMenuWrapper');

const FavoritesOld = observer(function FavoritesOld(props: FavoritesProps) {
    const { mailboxInfo } = props;

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const isPrimaryAccount = isPrimaryAccountFromMailboxInfo(mailboxInfo);
    const [isTreeExpanded, setIsTreeExpanded] = React.useState<boolean>(false);
    const onClick = React.useCallback(
        async (evt: React.MouseEvent<unknown> | KeyboardEvent) => {
            evt.stopPropagation();
            // Toggle setting only for primary account
            // otherwise setIsTreeExpanded
            if (!isPrimaryAccount) {
                // We have to await so favorites is loaded correctly
                await getPrimaryMailFolders(mailboxInfo);

                // Should be a no-op if favorites are loaded
                loadFavorites(mailboxInfo);

                // Should be a no-op if folder MRU are loaded
                lazyGetMoveToMruList.importAndExecute(mailboxInfo);

                // Initialize cache for people suggestions
                lazyInitializeCache.importAndExecute(mailboxInfo);

                // Expand the tree
                setIsTreeExpanded(!isTreeExpanded);
            } else {
                lazyToggleFavoritesTreeExpansion.importAndExecute();
            }
        },
        [isPrimaryAccount, mailboxInfo, isTreeExpanded]
    );

    const getSelectedEmailAddress = () => {
        if (getMailAccountSources().length > 0 && isMonarchMultipleAccountsEnabled()) {
            return mailboxInfo.mailboxSmtpAddress;
        }

        return null;
    };

    const onContextMenuDismissed = () => {
        setContextMenuState(key, null);
    };

    const renderContextMenuIfOpen = (): JSX.Element | null => {
        const contextMenuState = getMailFavoritesViewState(key)?.contextMenuState;
        // Render the context menu if it is set to be visible
        if (!contextMenuState || !contextMenuState.showRootMenu) {
            return null;
        }

        return (
            <FavoritesRootContextMenu
                anchorPoint={contextMenuState.anchor}
                onDismiss={onContextMenuDismissed}
                mailboxInfo={props.mailboxInfo}
            />
        );
    };

    const onContextMenu = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        logUsage('FP_ShowFavoriteRootCtxMenu');
        showFavoritesContextMenu(
            'favoritesRoot',
            0, // not used by this context menu
            getAnchorForContextMenu(evt),
            undefined /*folderId*/,
            true /*showRootMenu*/
        );
    };

    const renderFavoriteRoot = (
        chevronProps: {
            isExpanded: boolean;
            onClick: (evt: React.MouseEvent<unknown> | KeyboardEvent) => void;
            isAnimated: boolean;
        },
        setSize: number,
        positionInSet: number
    ): JSX.Element => {
        const selectedEmailAddress = getSelectedEmailAddress();
        const displayName =
            selectedEmailAddress && !isMultiAccountsCombinedFavoritesEnabled()
                ? format(loc(favoritesWithAccountNameHeaderText), selectedEmailAddress)
                : loc(favoritesHeaderText);

        // We cannot use orderedFavoritesNodeIds here, because user might delete favorites on other client and the list hasn't been updated.
        // favoritesFolderNodes and favoritesPersonaNodes are the filtered nodes which represent the nodes that we could show.
        return (
            <>
                <TreeNode
                    id={FAVORITES_ROOT_NODE_ID}
                    chevronProps={chevronProps}
                    displayName={displayName}
                    depth={0}
                    isRootNode={true}
                    isSelected={false}
                    key="favoritesRoot"
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    setSize={setSize}
                    positionInSet={positionInSet}
                    ellipsesOnHover={true}
                    source={SourceMap.account}
                    shouldShowFocusBorder={isFeatureEnabled('fp-jump-folder')}
                />
                {renderContextMenuIfOpen()}
            </>
        );
    };

    let shouldShowTreeExpanded: boolean;
    // We will show the tree expanded/collapsed based on setting only for primary account
    // Primary accounts will always have favorites loaded as part of sessiondata
    // other accounts will be collapsed and favorites will loaded on demand
    if (!isPrimaryAccount) {
        shouldShowTreeExpanded =
            isTreeExpanded &&
            getSharedFavoritesStore().favoriteTreeData.get(key)?.loadingState ===
                FolderTreeLoadStateEnum.Loaded;
    } else {
        shouldShowTreeExpanded =
            !getUserConfiguration()?.UserOptions?.IsFavoritesFolderTreeCollapsed;
    }

    logUsage('TnS_FavoriteFoldersExpandedState', [isPrimaryAccount, shouldShowTreeExpanded]);

    const chevronProps = React.useMemo(() => {
        return {
            isExpanded: shouldShowTreeExpanded,
            onClick,
            isAnimated: true,
        };
    }, [shouldShowTreeExpanded, onClick]);

    return (
        <div
            style={props.style}
            className={props.className}
            {...generateDomPropertiesForAria(ariaProps)}
        >
            {renderFavoriteRoot(chevronProps, props.setSize, props.positionInSet)}

            <CSSTransition
                in={shouldShowTreeExpanded}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <FavoritesList key="favoriteFolderList" mailboxInfo={mailboxInfo} />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}, 'FavoritesOld');

export default Favorites;
