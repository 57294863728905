import { LazyModule } from 'owa-bundling';
import type * as Mark from 'mark.js';

const lazyMarkJS = new LazyModule(() => import(/* webpackChunkName: "MarkJS" */ 'mark.js'), {
    name: 'MarkJS',
});

// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS2322 (9,5): Type 'null' is not assignable to type 'typeof Mark'.
// @ts-expect-error
let MarkJS: typeof Mark = null;
export async function getMarkJS(): Promise<typeof Mark> {
    if (!MarkJS) {
        const result = await lazyMarkJS.importModule('markJs');
        MarkJS = result.default;
    }
    return MarkJS;
}

export const SPAN_ELEMENT_NAME = 'span';
export const UNMARK_ELEMENT_LABEL = 'unmark';

export const MARK_ID_PREFIX = 'mark';
export const MARK_ID_NUMBER_LEN = 9;
