import { type ApolloLink } from '@apollo/client';
import { localRemoteRouterLink } from '../links/localRemoteRouterLink';
import { DataSource } from 'owa-analytics-types';
import executeGraphLink from '../links/executeGraphLink';
import type { ResolverImports } from '../factories/createGraphServerLink';

export function mailboxServerLink(
    context: Record<string, any>,
    resolverImports: ResolverImports,
    remoteLink: ApolloLink
) {
    const resolvers = resolverImports.offline || resolverImports.web;
    const dataSource = resolverImports.offline ? undefined : DataSource.NETWORK_GQL;
    const localLink = executeGraphLink(resolvers, context, dataSource);
    const isOfflineEnabled = !!resolverImports.offline;
    return localRemoteRouterLink({
        localLink,
        remoteLink,
        resolvers,
        isOfflineEnabled,
    });
}
