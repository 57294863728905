import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppbarMutationWeb" */ './lazyIndex')
);

export const lazyPinM365ApplicationMutationWeb = createLazyResolver(
    'APPHOST_PIN_M365_ACQUISITION_MUTATION_WEB',
    lazyModule,
    m => m.pinM365ApplicationMutationWeb
);

export const lazyUnpinM365ApplicationMutationWeb = createLazyResolver(
    'APPHOST_UNPIN_M365_ACQUISITION_MUTATION_WEB',
    lazyModule,
    m => m.unpinM365ApplicationMutationWeb
);

export const lazyMovePinnedM365ApplicationMutationWeb = createLazyResolver(
    'APPHOST_MOVE_PINNED_M365_APPLICATION_MUTATION_WEB',
    lazyModule,
    m => m.movePinnedM365ApplicationMutationWeb
);
