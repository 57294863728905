import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getMessagesControl, getLayoutControl } from './slrViewTabControlDefinitions';
import {
    getDensityControl,
    getRibbonCustomizerControl,
    getViewSettingsControl,
    getRemindersWindowControl,
    getExpandCollapseConversationControl,
    getSyncMailboxControl,
} from '../../sharedControls/readSharedControlDefinitions';
import {
    getImmersiveReaderControl,
    getZoomControl,
} from '../../sharedControls/readProjectionSharedControlDefinitions';
import { logUsage } from 'owa-analytics';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

/**
 * This function supplies a mapping from RibbonControlId to their respective control definition function.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getSLRViewTabControlDefinition = owaComputedFn(function getSLRViewTabControlDefinition(
    controlId: RibbonControlId,
    _tabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps | undefined {
    switch (controlId) {
        case 649:
            return getDensityControl(true /*isSLR*/, 3, props);
        case 532:
            return getImmersiveReaderControl(true /*isSLR*/, 3, props);
        case 654:
            return getMessagesControl(props);
        case 646:
            return getLayoutControl(props);
        case 558:
            return getRibbonCustomizerControl(3, props);
        case 643:
            return getViewSettingsControl(true /*isSLR*/, 3, props);
        case 690:
            return getRemindersWindowControl(true /*isSLR*/, 3, props);
        case 687:
            return getExpandCollapseConversationControl(props);
        case 688:
            return getZoomControl(true /*isSLR*/, 3, props);
        case 701:
            return getSyncMailboxControl(true /*isSLR*/, 3, props);
        default:
            debugErrorThatWillShowErrorPopupOnly(
                'getSLRViewTabControlDefinition UnknownControl: ' + controlId
            );
            logUsage('SLRViewTabControlDefinitionUnknownControl', {
                controlId,
                stack: new Error('SLRViewTabControlDefinitionUnknownControl').stack,
            });
            return undefined;
    }
});
