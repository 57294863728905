import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ScheduleConversationWebResolver" */ './lazyIndex')
);

export const lazyScheduleConversationWeb = createLazyResolver(
    'MUTATION_SCHEDULE_CONVERSATION_WEB',
    lazyModule,
    m => m.scheduleConversationWeb
);

export const lazyUndoScheduleConversationWeb = createLazyResolver(
    'MUTATION_UNDO_SCHEDULE_CONVERSATION_WEB',
    lazyModule,
    m => m.undoScheduleConversationWeb
);
