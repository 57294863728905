import type { MailboxInfo } from 'owa-client-types';
import { getAccessTokenforResourceAsLazy } from 'owa-tokenprovider';

const OCPSRESOURCEURI = 'https://clients.config.office.net/';

export default async function getOcpsTokenForBusiness(mailboxInfo: MailboxInfo) {
    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        OCPSRESOURCEURI,
        'OwaOcpsPolicy',
        undefined /* requestId */,
        undefined /* targetTenantId */,
        undefined /* wwwAuthenticateHeader */,
        undefined /* showFullTokenResponse */,
        undefined /* perferIdpToken */,
        mailboxInfo
    );

    if (!token) {
        token = await tokenPromise;
    }
    return token;
}
