import { createContext, useContext } from 'react';
import type { ConditionalFormattingModification } from 'owa-conditional-formatting';

export interface MailListItemContainerContextProps {
    isHovered: boolean;
    conditionalFormattingModifications: ConditionalFormattingModification[];
}
const MailListItemContainerContext = createContext<MailListItemContainerContextProps>({
    isHovered: false,
    conditionalFormattingModifications: [],
});

export const MailListItemContainerContextProvider = MailListItemContainerContext.Provider;

export default function useMailListItemContainerContext() {
    return useContext(MailListItemContainerContext);
}
