import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { isPstFileAccountInfraEnabled } from 'owa-account-source-list/lib/flights';

/**
 * Whether or not the mailbox supports modifications, e.g. creating, updating, or deleting things.
 * If this is disabled, the mailbox should be treated as read-only.
 *
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const mailboxModificationCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // If the mailbox supports modifications, they're always allowed
        return true;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return (
            !mailboxInfo /*allowed by default*/ ||
            !isPstFileAccountInfraEnabled() /*allowed if PST file infra changes are inactive*/ ||
            !isLocalDataBackedMailboxInfo(
                mailboxInfo
            ) /*only disallowed for local data backed mailboxes (e.g. PST files)*/
        );
    },
};
