import { getStore } from '../store/store';
import type FavoriteNodeViewState from '../store/schema/FavoriteNodeViewState';
import createDragViewState from 'owa-dnd/lib/utils/createDragViewState';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import setFavoriteNodeViewStateMutator from '../mutators/setFavoriteNodeViewStateMutator';

export default function getFavoriteNodeViewStateFromId(favoriteId: string): FavoriteNodeViewState {
    if (!getStore().favoriteNodeViewStates.has(favoriteId)) {
        setFavoriteNodeViewStateMutator(favoriteId, {
            drag: createDragViewState(),
            drop: createDropViewState(),
        });
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (18,5): Type 'FavoriteNodeViewState | undefined' is not assignable to type 'FavoriteNodeViewState'.
    // @ts-expect-error
    return getStore().favoriteNodeViewStates.get(favoriteId);
}
