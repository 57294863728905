import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DeleteConversationMutationWeb" */ './lazyIndex')
);

export const lazyDeleteConversationWeb = createLazyResolver(
    'MUTATION_DELETE_CONVERSATION_WEB',
    lazyModule,
    m => m.deleteConversationWeb
);

export const lazyUndoDeleteConversationWeb = createLazyResolver(
    'MUTATION_UNDO_DELETE_CONVERSATION_WEB',
    lazyModule,
    m => m.undoDeleteConversationWeb
);
