import type {
    OneJs_MeetingOptionsConfiguration,
    OneJs_MeetingOptionsExternalFeedViewData,
    OneJs_MeetingOptionsLockedTooltipViewData,
    OneJs_MeetingOptionsViewDataResolvers,
} from 'owa-graph-schema';
import loc from 'owa-localize';
import {
    adminLockedTooltipString,
    copyConfirmationString,
    copyKeyString,
    copyLinkString,
    genericErrorMessageString,
    labelLockedTooltipString,
    learnMoreString,
    saveButtonLabelString,
    savingButtonLabelString,
    confirmationDialogLabelString,
    confirmationDialogTextString,
    confirmationDialogContinueButtonString,
    confirmationDialogDiscardButtonString,
    townhallLockedTooltipString,
    webinarLockedTooltipString,
    closeButtonLabelString,
    dialogTitleString,
    dropdownMenuHeaderString,
} from './meetingOptionsStrings.locstring.json';

export const OneJS_MeetingOptionsViewData: OneJs_MeetingOptionsViewDataResolvers = {
    // id: lazyMeetingOptionsViewDataId,
    closeButtonLabel: () => loc(closeButtonLabelString),
    dialogTitle: () => loc(dialogTitleString),
    dropdownMenuHeader: () => loc(dropdownMenuHeaderString),
    saveButtonLabel: () => loc(saveButtonLabelString),
    savingButtonLabel: () => loc(savingButtonLabelString),
    confirmationDialogLabel: () => loc(confirmationDialogLabelString),
    confirmationDialogText: () => loc(confirmationDialogTextString),
    confirmationDialogContinueButton: () => loc(confirmationDialogContinueButtonString),
    confirmationDialogDiscardButton: () => loc(confirmationDialogDiscardButtonString),
    genericErrorMessage: () => loc(genericErrorMessageString),
    lockedTooltipModel: (): OneJs_MeetingOptionsLockedTooltipViewData => ({
        adminLockedTooltip: loc(adminLockedTooltipString),
        labelLockedTooltip: loc(labelLockedTooltipString),
        townhallLockedTooltip: loc(townhallLockedTooltipString),
        webinarLockedTooltip: loc(webinarLockedTooltipString),
    }),
    configuration: (): OneJs_MeetingOptionsConfiguration => ({
        optionLabelWeight: 'BOLD',
        showDropdownBelowTheLabel: false,
        skin: 'web',
        dropdownAppearance: 'outline',
    }),
    externalFeedModel: (): OneJs_MeetingOptionsExternalFeedViewData => ({
        copy_confirmation: loc(copyConfirmationString),
        copy_link: loc(copyLinkString),
        copy_key: loc(copyKeyString),
        learn_more: loc(learnMoreString),
    }),
};
