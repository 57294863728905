import '../orchestrators/onPreCoprincipalAccountRemovedOrchestrator';

import {
    getCoprincipalAccounts,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';
import { loadFavorites } from 'owa-favorites';
import {
    lazyLoadUnifiedGroupsSettings,
    lazyUpdateFolderRulePermissionAllGroup,
} from 'owa-groups-shared-actions';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { MailFolder } from 'owa-graph-schema';
import { PerformanceDatapoint } from 'owa-analytics';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import folderStore, { lazyInitializeAlphabetizeFoldersList } from 'owa-folders';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { isConsumer } from 'owa-session-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import { lazyEnsureAllAdditionalAccountInfoData } from 'owa-account-source-list';
import { lazyLoadGroupAccountSourcesForCurrentSession } from 'owa-group-mail-left-nav-actions';
import { loadMailFolders } from 'owa-mail-folder-store';
import { markFunction } from 'owa-performance';
import { checkAttachmentsSearchFolderAndLoad } from '../utils/checkAttachmentsSearchFolderAndLoad';
import selectDefaultFolder from './selectDefaultFolder';

/**
 * Load the folder forest to stores, i.e. folders and favorites
 */

export default markFunction(async function loadFolderForest(sessionData?: SessionData) {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const datapoint = new PerformanceDatapoint('loadFolderForest');

    lazyInitializeAlphabetizeFoldersList.importAndExecute();

    await loadMailFolders(mailboxInfo, sessionData);

    lazyEnsureAllAdditionalAccountInfoData.importAndExecute();

    loadFavorites(mailboxInfo);

    // If the selected folder is invalid
    if (!isSelectedNodeDataValid()) {
        selectDefaultFolder('ResetInbox', sessionData);
    }

    if (isGroupsEnabled(mailboxInfo)) {
        datapoint.addCustomData({ isSourceIdAwareGroupStore: true });
        loadGroupsFolderForest();
    }
    checkAttachmentsSearchFolderAndLoad();
    datapoint.end();
}, 'ff');

function isSelectedNodeDataValid(): boolean {
    const selectedNode = getSelectedNode();
    switch (selectedNode.type) {
        case 0:
        case 1:
            const selectedFolderId = selectedNode.id;
            const folder: MailFolder | undefined = selectedFolderId
                ? folderStore.folderTable.get(selectedFolderId)
                : undefined;
            return folder !== undefined;

        case 2:
            return true;

        default:
            break;
    }

    return false;
}

async function loadGroupsFolderForest() {
    const accounts = getCoprincipalAccounts();
    for (const acct of accounts) {
        if (acct && isGroupsEnabled(acct.mailboxInfo)) {
            const mailboxInfo = acct.mailboxInfo;
            await lazyLoadGroupAccountSourcesForCurrentSession.importAndExecute(
                acct,
                true /* Warmup Groups*/
            );

            await lazyLoadUnifiedGroupsSettings.importAndExecute(mailboxInfo);

            if (isFeatureEnabled('grp-loadFolders') && !isConsumer(undefined, mailboxInfo)) {
                lazyUpdateFolderRulePermissionAllGroup.importAndExecute(mailboxInfo);
            }
        }
    }
}
