import { createLazySubscriptionResolver } from 'owa-lazy-resolver';

export const lazySubscribeToNotificationChannelReadySubscriptionWeb =
    createLazySubscriptionResolver(
        () =>
            import(
                /* webpackChunkName: "SubscribeToNotificationChannelReadySubscriptionWeb" */ './subscribeToNotificationChannelReadySubscriptionWeb'
            ),
        m => m.subscribeToNotificationChannelReadySubscriptionWeb
    );
