import type ExtendedPropertyUri from 'owa-service/lib/contract/ExtendedPropertyUri';
import extendedPropertyUri from 'owa-service/lib/factory/extendedPropertyUri';
const LABEL_PROPERTY_NAME = 'msip_labels';
const LABEL_MODIFY_HEADER = 'X-MS-Exchange-Organization-ModifySensitivityLabel';

export const ModificationExtendedProperty: ExtendedPropertyUri = extendedPropertyUri({
    PropertyName: LABEL_MODIFY_HEADER,
    DistinguishedPropertySetId: 'InternetHeaders',
    PropertyType: 'String',
});
export const ClpLabelExtendedProperty: ExtendedPropertyUri = extendedPropertyUri({
    PropertyName: LABEL_PROPERTY_NAME,
    DistinguishedPropertySetId: 'InternetHeaders',
    PropertyType: 'String',
});
