import { getSelectedTableView, type TableQueryType } from 'owa-mail-list-store';
import { isPublicFolder } from 'owa-folders';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * Checks if selected row in left nav is public folder
 */
export const isPublicFolderSelected = owaComputedFn(function isPublicFolderSelected(): boolean {
    const selectedTable = getSelectedTableView();
    return (
        selectedTable &&
        selectedTable.tableQuery.type == 0 &&
        isPublicFolder(selectedTable.tableQuery.folderId)
    );
});

/**
 * Gets folderId from a specified row in left nav
 */
export const getSelectedPublicFolderId = owaComputedFn(function getSelectedPublicFolderId():
    | string
    | null {
    const tableView = getSelectedTableView();
    return tableView &&
        tableView.tableQuery.type == 0 &&
        isPublicFolder(tableView.tableQuery.folderId)
        ? tableView.tableQuery.folderId
        : null;
});
