import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TeamsAuthServiceSettingsQueryWeb" */ './lazyIndex')
);
export const lazyTeamsAuthServiceSettingsQueryWeb = createLazyResolver(
    'TEAMSAUTHSERVICESETTINGS_QUERY_WEB',
    lazyModule,
    m => m.teamsAuthServiceSettingsQueryWeb
);
