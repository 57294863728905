import { flagTitle, unflagTitle } from './getFlagCompleteHelper.locstring.json';
import loc from 'owa-localize';
import FlagFilled from 'owa-fluent-icons-svg/lib/icons/FlagFilled';
import FlagRegular from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import CheckmarkRegular from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';

import { flagIcon, flagIconUnfilled } from 'owa-mail-listitem-styles/lib/scss/FlagIconStyles.scss';

import classnames from 'owa-classnames';

export default function useFlagCompleteHelper(
    flagCompleteEntry: any,
    isFlagged: boolean,
    isComplete: boolean
) {
    if (isComplete) {
        flagCompleteEntry.title = loc(flagTitle);
        flagCompleteEntry.key = 'completeIcon';
        flagCompleteEntry.iconName = CheckmarkRegular;
    } else {
        const flagClasses = classnames(isFlagged ? flagIcon : flagIconUnfilled);
        flagCompleteEntry.title = isFlagged ? loc(unflagTitle) : loc(flagTitle);
        flagCompleteEntry.key = 'flagIcon';
        flagCompleteEntry.iconName = isFlagged ? FlagFilled : FlagRegular;
        flagCompleteEntry.iconClassName = flagClasses;
    }
    return flagCompleteEntry;
}
