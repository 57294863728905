import { logStartGreyError } from 'owa-analytics-start';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import getStore from '../store/store';
import { getOWAConnectedAccounts } from '../selectors';

let currentLogCount = 0;
const maxLogCount = 3;

/**
 * Allow the tests to reset the log count
 */
export function resetLogCount() {
    currentLogCount = 0;
}

/**
 * To suppport shared/delegate mailboxes as coprincipal accounts we need to be able to support multiple
 * coprincipal accounts with the same user identity values (such as user@example.com and shared@example.com
 * that are both authenticated as user@example.com). This means that we need to replace the user identity
 * key in the connected accounts with the indexer value of the mailbox info. This function converts the
 * indexer or user identity value into an indexer value.
 *
 * @param indexerOrUserIdentity Either an indexer value or user identity.
 * @returns The indexervalue
 */
export function getIndexerForConnectedAccount(indexerOrUserIdentity: string): string {
    if (isPersistenceIdIndexerEnabled()) {
        // If the indexer is a persistence id we can then use the passed in indexer value
        if (getStore().accountsMap.has(indexerOrUserIdentity)) {
            // The value is a valid indexer value
            return indexerOrUserIdentity;
        }

        // The passed in value was not an indexer for the connected account store, check if it is a user identity
        // by looking through the connected accounts and finding the first one that matches the user identity
        const matchingAccounts = getOWAConnectedAccounts().filter(
            account => account.userIdentity == indexerOrUserIdentity
        );
        if (matchingAccounts.length > 0) {
            // We found a matching account and can use the mailboxIndexer of the match. However in the case where
            // more than one account matches the user identity we may not have gotten the correct account. So we
            // log a grey error so that we can tell where the user identity is still being used.
            if (currentLogCount < maxLogCount) {
                ++currentLogCount;

                const errorForStack = new Error('UserIdentityUsedForConnectedAccount');
                logStartGreyError('UserIdentityUsedForConnectedAccount', errorForStack, {
                    matches: matchingAccounts.length,
                });
            }

            return matchingAccounts[0].mailboxIndexer;
        }

        // Did not find a matching indexer / user identity return the passed in value
    }

    return indexerOrUserIdentity;
}
