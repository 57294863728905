import getCopilotPrioritizeSettingsStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';

export default function getCopilotPrioritizeSettings(
    mailboxInfo: MailboxInfo
): CopilotPrioritizeSettings {
    if (!isCopilotFeatureEnabled('Inbox', mailboxInfo)) {
        const defaultSettings: CopilotPrioritizeSettings = {
            version: 1,
            // Feature is off if not supported
            prioritizationEnabled: false,
            useServicePrompt: false,
            moreImportantRules: [],
            lessImportantRules: [],
            miscellaneousRules: [],
            userPrompt: null,
            applyHighPriorityLabel: false,
            highPriorityScores: [4, 5],
            applyLowPriorityLabel: false,
            lowPriorityScores: [1, 2],
            experimentalSetting: null,
        };

        return defaultSettings;
    }

    return getCopilotPrioritizeSettingsStore(mailboxInfo).settings;
}
