import type { M365Acquisition } from 'owa-graph-schema';
import {
    lazyGetM365Acquisition,
    lazyGetM365Acquisitions,
} from 'owa-m365-acquisitions/lib/lazyFunction';
import { writeM365AppsQuery } from 'owa-nova-cache/lib/transform/writeM365AppsQuery';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';
import { getAppBarPinnedAppIdsFromUserSettings } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import type { AppBarPinnedAppsValue } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';

//add a constant to store the key locally and maintain the MRU list across sessions (like booting)
const LOCAL_STORAGE_KEY = 'mruList';

/**
 * MRUManager class to manage the Most Recently Used (MRU) list of apps.
 */
export class MRUManager {
    private mruList: M365Acquisition[] = [];
    private static shouldHide: boolean = false;

    constructor() {
        this.initializeMRUList();
    }

    /**
     * Initializes the MRU list.
     *
     * ```
     * const mruManager = new MRUManager();
     * mruManager.initializeMRUList();
     * ```
     */
    private async initializeMRUList() {
        const storedMruList: string | null = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedMruList) {
            this.mruList = JSON.parse(storedMruList);
        }
    }

    /**
     * Updates the MRU list with a new app by its appId.
     *
     * ```
     * const appId = 'some-app-id';
     * mruManager.updateMRUList(appId);
     * ```
     *
     * @param appId The ID of the app to add to the MRU list.
     */
    public updateMRUList(appId: string, mailboxInfo?: MailboxInfo): Promise<void> {
        // check whether the MRU is in the list of acquisitions, if yes, store it in acquisitions
        return lazyGetM365Acquisition
            .importAndExecute(appId, 'cache-first', mailboxInfo)
            .then(playload => {
                if (playload.acquisition) {
                    // Remove any item with the same appId and items with undefined appId
                    this.mruList = this.mruList.filter(item => item.appId && item.appId !== appId);
                    // Add the app to the top of the MRU list
                    this.mruList.unshift(playload.acquisition);
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.mruList));
                }
            })
            .catch(() => {
                logUsage('MOS-AppHostMruManager - Error updating MRU list');
            });
    }

    /**
     * Returns the sorted list of apps with MRUs followed by the remaining apps in alphabetical order.
     *
     * ```
     * const sortedAppList = mruManager.getSortedAppList();
     * ```
     *
     * @returns `M365Acquisition[]` The sorted list of apps.
     */
    public getSortedAppList(mailboxInfo?: MailboxInfo): Promise<M365Acquisition[]> {
        return lazyGetM365Acquisitions
            .importAndExecute('mos3-cache', undefined, mailboxInfo)
            .then(allAcquisitions => {
                //filter out the apps that are MRUs to get the remaining apps in the flyout
                const remainingApps = allAcquisitions.filter(item => {
                    const appId = item.appId;
                    return appId && !this.mruList.some(mruItem => mruItem.appId === appId);
                });
                //sort the remaining apps alphabetically
                remainingApps.sort((a, b) =>
                    a.titleDefinition.name.localeCompare(b.titleDefinition.name)
                );

                //return the full list of apps all pinned apps, MRU, followed by the remaining apps in alphabetical order within the flyout.
                return [...this.mruList, ...remainingApps];
            })
            .catch(() => {
                logUsage('MOS-AppHostMruManager - Error getting sorted M365 acquisitions');
                return [];
            });
    }

    /**
     * Writes the sorted app list to the query.
     *
     * ```
     * const sortedAppList = [...];
     * await mruManager.writeSortedAppList(sortedAppList);
     * ```
     *
     * @param sortedAppList The sorted list of apps to write to the query.
     */
    public writeSortedAppList(sortedAppList: M365Acquisition[], mailboxInfo?: MailboxInfo): void {
        writeM365AppsQuery(sortedAppList, mailboxInfo).catch(() => {
            logUsage('MOS-AppHostMruManager - Error writing M365 acquisitions');
        });
    }

    /**
     * Determines whether the pinning tip should be hidden.
     *
     * ```
     * const shouldHide = MRUManager.shouldHidePinningTip();
     * ```
     *
     * @returns `boolean` True if the pinning tip should be hidden, false otherwise.
     */
    public static shouldHidePinningTip(): boolean {
        return MRUManager.shouldHide;
    }

    /**
     * Sets the shouldHide flag to the provided value. Preserve the current value if it is already set to false.
     *
     * ```
     * MRUManager.setShouldHide(true);
     * ```
     *
     * @param value The value to set the shouldHide flag to.
     */
    public static setShouldHide(value: boolean): void {
        MRUManager.shouldHide = MRUManager.shouldHide ? value : false;
    }

    /**
     * Adds an app to the MRU list, updates the sorted list and write the query.
     *
     * ```
     * const appId = 'some-app-id';
     * await mruManager.AddAppToMRUList(appId);
     * ```
     *
     * @param appId The ID of the app to add to the MRU list.
     */
    public AddAppToMRUList(appId: string, mailboxInfo?: MailboxInfo): void {
        this.onPromptToPinApp(appId, mailboxInfo)
            .then(shouldPromptToPinApp => {
                MRUManager.setShouldHide(!shouldPromptToPinApp);
            })
            .then(() => this.updateMRUList(appId, mailboxInfo))
            .then(() => this.getSortedAppList(mailboxInfo))
            .then(sortedAppList => this.writeSortedAppList(sortedAppList, mailboxInfo))
            .catch(() => {
                logUsage('MOS-AppHostMruManager - Error adding app to MRU list');
            });
    }

    /**
     * Determines whether to prompt the user to pin an app to the AppBar based on its MRU (Most Recently Used) frequency.
     * This method will be exported to pinAppToAppBar.tsx to decide when the prompt card should be shown or hidden.
     *
     * @param {string} appId - The ID of the app being checked.
     * @param {MailboxInfo} [mailboxInfo] - Optional mailbox information used to fetch user settings.
     * @returns {Promise<boolean>} - Resolves to true if the user should be prompted to pin the app, false otherwise.
     */
    public onPromptToPinApp(appId: string, mailboxInfo?: MailboxInfo): Promise<boolean> {
        return new Promise(resolve => {
            /**
             * Fetch the list of pinned apps from user settings.
             * If the app is already pinned, either in lockedApps or pinnedApps, return false to avoid prompting the user.
             */
            const pinnedApps: AppBarPinnedAppsValue | undefined =
                getAppBarPinnedAppIdsFromUserSettings(mailboxInfo);
            if (
                pinnedApps &&
                (pinnedApps.lockedApps.includes(appId) || pinnedApps.pinnedApps.includes(appId))
            ) {
                resolve(false);
                return;
            }
            /**
             * Check if the app is in the top three positions of the MRU list.
             * If it is, return true to prompt the user to pin the app.
             */
            const appIndex = this.mruList.findIndex(item => item.appId === appId);
            resolve(appIndex > -1 && appIndex < 3);
        });
    }
}

const mruManager = new MRUManager();

// Export a single instance of MRUManager to be keep the MRU list in sync across the app
export function getAppHostMruManager() {
    return mruManager;
}
