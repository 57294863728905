import { isPrefetchDisabled } from './isPrefetchDisabled';
import shouldPrefetchModel from './shouldPrefetchModel';
import type { LazyAction, LazyModule } from 'owa-bundling';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { lazyLoadConversation, lazyLoadItem } from 'owa-mail-store-actions';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { lazyGovern, GovernPriority } from 'owa-tti';
import type { ClientItemId } from 'owa-client-ids';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import type { LoadConversationItemActionSource } from 'owa-mail-store';
/**
 * Issues load conversation to make a service call for fetching this item
 * @param - rowKey - rowKey of the row that needs to be prefetched, currently only conversation rows supported
 * @param - tableView - tableView to which the item belongs
 * @param - updateOnlyIfModelExistsInCache - Flag indicating to update the row only if it is cached to get latest data for it
 * @param - actionSource - the load conversation/item action source
 */
export default function prefetchRow(
    rowKey: string,
    tableView: TableView,
    updateOnlyIfModelExistsInCache: boolean,
    actionSource: LoadConversationItemActionSource
) {
    if (isPrefetchDisabled()) {
        return;
    }

    // When prefetching a row, check if it still exists in the table
    const rowIndex = tableView.rowKeys.indexOf(rowKey);
    if (rowIndex < 0 || rowIndex > tableView.currentLoadedIndex) {
        return;
    }

    // For unstacked view, use message type if not adding or updating row (actionSource is PrefetchSingleRow)
    let listViewType;
    let rowId;
    let secondaryPrefetchAction: (() => void) | undefined;
    if (actionSource !== 'PrefetchSingleRow' && shouldShowUnstackedReadingPane()) {
        // If the row has more than one local item id
        // prefetch list view fork content by issuing a GCI call with max 100 items to return
        secondaryPrefetchAction = function () {
            rowId = MailRowDataPropertyGetter.getRowClientItemId(rowKey, tableView);
            if (MailRowDataPropertyGetter.getItemIds(rowKey, tableView)?.length > 1) {
                prefetchRowBasedOnRowId(
                    rowId,
                    updateOnlyIfModelExistsInCache,
                    actionSource == 'PrefetchFirstN'
                        ? 'PrefetchFirstNForks'
                        : 'PrefetchAdjacentRowsForksOnDelay',
                    tableView.tableQuery.listViewType
                );
            }
        };

        // Prefetch the item reading pane content
        listViewType = 1;
        rowId = MailRowDataPropertyGetter.getRowIdToShowInReadingPane(rowKey, tableView);
    } else {
        listViewType = tableView.tableQuery.listViewType;
        rowId = MailRowDataPropertyGetter.getRowClientItemId(rowKey, tableView);
    }
    prefetchRowBasedOnRowId(rowId, updateOnlyIfModelExistsInCache, actionSource, listViewType);
    secondaryPrefetchAction?.();
}

/**
 * Issues load row to make a service call for fetching the full row data
 * @param - rowKey - rowKey to prefetch
 * @param - updateOnlyIfModelExistsInCache - Flag indicating to update the row only if it is cached to get latest data for it
 * @param - actionSource - the load conversation/item action source
 * @param - listViewType
 */
export function prefetchRowBasedOnRowId(
    rowId: ClientItemId,
    updateOnlyIfModelExistsInCache: boolean,
    actionSource: LoadConversationItemActionSource,
    listViewType: ReactListViewType
) {
    if (isPrefetchDisabled() || !rowId) {
        return;
    }

    // Skip this check for addOrUpdateRow in unstacked view because we prefetch items, and when updating row, we need
    // conversation data which may or may not be present in cache.
    if (
        !(actionSource === 'PrefetchSingleRow' && shouldShowUnstackedReadingPane()) &&
        !shouldPrefetchModel(rowId.Id, listViewType, updateOnlyIfModelExistsInCache)
    ) {
        return;
    }

    let lazyAction: LazyAction<(...args: any[]) => void, LazyModule<any>> | undefined;
    switch (listViewType) {
        case 0:
            lazyAction = lazyLoadConversation;
            break;
        case 1:
            lazyAction = lazyLoadItem;
            break;
    }

    if (lazyAction && !!rowId.Id) {
        lazyGovern.importAndExecute({
            task: () => lazyAction?.importAndExecute(rowId, actionSource),
            priority: GovernPriority.Messages,
        });
    }
}
