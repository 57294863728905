import type { SearchScenarioId } from 'owa-search-store';
import { action } from 'satcheljs';
import type SearchRequestInstrumentation from 'owa-search-service/lib/data/schema/SearchRequestInstrumentation';
import type { SearchTab } from 'owa-search-types/lib/types/SearchTab';

/**
 * Action dispatched when a search results page renders
 */
export const onSearchResultsPageRendered = action(
    'ON_SEARCH_RESULTS_PAGE_RENDERED',
    (
        scenarioId: SearchScenarioId,
        activeTab: SearchTab,
        searchRequestInstrumentation: SearchRequestInstrumentation
    ) => ({
        scenarioId,
        activeTab,
        searchRequestInstrumentation,
    })
);
