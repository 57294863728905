import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { StorageInfos } from 'owa-storage-common-types';
import { EnforcementState_Revoked } from 'owa-storage-store';
import { StorageState } from 'owa-storage-common-types';

export function getShouldShowStorageNotification(
    storageInfos: StorageInfos | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    if (!storageInfos) {
        return false;
    }

    if (
        !storageInfos.emailStorage.hasDismissedLeftNavBar &&
        storageInfos.emailStorage.state != StorageState.Normal
    ) {
        return true;
    }

    if (isFeatureEnabled('storage-msq', mailboxInfo)) {
        if (!storageInfos.microsoftStorage) {
            return false;
        }

        if (
            isFeatureEnabled('storage-revokedState', mailboxInfo) &&
            storageInfos.microsoftStorage.enforcementState === EnforcementState_Revoked
        ) {
            return false;
        }

        return (
            !storageInfos.microsoftStorage.hasDismissedLeftNavBar &&
            storageInfos.microsoftStorage.state != StorageState.Normal
        );
    }

    return false;
}
