import initializeAttachmentsForItem from './initializeAttachmentsForItem';
import type ItemViewState from '../store/schema/ItemViewState';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import { lazyInitializeMeetingRequestViewState } from 'owa-meeting-message';
import { isMeetingMessage } from 'owa-meeting-message-utils';

export interface InitializeExtendedStateForItemViewStateState {
    item: ClientItem;
}

export default async function initializeExtendedStateForItemViewState(
    itemViewState: ItemViewState,
    reinitializeAttachments: boolean = false,
    state: InitializeExtendedStateForItemViewStateState = {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (19,9): Type 'ClientItem | undefined' is not assignable to type 'ClientItem'.
        // @ts-expect-error
        item: mailStore.items.get(itemViewState.itemId),
    }
) {
    await initializeAttachmentsForItem(itemViewState, state.item, reinitializeAttachments);
    isMeetingMessage(state.item) &&
        lazyInitializeMeetingRequestViewState.importAndExecute(itemViewState);
}
