// extracted by mini-css-extract-plugin
export var compact = "QyySM";
export var container = "OVIE8";
export var containerPadding = "qQbyL";
export var containerPaddingBleedThrough = "Wfr_6";
export var containerWillChangeScrollPosition = "zjEPs";
export var droppableContainer = "slWCo";
export var full = "r5t0x";
export var isChromium = "ou4TM";
export var leftNavSectionWithSectionHeaders = "DPg26";
export var medium = "XcxaR";
export var safariScrollHandling = "bn_Zl";