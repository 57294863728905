import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import getPendingDataFiles from './getPendingDataFiles';
import getPendingEmailAccounts from './getPendingEmailAccounts';

/**
 * Returns the list of ALL pending accounts, i.e. both email accounts and data files
 */
export default function getAllPendingAccounts(): PendingAccountInfo[] {
    return getPendingEmailAccounts().concat(getPendingDataFiles());
}
