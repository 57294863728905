import type { AccountSource } from 'owa-account-source-list-store';
import type { AccountSourceType } from 'owa-account-source-list-types';
import { isPstFile, isPstFileAccountType } from 'owa-account-source-list-internal';

export function isEmailAccount(account: AccountSource): boolean {
    return !isPstFile(account);
}

export function isEmailAccountType(accountType: AccountSourceType): boolean {
    return !isPstFileAccountType(accountType);
}
