import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

import { isDataWorkerPlatformSupported } from './isDataWorkerPlatformSupported';

export function isDataWorkerPlatformEnabled(): boolean {
    return (
        isDataWorkerPlatformSupported() &&
        isFeatureEnabled('fwk-graphql-worker-link') &&
        isWorkerEnabledForMetaOSContext()
    );
}

/**
 *  Meta OS context initialization
 *      import { app } from '@microsoft/teams-js';
 *
 *      app.initialize()
 *
 *  is not worker friendly at the moment (e.g., it has dependencies on 'window' being available).
 */
function isUsingMetaOsContext() {
    return (
        isHostAppFeatureEnabled('platformAppSdk') &&
        !isFeatureEnabled('platform-disableHostAppSpecificCustomizations')
    );
}

function isWorkerEnabledForMetaOSContext(): boolean {
    return !isUsingMetaOsContext() || isFeatureEnabled('platform-enableWorkerForMetaOSContext');
}
