import { LazyModule } from 'owa-bundling-light';
import { createLazySubscriptionResolver, createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OutlookListViewWeb" */ './lazyIndex'),
    { name: 'OutlookListViewWeb' }
);

export const lazySubscribeToRowNotificationsWeb = createLazySubscriptionResolver(
    lazyModule,
    m => m.subscribeToRowNotificationsWeb
);

export const lazyConversationRowByIdWeb = createLazyResolver(
    'QUERY_CONVERSATION_ROW_BY_ID',
    lazyModule,
    m => m.conversationRowByIdWeb
);

export const lazyConversationRowsWeb = createLazyResolver(
    'QUERY_CONVERSATION_ROWS',
    lazyModule,
    m => m.conversationRowsWeb
);
