import type { default as SearchStore, SearchScenarioStore } from './schema/SearchStore';
import { ObservableMap } from 'mobx';
import type { PillSuggestion } from 'owa-search-service/lib/data/schema/SuggestionSet';
import { createStore } from 'satcheljs';

export const createDefaultSearchStore = (): SearchScenarioStore => {
    return {
        alteredQuery: '',
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (12,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        alterationProviderId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (16,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        answerPlaceholderId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (20,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        currentSearchQueryId: null,
        currentSuggestions: undefined,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (28,9): Type 'null' is not assignable to type 'Date'.
        // @ts-expect-error
        displayedQFRequestTime: null,
        flaggedTokens: [],
        isAnswerRendered: false,
        isLastKeyPressedDeletion: false,
        isPrimingCacheSession: false,
        isSuggestionsCalloutClickable: false,
        isSuggestionsDropdownVisible: false,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (37,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        isUsing3S: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (41,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        latestQFRequestId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (45,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        latestRenderedQFTraceId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (49,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        latestQFTraceId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (53,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        latestTraceId: null,
        logicalId: '',
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (57,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        nextSearchQueryId: null,
        recourseQuery: '',
        searchBoxHasFocus: false,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (63,9): Type 'null' is not assignable to type 'number'.
        // @ts-expect-error
        searchBoxWidth: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (67,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        searchSessionGuid: null,
        searchText: '',
        searchTextForSuggestion: '',
        selectedPillIndex: -1,
        selectedSuggestionIndex: -1,
        selectedSuggestionQuickActionSelected: false,
        showSearchBoxInCompactMode: false,
        isFocusOnScopeTabs: false,
        suggestedSearchTerm: '',
        suggestedSearchTermReferenceId: '',
        suggestionPillIds: [],
        transientSuggestionPillId: '',
        suggestionPills: new ObservableMap<string, PillSuggestion>(),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (81,9): Type 'null' is not assignable to type 'QueryAlterationType'.
        // @ts-expect-error
        queryAlterationType: null,
        queryActionSource: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (86,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        queryAlterationLogicalId: null,
        alterationDisplayText: '',
        traceIdToLogicalIdMap: new ObservableMap<string, string>(), // Map of Trace Ids to Logical Ids
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (92,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        tableViewId: null,
        isScopePickerVisible: false,
        filters: [],
        placeholderIdToIsRenderedMap: new ObservableMap<string, boolean>(),
        interactionStartTime: -1,
        inKeyboardSelectionMode: false,
    };
};

export const searchStore: SearchStore = {
    scenarioStores: new ObservableMap<string, SearchScenarioStore>(),
};

const store = createStore<SearchStore>('commonSearchStore', searchStore)();
export const getStore = () => store;
export default getStore;
