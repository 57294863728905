import merge from 'lodash-es/merge';
import type { Resolvers } from 'owa-graph-schema';
import { webResolvers as account } from './resolvers/account';
import { webResolvers as teamsServices } from './resolvers/teams-services';
import { webResolvers as appbar } from './resolvers/appbar';
import { webResolvers as apphost } from './resolvers/apphost';
import { webResolvers as attachment } from './resolvers/attachment';
import { webResolvers as calendar } from './resolvers/calendar';
import { webResolvers as common } from './resolvers/common';
import { webResolvers as compose } from './resolvers/compose';
import { webResolvers as configuration } from './resolvers/configuration';
import { webResolvers as conversation } from './resolvers/conversation';
import { webResolvers as groups } from './resolvers/groups';
import { webResolvers as highlights } from './resolvers/highlights';
import { webResolvers as item } from './resolvers/item';
import { webResolvers as mailfolder } from './resolvers/mailfolder';
import { webResolvers as meetingoptions } from './resolvers/meetingoptions';
import { webResolvers as onegqlview } from './resolvers/onegqlview';
import { webResolvers as persona } from './resolvers/persona';
import { webResolvers as places } from './resolvers/places';
import { webResolvers as recipients } from './resolvers/recipients';
import { webResolvers as notifications } from './resolvers/notifications';
import { webResolvers as sample } from './resolvers/sample';
import { webResolvers as subscription } from './resolvers/subscription';
import { webResolvers as view } from './resolvers/view';
import { webResolvers as search } from './resolvers/search';
import { webResolvers as meetingTemplates } from './resolvers/meetingTemplates';

export const webResolvers: Resolvers = merge(
    {},
    account,
    appbar,
    apphost,
    attachment,
    calendar,
    common,
    compose,
    configuration,
    conversation,
    groups,
    item,
    highlights,
    mailfolder,
    meetingoptions,
    notifications,
    onegqlview,
    persona,
    places,
    recipients,
    sample,
    subscription,
    view,
    search,
    meetingTemplates,
    teamsServices
);
