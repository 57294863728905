import { LazyModule, LazyImport, createLazyComponent } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SearchRefiners"*/ './lazyIndex')
);

// Lazy-load components
export const RefinersContainer = createLazyComponent(
    lazyModule,
    m => m.RefinersContainer,
    undefined,
    undefined,
    { govern: true }
);

export const QfRefinersContainer = createLazyComponent(
    lazyModule,
    m => m.QfRefinersContainer,
    undefined,
    undefined,
    { govern: true }
);

export const lazyCopyAppliedRefinersToQfRefiners = new LazyImport(
    lazyModule,
    m => m.copyAppliedRefinersToQfRefiners
);

export const lazyCopyQfRefinersToAppliedRefiners = new LazyImport(
    lazyModule,
    m => m.copyQfRefinersToAppliedRefiners
);

export const lazySetRefinersInstrumentationContext = new LazyImport(
    lazyModule,
    m => m.setRefinersInstrumentationContext
);
