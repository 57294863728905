import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyUpdateCategoryListMutationWeb = createLazyResolver(
    'UPDATE_MASTER_CATEGORY_LIST_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "UpdateMasterCategoryListMutationWeb" */ './updateMasterCategoryListMutationWeb'
        ),
    m => m.updateMasterCategoryListMutationWeb
);
