import type { MailGroupFolderRouteParameters } from './utils/mailGroupRouteSettings';
import { ensureValidGroupSmtpAddress } from './utils/mailGroupRouteSettings';
import { lazySelectGroup, lazySelectGroupFolder } from 'owa-mail-folder-forest-actions';
import {
    lazyGroupHeaderCommandBarAction,
    lazyGroupHeaderNavigationButton,
} from 'owa-group-header-actions';
import selectRow from './selectRow';
import { toggleGroupListNodeExpansion } from 'owa-groups-shared-folders-store';
import { GROUPS_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazySwitchToGroupsModule } from 'owa-groups-dash-nav-actions/lib/lazyCalendarBoot';
import getGroupsAppIsOptedIn from 'owa-groups-toggle-store/lib/utils/getGroupsAppIsOptedIn';
import isGroupsSwitchEnabled from 'owa-groups-toggle-store/lib/utils/isGroupsSwitchEnabled';
import { lazyLoadGroupsList } from 'owa-groups-left-nav-actions/lib/lazyFunction';

export default async function mailGroupFolderRouteHandler(
    parameters: MailGroupFolderRouteParameters
) {
    const groupSmtp = ensureValidGroupSmtpAddress(parameters);
    if (!groupSmtp) {
        return;
    }
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (isGroupsSwitchEnabled() && getGroupsAppIsOptedIn(mailboxInfo)) {
        const loadGroupsList = await lazyLoadGroupsList.import();
        await loadGroupsList(mailboxInfo, false /*isReload*/, true /*SubscribeAll */);
        lazySwitchToGroupsModule.importAndExecute(
            mailboxInfo,
            groupSmtp,
            parameters.folderId,
            undefined /*tabId*/,
            'MailAppGroupsFolderRouting'
        );
    } else {
        await lazySelectGroup.importAndExecute(groupSmtp, GROUPS_FOLDERS_TREE_TYPE, mailboxInfo);

        const commandBarAction = await lazyGroupHeaderCommandBarAction.import();
        const groupHeaderNavigationButton = await lazyGroupHeaderNavigationButton.import();

        lazySelectGroupFolder.importAndExecute(
            groupSmtp,
            parameters.folderId,
            'groupFolderTree',
            mailboxInfo
        );
        toggleGroupListNodeExpansion(groupSmtp, mailboxInfo);
        commandBarAction(groupHeaderNavigationButton.Email);
    }
}

export async function mailGroupFolderRowRouteHandler(parameters: MailGroupFolderRouteParameters) {
    await mailGroupFolderRouteHandler(parameters);

    if (parameters.rowId) {
        selectRow(parameters.rowId);
    }
}
