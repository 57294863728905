import expandCollapsedItemsRollUp from './expandCollapsedItemsRollUp';
import toggleSelectItemPart from './toggleSelectItemPart';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import { getFocusedItemPart } from '../utils/focusedItemPartUtils';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import { isItemPartInCollapsedItemsRollUp } from '../utils/rollUp/collapsedItemsRollUpUtils';
import { getParentItemPart } from '../utils/rollUp/rollUpUtils';
import type { ObservableMap } from 'mobx';
import type { ClientItem, ConversationReadingPaneNode } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import { OOF_ITEM_CLASS_REGEX } from 'owa-mail-store/lib/utils/constants';
import type Item from 'owa-service/lib/contract/Item';
import type Message from 'owa-service/lib/contract/Message';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';

export interface SetItemIdToScrollToState {
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>;
    conversationReadingPaneState: ConversationReadingPaneViewState;
    items: ObservableMap<string, ClientItem>;
}

/**
 * @param item
 * @param parentNode
 * @param parentItemPart
 * @return true if the item is an OOF message and its trigger message exists in allNodeIds
 */
const shouldScrollToParentNode = (
    item: Item,
    nodeId: string,
    parentItemPart: ItemPartViewState,
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>
): boolean => {
    const node = conversationNodes.get(nodeId);
    const parentNode = node?.parentInternetMessageId
        ? conversationNodes.get(node.parentInternetMessageId)
        : null;
    return !!(
        item &&
        parentItemPart &&
        parentNode &&
        item.ItemClass &&
        OOF_ITEM_CLASS_REGEX.test(item.ItemClass)
    );
};

export default action('setItemIdToScrollTo')(function setItemIdToScrollTo(
    conversationId: string,
    itemId: string,
    shouldNotGrabFocus?: boolean,
    state: SetItemIdToScrollToState = {
        conversationNodes: mailStore.conversationNodes,
        conversationReadingPaneState: getConversationReadingPaneViewState(conversationId),
        items: mailStore.items,
    }
) {
    // In case there is no ReadingPaneState, dont do anything.
    // SxS is an example where the consumer might be in compose or reading mode

    if (!state.conversationReadingPaneState) {
        return;
    }
    state.conversationReadingPaneState.itemIdToScrollTo = itemId;
    const item = state.items.get(itemId);
    if (item) {
        const nodeId = (<Message>item).InternetMessageId;
        if (nodeId) {
            const itemPart = state.conversationReadingPaneState.itemPartsMap.get(nodeId);
            const focusedItemPart = getFocusedItemPart({
                conversationReadingPaneState: state.conversationReadingPaneState,
            });
            const parentItemPart = getParentItemPart(
                nodeId,
                state.conversationReadingPaneState.itemPartsMap,
                state.conversationNodes
            );
            if (
                parentItemPart &&
                shouldScrollToParentNode(item, nodeId, parentItemPart, state.conversationNodes)
            ) {
                if (itemPart && focusedItemPart != parentItemPart) {
                    setItemToScrollTo(parentItemPart, state.conversationReadingPaneState);
                    state.conversationReadingPaneState.focusedItemPart = {
                        focusedItemArea: FocusedItemArea.Oof,
                        itemPart,
                    };
                }
                parentItemPart.oofRollUpViewState.isOofRollUpExpanded = true;
                return;
            }
            // If the itemPart exists in the conversation that's in view and is not selected, select it.
            // This will expand collapsed itemParts and select already expanded itemParts.
            if (itemPart && focusedItemPart != itemPart) {
                setItemToScrollTo(itemPart, state.conversationReadingPaneState);
                toggleSelectItemPart(
                    conversationId,
                    true /*toggleExpandedCollapsed*/,
                    itemPart,
                    undefined /* fromKeyboard */,
                    shouldNotGrabFocus
                );
            }
        }
    }
});

const setItemToScrollTo = (
    itemPart: ItemPartViewState,
    conversationReadingPaneState: ConversationReadingPaneViewState
) => {
    // Set the itemIdToScrollTo now. This will trigger the autorun on the itemParts to check if the itemId matches its own.
    // It also has logic to only call back to the reading pane to scroll if it's already expanded.
    conversationReadingPaneState.itemIdToScrollTo = itemPart.itemId;

    // If this itemPart is in collapsed items roll up, then expand the roll up first.
    if (isItemPartInCollapsedItemsRollUp(conversationReadingPaneState, itemPart)) {
        expandCollapsedItemsRollUp(conversationReadingPaneState, true /*isAuto*/);
    }
};
