import type { MailboxInfo } from 'owa-client-types';
import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import checkLanguage from './checkLanguage';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

const isCopilotFeatureEnabled = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    boolean
>('CopilotEnabled', isCopilotFeatureEnabledInternal);

export default isCopilotFeatureEnabled;

function isCopilotFeatureEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
) {
    if (store.isEnabled && isServiceRequestSupportedForMailbox(mailboxInfo)) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            return (
                isEnabledInECS(scenario, mailboxInfo) &&
                (skipLanguageCheck || checkLanguage(language, featureEnabled.langauges))
            );
        }
    }
    return false;
}

function isEnabledInECS(scenario: ImplementedCopilotFeatures, mailboxInfo: MailboxInfo): boolean {
    const appSettings = getApplicationSettings('Copilot', mailboxInfo);
    switch (scenario) {
        case 'Elaborate':
            return appSettings.elaborateEnabled;
        case 'Summarize':
            return appSettings.summarizeEnabled;
        case 'Coach':
        case 'CoachApply':
            return appSettings.coachEnabled;
        case 'SuggestedDrafts':
            return appSettings.suggestedDraftsEnabled;
        case 'SuggestCategory':
            return isFeatureEnabled('time-copilot-categories-row-v2');
        case 'ExplainPlacesReport':
            return isFeatureEnabled('msplaces-explain-report'); // client side feature flag.
        case 'Rewrite':
            return isFeatureEnabled('mon-copilot-elaborate-rewrite');
        case 'MeetingPrepSummary':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1116121
            // appSettings.meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return appSettings.meetingPrepSummaryEnabled;
        case 'ReplyByMeeting':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1123944
            // appSettings.meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return appSettings.replyByMeetingEnabled;
        case 'Chat':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1098830
            return true; // Rely on license check for Chat.
        case 'Inbox':
            return isFeatureEnabled('mon-copilot-inbox');
        case 'TimeCopilotChat':
            return appSettings.timeCopilotChatEnabled;
    }
}
