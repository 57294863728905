//This is used to pass additional properties in the OWS getCalendarEvent
import type ExtendedPropertyUri from 'owa-service/lib/contract/ExtendedPropertyUri';
import {
    SchedulingServiceUpdateUrlExtendedProperty,
    SchedulingServiceMeetingOptionsUrlExtendedProperty,
} from '../types/OnlineMeetingServiceExtendedProperty';

const getAdditionalProperties = (): ExtendedPropertyUri[] => {
    return [
        SchedulingServiceUpdateUrlExtendedProperty,
        SchedulingServiceMeetingOptionsUrlExtendedProperty,
    ];
};

export default getAdditionalProperties;
