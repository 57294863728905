import type { BodyContent as GqlBodyContent } from 'owa-graph-schema';
import type BodyContentType from 'owa-service/lib/contract/BodyContentType';

export function convertBodyContentGqlTypeToOws(bodyContent: GqlBodyContent): BodyContentType {
    return {
        BodyType: bodyContent.BodyType ?? undefined,
        Value: bodyContent.Value ?? '', // required value of type String
        QuotedText: bodyContent.QuotedText ?? undefined,
        IsTruncated: bodyContent.IsTruncated ?? undefined,
        UTF8BodySize: bodyContent.UTF8BodySize ?? undefined,
        BodyFragmentInformation:
            bodyContent.BodyFragmentInformation?.map(info => {
                return {
                    StartOffset: info.StartOffset ? info.StartOffset : undefined,
                    EndOffset: info.EndOffset ? info.EndOffset : undefined,
                };
            }) ?? undefined,
        DataUriCount: bodyContent.DataUriCount ?? undefined,
        CustomDataUriCount: bodyContent.CustomDataUriCount ?? undefined,
    };
}
