import { LazyAction, LazyImport, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import applyConversationFlagStateStoreUpdate from 'owa-mail-actions/lib/triage/applyConversationFlagStateStoreUpdate';
import deleteConversationStoreUpdate from 'owa-mail-actions/lib/triage/deleteConversationStoreUpdate';
import deleteItemsStoreUpdate from 'owa-mail-actions/lib/triage/deleteItemsStoreUpdate';
import markAllAsReadStoreUpdate from 'owa-mail-actions/lib/triage/markAllAsReadStoreUpdate';
import markConversationsAsReadStoreUpdate from 'owa-mail-actions/lib/triage/markConversationsAsReadStoreUpdate';
import updateInternetMessageId from 'owa-mail-compose-actions/lib/actions/updateInternetMessageId';
import {
    conversationReadyToLoad,
    updateItemMandatoryProperties,
    updateAttachments,
} from 'owa-mail-actions/lib/conversationLoadActions';

// webpackPrefetch is 2 means that the reading pane package could be prefetched ealier than
// the packages which have webpackPrefetch as true.
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ConversationReadingPaneStore"*/ './lazyIndex'),
    {
        name: 'ConversationReadingPaneStore',
    }
);

// Export delay loaded actions
export const lazyLoadConversationReadingPane = new LazyAction(
    lazyModule,
    m => m.loadConversationReadingPane
);
export const lazySetItemIdToScrollTo = new LazyAction(lazyModule, m => m.setItemIdToScrollTo);
export const lazyExpandCollapseAllItemParts = new LazyAction(
    lazyModule,
    m => m.expandCollapseAllItemParts
);

// Register lazy orchestrator
registerLazyOrchestrator(
    deleteItemsStoreUpdate,
    lazyModule,
    m => m.deleteItemsStoreUpdateOrchestrator
);
registerLazyOrchestrator(
    markConversationsAsReadStoreUpdate,
    lazyModule,
    m => m.markConversationsAsReadStoreUpdateOrchestrator
);
registerLazyOrchestrator(markAllAsReadStoreUpdate, lazyModule, m => m.markAllAsReadOrchestrator);
registerLazyOrchestrator(
    applyConversationFlagStateStoreUpdate,
    lazyModule,
    m => m.applyConversationFlagStateStoreUpdateOrchestrator
);
registerLazyOrchestrator(
    deleteConversationStoreUpdate,
    lazyModule,
    m => m.deleteConversationStoreUpdateOrchestrator
);
registerLazyOrchestrator(
    conversationReadyToLoad,
    lazyModule,
    m => m.conversationReadyToLoadOrchestrator
);
registerLazyOrchestrator(updateAttachments, lazyModule, m => m.updateAttachmentsOrchestrator);

registerLazyOrchestrator(
    updateItemMandatoryProperties,
    lazyModule,
    m => m.updateItemMandatoryPropertiesOrchestrator
);
registerLazyOrchestrator(
    updateInternetMessageId,
    lazyModule,
    m => m.updateInternetMessageIdOrchestrator
);

// Export delay loaded utilities
export const lazyGetLatestNonDraftItemIdFromConversation = new LazyImport(
    lazyModule,
    m => m.getLatestNonDraftItemIdFromConversation
);
export const lazyExpandCollapseQuotedBody = new LazyImport(
    lazyModule,
    m => m.expandCollapseQuotedBody
);

// Export store and schema
export { default as getConversationReadingPaneViewState } from './utils/getConversationReadingPaneViewState';
export { default as loadConversationReadingPaneForSingleMailItemSelected } from './actions/loadConversationReadingPaneForSingleMailItemSelected';
export { isAllItemPartsExpanded } from './utils/isAllItemPartsExpanded';
