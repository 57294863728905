//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MailFolderNodeFragment = {
    __typename?: 'MailFolder';
    distinguishedFolderType?: string | null;
    parentFolderId: string;
    totalMessageCount: number;
    UnreadCount: number;
    type?: string | null;
    EffectiveRights?: {
        __typename?: 'EffectiveRightsType';
        Modify?: boolean | null;
        Delete?: boolean | null;
    } | null;
};
export const MailFolderNodeFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MailFolderNode' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailFolder' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'distinguishedFolderType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'parentFolderId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'totalMessageCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'UnreadCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EffectiveRights' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'Modify' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Delete' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MailFolderNodeFragment, unknown>;
