import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SettingsMutationWeb" */ './lazyIndex')
);

export const lazyUpdateMobileDevicesStatisticsMutationWeb = createLazyResolver(
    'UPDATEMOBILEDEVICESSTATISTICS_MUTATION_WEB',
    lazyModule,
    m => m.updateMobileDevicesStatisticsMutationWeb
);

export const lazyDeleteMobileDeviceMutationWeb = createLazyResolver(
    'DELETEMOBILEDEVICE_MUTATION_WEB',
    lazyModule,
    m => m.deleteMobileDeviceMutationWeb
);

export const lazyClearMobileDeviceMutationWeb = createLazyResolver(
    'CLEARMOBILEDEVICE_MUTATION_WEB',
    lazyModule,
    m => m.clearMobileDeviceMutationWeb
);
export const lazyWriteOcsSettingsMutationWeb = createLazyResolver(
    'WRITEOCSSETTINGS_MUTATION_WEB',
    lazyModule,
    m => m.writeOcsSettingsMutationWeb
);
