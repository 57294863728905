import { type RibbonIconColor } from 'owa-command-ribbon';
import type { RibbonId } from 'owa-ribbon-ids';
import { type HelpRibbonControlId } from 'owa-ribbon-ids/lib/helpControlId';
import { type MailRibbonGroupId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';

const MailIconColorMap: Map<RibbonId, RibbonIconColor> = new Map<RibbonId, RibbonIconColor>([
    [505, 3],
    [616, 4],
    [507, 0],
    [658, 0],
    [508, 6],
    [509, 6],
    [612, 0],
    [510, 3],
    [514, 6],
    [516, 6],
    [518, 4],
    [519, 6],
    [593, 6],
    [625, 3],
    [525, 2],
    [527, 0],
    [528, 2],
    [108, 6],
    [109, 6],
    [113, 4],
    [128, 0],
    [145, 4],
    [531, 0],
    [532, 4],
    [535, 0],
    [652, 4],
    [537, 4],
    [538, 3],
    [540, 4],
    [584, 6],
    [585, 6],
    [586, 6],
    [587, 6],
    [589, 6],
    [641, 6],
    [640, 6],
    [642, 6],
    [545, 4],
    [546, 2],
    [547, 2],
    [548, 4],
    [549, 6],
    [550, 2],
    [552, 6],
    [553, 5],
    [696, 5],
    [698, 5],
    [699, 5],
    [700, 5],
    [657, 0],
    [678, 5],
    [555, 4],
    [680, 4],
    [651, 4],
    [556, 5],
    [679, 5],
    [557, 5],
    [681, 5],
    [683, 6],
    [684, 6],
    [594, 6],
    [567, 6],
    [570, 4],
    [571, 6],
    [572, 6],
    [580, 6],
    [581, 4],
    [663, 6],
    [643, 4],
    [690, 6],
    [687, 6],
    [688, 6],

    /**************** format controls ****************/
    [6018, 6],
    [6017, 6],
    [6019, 6],
    [6110, 6],
    [6001, 6],
    [6005, 6],
    [6024, 6],
    [6029, 2],
    [6007, 6],
    [6002, 6],
    [6020, 6],
    [6006, 6],
    [6008, 6],
    [6011, 2],
    [6012, 2],
    [6016, 6],
    [6030, 6],
    [6021, 6],
    [6004, 6],
    [6022, 6],
    [6023, 6],
    [6003, 6],
    [6013, 6],
    [6072, 6],

    /**************** action controls ****************/
    [638, 4],
    [620, 6],
    [7017, 6],
    [621, 6],
    [622, 6],
    [623, 4],
    [506, 4],
    [7004, 3],
    [7003, 6],
    [7029, 4],
    [639, 2],
    [7012, 2],
    [7011, 1],
    [7000, 4],
    [529, 1],
    [7016, 6],
    [7005, 0],
    [7006, 4],
    [7007, 6],
    [7021, 6],
    [7023, 6],
    [7027, 6],
    [7028, 6],
    [7001, 6],
    [560, 5],
    [7035, 5],
    [564, 4],
    [565, 6],
    [7009, 6],
    [624, 6],
    [7010, 6],
    [7002, 6],
    [7030, 6],

    /**************** help tab controls ****************/
    [11201, 1],
    [11210, 4],
    [11202, 4],
    [11203, 3],
    [11204, 5],
    [11205, 6],
]);

export function getMailIconColorMap(): Map<RibbonId, RibbonIconColor> {
    return MailIconColorMap;
}
