import type { ObservableMap } from 'mobx';
import { getGroupIdFromTableQuery } from 'owa-group-utils';
import type { TableView } from 'owa-mail-list-store';
import { getStore as getListViewStore, type TableQueryType } from 'owa-mail-list-store';

export interface GetAllTableViewsContainingItemsForGroupState {
    tableViews: ObservableMap<string, TableView>;
}

/**
 * Get all the table views that may contain items from the given group
 * @param the group id
 * @param shouldIncludeSearchTable - whether to include the search table in the table list being returned
 * @param the GetAllTableViewsContainingItemsForGroupState used for getAllTableViews
 */
export default function getAllTableViewsContainingItemsForGroup(
    groupId: string,
    shouldIncludeSearchTable: boolean,
    state: GetAllTableViewsContainingItemsForGroupState = {
        tableViews: getListViewStore().tableViews,
    }
): TableView[] {
    const { tableViews } = state;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7034 (24,11): Variable 'allTableViewsOfGroup' implicitly has type 'any[]' in some locations where its type cannot be determined.
    // @ts-expect-error
    const allTableViewsOfGroup = [];
    tableViews.forEach(tableView => {
        // Include search table if specified
        if (
            getGroupIdFromTableQuery(tableView.tableQuery) == groupId ||
            (shouldIncludeSearchTable && tableView.tableQuery.type == 1)
        ) {
            allTableViewsOfGroup.push(tableView);
        }
    });
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7005 (37,12): Variable 'allTableViewsOfGroup' implicitly has an 'any[]' type.
    // @ts-expect-error
    return allTableViewsOfGroup;
}
