import { isFeatureEnabled } from 'owa-feature-flags';
import { isBootFeatureEnabled } from 'owa-metatags';

/**
 * Determines if a flight for using the mailbox package is enabled
 * @returns True if the owa-mailbox-info package shoul be used, false otherwise
 */
export default function isMailboxPackageFlightEnabled(): boolean {
    return (
        isFeatureEnabled(
            'acct-mbxpkg',
            /*mailboxinfo*/ undefined,
            /*dontThrowErrorIfNotInitialzied*/ true
        ) || isBootFeatureEnabled('acct-mbxpkg-boot')
    );
}
