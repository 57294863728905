import onLoadInitialRowsSucceeded from '../onLoadInitialRowsSucceeded';
import { apolloErrorToResponseCode } from 'owa-apollo-errors/lib/apolloErrorToResponseCode';
import { getItemRows } from 'owa-mail-find-rows';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import {
    getMailboxInfoFromTableQuery,
    getMailboxRequestOptionsFromTableQuery,
} from 'owa-mail-mailboxinfo';
import { INITIAL_LOAD_ROW_COUNT, shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import folderId from 'owa-service/lib/factory/folderId';
import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';
import {
    getBaseFolderId,
    getBaseServerFolderId,
    type SortColumn,
    getGQLPagingInfoForQueryRows,
} from 'owa-mail-list-store';
import type { ItemRow, PagingInfoInput as GqlPagingInfo } from 'owa-graph-schema';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { PerformanceDatapoint } from 'owa-analytics';
import { returnTopExecutingActionDatapoint } from 'owa-analytics';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderStore from 'owa-folders';
import type { MailboxInfo } from 'owa-client-types';
import { lazyAreOutboxCountsLoaded } from 'owa-mail-folder-orchestration';

/**
 * Load the initial items from the server
 * @param tableView to load items in
 * @param OnInitialTableLoadComplete is a callback that is called when we receive the response
 * The callback is handled by table loading
 * @param isTablePrefetched indicates if the table is prefetched
 * @return a promise that resolves when the load table from server has completed
 */
export default function loadInitialItemsFromServer(
    tableView: TableView,
    isTablePrefetched: boolean,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    initialSessionData?: SessionData,
    customPagingInfo?: GqlPagingInfo
): Promise<void> {
    const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;

    const lastSwitchMailFolderDp = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
        return dp.getEventName() == 'SwitchMailFolder';
    });
    lastSwitchMailFolderDp?.addCheckpoint('SMF_GetItemRows');
    const mailboxInfo = getMailboxInfoFromTableQuery(mailTableQuery);
    return getItemRows(
        getBaseFolderId(mailTableQuery),
        mailTableQuery.viewFilter,
        mailTableQuery.sortBy,
        shouldTableSortByRenewTime(mailTableQuery),
        mailTableQuery.focusedViewFilter,
        mailTableQuery.requestShapeName,
        mailboxInfo,
        customPagingInfo ??
            getGQLPagingInfoForQueryRows(
                !isTablePrefetched &&
                    (mailTableQuery.sortBy?.sortColumn === 16 ||
                        mailTableQuery.scenarioType === 'highPriorityViewFilter')
                    ? 200
                    : INITIAL_LOAD_ROW_COUNT,
                false /* ConversationView */
            ),
        initialSessionData,
        getMailboxRequestOptionsFromTableQuery(mailTableQuery),
        mailTableQuery.categoryName,
        mailTableQuery.scenarioType === 'mailFolderSortBySize',
        getBaseServerFolderId(tableView, true /* returnNullIfSameAsFolderId */),
        isTablePrefetched,
        undefined, // isReload,
        mailTableQuery.messageSenderScreeningFilter
    )
        .then(responseMessage => {
            const returnedPromise = Promise.resolve();
            let items: ItemRow[] = [];

            if (responseMessage?.edges) {
                items = responseMessage.edges.map(edge => edge.node as ItemRow);
            }
            onLoadInitialRowsSucceeded(
                tableView,
                items,
                responseMessage?.totalItemRowsInView ?? 0,
                responseMessage?.searchFolderId
                    ? folderId({ Id: responseMessage.searchFolderId })
                    : undefined,
                undefined /* FolderId used for groups scenario, we currently don't support message view in groups. */
            );

            onInitialTableLoadComplete(
                tableView,
                true, // isSuccessResponseClass
                '200',
                isTablePrefetched
            );

            lastSwitchMailFolderDp?.addCheckpoint('SMF_GIR_Then');
            // Ensuring we are returning a promise
            return returnedPromise;
        })
        .catch(async (error: any) => {
            lastSwitchMailFolderDp?.addCheckpoint('SMF_GIR_Error');

            if (await shouldSuppressError(tableView, mailboxInfo)) {
                lastSwitchMailFolderDp?.addCustomData({ errorMessage: error.message });
                lastSwitchMailFolderDp?.end();
                onInitialTableLoadComplete(tableView, true, '200', isTablePrefetched);
                return Promise.resolve();
            }

            const responseCode = apolloErrorToResponseCode(error);
            onInitialTableLoadComplete(tableView, false, responseCode, isTablePrefetched);
            return Promise.reject(error);
        });
}

async function shouldSuppressError(tableView: TableView, mailboxInfo: MailboxInfo) {
    // Local only outbox is hard to make resilient since there are some cases like with db versioning
    // where we just can't access the local data. On the other hand, outbox is typically empty.
    // If the outbox is empty, we should just suppress the error and show an empty list.
    try {
        if (folderIdToName(tableView.serverFolderId) == 'outbox') {
            const folder = folderStore.folderTable.get(tableView.serverFolderId);
            if (
                folder &&
                folder.totalMessageCount == 0 &&
                (await lazyAreOutboxCountsLoaded.importAndExecute(mailboxInfo))
            ) {
                return true;
            }
        }
    } catch {}

    return false;
}
