import type { FeatureName } from '../store/featureDefinitions';
import type { CustomDataMap } from 'owa-analytics-types';
import type { MailboxInfo } from 'owa-client-types';
import {
    areFeatureFlagsInitialized,
    areFeatureFlagsInitializedForIndexer,
} from '../actions/initializeFeatureFlags';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { logStartGreyError } from 'owa-analytics-start';

type GetDiagnosticInfoFunc = (feature: FeatureName, mailboxInfo?: MailboxInfo) => CustomDataMap;

/**
 * To help understand why we saw an increase in feature flag errors, we are logging the information
 * when we would have thrown an error before alias lookup.
 *
 * Note, this function is exported for unit testing purposes
 * @param feature Specifies the feature for which the diagnostic information is being requested
 * @param mailboxInfo Optionally specifies the mailbox info for which the diagnostic information is being requested
 * @param dontThrowErrorIfNotInitialized Specifies if we should not throw an error if the feature flags are not initialized
 */
export function checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup(
    feature: FeatureName,
    mailboxInfo: MailboxInfo | undefined,
    dontThrowErrorIfNotInitialized: boolean | undefined,
    getDiagnosticInfo: GetDiagnosticInfoFunc
) {
    // This code makes the assumption that user identity is used for the indexer, if the persistence id
    // indexer is enabled that assumption is wrong and we should not run the logic. Also we do not care
    // about cases where the indexer is not set.
    if (!isPersistenceIdIndexerEnabled() && !dontThrowErrorIfNotInitialized) {
        const indexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
        const userIdentity = mailboxInfo?.userIdentity;
        if (
            !!indexer &&
            !!userIdentity &&
            indexer !== userIdentity &&
            areFeatureFlagsInitialized(mailboxInfo) &&
            !areFeatureFlagsInitializedForIndexer(userIdentity)
        ) {
            // This would have previously thrown before the alias lookup
            const error = new Error('WouldHaveThrownBeforeAliasLookup');
            logStartGreyError(
                'WouldHaveThrownBeforeAliasLookup',
                error,
                getDiagnosticInfo(feature, mailboxInfo)
            );
        }
    }
}
