import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyGetScheduleWithLocationQueryWeb = createLazyResolver(
    'GET_SCHEDULE_WITH_QUERY_WEB',
    () =>
        import(
            /* webpackChunkName: "GetScheduleWithLocationQueryWeb" */ './getScheduleWithLocationQueryWeb'
        ),
    m => m.getScheduleWithLocationQueryWeb
);
