import type {
    MutationParameters,
    GraphQLTaggedNode,
    VariablesOf,
    PayloadError,
} from 'relay-runtime';

/* eslint-disable @typescript-eslint/no-restricted-imports --
 * Relay hook imports are allowed only in Nova GraphQL integration
 *	> 'useMutation', 'useFragment', 'useLazyLoadQuery', 'useRefetchableFragment', 'usePaginationFragment' imports are restricted from being used. */
import { useMutation as useMutationRelay } from 'react-relay';
/* eslint-enable @typescript-eslint/no-restricted-imports  */

type OwaUseMutationConfig<TMutation extends MutationParameters> = {
    variables: VariablesOf<TMutation>;
    context?: Record<string, any>;
    optimisticResponse?: TMutation['rawResponse'] | undefined;
    onCompleted?:
        | ((response: TMutation['response'], errors: PayloadError[] | null) => void | null)
        | undefined;
};

export function useMutation<TMutation extends MutationParameters>(
    mutation: GraphQLTaggedNode
): [
    (config: OwaUseMutationConfig<TMutation>) => Promise<{
        data?: TMutation['response'];
        errors?: PayloadError[];
    }>,
    boolean
] {
    const [commitMutation, isInFlight] = useMutationRelay(mutation);

    const comFn = (
        config: OwaUseMutationConfig<TMutation>
    ): Promise<{
        data?: TMutation['response'];
        errors?: PayloadError[];
    }> => {
        const relayCompatibleOptimisticResponse =
            typeof config.optimisticResponse === 'object'
                ? config.optimisticResponse ?? undefined
                : undefined;

        return new Promise(resolve => {
            commitMutation({
                variables: config.variables,
                optimisticResponse: relayCompatibleOptimisticResponse,
                onCompleted: (data, errors) => {
                    config.onCompleted?.(data, errors);
                    resolve({
                        errors: errors?.map(error => ({ message: error.message })) ?? undefined,
                        data,
                    });
                },
            });
        });
    };

    return [comFn, isInFlight];
}
