import type { TypeOfCalendarItemEx } from 'owa-graph-schema';
import type CalendarItemTypeType from 'owa-service/lib/contract/CalendarItemTypeType';

export function convertCalendarItemTypeToOws(
    calendarItemType: TypeOfCalendarItemEx | undefined
): CalendarItemTypeType | undefined {
    if (calendarItemType === 'ThisAndFollowingInstancesMaster') {
        return 'Occurrence';
    }

    return calendarItemType;
}
