import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { lazyShowCategoryDialog } from 'owa-categories';
import setInitialCategoryDialogViewState from 'owa-categories/lib/actions/setInitialCategoryDialogViewState';
import type CategoryActionSource from 'owa-categories/lib/utils/CategoryActionSource';
import type { MailboxInfo } from 'owa-client-types';
import { getActionSourceFromCommandingViewMode } from 'owa-command-ribbon';
import { lazyAddFavoriteCategory } from 'owa-favorites';
import { resetFocus } from 'owa-mail-focus-manager';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import {
    lazyAddCategoriesFromTable,
    lazyClearCategoriesFromTable,
    lazyRemoveCategoriesFromTable,
    lazyAddCategoriesFromItem,
    lazyClearCategoriesFromItem,
    lazyRemoveCategoriesFromItem,
} from 'owa-mail-triage-action';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import type { ClientItemId } from 'owa-client-ids';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import setCategoryMenuFindText from 'owa-categories/lib/actions/setCategoryMenuFindText';
import setShouldShowAllCategories from 'owa-categories/lib/actions/setShouldShowAllCategories';
import { logUsage } from 'owa-analytics';

/**
 * Function to handle a category clicked.
 *
 * @param categoryName: name of category
 * @param isChecked: whether category is currently applied on the item(s)
 *
 */
export const onCategoryClicked = (
    categoryName: string,
    isChecked: boolean,
    itemId: ClientItemId | undefined,
    mailboxInfo: MailboxInfo | undefined
) => {
    // Dismiss menu first and then proceed with click handler.
    resetFocus('ribbonCategoryMenuDismissed');

    if (itemId !== undefined) {
        if (isChecked) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            lazyRemoveCategoriesFromItem.importAndExecute(itemId.Id, mailboxInfo!, [categoryName]);
        } else {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            lazyAddCategoriesFromItem.importAndExecute(itemId.Id, mailboxInfo!, [categoryName]);
        }
    } else {
        const tableView = getSelectedTableView();
        const rowKeys = tableView.isInVirtualSelectAllMode
            ? tableView.rowKeys
            : [...tableView.selectedRowKeys.keys()];

        if (isChecked) {
            // Checked category is clicked, remove category.
            lazyRemoveCategoriesFromTable.importAndExecute(
                rowKeys,
                tableView,
                [categoryName],
                getActionSourceFromCommandingViewMode()
            );
        } else {
            // Unchecked category is clicked, add category.
            lazyAddCategoriesFromTable.importAndExecute(
                rowKeys,
                tableView,
                [categoryName],
                getActionSourceFromCommandingViewMode()
            );
        }
    }
};

/**
 * Shows the Category Dialog to allow user to create a new category
 */
export const onNewCategoryClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    categoryName: string | undefined,
    mailboxInfo: MailboxInfo | undefined,
    itemId: ClientItemId | undefined
) => {
    const tableView = getSelectedTableView();
    const rowKeys = tableView.isInVirtualSelectAllMode
        ? tableView.rowKeys
        : [...tableView.selectedRowKeys.keys()];
    const targetWindow = windowRetriever(tabId);

    const addFavoriteCategoryCallback = (
        categoryId: string,
        actionSource: CategoryActionSource,
        favoriteMailboxInfo: MailboxInfo
    ) => {
        lazyAddFavoriteCategory
            .import()
            .then(addFavoriteCategory =>
                addFavoriteCategory(categoryId, actionSource, favoriteMailboxInfo)
            );
    };

    const onAfterCreateNewCategory = (category?: string) => {
        // When a new category is created, we should apply it optimistically to the selected items
        if (itemId) {
            lazyAddCategoriesFromItem.importAndExecute(
                itemId.Id,
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                mailboxInfo!,
                category ? [category] : []
            );
        } else {
            lazyAddCategoriesFromTable.importAndExecute(
                rowKeys,
                tableView,
                category ? [category] : [],
                getActionSourceFromCommandingViewMode()
            );
        }
    };

    setInitialCategoryDialogViewState('new', categoryName);
    lazyShowCategoryDialog.importAndExecute({
        actionSource: getActionSourceFromCommandingViewMode(),
        addFavoriteCategory: addFavoriteCategoryCallback,
        onAfterCreateNewCategory,
        mailboxInfo: mailboxInfo ?? getMailboxInfo(tableView),
        targetWindow,
    });
};

/**
 * Clears all categories on the selected mail item(s)
 */
export const onClearCategoriesClicked = (
    mailboxInfo: MailboxInfo | undefined,
    itemId: ClientItemId | undefined
) => {
    resetFocus('ribbonClearCategoriesClicked');

    if (itemId) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        lazyClearCategoriesFromItem.importAndExecute(itemId.Id, mailboxInfo!);
    } else {
        const tableView = getSelectedTableView();
        const rowKeys = tableView.isInVirtualSelectAllMode
            ? tableView.rowKeys
            : [...tableView.selectedRowKeys.keys()];

        lazyClearCategoriesFromTable.importAndExecute(
            rowKeys,
            tableView,
            getActionSourceFromCommandingViewMode()
        );
    }
};

/**
 * Clears all categories on the selected mail item(s)
 */
export const onManageCategoriesClicked = (mailboxInfo: MailboxInfo) => {
    logUsage('TnS_Category_ManageCategoriesRibbon');
    if (isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-multiacctcomingsoon')) {
        lazyMountAndShowFullOptions.importAndExecute(
            'accounts-category',
            'categories',
            undefined,
            undefined,
            mailboxInfo
        );
    } else {
        lazyMountAndShowFullOptions.importAndExecute('general', 'categories');
    }
};

export const onAllCategoriesClicked = (props: ReadOnlyRibbonControlDefProps) => {
    logUsage('TnS_Category_AllCategoriesRibbon');
    const ele: HTMLElement | null = document.getElementById(
        getReadRibbonId(626, props.itemId).toString()
    );

    if (ele) {
        ele.focus();
    }

    resetCategorizeSearch(true /*showAllCategories*/);
};

export const resetCategorizeSearch = (showAllCategories: boolean | undefined) => {
    setCategoryMenuFindText('');
    setShouldShowAllCategories(showAllCategories ?? false);
};
