import { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import type SelectionDirection from 'owa-mail-list-store/lib/store/schema/SelectionDirection';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import isSelectMailItemCore from './isSelectMailItemCore';
import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
} from './selectMailItemDatapointGetterAndSetter';

let eventTimestamp: number | undefined = undefined;

export function setInteractionTimestamp(timeStamp?: number) {
    eventTimestamp = timeStamp;
}

export function createSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string | undefined,
    selectionDirection?: SelectionDirection
) {
    // End the previous SMI datapoint if it did not complete before new selection.
    const dp = getSelectMailItemDatapoint();
    dp?.invalidate();
    dp?.end();

    const isCoreAction = isSelectMailItemCore(
        mailListItemSelectionSource,
        tableView,
        rowKey,
        selectionDirection
    );
    const options = {
        timeout: 90 * 1000,
        eventTimestamp,
    };
    if (isCoreAction) {
        setSelectMailItemDatapoint(new PerformanceCoreDatapoint('SelectMailItem', options));
    } else {
        setSelectMailItemDatapoint(new PerformanceDatapoint('SelectMailItemNonCritical', options));
    }
}
