import { onNudgeRemoved } from '../actions/onNudgeRemoved';
import { onGetNudgesCompleted } from '../actions/onGetNudgesCompleted';
import nudgeStore from '../store/Store';
import { mutator } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onGetNudgesCompleted, actionMessage => {
    nudgeStore.nudgedRows = actionMessage.nudgedRows;
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onNudgeRemoved, actionMessage => {
    for (let i = nudgeStore.nudgedRows.length - 1; i >= 0; i--) {
        if (nudgeStore.nudgedRows[i].rowKey === actionMessage.rowKey) {
            nudgeStore.nudgedRows.splice(i, 1);
        }
    }
});
