import type { AppBootstrapOptions } from 'owa-app-module/lib/types/AppBootstrapOptions';
import MailModule from 'owa-mail-module-view/lib/components/MailModule';
import getMailHeaderProps from 'owa-mail-module-view/lib/components/getMailHeaderProps';
import { mailModuleName } from './strings.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import initializeMailModuleState from './initializeMailModuleState';
import { mailRouterOptions } from './routing/mailRoutes';
import { type OwaWorkload, Module } from 'owa-workloads';
import controlIconRegistration from 'owa-control-icons/lib/data/controlIconsRegistration';
import surfaceActionIconsRegistration from 'owa-surface-action-icons/lib/data/surfaceActionIconsRegistration';
import { getAnalyticOptions } from 'owa-shared-analytics';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { lazySetupMailModulePostRender } from 'owa-mail-post-boot';
import type { BootStrategies } from 'owa-shared-start-types';
import type { SearchBoxContainerHandle } from 'owa-search';
import { selectDefaultFolder } from 'owa-mail-folder-forest-actions';
import { mailAppRibbonProps } from 'owa-mail-ribbon';
import { getMailFavicon } from 'owa-mail-favicon';

// Initialize mail packages here:
import 'owa-mail-list-selection-actions-v2';
import 'owa-mail-nudge-actions';
import 'owa-mail-folder-orchestration';
import 'owa-public-folder-favorite';
import 'owa-shared-folder';

export function getMailBootstrapOptions(
    sessionPromise: Promise<SessionData | undefined>,
    strategies?: BootStrategies
): AppBootstrapOptions {
    return {
        initializeState: initializeMailModuleState,
        sessionPromise,
        routerOptions: mailRouterOptions,
        getModuleName: () => loc(mailModuleName),
        workload: 1,
        analyticsOptions: getAnalyticOptions(),
        iconFonts: [controlIconRegistration, surfaceActionIconsRegistration],
        postLazyAction: lazySetupMailModulePostRender,
        strategies,
        appModuleProps: {
            module: Module.Mail,
            renderModule: (searchBoxRef: React.RefObject<SearchBoxContainerHandle>) => (
                <MailModule searchBoxRef={searchBoxRef} />
            ),
            ribbonProps: mailAppRibbonProps,
            activeModuleAction: () => selectDefaultFolder('ModuleSwitcher'),
            getHeaderInfo: getMailHeaderProps,
            isSkiplinkEnabled: true,
            getOverrideFavIcon: getMailFavicon,
        },
    };
}
