import type { QueryRoomArgs, ResolverContext, GetRoomResponse } from 'owa-graph-schema';

/**
 * query web resolver responsible for room
 */
export const roomQueryWeb = async (
    _parent: any,
    _args: QueryRoomArgs,
    _context: ResolverContext,
    _info: any
): Promise<GetRoomResponse> => {
    // TODO
    return Promise.reject(new Error('Not implemented'));
};
