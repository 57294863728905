import {
    ArrowCircleDown16Regular,
    ArrowCircleUp16Filled,
    ArrowCircleLeft16Filled,
} from '@fluentui/react-icons';
import classNames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    copilotInboxPriorityIconForMLStyles,
    copilotInboxPriorityIconForSettingsStyles,
    copilotInboxHighPriorityIconColor,
    copilotInboxLowPriorityIconColor,
    copilotInboxNormalPriorityColor,
    iconContainer,
} from './CopilotInboxPriorityIcon.scss';
import { getCopilotPrioritizeSettings } from 'owa-mail-copilot-prioritize-settings';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    highPriorityIconTooltip,
    lowPriorityIconTooltip,
} from './CopilotInboxPriorityIcon.locstring.json';
import loc from 'owa-localize';

interface CopilotInboxPriorityIconProps {
    copilotInboxScore?: number;
    priorityOverride?: 'high' | 'low' | 'normal';
    scenario: 'ML' | 'Settings' | 'RP' | 'PrioritySort';
    mailboxInfo?: MailboxInfo;
}

export default observer(function CopilotInboxPriorityIcon(props: CopilotInboxPriorityIconProps) {
    const { copilotInboxScore = 0, scenario, priorityOverride, mailboxInfo } = props;

    if (!isFeatureEnabled('mon-copilot-inbox', mailboxInfo)) {
        return null;
    }

    const prioritizationEnabled = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).prioritizationEnabled
        : null;
    const applyHighPriorityLabel = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).applyHighPriorityLabel
        : null;
    const applyLowPriorityLabel = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).applyLowPriorityLabel
        : null;
    const highPriorityScores = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).highPriorityScores ?? []
        : [];
    const lowPriorityScores = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).lowPriorityScores ?? []
        : [];

    const isHighPriority =
        priorityOverride === 'high' ||
        (prioritizationEnabled &&
            (applyHighPriorityLabel || scenario === 'PrioritySort') &&
            highPriorityScores.includes(copilotInboxScore));

    const isLowPriority =
        priorityOverride === 'low' ||
        (prioritizationEnabled &&
            (applyLowPriorityLabel || scenario === 'PrioritySort') &&
            lowPriorityScores.includes(copilotInboxScore));

    const isNormalPriority = priorityOverride === 'normal';

    let scenarioStyles = copilotInboxPriorityIconForMLStyles;
    switch (scenario) {
        case 'ML':
        case 'PrioritySort':
            scenarioStyles = copilotInboxPriorityIconForMLStyles;
            break;
        case 'Settings':
            scenarioStyles = copilotInboxPriorityIconForSettingsStyles;
            break;
        case 'RP':
            scenarioStyles = '';
            break;
    }

    const iconStyles = classNames(
        isHighPriority && copilotInboxHighPriorityIconColor,
        isLowPriority && copilotInboxLowPriorityIconColor,
        isNormalPriority && copilotInboxNormalPriorityColor,
        scenarioStyles
    );

    let icon = null;

    if (isLowPriority) {
        icon = <ArrowCircleDown16Regular className={iconStyles} />;
    } else if (isHighPriority) {
        icon = <ArrowCircleUp16Filled className={iconStyles} />;
    } else if (isNormalPriority) {
        icon = <ArrowCircleLeft16Filled className={iconStyles} />;
    }

    if (!icon) {
        return null;
    }

    const iconTitle = isHighPriority ? loc(highPriorityIconTooltip) : loc(lowPriorityIconTooltip);

    return (
        <div className={iconContainer} title={scenario === 'ML' ? iconTitle : undefined}>
            {icon}
        </div>
    );
}, 'CopilotInboxPriorityIcon');
