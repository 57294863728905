import { setSelectedApp } from 'owa-appbar-state';
import onInvalidAppNavigation from '../actions/onInvalidAppNavigation';
import onOpenMetaOsApp from '../actions/onOpenMetaOsApp';
import { AppHostNavigationError } from './telemetry';
import type { NavigateToMetaOsApp } from '../actions/fetchAndOpenLaunchPageMetaOsApp';
import type { AppDefinition } from 'owa-graph-schema';
import onOpenAppInstallDialog from '../actions/onOpenAppInstallDialog';

/**
 * Validates input and calls action that triggers an app to be rendered in AppHost's iframe
 * @param appDefinition
 * @param navigateParams
 * @returns
 */
export async function openLaunchPageMetaOsApp(
    appDefinition: AppDefinition,
    navigateParams: NavigateToMetaOsApp
) {
    let { entityId, subEntityId } = navigateParams;
    const userClickTime = navigateParams.userClickTime ?? Date.now();
    if (!appDefinition) {
        return fallbackBehavior(AppHostNavigationError.AppManifestNotFound, navigateParams);
    }

    if (!appDefinition.staticTabs || appDefinition.staticTabs?.length === 0) {
        return fallbackBehavior(
            AppHostNavigationError.AppManifestWithoutStaticTabs,
            navigateParams
        );
    }

    const existingEntityId = appDefinition.staticTabs.some(tab => tab.entityId === entityId);
    if (entityId && !existingEntityId) {
        return fallbackBehavior(AppHostNavigationError.EntityIdNotFoundInManifest, navigateParams);
    }

    if (!entityId) {
        // Fallback to first static tab with a content url
        entityId =
            appDefinition.staticTabs.filter(tab => tab.contentUrl).map(tab => tab.entityId)[0] ??
            undefined;
        if (!entityId) {
            return fallbackBehavior(AppHostNavigationError.InvalidEntityId, navigateParams);
        }
    }

    if (!subEntityId) {
        subEntityId = '';
    }

    setSelectedApp(appDefinition.id);
    onOpenMetaOsApp({
        ...navigateParams,
        entityId,
        subEntityId,
        appDefinition,
        userClickTime,
    });
    return true;
}

export function fallbackBehavior(
    error: AppHostNavigationError,
    navigateParams: NavigateToMetaOsApp
) {
    const { fallbackUrl } = navigateParams;
    if (!!fallbackUrl) {
        window.open(fallbackUrl, '_blank');
    }
    onInvalidAppNavigation({ error, ...navigateParams });

    if (
        error === AppHostNavigationError.AppManifestNotFound ||
        error === AppHostNavigationError.AppManifestWithoutStaticTabs
    ) {
        onOpenAppInstallDialog(navigateParams.appId);
    }

    return Promise.resolve(false);
}
