import type { AttachmentTypeUnion, ProviderType } from 'owa-graph-schema';
import type AttachmentType from 'owa-service/lib/contract/AttachmentType';
import type ReferenceAttachmentType from 'owa-service/lib/contract/ReferenceAttachment';
import { assertNever } from 'owa-assert';

export const convertAttachmentToOws = (
    attachment: AttachmentTypeUnion
): AttachmentType | ReferenceAttachmentType => {
    if (!attachment || !attachment.__typename) {
        return attachment as AttachmentType;
    }

    const attachmentTypeName = attachment.__typename;
    switch (attachmentTypeName) {
        case 'ReferenceAttachment': {
            return {
                ...(attachment as typeof attachment & {
                    ProviderType: ProviderType;
                }),
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (17,17): Type 'Maybe<string> | undefined' is not assignable to type 'string | undefined'.
                // @ts-expect-error
                __type: attachment.OwsTypeName,
            };
        }
        case 'BasicAttachment': {
            return {
                ...attachment,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (26,17): Type 'Maybe<string> | undefined' is not assignable to type 'string | undefined'.
                // @ts-expect-error
                __type: attachment.OwsTypeName,
            };
        }
        default:
            return assertNever(attachmentTypeName);
    }
};
