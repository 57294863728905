import type { NovaEvent } from '@nova/types';
import type { MailboxInfo } from 'owa-client-types';
import type { AppHostServiceEventOriginator } from './originator';

export const OpenPersonSourcesEventType = 'openPersonSources' as const;

export type PersonSourcesPersonaIdentifiers = {
    contactId?: string | null;
    directoryObjectId?: string | null;
    hostAppPersonaId?: string | null;
    messagingResourceId?: string | null;
    outlookServicesPersonaId?: string | null;
    smtp?: string | null;
};

export type HostAppContext = {
    appName: string;
    startingPoint: string;
    sessionId: string;
    showUpdatedPersonOnClose: boolean;
    showInlineEditorExperience: boolean;
};

export type OpenPersonSourcesData = {
    personaIdentifiers?: PersonSourcesPersonaIdentifiers;
    mailboxInfo: MailboxInfo;
    hostAppContext: HostAppContext;
};

export interface OpenPersonSourcesEvent extends NovaEvent<OpenPersonSourcesData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof OpenPersonSourcesEventType;
    data: () => OpenPersonSourcesData;
}
