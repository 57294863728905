import { mutator } from 'satcheljs';
import removeFavoriteFromPublicFolderStore from '../actions/removeFavoriteFromPublicFolderStore';
import publicFolderFavoriteStore from '../store/publicFolderFavoriteStore';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(removeFavoriteFromPublicFolderStore, actionMessage => {
    const { folderId } = actionMessage;

    if (publicFolderFavoriteStore.folderTable.has(folderId)) {
        publicFolderFavoriteStore.folderTable.delete(folderId);
    }
});
