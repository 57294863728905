export interface OwaTeamsPolicies {
    teamsUpgraded: boolean;
    allowUserChat: boolean;
    allowMeetNow: boolean;
    meetingChatEnabledType: string;
    allowPrivateMeetingScheduling: boolean;
    teamsFeatureEnabled: boolean;
}

export enum FetchState {
    NotStarted,
    Fetching,
    Succeeded,
    Failed,
    Retry,
}

export interface OwaTeamsPoliciesStore {
    policies: OwaTeamsPolicies | null;
    fetchState: FetchState;
}
