import { getFlyoutAcquisitionLinks } from './getFlyoutAcquisitionLinks';
import { getFlyoutAppFilters, getFlyoutAppFilters_addins } from './getFlyoutAppFilters';

import type { MessageExtensionType } from 'owa-graph-schema';
import type { StrictAcquisition } from 'owa-m365-acquisitions/lib/types';
import type { StrictAppAcquisitionLink } from '../types';
import type { MailboxInfo } from 'owa-client-types';
import type { IEnabledAddinCommands, IAddin } from 'owa-addins-types';

export const MessageExtensionFlyoutTypes: Readonly<MessageExtensionType[]> = [
    'Read',
    'ReadCalendar',
    'ReadSharedFolder',
    'Compose',
    'ComposeCalendar',
    'ComposeSharedFolder',
    'ComposeMessageExtensions',
    'MultipleSelection',
] as const;

type MessageExtensionTypeFilterMap = {
    [key in MessageExtensionType]: {
        appAcquisitionLinks: StrictAppAcquisitionLink[];
        appFilter: (acquisition: StrictAcquisition) => boolean;
    };
};

export type MessageExtensionTypeFilterMapAddins = {
    [key in MessageExtensionType]: {
        appFilter: (enabledAddinCommands: IEnabledAddinCommands) => IAddin[];
    };
};

/**
 * Generates a set of (i) appAcquisitionLinks and (ii) appFilters for each
 * possible combination of `messageExtensionType`.
 * @returns map of MessageExtensionTypeFilter
 */
export async function getMessageExtensionSurfaceArgs(
    mailboxInfo?: MailboxInfo
): Promise<MessageExtensionTypeFilterMap> {
    const filterMap = {} as MessageExtensionTypeFilterMap;
    for (const messageExtensionType of MessageExtensionFlyoutTypes) {
        filterMap[messageExtensionType] = {
            appAcquisitionLinks: await getFlyoutAcquisitionLinks(mailboxInfo),
            appFilter: getFlyoutAppFilters(messageExtensionType),
        };
    }
    return filterMap;
}

export function getMessageExtensionSurfaceArgs_addins(): MessageExtensionTypeFilterMapAddins {
    const filterMap = {} as MessageExtensionTypeFilterMapAddins;
    for (const messageExtensionType of MessageExtensionFlyoutTypes) {
        filterMap[messageExtensionType] = {
            appFilter: getFlyoutAppFilters_addins(messageExtensionType),
        };
    }
    return filterMap;
}
