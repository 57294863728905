//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OnFolderHierarchyResponseFragmentDoc } from 'owa-mail-folder-view-graphql/lib/graphql/__generated__/onFolderHierarchyResponse.interface';
export type PrimaryMailFolderHierarchyQueryVariables = Types.Exact<{
    baseFolderDistinguishedIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
    offset?: Types.InputMaybe<Types.Scalars['Int']>;
    mailboxInfo: Types.MailboxInfoInput;
    shouldShowNotesFolder?: Types.InputMaybe<Types.Scalars['Boolean']>;
    shouldShowConversationHistoryFolder?: Types.InputMaybe<Types.Scalars['Boolean']>;
    shouldShowOutboxFolder?: Types.InputMaybe<Types.Scalars['Boolean']>;
    doCustomSort?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;
export type PrimaryMailFolderHierarchyQuery = {
    __typename?: 'Query';
    folderHierarchy?: {
        __typename?: 'FolderHierarchyResult';
        IncludesLastItemInRange?: boolean | null;
        offset?: number | null;
        CustomSorted?: boolean | null;
        TotalItemsInView?: number | null;
        RootFolder?: {
            __typename?: 'MailFolder';
            id: string;
            childFolderIds?: Array<string> | null;
            type?: string | null;
            changeKey?: string | null;
            parentFolderId: string;
            displayName: string;
            distinguishedFolderType?: string | null;
            FolderClass?: string | null;
            UnreadCount: number;
            totalMessageCount: number;
            childFolderCount: number;
            messageSizeInBytes?: string | null;
            mailboxInfo: any;
            replicaList?: Array<string | null> | null;
            SearchFolderTemplateId?: number | null;
            sortParentId?: string | null;
            sortPosition?: string | null;
            remoteFolderInfo?: {
                __typename?: 'RemoteFolderInfo';
                remoteFolderDisplayName?: string | null;
                remoteDistinguishedFolderType?: Types.RemoteDistinguishedFolderType | null;
            } | null;
            ArchiveTag?: {
                __typename?: 'RetentionTagType';
                IsExplicit?: boolean | null;
                Value: string;
            } | null;
            PolicyTag?: {
                __typename?: 'RetentionTagType';
                IsExplicit?: boolean | null;
                Value: string;
            } | null;
            EffectiveRights?: {
                __typename?: 'EffectiveRightsType';
                CreateAssociated?: boolean | null;
                CreateContents?: boolean | null;
                CreateHierarchy?: boolean | null;
                Delete?: boolean | null;
                Modify?: boolean | null;
                Read?: boolean | null;
                ViewPrivateItems?: boolean | null;
            } | null;
        } | null;
        Folders?: Array<{
            __typename?: 'MailFolder';
            displayName: string;
            distinguishedFolderType?: string | null;
            childFolderIds?: Array<string> | null;
            mailboxInfo: any;
            type?: string | null;
            id: string;
            changeKey?: string | null;
            parentFolderId: string;
            FolderClass?: string | null;
            UnreadCount: number;
            totalMessageCount: number;
            childFolderCount: number;
            messageSizeInBytes?: string | null;
            replicaList?: Array<string | null> | null;
            SearchFolderTemplateId?: number | null;
            sortParentId?: string | null;
            sortPosition?: string | null;
            remoteFolderInfo?: {
                __typename?: 'RemoteFolderInfo';
                remoteFolderDisplayName?: string | null;
                remoteDistinguishedFolderType?: Types.RemoteDistinguishedFolderType | null;
            } | null;
            ArchiveTag?: {
                __typename?: 'RetentionTagType';
                IsExplicit?: boolean | null;
                Value: string;
            } | null;
            PolicyTag?: {
                __typename?: 'RetentionTagType';
                IsExplicit?: boolean | null;
                Value: string;
            } | null;
            EffectiveRights?: {
                __typename?: 'EffectiveRightsType';
                CreateAssociated?: boolean | null;
                CreateContents?: boolean | null;
                CreateHierarchy?: boolean | null;
                Delete?: boolean | null;
                Modify?: boolean | null;
                Read?: boolean | null;
                ViewPrivateItems?: boolean | null;
            } | null;
        } | null> | null;
    } | null;
};
export const PrimaryMailFolderHierarchyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrimaryMailFolderHierarchy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: {
                                    kind: 'NamedType',
                                    name: { kind: 'Name', value: 'String' },
                                },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldShowNotesFolder' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldShowConversationHistoryFolder' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldShowOutboxFolder' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'doCustomSort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'folderHierarchy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mailboxInfo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mailboxInfo' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'shouldShowNotesFolder' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'shouldShowNotesFolder' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'shouldShowConversationHistoryFolder',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'shouldShowConversationHistoryFolder',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'shouldShowOutboxFolder' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'shouldShowOutboxFolder' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'doCustomSort' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'doCustomSort' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'OnFolderHierarchyResponse' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...OnFolderHierarchyResponseFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<
    PrimaryMailFolderHierarchyQuery,
    PrimaryMailFolderHierarchyQueryVariables
>;
