import { action } from 'satcheljs';
import type { AppHostEntryPoint } from '../utils/telemetry';
import type { AppDefinition } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';

export type OpenMetaOsAppAction = {
    callerFn: AppHostEntryPoint;
    appDefinition: AppDefinition;
    entityId: string;
    subEntityId: string;
    subEntityLabel?: string;
    mailboxInfo?: MailboxInfo;
    userClickTime: number;
};

/* Do not call this action directly, use utils/openLaunchPageMetaOsApp instead */
const onOpenMetaOsApp = action(
    'onOpenMetaOsApp',
    ({
        callerFn,
        appDefinition,
        entityId,
        subEntityId,
        subEntityLabel,
        mailboxInfo,
        userClickTime,
    }: OpenMetaOsAppAction) => ({
        appDefinition,
        callerFn,
        entityId,
        subEntityId,
        subEntityLabel,
        mailboxInfo,
        userClickTime,
    })
);

export default onOpenMetaOsApp;
