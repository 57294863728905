import { MoveFolderDocument } from 'owa-folder-movefolder/lib/graphql/__generated__/MoveFolderMutation.interface';
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
} from '@fluentui/react-components';
import type { MailboxInfo, MailboxType } from 'owa-client-types';
import loc from 'owa-localize';
import { cancelButton } from 'owa-locstrings/lib/strings/cancelbutton.locstring.json';
import { move, moveFolderTo } from 'owa-locstrings/lib/strings/move.locstring.json';
import { resetFocus } from 'owa-mail-focus-manager';
import { lazyMoveFolder } from 'owa-folder-movefolder';
import { MoveToFolder } from 'owa-mail-moveto-control';
import React from 'react';
import { useManagedMutation } from 'owa-apollo-hooks';
import type { MailFolder } from 'owa-graph-schema';

import { moveFolderBody } from './MailFolderDialog.scss';

const folderSupportedMailboxTypes: MailboxType[] = [
    'UserMailbox',
    'ArchiveMailbox',
    'SharedMailbox',
];

export interface MoveToFolderDialogProps {
    onClose: () => void;
    sourceFolder: MailFolder;
}

export const MoveToFolderDialog = function MoveToFolderDialog(props: MoveToFolderDialogProps) {
    const [moveFolderMutation] = useManagedMutation(MoveFolderDocument);
    const [moveToFolderId, setMoveToFolderId] = React.useState<string | undefined>(undefined);
    const [showDialog, setShowDialog] = React.useState<boolean>(true);
    const [destinationFolderMailboxInfo, setDestinationFolderMailboxInfo] = React.useState<
        MailboxInfo | undefined
    >(undefined);

    const { sourceFolder, onClose } = props;

    const dialogTitle = React.useMemo(() => {
        return loc(moveFolderTo, sourceFolder.displayName);
    }, [sourceFolder.displayName]);

    const onDismiss = React.useCallback(() => {
        setShowDialog(false /*shouldShow*/);
        setMoveToFolderId(undefined);
        onClose();
        resetFocus('MoveToFolderDialogDismissed');
    }, [onClose]);

    const onMoveToFolderClicked = React.useCallback(
        (folderId: string, mailboxInfo: MailboxInfo) => {
            setMoveToFolderId(folderId);
            setDestinationFolderMailboxInfo(mailboxInfo);
        },
        []
    );

    const onMoveToDismiss = React.useCallback(() => {
        onDismiss;
    }, [onDismiss]);

    const onMoveToFolder = React.useCallback(
        (ev: any) => {
            ev.preventDefault();
            if (moveToFolderId && destinationFolderMailboxInfo) {
                lazyMoveFolder.importAndExecute(
                    moveFolderMutation,
                    moveToFolderId /*destinationFolderId */,
                    destinationFolderMailboxInfo,
                    sourceFolder.id,
                    sourceFolder.mailboxInfo,
                    sourceFolder.parentFolderId,
                    sourceFolder.displayName
                );
            }
            onDismiss();
        },
        [moveToFolderId, destinationFolderMailboxInfo, sourceFolder, moveFolderMutation, onDismiss]
    );

    return (
        <Dialog open={showDialog} onOpenChange={onDismiss}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent className={moveFolderBody}>
                        <MoveToFolder
                            actionSource={'MoveToLeftNav'}
                            dismissMenu={onMoveToDismiss}
                            shouldShowSearchBox={false}
                            supportedMailboxTypes={folderSupportedMailboxTypes}
                            onFolderClick={onMoveToFolderClicked}
                            defaultInAllFolders={true}
                            shouldHideNewFolder={true}
                            shouldStyleSelectedFolder={true}
                            selectedFolderId={moveToFolderId}
                            shouldShowRootFolder={true}
                            sourceFolderId={props.sourceFolder.id}
                            mailboxInfo={props.sourceFolder.mailboxInfo}
                            shouldHideCurrentFolder={true}
                            currentFolderId={props.sourceFolder.id}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance="primary"
                            disabled={props.sourceFolder.id === moveToFolderId}
                            onClick={onMoveToFolder}
                        >
                            {loc(move)}
                        </Button>
                        <DialogTrigger>
                            <Button onClick={onDismiss}>{loc(cancelButton)}</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
