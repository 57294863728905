import type { StrictSubscriptionOptions, StrictVariables } from 'owa-apollo-types';
import type { OperationVariables, SubscriptionOptions, TypedDocumentNode } from '@apollo/client';
import { getApolloClientInternal } from './apolloClient';

export function strictSubscribe<
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options: StrictSubscriptionOptions<TActualVars, TData> = {}
) {
    const client = getApolloClientInternal();
    return client.subscribe({
        ...(options as unknown as SubscriptionOptions<TSchemaVars, TData>),
        query,
    });
}
