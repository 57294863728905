import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import { getFolderByDistinguishedId } from 'owa-folders';
import setUnseenCount from '../mutators/setUnseenCount';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export default function onPageVisibilityChanged() {
    if (
        getSelectedFolder() ===
            getFolderByDistinguishedId('inbox', getModuleContextMailboxInfo()) &&
        document.hasFocus()
    ) {
        setUnseenCount(false);
    }
}
