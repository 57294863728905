import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DeleteItemMutationWeb" */ './lazyIndex')
);

export const lazyDeleteItemMutationWeb = createLazyResolver(
    'DELETEITEM_MUTATION_WEB',
    lazyModule,
    m => m.deleteItemMutationWeb
);

export const lazyUndoDeleteItemMutationWeb = createLazyResolver(
    'UNDODELETEITEM_MUTATION_WEB',
    lazyModule,
    m => m.undoDeleteItemMutationWeb
);
