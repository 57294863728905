import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import { isEdu, doesActiveExperiencesInclude } from 'owa-bpos-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getModuleForTasks, ModuleForTasks } from 'owa-todo-module-utils';
import { M365AppId } from './M365AppId';
import { getAppBposData } from '../utils/getAppBposData';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isFluidEnabledForSource, LoopType } from 'owa-fluid-validations';
import type { MailboxInfo } from 'owa-client-types';
import { isUnifiedAppStoreEnabled } from '../utils/isUnifiedAppStoreEnabled';
import { isMosPlacesEnabledForProd, isMosPlacesEnabledForSdf } from '../utils/isMosPlacesEnabled';
import { isImmersiveBizChatEnabled } from '../utils/isImmersiveBizChatEnabled';
import isOutlookNewslettersEnabled from 'owa-session-store/lib/utils/isOutlookNewslettersEnabled';
import { isToDoMetaOsEnabled } from '../utils/isToDoMetaOsEnabled';
import {
    isOneDriveMetaOsEnabled,
    isOneDriveMetaOsConsumerEnabled,
} from '../utils/isOneDriveMetaOsEnabled';

export const M365AcquisitionsInvariants: {
    [appId in M365AppId]: (mailboxInfo?: MailboxInfo) => boolean;
} = {
    // Outlook core modules
    [M365AppId.Mail]: () => true,
    [M365AppId.Calendar]: () => true,
    [M365AppId.People]: () => true,
    // Special case
    [M365AppId.AppStore]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.AppStoreModal]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.AppStoreAbout]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.Test]: (mailboxInfo?: MailboxInfo) => isFeatureEnabled('mos-testApp', mailboxInfo),
    // Behind feature flags
    [M365AppId.OrgExplorer]: (mailboxInfo?: MailboxInfo) =>
        !isEdu(mailboxInfo) &&
        isFeatureEnabled('mos-orgExplorer', mailboxInfo) &&
        doesActiveExperiencesInclude('PeopleInViva', mailboxInfo),
    [M365AppId.Groups]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('grp-groupsApp-re') && !!isGroupsEnabled(mailboxInfo),
    [M365AppId.Files]: () => false,
    [M365AppId.ToDo]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
            ModuleForTasks.ToDo && !isToDoMetaOsEnabled(mailboxInfo),
    [M365AppId.ToDoMetaOs]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
            ModuleForTasks.ToDo && isToDoMetaOsEnabled(mailboxInfo),
    [M365AppId.Tasks]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
        ModuleForTasks.LegacyTasks,
    [M365AppId.EventsSdf]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-eventsApp', mailboxInfo) &&
        !isFeatureEnabled('mos-eventsApp-prod', mailboxInfo),
    [M365AppId.EventsProd]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-eventsApp-prod', mailboxInfo),
    [M365AppId.MicrosoftPlaces]: (mailboxInfo?: MailboxInfo) =>
        isMosPlacesEnabledForProd(mailboxInfo),
    [M365AppId.MicrosoftPlacesSdf]: (mailboxInfo?: MailboxInfo) =>
        isMosPlacesEnabledForSdf(mailboxInfo),
    [M365AppId.YouTube]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.WikipediaSearch]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.Weather]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.Stocks]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.Places]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.Images]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.News]: (mailboxInfo?: MailboxInfo) =>
        isFeatureEnabled('mos-messageExtensionApps', mailboxInfo),
    [M365AppId.ADO]: (mailboxInfo?: MailboxInfo) => isFeatureEnabled('mos-adoApp', mailboxInfo),
    [M365AppId.ImmersiveBizChat]: (mailboxInfo?: MailboxInfo) =>
        isImmersiveBizChatEnabled(mailboxInfo),
    // License dependent
    [M365AppId.Word]: (mailboxInfo?: MailboxInfo) => !!getAppBposData(M365AppId.Word, mailboxInfo),
    [M365AppId.Excel]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Excel, mailboxInfo),
    [M365AppId.PowerPoint]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.PowerPoint, mailboxInfo),
    [M365AppId.OneNote]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneNote, mailboxInfo),
    [M365AppId.Yammer]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Yammer, mailboxInfo),
    [M365AppId.Bookings]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Bookings, mailboxInfo),
    [M365AppId.OneDrive]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDrive, mailboxInfo) &&
        !isOneDriveMetaOsEnabled(mailboxInfo) &&
        !isConsumer(undefined, mailboxInfo),
    [M365AppId.OneDriveMetaOs]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDrive, mailboxInfo) &&
        isOneDriveMetaOsEnabled(mailboxInfo) &&
        !isConsumer(undefined, mailboxInfo),
    [M365AppId.OneDriveConsumerMetaOs]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDriveConsumerMetaOs, mailboxInfo) &&
        isOneDriveMetaOsConsumerEnabled(mailboxInfo) &&
        !isOneDriveMetaOsEnabled(mailboxInfo) &&
        isConsumer(undefined, mailboxInfo),
    [M365AppId.Hoop]: (mailboxInfo?: MailboxInfo) =>
        isFluidEnabledForSource(
            'MOS',
            mailboxInfo ?? getGlobalSettingsAccountMailboxInfo(),
            LoopType.Fluid
        ),
    [M365AppId.Newsletters]: (mailboxInfo?: MailboxInfo) =>
        (isFeatureEnabled('addison-enabled', mailboxInfo) || isOutlookNewslettersEnabled()) &&
        isFeatureEnabled('mos-newslettersApp', mailboxInfo),
    [M365AppId.VsbAdmin]: () => true,
};
