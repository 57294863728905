import owaEmptyState_lib_svg_HighContrast_illustration_mailSvg from 'owa-empty-state/lib/svg/HighContrast/illustration_mail.svg';
import owaEmptyState_lib_svg_HighContrast_illustration_no_wifiSvg from 'owa-empty-state/lib/svg/HighContrast/illustration_no_wifi.svg';
import owaEmptyState_lib_svg_Light_illustration_mailSvg from 'owa-empty-state/lib/svg/Light/illustration_mail.svg';
import owaEmptyState_lib_svg_Light_illustration_no_wifiSvg from 'owa-empty-state/lib/svg/Light/illustration_no_wifi.svg';
import owaEmptyState_lib_svg_Dark_illustration_mailSvg from 'owa-empty-state/lib/svg/Dark/illustration_mail.svg';
import owaEmptyState_lib_svg_Dark_illustration_no_wifiSvg from 'owa-empty-state/lib/svg/Dark/illustration_no_wifi.svg';
import owaEmptyState_lib_svg_DarkTheme_illustration_mailSvg from 'owa-empty-state/lib/svg/DarkTheme/illustration_mail.svg';
import owaEmptyState_lib_svg_DarkTheme_illustration_no_wifiSvg from 'owa-empty-state/lib/svg/DarkTheme/illustration_no_wifi.svg';
import owaEmptyState_lib_svg_LightTheme_illustration_mailSvg from 'owa-empty-state/lib/svg/LightTheme/illustration_mail.svg';
import owaEmptyState_lib_svg_LightTheme_illustration_no_wifiSvg from 'owa-empty-state/lib/svg/LightTheme/illustration_no_wifi.svg';
import owaEmptyState_lib_svg_Dark_illustration_mail_notfoundSvg from 'owa-empty-state/lib/svg/Dark/illustration_mail_notfound.svg';
import owaEmptyState_lib_svg_HighContrast_illustration_mail_notfoundSvg from 'owa-empty-state/lib/svg/HighContrast/illustration_mail_notfound.svg';
import owaEmptyState_lib_svg_Light_illustration_mail_notfoundSvg from 'owa-empty-state/lib/svg/Light/illustration_mail_notfound.svg';
import { getIllustration, type IllustrationType } from 'owa-empty-state/lib/utils/getIllustration';
import {
    errorCannotCompleteRequestLineOne,
    errorCannotCompleteRequestLineTwo,
    safeToCloseString,
    nothingIsSelected,
    errorMessageNotFound,
    goToInboxString,
} from './EmptyStateReadingPane.locstring.json';
import { emptyStateMessageSelectAnItemToRead } from 'owa-locstrings/lib/strings/emptystatemessageselectanitemtoread.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import isDeepLink from 'owa-url/lib/isDeepLink';
import getMailPath from 'owa-url/lib/getMailPath';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { observer } from 'owa-mobx-react';
import { updateAddinOnNavigationToEmptyNullReadingPane } from 'owa-mail-addins';
import EmptyState from 'owa-empty-state/lib/components/EmptyState';
import getIsDarkTheme from 'owa-fabric-theme/lib/selectors/getIsDarkTheme';
import { getIsMsHighContrast } from 'owa-high-contrast';
import {
    getIsLightBaseTheme,
    getIsDarkBaseTheme,
    getCurrentThemeId,
    getBackgroundImageData,
} from 'owa-theme';
import { getCdnUrl } from 'owa-config';
import { isReadingPanePositionBottom } from 'owa-mail-layout';

import { recoverableItemsFolderDescription } from './ReadingPane.locstring.json';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCapabilityEnabled, isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import classnames from 'owa-classnames';
import {
    emptyState,
    actionButton,
    imageLoaded,
    image,
    backgroundImage,
} from './EmptyStateReadingPane.scss';

import type { LogData } from '../utils/fetchWithTimeoutAndRetries';
import { fetchWithTimeoutAndRetries } from '../utils/fetchWithTimeoutAndRetries';

let NO_SELECTION_SVG: string;
let EMPTY_ERROR_SVG: string;
let NOT_FOUND_SVG: string;

const LOG_IDENTIFIER = 'ReadingPaneBackgroundImage';

export interface EmptyStateReadingPaneProps {
    isError?: boolean;
    isDumpsterOrDumpsterSearchTable?: boolean;
    shouldShowBackgroundImage?: boolean;
    mailListViewState?: MailListViewState;
}

const emptyStateStyles = {
    display: 'flex',
};

const EmptyStateReadingPane = observer(function EmptyStateReadingPane(
    props: EmptyStateReadingPaneProps
) {
    const [bgImage, setBgImage] = React.useState<string>('');
    const [isBackgroundImageLoaded, setBackgroundImageLoaded] = React.useState(false);
    const [shouldShowBackgroundImage, setShouldShowBackgroundImage] = React.useState(false);

    const isDarkTheme = getIsDarkTheme();
    const currentThemeId = getCurrentThemeId();
    const isRPPositionBottom = isReadingPanePositionBottom();

    const loadBackgroundImageForTheme = React.useCallback(async () => {
        const bgImageURL = getBackgroundImageData(currentThemeId, isDarkTheme);
        setBackgroundImageLoaded(false);

        if (!bgImageURL || isCapabilitySupported(bleedThroughCapability)) {
            setShouldShowBackgroundImage(false);
            return;
        }

        const logData: LogData = {
            logIdentifier: LOG_IDENTIFIER,
            customData: {
                themeId: currentThemeId,
                isDarkTheme,
            },
        };

        setShouldShowBackgroundImage(true);
        const bgImageResponse = await fetchWithTimeoutAndRetries(
            `${getCdnUrl()}${bgImageURL}`,
            logData
        );
        if (bgImageResponse) {
            setBgImage(bgImageResponse);
            setBackgroundImageLoaded(true);
        } else {
            setShouldShowBackgroundImage(false);
        }
    }, [isDarkTheme, currentThemeId]);

    React.useEffect(() => {
        updateAddinOnNavigationToEmptyNullReadingPane();
    }, []);

    React.useEffect(() => {
        if (isDarkTheme !== undefined && currentThemeId !== undefined) {
            loadBackgroundImageForTheme();
        }
    }, [isDarkTheme, currentThemeId]);

    initializeSvgsForTheme();

    const backgroundImageStyles = React.useMemo(
        () => ({
            backgroundImage: `url(${bgImage})`,
            margin: isRPPositionBottom ? '8px 20px 8px 0' : '8px 20px 8px 8px',
        }),
        [bgImage, isRPPositionBottom]
    );

    let emptyStateIcon: string | undefined = NO_SELECTION_SVG;
    let mainMessageText: string | null = loc(emptyStateMessageSelectAnItemToRead);
    let detailsMessageText: string | undefined = loc(nothingIsSelected);
    let ctaMessageText: string | undefined = undefined;
    let onCtaClickHandler = undefined;
    let actionButtonStyle: string | undefined = undefined;

    const emptyStateContainerClassNames = React.useMemo(
        () => ({
            container: emptyState,
            button: actionButtonStyle,
        }),
        [actionButtonStyle]
    );

    if (props.isError && isDeepLink()) {
        emptyStateIcon = NOT_FOUND_SVG;
        mainMessageText = loc(errorMessageNotFound);
        detailsMessageText = undefined;
    } else if (props.isError) {
        emptyStateIcon = EMPTY_ERROR_SVG;
        mainMessageText = loc(errorCannotCompleteRequestLineOne);
        detailsMessageText = loc(errorCannotCompleteRequestLineTwo);
    } else if (isDeepLink()) {
        mainMessageText = loc(safeToCloseString);
        detailsMessageText = undefined;
        if (isFeatureEnabled('fwk-mailtoProtocolHandler')) {
            ctaMessageText = loc(goToInboxString);
            /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
             * Baseline, please do not copy and paste this justification
             *	> JSX attribute values should not contain functions created in the same scope */
            onCtaClickHandler = () => {
                window?.open(`${getOrigin()}${getMailPath()}inbox`, '_self');
            };
            actionButtonStyle = actionButton;
        }
    } else if (props.mailListViewState === 'Empty') {
        emptyStateIcon = undefined;
        mainMessageText = null;
        detailsMessageText = undefined;
    } else if (props.isDumpsterOrDumpsterSearchTable) {
        mainMessageText = loc(recoverableItemsFolderDescription);
        detailsMessageText = undefined;
    }

    return shouldShowBackgroundImage && !props.isError ? (
        <div
            className={classnames(isBackgroundImageLoaded ? imageLoaded : image, backgroundImage)}
            style={backgroundImageStyles}
        />
    ) : isCapabilityEnabled(bleedThroughCapability) ? null : (
        <EmptyState
            icon={emptyStateIcon}
            mainMessage={mainMessageText}
            detailMessage={detailsMessageText}
            ctaMessage={ctaMessageText}
            onCtaClick={onCtaClickHandler}
            classNames={emptyStateContainerClassNames}
            styles={emptyStateStyles}
        />
    );
},
'EmptyStateReadingPane');

function initializeSvgsForTheme() {
    const newIllustrationsEnabled = isFeatureEnabled('mon-new-illustrations');
    if (newIllustrationsEnabled) {
        NO_SELECTION_SVG = getIllustration('NoMailSelected', getIsDarkTheme());
        EMPTY_ERROR_SVG = getIllustration('DisconnectedError', getIsDarkTheme());
        NOT_FOUND_SVG = getIllustration('EmailNotFound', getIsDarkTheme());
    } else {
        const isLightBaseTheme = getIsLightBaseTheme();

        if (getIsMsHighContrast()) {
            NO_SELECTION_SVG = owaEmptyState_lib_svg_HighContrast_illustration_mailSvg;
            EMPTY_ERROR_SVG = owaEmptyState_lib_svg_HighContrast_illustration_no_wifiSvg;
            NOT_FOUND_SVG = owaEmptyState_lib_svg_HighContrast_illustration_mail_notfoundSvg;
        } else if (isLightBaseTheme) {
            NO_SELECTION_SVG = owaEmptyState_lib_svg_Light_illustration_mailSvg;
            EMPTY_ERROR_SVG = owaEmptyState_lib_svg_Light_illustration_no_wifiSvg;
            NOT_FOUND_SVG = owaEmptyState_lib_svg_Light_illustration_mail_notfoundSvg;
        } else if (getIsDarkBaseTheme()) {
            NO_SELECTION_SVG = owaEmptyState_lib_svg_Dark_illustration_mailSvg;
            EMPTY_ERROR_SVG = owaEmptyState_lib_svg_Dark_illustration_no_wifiSvg;
            NOT_FOUND_SVG = owaEmptyState_lib_svg_Dark_illustration_mail_notfoundSvg;
        } else if (getIsDarkTheme()) {
            NO_SELECTION_SVG = owaEmptyState_lib_svg_DarkTheme_illustration_mailSvg;
            EMPTY_ERROR_SVG = owaEmptyState_lib_svg_DarkTheme_illustration_no_wifiSvg;
            NOT_FOUND_SVG = owaEmptyState_lib_svg_Dark_illustration_mail_notfoundSvg;
        } else {
            //colorful theme grayscale svgs
            NO_SELECTION_SVG = owaEmptyState_lib_svg_LightTheme_illustration_mailSvg;
            EMPTY_ERROR_SVG = owaEmptyState_lib_svg_LightTheme_illustration_no_wifiSvg;
            NOT_FOUND_SVG = owaEmptyState_lib_svg_Light_illustration_mail_notfoundSvg;
        }
    }
}

export default EmptyStateReadingPane;
