import { getItem } from 'owa-local-storage';
import type { MailboxInfo } from 'owa-client-types';

import { OFFLINE_ENABLED_KEY } from './utils/constants';

export function isOfflineEnabled(mailboxInfo: MailboxInfo) {
    // No offline support for other mailbox types (yet?)
    if (mailboxInfo.type !== 'UserMailbox') {
        return false;
    }

    const value = getItem(self, OFFLINE_ENABLED_KEY);

    return !value || value.toLocaleLowerCase() !== 'false';
}
