import { MAIN_WINDOW_ID, getStore } from '../store/menuDefinitionStore';

import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { MenuDefinitionStoreNames } from '../store/menuDefinitionStore';
import type { MenuItemControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/Menu';
import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

/**
 * Creates an entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
export const createMenuDefinitionStore = mutatorAction(
    'createMenuDefinitionStore',
    function createMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId == undefined) {
            // We create a store on-boot for the main window (undefined), so return early here.
            return;
        }
        getStore().menuDefinitionMapManager.set(projectionTabId, {
            MenuDefinitionMap: new ObservableMap<MenuDefinitionStoreNames, () => MenuDefinition>(),
        });
    }
);

/**
 * Removes the entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
export const removeMenuDefinitionStore = mutatorAction(
    'deleteMenuDefinitionStore',
    function removeMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId == undefined) {
            // We never want to delete the main window store, so we return early here.
            return;
        }

        if (getStore().menuDefinitionMapManager.has(projectionTabId)) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            getStore().menuDefinitionMapManager.get(projectionTabId)!.MenuDefinitionMap.clear();
        }
        getStore().menuDefinitionMapManager.delete(projectionTabId);
    }
);

/**
 * Resets a store's menuDefinition back to default value of { sections: [] }.
 * Use this mutator in onAfterMenuDismiss callback only if you explicitly do not want
 * the menu to be loaded even after the first click.
 * An example of when this is useful is Flag/Unflag and Snooze, of which their menuDefinition has a call
 * to observableNow(), which causes the Ribbon to render every 60-seconds even if nothing is happening.
 */
export const resetMenuDefinition = mutatorAction(
    'resetMenuDefinition',
    function resetMenuDefinition(storeName: MenuDefinitionStoreNames, projectionTabId?: string) {
        const id = projectionTabId || MAIN_WINDOW_ID;

        if (
            getStore().menuDefinitionMapManager.has(id) &&
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            getStore().menuDefinitionMapManager.get(id)!.MenuDefinitionMap.has(storeName)
        ) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            getStore()
                .menuDefinitionMapManager.get(id)!
                .MenuDefinitionMap.set(storeName, () =>
                    id === MAIN_WINDOW_ID
                        ? { sections: [] }
                        : {
                              sections: [
                                  {
                                      controls: [
                                          {
                                              type: 'AppButtonProps',
                                              id: 'controlPlaceHolder',
                                              label: '',
                                          } as MenuItemControlProps,
                                      ],
                                  },
                              ],
                          }
                );
        }
    }
);
