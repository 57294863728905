import { twistyButtonExpandConversationLabel } from './MailListItemTwistyButton.locstring.json';
import {
    twistyImage,
    twistyImageFull,
    twistyImageFirstLevelRotation,
    twistyImageSecondLevelRotation,
    loadingItemPartsSpinnerSingleLine,
    loadingItemPartsSpinnerThreeColumn,
    twistyMCL,
    twistyMCLMessageSelected,
    twistySCL,
    twistySCLMessageSelected,
    twistyUnread,
    twistyUnreadMessageSelected,
    sclTwistyIconButtonContainer,
    button,
} from './MailListItemTwistyButton.scss';

import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { logUsage } from 'owa-analytics';
import { getDensityModeString } from 'owa-fabric-theme';
import ChevronUpRegular from 'owa-fluent-icons-svg/lib/icons/ChevronUpRegular';
import { FluentButton } from 'owa-fluent-v9-shims';
import loc from 'owa-localize';
import { onMailListItemClickHandler } from 'owa-mail-list-item-shared';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type { ConditionalFormattingModification } from 'owa-conditional-formatting';
import { getConditionalFormattingStylesForIcon } from 'owa-conditional-formatting';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type { IIconProps } from '@fluentui/react/lib/Icon';
import React from 'react';

export interface MailListItemTwistyButtonProps {
    isLoadingSecondLevelExpansion: boolean;
    isLoadingExpansion: boolean;
    isFirstLevelExpanded: boolean;
    isSecondLevelExpanded: boolean;
    isSelected: boolean;
    isSingleLine: boolean;
    isUnread: boolean;
    rowKey: string;
    showCondensedView: boolean;
    tableViewId: string;
    styleSelectorAsPerUserSettings?: string;
    conditionalFormattingModifications: ConditionalFormattingModification[];
}

export default observer(function MailListItemTwistyButton(props: MailListItemTwistyButtonProps) {
    const {
        isLoadingSecondLevelExpansion,
        isLoadingExpansion,
        isFirstLevelExpanded,
        isSecondLevelExpanded,
        isSelected,
        isSingleLine,
        isUnread,
        rowKey,
        showCondensedView,
        tableViewId,
        conditionalFormattingModifications,
    } = props;

    const twistyConditionalFormattingStyles = React.useMemo(() => {
        const conditionalFormattingStyles =
            conditionalFormattingModifications && conditionalFormattingModifications.length > 0
                ? getConditionalFormattingStylesForIcon(conditionalFormattingModifications)
                : undefined;

        return conditionalFormattingStyles;
    }, [conditionalFormattingModifications]);

    // unneeded in spinner case, but hooks must be defined prior to any return statements
    const onClick = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            onMailListItemClickHandler(
                evt,
                MailListItemSelectionSource.MailListItemTwisty,
                rowKey,
                tableViewId
            );

            logUsage('MailListRowExpanded', [
                showCondensedView,
                getListViewStore().tableViews.get(tableViewId)?.tableQuery.type,
            ]);
        },
        [rowKey, tableViewId, showCondensedView]
    );

    const secondLevelExpansion = isSecondLevelExpanded || isLoadingSecondLevelExpansion;
    const isFullDensity = getDensityModeString() === 'full';

    const iconRootStyles = classnames(
        twistyImage,
        // Rotates chevron by 45 degrees for first level expansion
        isFirstLevelExpanded && !secondLevelExpansion && twistyImageFirstLevelRotation,
        // Rotates chevron downwards for second level expansion or if only one level of expansion exists.
        secondLevelExpansion && twistyImageSecondLevelRotation,
        isFullDensity && twistyImageFull
    );

    const chevronIconProps: IIconProps = useComputedValue(
        () => ({
            iconName: ChevronUpRegular,
            styles: {
                root: iconRootStyles,
            },
        }),
        [iconRootStyles]
    );

    // show spinner if loading expansion
    if (isLoadingExpansion) {
        return (
            <Spinner
                // need to stop the event from bubbling up and causing the popout view
                onDoubleClick={stopPropagation}
                className={classnames(
                    isSingleLine
                        ? loadingItemPartsSpinnerSingleLine
                        : loadingItemPartsSpinnerThreeColumn
                )}
                size={SpinnerSize.xSmall}
            />
        );
    }

    const iconButtonClasses = classnames(
        isSingleLine
            ? classnames(twistyMCL, isSelected && twistyMCLMessageSelected)
            : classnames(twistySCL, isSelected && twistySCLMessageSelected),
        isUnread &&
            !twistyConditionalFormattingStyles &&
            (isSelected ? twistyUnreadMessageSelected : twistyUnread)
    );

    return (
        <div className={!isSingleLine ? sclTwistyIconButtonContainer : undefined}>
            <FluentButton
                appearance="icon"
                aria-expanded={isSecondLevelExpanded}
                aria-label={loc(twistyButtonExpandConversationLabel)}
                className={classnames(iconButtonClasses, button)}
                iconProps={chevronIconProps}
                tabIndex={-1}
                onClick={onClick}
                onDoubleClick={stopPropagation}
                style={twistyConditionalFormattingStyles}
            />
        </div>
    );
}, 'MailListItemTwistyButton');

const stopPropagation = (evt: React.MouseEvent<unknown>) => {
    evt.stopPropagation();
};
