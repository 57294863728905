import {
    lazyMeetingOptionsQueryWeb,
    lazyMeetingOptionTypeResolver,
    lazyMeetingOptionsMutationWeb,
    lazySensitivityLabelActionTypeResolver,
} from 'meetingoptions-resolvers';
import type { Resolvers } from 'owa-graph-schema';

/**
 * Please keep fields alphabetized to minimize merge conflicts
 */
export const webResolvers: Resolvers = {
    /* ======================== */
    /* Resolvers for root types */
    /* ======================== */

    /**
     * The root query type. All queries that fetch data start at the Query type.
     * Resolvers under Query should not have effects
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Query: {
        getMeetingOptions: lazyMeetingOptionsQueryWeb,
    },

    /**
     * The root mutation type. All queries that alter data start at the Mutation type.
     * Mutations typically return the mutated data.
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Mutation: {
        setMeetingOptions: lazyMeetingOptionsMutationWeb,
    },

    /**
     * The root subscription type. Resolvers under subscriptions return an event stream
     * that the client responds to.
     *
     * For for definition and rationale, see https://graphql.org/blog/subscriptions-in-graphql-and-relay/#event-based-subscriptions
     * For resolver implementation, see https://www.apollographql.com/docs/apollo-server/data/subscriptions
     * For client consumption, see https://www.apollographql.com/docs/react/data/subscriptions
     */
    Subscription: {},

    /**
     * =============================
     * Resolvers for abstract types
     * =============================
     * For all abstract types — union, interfaces —, Apollo needs a hand in figuring out
     * its specific concrete type to perform type validations.
     * https://www.apollographql.com/docs/apollo-server/data/resolvers#resolving-unions-and-interfaces
     */
    OneGQL_MeetingOption: {
        __resolveType: lazyMeetingOptionTypeResolver,
    },

    OneGQL_MeetingOptionSensitivityLabelAction: {
        __resolveType: lazySensitivityLabelActionTypeResolver,
    },
};
