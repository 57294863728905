import { resetMovetoStore } from '../actions/resetMoveToStore';
import type { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { MoveToFolder } from '../index';
import React from 'react';
import type MoveToFolderPropertiesType from '../store/schema/MoveToFolderPropertiesType';

import { moveToMenuSweepDialog, moveToMenu } from './MoveToMenuHeight.scss';

export function getMoveToMenuProps(
    moveToProperties: MoveToFolderPropertiesType
): IContextualMenuProps {
    const items: IContextualMenuItem[] = [];
    items.push({
        key: 'MoveToMenuItemDiv',
        onRender: () => (
            <MoveToFolder
                mailboxInfo={moveToProperties.mailboxInfo}
                actionSource={moveToProperties.actionSource}
                createNewFolder={moveToProperties.createNewFolder}
                dismissMenu={moveToProperties.dismissMenu}
                shouldShowSearchBox={moveToProperties.shouldShowSearchBox}
                supportedMailboxTypes={moveToProperties.supportedMailboxTypes}
                onFolderClick={moveToProperties.onFolderClick}
                getMenuItemsToPrepend={moveToProperties.getMenuItemsToPrepend}
                getCustomMenuItemsToAppend={moveToProperties.getCustomMenuItemsToAppend}
                disableSelectedFolder={moveToProperties.disableSelectedFolder}
                selectedFolderId={moveToProperties.selectedFolderId}
                viewAllFoldersDisplayText={moveToProperties.viewAllFoldersDisplayText}
            />
        ),
    });

    const menuClassName = moveToProperties.isSweepDialog ? moveToMenuSweepDialog : moveToMenu;

    /**
     * Called when the move to menu is actually dismissed
     * We clean up the move to store when menu dismisses
     */
    const onMenuDismissed = () => {
        resetMovetoStore();
        if (moveToProperties.actionSource === 'ReadingPane') {
            moveToProperties.dismissMenu?.();
        }
    };

    const moveToFolderContextMenuProps = {
        className: menuClassName,
        styles: {
            title: {},
            container: {},
            root: {
                width: moveToProperties.width ? moveToProperties.width + 'px' : undefined,
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '500px !important',
            },
            header: {},
            list: {},
        },
        coverTarget: moveToProperties.coverTarget,
        directionalHint: moveToProperties.directionalHint
            ? moveToProperties.directionalHint
            : DirectionalHint.bottomLeftEdge,
        directionalHintFixed: moveToProperties.directionalHintFixed, // if true ensures the position will not change sides in an attempt to fit the callout within bounds.
        items,
        onMenuDismissed,
        shouldFocusOnMount: !moveToProperties.shouldShowSearchBox, // If this is true the focus will be grabbed by entire context menu when it open, the focus should stay on search box when menu is opened
        useTargetWidth: moveToProperties.useTargetWidth,
        focusZoneProps: {
            // We want the up and down arrow keys on search input element to shift the focus to next and previous element respectively
            // irrespective of the default behavior where FocusZone does not permit it if shifting focus is only doable using Tab keys
            shouldInputLoseFocusOnArrowKey: () => true,
        },
        calloutProps: {
            className: 'customScrollBar',
            preventDismissOnScroll: true,
            hideOverflow: false,
            preventDismissOnResize: true,
            autoFocus: true,
        },
    };

    return moveToFolderContextMenuProps;
}
