import type FlaggedToken from 'owa-search-service/lib/data/schema/FlaggedToken';
import type { SearchScenarioId } from 'owa-search-store';
import { action } from 'satcheljs';
import type { QueryAlterationType } from 'owa-search-service/lib/data/schema/SubstrateSearchResponse';

/**
 * Action that stores the alteration query response data into the search store, to
 * render the component ModifiedQueryInformationalView later
 */
export const setSpellerData = action(
    'SET_SPELLER_DATA',
    (
        alteredQuery: string,
        suggestedSearchTerm: string,
        suggestedSearchTermReferenceId: string,
        flaggedTokens: FlaggedToken[],
        recourseQuery: string,
        scenarioId: SearchScenarioId,
        queryAlterationType: QueryAlterationType,
        providerId: string,
        queryAlterationLogicalId: string,
        alterationDisplayText: string
    ) => ({
        alteredQuery,
        suggestedSearchTerm,
        suggestedSearchTermReferenceId,
        flaggedTokens,
        recourseQuery,
        scenarioId,
        queryAlterationType,
        providerId,
        queryAlterationLogicalId,
        alterationDisplayText,
    })
);
