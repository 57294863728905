import { createLazyResolver } from 'owa-lazy-resolver';

export const lazySetInferenceClassificationOnConversationWeb = createLazyResolver(
    'MUTATION_SET_INFERENCE_CLASSIFICATION_CONVERSATION_WEB',
    () =>
        import(
            /* webpackChunkName: "SetInferenceClassificationOnConversationWebResolverWeb"*/ './setInferenceClassificationOnConversationWeb'
        ),
    m => m.setInferenceClassificationOnConversationWeb
);
