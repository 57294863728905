import assert from 'owa-assert/lib/assert';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';

export type SecondaryDensitySettings = {
    enableInlinePreview: boolean;
    hideSenderImage: boolean;
};

export function getSecondarySettingDefaultsForMode(
    mode: DisplayDensityMode
): SecondaryDensitySettings {
    switch (mode) {
        case 'Full':
            return {
                enableInlinePreview: true,
                hideSenderImage: false,
            };
            break;

        case 'Simple':
            return {
                enableInlinePreview: true,
                hideSenderImage: true,
            };
            break;

        case 'Compact':
            return {
                enableInlinePreview: false,
                hideSenderImage: true,
            };
            break;

        default:
            assert(false, `Unhandled enum value: ${mode}`);
            break;
    }
}
