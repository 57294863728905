import { lazyGovern, GovernPriority } from 'owa-tti';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyShouldLoadEmbedPreAuthOnIdle, lazyGetChatUnreadMessageCount } from '../index';
import { isFeatureEnabled } from 'owa-feature-flags';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> './embedChatTeachingCallout/index' import is restricted from being used by a pattern. Do not import from an index file directly. Import from the specific file instead. */
import { lazyTriggerEmbedChatCallout } from './embedChatTeachingCallout/index';

let isShouldLoadPreAuthComputed: boolean = false;
let isUnreadChatCountComputed: boolean = false;
let shouldLoadPreAuthOnIdleResult: boolean = false;
let unreadChatCount: number = 0;

export const shouldLoadPreAuthOnIdleCallbackPromise = new Promise<boolean>((resolve, reject) => {
    if (!isShouldLoadPreAuthComputed) {
        lazyGovern.importAndExecute({
            task: async () => {
                try {
                    shouldLoadPreAuthOnIdleResult =
                        isFeatureEnabled('fwk-teamsSDKPreAuth') &&
                        isFeatureEnabled('fwk-teamsSuite') &&
                        !isFeatureEnabled('mon-hide-chat-integration')
                            ? await lazyShouldLoadEmbedPreAuthOnIdle.importAndExecute()
                            : false;
                    isShouldLoadPreAuthComputed = true;
                    resolve(shouldLoadPreAuthOnIdleResult);
                } catch (error) {
                    reject(error);
                }
            },
            priority: GovernPriority.Idle,
        });
    } else {
        resolve(shouldLoadPreAuthOnIdleResult);
    }
});

export const getChatUnreadMessageCallbackPromise = new Promise<number>((resolve, reject) => {
    if (!isUnreadChatCountComputed) {
        lazyGovern.importAndExecute({
            task: async () => {
                try {
                    unreadChatCount =
                        isFeatureEnabled('fwk-teamsSuite') &&
                        !isFeatureEnabled('mon-hide-chat-integration')
                            ? await lazyGetChatUnreadMessageCount.importAndExecute()
                            : 0;
                    if (
                        isFeatureEnabled('mon-embed-chat-teaching-callout') &&
                        unreadChatCount > 0
                    ) {
                        await lazyTriggerEmbedChatCallout.importAndExecute();
                    }
                    isUnreadChatCountComputed = true;
                    resolve(unreadChatCount);
                } catch (error) {
                    reject(error);
                }
            },
            priority: GovernPriority.Idle,
        });
    } else {
        resolve(unreadChatCount);
    }
});
