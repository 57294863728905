import type TableView from '../store/schema/TableView';
import { type TableQueryType } from '../store/schema/TableQuery';
import getComputedIsVirtualSelectAllExclusionListEmpty from '../utils/getComputedIsVirtualSelectAllExclusionListEmpty';
import { owaComputedFn } from 'owa-computed-fn';
import getSelectedTableView from '../utils/getSelectedTableView';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';
import { type SortColumn } from '../store/schema/SortBy';

/**
 * Gets whether the everything is selected in table
 * @param tableView the table view
 * @return true if everything is selected in the table, false otherwise
 */
export default function getIsEverythingSelectedInTable(tableView: TableView): boolean {
    const {
        isInVirtualSelectAllMode,
        selectedRowKeys,
        rowKeys,
        totalRowsInView,
        tableQuery,
        isInCheckedMode,
    } = tableView;

    // These views only fetch the most recent items, so we can't be sure if everything
    // is selected.
    const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;
    if (
        mailTableQuery.sortBy?.sortColumn === 16 ||
        mailTableQuery.scenarioType === 'highPriorityViewFilter'
    ) {
        return false;
    }

    if (isInVirtualSelectAllMode) {
        // Always return true if user is in virtual select all mode and there are no unchecked rows (exclusion list has rows).
        // Otherwise if a user is in select all mode and loads more items, it would switch to multi-select mode
        // and update command bar to show different items
        return getComputedIsVirtualSelectAllExclusionListEmpty();
    }

    // VSO 17775: remove checking search table query after fixing 17775
    if (rowKeys.length == totalRowsInView || tableQuery.type == 1) {
        // If client has all items or table is a search table, returns true if tableView is in multi-select mode and
        // all items are in real selection.
        return isInCheckedMode && selectedRowKeys.size == rowKeys.length;
    }

    return false;
}

/**
 * Computed version of getIsEverythingSelectedInTable. Notably, this function
 * does not have a parameter and is assuming you want to use the selected table view.
 * This is important as computedFn caches based on parameters, and tableView changes enough
 * that we would otherwise get too many cache misses due to the tableView changing.
 */
export const getComputedIsEverythingSelectedInTable = owaComputedFn(
    function getComputedIsEverythingSelectedInTable(): boolean {
        const tableView = getSelectedTableView();
        return tableView ? getIsEverythingSelectedInTable(tableView) : false;
    }
);
