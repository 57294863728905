import { LazyModule } from 'owa-bundling-light';
import type { OneJs_PeopleAppViewDataResolvers } from 'owa-graph-schema';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PeopleAppViewSchemaResolvers" */ './lazyIndex')
);

const lazyPeopleAppViewDataId = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_ID',
    lazyModule,
    m => m.id
);

const lazyPeopleAppIsRibbonEnabled = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_IS_RIBBON_ENABLED',
    lazyModule,
    m => m.isRibbonEnabled
);

const lazyPeopleAppMainViewModel = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_MAIN_VIEW_MODEL',
    lazyModule,
    m => m.mainViewModel
);

const lazyPeopleAppNavigationModel = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_NAVIGATION_MODEL',
    lazyModule,
    m => m.navigationModel
);

const lazyPeopleAppOutlookRibbonModel = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_OWA_RIBBON_MODEL',
    lazyModule,
    m => m.outlookRibbonModel
);

const lazyPeopleAppDialogsModel = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_DIALOGS_MODEL',
    lazyModule,
    m => m.dialogsModel
);

const lazyPeopleAppSearchResultsModel = createLazyResolver(
    'PEOPLE_APP_VIEW_DATA_SEARCH_RESULTS_MODEL',
    lazyModule,
    m => m.searchResultsViewModel
);

export const OneJS_PeopleAppViewData: OneJs_PeopleAppViewDataResolvers = {
    id: lazyPeopleAppViewDataId,
    isRibbonEnabled: lazyPeopleAppIsRibbonEnabled,
    mainViewModel: lazyPeopleAppMainViewModel,
    navigationModel: lazyPeopleAppNavigationModel,
    outlookRibbonModel: lazyPeopleAppOutlookRibbonModel,
    dialogsModel: lazyPeopleAppDialogsModel,
    searchResultsViewModel: lazyPeopleAppSearchResultsModel,
};
