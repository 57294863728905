import type SubstrateSearchRequest from '../../data/schema/SubstrateSearchRequest';
import type { SearchResultConversation } from '../../data/schema/SubstrateSearchResponse';
import type { ConversationType } from 'owa-graph-schema';
import {
    convertRecipientToSingleRecipientType,
    convertSearchSortOrder,
    convertToFolderId,
    convertToItemId,
    convertToItemIds,
    convertUtcDateTimeToRequestTimeZone,
} from './converterHelpers';

export default function convertSearchResultConversationToConversationType(
    searchConversation: SearchResultConversation,
    request: SubstrateSearchRequest
): ConversationType {
    const conversationId = convertToItemId(searchConversation.ConversationId);

    return {
        __typename: 'ConversationType',
        id: conversationId.Id,
        Categories: searchConversation.Categories,
        ConversationId: conversationId,
        ConversationTopic: searchConversation.ConversationTopic,
        FlagStatus: searchConversation.FlagStatus,
        From: convertRecipientToSingleRecipientType(searchConversation.From),
        GlobalItemIds: convertToItemIds(searchConversation.GlobalItemIds),
        GlobalMessageCount: 2, // The message list uses this field to determine if there should be a conversation expansion button.
        // We don't have this information from the search service, so we just set it to 2 to always show the button.
        GlobalUnreadCount: searchConversation.GlobalUnreadCount,
        HasAttachments: searchConversation.HasAttachments,
        HasIrm: searchConversation.HasIrm,
        HasProcessedSharepointLink: searchConversation.HasProcessedSharepointLink,
        Importance: searchConversation.Importance,
        ItemClasses: searchConversation.ItemClasses,
        ItemIds: convertToItemIds(searchConversation.ItemIds),
        LastDeliveryOrRenewTime: convertUtcDateTimeToRequestTimeZone(
            searchConversation.LastDeliveryOrRenewTime ?? searchConversation.LastDeliveryTime,
            request
        ),
        LastDeliveryTime: convertUtcDateTimeToRequestTimeZone(
            searchConversation.LastDeliveryTime,
            request
        ),
        LastModifiedTime: convertUtcDateTimeToRequestTimeZone(
            searchConversation.LastModifiedTime,
            request
        ),
        MailboxGuids: searchConversation.MailboxGuids,
        MessageCount: searchConversation.MessageCount,
        ParentFolderId: convertToFolderId(searchConversation.ParentFolderId),
        Preview: searchConversation.Preview,
        SortOrderSource: convertSearchSortOrder(searchConversation.SortOrderSource),
        UniqueRecipients: searchConversation.UniqueRecipients,
        UniqueSenders: searchConversation.UniqueSenders,
        UnreadCount: searchConversation.UnreadCount,
    };
}
