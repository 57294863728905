import type { Provenance } from './SubstrateSearchShared';
import type IconIndexType from 'owa-service/lib/contract/IconIndexType';
import type ImportanceType from 'owa-service/lib/contract/ImportanceType';
import type SensitivityType from 'owa-service/lib/contract/SensitivityType';

export interface SubstrateSearchResponseV1 {
    ApiVersion: string;
    EntitySets: EntitySet[];
    Instrumentation: InstrumentationDetails;
    SearchTerms: string[];
    error?: any; // Present when call to 3S fails
}

export interface SubstrateSearchResponseV2 {
    ApiVersion: string;
    EntitySets: EntitySet[];
    Instrumentation: InstrumentationDetails;
    SearchTerms: string[];
    QueryAlterationResponse?: QueryAlterationResponseV2;
    error?: any; // Present when call to 3S fails
}

export type SubstrateSearchResponse = SubstrateSearchResponseV1 | SubstrateSearchResponseV2;

export interface Properties {
    HasParseException: boolean;
    WordBreakerLanguage: string;
}

export interface EntitySet {
    QueryAlterationResponse?: QueryAlterationResponse;
    ResultSets: SearchResultSet[];
    Type: EntitySetType;
    EntityType?: EntitySetType;
    Properties?: Properties;
}

interface AlteredQuery {
    HighlightedString: string;
    DisplayText: string;
    RawString: string;
    ReferenceId: string;
}

interface FlaggedToken {
    Length: number;
    Suggestion: string;
    Offset: number;
}

interface RecourseQuery {
    HighlightedString: string;
    RawString: string;
}

export interface QueryAlterationResponse {
    AlteredQuery: {
        AlteredQuery: AlteredQuery;
        FlaggedTokens: FlaggedToken[];
    };
    QueryAlterationType: QueryAlterationType;
    RecourseQuery: RecourseQuery;
}

export interface QueryAlterationResponseV2 {
    QueryAlteration: {
        AlteredQuery: AlteredQuery;
        FlaggedTokens: FlaggedToken[];
    };
    QueryAlterationType: QueryAlterationType;
    RecourseQuery: RecourseQuery;
}

export type QueryAlterationType =
    | 'Suggestion'
    | 'Modification'
    | 'NoResultModification'
    | 'NoRequeryModification'
    | 'Modification'
    | 'Extension'
    | 'NoResultFolderRefinerModification';

export interface SearchResultSet {
    MoreResultsAvailable: boolean;
    Provenance: Provenance;
    QueryId: QueryId;
    Results: SearchResult[];
    Total: number;
}

export interface QueryId {
    Id: string;
}

export interface SearchResult {
    Id: string;
    Provenance: string;
    ReferenceId: string;
    Source: {};
    InstUrlSuffix: string;
    Annotations?: Annotation[];
}

export interface InstrumentationDetails {
    TraceId: string;
    InstUrlBase: string;
}

export type EntitySetType =
    | 'Message'
    | 'Messages'
    | 'Conversation'
    | 'Conversations'
    | 'Event'
    | 'Events'
    | 'File'
    | 'Files'
    | 'People';

export interface SearchResultItem {
    Categories: string[];
    ConversationId: SearchResultItemId;
    ConversationThreadId: string;
    DateTimeCreated: string;
    DateTimeLastModified: string;
    DateTimeReceived: string;
    DateTimeSent: string;
    DiagnosticsItemContent: string;
    DiagnosticsItemQuery: string;
    DisplayBcc: string;
    DisplayCc: string;
    DisplayTo: string;
    ExtendedProperty: SearchResultExtendedPropertyType[];
    Flag: SearchResultFlagType;
    HasAttachments: boolean;
    Hashtags: string[];
    HasProcessedSharepointLink: boolean;
    IconIndex: IconIndexType;
    Importance: ImportanceType;
    IsDraft: boolean;
    IsFromMe: boolean;
    ItemClass: string;
    ItemId: SearchResultItemId;
    ParentFolderDisplayName: string;
    ParentFolderId: SearchResultFolderId;
    Preview: string;
    SearchKey: string;
    Sensitivity: SensitivityType;
    Size: number;
    SortKey: number;
    SortOrderSource: string;
    Subject: string;
    ReferenceId: string;
}

export interface SearchResultItemId {
    ChangeKey: string;
    EntryId: string;
    Id: string;
    OutlookItemId: string;
}

export interface SearchResultFolderId {
    ChangeKey: string;
    Id: string;
}

export interface SearchResultExtendedPropertyType {
    ExtendedFieldURI: string;
    Value: string;
    Values: string[];
}

export interface SearchResultFlagType {
    CompleteDate: string;
    DueDate: string;
    FlagStatus: SearchResultFlagStatus;
    StartDate: string;
}

export type SearchResultFlagStatus = 'NotFlagged' | 'Complete' | 'Flagged';

export interface SearchResultFile extends SearchResultItem {
    Author: string[];
    Created: string;
    ModifiedBy: string;
    LastModifiedTime: string;
    Title: string;
    FileExtension: string;
    UniqueId?: string;
    Id?: string;
    DocId?: string;
    // Six possible URL fields
    LinkingUrl: string;
    ServerRedirectedURL: string;
    OriginalPath: string;
    Path: string;
    DefaultEncodingUrl: string;
    ClassicAttachmentVisualizationUrl: string;
    FileName?: string; // Populated for SharePoint files
    Filename?: string; // Populated for OneDrive files
}

export interface SearchFile {
    ItemId: string;
    Author: string[];
    AuthorUpn?: string;
    Created: string;
    ModifiedBy: string;
    ModifiedByUpn?: string;
    LastModifiedTime: string;
    FileName: string;
    Title: string;
    LinkingUrl: string;
    FileExtension: string;
    InstanceKey?: string;
}

// The smaller person object that will be used in the UI
// Currently has the bare minimum fields just to get the UI working
export interface SearchPerson {
    ItemId: string;
    name: string;
    officeLocation: string;
    role: string;
    email: string;
    InstanceKey: string;
}

export interface PersonPhone {
    Number: string;
    Type: 'Business' | 'Mobile' | 'Home' | 'Other';
}

// Specifies all the properties of a person search result in the network response
export interface SearchResultPerson extends SearchResultItem {
    Id: string;
    DisplayName: string;
    GivenName: string;
    Surname: string;
    EmailAddresses: string[];
    ProxyEmailAddresses?: string[];
    CompanyName?: string;
    Department?: string;
    OfficeLocation: string;
    AdditionalOfficeLocation?: string;
    JobTitle: string;
    ImAddress: string;
    PeopleType: 'Person' | 'Group' | 'Other';
    PeopleSubtype:
        | 'OrganizationUser'
        | 'OrganizationContact'
        | 'PersonalContact'
        | 'ImplicitContact'
        | 'ChatImplicitContact'
        | 'Room'
        | 'UnifiedGroup'
        | 'PublicDistributionList'
        | 'PersonalDistributionList'
        | 'Guest'
        | 'Unknown';
    ADObjectId: string;
    UserPrincipalName: string;
    Confidence?: string; // "High" or null
    Text: string;
    QueryText: string; // Used for a click to query
    Score: number;
    PropertyHits: string[];
    AdditionalNames?: string[];
    Phones?: PersonPhone[];
    ExternalDirectoryObjectId?: string;
    PersonaId?: string;
}

export type TabbedSearchItems = SearchFile[] | TeamsMessage[] | SearchPerson[];

export interface Annotation {
    Timestamp: string;
    Type: string;
    User: User;
}

export interface SenderProfileUser {
    displayName?: string;
    email?: string;
    department?: string;
    givenName?: string;
    isEnabled?: boolean;
    isMuted?: boolean;
    jobTitle?: string;
    mail?: string;
    mobile?: string;
    mri?: string;
    objectId?: string;
    role?: string;
    physicalDeliveryOfficeName?: string;
    sipProxyAddress?: string;
    surname?: string;
    telephoneNumber?: string;
    type?: string;
    userSubType?: string;
    userPrincipalName?: string;
    tenantId?: string;
}

interface Message {
    email?: string;
    messageType?: string;
    contentType?: string;
    content?: string;
    activityType?: string;
    clientMessageId?: string;
    imDisplayName?: string;
    skypeEmoteOffset?: string;
    skypeGuid?: string;
    properties?: {
        [name: string]: {};
    };
    externalId?: string;
    id?: string;
    type?: string;
    composeTime?: string;
    originalArrivalTime?: string;
    containerId?: string;
    parentMessageId?: string;
    from?: string;
    sequenceId?: number; // int64
    senderProfile?: SenderProfileUser;
    version?: number; // int64
    threadType?: string;
    amsReferences?: string[];
    isEscalationToNewPerson?: boolean;
    escalationSourceMessageChatId?: string;
    escalationSourceMessageId?: string;
    annotationsSummary?: {
        [name: string]: {};
    };
}

export interface TeamsMessage extends Message {
    displayTo: string;
    ItemId: string;
    messageType: string;
    chatName: string;
    isExpanded: boolean;
    topicGroupId: string;
    clientThreadId: string;
    InstanceKey: string;
}

export interface User {
    AadObjectId: string;
    DisplayName: string;
    Upn: string;
}

export interface SearchResultMessage extends SearchResultItem {
    ConversationIndex: string;
    ConversationTopic: string;
    FolderRefinerId: string;
    From: SearchResultRecipient;
    ImmutableId: string;
    InternetMessageId: string;
    IsRead: boolean;
    MailboxGuids: string[];
    Sender: SearchResultRecipient;
    SenderSMTPAddress: string;
    MentionsPreview: MentionsPreview;
}

export interface SearchResultTeamsMessage extends SearchResultItem {
    ConversationHexId: string;
    ConversationRestId: string;
    ConversationIndex: number[];
    ConversationTopic: string;
    DocumentId?: number;
    MentionsPreview: MentionsPreview;
    ClientConversationId: string;
    ClientThreadId: string;
    AnnouncementTitle: string;
    From: SearchResultRecipient;
    InternetMessageId: string;
    IsRead?: boolean;
    ItemHexId: string;
    ItemRestId: string;
    MailboxGuids: string[];
    NormalizedSubject: string;
    ReceivedOrRenewTime?: string;
    Sender: SearchResultRecipient;
    SideEffects: number;
    WebLink: string;
    OwnerFolderRestId: string;
    ParentFolderDisplayName: string;
    Extensions: SearchResultsTeamsChatExtensions;
}

export interface MentionsPreview {
    IsMentioned: boolean;
}

export interface SearchResultRecipient {
    EmailAddress: SearchResultEmailAddress;
}

export interface SearchResultsTeamsChatExtensions {
    SkypeSpaces_ConversationPost_Extension_ThreadType: string;
    SkypeSpaces_ConversationPost_Extension_Topic: string;
    SkypeSpaces_ConversationPost_Extension_SkypeGroupId: string;
}

export interface SearchResultEmailAddress {
    Address: string;
    EmailAddressIndex: string;
    IsDomainInOrganization: boolean;
    IsExternalSmtpAddress: boolean;
    ItemId: SearchResultItemId;
    MailboxType: string;
    Name: string;
    OriginalDisplayName: string;
    RelevanceScore: number;
    RoutingType: string;
    SipUri: string;
    Submitted: boolean;
}

export interface SearchResultConversation extends SearchResultItem {
    Categories: string[];
    ConversationTopic: string;
    FlagStatus: SearchResultFlagStatus;
    FolderRefinerId: string;
    From: SearchResultRecipient;
    GlobalItemIds: SearchResultItemId[];
    GlobalMessageCount: number;
    GlobalUnreadCount: number;
    HasIrm: boolean;
    ImmutableId: string;
    ItemClasses: string[];
    ItemIds: SearchResultItemId[];
    LastDeliveryOrRenewTime: string;
    LastDeliveryTime: string;
    LastModifiedTime: string;
    MailboxGuids: string[];
    MessageCount: number;
    SenderSMTPAddress: string;
    UniqueRecipients: string[];
    UniqueSenders: string[];
    UnreadCount: number;
}

export interface SearchResultEvent extends SearchResultItem {
    CalendarItemType: SearchResultCalendarItemType;
    Charm: number;
    End: string;
    ImmutableId: string;
    IsAllDayEvent: boolean;
    IsMeeting: boolean;
    IsMeetingPollEvent: boolean;
    IsOrganizer: boolean;
    IsCancelled: boolean;
    MyResponseType: string;
    LegacyFreeBusyStatus: SearchResultBusyType;
    Location: string;
    Organizer: SearchResultRecipient;
    SeriesMasterItemId: SearchResultItemId;
    Start: string;
    UID: string;
}

export enum SearchResultCalendarItemType {
    Single = 0,
    Occurrence = 1,
    Exception = 2,
    RecurringMaster = 3,
}

export enum SearchResultBusyType {
    Free = 0,
    Tentative = 1,
    Busy = 2,
    Oof = 3,
    WorkingElsewhere = 4,
    NoData = 5,
}

export default SubstrateSearchResponse;
