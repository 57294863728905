import getStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';

export const getIsCustomSortEnabled = (mailboxInfo: MailboxInfo) => {
    return (
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        !getStore().isAlphabetizeFolderList
    );
};
