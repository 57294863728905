import {
    lazyOnSupportPaneClose,
    lazyOnSupportPaneOpen,
    lazyRenderFeedbackPane,
    lazyRenderGetDiagnosticsPane,
    lazyRenderSupportPane,
} from 'diagnostics-and-support';
import { GetUrlToOpenUsingDefault } from 'flora-common';
import { ActivityFeed, ActivityFeedTitle } from 'owa-activity-feed';
import { logUsage, logCoreUsage } from 'owa-analytics';
import { CalendarCopilotPane, CalendarCopilotPaneHeader } from 'owa-calendar-copilot';
import classNames from 'owa-classnames';
import { ChatCopilotPane, ChatCopilotPaneHeader } from 'owa-copilot-chat';
import { DictationHelpPane } from 'owa-editor-dictation';
import { DexPaneWrapper, lazyCloseDexPane } from 'owa-editor-proofing-dex-pane';
import { lazyCloseExpressionPane } from 'owa-expressions-store';
import { ExpressionPane } from 'owa-expressions-view';
import { isFeatureEnabled } from 'owa-feature-flags';
import { GroupsPanelContainer, GroupsPanelHeader } from 'owa-groups-panel-container';
import { incrementSidepaneClickCountAction } from 'owa-groups-panel-feedback';
import { RolloutOverridesPane } from 'owa-header-pane';
import { content } from 'owa-header-pane/lib/components/HeaderPane.scss';
import {
    deactivateCharm,
    getActiveCharm,
    type HeaderCharmType,
    toggleCharm,
} from 'owa-header-store';
import loc from 'owa-localize';
import { activityFeedTitle } from 'owa-locstrings/lib/strings/activityfeedtitle.locstring.json';
import { calendarCopilotCharmAriaLabel } from 'owa-locstrings/lib/strings/calendarCopilotCharmArialabel.locstring.json';
import { feedbackCharm_ariaLabel } from 'owa-locstrings/lib/strings/feedbackcharm_arialabel.locstring.json';
import { noteFeedFlexPaneTitle } from 'owa-locstrings/lib/strings/notefeedflexpanetitle.locstring.json';
import { tips_FlexPane_Title } from 'owa-locstrings/lib/strings/tips_flexpane_title.locstring.json';
import { whatsNew_FlexPane_Title } from 'owa-locstrings/lib/strings/whatsnew_flexpane_title.locstring.json';
import { NotesPane } from 'owa-notes-components';
import { popoutMailReadingPane } from 'owa-popout';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { KnownIssuesFeedbackForm, KnownIssuesFeedbackFormHeader } from 'owa-smiley-feedback';
import { closeFlexPane } from 'owa-suite-header-apis';
import { TimePanel, TimePanelHeaderBar } from 'owa-time-panel/lib/lazyCalendarBoot';
import { WhatsNewFluentPane } from 'owa-whats-new';
import React from 'react';
import { focusAsync } from '@fluentui/utilities';

import {
    OwaActivityFeedButtonID,
    OwaActivityFeedFlexPaneID,
    OwaCalendarCopilotButtonID,
    OwaCalendarCopilotPaneID,
    OwaChatCopilotButtonID,
    OwaChatCopilotFlexPaneID,
    OwaDexFlexPaneId,
    OwaDiagFeedbackButtonID,
    OwaDiagFeedbackFlexPaneID,
    OwaDiagnosticsButtonID,
    OwaDiagnosticsFlexPaneID,
    OwaDictationHelpFlexPaneID,
    OwaExpressionFlexPaneID,
    OwaFeedbackButtonID,
    OwaFeedbackFlexPaneID,
    OwaGroupsPanelFlexPaneID,
    OwaNoteFeedButtonID,
    OwaNoteFeedFlexPaneID,
    OwaRolloutOverridesButtonID,
    OwaRolloutOverridesFlexPaneID,
    OwaSkypeFlexPaneID,
    OwaSupportButtonID,
    OwaSupportFlexPaneID,
    OwaGetDiagnosticsButtonID,
    OwaGetDiagnosticsFlexPaneID,
    OwaTimePanelButtonID,
    OwaTimePanelFlexPaneID,
    OwaTipsButtonID,
    OwaTipsFlexPaneID,
    OwaWhatsNewButtonID,
    OwaWhatsNewFlexPaneID,
} from '../constants';
import { groupsPanelHeaderText, timePanelHeaderText } from '../strings.locstring.json';
import createShellFlexPane from '../utils/createShellFlexPane';
import renderInsideFlexPane from '../utils/renderInsideFlexPane';
import {
    dexLitePaneHeaderShellPane,
    expressionHeaderText,
    dictationHelpHeaderText,
} from './OwaSuiteFlexPane.locstring.json';
import {
    copilotPanelContainer,
    customFlexPane,
    fullHeightFlexPane,
    groupsPanelContainer,
    timePanelContainer,
} from './OwaSuiteHeader.scss';

import type { ShellFlexPane } from '@1js/suiteux-shell-react';
import type { HeaderPaneProps } from 'owa-bootstrap';
import type { MailboxInfo } from 'owa-client-types';

const FLEX_PANE_CLOSE_BUTTON_ID = 'flexPaneCloseButton';

export const OwaDiagFeedbackFlexPane = createShellFlexPane({
    flexPaneID: OwaDiagFeedbackFlexPaneID,
    flexPaneTitle: () => '',
    triggerControlID: OwaDiagFeedbackButtonID,
    containerClassName: customFlexPane,
    render: (container: HTMLDivElement) => {
        lazyRenderFeedbackPane.importAndExecute(container, () => {
            closeFlexPane(OwaDiagFeedbackFlexPaneID);
        });
    },
} as ShellFlexPane);

function closeOwaFeedbackFlexPane() {
    closeFlexPane(OwaFeedbackFlexPaneID);
}
export const OwaFeedbackFlexPane = createShellFlexPane({
    flexPaneID: OwaFeedbackFlexPaneID,
    flexPaneTitle: () => loc(feedbackCharm_ariaLabel),
    customTitle: (container: HTMLDivElement) => {
        render(<KnownIssuesFeedbackFormHeader />, container);
    },
    triggerControlID: OwaFeedbackButtonID,
    containerClassName: customFlexPane,
    render: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <KnownIssuesFeedbackForm onSendFeedback={closeOwaFeedbackFlexPane} />
            </React.StrictMode>,
            container
        );
    },
} as ShellFlexPane);

export const OwaRolloutOverridesFlexPane = createShellFlexPane({
    flexPaneID: OwaRolloutOverridesFlexPaneID,
    flexPaneTitle: () => 'Rollout Overrides', // No need to localize as it is a DEV feature only
    triggerControlID: OwaRolloutOverridesButtonID,
    containerClassName: customFlexPane,
    render: renderInsideFlexPane(<RolloutOverridesPane />, 'Rollout Overrides'),
    onFlexPaneOpen: () => focusAsync(document.getElementById(FLEX_PANE_CLOSE_BUTTON_ID)),
} as ShellFlexPane);

export const OwaCalendarCopilotPane = createShellFlexPane({
    flexPaneID: OwaCalendarCopilotPaneID,
    flexPaneTitle: () => loc(calendarCopilotCharmAriaLabel),
    triggerControlID: OwaCalendarCopilotButtonID,
    containerClassName: classNames(copilotPanelContainer, customFlexPane),
    customTitle: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <CalendarCopilotPaneHeader />
            </React.StrictMode>,
            container
        );
    },
    render: renderInsideFlexPane(
        <React.StrictMode>
            <CalendarCopilotPane />
        </React.StrictMode>,
        /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
         *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
        loc(calendarCopilotCharmAriaLabel),
        content
    ),
} as ShellFlexPane);

export const OwaTimePanelFlexPane = createShellFlexPane({
    flexPaneID: OwaTimePanelFlexPaneID,
    flexPaneTitle: () => loc(timePanelHeaderText),
    triggerControlID: OwaTimePanelButtonID,
    containerClassName: classNames(timePanelContainer, customFlexPane),
    customTitle: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <TimePanelHeaderBar onClosePanel={onTimePanelDismiss} />
            </React.StrictMode>,
            container
        );
    },
    render: renderInsideFlexPane(
        <TimePanel onClosePanel={onTimePanelDismiss} />,
        /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
         *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
        loc(timePanelHeaderText)
    ),
    customEscapeKeyHandling: true,
} as ShellFlexPane);

export const OwaChatCopilotFlexPane = createShellFlexPane({
    flexPaneID: OwaChatCopilotFlexPaneID,
    triggerControlID: OwaChatCopilotButtonID,
    containerClassName: classNames(timePanelContainer, customFlexPane),
    onFlexPaneClose: () => {
        logCoreUsage('OutlookCopilotChat_ChatClosed');
    },
    customTitle: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <ChatCopilotPaneHeader />
            </React.StrictMode>,
            container
        );
    },
    render: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <ChatCopilotPane />
            </React.StrictMode>,
            container
        );
    },
    customEscapeKeyHandling: true,
} as ShellFlexPane);

export const OwaGroupsPanelFlexPane = createShellFlexPane({
    flexPaneID: OwaGroupsPanelFlexPaneID,
    flexPaneTitle: () => loc(groupsPanelHeaderText),
    containerClassName: classNames(groupsPanelContainer, customFlexPane),
    onFlexPaneOpen: () => {
        incrementSidepaneClickCountAction();
        logUsage('GroupsPanelFlexPaneOpened');
    },
    onFlexPaneClose: () => {
        logUsage('GroupsPanelFlexPaneClosed');
    },
    customTitle: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <GroupsPanelHeader />
            </React.StrictMode>,
            container
        );
    },
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<GroupsPanelContainer />, loc(groupsPanelHeaderText)),
} as ShellFlexPane);

export const OwaWhatsNewFlexPane = createShellFlexPane({
    flexPaneID: OwaWhatsNewFlexPaneID,
    flexPaneTitle: () => loc(whatsNew_FlexPane_Title),
    triggerControlID: OwaWhatsNewButtonID,
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<WhatsNewFluentPane />, loc(whatsNew_FlexPane_Title)),
} as ShellFlexPane);

export const OwaTipsFlexPane = createShellFlexPane({
    flexPaneID: OwaTipsFlexPaneID,
    flexPaneTitle: () => loc(tips_FlexPane_Title),
    triggerControlID: OwaTipsButtonID,
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<WhatsNewFluentPane />, loc(tips_FlexPane_Title)),
} as ShellFlexPane);

export const OwaSupportFlexPane = createShellFlexPane({
    flexPaneID: OwaSupportFlexPaneID,
    flexPaneTitle: () => '',
    triggerControlID: OwaSupportButtonID,
    containerClassName: customFlexPane,
    render: (container: HTMLDivElement) => {
        lazyRenderSupportPane.importAndExecute(container, () => {
            closeFlexPane(OwaSupportFlexPaneID);
        });
    },
    onFlexPaneOpen: () => {
        lazyOnSupportPaneOpen.importAndExecute();
    },
    onFlexPaneClose: () => {
        lazyOnSupportPaneClose.importAndExecute();
    },
} as ShellFlexPane);

export const OwaGetDiagnosticsFlexPane = createShellFlexPane({
    flexPaneID: OwaGetDiagnosticsFlexPaneID,
    flexPaneTitle: () => '',
    triggerControlID: OwaGetDiagnosticsButtonID,
    containerClassName: customFlexPane,
    render: (container: HTMLDivElement) => {
        lazyRenderGetDiagnosticsPane.importAndExecute(container, () => {
            closeFlexPane(OwaGetDiagnosticsFlexPaneID);
        });
    },
} as ShellFlexPane);

function onDismissDiagnosticPanel() {
    closeFlexPane(OwaDiagnosticsFlexPaneID);
}
export function OwaDiagnosticsFlexPane(
    DiagnosticsPanelType: React.ComponentType<HeaderPaneProps> | undefined
) {
    return createShellFlexPane({
        flexPaneID: OwaDiagnosticsFlexPaneID,
        flexPaneTitle: () => 'Diagnostics',
        triggerControlID: OwaDiagnosticsButtonID,
        containerClassName: fullHeightFlexPane,
        render: renderInsideFlexPane(
            DiagnosticsPanelType ? (
                <DiagnosticsPanelType onDismiss={onDismissDiagnosticPanel} />
            ) : (
                <div />
            ),
            'Diagnostics'
        ),
    } as ShellFlexPane);
}

const activityFeedOpenMailAction = {
    openMail: (mailboxInfo: MailboxInfo, messageId: string, itemId?: string) => {
        popoutMailReadingPane(
            /* itemId */ itemId,
            /* conversationId */ undefined,
            /* internetMessageId */ messageId,
            /* mailboxInfo */ mailboxInfo
        );
    },
};
const activityFeedOpenLinkAction = {
    openLink: (mailboxInfo: MailboxInfo, url: string) => {
        if (isFeatureEnabled('doc-flora-stage1', mailboxInfo)) {
            openLinkWithFLORA(url);
        } else {
            window.open(url, '_blank');
        }
    },
};

async function openLinkWithFLORA(url: string): Promise<void> {
    const urlToOpen: string | undefined = await GetUrlToOpenUsingDefault(url);
    if (urlToOpen) {
        // OW: 270495 Need to use the main window instead of targetWindow to prevent a dialog from showing in popout's.
        const handle = window.open(urlToOpen, '_self');
        if (!handle) {
            logUsage('FLORAFailedToOpen');
        }
    } else {
        window.open(url, '_blank');
    }
}

function ActivityFeedFlexPanel() {
    return (
        <ActivityFeed
            openMailAction={activityFeedOpenMailAction}
            openLinkAction={activityFeedOpenLinkAction}
        />
    );
}
export const OwaActivityFeedFlexPane = createShellFlexPane({
    flexPaneID: OwaActivityFeedFlexPaneID,
    flexPaneTitle: () => loc(activityFeedTitle),
    triggerControlID: OwaActivityFeedButtonID,
    containerClassName: fullHeightFlexPane,
    onFlexPaneOpen: () => {
        logUsage('activityFeedCharmClicked');
    },
    customTitle: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <ActivityFeedTitle />
            </React.StrictMode>,
            container
        );
    },
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<ActivityFeedFlexPanel />, loc(activityFeedTitle)),
} as ShellFlexPane);

export const OwaNoteFeedFlexPane = createShellFlexPane({
    flexPaneID: OwaNoteFeedFlexPaneID,
    flexPaneTitle: () => loc(noteFeedFlexPaneTitle),
    triggerControlID: OwaNoteFeedButtonID,
    containerClassName: fullHeightFlexPane,
    onFlexPaneOpen: () => {
        if (window.performance) {
            window.performance.mark('StartStickyNotesSDKLoad');
        }
        logUsage('oneNoteFeedCharmClicked');
    },
    render: renderInsideFlexPane(
        <NotesPane scenario="NotesFeedFlexPane" onClosePanel={onNotesPanelDismiss} />,
        /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
         *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
        loc(noteFeedFlexPaneTitle)
    ),
    customEscapeKeyHandling: true,
} as ShellFlexPane);

export const OwaExpressionFlexPane = createShellFlexPane({
    flexPaneID: OwaExpressionFlexPaneID,
    flexPaneTitle: () => loc(expressionHeaderText),
    onFlexPaneClose: () => lazyCloseExpressionPane.importAndExecute(),
    render: (container: HTMLDivElement) => {
        render(
            <React.StrictMode>
                <ExpressionPane />
            </React.StrictMode>,
            container
        );
    },
} as ShellFlexPane);

export const OwaDexFlexPane = createShellFlexPane({
    flexPaneID: OwaDexFlexPaneId,
    flexPaneTitle: () => loc(dexLitePaneHeaderShellPane),
    onFlexPaneClose: () => lazyCloseDexPane.importAndExecute(),
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<DexPaneWrapper />, loc(dexLitePaneHeaderShellPane)),
} as ShellFlexPane);

export const OwaSkypeFlexPane = createShellFlexPane({
    flexPaneID: OwaSkypeFlexPaneID,
    flexPaneTitle: () => null,
    onFlexPaneOpen: () => {
        if (getActiveCharm() !== 0) {
            toggleCharm(0);
        }
    },
    onFlexPaneClose: () => {
        deactivateCharm(0);
    },
    render: () => {
        // Intended to be empty, skype pane will be rendered separately
    },
} as ShellFlexPane);

export const OwaDictationHelpFlexPane = createShellFlexPane({
    flexPaneID: OwaDictationHelpFlexPaneID,
    flexPaneTitle: () => loc(dictationHelpHeaderText),
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    render: renderInsideFlexPane(<DictationHelpPane />, loc(dictationHelpHeaderText), content),
} as ShellFlexPane);

function onTimePanelDismiss() {
    closeFlexPane(OwaTimePanelFlexPaneID);

    // normally suite header would handle this concern, but Time Panel needs custom ESC key handling
    // so we need to manually setting focus back on the panel button per A11Y best practices
    document.getElementById(OwaTimePanelButtonID)?.focus();
}

function onNotesPanelDismiss() {
    closeFlexPane(OwaNoteFeedFlexPaneID);
    document.getElementById(OwaNoteFeedButtonID)?.focus();
}
