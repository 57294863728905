import { format } from 'owa-localize';
import type SweepRequestStatus from '../store/schema/SweepRequestStatus';
import sweepStore from '../store/store';
import { errorThatWillCauseAlert } from 'owa-trace';
import { mutatorAction } from 'satcheljs';

/**
 * Validate the old and new sweep request states are expected
 * @param oldStatus current sweep request status in store
 * @param newStatus the sweep request status to be set
 */
function validateSweepRequestState(oldStatus: SweepRequestStatus, newStatus: SweepRequestStatus) {
    const errorStr = 'Invalid state transition {0} to {1}';

    if (newStatus == 1 && oldStatus != 0) {
        errorThatWillCauseAlert(format(errorStr, oldStatus, newStatus));
    }

    if ((newStatus == 2 || newStatus == 3) && oldStatus != 1) {
        errorThatWillCauseAlert(format(errorStr, oldStatus, newStatus));
    }
}

/**
 * Set the SweepRequestStatus in sweep store
 * @param requestStatus the sweep request status to set
 * @param state the state which contains the sweep store
 */
export default mutatorAction(
    'setSweepRequestStatus',
    function setSweepRequestStatus(requestStatus: SweepRequestStatus) {
        if (!sweepStore.isSweepDialogShown) {
            // Do nothing if the sweep dialog is hidden when try setting SweepRequestStatus, e.g. user dismisses the
            // dialog when the request is outstanding.
            return;
        }
        validateSweepRequestState(sweepStore.sweepRequestStatus, requestStatus);
        sweepStore.sweepRequestStatus = requestStatus;
    }
);
