import { isFeatureEnabled } from 'owa-feature-flags';

const PROJECTION_URL = 'about:blank';

export default function getProjectionUrl(projectionTabId: string, freezeDryId?: string) {
    if (isFeatureEnabled('mon-projectionCustomTitlebar')) {
        let url = `${PROJECTION_URL}?windowId=${projectionTabId}`;
        if (freezeDryId && isFeatureEnabled('nh-restoreWindowPos-sendFreezeDryIdUrlParam')) {
            url += `&fdId=${freezeDryId}`;
        }
        return url;
    } else {
        return PROJECTION_URL;
    }
}
