import getMailListHeader from './getMailListHeader';
import { messageListLabel, skipToMessageList } from './ListPane.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { MailListColumnHeaders } from '../index';
import { observer } from 'owa-mobx-react';
import loc from 'owa-localize';
import { getNativeAdPlacement, type NativeAdPlacement } from 'owa-mail-ads-shared';
import { resetFocus } from 'owa-mail-focus-manager';
import {
    getStore as getListViewStore,
    getFocusedFilterForTable,
    getIsSearchTableShown,
} from 'owa-mail-list-store';
import MailList from 'owa-mail-list-view/lib/components/MailList';
import { MessageAdList } from 'owa-mail-messagead-list-view';
import { AnswersContainer, CopilotContainer } from 'owa-mail-search';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { nativeadsUpsellEffectStore } from 'owa-nativeadsupselleffect-store';
import { NotificationBarHost } from 'owa-notification-bar';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { SkipLinkRegion } from 'owa-skip-link/lib/components/SkipLinkRegion';
import React from 'react';
import {
    getDataMinMaxDimensionsForListView,
    isReadingPanePositionRight,
    shouldRenderColumnHeaders,
} from 'owa-mail-layout';
import type { UserSettingsStyleSelector } from 'owa-mail-list-view/lib/utils/getStyleSelectorAsPerUserSettings';
import { RollupContainer } from 'owa-mail-rollup';
import { LazySenderScreening } from 'owa-mail-sender-screening';
import { isSenderScreeningEnabledForFolder } from 'owa-mail-triage-common';
import { isFeatureEnabled } from 'owa-feature-flags';
import onPaste from '../utils/onPaste';
import { addWrappedEventListener } from 'owa-event-listener';
import { SearchProvider } from 'owa-search-service';
import type { SearchTableQuery } from 'owa-mail-list-search';

export interface ListPaneProps {
    tableViewId: string;
    tabBar: JSX.Element | null;
    className?: string;
    shouldShowHeader: boolean;
    style?: any;
    styleSelectorAsPerUserSettings: UserSettingsStyleSelector;
}

const ListPane = observer(function ListPane(props: ListPaneProps) {
    const {
        tableViewId,
        tabBar,
        className,
        shouldShowHeader,
        style,
        styleSelectorAsPerUserSettings,
    } = props;

    const tableView = getListViewStore().tableViews.get(tableViewId);
    const folderId = tableView?.tableQuery.folderId;
    const mailboxInfo = getModuleContextMailboxInfo();
    const { dataWidthMin, dataHeightMin, dataWidthMax, dataHeightMax } =
        getDataMinMaxDimensionsForListView();
    const shouldRenderCopilotForSearchMailResults =
        isFeatureEnabled('sea-copilotMailResults', getModuleContextMailboxInfo()) &&
        getIsSearchTableShown() &&
        tableView?.totalRowsInView !== undefined &&
        tableView?.totalRowsInView > 0 &&
        (tableView?.tableQuery as SearchTableQuery)?.searchProvider !== SearchProvider.Offline &&
        (tableView?.tableQuery as SearchTableQuery)?.searchProvider !== SearchProvider.PST;
    const mailListSkipLinkRegionRef = React.useRef<HTMLDivElement | null>(null);

    const removeKeyDownListenerRef = React.useRef<(() => void) | undefined>();
    const setMailListSkipLinkRegionRef = React.useCallback((ref: HTMLDivElement) => {
        if (ref) {
            mailListSkipLinkRegionRef.current = ref;
            removeKeyDownListenerRef.current = addWrappedEventListener(
                'ListPane',
                mailListSkipLinkRegionRef.current,
                'keydown',
                onPaste
            );
        } else {
            if (mailListSkipLinkRegionRef.current) {
                removeKeyDownListenerRef.current?.();
                removeKeyDownListenerRef.current = undefined;
                mailListSkipLinkRegionRef.current = null;
            }
        }
    }, []);

    return (
        <SkipLinkRegion
            skipLinkName={loc(skipToMessageList)}
            role={'complementary'}
            data-app-section="MessageList"
            aria-label={loc(messageListLabel)}
            tabIndex={-1}
            style={style}
            className={className}
            data-min-width={dataWidthMin}
            data-min-height={dataHeightMin}
            data-max-width={dataWidthMax}
            data-max-height={dataHeightMax}
            ref={setMailListSkipLinkRegionRef}
        >
            {isConsumer(undefined, mailboxInfo) &&
                (getNativeAdPlacement() == 1 || getNativeAdPlacement() == 2) &&
                !nativeadsUpsellEffectStore.isEffective &&
                tableView && (
                    <MessageAdList
                        loadInFocusedPivot={false}
                        loadInOtherPivot={false}
                        loadInEmptyPivot={false}
                        loadInItemView={tableView?.tableQuery?.listViewType == 1}
                        loadOnHeader={true}
                        requestingAnotherFloatingAd={false}
                        middleFloatingAdOnly={false}
                        shouldShowTwoAds={false}
                        tableView={tableView}
                        adIndex={0}
                    />
                )}
            {shouldShowHeader && getMailListHeader(tableViewId, styleSelectorAsPerUserSettings)}
            {folderId && isSenderScreeningEnabledForFolder(folderId) && (
                <LazySenderScreening mailboxInfo={mailboxInfo} />
            )}

            {shouldRenderCopilotForSearchMailResults && <CopilotContainer />}
            {tableView && shouldRenderColumnHeaders(tableViewId) && (
                <div id="SearchAnswerTop">
                    <AnswersContainer tableQuery={tableView?.tableQuery} itemIndex={0} />
                </div>
            )}
            {shouldRenderColumnHeaders(tableViewId) && folderId && (
                <>
                    <MailListColumnHeaders tableViewId={tableViewId} folderId={folderId} />
                    <RollupContainer
                        key="rollup_0"
                        focusedViewFilter={getFocusedFilterForTable(tableView)}
                        tableView={tableView}
                        shouldShowRollupOverride={true}
                    />
                </>
            )}
            <MailList
                key="listpane_list"
                tableViewId={tableViewId}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
            />
            {isConsumer(undefined, mailboxInfo) &&
                getNativeAdPlacement() == 3 &&
                !nativeadsUpsellEffectStore.isEffective &&
                tableView && (
                    <MessageAdList
                        loadInFocusedPivot={false}
                        loadInOtherPivot={false}
                        loadInEmptyPivot={false}
                        loadInItemView={tableView?.tableQuery?.listViewType == 1}
                        loadOnHeader={true}
                        requestingAnotherFloatingAd={false}
                        middleFloatingAdOnly={false}
                        shouldShowTwoAds={false}
                        tableView={tableView}
                        adIndex={0}
                    />
                )}
            {isReadingPanePositionRight() && (
                <NotificationBarHost
                    hostId={'MailModuleNotificationBarHost'}
                    onNotificationBlur={onNotificationBlurCallback}
                />
            )}
            {tabBar}
        </SkipLinkRegion>
    );
}, 'ListPane');
export default ListPane;

const onNotificationBlurCallback = () => {
    resetFocus('ListPaneNotificationBlur');
};
