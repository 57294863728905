import type { DatapointOptions, CustomData, InternalDatapointOptions } from 'owa-analytics-types';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';
import { lazyLogUsage } from '../lazyFunctions';

export function logCoreUsage(
    eventName: AnalyticsCoreEventNames,
    customData?: CustomData,
    options?: DatapointOptions
): Promise<void> {
    const logUsageOptions = (options || {}) as InternalDatapointOptions;
    logUsageOptions.isCore = true;

    return lazyLogUsage.importAndExecute(eventName, customData, logUsageOptions);
}
