import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RepromptDialog" */ './lazyIndex')
);

export const lazyShowRepromptDialogIfRequired = new LazyAction(
    lazyModule,
    m => m.showRepromptDialogIfRequired
);

export const RepromptDialog = createLazyComponent(lazyModule, m => m.RepromptDialog);
