import type {
    MutationCreateCalendarArgs,
    ResolverContext,
    CalendarEntry,
    CreateCalendarMutation_CalendarEntry,
    CalendarsUnionType,
    FolderId as Schema_FolderId,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';
import createCalendarOperation from 'owa-service/lib/operation/createCalendarOperation';
import type CalendarActionFolderIdResponse from 'owa-service/lib/contract/CalendarActionFolderIdResponse';
import type FolderId from 'owa-service/lib/contract/FolderId';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

/**
 * Mutation Web resolver responsible for createCalendar
 */
export const createCalendarMutationWeb = async (
    _parent: any,
    args: MutationCreateCalendarArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarEntry> => {
    // TODO: Strict mode is currently disabled to permit passing in null here, and needs to be
    // re-enabled once the OWS API signature has been updated.
    // Work Item 108931: Update signature of CreateCalendar API to explicitly accept null values
    const response: CalendarActionFolderIdResponse = await createCalendarOperation(
        {
            newCalendarName: args.input.newCalendarName,
            parentGroupGuid: args.input.parentGroupServerId,
            // https://o365exchange.visualstudio.com/O365%20Core/_git/Substrate?path=/sources/dev/clients/src/Owa2/Server/Core/OWAService.cs
            // Email address param can be null for local calendars, but cannot be marked as nullable by tsproxygen, so OWA
            // client needs to be able to pass empty string instead to get around type restrictions.
            // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/108931/
            emailAddress: args.input.emailAddress ?? '',
        },
        getMailboxRequestOptions(args.input.mailboxInfo)
    );

    if (!response || !response.WasSuccessful) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `createCalendarMutationWeb: failed to create calendar. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }
    if (!response.NewCalendarEntryId) {
        throw new Error(
            'createCalendarMutationWeb: failed to create calendar. NewCalendarEntryId is undefined.'
        );
    }

    const newFolderId = response.NewFolderId ? mapFolderId(response.NewFolderId) : undefined;

    const partialCalendarEntry: CreateCalendarMutation_CalendarEntry = {
        __typename: args.input.calendarTypename as CalendarsUnionType['__typename'],
        calendarId: {
            id: response.NewCalendarEntryId.Id,
            changeKey: response.NewCalendarEntryId.ChangeKey,
        },
        FolderId: newFolderId,
    };

    return partialCast<CalendarEntry>(partialCalendarEntry);
};

function mapFolderId(folderId: FolderId): Schema_FolderId {
    return {
        __typename: 'FolderId',
        Id: (folderId as FolderId).Id,
        ChangeKey: (folderId as FolderId).ChangeKey,
    };
}
