import type Message from 'owa-service/lib/contract/Message';
import {
    MailRowDataPropertyGetter,
    getSelectedItemParts,
    getSelectedTableView,
    isItemPartOperation,
} from 'owa-mail-list-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import type { TableView } from 'owa-mail-list-store';
import type { ClientItem } from 'owa-mail-store';

export default function getItemOrMessage(tableView: TableView): Message | ClientItem | undefined {
    const selectedRowKeys = [...getSelectedTableView().selectedRowKeys.keys()];
    const rowKey = selectedRowKeys.length == 1 ? selectedRowKeys[0] : '';
    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        const item = getItemForMailList(itemId, isFirstLevelExpanded(rowKey));

        return item;
    } else if (rowKey) {
        const rowId = MailRowDataPropertyGetter.getItemIds(rowKey, tableView) as string[] | null;
        if (rowId?.[0]) {
            const item = mailStore.items.get(rowId[0]);
            return item;
        }
    }
    return undefined;
}
