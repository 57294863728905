import { encodeLinkedEntity } from './encodeLinkedEntity';
import type { MailEntrypoint } from 'owa-m365-acquisitions/lib/types';

import type { TileMessageExtensionLaunchEvent } from 'owa-nova-actions/lib/types';

export function createAddInNovaEvent(
    appId: string,
    entrypoint: MailEntrypoint,
    tabId: string,
    anchorElementId: string,
    composeId?: string,
    itemId?: string
): TileMessageExtensionLaunchEvent {
    const linkedEntity = (() => {
        switch (entrypoint) {
            case 'MessageCompose':
                return encodeLinkedEntity({
                    anchorElementId,
                    scenario: 'MessageCompose',
                    activeContentTabId: tabId,
                    formId: composeId as string,
                });
            case 'MessageRead':
                return encodeLinkedEntity({
                    anchorElementId,
                    scenario: 'MessageRead',
                    activeContentTabId: tabId,
                    readingPaneItemId: itemId as string,
                });
            case 'MultipleSelection':
                return encodeLinkedEntity({
                    anchorElementId,
                    scenario: 'MultipleSelection',
                    activeContentTabId: tabId,
                });
        }
    })();

    return {
        type: 'launch' as const,
        originator: 'LaunchTile' as const,
        data: () => ({
            tileID: appId,
            launchContext: linkedEntity,
        }),
    };
}
