/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode } from 'owa-react-dom';
import getProjection from '../utils/getProjection';
import { getProjectionRoot } from '../utils/getProjectionElement';
import { errorThatWillCauseAlert } from 'owa-trace';
import getTabDeeplinkUrl from 'owa-tab-store/lib/utils/getTabDeeplinkUrl';
import removeProjection from './removeProjection';

export default function closeProjection(tabId: string, redirectToDeeplink: boolean) {
    const projection = getProjection(tabId);

    if (projection) {
        const redirectUrl = redirectToDeeplink ? getTabDeeplinkUrl(tabId) : null;

        try {
            const root = getProjectionRoot(projection);
            if (!!root) {
                unmountComponentAtNode(root);
            }

            if (redirectUrl) {
                removeProjection(projection.window);
                projection.window.location.href = redirectUrl;
            } else {
                // No need to clear store here. Store will be cleared in onUnload event handler
                projection.window.close();
            }
        } catch (e) {
            errorThatWillCauseAlert('Fail to close projection: ' + e);
        }
    }
}
