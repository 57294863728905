import type { BodyContent, BodyContentInput } from 'owa-graph-schema';

export function convertBodyContentGqlTypeToGqlInput(bodyContent: BodyContent): BodyContentInput {
    return {
        BodyType: bodyContent.BodyType ?? undefined,
        Value: bodyContent.Value ?? '', // required value of type String
        QuotedText: bodyContent.QuotedText ?? undefined,
        IsTruncated: bodyContent.IsTruncated ?? undefined,
        UTF8BodySize: bodyContent.UTF8BodySize ?? undefined,
        BodyFragmentInformation: bodyContent.BodyFragmentInformation ?? undefined,
        DataUriCount: bodyContent.DataUriCount ?? undefined,
        CustomDataUriCount: bodyContent.CustomDataUriCount ?? undefined,
    };
}
