import {
    secondLineText,
    secondLineTextContainer,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';

import { observer } from 'owa-mobx-react';
import { highlightTermsInHtmlElement } from 'owa-highlight';
import type { ConditionalFormattingModification } from 'owa-conditional-formatting';
import { getConditionalFormattingStylesForText } from 'owa-conditional-formatting';
import React from 'react';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';

export interface MailListItemLineTextProps {
    addOverflowStyles: boolean;
    highlightTerms: string[];
    text: string;
    tooltipText: string;
    additionalStyles?: {
        [style: string]: string;
    };
    conditionalFormattingModifications?: ConditionalFormattingModification[];
    highlightAccuracy?: Accuracy;
}

export default observer(function MailListItemLineText(
    props: MailListItemLineTextProps
): JSX.Element {
    const {
        addOverflowStyles,
        additionalStyles,
        highlightTerms: _highlightTerms,
        text,
        tooltipText,
        conditionalFormattingModifications,
        highlightAccuracy,
    } = props;

    const textStyle = React.useMemo(() => {
        const conditionalFormattingStyles =
            conditionalFormattingModifications && conditionalFormattingModifications.length > 0
                ? getConditionalFormattingStylesForText(conditionalFormattingModifications)
                : undefined;

        if (additionalStyles) {
            return {
                ...conditionalFormattingStyles,
                additionalStyles,
            };
        }
        return { ...conditionalFormattingStyles };
    }, [additionalStyles, conditionalFormattingModifications]);

    const highlightTerms = React.useCallback(
        (element: HTMLDivElement) => {
            highlightTermsInHtmlElement(
                element,
                _highlightTerms,
                false /* separateWordSearch */,
                highlightAccuracy /* highlightAccurcy */
            );
        },
        [_highlightTerms]
    );

    return Object.keys(textStyle).length > 0 ? (
        <div style={textStyle} className={secondLineTextContainer}>
            <span
                className={addOverflowStyles ? secondLineText : undefined}
                title={tooltipText}
                ref={highlightTerms}
            >
                {text}
            </span>
        </div>
    ) : (
        <span
            className={addOverflowStyles ? secondLineText : undefined}
            title={tooltipText}
            ref={highlightTerms}
        >
            {text}
        </span>
    );
},
'MailListItemLineText');
