import type {
    SurfaceActionsOptions,
    CalendarSurfaceAddinsOptions,
} from 'owa-outlook-service-options';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-options';

export function isMessageExtensionPinned(appId: string): boolean {
    return (
        isReadMessageExtensionPinned(appId) ||
        isComposeMessageExtensionPinned(appId) ||
        isCalendarMessageExtensionPinned(appId)
    );
}

export function isReadMessageExtensionPinned(appId: string): boolean {
    const mailOptions: SurfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    );

    return !!mailOptions.readSurfaceAddins.find(app => app.toLowerCase() == appId.toLowerCase());
}

export function isComposeMessageExtensionPinned(appId: string): boolean {
    const mailOptions: SurfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    );

    return !!mailOptions.composeSurfaceAddins.find(app => app.toLowerCase() == appId.toLowerCase());
}

export function isCalendarMessageExtensionPinned(appId: string): boolean {
    const calendarOptions: CalendarSurfaceAddinsOptions =
        getOptionsForFeature<CalendarSurfaceAddinsOptions>(
            OwsOptionsFeatureType.CalendarSurfaceAddins
        );

    return !!calendarOptions?.calendarSurfaceAddins?.find(
        app => app.toLowerCase() == appId.toLowerCase()
    );
}
