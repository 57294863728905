import type BirthdayEvent from 'owa-service/lib/contract/BirthdayEvent';
import type { OwaDate } from 'owa-datetime';
import { addDays, getDate, getMonth, getYear, userDate, getISOString, utcDate } from 'owa-datetime';
import type { MailboxInfo } from 'owa-client-types';
import { isDateInDateRange } from 'owa-datetime-utils';
import getDaysInMonth from 'owa-date-utc-fn/lib/getDaysInMonth';
import type { ClientCalendarEvent as GqlCalendarEvent, DateRangeInput } from 'owa-graph-schema';
import { convertInboxRemindersToGql } from './convertInboxRemindersToGql';

/**
 * Converts a Birthday event instance to a GqlCalendarEvent
 * @param birthdayEvent The Birthday event (service object) to convert
 * @param mailboxInfo The mailbox info for the calendar event
 * @returns The converted CalendarEvent object
 */
export function mapBirthdayCalendarItemToGql(
    birthdayEvent: BirthdayEvent,
    parentFolderId: string,
    dateRange: DateRangeInput,
    mailboxInfo?: MailboxInfo
): GqlCalendarEvent {
    const { PopupReminderSettings, Birthday } = birthdayEvent;
    const birthdayId = birthdayEvent.Id?.Id || '';
    let start: OwaDate | undefined;
    let startIsoString: string | undefined;
    let endIsoString: string | undefined;

    const firstPopUpReminderSettings =
        PopupReminderSettings && PopupReminderSettings.length > 0
            ? PopupReminderSettings[0]
            : undefined;

    if (Birthday) {
        start = calculateCalendarItemStart(Birthday, dateRange);
        startIsoString = getISOString(start);
        endIsoString = getISOString(addDays(start, 1));
    }

    // Copy then overwrite with proper conversions
    return {
        ...birthdayEvent,
        id: birthdayId,
        Subject: birthdayEvent.Name,
        Start: startIsoString,
        End: endIsoString,
        IsAllDayEvent: true,
        ParentFolderId: { Id: parentFolderId, mailboxInfo },
        ItemId: { Id: birthdayId, mailboxInfo },
        PersonId: birthdayEvent.PersonId,
        EffectiveRights: {
            Delete: true,
            Modify: true,
            Read: true,
        },
        FreeBusyType: 'Free',
        ReminderIsSet: firstPopUpReminderSettings?.IsReminderSet,
        ReminderMinutesBeforeStart: firstPopUpReminderSettings?.ReminderMinutesBeforeStart,
        InboxReminders: convertInboxRemindersToGql(birthdayEvent.EmailReminderSettings),
    };
}

/**
 * Calculates the start date of the calendar item given the birthday.
 * @param birthdayUtcIso Birthday in UTC Iso (Birthday events are always UTC format regardless of the user's timezone)
 * @returns The start date
 */
function calculateCalendarItemStart(birthdayUtcIso: string, dateRange: DateRangeInput): OwaDate {
    const birthdayDate = utcDate(birthdayUtcIso);
    const dateRangeDates = { start: userDate(dateRange.start), end: userDate(dateRange.end) };
    const candidate = calculateBirthdayForYear(birthdayDate, getYear(dateRangeDates.start));

    return !isDateInDateRange(dateRangeDates, candidate)
        ? calculateBirthdayForYear(birthdayDate, getYear(dateRangeDates.end))
        : candidate;
}

/**
 * Uses the year/ month/ date of the UTC date we receive from the server to create a date in the user's timezone
 * @param utcBirthdayDate OwaDate Birthday in UTC
 * @param year The year
 * @returns The birthday in the year requested
 */
function calculateBirthdayForYear(utcBirthdayDate: OwaDate, year: number): OwaDate {
    const month = getMonth(utcBirthdayDate);
    const originalDay = getDate(utcBirthdayDate); // ex: feb 29 2016
    const daysInMonth = getDaysInMonth(year, month); // ex: 28 (feb 2017)
    const day = Math.min(originalDay, daysInMonth);
    return userDate(year, month, day);
}
