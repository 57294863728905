/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode, render } from 'owa-react-dom';
import React from 'react';
import { MoveToFolderDialog } from './MoveToFolderDialog'; // We are importing lazily to wrap the dialog with apollo provider
import type { MailFolder } from 'owa-graph-schema';
import { wrapInApolloProvider } from 'owa-apollo/lib/react';

export function selfMountMoveToDialog(sourceFolder: MailFolder) {
    const moveFolderDialogDiv = document.createElement('div');
    moveFolderDialogDiv.id = 'moveFolderDialog';
    document.body.appendChild(moveFolderDialogDiv);
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop -- this action is triggered on click
     * and is  not re-rendered as part of the rest of the app */
    const closeDialogProp = () => {
        unmountComponentAtNode(moveFolderDialogDiv);
        document.body.removeChild(moveFolderDialogDiv);
    };
    render(
        wrapInApolloProvider(
            <MoveToFolderDialog onClose={closeDialogProp} sourceFolder={sourceFolder} />
        ),
        moveFolderDialogDiv
    );
}
