import type { MailboxInfo } from 'owa-client-types';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import getUserConfiguration from '../actions/getUserConfiguration';
import { getAccountScopeUserSettings } from '../selectors/getAccountScopeUserSettings';
import getConnectedAccountUserConfiguration from '../selectors/getConnectedAccountUserConfiguration';
import { checkGlobalSettingsReady } from './checkGlobalSettingsReady';
import { mailboxInfoOrTemporaryGlobal } from './mailboxInfoOrTemporaryGlobal';

/***
 * Function that checks whether the primary account of logged in user represents a consumer account
 */
export default function isConsumer(smtpAddress?: string, mailboxInfo?: MailboxInfo): boolean {
    if (!smtpAddress && mailboxInfo) {
        return getAccountScopeUserSettings(mailboxInfo).SessionSettings?.WebSessionType !== 0;
    }

    checkGlobalSettingsReady();
    if (smtpAddress && getUserConfiguration().SessionSettings?.LogonEmailAddress !== smtpAddress) {
        const userConfiguration = getConnectedAccountUserConfiguration(smtpAddress);
        return (
            !!userConfiguration?.SessionSettings &&
            userConfiguration.SessionSettings.WebSessionType !== 0
        );
    }

    return (
        getAccountScopeUserSettings(mailboxInfoOrTemporaryGlobal(mailboxInfo)).SessionSettings
            ?.WebSessionType !== 0
    );
}
