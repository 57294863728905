import type {
    CustomData,
    CustomDataMap,
    DatapointOptions,
    InternalDatapointOptions,
} from 'owa-analytics-types';
import { AriaDatapoint } from './AriaDatapoint';
import { scrubForPii } from 'owa-config/lib/scrubForPii';

// For these datapoints, we don't want to truncate the callstack
const fullStackDatapoints = ['TOO_MANY_RERENDERS'];

export default class GreyErrorDatapoint extends AriaDatapoint {
    private originalCustomData?: CustomDataMap;

    constructor(
        eventName?: string,
        error?: Error,
        customData?: CustomData,
        options?: DatapointOptions,
        originalCustomData?: CustomDataMap
    ) {
        super(eventName, customData, options);

        (this.options as InternalDatapointOptions).isGreyError = true;

        if (error) {
            this.addData('message', scrubForPii(error.message));
            this.addData(
                'stack',
                scrubForPii(error.stack, !fullStackDatapoints.includes(eventName ?? ''))
            );
        }

        if (originalCustomData) {
            this.originalCustomData = originalCustomData;
        }
    }

    getOriginalCustomData(): CustomDataMap | undefined {
        return this.originalCustomData;
    }
}
