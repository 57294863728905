import type { ComposeOperation } from 'owa-mail-compose-store';
import type CLPViewState from 'owa-mail-protection-types/lib/schema/clp/CLPViewState';
import type { ClientItemId } from 'owa-client-ids';
import { action } from 'satcheljs';

export default action(
    'ON_SEND_MESSAGE_SUCCEEDED',
    (
        operation: ComposeOperation,
        referenceItemId: ClientItemId | null,
        composeId: string,
        conversationId: string | undefined,
        isGroupViewState: boolean,
        clpViewState: CLPViewState
    ) => {
        return {
            operation,
            referenceItemId,
            composeId,
            conversationId,
            isGroupViewState,
            clpViewState,
        };
    }
);
