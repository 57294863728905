import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type AccountConditionStore from './schema/AccountConditionStore';
import type AccountAuthState from './schema/AccountAuthState';
export { ObservableMap };

const defaultAccountConditionStore: AccountConditionStore = {
    accountAuthStates: new ObservableMap<string, AccountAuthState>({}),
};

const accountConditionStore = createStore<AccountConditionStore>(
    'accountConditionStore',
    defaultAccountConditionStore
)();
const getStore = () => accountConditionStore;
export default getStore;
