import type { MailboxInfo } from 'owa-client-types';
import getUserAccount from './utils/getUserAccount';
import getSessionSettingsForUserAccount from './utils/getSessionSettingsForUserAccount';
import findOrMakeArchiveMailboxInfoForAccount from './utils/findOrMakeArchiveMailboxInfoForAccount';

/**
 * Get the MailboxInfo for the auxilary archive mailbox associated with the coprincipal account
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @param auxiliaryMailboxGuid The guid of the auxiliary mailbox
 * @param auxiliaryDisplayName The display name of the auxiliary mailbox
 * @returns MailboxInfo for the auxiliary mailbox or throw if it's not found or an error happens
 */
export default function getMailboxInfoForAuxArchive(
    mailboxInfoInAccount: MailboxInfo,
    auxiliaryMailboxGuid: string,
    auxiliaryDisplayName?: string
): MailboxInfo {
    const account = getUserAccount(mailboxInfoInAccount);
    const sessionSettings = getSessionSettingsForUserAccount(account);

    return findOrMakeArchiveMailboxInfoForAccount(
        account,
        sessionSettings,
        auxiliaryMailboxGuid,
        auxiliaryDisplayName
    );
}
