import { createAppButton } from 'owa-command-ribbon';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { onAddinTrigger, onMessageExtensionAddinsTrigger } from 'owa-nova-actions';
import getTabIdFromWindow from 'owa-addins-store/lib/store/getTabIdFromWindow';
import { errorThatWillCauseAlert } from 'owa-trace';
import { createAddInNovaEvent } from 'owa-nova-event-helpers';

import type { IButtonStyles } from '@fluentui/react';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getItemIds, getItem } from 'owa-mail-list-store/lib/selectors/mailRowDataPropertyGetter';
import { getIsReadButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsReadButtonDisabled';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { owaComputedFn } from 'owa-computed-fn';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import isAddinMultiAccountEnabled from 'owa-feature-flags/lib/utils/isAddinMultiAccountEnabled';
import mailStore from 'owa-mail-store/lib/store/Store';
import { getAddinIcon } from 'owa-addins-surface-actions/lib/utils/getAddinIcon';
import { areMessageExtensionsEnabledOnReadRibbon } from 'owa-feature-flags/lib/utils/areMessageExtensionsEnabledOnReadRibbon';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { hasExchangeAddInMultiSelectCommand } from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';
import { readM365AcquisitionsFromCache } from 'owa-m365-acquisitions/lib/readM365AcquisitionsFromCache';
import { isReadPinnedAppRuntimeControl } from 'owa-mail-ribbon-store/lib/util/isReadPinnedAppRuntimeControl';
import { getIsMultipleSelection } from 'owa-mail-list-store/lib/utils/getIsMultipleSelection';
import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowReadingPane } from 'owa-mail-layout/lib/selectors/shouldShowReadingPane';

export function createPinnedAddInButton(
    addIn: AddinCommandSurfaceItem,
    composeId: string,
    editorId: string,
    controlId: number,
    targetWindow: Window,
    styles: IButtonStyles
): RibbonControlProps {
    const composeRibbonId = getComposeRibbonId(controlId, editorId);
    return createAppButton(
        composeRibbonId /* controlId */,
        addIn?.name ?? '' /* label */,
        (params?: AppButtonOnExecuteParameter) => {
            if (!params) {
                errorThatWillCauseAlert(
                    `Pinned add-in button was clicked without parameters: ${addIn?.name}`
                );
                return;
            }
            const tabId = getTabIdFromWindow(targetWindow);
            const event = createAddInNovaEvent(
                addIn.key /* appId */,
                'MessageCompose' /* entrypoint */,
                tabId /* tabId */,
                params.location === 'Overflow'
                    ? 'RibbonOverflowMenu-overflow'
                    : params.id /* anchorElementId */,
                composeId /* composeId */,
                '' /* itemId */
            );
            isFeatureEnabled('mos-messageExtensionPinning')
                ? onMessageExtensionAddinsTrigger(event)
                : onAddinTrigger(event);
        } /* onExecute handler */,
        undefined /* keyTip */,
        getAddinIcon(addIn) ?? '' /* iconName */,
        undefined /* iconColor */,
        styles,
        {
            customTooltip: addIn.ariaLabel?.replace(/\s-\s/g, '\n\n'),
        } /* overrides */
    );
}

/**
 * OnExecute handler for createAppButton
 *
 * @param {AddinCommandSurfaceItem} addIn
 * @param {(ClientItemId | undefined)} itemId
 * @param {Window} targetWindow
 * @param {?AppButtonOnExecuteParameter} [params]
 */
const onExecuteFn = owaComputedFn(function onExecuteFn(
    addIn: AddinCommandSurfaceItem,
    itemId: string | undefined,
    targetWindow: Window,
    params?: AppButtonOnExecuteParameter
): void {
    if (!params) {
        errorThatWillCauseAlert(
            `Read add-in button was clicked without parameters: ${addIn?.name}`
        );
        return;
    }
    const tabId = getTabIdFromWindow(targetWindow);

    if (!itemId && itemId !== '') {
        itemId = getItemIdFromTableView();
    }
    const event = createAddInNovaEvent(
        addIn.key /* appId */,
        'MessageRead' /* entrypoint */,
        tabId /* tabId */,
        params.location === 'Overflow'
            ? 'RibbonOverflowMenu-overflow'
            : params.id /* anchorElementId */,
        '' /* composeId */,
        itemId /* itemId */
    );
    onAddinTrigger(event);
});

/**
 * Create a button for an add-in in the read ribbon.
 *
 * @export
 * @param {AddinCommandSurfaceItem} addIn
 * @param {number} controlId
 * @param {Window} targetWindow
 * @param {IButtonStyles} styles
 * @param {ReadOnlyRibbonControlDefProps} props
 * @returns {RibbonControlProps}
 */
export function createAddinButtonForReadInRibbon(
    addIn: AddinCommandSurfaceItem,
    controlId: number,
    targetWindow: Window,
    styles: IButtonStyles,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { isPopout, itemId } = props;
    const readRibbonId = getReadRibbonId(controlId, itemId);
    const params = {} as AppButtonOnExecuteParameter;
    params.id = readRibbonId as string;
    const ribbonMailboxInfo = getRibbonMailboxInfo(props);
    const messageItemId =
        isMultipleSelection(props.isPopout) && isReadPinnedAppRuntimeControl(controlId)
            ? ''
            : itemId?.Id ?? undefined;

    return createAppButton(
        readRibbonId /* controlId */,
        // title holds the label for the button, if not present then fallback to addIn.name
        addIn?.title ?? addIn?.name ?? '' /* label */,
        getComputedCallback(
            controlId,
            onExecuteFn,
            addIn,
            messageItemId /* itemId */,
            targetWindow,
            params
        ) /* onExecute handler */,
        undefined /* keyTip */,
        getAddinIcon(addIn) ?? '' /* iconName */,
        undefined /* iconColor */,
        styles,
        {
            disabled:
                getIsReadButtonDisabled(controlId, isPopout, ribbonMailboxInfo, itemId) ||
                isAddinDisabledForMultipleSelection(
                    addIn.key,
                    controlId,
                    isPopout,
                    ribbonMailboxInfo
                ),
        } /* overrides */
    );
}

/**
 * Read itemId of the selected row in the table view.
 *
 * @returns {(string | undefined)}
 */
function getItemIdFromTableView(): string | undefined {
    const tableView = getSelectedTableView();

    // Integrated spam is not supported for multiple selection.
    if (tableView?.selectedRowKeys.size > 1) {
        return undefined;
    }
    const selectedRowKeys = [...tableView?.selectedRowKeys.keys()];
    const itemIds = getItemIds(selectedRowKeys[0], tableView);

    // Check if the item exists in the mailStore based on the itemId. If not, then
    // try to get the item from the tableView directly using mailRowDataPropertyGetter
    const itemRetrievedFromStore =
        mailStore.items.get(itemIds[0]) || getItem(selectedRowKeys[0], tableView);

    if (!itemRetrievedFromStore) {
        errorThatWillCauseAlert(`No item for: "${selectedRowKeys[0]}"`);
        return undefined;
    }

    return itemRetrievedFromStore.ItemId ? itemRetrievedFromStore.ItemId.Id : undefined;
}

function isAddinDisabledForMultipleSelection(
    appId: string,
    controlId: number,
    isPopOut: boolean,
    mailboxInfo: MailboxInfo
): boolean {
    if (
        areMessageExtensionsEnabledOnReadRibbon() &&
        isReadPinnedAppRuntimeControl(controlId) &&
        isMultipleSelection(isPopOut)
    ) {
        const acquisitions = readM365AcquisitionsFromCache(
            undefined /* datapoint */,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );

        const matchingAcquisition = acquisitions.find(
            acquisition => acquisition.manifestId?.toLowerCase() === appId.toLowerCase()
        );

        if (matchingAcquisition) {
            return !hasExchangeAddInMultiSelectCommand(matchingAcquisition);
        }
    }
    return false;
}

function isMultipleSelection(isPopOut: boolean): boolean {
    const isInMutipleSelectionCheckedMode =
        getIsMultipleSelection() ||
        (isFeatureEnabled('tri-reselect-checkbox-behavior') &&
            getSelectedTableView()?.isInCheckedMode);

    return (
        !isPopOut &&
        (isInMutipleSelectionCheckedMode ||
            (isReadingPanePositionOff() && !shouldShowReadingPane()))
    );
}
