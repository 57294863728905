import type { CoordinateMap } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/TabList';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setItem, getItem, removeItem } from 'owa-local-storage';
import { getBrowserWidth } from 'owa-config';
import { getCurrentModule } from 'owa-app-module-store';

const LocalStorageKey = 'OwaCoordinatesMap';

type CoordinateMapByApp = {
    [app: string]: CoordinateMap;
};
let coordinatesMap: CoordinateMapByApp | undefined;

interface CoordinatesAndWidth {
    coor: CoordinateMapByApp;
    width: number;
}

export function getCoordinatesMap(key: string): CoordinateMap | undefined {
    if (isFeatureEnabled('fwk-coor-cache-3')) {
        if (!coordinatesMap) {
            const localStorageValue = getItem(window, LocalStorageKey);
            if (localStorageValue) {
                const coordinatesAndWidth: CoordinatesAndWidth = JSON.parse(localStorageValue);

                // if the width of the browser is the same as the width when we stored it,
                // then we can use the coordinate map
                if (coordinatesAndWidth.width == getBrowserWidth()) {
                    coordinatesMap = coordinatesAndWidth.coor;
                } else {
                    removeItem(window, LocalStorageKey);
                }
            }
        }

        return coordinatesMap?.[getCurrentModule() + key];
    }
    return undefined;
}

export function onTabCoordinatesCalculated(key: string, coordinates: CoordinateMap) {
    if (isFeatureEnabled('fwk-coor-cache-3')) {
        coordinatesMap = coordinatesMap || {};
        coordinatesMap[getCurrentModule() + key] = coordinates;
        const coordinatesAndWidth: CoordinatesAndWidth = {
            coor: coordinatesMap,
            width: getBrowserWidth(),
        };
        setItem(window, LocalStorageKey, JSON.stringify(coordinatesAndWidth));
    }
}

export function resetCoordinates() {
    if (isFeatureEnabled('fwk-coor-cache-3')) {
        removeItem(window, LocalStorageKey);
        coordinatesMap = undefined;
    }
}
