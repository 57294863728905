import type {
    MutationImportEventsFromIcsFileArgs,
    ResolverContext,
    ImportEventsFromIcsFileResult,
} from 'owa-graph-schema';

import folderIdFactory from 'owa-service/lib/factory/folderId';
import importCalendarEventOperation from 'owa-service/lib/operation/importCalendarEventOperation';
import importCalendarEventRequest from 'owa-service/lib/factory/importCalendarEventRequest';
import type ImportCalendarEventResponseMessage from 'owa-service/lib/contract/ImportCalendarEventResponseMessage';
import targetFolderId from 'owa-service/lib/factory/targetFolderId';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { DatapointStatus } from 'owa-analytics-types';

/**
 * Mutation Web resolver responsible for importEventsFromIcsFile
 */
export const importEventsFromIcsFileMutationWeb = async (
    _parent: any,
    args: MutationImportEventsFromIcsFileArgs,
    _context: ResolverContext,
    _info: any
): Promise<ImportEventsFromIcsFileResult> => {
    const { fileContent, attachmentId, folderId, mailboxInfo } = args.input;

    // addToCalendarAttachmentService calls this mutation without a folderId, but folderIdFactory
    // does not accept a nullish value for ID, so this is a workaround for that scenario
    const baseFolderId = folderId
        ? folderIdFactory({ Id: folderId })
        : { __type: 'FolderId:#Exchange', Id: null };

    const response = await importCalendarEventOperation(
        {
            Header: getJsonRequestHeader(),
            Body: importCalendarEventRequest({
                Content: fileContent ?? undefined,
                ImportedItemFolderId: targetFolderId({
                    BaseFolderId: baseFolderId,
                }),
                AttachmentId: attachmentId ?? undefined,
            }),
        },
        getMailboxRequestOptions(mailboxInfo, {
            datapoint: {
                jsonCustomData: (json: ImportCalendarEventResponseMessage) => ({
                    status:
                        json?.ResponseClass == 'Success'
                            ? DatapointStatus.Success
                            : DatapointStatus.RequestNotComplete,
                    numberOfEventsImported: json.NumberOfEventsImported ?? 0,
                }),
            },
        })
    );

    if (response?.Body == null || response.Body.ResponseClass !== 'Success') {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `importEventsFromIcsFileMutationWeb: importCalendarOperation failed. ${
                response?.Body
                    ? `ResponseCode: ${response.Body.ResponseCode}, MessageText: \'${response.Body.MessageText}\'`
                    : 'Did not receive a response from service'
            }`
        );
    }

    return {
        numberOfEventsImported: response.Body.NumberOfEventsImported,
    };
};
