import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';
import store from 'owa-hotkeys-map/lib/store/Store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailHotKeys" */ './lazyIndex'),
    { name: 'MailHotKeys' }
);

export const lazySetupUndoHotKeys = new LazyAction(lazyModule, m => m.setupUndoHotKeys);
export const MailHotkeysMap = createLazyComponent(
    lazyModule,
    m => m.MailHotkeysMap,
    undefined,
    undefined,
    { onlyImportIf: () => store.isVisible }
);
export const lazySetupMailHotkeysMapProperties = new LazyAction(
    lazyModule,
    m => m.setupMailHotkeysMapProperties
);
export const lazySetupSyncMailboxHotKeys = new LazyAction(
    lazyModule,
    m => m.setupSyncMailboxHotKeys
);
