import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Tti" */ './lazyIndex'), {
    name: 'Tti',
});
export const lazyGovern = new LazyAction(lazyModule, m => m.govern);
export const lazyEnableGovernReport = new LazyAction(lazyModule, m => m.enableGovernReport);

/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Re exporting a type from the index won't hurt bundling
 *	> Do not re-export from a separate package */
export { GovernPriority } from 'owa-client-types/lib/GovernPriority';
