import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyRespondToMeetingRequestWeb = createLazyResolver(
    'RESPOND_TO_MEETING_INVITE_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "RespondToMeetingRequestMutationWebResolver"*/ './respondToMeetingRequestWeb'
        ),
    m => m.respondtoMeetingRequestWeb
);
