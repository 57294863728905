import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarBootGroupNavActions"*/ './lazyFunctions')
);

export const lazySwitchToGroupsModule = new LazyAction(lazyModule, m => {
    const action = m.lazySwitchToGroupsModule;
    return action.importAndExecute.bind(action);
});
