import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "InactiveTransactions" */ './lazyIndex')
);

export const lazyCheckForInactiveTransaction = new LazyAction(
    lazyModule,
    m => m.checkForInactiveTransaction
);
