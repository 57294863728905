import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

const onAcquisitionsRemoteRefresh = action(
    'onAcquisitionsRemoteRefresh',
    (nextInterval: number, isInitializationFetch: boolean, mailboxInfo?: MailboxInfo) => ({
        nextInterval,
        isInitializationFetch,
        mailboxInfo,
    })
);

export default onAcquisitionsRemoteRefresh;
