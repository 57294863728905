import { LazyAction, LazyModule } from 'owa-bundling';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListResponseProcessor"*/ './lazyIndex'),
    {
        name: 'MailListResponseProcessor',
    }
);
// Init/Fetching
export const lazyFetchRichContentForRows = new LazyAction(
    lazyModule,
    m => m.fetchRichContentForRows
);
export const lazyReloadTable = new LazyAction(lazyModule, m => m.reloadTable);

export { default as appendRowResponse } from './actions/appendRowResponse';
export { default as mergeRowResponseFromTop } from './actions/mergeRowResponseFromTop';
export { default as appendConversationWithSeekToConditionResponse } from './actions/appendConversationWithSeekToConditionResponse';
export { default as appendItemWithSeekToConditionResponse } from './actions/appendItemWithSeekToConditionResponse';
export { default as getFirstUnpinnedRowIndex } from './actions/helpers/getFirstUnpinnedRowIndex';
export { default as preProcessServerRowPayload } from './actions/preProcessServerRowPayload';
export { default as preProcessServerRowDeleteNotification } from './actions/preProcessServerRowDeleteNotification';
export { default as isRowSortKeyEqual } from './actions/helpers/isSortKeyEqual';
export { default as getDateSortTimeStampFromRowData } from './actions/helpers/getDateSortTimeStampFromRowData';
export { onMergeRowResponseFromTopInTableComplete } from './actions/onMergeRowResponseFromTopInTableComplete';
export { default as compareRowDateTime } from './actions/helpers/compareRowDateTime';
