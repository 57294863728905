import { logUsage } from 'owa-analytics';
import { getStore } from 'owa-teams-policies-lazy/lib/store/store';

export function isMeetingChatEnabled(): boolean {
    const policies = getStore().policies;
    /***
     * This is temporary for debugging purposes. We will remove this once we have enough data.
     * WI: 261051
     */
    if (!policies) {
        logUsage('TeamsPoliciesNotLoaded');
    }
    return policies?.meetingChatEnabledType === 'Enabled' && !!policies?.teamsFeatureEnabled;
}
