import mailStore from 'owa-mail-store/lib/store/Store';
import tryRemoveFromMailStoreItems, {
    type RemoveItemSource,
} from 'owa-mail-actions/lib/triage/tryRemoveFromMailStoreItems';
import { onDeleteConversationItemPartsComplete } from 'owa-mail-actions/lib/mailTriageActions';
import { mutatorAction } from 'satcheljs';

export default function deleteConversationItemParts(conversationId: string) {
    if (mailStore.conversations.has(conversationId)) {
        const conversationItemParts = mailStore.conversations.get(conversationId);
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (14,42): Object is possibly 'undefined'.
        // @ts-expect-error
        for (const conversationNodeId of conversationItemParts.conversationNodeIds) {
            const conversationNode = mailStore.conversationNodes.get(conversationNodeId);
            if (conversationNode) {
                for (const itemId of conversationNode.itemIds) {
                    tryRemoveFromMailStoreItems(itemId, 1);
                }
            }
            deleteConversationNode(conversationNodeId);
        }

        deleteConversation(conversationId);
    }

    onDeleteConversationItemPartsComplete();
}

const deleteConversationNode = mutatorAction(
    'deleteConversationNode',
    (conversationNodeId: string) => {
        mailStore.conversationNodes.delete(conversationNodeId);
    }
);

const deleteConversation = mutatorAction('deleteConversation', (conversationId: string) => {
    mailStore.conversations.delete(conversationId);
});
