import { ObservableMap } from 'mobx';
import type { MailFolder } from 'owa-graph-schema';
import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';
import getFolderTable from '../selectors/getFolderTable';

export const addFoldersToFolderTableMutator = mutatorAction(
    'addFoldersToFolderTableMutator',
    (folders: { [id: string]: MailFolder }) => {
        const folderTable = getFolderTable();
        if (folderTable.size == 0) {
            getStore().folderTable = new ObservableMap();
        }

        Object.keys(folders).forEach(id => {
            const newFolderString = JSON.stringify(folders[id]);
            if (JSON.stringify(getFolderTable().get(id)) != newFolderString) {
                const newFolder = JSON.parse(newFolderString);
                getFolderTable().set(id, newFolder);
            }
        });
    }
);
