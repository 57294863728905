import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyDeleteAttachmentMutationWeb = createLazyResolver(
    'DELETEATTACHMENT_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "DeleteAttachmentMutationWeb" */ './deleteAttachmentMutationWeb'
        ),
    m => m.deleteAttachmentMutationWeb
);
