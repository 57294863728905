import { isFeatureEnabled } from 'owa-feature-flags';
import { SubstrateSearchScenario } from '../data/schema/SubstrateSearchScenario';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getHasArchive } from 'owa-session-store';

/**
 * Helper function to return "X-Client-Flights" header value to compose with
 * server flights
 */
export default function getXClientFlightsHeaderValue(
    scenarioId: SubstrateSearchScenario
): string | undefined {
    if (scenarioId !== SubstrateSearchScenario.Mail) {
        return undefined;
    }

    const flightNames: string[] = ['OWA_BestMatch_V15'];
    if (isFeatureEnabled('sea-fetchEvents2')) {
        flightNames.push('CalendarInsightsFlight');
    }

    if (getHasArchive(getGlobalSettingsAccountMailboxInfo())) {
        flightNames.push('OWA_HasArchiveMailbox');
    }

    const tidbitsSearchFlights = [
        'EESForEmailConv',
        'EESForEmailConvSsa',
        'EnableTidBits',
        'EnableTidBits2',
        'PopulateTidBits',
        'PopulateTidBits2',
    ];

    tidbitsSearchFlights.forEach(tidbitFlight => flightNames.push(tidbitFlight));

    if (isFeatureEnabled('sea-folderRecursive')) {
        flightNames.push('DisableAncestorContainerIDOnlyValidation');
    }

    return flightNames.length > 0 ? flightNames.join(',') : undefined;
}
