//This is used to pass extended properties in the OWS CreateCalendarEvent, UpdateCalendarEvent
import extendedPropertyType from 'owa-service/lib/factory/extendedPropertyType';
import {
    SchedulingServiceMeetingOptionsUrlExtendedProperty,
    SchedulingServiceUpdateUrlExtendedProperty,
} from '../types/OnlineMeetingServiceExtendedProperty';
import type ExtendedPropertyType from 'owa-service/lib/contract/ExtendedPropertyType';
import type { ProviderPropertiesEntry } from 'owa-graph-schema';

const getExtendedProperties = (
    providerProperties: (ProviderPropertiesEntry | null)[] | undefined | null
): ExtendedPropertyType[] => {
    const extendedProperties: ExtendedPropertyType[] = [];
    if (providerProperties?.length) {
        //Fetching ScheduleServiceUpdateUrl value
        const updateUrl = providerProperties.find(
            property => property?.key === 'links.update'
        )?.value;

        //Fetching ScheduleServiceMeetingOptionsUrl value
        const meetingOptionsUrl = providerProperties.find(
            property => property?.key === 'links.options'
        )?.value;

        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: SchedulingServiceUpdateUrlExtendedProperty,
                Value: updateUrl,
            }),
            extendedPropertyType({
                ExtendedFieldURI: SchedulingServiceMeetingOptionsUrlExtendedProperty,
                Value: meetingOptionsUrl,
            })
        );
    }
    return extendedProperties;
};

export default getExtendedProperties;
