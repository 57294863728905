import getAccountNumberByType from '../selectors/getAccountNumberByType';
import getAccountsByType from '../selectors/getAccountsByType';
import { getAllDistinctPendingAccountsForMail } from '../selectors/getDistinctPendingAccounts';
import { getDomainName } from 'owa-account-source-list-internal';
import { getCoprincipalAccounts } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import { logCoreUsageFromAccounts } from 'owa-account-analytics';

let loggedPendingAccountTypes = false;

export function logPendingAccountTypes() {
    const numberOfPendingAccounts = getAllDistinctPendingAccountsForMail().length;
    const numberOfLoggedInAccounts = getCoprincipalAccounts().length;
    if (numberOfPendingAccounts > 0 && numberOfLoggedInAccounts > 0 && !loggedPendingAccountTypes) {
        logCoreUsageFromAccounts('PendingAccountTypes', {
            numberOfPendingAccounts,
            numberOfLoggedInAccounts,
            office365: getAccountNumberByType(AccountSourceType.Office365),
            office365Domains: joinDomains(AccountSourceType.Office365),
            outlookDotCom: getAccountNumberByType(AccountSourceType.OutlookDotCom),
            outlookDotComDomains: joinDomains(AccountSourceType.OutlookDotCom),
            google: getAccountNumberByType(AccountSourceType.Google),
            googleDomains: joinDomains(AccountSourceType.Google),
            yahoo: getAccountNumberByType(AccountSourceType.Yahoo),
            yahooDomains: joinDomains(AccountSourceType.Yahoo),
            icloud: getAccountNumberByType(AccountSourceType.ICloud),
            icloudDomains: joinDomains(AccountSourceType.ICloud),
            imap: getAccountNumberByType(AccountSourceType.IMAP),
            imapDomains: joinDomains(AccountSourceType.IMAP),
            pop3: getAccountNumberByType(AccountSourceType.POP3),
            pop3Domains: joinDomains(AccountSourceType.POP3),
        });
        loggedPendingAccountTypes = true;
    }
}

function joinDomains(accountType: AccountSourceType): string {
    const accounts = getAccountsByType(accountType);
    return accounts.map(account => getDomainName(account.emailAddress)).join(';');
}
