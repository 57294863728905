import type {
    ApplicationSettings,
    ApplicationSettingGroup,
} from '../store/schema/ApplicationSettings';

import { defaultApplicationSettings } from '../store/applicationSettingsDefinitions';

export default function getApplicationSettingsFromSessionData<
    TGroup extends ApplicationSettingGroup
>(
    sessionData: {
        applicationSettings?: any;
    },
    group: TGroup
): ApplicationSettings[TGroup] {
    const sessionGroup = sessionData.applicationSettings?.settings[group];

    return sessionGroup || defaultApplicationSettings[group];
}
