import getWindowsTimeZoneIdFromIANAId from './getWindowsTimeZoneIdFromIANAId';

/**
 * Get the OS TimeZoneId from the browser Intl API.
 *
 * * @description
 * Note - Unsupported for IE and certain older browser version (E.g., Safari 10)
 * Note - If a user has recently traveled and changed time zones, this may be
 * inaccurate (not up-to-date) for browsers other than Chrome and Chromium-Edge
 */
export default function getOSTimeZoneId(): string | undefined {
    /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki)
     * Baseline, should investigate if file should be in th worker or we can convert to getWindowData
     *	> Unexpected use of 'window'. Do not include "window" in code included in the worker build. Use getWindowData or self instead */
    if (window.Intl) {
        const OSIANATimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return getWindowsTimeZoneIdFromIANAId(OSIANATimeZone);
    }

    return undefined;
}
