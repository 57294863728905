import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { type FocusComponent } from './types/FocusComponent';
import { PerformanceDatapoint } from 'owa-analytics';
import type { CustomWaterfallRange } from 'owa-analytics-types';
import { isFeatureEnabled } from 'owa-feature-flags';

const componentPriorityList: FocusComponent[] = ['MailCompose', 'MailList', 'ReadingPane'];

type FocusCallback = () => boolean;
const registeredComponents: {
    [focusComponent: string]: FocusCallback[];
} = {
    MailCompose: [],
    MailList: [],
    ReadingPane: [],
};

/**
 * Register a component as available to be focused on
 */
export function registerComponent(
    component: FocusComponent,
    setFocusCallback: FocusCallback
): () => void {
    const callbacks = registeredComponents[component];
    callbacks.push(setFocusCallback);
    return function unregisterComponent() {
        for (let ii = 0; ii < callbacks.length; ii++) {
            if (callbacks[ii] === setFocusCallback) {
                // Remove the callback from our list
                callbacks.splice(ii, 1);
            }
        }
    };
}

const startComponentIndices: Map<FocusComponent, CustomWaterfallRange> = new Map<
    FocusComponent,
    CustomWaterfallRange
>([
    ['MailCompose', 1],
    ['MailList', 3],
    ['ReadingPane', 5],
]);
const endComponentIndices: Map<FocusComponent, CustomWaterfallRange> = new Map<
    FocusComponent,
    CustomWaterfallRange
>([
    ['MailCompose', 2],
    ['MailList', 4],
    ['ReadingPane', 6],
]);

/**
 * Determine which component should get focus and executes its callback to set focus
 * @returns promise that resolves when focus operation has been performed
 */
export function resetFocus(source: string): Promise<void> {
    return new Promise<void>(resolve => {
        const resetFocusCallback = function () {
            addToTimingMap('resetFocus', source);
            const dp = new PerformanceDatapoint('ResetFocus');
            for (const component of componentPriorityList) {
                dp.addToCustomWaterfall(
                    startComponentIndices.get(component) ?? 15,
                    component + '_S'
                );
                if (setFocusToSynchronous(component)) {
                    dp.addToCustomWaterfall(
                        endComponentIndices.get(component) ?? 15,
                        component + '_E'
                    );
                    dp.end();
                    break;
                }
                dp.addToCustomWaterfall(endComponentIndices.get(component) ?? 15, component + '_E');
            }
            dp.end();
            return resolve();
        };

        if (isFeatureEnabled('fwk-resetFocus-loaf-fix')) {
            setTimeout(resetFocusCallback, 0);
        } else {
            window.requestAnimationFrame(resetFocusCallback);
        }
    });
}

/**
 * Sets focus to the component synchronously. This should be used with GREAT CAUTION as it will cause an immediate repaint.
 * An example is when a component is focused and about to be unmounted and focus has to be set to particular component, use
 * this function. Otherwise use resetFocus which will set focus to the highest order component.
 */
export function setFocusToSynchronous(component: FocusComponent): boolean {
    // Go through each key individually and stop once we are able to set focus
    const callbacks = registeredComponents[component];
    for (let ii = callbacks.length - 1; ii >= 0; ii--) {
        const setFocusCallback = callbacks[ii];
        const result = setFocusCallback?.();
        if (result) {
            return result;
        }
    }
    return false;
}
