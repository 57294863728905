import { trace } from 'owa-trace';
import type { DomainConnectFetchSetupResponse } from '../contract/DomainConnectFetchSetupResponse';
import { makeGetRequest } from 'owa-ows-gateway';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

const fetchDomainUrl: string = 'ows/api/v1/PremiumDomain/';

export function domainConnectFetchDomainService(): Promise<DomainConnectFetchSetupResponse> {
    return makeGetRequest(
        fetchDomainUrl,
        undefined, // correlationId
        undefined, // returnFullResponse
        null, // customHeaders
        undefined, // throwServiceError
        undefined, // includeCredentials
        undefined, // actionName
        getGlobalSettingsAccountMailboxInfo()
    )
        .then(resp => {
            return resp;
        })
        .catch(err => {
            trace.warn(err);
            return null;
        });
}
