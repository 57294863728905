import type {
    MutationDeleteCalendarGroupArgs,
    ResolverContext,
    CalendarGroup,
    DeleteCalendarGroupMutation_CalendarGroup,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';

import type CalendarActionResponse from 'owa-service/lib/contract/CalendarActionResponse';
import deleteCalendarGroupOperation from 'owa-service/lib/operation/deleteCalendarGroupOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

/**
 * Mutation Web resolver responsible for deleteCalendarGroup
 */
export const deleteCalendarGroupMutationWeb = async (
    _parent: any,
    args: MutationDeleteCalendarGroupArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarGroup> => {
    const { calendarGroupId } = args.input;

    const response: CalendarActionResponse = await deleteCalendarGroupOperation(
        {
            groupId: calendarGroupId.id,
        },
        getMailboxRequestOptions(calendarGroupId.mailboxInfo)
    );

    if (response == null || !response.WasSuccessful) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `deleteCalendarGroupMutationWeb: failed to delete calendar group. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }

    const partialCalendarGroup: DeleteCalendarGroupMutation_CalendarGroup = {
        __typename: 'CalendarGroup',
        calendarGroupId,
    };

    return partialCast<CalendarGroup>(partialCalendarGroup);
};

export default deleteCalendarGroupMutationWeb;
