import type { ConversationType } from 'owa-graph-schema';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import { isConversationSortKeyEqual } from './helpers/isSortKeyEqual';
import getTableConversationRelation from 'owa-mail-list-store/lib/utils/getTableConversationRelation';
import appendRowResponse from './appendRowResponse';

export default function appendConversationWithSeekToConditionResponse(
    tableView: TableView,
    conversations: ConversationType[],
    conversationsInView: number
) {
    // Server did not return anything
    if (!conversations || conversations.length == 0) {
        return;
    }

    // The first conversation would be the last conversation from the previous FindConversation response.
    // We check if we have that conversation and do a sort key comparision
    const conversationInstanceKeyUsedForSeekConditionRequest = conversations[0].InstanceKey;
    const tableViewConversationRelation = getTableConversationRelation(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (24,9): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        conversationInstanceKeyUsedForSeekConditionRequest,
        tableView.id
    );

    if (
        !tableViewConversationRelation ||
        !isConversationSortKeyEqual(
            tableView.tableQuery,
            tableViewConversationRelation,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (36,13): Argument of type 'import("packages/framework/owa-graph-schema/lib/__generated__/schema.all.interface").ConversationType' is not assignable to parameter of type 'import("C:/Users/loicbe/workspace/client-web/shared/internal/owa-service/lib/contract/ConversationType").default'.
            // @ts-expect-error
            conversations[0]
        )
    ) {
        // Do not process the response if the last item which was used as an instance key
        // to get the next 'n' items has
        // 1) moved in place in the table
        // Because at this point the state on the server might have changed a lot e.g. new rows might have got
        // added after this item in question and we would end up appending the new rows after the existing rows if we
        // proceed and hence we discard.
        // 2) Or the row has been deleted on client in which case we do not have anything to compare to.
        // In both above cases we would rely on the row notifications or user scrolling to get more items.
        addMailListLog(
            getMailListLogObjectToAddToStore('AppendConversationWithSeekToCondition:Aborted', {
                tableViewId: tableView.id,
                referenceInstanceKey: conversationInstanceKeyUsedForSeekConditionRequest,
                missing: !tableViewConversationRelation,
            })
        );
        return;
    }

    appendRowResponse(tableView, conversations, conversationsInView, 'AppendOnLoadMore');
}
