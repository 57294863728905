import type { PersonaPostalAddress } from 'owa-graph-schema';
import { getLocalizedAddress } from './getLocalizedAddress';
import { isNullOrWhiteSpace } from 'owa-string-utils';

/**
 * Gets the Location display text from the location persona.
 * It is the display name of the location if present, the a localized postal address otherwise
 * @param postalAddress: the location persona
 * @param displayName: the location displayName
 */
export function getLocationDisplayText(
    displayName: string | undefined,
    postalAddress: PersonaPostalAddress
): string {
    return !displayName || isNullOrWhiteSpace(displayName)
        ? getLocalizedAddress(postalAddress)
        : displayName;
}
