import { areFeatureFlagsInitialized, isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

/**
 * This is a temporary feature check as we migrate from the legacy connected
 * account infrastructure in OWA Calendar to the new multi-account auth
 * framework previewing in Monarch.
 *
 * See 'Multi-account for M2.dev plan.docx'
 * https://microsoft.sharepoint-df.com/:w:/t/TimeManagementTeam/EYcdsDAODu5EummUW_tB1MgBhUJ5IhKDHYo68FpKwLes3g?e=82pJ7T
 */
export function isMonarchMultipleAccountsEnabled(): boolean {
    return (
        isHostAppFeatureEnabled('acctmonaccounts') &&
        // Do not throw if the flight settings are not yet enabled, this will only happen
        // when we are still is startup and loading the global settings accounts. At this point
        // we can assume that multi-account is enabled.
        (!areFeatureFlagsInitialized(undefined) || isFeatureEnabled('acctmonaccounts'))
    );
}
