import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailFavoritesStore" */ './lazyIndex'),
    { name: 'MailFavoritesStore' }
);

export const lazyToggleFavoritesTreeExpansion = new LazyAction(
    lazyModule,
    m => m.toggleFavoritesTreeExpansion
);

export const lazyRemoveFavoritePersona = new LazyAction(lazyModule, m => m.removeFavoritePersona);

export const lazyOnSelectedPersonaNodeChanged = new LazyAction(
    lazyModule,
    m => m.onSelectedPersonaNodeChanged
);

export const lazyHandleToggleFavoritePersonaError = new LazyAction(
    lazyModule,
    m => m.handleToggleFavoritePersonaError
);

export const lazyMarkFavoritePersonaNodeAsAdded = new LazyAction(
    lazyModule,
    m => m.markFavoritePersonaNodeAsAdded
);

export const lazyOnFavoritePersonaSelected = new LazyAction(
    lazyModule,
    m => m.onFavoritePersonaSelected
);

export const lazyAddFavoriteFolder = new LazyImport(lazyModule, m => m.addFavoriteFolder);
export const lazyRemoveFavoriteFolder = new LazyImport(lazyModule, m => m.removeFavoriteFolder);
export const lazyAddFavoriteFolderV1 = new LazyAction(lazyModule, m => m.addFavoriteFolderV1);
export const lazyRemoveFavoriteFolderV1 = new LazyAction(lazyModule, m => m.removeFavoriteFolderV1);

export const lazyOnFavoritePersonaUpdated = new LazyAction(
    lazyModule,
    m => m.onFavoritePersonaUpdated
);

export const lazyAddFavoritePersona = new LazyAction(lazyModule, m => m.addFavoritePersona);
export const lazyInitializeFavoritePersonas = new LazyAction(
    lazyModule,
    m => m.initializeFavoritePersonas
);
export const lazyOutlookFavoritePersonasLoaded = new LazyAction(
    lazyModule,
    m => m.outlookFavoritePersonasLoaded
);

export const lazyOutlookFavoriteFoldersLoaded = new LazyAction(
    lazyModule,
    m => m.outlookFavoriteFoldersLoaded
);
