//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MailFolderRootFragmentDoc } from 'owa-mail-folder-view-graphql/lib/graphql/__generated__/MailFolderRoot.interface';
import { MailFolderListWrapperFragmentDoc } from 'owa-mail-folder-view-graphql/lib/graphql/__generated__/MailFolderListWrapper.interface';
export type MailFolderTreeContainerQueryVariables = Types.Exact<{
    baseFolderDistinguishedIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
    offset?: Types.InputMaybe<Types.Scalars['Int']>;
    mailboxInfo: Types.MailboxInfoInput;
}>;
export type MailFolderTreeContainerQuery = {
    __typename?: 'Query';
    folderHierarchy?: {
        __typename?: 'FolderHierarchyResult';
        IncludesLastItemInRange?: boolean | null;
        offset?: number | null;
        RootFolder?: {
            __typename?: 'MailFolder';
            id: string;
            type?: string | null;
            childFolderIds?: Array<string> | null;
            displayName: string;
            distinguishedFolderType?: string | null;
            mailboxInfo: any;
            parentFolderId: string;
            totalMessageCount: number;
            UnreadCount: number;
            remoteFolderInfo?: {
                __typename?: 'RemoteFolderInfo';
                remoteFolderDisplayName?: string | null;
            } | null;
            EffectiveRights?: {
                __typename?: 'EffectiveRightsType';
                Modify?: boolean | null;
                Delete?: boolean | null;
            } | null;
        } | null;
    } | null;
};
export const MailFolderTreeContainerDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'MailFolderTreeContainer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: {
                                    kind: 'NamedType',
                                    name: { kind: 'Name', value: 'String' },
                                },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'folderHierarchy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'baseFolderDistinguishedIds' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mailboxInfo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mailboxInfo' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'RootFolder' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MailFolderRoot' },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'MailFolderListWrapper',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'IncludesLastItemInRange' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                            ],
                        },
                    },
                ],
            },
        },
        ...MailFolderRootFragmentDoc.definitions,
        ...MailFolderListWrapperFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MailFolderTreeContainerQuery, MailFolderTreeContainerQueryVariables>;
