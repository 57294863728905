import type { DatapointOptions, CustomData, InternalDatapointOptions } from 'owa-analytics-types';
import { lazyLogUsage } from '../lazyFunctions';

const GREY_ERROR_TABLE = 'client_grey_error';

// TODO: __LEGACY: Remove everything except lazyLogUsage function call
export function logUsage(
    eventName: string,
    customData?: CustomData,
    options?: DatapointOptions
): Promise<void> {
    const tableOverride = (options as InternalDatapointOptions)?.isGreyError
        ? GREY_ERROR_TABLE
        : undefined;

    return lazyLogUsage.importAndExecute(eventName, customData, options, tableOverride);
}
