import { getCdnUrl } from 'owa-config';

const ASSETS_PATH = 'assets/mail/illustrations';
export type IllustrationType = any;

type IllustrationData = {
    path: string;
    version: number;
};

type IllustrationMap = {
    [key in IllustrationType]: IllustrationData;
};

function illustrationMap(isDark: boolean): IllustrationMap {
    return {
        ['AvatarAdd']: { path: `${ASSETS_PATH}/avatarAdd/`, version: 1 },
        ['BellToggleOff']: { path: `${ASSETS_PATH}/bellToggleOff/`, version: 1 },
        ['CheckmarkProgress']: {
            path: `${ASSETS_PATH}/checkmarkProgress/`,
            version: 1,
        },
        ['DataTrending']: { path: `${ASSETS_PATH}/dataTrending/`, version: 1 },
        ['DocumentAdd']: { path: `${ASSETS_PATH}/documentAdd/`, version: 1 },
        ['DocumentPeople']: { path: `${ASSETS_PATH}/documentPeople/`, version: 1 },
        ['M365Files']: { path: `${ASSETS_PATH}/m365Files/`, version: 1 },
        ['WebPersonDismiss']: {
            path: `${ASSETS_PATH}/webPersonDismiss/`,
            version: 1,
        },
        ['GenericError']: { path: `${ASSETS_PATH}/genericError/`, version: 1 },
        ['DisconnectedError']: {
            path: `${ASSETS_PATH}/disconnectedError/`,
            version: 1,
        },
        ['DocumentExclamationMark']: {
            path: `${ASSETS_PATH}/documentExclamationMark/`,
            version: 1,
        },
        ['NoMailSelected']: { path: `${ASSETS_PATH}/noMailSelected/`, version: 2 },
        ['EmailNotFound']: { path: `${ASSETS_PATH}/emailNotFound/`, version: 1 },
        ['NoUnReadMessages']: {
            version: 1,
            path: `${ASSETS_PATH}/noUnReadMessages/`,
        },
        ['EmptyInbox']: { path: `${ASSETS_PATH}/emptyInbox/`, version: 1 },
        ['EmptyFolder']: { path: `${ASSETS_PATH}/emptyFolder/`, version: 1 },
        ['EmptyDraft']: { path: `${ASSETS_PATH}/emptyDraft/`, version: 1 },
        ['EmptySent']: { path: `${ASSETS_PATH}/emptySent/`, version: 1 },
        ['EmptyArchive']: { path: `${ASSETS_PATH}/emptyArchive/`, version: 1 },
        ['EmptyDeleted']: { path: `${ASSETS_PATH}/emptyDeleted/`, version: 1 },
        ['EmptyMentions']: { path: `${ASSETS_PATH}/emptyMentions/`, version: 1 },
        ['EmptyAttached']: {
            path: `${ASSETS_PATH}/emptyAttachments/`,
            version: 1,
        },
        ['EmptyFiles']: { path: `${ASSETS_PATH}/emptyFiles/`, version: 1 },
        ['EmptyFlagged']: { path: `${ASSETS_PATH}/emptyFlagged/`, version: 1 },
        ['EmptyToMe']: { path: `${ASSETS_PATH}/emptyToMe/`, version: 1 },
        ['EmptyUnread']: { path: `${ASSETS_PATH}/emptyUnread/`, version: 1 },
        ['EmptySpotlight']: { path: `${ASSETS_PATH}/emptySpotlight/`, version: 2 },
        ['MailSelected']: {
            path: `${ASSETS_PATH}/mailSelected/`,
            version: isDark ? 2 : 2.1,
        },
        ['EmptyCalendar']: { path: `${ASSETS_PATH}/emptyCalendar/`, version: 1 },
        ['EmptyNotifications']: {
            path: `${ASSETS_PATH}/emptyNotifications/`,
            version: 1,
        },
        ['EmptyGroups']: { path: `${ASSETS_PATH}/emptyGroups/`, version: 1 },
        ['EmptyCopilotAppContext']: {
            path: `${ASSETS_PATH}/workspaceActive/`,
            version: 1,
        },
        ['TaskList']: { path: `${ASSETS_PATH}/taskList/`, version: 1 },
        ['Privacy']: { path: `${ASSETS_PATH}/privacy/`, version: 1 },
        ['Security']: { path: `${ASSETS_PATH}/security/`, version: 1 },
        ['TXPFlight']: { path: `${ASSETS_PATH}/txpFlight/`, version: 1 },
        ['TXPLodging']: { path: `${ASSETS_PATH}/txpLodging/`, version: 1 },
        ['TXPParcelDelivery']: {
            path: `${ASSETS_PATH}/txpParcelDelivery/`,
            version: 1,
        },
        ['TXPRentalCar']: { path: `${ASSETS_PATH}/txpRentalCar/`, version: 1 },
        ['TXPEvent']: { path: `${ASSETS_PATH}/txpEvent/`, version: 1 },
        ['TXPDining']: { path: `${ASSETS_PATH}/txpDining/`, version: 1 },
        ['TXPInvoice']: { path: `${ASSETS_PATH}/txpInvoice/`, version: 1 },
        ['TXPServiceReservation']: {
            path: `${ASSETS_PATH}/txpServiceReservation/`,
            version: 1,
        },
        ['Warning']: {
            path: `${ASSETS_PATH}/warning/`,
            version: 1,
        },
        ['WebWorkspace']: { path: `${ASSETS_PATH}/webWorkspace/`, version: 1 },
    };
}

export const getIllustration = (
    illustrationKind: IllustrationType,
    dark: boolean = false,
    assetVersion?: number
): string => {
    const cdnBaseUrl = getCdnUrl();

    const mappedIllustrations = illustrationMap(dark);
    const { path, version } = mappedIllustrations[illustrationKind];
    return `${cdnBaseUrl}${path}v${assetVersion ?? version}/${dark ? 'dark' : 'light'}.svg`;
};
