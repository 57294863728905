import type EmailReminderSendOption from 'owa-service/lib/contract/EmailReminderSendOption';
import type EmailReminderChangeType from 'owa-service/lib/contract/EmailReminderChangeType';
import type InboxReminderType from 'owa-service/lib/contract/InboxReminderType';
import type {
    InboxReminder,
    EmailReminderChangeType as Schema_EmailReminderChangeType,
    EmailReminderSendOption as Schema_EmailReminderSendOption,
} from 'owa-graph-schema';
import { assertNever } from 'owa-assert';

export function convertInboxRemindersToOws(inboxReminder: InboxReminder): InboxReminderType {
    const { SendOption, OccurrenceChange } = inboxReminder;
    return (
        inboxReminder && {
            Id: <string>inboxReminder.Id,
            ReminderOffset: <number>inboxReminder.ReminderOffset,
            Message: <string>inboxReminder.Message,
            IsOrganizerReminder: <boolean>inboxReminder.IsOrganizerReminder,
            IsImportedFromOLC: <boolean>inboxReminder.IsImportedFromOLC,
            Subject: <string>inboxReminder.Subject,
            RichTextMessage: <string>inboxReminder.RichTextMessage,
            SendOption: SendOption ? mapEmailReminderSendOption(SendOption) : undefined,
            OccurrenceChange: OccurrenceChange
                ? mapEmailReminderChangeType(OccurrenceChange)
                : undefined,
        }
    );
}

function mapEmailReminderChangeType(
    emailReminderChangeType: Schema_EmailReminderChangeType
): EmailReminderChangeType {
    switch (emailReminderChangeType) {
        case 'None':
            return 0;
        case 'Added':
            return 1;
        case 'Override':
            return 2;
        case 'Deleted':
            return 3;
        default:
            return assertNever(emailReminderChangeType);
    }
}

function mapEmailReminderSendOption(
    emailReminderSendOption: Schema_EmailReminderSendOption
): EmailReminderSendOption {
    switch (emailReminderSendOption) {
        case 'NotSet':
            return 0;
        case 'User':
            return 1;
        case 'AllAttendees':
            return 2;
        case 'Staff':
            return 3;
        case 'Customer':
            return 4;
        default:
            return assertNever(emailReminderSendOption);
    }
}
