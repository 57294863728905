import { default as setFolderSortBy } from './actions/setFolderSortBy';
import { LazyAction, LazyModule, registerLazyOrchestrator, LazyImport } from 'owa-bundling';
import emptyFolderStoreUpdate from 'owa-folder-emptyfolder/lib/actions/emptyFolderStoreUpdate';
import markAllAsReadStoreUpdate from 'owa-mail-actions/lib/triage/markAllAsReadStoreUpdate';
import { onNewFolderNotification } from 'owa-mail-actions/lib/folderNotificationActions';

export {
    default as getListViewTypeForFolder,
    getListViewTypeForFolderV2,
} from './utils/getListViewTypeForFolder';
export { setFolderSortBy };
export { default as showErrorMessage } from './utils/showErrorMessage';
export { default as loadMailFolders } from './actions/loadMailFolders';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailFolderStore" */ './lazyIndex')
);

registerLazyOrchestrator(
    onNewFolderNotification,
    lazyModule,
    m => m.onNewFolderRowNotificationOrchestrator
);

registerLazyOrchestrator(markAllAsReadStoreUpdate, lazyModule, m => m.markAllAsReadOrchestrator);
registerLazyOrchestrator(
    emptyFolderStoreUpdate,
    lazyModule,
    m => m.emptyFolderStoreUpdateOrchestrator
);
registerLazyOrchestrator(setFolderSortBy, lazyModule, m => m.setFolderSortByOrchestrator);

export const lazyDismissMailFolderTextField = new LazyAction(
    lazyModule,
    m => m.dismissMailFolderTextField
);

export const lazyRemoveSortByOverride = new LazyAction(lazyModule, m => m.removeSortByOverride);

export const lazyGetFolderUrl = new LazyImport(lazyModule, m => m.getFolderUrl);

export const lazyGetFolderViewStateFromId = new LazyImport(
    lazyModule,
    m => m.getFolderViewStateFromId
);

export { getShouldOverrideToSortBySizeForMailFolder } from './utils/getShouldOverrideToSortBySizeForMailFolder';
export { FolderContextMenuState } from './store/schema/FolderContextMenuState';
