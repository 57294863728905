import Droppable from 'owa-dnd/lib/components/Droppable';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderForestTreeType } from 'owa-graph-schema';
import { showFolderTreeContextMenu } from 'owa-mail-folder-store/lib/actions/folderTreeContextMenu';
import type MailFolderDragData from 'owa-mail-folder-store/lib/store/schema/MailFolderDragData';
import { getAnchorForContextMenu } from 'owa-positioning';
import type { ChevronProps } from 'owa-tree-node/lib/components/TreeNode';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import type { MailboxInfo } from 'owa-client-types';
import type { MailFolderRootFragment } from 'owa-mail-folder-view-graphql';
import { SourceMap } from 'owa-folders-constants';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { FluentIcon } from '@fluentui/react-icons';

interface MailFolderRootProps {
    rootFolder: MailFolderRootFragment; // Root folder details
    shouldBeDroppable: boolean;
    displayName: string;
    rootNodeId: string;
    treeType: FolderForestTreeType; // Type of folder tree
    mailboxInfo: MailboxInfo;
    style?: React.CSSProperties;
    moveFolder: (
        destinationFolderId: string,
        destinationFolderMailboxInfo: MailboxInfo,
        sourceFolderId: string,
        sourceFolderMailboxInfo: MailboxInfo,
        sourceFolderParentFolderId: string,
        sourceFolderDisplayName: string
    ) => void;
    setSize?: number;
    positionInSet?: number;
    customIcon?: string;
    customIconComponent?: FluentIcon;
    customDepth?: number;

    shouldShowRootNodeContextMenu: boolean;
    isRootExpanded: boolean;
    onRootNodeChevronClickedCallback: () => void; // Callback on click of root folder
    hideIcon?: boolean;
    ellipsesOnHover?: boolean;
    id?: string;
    elementRef?: React.RefObject<HTMLDivElement>;
}

export default function MailFolderRoot(props: MailFolderRootProps) {
    const { isRootExpanded } = props;
    const onRootNodeChevronClicked = React.useCallback(
        (evt: React.MouseEvent<unknown> | KeyboardEvent) => {
            evt.stopPropagation();
            props.onRootNodeChevronClickedCallback();
        },
        [props.onRootNodeChevronClickedCallback]
    );

    const chevronProps: ChevronProps = React.useMemo(() => {
        return {
            isExpanded: isRootExpanded,
            onClick: onRootNodeChevronClicked,
            isAnimated: true,
        };
    }, [isRootExpanded, onRootNodeChevronClicked]);

    const emptyDropViewState = createDropViewState();
    const onDrop = React.useCallback(
        (dragData: DragData) => {
            const mailFolderItemBeingDragged = dragData as MailFolderDragData;
            const draggedFolderId = mailFolderItemBeingDragged.folderId;
            const draggedFolderMailboxInfo = mailFolderItemBeingDragged.mailboxInfo;
            const draggedFolderParentFolderId = mailFolderItemBeingDragged.parentFolderId;
            props.moveFolder(
                props.rootFolder.id /*destinationFolderId */,
                props.mailboxInfo /* destinationFolderMailboxInfo */,
                draggedFolderId /* sourceFolderId */,
                draggedFolderMailboxInfo /* sourceFolderMailboxInfo */,
                draggedFolderParentFolderId /* parentFolderId */,
                mailFolderItemBeingDragged.displayName
            );
        },
        [props.rootFolder?.id, props.mailboxInfo.type]
    );

    const canDrop = React.useCallback(
        (dragData: DragData) => {
            const itemType = dragData.itemType;
            return (
                itemType === DraggableItemTypes.MailFolderNode &&
                props.shouldBeDroppable &&
                !!props.rootFolder
            );
        },
        [props.shouldBeDroppable, !!props.rootFolder]
    );

    const onRootNodeContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLElement>) => {
            evt.preventDefault();
            if (props.shouldShowRootNodeContextMenu) {
                showFolderTreeContextMenu(
                    props.rootFolder.id,
                    0,
                    props.rootFolder.id,
                    getAnchorForContextMenu(evt),
                    props.treeType,
                    props.rootNodeId,
                    true /* showRootNodeMenu */
                );
            }
        },
        [
            props.shouldShowRootNodeContextMenu,
            props.rootFolder?.id,
            props.rootFolder,
            props.treeType,
            props.rootNodeId,
        ]
    );

    return (
        /* Render root node */
        <Droppable
            dropViewState={emptyDropViewState}
            onDrop={onDrop}
            canDrop={canDrop}
            style={props.style}
        >
            <TreeNode
                elementRef={props.elementRef}
                chevronProps={chevronProps}
                depth={props.customDepth !== undefined ? props.customDepth : 0} // Started at 0 depth
                displayName={props.displayName}
                isRootNode={!props.customIcon && !props.customIconComponent} // if custom icon is defined, then it is not a root node
                onClick={chevronProps.onClick}
                onContextMenu={onRootNodeContextMenu}
                setSize={props.setSize}
                positionInSet={props.positionInSet}
                customIcon={props.customIcon}
                customIconComponent={props.customIconComponent}
                hideIcon={props.hideIcon}
                ellipsesOnHover={props.ellipsesOnHover}
                source={SourceMap.account}
                shouldShowFocusBorder={isFeatureEnabled('fp-jump-folder')}
                id={props.id}
            />
        </Droppable>
    );
}
