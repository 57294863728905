import store from '../store/store';
import type MailFavoritesViewState from '../store/schema/FavoritesViewState';
import setMailFavoritesViewState from '../mutators/setMailFavoritesViewState';

export default function getMailFavoritesViewState(
    mailboxKey: string
): MailFavoritesViewState | undefined {
    if (!store.mailFavoritesViewState.has(mailboxKey)) {
        setMailFavoritesViewState(mailboxKey, {
            contextMenuState: null,
            shouldShowFindFavoritesPicker: false,
        });
    }
    return store.mailFavoritesViewState.get(mailboxKey);
}
