import React from 'react';
import useIsMounted from './useIsMounted';
import type { LazyImport, LazyModule } from 'owa-bundling-light';
import type { CustomCheckpointCode, PerformanceDatapointType } from 'owa-analytics-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * If onlyImportIf is a function, then we want to wrap it around an autorun to make
 * make sure that we run this function again if an observable property changed
 * The autorun is better than the observable because we don't want to make all
 * LazyComponents observable
 */
import { autorun } from 'owa-mobx-react';

const useLazyImport = <TLazyModule extends LazyModule<any>, TImport>(
    lazyImport: LazyImport<TImport, TLazyModule>,
    importErrorHandler?: (e: Error) => void,
    onlyImportIf?: () => boolean,
    perfDatapoint?: PerformanceDatapointType | CustomCheckpointCode
): TImport | undefined => {
    const isMounted = useIsMounted();
    const [lazyImportResult, setLazyImportResult] = React.useState<TImport | undefined>(() =>
        lazyImport.isLoaded() ? lazyImport.dangerouslyImportSync() : undefined
    );

    const wasLoadedOnFirstRender = React.useMemo(() => lazyImport.isLoaded(), []);

    React.useEffect(() => {
        // Do nothing if the import was already loaded on first render
        if (wasLoadedOnFirstRender) {
            return undefined;
        }

        const startImport = () => {
            const importPromise = lazyImport.import('LazyComponent', perfDatapoint);

            // Import the promise
            importPromise.then((importedValue: TImport) => {
                if (isMounted.current) {
                    // Because our lazyImportResult might be a function (e.g. functional components),
                    // wrap the value passed to the state setter in a function, since
                    // functions passed to setState() are evaluated.
                    setLazyImportResult(() => importedValue);
                }
            });

            if (importErrorHandler) {
                importPromise.catch(importErrorHandler);
            }
        };

        // If onlyImportIf is a function, then we want to wrap it around an autorun to make
        // make sure that we run this function again if an observable property changed
        // The autorun is better than the observable because we don't want to make all
        // LazyComponents observable
        if (typeof onlyImportIf == 'function') {
            let importCalled = false;
            return autorun(`useLazyImport_${lazyImport.getName()}`, () => {
                if (!importCalled && onlyImportIf()) {
                    importCalled = true;
                    startImport();
                }
            });
        }
        startImport();
        return undefined;
    }, []);

    return lazyImportResult;
};

export default useLazyImport;
