import {
    lazyAppDefinitionCatalogQueryWeb,
    lazyM365AcquisitionsQueryWeb,
    lazyM365TitleLaunchInfoQueryWeb,
    lazyM365DomainToTitlesMappingQueryWeb,
} from 'apphost-query-web';
import { lazyUninstallM365AcquisitionMutationWeb } from 'apphost-mutation-web';
import type { Resolvers, ResolversParentTypes } from 'owa-graph-schema';

/**
 * Please keep fields alphabetized to minimize merge conflicts
 */
export const webResolvers: Resolvers = {
    /* ======================== */
    /* Resolvers for root types */
    /* ======================== */

    /**
     * The root query type. All queries that fetch data start at the Query type.
     * Resolvers under Query should not have effects
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Query: {
        m365PlatformAppCatalog: lazyAppDefinitionCatalogQueryWeb,
        m365Acquisitions: lazyM365AcquisitionsQueryWeb,
        m365TitleLaunchInfo: lazyM365TitleLaunchInfoQueryWeb,
        m365DomainToTitlesMapping: lazyM365DomainToTitlesMappingQueryWeb,
    },

    /**
     * The root mutation type. All queries that alter data start at the Mutation type.
     * Mutations typically return the mutated data.
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Mutation: {
        uninstallM365Acquisition: lazyUninstallM365AcquisitionMutationWeb,
    },

    /**
     * The root subscription type. Resolvers under subscriptions return an event stream
     * that the client responds to.
     *
     * For for definition and rationale, see https://graphql.org/blog/subscriptions-in-graphql-and-relay/#event-based-subscriptions
     * For resolver implementation, see https://www.apollographql.com/docs/apollo-server/data/subscriptions
     * For client consumption, see https://www.apollographql.com/docs/react/data/subscriptions
     */
    Subscription: {},

    /**
     * =============================
     * Resolvers for abstract types
     * =============================
     * For all abstract types — union, interfaces —, Apollo needs a hand in figuring out
     * its specific concrete type to perform type validations.
     * https://www.apollographql.com/docs/apollo-server/data/resolvers#resolving-unions-and-interfaces
     */
    AddInExtensionControl: {
        __resolveType(
            addInExtensionControl,
            _context,
            _info
        ): 'AddInControlMenu' | 'AddInButtonExecuteFunction' {
            const typeMap = new Map<
                'button' | 'menu',
                'AddInControlMenu' | 'AddInButtonExecuteFunction'
            >([
                ['button', 'AddInButtonExecuteFunction'],
                ['menu', 'AddInControlMenu'],
            ]);
            return (
                typeMap.get(addInExtensionControl.type as 'button' | 'menu') ??
                'AddInButtonExecuteFunction'
            );
        },
    },

    AddInExtensionControlButton: {
        __resolveType(_addInExtensionControl, _context, _info): 'AddInButtonExecuteFunction' {
            return 'AddInButtonExecuteFunction';
        },
    },

    /**
     * ============================
     * Resolvers for enum types
     * Transforms: variedStringENUMS -> CapitalizedEnums
     * ============================
     *
     * Context:
     * MOS3 and TMT doesn't agree on how it capitalizes some Enum values. Apollo expects one way
     * or the other (which is defined on schema). Because of that, we need to have a transformation
     * step for those ambiguous Enum types to ensure value consistency.
     * That way, 'personal', 'PERSONAL' and 'Personal' all get transformed to 'Personal'.
     */

    M365Acquisition: {
        ...genericCapitalize<'M365Acquisition', 'acquisitionState'>(
            'acquisitionState'
        ) /* AcquisitionState */,
        ...genericCapitalize<'M365Acquisition', 'acquisitionContext'>(
            'acquisitionContext'
        ) /* AcquisitionContext */,
    },

    Title: {
        ...genericCapitalize<'Title', 'scope'>('scope') /* TitleScope */,
    },

    AppDefinition: {
        ...genericCapitalize<'AppDefinition', 'state'>('state') /* AppState */,
        ...genericCapitalize<'AppDefinition', 'hostedCapabilities'>(
            'hostedCapabilities'
        ) /* AppHostedCapabilities */,
        ...genericCapitalize<'AppDefinition', 'industries'>('industries') /* AppIndustry */,
        ...genericCapitalize<'AppDefinition', 'defaultInstallScope'>(
            'defaultInstallScope'
        ) /* InstallScope */,
    },

    ConnectorDefinition: genericLowercase<'ConnectorDefinition', 'scopes'>('scopes') /* AppScope */,
    MessageExtension: genericLowercase<'MessageExtension', 'scopes'>('scopes') /* AppScope */,
    LaunchPage: genericLowercase<'LaunchPage', 'scopes'>('scopes') /* AppScope */,

    GalleryTabDefinition: {
        ...genericCapitalize<'GalleryTabDefinition', 'context'>('context') /* GalleryTabContext */,
        ...genericCapitalize<'GalleryTabDefinition', 'supportedPlatform'>(
            'supportedPlatform'
        ) /* GalleryTabPlatform */,
    },

    MobileModuleDefinition: genericCapitalize<'MobileModuleDefinition', 'type'>(
        'type'
    ) /* MobileModuleType */,

    DefaultGroupCapability: {
        ...genericCapitalize<'DefaultGroupCapability', 'team'>('team') /* GroupCapability */,
        ...genericCapitalize<'DefaultGroupCapability', 'groupChat'>(
            'groupChat'
        ) /* GroupCapability */,
        ...genericCapitalize<'DefaultGroupCapability', 'meetings'>(
            'meetings'
        ) /* GroupCapability */,
    },

    SecurityComplianceInfo: genericCapitalize<'SecurityComplianceInfo', 'status'>(
        'status'
    ) /* SecurityComplianceStatusType */,

    AppCatalogExtensionDefinition: {
        ...genericCapitalize<'AppCatalogExtensionDefinition', 'authModel'>(
            'authModel'
        ) /* AppCatalogExtensionAuthModel */,
        ...genericCapitalize<'AppCatalogExtensionDefinition', 'type'>(
            'type'
        ) /* AppCatalogExtensionType */,
    },

    AppPermissionsNode: genericCapitalize<'AppPermissionsNode', 'type'>(
        'type'
    ) /* AppPermissionsNodeType */,

    // MessageExtension
    InputExtensionCommand: {
        ...genericLowercase<'InputExtensionCommand', 'type'>(
            'type'
        ) /* InputExtensionCommandType */,
        ...genericCapitalize<'InputExtensionCommand', 'context'>(
            'context'
        ) /* InputExtensionCommandContext */,
    },

    InputExtensionMessageHandler: genericCapitalize<'InputExtensionMessageHandler', 'type'>(
        'type'
    ) /* InputExtensionMessageHandlerType */,
    ExecutionService: genericCapitalize<'ExecutionService', 'type'>(
        'type'
    ) /* ExecutionServiceType */,
    InputExtensionCommandParameter: genericCapitalize<
        'InputExtensionCommandParameter',
        'inputType'
    >('inputType') /* InputExtensionParameterType */,
};

type NonAbstractResolver<TName extends keyof Resolvers> = Resolvers[TName] extends {
    __resolveType: any;
}
    ? Omit<Resolvers[TName], '__resolveType'>
    : Resolvers[TName];

/* Enums with more than one word can't trivially be coerced. */
const replacements: Record<string, string> = {
    needsconsentforupgrade: 'NeedsConsentForUpgrade',
    statictab: 'StaticTab',
    notinstalled: 'NotInstalled',
    installedandhidden: 'InstalledAndHidden',
    installedandpermanent: 'InstalledAndPermanent',
    installedanddeprecated: 'InstalledAndDeprecated',
    adminpreinstalled: 'AdminPreInstalled',
    preconsented: 'PreConsented',
    groupchat: 'groupchat',
    commandbox: 'CommandBox',
    textarea: 'TextArea',
    choiceset: 'ChoiceSet',
    teamsmeetingdevices: 'TeamsMeetingDevices',
    personaltab: 'PersonalTab',
    channeltab: 'ChannelTab',
    privatechattab: 'PrivateChatTab',
    meetingchattab: 'MeetingChatTab',
    meetingdetailstab: 'MeetingDetailsTab',
    meetingsidepanel: 'MeetingSidePanel',
    meetingstage: 'MeetingStage',
    callingsidepanel: 'CallingSidePanel',
};

function genericCapitalize<
    TName extends keyof Resolvers,
    FName extends keyof ResolversParentTypes[TName]
>(fieldName: FName): NonAbstractResolver<TName> {
    return {
        [fieldName]: (parent: ResolversParentTypes[TName]) => {
            const value = parent[fieldName];
            if (!value) {
                return value;
            }
            if (value.__proto__.hasOwnProperty('map')) {
                return value.map(capitalizeEnum);
            }
            return capitalizeEnum(value);
        },
    } as NonAbstractResolver<TName>;
}

function genericLowercase<
    TName extends keyof Resolvers,
    FName extends keyof ResolversParentTypes[TName]
>(fieldName: FName): NonAbstractResolver<TName> {
    return {
        [fieldName]: (parent: ResolversParentTypes[TName]) => {
            const value = parent[fieldName];
            if (!value) {
                return value;
            }
            if (value.__proto__.hasOwnProperty('map')) {
                return value.map((val: string) => val.toLowerCase());
            }
            return value.toLowerCase();
        },
    } as NonAbstractResolver<TName>;
}

function capitalizeEnum(word: string | undefined): string | undefined {
    if (!word) {
        return undefined;
    }
    const enumCleaned = word.trim();
    const replacementFound = replacements[enumCleaned.toLowerCase()];
    if (replacementFound) {
        return replacementFound;
    }
    return enumCleaned.substring(0, 1).toUpperCase() + enumCleaned.substring(1);
}
