export default function checkLanguage(testLanguage: string, enabledLanguages: string[]) {
    return enabledLanguages.some(val =>
        isLocaleEnabled(testLanguage.toLowerCase(), val.toLowerCase())
    );
}

// if a locale is enabled, as assume all sub locales are enabled. e.g - if en is enabled, we assume all en-* locales are enabled
function isLocaleEnabled(testLocale: string, enabledLocale: string) {
    const testLocaleParts = testLocale.split('-');
    const enabledLocaleParts = enabledLocale.split('-');
    for (let i = 0; i < testLocaleParts.length && i < enabledLocaleParts.length; i++) {
        if (testLocaleParts[i] !== enabledLocaleParts[i]) {
            return false;
        }
    }
    return true;
}
