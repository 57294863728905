import { ObservableMap } from 'mobx';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { checkGlobalSettingsReady } from '../utils/checkGlobalSettingsReady';
import {
    getSessionCount,
    getandIncreaseSessionCount,
    getNpsDismissCount,
} from '../utils/localStorageHelper';
import type { OwaSessionStore } from './schema/OwaSessionStore';
import { isRunningOnWorker } from 'owa-config';

function getInitialStore(): OwaSessionStore {
    return {
        nonGlobalAccountDataMap: new ObservableMap({}),
        userConfiguration: undefined,
        userSessionCount: isRunningOnWorker() ? getSessionCount() : getandIncreaseSessionCount(),
        npsDismissCount: getNpsDismissCount(),

        defaultFolderIdToNameMap: new ObservableMap({}),
        connectedAccountsUserConfigurationMap: new ObservableMap({}),

        allowDefaultDataReturn: false,
    };
}

let store: OwaSessionStore;

export const getStore: () => OwaSessionStore = () => {
    checkGlobalSettingsReady();
    return store;
};

export const getRawStoreInternalOnly: () => OwaSessionStore = () => {
    if (!store) {
        initializeStore();
    }

    return store;
};

function initializeStore() {
    store = createStore<OwaSessionStore>('owaSessionStore', getInitialStore())();
}
