import { isBootFeatureEnabled } from 'owa-metatags';
import { lazyLogProfilerDatapoint } from '../lazyFunctions';

const MaxRunsPerSession = 10;
let runs = 0;
let datapointLogged = false;

export function startSelfProfiler(scenario: string): (() => Promise<void>) | null {
    // make sure the boot flight is there. This boot flight indicates when we send the
    // document policy header down. And we also need to make sure the browser supports it
    // and we will only run the profiler a limited number of times per session as it might
    // be expensive
    if (
        !isBootFeatureEnabled('jsselfprofiler') ||
        typeof self.Profiler != 'function' ||
        typeof performance?.now != 'function' ||
        runs >= MaxRunsPerSession ||
        datapointLogged
    ) {
        return null;
    }
    runs++;
    try {
        const profiler = new self.Profiler({
            sampleInterval: 10, // Target sampling every 10ms
            maxBufferSize: 10 * 100, // Cap at ~10s worth of samples
        });

        const startTime = performance.now();
        return async () => {
            const duration = Math.floor(performance.now() - startTime);
            const trace = await profiler.stop();
            if (duration > 50 && !datapointLogged) {
                try {
                    const traceString = JSON.stringify(trace);
                    lazyLogProfilerDatapoint.importAndExecute(scenario, duration, traceString);
                    datapointLogged = true;
                } catch {
                    // do nothing if we can't parse the json
                }
            }
        };
    } catch {
        // if the profiler is not enabled, then it might throw an error
        return null;
    }
}
