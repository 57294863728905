import { gql } from '@apollo/client';
import type { FieldFunctionOptions } from '@apollo/client/cache';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getFolderTable } from 'owa-folders';
import { mutatorAction } from 'satcheljs';

export function updateChildFolderIdsOnParent(
    childIds: string[],
    folderId: string,
    options: FieldFunctionOptions
) {
    if (isFeatureEnabled('fp-remove-apollo')) {
        updateChildFolderIds(childIds, folderId);
    } else {
        options.cache.writeFragment({
            id: 'MailFolder:' + folderId,
            fragment: gql`
                fragment ChildFolderIds on MailFolder {
                    childFolderIds
                }
            `,
            data: {
                childFolderIds: childIds,
            },
        });
    }
}

export const updateChildFolderIds = mutatorAction(
    'updateChildFolderIds',
    (childIds: string[], folderId: string) => {
        const folder = getFolderTable()?.get(folderId);
        if (folder && childIds) {
            folder.childFolderIds = childIds;
        }
    }
);
