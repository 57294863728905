import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarBootTimePanel"*/ './index')
);

export const lazyInitializeTimePanelProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyInitializeTimePanel;
    return action.importAndExecute.bind(action);
});

export const lazyOpenTasksViewProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOpenTasksView;
    return action.importAndExecute.bind(action);
});

export const lazyOpenTimePanelInCalendarViewProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOpenTimePanelInCalendarView;
    return action.importAndExecute.bind(action);
});

export const lazyOpenConflictsViewProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOpenConflictsView;
    return action.importAndExecute.bind(action);
});

export const lazyCloseConflictsViewProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyCloseConflictsView;
    return action.importAndExecute.bind(action);
});

export const lazyOpenEventDetailsViewProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOpenEventDetailsView;
    return action.importAndExecute.bind(action);
});

export const TimePanel = createLazyComponent(lazyModule, m => m.TimePanel);
export const TimePanelHeaderBar = createLazyComponent(lazyModule, m => m.TimePanelHeaderBar);
