import React from 'react';
import {
    type FluentIcon,
    MailInboxRegular,
    MailInboxFilled,
    DraftsRegular,
    DraftsFilled,
    FolderRegular,
    FolderFilled,
    SendRegular,
    SendFilled,
    DeleteRegular,
    DeleteFilled,
    ArchiveRegular,
    ArchiveFilled,
    FolderProhibitedRegular,
    FolderProhibitedFilled,
    NoteRegular,
    NoteFilled,
    ClockRegular,
    ClockFilled,
    FolderSearchRegular,
    FolderSearchFilled,
    MailInboxArrowUpRegular,
    MailInboxArrowUpFilled,
    bundleIcon,
} from '@fluentui/react-icons';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';

/**
 * Get custom icon for special folders like inbox, drafts, sent, deleted, etc.
 * @param showNonSpecialFolderIcon Whether to show generic folder icon
 */
function getCustomIconComponent(
    folderId: string,
    showNonSpecialFolderIcon: boolean,
    iconClassName?: string
) {
    const folderName = folderId && folderIdToName(folderId);
    switch (folderName) {
        case 'inbox':
            return <MailInboxRegular className={iconClassName} />;

        case 'sentitems':
            return <SendRegular className={iconClassName} />;

        case 'drafts':
            return <DraftsRegular className={iconClassName} />;

        case 'deleteditems':
            return <DeleteRegular className={iconClassName} />;

        case 'archive':
            return <ArchiveRegular className={iconClassName} />;

        case 'junkemail':
            return <FolderProhibitedRegular className={iconClassName} />;

        case 'scheduled':
            return <ClockRegular className={iconClassName} />;

        case 'notes':
            return <NoteRegular className={iconClassName} />;

        case 'outbox':
            return <MailInboxArrowUpRegular className={iconClassName} />;

        case SEARCH_FOLDER_ROOT_DISTINGUISHED_ID:
            return <FolderSearchRegular className={iconClassName} />;

        default:
            break;
    }

    if (showNonSpecialFolderIcon) {
        return <FolderRegular className={iconClassName} />;
    }

    return null;
}

export default getCustomIconComponent;

const MailInbox = bundleIcon(MailInboxFilled, MailInboxRegular);
const Send = bundleIcon(SendFilled, SendRegular);
const Drafts = bundleIcon(DraftsFilled, DraftsRegular);
const Delete = bundleIcon(DeleteFilled, DeleteRegular);
const Archive = bundleIcon(ArchiveFilled, ArchiveRegular);
const FolderProhibited = bundleIcon(FolderProhibitedFilled, FolderProhibitedRegular);
const Note = bundleIcon(NoteFilled, NoteRegular);
const Clock = bundleIcon(ClockFilled, ClockRegular);
const FolderSearch = bundleIcon(FolderSearchFilled, FolderSearchRegular);
const MailInboxArrowUp = bundleIcon(MailInboxArrowUpFilled, MailInboxArrowUpRegular);
const Folder = bundleIcon(FolderFilled, FolderRegular);

export function getCustomBundledIcon(
    folderId: string,
    showNonSpecialFolderIcon: boolean
): FluentIcon | undefined {
    const folderName = folderId && folderIdToName(folderId);
    switch (folderName) {
        case 'inbox':
            return MailInbox;

        case 'sentitems':
            return Send;

        case 'drafts':
            return Drafts;

        case 'deleteditems':
            return Delete;

        case 'archive':
            return Archive;

        case 'junkemail':
            return FolderProhibited;

        case 'scheduled':
            return Clock;

        case 'notes':
            return Note;

        case 'outbox':
            return MailInboxArrowUp;

        case SEARCH_FOLDER_ROOT_DISTINGUISHED_ID:
            return FolderSearch;

        default:
            break;
    }

    if (showNonSpecialFolderIcon) {
        return Folder;
    }

    return undefined;
}

export function getCustomBundledIconComponent(
    folderId: string,
    showNonSpecialFolderIcon: boolean,
    iconClassName?: string
): React.JSX.Element | undefined {
    const FolderIcon = getCustomBundledIcon(folderId, showNonSpecialFolderIcon);
    if (FolderIcon) {
        return <FolderIcon className={iconClassName} />;
    }
    return undefined;
}
