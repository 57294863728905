import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { createStore } from 'satcheljs';
import type CommandRibbonStore from './schema/CommandRibbonStore';

const defaultCommandRibbonStore = <CommandRibbonStore>{
    viewMode: 1,
};

const store = createStore<CommandRibbonStore>('CommandRibbon', defaultCommandRibbonStore)();
export default store;
export const getStore = () => store;
