import { isFeatureEnabled } from 'owa-feature-flags';
import { isTeamsChatEnabled } from 'owa-teams-policies';

export default async function isTeamsEnabled(): Promise<boolean> {
    if (isFeatureEnabled('fwk-teamsPolicies')) {
        return isTeamsChatEnabled();
    } else {
        return false;
    }
}
