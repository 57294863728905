import {
    type AccountSource,
    getAllCalendarAccountSources,
    getAllMailAccountSources,
} from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { logStartCoreUsage } from 'owa-analytics-start';
import getPendingEmailAccounts from './getPendingEmailAccounts';
import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import getPendingDataFiles from './getPendingDataFiles';
import { isEmailAccount } from '../utils/isEmailAccount';

let issueCount = 0;

/**
 * Filter out accounts already added to account list (smtp/upn issue)
 */
export function getDistinctPendingEmailAccountsForMail(): PendingAccountInfo[] {
    return getPendingEmailAccounts().filter(
        pendingAccount =>
            !getUpns(
                getAllMailAccountSources().filter(account => isEmailAccount(account))
            ).includes(pendingAccount.emailAddress)
    );
}

export function getAllDistinctPendingAccountsForMail(): PendingAccountInfo[] {
    return getDistinctPendingEmailAccountsForMail().concat(getPendingDataFiles());
}

export function getDistinctPendingAccountsForCalendar(): PendingAccountInfo[] {
    return getPendingEmailAccounts().filter(
        pendingAccount =>
            !getUpns(getAllCalendarAccountSources()).includes(pendingAccount.emailAddress)
    );
}

function getUpns(sources: AccountSource[]): string[] {
    //Only check the case which smtp and upn are different
    const upns: string[] = sources
        .filter(account => {
            const upn = getAccountScopeUserSettings(account.mailboxInfo)?.SessionSettings
                ?.UserPrincipalName;
            return upn && upn !== account.mailboxInfo.mailboxSmtpAddress;
        })
        .map(
            account =>
                getAccountScopeUserSettings(account.mailboxInfo)?.SessionSettings?.UserPrincipalName
        )
        .filter((upn): upn is string => !!upn);

    if (upns.length > issueCount) {
        logStartCoreUsage('SmtpUpnNotMatched', {
            count: upns.length,
        });
        issueCount = upns.length;
    }

    return upns;
}
