import React from 'react';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { KeytipManager, type IKeytipLayerProps, KeyCodes } from '@fluentui/react';
import { KeytipLayer } from '@fluentui/react/lib/KeytipLayer';
import { format, memoizeFunction } from '@fluentui/react/lib/Utilities';
import { isMac } from '@1js/acui-common';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { keytipAriaDescription } from './RibbonKeytipLayer.locstring.json';
import { useValueInitializedOnce } from 'owa-react-hooks/lib/useValueInitializedOnce';
import { useCustomKeytipEvents } from './useCustomKeytipEvents';
import { isFeatureEnabled } from 'owa-feature-flags';

const getContent = memoizeFunction((): IKeytipLayerProps['content'] => {
    const startKeyWindows = 'Windows';
    const startKeyMac = 'Command';
    const altMetaStartSequence = format('Alt {0}', isMac() ? startKeyMac : startKeyWindows);

    return isHostAppFeatureEnabled('altForKeytips')
        ? loc(keytipAriaDescription, altMetaStartSequence, 'F10')
        : altMetaStartSequence;
});

const getContentParity = (): IKeytipLayerProps['content'] => {
    return isHostAppFeatureEnabled('altForKeytips')
        ? loc(keytipAriaDescription, 'Alt', 'F10')
        : 'Alt';
};

const getKeytipSequences = memoizeFunction((): IKeytipLayerProps['keytipStartSequences'] => {
    const sequence = [{ key: 'Meta', modifierKeys: [KeyCodes.alt] }];

    if (isHostAppFeatureEnabled('altForKeytips')) {
        return [...sequence, { key: 'F10' }];
    }
    return sequence;
});

let lastActiveElement: typeof document.activeElement;

const manageFocusOnEnter = (selectedTabId: string) => () => {
    // Save currently focused element
    lastActiveElement = document.activeElement;
    // Set focus to selected Ribbon tab
    document.getElementById(selectedTabId)?.focus();
};

const manageFocusOnExit: IKeytipLayerProps['onExitKeytipMode'] = event => {
    if (!(event?.target instanceof HTMLElement)) {
        return;
    }

    const { role } = event.target;

    const isTargetInMenu =
        role === 'gridcell' || role === 'menu' || role === 'menuitem' || role === 'menuitemradio';

    const isTabKey = event instanceof KeyboardEvent && event.key === 'Tab';

    if (
        // Avoid restoring last focus when current focus is in a dropdown menu
        !isTargetInMenu &&
        // Avoid restoring last focus when `Tab` is pressed
        !isTabKey
    ) {
        // Restore focus to element that had focus prior to entering keytip mode
        lastActiveElement instanceof HTMLElement && lastActiveElement.focus();
    }
};

export const RibbonKeytipLayer = observer(({ selectedTabId }: { selectedTabId: string }) => {
    const isKeytipParityEnabled = isFeatureEnabled('mon-ribbon-win32-keytip-parity');

    useValueInitializedOnce(() => {
        // Delay registration of keytips until user is in keytip mode
        KeytipManager.getInstance().init(true);
    });

    if (isKeytipParityEnabled) {
        useCustomKeytipEvents(isHostAppFeatureEnabled('altForKeytips'));
    }

    return (
        <KeytipLayer
            content={isKeytipParityEnabled ? getContentParity() : getContent()}
            keytipExitSequences={isKeytipParityEnabled ? undefined : getKeytipSequences()}
            keytipStartSequences={isKeytipParityEnabled ? undefined : getKeytipSequences()}
            onEnterKeytipMode={manageFocusOnEnter(selectedTabId)}
            onExitKeytipMode={manageFocusOnExit}
        />
    );
}, 'RibbonKeytipLayer');
