import { M365AppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import type { FirstPartyAppId, LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { getAccountKind, Account } from '../utils/getAccountKind';
import { getAppBarPinnedAppIdsFromUserSettings } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import { readM365ApplicationsFromCache } from '../utils/readM365ApplicationsFromCache';
import { isAppBarPinnedAppsEnabled } from 'owa-m365-acquisitions/lib/pinnedApps/isAppBarPinnedAppsEnabled';

import type { AppBarPinnedAppsValue } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import type { MailboxInfo } from 'owa-client-types';

export const AppBarTilesIndex: {
    [key in Account]: (LaunchPageAppId | FirstPartyAppId)[];
} = {
    [Account.Business]: [
        M365AppId.Mail,
        M365AppId.Calendar,
        M365AppId.People,
        M365AppId.Groups,
        M365AppId.Files,
        M365AppId.ToDo,
        M365AppId.ToDoMetaOs,
        M365AppId.OrgExplorer,
        M365AppId.Newsletters,
        M365AppId.EventsSdf,
        M365AppId.EventsProd,
        M365AppId.MicrosoftPlaces,
        M365AppId.Yammer,
        M365AppId.Bookings,
        M365AppId.OneDrive,
        M365AppId.OneDriveMetaOs,
        M365AppId.Hoop,
    ],
    [Account.Consumer]: [
        M365AppId.Mail,
        M365AppId.Calendar,
        M365AppId.People,
        M365AppId.Files,
        M365AppId.ToDo,
        M365AppId.ToDoMetaOs,
        M365AppId.EventsSdf,
        M365AppId.EventsProd,
        M365AppId.Word,
        M365AppId.Excel,
        M365AppId.PowerPoint,
        M365AppId.OneDrive,
        M365AppId.OneDriveMetaOs,
    ],
    [Account.Edu]: [
        M365AppId.Mail,
        M365AppId.Calendar,
        M365AppId.People,
        M365AppId.Files,
        M365AppId.ToDo,
        M365AppId.ToDoMetaOs,
        M365AppId.Word,
        M365AppId.Excel,
        M365AppId.PowerPoint,
        M365AppId.OneDrive,
        M365AppId.OneDriveMetaOs,
    ],
};

/**
 * Gets the indices of the locked and pinned apps from the cache, otherwise from user config (bootstrap scenario)
 *
 * @returns { lockedApps: string[], pinnedApps: string[] }
 */

export function getAppBarTilesIndices(
    mailboxInfo?: MailboxInfo,
    isAccountSwitchWrite?: boolean
): AppBarPinnedAppsValue {
    const accountKind = getAccountKind();
    const fallbackValue = { lockedApps: AppBarTilesIndex[accountKind], pinnedApps: [] };

    if (isAppBarPinnedAppsEnabled(mailboxInfo)) {
        const m365Apps = readM365ApplicationsFromCache();

        // If cache is empty, it's the bootstrap scenario and we want to read from user config
        // If we are fetching pinned apps for a new account after an account switch, we cannot rely on the current value of M365Application[] in the nova cache,
        // since it's for the account we're switching away from
        if (!m365Apps || isAccountSwitchWrite) {
            return getAppBarPinnedAppIdsFromUserSettings(mailboxInfo) ?? fallbackValue;
        }
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const lockedApps = m365Apps.filter(app => app.lockedIndex! > -1);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion.
         *	> Forbidden non-null assertion. */
        lockedApps.sort((a, b) => a.lockedIndex! - b.lockedIndex!);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const pinnedApps = m365Apps.filter(app => app.pinnedIndex! > -1);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion.
         *	> Forbidden non-null assertion. */
        pinnedApps.sort((a, b) => a.pinnedIndex! - b.pinnedIndex!);
        return {
            lockedApps: lockedApps.map(app => app.id) as string[],
            pinnedApps: pinnedApps.map(app => app.id) as string[],
        };
    }
    return fallbackValue;
}
