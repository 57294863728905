import { action } from 'satcheljs';

const onOpenMetaOsAppFromComposeAppStore = action(
    'onOpenMetaOsAppFromComposeAppStore',
    ({ appId }: { appId: string }) => ({
        appId,
    })
);

export default onOpenMetaOsAppFromComposeAppStore;
