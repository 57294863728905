import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ItemMarkAsJunkMutationWeb"*/ './lazyIndex')
);

export const lazyMarkItemAsJunkWeb = createLazyResolver(
    'MUTATION_MARK_ITEM_AS_JUNK_WEB',
    lazyModule,
    m => m.markItemAsJunkWeb
);

export const lazyUndoMarkItemAsJunkWeb = createLazyResolver(
    'MUTATION_UNDO_MARK_ITEM_AS_JUNK_WEB',
    lazyModule,
    m => m.undoMarkItemAsJunkWeb
);
