import { getItemClientId } from 'owa-mail-list-table-operations/lib/actions/store-factory/mapItemTypeToItemRelation';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import type Message from 'owa-service/lib/contract/Message';
import type { TableView } from 'owa-mail-list-store';

export default function getExpandedItemId(tableView: TableView, nodeIds: string[]) {
    const rowKey = [...tableView.selectedRowKeys.keys()][0];
    const expandedFirstLevel = isFirstLevelExpanded(rowKey);
    const item: Message = getItemForMailList(nodeIds[0], expandedFirstLevel);
    const clientItemId = getItemClientId(item, tableView);
    return clientItemId;
}
