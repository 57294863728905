import type { CustomCheckpointNetwork, PerformanceDatapointType } from 'owa-analytics-types';
import type { BaseRequestOptions } from 'owa-analytics-types/lib/types/BaseRequestOptions';
import type { MailboxInfo } from 'owa-client-types';

export enum CustomBaseUrl {
    PublishedCalendar = '/owa/published',
}

export type IdType = 'ImmutableId' | 'EntryId';

// We can not use for..of or any other iterator for headers
// as it is not supported on some older version of Edge e.g. 15.15063, 14.14393.
// We also can't use forEach to iterate over headers as it is also not supported
// in all browsers e.g. Firefox. Hence we create this type without iterators.
export type HeadersWithoutIterator = Omit<Headers, 'entries' | 'keys' | 'values' | 'forEach'>;

export type OnBeforeRetry = (r: Response) => Promise<RetryOptions | undefined>;
export type RetryOptions = {
    endpoint?: string;
};
export type ShouldRetry = (status: number) => Promise<boolean>;

export interface RequestOptions extends BaseRequestOptions {
    headers?: HeadersWithoutIterator;
    method?: string;
    returnFullResponseOnSuccess?: boolean;
    isUserActivity?: boolean;
    noEmptyPost?: boolean;
    authNeededOnUnAuthorized?: boolean;
    customBaseUrl?: CustomBaseUrl;
    customBaseUrlSubPath?: string;
    customIdType?: IdType;
    timeoutMS?: number;
    endpoint?: string;
    shouldRetry?: ShouldRetry;
    onBeforeRetry?: OnBeforeRetry;
    retryCount?: number;
    returnResponseHeaders?: boolean;
    mailboxInfo?: MailboxInfo; // The MailboxInfo of the account for which the request is being made. Will be set by web resolvers.
    perfDatapoint?: NetworkPerformanceDatapoint;
    isAnonymousAllowed?: boolean;
    scenarioId?: string;
    skipMailboxSpecificRequestOptions?: boolean; // true if the header mailbox specific header processing should be skipped
    retryAuthForExplicitLogon?: boolean; // true if the request should be retried for explicit logon
}

interface NetworkPerformanceDatapoint {
    datapoint?: PerformanceDatapointType;
    customDatapoint?: CustomCheckpointNetwork;
    discardIfDefined?: boolean;
}

export interface InternalRequestOptions extends Omit<RequestOptions, 'headers'> {
    headers: Exclude<RequestOptions['headers'], undefined>; // headers are not optional
    credentials?: RequestCredentials;
    body?: string;
}

export default RequestOptions;
