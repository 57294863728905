import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyRespondToCalendarEventMutationWeb = createLazyResolver(
    'RESPONDTOMEETINGREQUEST_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "RespondToCalendarEventMutationWeb" */ './respondToCalendarEventMutationWeb'
        ),
    m => m.respondToCalendarEventMutationWeb
);
