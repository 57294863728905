import React from 'react';
import type { AppRibbonProps } from 'owa-app-ribbon';
import { getMailCommandRibbonProps } from './getMailCommandRibbonProps';
import categoryStore from 'owa-categories/lib/store/store';
import moveToStore from 'owa-mail-moveto-control/lib/store/store';
import { owaComputedFn } from 'owa-computed-fn';
import { MailAttachmentPickers } from 'owa-attachment-pickers';
import { RibbonCustomizer } from 'owa-mail-ribbon-customizer';

export const mailAppRibbonProps: AppRibbonProps = {
    getRibbonProps: getMailCommandRibbonProps,
    renderExtraElements: owaComputedFn((attachmentInlinePickers, attachmentMultiPickers) => (
        <MailAttachmentPickers
            attachmentInlinePickers={attachmentInlinePickers}
            attachmentMultiPicker={attachmentMultiPickers}
        >
            <RibbonCustomizer />
        </MailAttachmentPickers>
    )),
    ribbonPropsDependency1: categoryStore.categoryMenuViewState.findText,
    ribbonPropsDependency2: moveToStore.findText,
};
