export const moveToFolderNewFolderText = "L6UQZb";
export const moveToArchiveMbxFolderNewFolderText = "eQJfhb";
export const moveToFolderNewGroupFolderText = "iYbqT";
export const searchFolder = "EKZBMe";
export const searchGroupFolder = "Rhq1b";
export const searchArchiveFolder = "A1pxm";
export const moveToSearchSharedFolder = "zvTmk";
export const moveToFolderCreateNewText = "znpvLb";
export const moveToArchiveMbxFolderCreateNewText = "U8dW4";
export const rootFolderString = "RXXTVb";
export default {moveToFolderNewFolderText,moveToArchiveMbxFolderNewFolderText,moveToFolderNewGroupFolderText,searchFolder,searchGroupFolder,searchArchiveFolder,moveToSearchSharedFolder,moveToFolderCreateNewText,moveToArchiveMbxFolderCreateNewText,rootFolderString};