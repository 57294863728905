export { NewOutlookPromoDialog } from './components/NewOutlookPromoDialog';
export { OperatingSystemName, default as useOperatingSystemName } from './useOperatingSystemName';
export { PromoDialogEntrypoint } from './utils/types';
import { createLazyComponent } from 'owa-bundling';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyModule } from './lazyFunctions';

export const PremiumNewOutlookPromoDialog = createLazyComponent(
    lazyModule,
    m => m.PremiumNewOutlookPromoDialog,
    undefined,
    undefined,
    {
        onlyImportIf: () => isFeatureEnabled('acct-newOutlookPromoPremiumConsumers'),
    }
);
