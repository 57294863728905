import { LazyAction, LazyImport } from 'owa-bundling';
import { LINK_CHICLET_OPEN_FAILURE } from './constants/safelinksDatapoints';
import { lazyModule } from './lazyFunctions';

export const lazyCreateUrlDetectionContentHandler = new LazyImport(
    lazyModule,
    m => m.createUrlDetectionContentHandler
);
export const lazyRefreshSafelinksPolicy = new LazyImport(lazyModule, m => m.refreshSafelinksPolicy);
export const lazyHandleLinkClick = new LazyImport(lazyModule, m => m.handleLinkClick);
export const lazyGetSafelinksPolicyRefreshStatus = new LazyImport(
    lazyModule,
    m => m.getSafelinksPolicyRefreshStatus
);
export const addOnClickHandler = new LazyAction(lazyModule, m => m.addOnClickHandler);

export const lazyOpenJunkLinkVerification = new LazyImport(
    lazyModule,
    m => m.openJunkLinkVerification
);

export { SafelinksPolicyRefreshStatus } from './schema/SafelinksPolicyRefreshStatus';
/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { ClickSource } from 'owa-safelinks-evaluator/lib/utils/SourceEnums';
export const SafelinksDatapoints = {
    LINK_CHICLET_OPEN_FAILURE,
};
export { SAFELINKS_CLICK_HANDLER, SafelinksElement } from './schema/constants';
