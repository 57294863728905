import type { MailFolder, Maybe } from 'owa-graph-schema';

export const updateFoldersChildIds = (
    folders: Maybe<MailFolder>[],
    rootFolder: MailFolder | null | undefined
) => {
    const foldersMap: {
        [id: string]: MailFolder;
    } = {};

    // Add root folder to list if it's provided
    if (rootFolder) {
        foldersMap[rootFolder.id] = rootFolder;
    }

    // Get all folder ids and add them to the map for lookup in the next section
    const folderIds: string[] = [];
    for (let i = 0; i < folders.length; i++) {
        const rawFolder = folders[i];
        if (rawFolder) {
            const folderId = rawFolder.id;
            foldersMap[folderId] = rawFolder;
            folderIds.push(folderId);
        }
    }

    // Add children to their parent's child id list
    folderIds.forEach(folderId => {
        const childFolder = foldersMap[folderId];
        const parent = foldersMap[childFolder.parentFolderId];
        // If the parent came in the response, add to that childFolderIds list
        if (parent) {
            if (!parent.childFolderIds) {
                parent.childFolderIds = [];
            }
            if (parent.childFolderIds && parent.childFolderIds.indexOf(folderId) == -1) {
                parent.childFolderIds.push(folderId);
            }
        }
    });
};
