import { userDate } from 'owa-datetime';
import type TimeGroupHeader from '../type/TimeGroupHeader';
import { getTimeGroupHeader } from '../utils/timeGroupHeaderGenerator';
import { NudgedGroupHeaderId, NoGroupHeaderId, PinnedGroupHeaderId } from '../type/GroupHeaderId';
import { noneGroupHeader } from '../utils/noneGroupHeader.locstring.json';
import { shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { doesRowBelongToNudgeSection } from 'owa-mail-nudge-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { pinned } from '../strings.locstring.json';
import loc from 'owa-localize';
import { logUsage } from 'owa-analytics';

/**
 * Get TimeGroupHeader for item
 * @param rowKey item key for which we want to lookup time group range
 * @param tableView tableView
 * @return group header for this row
 */
export default function getGroupHeaderForSortByDateTime(
    rowKey: string,
    tableView: TableView
): TimeGroupHeader {
    /**
     * Nudged rows do not stay in date time sort order
     * return now instead of looking up the time header
     */
    const isTableSortedByRenewTime = shouldTableSortByRenewTime(tableView.tableQuery);
    if (
        doesRowBelongToNudgeSection(
            rowKey,
            tableView.id,
            MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(rowKey, tableView)
        )
    ) {
        return {
            headerText: () => '',
            headerId: NudgedGroupHeaderId.Nudged,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (47,13): Type 'null' is not assignable to type 'OwaDate'.
            // @ts-expect-error
            rangeStartTime: null,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (51,13): Type 'null' is not assignable to type 'OwaDate'.
            // @ts-expect-error
            rangeEndTime: null,
        };
    } else if (
        MailRowDataPropertyGetter.getIsPinned(rowKey, tableView) &&
        isTableSortedByRenewTime
    ) {
        // We want to make sure that we only show the pinned header
        return {
            headerText: () => loc(pinned),
            headerId: PinnedGroupHeaderId.Pinned,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (64,13): Type 'null' is not assignable to type 'OwaDate'.
            // @ts-expect-error
            rangeStartTime: null,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (68,13): Type 'null' is not assignable to type 'OwaDate'.
            // @ts-expect-error
            rangeEndTime: null,
        };
    } else {
        let propertyGetter: (rowKey: string, tableView: TableView) => string;
        if (isTableSortedByRenewTime) {
            propertyGetter = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp;
        } else if (
            tableView?.tableQuery?.folderId &&
            folderIdToName(tableView.tableQuery.folderId) === 'drafts'
        ) {
            propertyGetter = MailRowDataPropertyGetter.getLastModifiedTimeStamp;
        } else {
            propertyGetter = MailRowDataPropertyGetter.getLastDeliveryTimeStamp;
        }

        try {
            const timeStamp = propertyGetter(rowKey, tableView);
            const date = userDate(timeStamp);

            return getTimeGroupHeader(date);
        } catch (e) {
            const logSystemFolder = tableView.tableQuery.folderId
                ? folderIdToName(tableView.tableQuery.folderId)
                : null;
            logUsage('TnS_DateTimeNotFound', [logSystemFolder]);

            return {
                headerText: () => loc(noneGroupHeader),
                headerId: NoGroupHeaderId.None,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (97,17): Type 'null' is not assignable to type 'OwaDate'.
                // @ts-expect-error
                rangeStartTime: null,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (101,17): Type 'null' is not assignable to type 'OwaDate'.
                // @ts-expect-error
                rangeEndTime: null,
            };
        }
    }
}
