import type { ClientItemId } from 'owa-client-ids';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type ReadingPanePopoutItemFolderInfo from '../schema/ReadingPanePopoutItemFolderInfo';
import { popoutMailReadingPane } from 'owa-popout';

export function openTableRowInPopout(
    listViewType: ReactListViewType,
    rowId: ClientItemId,
    readingPaneDataFunction: () => Promise<ReadingPanePopoutItemFolderInfo | undefined>
) {
    if (rowId) {
        popoutMailReadingPane(
            rowId.Id,
            listViewType == 0 ? rowId.Id : undefined,
            undefined /*internetMessageId*/,
            rowId.mailboxInfo,
            readingPaneDataFunction
        );
    }
}
