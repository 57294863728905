import { SwipeStage } from 'touch-behavior';
import { logUsage, PerformanceDatapoint } from 'owa-analytics';
import { getSwipeFromLeftAction, getSwipeFromRightAction } from 'owa-mail-list-swipe-utils';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import type { MailListItemPartProps, MailListItemWrapperProps } from 'owa-mail-list-item-shared';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export const skipHandleTouchCallback = (moveX?: number): boolean => {
    if (moveX === undefined) {
        return true;
    }
    if (moveX === 0) {
        return false;
    }
    const isLeftSwipe = moveX > 0;
    const swipeAction = isLeftSwipe ? getSwipeFromLeftAction() : getSwipeFromRightAction();

    return swipeAction === 0;
};

export const swipePerfMeasurer = (() => {
    let perfDataPoint: PerformanceDatapoint | null = null;

    function startDatapoint() {
        // invalidate already running datapoint
        invalidateDatapoint();
        perfDataPoint = new PerformanceDatapoint('MailListItemSwipePerf');
    }

    function endDatapoint() {
        perfDataPoint && perfDataPoint.end();
    }

    function invalidateDatapoint() {
        perfDataPoint && perfDataPoint.invalidate();
    }

    return (_props: MailListItemWrapperProps | MailListItemPartProps, stage: SwipeStage) => {
        switch (stage) {
            case SwipeStage.Started:
                startDatapoint();
                break;
            case SwipeStage.Cancelled:
                invalidateDatapoint();
                break;
            case SwipeStage.Completed:
                endDatapoint();
                break;
            default:
                break;
        }
    };
})();

export const logSwipeAction = (
    isSwipeFromLeft: boolean,
    swipeAction: MailSwipeAction,
    rowKeyOrNodeId: string,
    tableViewId: string,
    isItemPart: boolean
) => {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const folderId = tableView?.tableQuery?.folderId;
    const folderName = folderId ? folderIdToName(folderId) : 'none';

    logUsage('SwipeMailListItem', {
        isSwipeFromLeft,
        swipeAction,
        tableType: tableView?.tableQuery?.type,
        listViewType: tableView?.tableQuery?.listViewType,
        folderName,
        rowKeyOrNodeId,
        isItemPart,
    });
};
