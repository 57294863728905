import type {
    MutationDeleteCalendarArgs,
    ResolverContext,
    CalendarEntry,
    DeleteCalendarMutation_CalendarEntry,
    CalendarsUnionType,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';
import deleteCalendarOperation from 'owa-service/lib/operation/deleteCalendarOperation';
import type CalendarActionResponse from 'owa-service/lib/contract/CalendarActionResponse';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import itemId from 'owa-service/lib/factory/itemId';

/**
 * Mutation Web resolver responsible for deleteCalendar
 */
export const deleteCalendarMutationWeb = async (
    _parent: any,
    args: MutationDeleteCalendarArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarEntry> => {
    const { calendarId, calendarTypename } = args.input;
    const changeKey: string | undefined =
        calendarId.changeKey === null ? undefined : calendarId.changeKey;

    const response: CalendarActionResponse = await deleteCalendarOperation(
        {
            itemId: itemId({ Id: calendarId.id, ChangeKey: changeKey }),
        },
        getMailboxRequestOptions(calendarId.mailboxInfo)
    );

    if (response == null || !response.WasSuccessful) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `deleteCalendarMutationWeb: failed to delete calendar. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }

    const partialCalendarEntry: DeleteCalendarMutation_CalendarEntry = {
        __typename: calendarTypename as CalendarsUnionType['__typename'],
        calendarId,
    };

    return partialCast<CalendarEntry>(partialCalendarEntry);
};

export default deleteCalendarMutationWeb;
