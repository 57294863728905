import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MeetingOptionsResolvers" */ './lazyIndex')
);

export const lazyMeetingOptionsQueryWeb = createLazyResolver(
    'MEETING_OPTIONS_QUERY_WEB',
    lazyModule,
    m => m.getMeetingOptionsQueryWeb
);

export const lazyMeetingOptionsMutationWeb = createLazyResolver(
    'MEETING_OPTIONS_MUTATION_WEB',
    lazyModule,
    m => m.setMeetingOptionsMutationWeb
);

export const lazyMeetingOptionTypeResolver = createLazyResolver(
    'MEETING_OPTIONS_MO_TYPE_RESOLVER',
    lazyModule,
    m => m.resolveMeetingOptionType
);

export const lazySensitivityLabelActionTypeResolver = createLazyResolver(
    'MEETING_OPTIONS_SL_ACTION_TYPE_RESOLVER',
    lazyModule,
    m => m.resolveSensitivityLabelActionType
);
