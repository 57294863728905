import { addClass, removeClass } from 'owa-griffel-utils';
import type { TableView } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import { lazyLogSearchActions } from 'owa-search-instrumentation';
import { SubstrateSearchScenario } from 'owa-search-service';
import { getScenarioStore, SearchScenarioId } from 'owa-search-store';
import mailSearchStore from '../store/store';
import { answerHidden } from '../components/AnswerContainer.scss';

import setAnswerCardHidden from '../mutators/setAnswerCardHidden';

export function onMailListScroll(tableView: TableView, scrollingRegion: HTMLDivElement) {
    // check table is a search table
    if (tableView.tableQuery.type === 1 && mailSearchStore.isAnswerAvailable) {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * This is the best way to determine the scroll of an element. We try to limit the impact on perf by only running it within this specific scenario.
         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
        if (mailSearchStore.isAnswerCardHidden && scrollingRegion.scrollTop === 0) {
            // show answer card
            const topAnswerCard = document.getElementById('SearchAnswerTop');
            if (topAnswerCard) {
                removeClass(topAnswerCard, answerHidden);
            }
            setAnswerCardHidden(false);
        } else {
            if (!mailSearchStore.serpHasScrolled) {
                mailSearchStore.serpHasScrolled = true;
                const searchStore = getScenarioStore(SearchScenarioId.Mail);
                lazyLogSearchActions.importAndExecute(
                    SubstrateSearchScenario.Mail,
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2345 (23,17): Argument of type 'null' is not assignable to parameter of type 'string'.
                    // @ts-expect-error
                    null /* userId */,
                    null /* tenantId */,
                    searchStore.logicalId,
                    null /* traceId */,
                    'ScrollBarMoved',
                    new Map<string, string>([['ScrollbarMovementType', 'Down']])
                );
            } else if (!mailSearchStore.isAnswerCardHidden) {
                // hide answer card
                const topAnswerCard = document.getElementById('SearchAnswerTop');
                if (topAnswerCard) {
                    addClass(topAnswerCard, answerHidden);
                }
                setAnswerCardHidden(true);
            }
        }
    }
}
