import { getSelectedTableView, getListViewState, isItemPartOperation } from 'owa-mail-list-store';
import { resetFocus } from 'owa-mail-focus-manager';
import type { ActionSource } from 'owa-analytics-types';
import {
    lazyMarkItemsJunkNotJunkBasedOnNodeIds,
    lazyMarkRowsJunkNotJunk,
    lazyReportItems,
} from 'owa-mail-triage-action';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import { ReportType } from 'owa-reporting';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function onJunkNotJunk(actionSource: ActionSource, isJunkValueToSet: boolean) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    //Mark as read when junk from menu
    if (tableView.selectedRowKeys.size === 1) {
        const selectedRowKey = [...tableView.selectedRowKeys.keys()][0];
        lazyOnSingleSelectionChanged.importAndExecute(selectedRowKey, tableView, true);
    }

    if (isFeatureEnabled('tri-reportUnsubscribeBlock')) {
        lazyReportItems.importAndExecute(
            isJunkValueToSet ? ReportType.Junk : ReportType.NotJunk,
            rowKeys,
            tableView,
            actionSource
        );
    } else {
        if (isItemPartOperation()) {
            const markItemsJunkNotJunkBasedOnNodeIds =
                await lazyMarkItemsJunkNotJunkBasedOnNodeIds.import();

            markItemsJunkNotJunkBasedOnNodeIds(
                getListViewState().expandedConversationViewState.selectedNodeIds,
                tableView.id,
                isJunkValueToSet,
                actionSource
            );
        } else {
            lazyMarkRowsJunkNotJunk.importAndExecute(
                rowKeys,
                tableView,
                isJunkValueToSet,
                actionSource
            );
        }
        resetFocus('junkCommand');
    }
}
