import type { ClientItemId } from 'owa-client-ids';
import type { ItemRow } from 'owa-graph-schema';
import type { TableView } from 'owa-mail-list-store';
import type Message from 'owa-service/lib/contract/Message';
import { type TableQueryType } from 'owa-mail-list-store';
import type TableViewItemRelation from 'owa-mail-list-store/lib/store/schema/TableViewItemRelation';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import getItemClientIdForSearchResult from 'owa-mail-search/lib/utils/getItemClientIdForSearchResult';

export function getItemClientId(
    item: ItemRow | Message,
    tableView: TableView | undefined
): ClientItemId {
    if (tableView?.tableQuery?.type == 1) {
        return getItemClientIdForSearchResult(item as ItemRow, tableView);
    }

    return {
        Id: item.ItemId?.Id ?? '',
        mailboxInfo: getMailboxInfo(tableView),
    };
}

export default function mapItemTypeToItemRelation(
    item: ItemRow,
    tableView: TableView
): TableViewItemRelation {
    return {
        executeSearchSortOrder: item.SortOrderSource ?? 'DateTime',
        clientId: getItemClientId(item, tableView),
        instanceKey: item.InstanceKey ?? '',
        instrumentationContext: null,
    };
}
