import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { onOpenPersonSources, onPersonSourcesInternal } from 'owa-nova-actions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaNovaPersonSources" */ './lazyIndex')
);

registerLazyOrchestrator(onOpenPersonSources, lazyModule, m => m.openPersonSources);
registerLazyOrchestrator(onPersonSourcesInternal, lazyModule, m => m.personSourcesInternal);

export const PersonSourcesAsync = createLazyComponent(lazyModule, m => m.PersonSourcesWrapper);
export { isPersonSourcesEnabled } from './utils/isPersonSourcesEnabled';
export type { PersonaIdentifiers } from './store/schema/PersonSourcesState';
