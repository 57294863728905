export enum AppContextMenuItemKeys {
    SaveImageAs = 'saveImageAs',
    CopyImage = 'copyImage',
    CopyImageLink = 'copyImageLink',
    OpenLink = 'openLink',
    CopyLink = 'copyLink',
    CopyTextLink = 'copyTextLink',
    Copy = 'copy',
    Paste = 'paste',
    Cut = 'cut',
}
