import type { Reference } from '@apollo/client';
import type { M365Acquisition } from 'owa-graph-schema';
import { getClientTitleId } from 'owa-m365-acquisitions/lib/utils/getClientTitleId';

/**
 * Merges two arrays of references removing duplicate entries.
 * We only add values from `incoming` if they don't exist on `existing`.
 *
 * ```js
 * >>> existing = [ {__ref: 'A'}, {__ref: 'B'}]
 * >>> incoming = [ {__ref: 'C'}, {__ref: 'B'}]
 * >>> mergeArraysDedupe(existing, incoming)
 * [ {__ref: 'A'}, {__ref: 'B'}, {__ref: 'C'} ]
 * ```
 *
 * @param  {Reference[]} incoming
 * @param  {Reference[]} existing
 */
export function mergeArraysDedupe(existing: M365Acquisition[], incoming: M365Acquisition[]) {
    const uniqueIds = new Set();
    const uniqueAcquisitions: M365Acquisition[] = [];
    const unionSet = [...incoming, ...existing];
    unionSet.forEach(element => {
        const clientId = getClientTitleId(element);
        if (!uniqueIds.has(clientId)) {
            uniqueIds.add(clientId);
            uniqueAcquisitions.push(element);
        }
    });
    return uniqueAcquisitions;
}

/**
 * Get a diff, using the left (in {A;B} sets) set as reference.
 * ```
 * >>> fullSet = [ {__ref: 'A'}, {__ref: 'B'}, {__ref: 'C'}]
 * >>> existing = [ {__ref: 'A'}, {__ref: 'B'}]
 * >>> getLeftSetDiff(fullSet, existing)
 * [ {__ref: 'C'} ]
 * ```
 *
 * @param  {Reference[]} fullSet
 * @param  {Reference[]} existing
 */
export function getLeftSetDiff(fullSet: Reference[], existing: Reference[]) {
    const diff: Reference[] = [];
    const existingRefs = new Set((existing ?? []).map(ref => ref.__ref));
    (fullSet ?? []).forEach(element => {
        const ref = element?.__ref;
        if (ref && !existingRefs.has(ref)) {
            diff.push(element);
        }
    });
    return diff;
}

/**
 * Maps a concrete cache object to its reference.
 */
export function mapConcreteToReference<
    T extends Record<string, unknown> = Record<string, unknown>,
    KeyFieldGetter extends (concreteType: T) => string = (concreteType: T) => string
>(concreteType: T | T[], getKeyField: KeyFieldGetter): Reference | Reference[] {
    const createRef = (t: T): Reference => ({
        __ref: getKeyField(t),
    });
    return Array.isArray(concreteType) ? concreteType.map(createRef) : createRef(concreteType);
}
