import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { isConnectedAccount } from 'owa-accounts-store';
import { DatapointStatus, VerboseDatapoint, logUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { createRetriableFunction } from 'owa-retriable-function';
import getAttachmentDownloadToken from 'owa-service/lib/operation/getAttachmentDownloadTokenOperation';
import type { TraceErrorObject } from 'owa-trace';
import { trace } from 'owa-trace';
import { getFilesUrlDataByUserIdentity, saveUrlData } from './filesUrlDataUtils';
import { getLoggableToken } from './getLoggableToken';
import { getConnectedAccountHeadersForUserIdentity } from './getConnectedAccountHeadersForUserIdentity';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { logAttachmentDownloadToken } from './logAttachmentDownloadToken';
import { isFeatureEnabled } from 'owa-feature-flags';
import getAttachmentDownloadTokenRequest from 'owa-service/lib/factory/getAttachmentDownloadTokenRequest';

const TIME_TO_WAIT_BEFORE_REFRESHING_TOKEN_IN_CASE_OF_ERROR_IN_MS = 1000 * 30; // 30 Seconds

// This will retry indefinitely and will wait 30 seconds between each retry
const createRetriableForRefreshingToken = createRetriableFunction({
    timeBetweenRetryInMS: () => TIME_TO_WAIT_BEFORE_REFRESHING_TOKEN_IN_CASE_OF_ERROR_IN_MS,
});

export async function refreshDownloadTokenAndRetryInCaseOfFailure(mailboxInfo: MailboxInfo) {
    const refreshDownloadToken = async (): Promise<string> => {
        let step = '0;';
        try {
            let requestOptions: RequestOptions = {};
            if (isMonarchMultipleAccountsEnabled()) {
                step += '1.1;';
                requestOptions = {
                    mailboxInfo,
                };
            } else if (isConnectedAccount(mailboxInfo.userIdentity)) {
                step += '1.2;';
                requestOptions = await getConnectedAccountHeadersForUserIdentity(
                    mailboxInfo.userIdentity
                );
            }

            step += '2';

            return getAttachmentDownloadToken(
                getAttachmentDownloadTokenRequest({
                    UseNewDownloadTokenLifetime: isFeatureEnabled(
                        'doc-use-new-download-token-lifetime'
                    ),
                }),
                getMailboxRequestOptions(mailboxInfo, requestOptions)
            );
        } catch (error) {
            trace.warn(`Unable to get the attachment download token ${error}`);
            logUsage('RefreshDownloadTokenError', {
                Step: step,
                Error: error,
            });
            (error as TraceErrorObject).networkError = true;
            throw error;
        }
    };

    const urlData = getFilesUrlDataByUserIdentity(mailboxInfo.userIdentity);

    if (!urlData?.isRefreshingDownloadToken) {
        saveUrlData(mailboxInfo.userIdentity, {
            isRefreshingDownloadToken: true,
        });

        let totalTries = 0;
        const { retriableFunc } = createRetriableForRefreshingToken(async () => {
            totalTries++;

            const token = await refreshDownloadToken();
            trace.info('update new attachment download token');
            saveUrlData(mailboxInfo.userIdentity, {
                downloadToken: token,
                isRefreshingDownloadToken: false,
            });
            logAttachmentDownloadToken(token, false /* isFirst */);
        });
        const performanceDatapoint = new VerboseDatapoint('AttachmentRefreshDownloadToken');

        try {
            await retriableFunc();
            const filesUrl = getFilesUrlDataByUserIdentity(mailboxInfo.userIdentity);
            const loggableToken = getLoggableToken(filesUrl?.downloadToken);
            performanceDatapoint.addCustomData({
                TotalTries: totalTries,
                Token: loggableToken,
            });
            performanceDatapoint.end();
        } catch (error) {
            trace.warn(`Retry to refresh attachment download token failed: ${error}`);
            performanceDatapoint.addCustomData({
                TotalTries: totalTries,
                Error: error.message,
            });
            performanceDatapoint.endWithError(DatapointStatus.ServerError, error);

            saveUrlData(mailboxInfo.userIdentity, {
                isRefreshingDownloadToken: false,
            });
        }
    }
}

export function startIntervalForRefreshDownloadToken(
    mailboxInfo: MailboxInfo,
    downloadTokenRefreshMinutes: number
): void {
    downloadTokenRefreshMinutes = downloadTokenRefreshMinutes < 1 ? 1 : downloadTokenRefreshMinutes;

    // Function startIntervalForRefreshDownloadToken will be called when account's Inbox folder
    // is selected by default, but the first background auto-refresh task will be launched after
    // downloadTokenRefreshMinutes (5 by default) minutes. The first download token of each account
    // is set by applicationSettings.FirstDownloadToken, which was already generated and saved in
    // userConfiguration, it could already be invalid/expired when it's using. So we should call
    // refresh token method immediately.
    if (isMonarchMultipleAccountsEnabled()) {
        refreshDownloadTokenAndRetryInCaseOfFailure(mailboxInfo);
    }

    if (isFeatureEnabled('doc-ensure-one-token-refresh-timer')) {
        const urlData = getFilesUrlDataByUserIdentity(mailboxInfo.userIdentity);
        if (urlData?.isTimerStarted) {
            return;
        }
    }

    if (isFeatureEnabled('doc-use-new-download-token-lifetime')) {
        // When a token's TTL is 10 minutes, a new token is refreshed every 5 minutes.
        // We are running an experiment to shorten the TTL to 5 minutes and would like to keep the refreshing
        // interval at 5 minutes as well. To have enough buffer for token refreshing latencies,
        // we will refresh the first token halfway through its lifetime and then follow the regular schedule.
        setTimeout(() => {
            refreshDownloadTokenAndRetryInCaseOfFailure(mailboxInfo);
            startRegularRefreshTimer(mailboxInfo, downloadTokenRefreshMinutes);
        }, 1000 * 60 * (downloadTokenRefreshMinutes / 2));
    } else {
        startRegularRefreshTimer(mailboxInfo, downloadTokenRefreshMinutes);
    }

    if (isFeatureEnabled('doc-ensure-one-token-refresh-timer')) {
        saveUrlData(mailboxInfo.userIdentity, {
            isTimerStarted: true,
        });
    }
}

function startRegularRefreshTimer(
    mailboxInfo: MailboxInfo,
    downloadTokenRefreshMinutes: number
): void {
    setInterval(() => {
        refreshDownloadTokenAndRetryInCaseOfFailure(mailboxInfo);
    }, 1000 * 60 * downloadTokenRefreshMinutes);
}
