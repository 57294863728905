import type { ResourceId } from './ResourceId';
import type { LocalizedString } from './LocalizedString';
import { getLocalizedStringStore } from './store/store';
import { isBootFeatureEnabled } from 'owa-metatags';

// Without fwk-loc-single, strings are stored in observable map.
// With fwk-loc-single, localized string are stored in this plain map and
// then use a single "version" field to track anyone that reads out of this map.
// This means we will re-render all components that call loc when this version
// changes (like a new string being added to the store), so we need to be judicious
// and update version only after all strings change (ex, language change, see setLocale).
const localizedStrings = new Map<ResourceId, LocalizedString>();

/**
 * Gets the set of localized strings, for a loc() operation.
 * This function ensures the calling component re-rerenders when language changes.
 * Without fwk-loc-single, re-render happens as each new set of strings is merged.
 * With fwk-loc-single, re-render happens when setLocale finishes merging all strings.
 */
export default function getLocalizedStrings(): ReadonlyMap<ResourceId, LocalizedString> {
    if (isBootFeatureEnabled('fwk-loc-single')) {
        getLocalizedStringStore().version;
        return localizedStrings;
    } else {
        return getLocalizedStringStore().localizedStringsV1;
    }
}

/**
 * Merge a new set of localized strings into the underlying storage.
 * Without fwk-loc-single, this causes individual observers to re-render, based on the strings they read.
 * With fwk-loc-single adding strings to the plain map causes no re-render, and
 * at that point we have two options:
 * - without fwk-loc-no-rerender-on-merge we're playing safe, by increasing the
 *   store's version, causing every reader of loc() to render, weather they care
 *   about the new set of strings or not. This can be a more expensive re-render.
 * - with fwk-loc-no-rerender-on-merge, we're working with the knowledge our webpack
 *   layer requires the strings to be loaded before the components that will use it
 *   will read them, so we don't really need to bump the version on each merge,
 *   and we only need a full re-render when user changes the language (see setLocale).
 */
export function mergeLocalizedStrings(strings: Record<ResourceId, LocalizedString>) {
    if (isBootFeatureEnabled('fwk-loc-single')) {
        Object.keys(strings).forEach(key =>
            localizedStrings.set(key as ResourceId, strings[key as ResourceId])
        );

        // We prefer a solution where this never needs to happen (see setLocale),
        // so we don't re-render existing components when we lazy load new strings.
        // By default we re-render, to play it safe, but if we can advance this
        // along with fwk-loc-single we will have the best of both worlds.
        if (!isBootFeatureEnabled('fwk-loc-no-rerender-on-merge')) {
            getLocalizedStringStore().version++;
        }
    } else {
        getLocalizedStringStore().localizedStringsV1.merge(strings);
    }
}
