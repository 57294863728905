import { getSelectedTableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import { lazyMarkRowsAsPhishingFromTable, lazyReportItems } from 'owa-mail-triage-action';
import { ReportType } from 'owa-reporting';
import { resetFocus } from 'owa-mail-focus-manager';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function onMarkAsPhishing(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    if (isFeatureEnabled('tri-reportUnsubscribeBlock')) {
        lazyReportItems.importAndExecute(ReportType.Phishing, rowKeys, tableView, actionSource);
    } else {
        lazyMarkRowsAsPhishingFromTable.importAndExecute(rowKeys, tableView, actionSource);
        resetFocus('markAsPhishingCommand');
    }
}
