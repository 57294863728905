import type { OneJs_PersonSourcesModel } from 'owa-graph-schema';
import loc from 'owa-localize';

import {
    managePersonSourcesHeading,
    managePersonSourcesHeadingMoreInfoLabel,
    managePersonSourcesSubHeading,
    personSourcesLearnMoreLabel,
    dismissLabel,
} from './personSourcesLabels.locstring.json';

export const personSourcesModel = async (): Promise<OneJs_PersonSourcesModel> => ({
    managePersonSourcesHeading: loc(managePersonSourcesHeading),
    managePersonSourcesHeadingMoreInfoLabel: loc(managePersonSourcesHeadingMoreInfoLabel),
    managePersonSourcesSubHeading: loc(managePersonSourcesSubHeading),
    personSourcesLearnMoreLabel: loc(personSourcesLearnMoreLabel),
    dismissLabel: loc(dismissLabel),
});
