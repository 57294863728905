import getPaletteAsRawColors from '../selectors/getPaletteAsRawColors';
import getFallbackPaletteAsRawColors from '../selectors/getFallbackPaletteAsRawColors';

export default function setCssVariablesOnDocument(doc: Document = document) {
    // Even if a browser doesn't support CSS variables, setting these values is benign
    const palette = getPaletteAsRawColors();
    const fallbackPalette = getFallbackPaletteAsRawColors();

    Object.keys(palette).forEach(key => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        doc.documentElement.style.setProperty(`--${key}`, palette[key]!);
    });

    Object.keys(fallbackPalette).forEach(key => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        doc.documentElement.style.setProperty(`--fallback-${key}`, fallbackPalette[key]!);
    });
}
