import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyOneGqlViewSchema = createLazyResolver(
    'ONEGQL_VIEW_SCHEMA',
    () => import(/* webpackChunkName: "OneGqlViewSchema" */ './oneGql_viewData'),
    m => m.oneGql_viewData
);

export { OneJS_PeopleAppViewData } from './resolvers/people-app';
export { OneJS_LivePersonaEditorViewData } from './resolvers/live-persona-editor';
export { OneJS_MeetingOptionsViewData } from './resolvers/meeting-options';
export { OneJS_PersonSourcesViewData } from './resolvers/person-sources';
