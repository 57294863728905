import {
    getCoprincipalAccountForIndexerValue,
    getMailboxInfoBySourceId,
} from 'owa-account-source-list-store';
import getSourceIdForUserIdentity from './getSourceIdForUserIdentity';
import type { MailboxInfo } from 'owa-client-types';

/**
 * REFLOW TECHNICAL DEBT
 *
 * In the Respond approach the sourceId should be passed into components. To support the Reflow approach the
 * client-web code continues to use the userIdentity to identify mailboxes. This helper method allows for
 * getting a sourceId from the source list based on that user identity.
 *
 * Long term, meaning when all code has moved to the Respond approach, this method will be removed.
 *
 * @param indexerOrUserIdentity identifies the mailbox, when not specified the selected mailbox is assumed
 * @returns MailboxInfo associated with the supplied user identity, or undefined if not source was found
 */
export default function getMailboxInfoForIndexerOrUserIdentity(
    indexerOrUserIdentity?: string | null
): MailboxInfo | undefined {
    let mailboxInfo = null;

    if (indexerOrUserIdentity) {
        mailboxInfo = getCoprincipalAccountForIndexerValue(
            indexerOrUserIdentity,
            false /*logIfNotFound*/
        )?.mailboxInfo;
    }

    if (!mailboxInfo) {
        mailboxInfo = getMailboxInfoBySourceId(getSourceIdForUserIdentity(indexerOrUserIdentity));
    }

    return mailboxInfo;
}
