import { getIndexerValueForIdentityInfo } from 'owa-auth-types';
import { mutator } from 'satcheljs';
import setAccountAuthState from '../actions/setAccountAuthState';
import getStore from '../store/Store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setAccountAuthState, props => {
    const states = getStore().accountAuthStates;
    states.set(getIndexerValueForIdentityInfo(props.identityInfo), props.authState);
});
