import { OcpsPolicy, isOcpsPolicyEnabled } from 'owa-ocps-policy-store';
import { isPrivacyRoamingEnabled } from '../utils/isPrivacyRoamingEnabled';

export const isOptionalExperiencesAllowedByPolicy = () =>
    isPrivacyRoamingEnabled()
        ? isOcpsPolicyEnabled(
              OcpsPolicy.OptionalConnectedExperiences,
              true /* enabledIfUndefined */
          )
        : true;
