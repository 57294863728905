import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupListTree" */ './lazyIndex'),
    { name: 'GroupListTree' }
);

// Delay loaded components
export const GroupListTree = createLazyComponent(lazyModule, m => m.GroupListTree);
export const GroupRightCharm = createLazyComponent(lazyModule, m => m.GroupRightCharm);
export const GroupRightCharmHover = createLazyComponent(lazyModule, m => m.GroupRightCharmHover);
export const GroupContextMenu = createLazyComponent(lazyModule, m => m.GroupContextMenu);
