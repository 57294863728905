// This is used to pass update properties in the OWS UpdateCalendarEvent.
import type SetItemField from 'owa-service/lib/contract/SetItemField';
import type DeleteItemField from 'owa-service/lib/contract/DeleteItemField';
import type { ProviderPropertiesEntry } from 'owa-graph-schema';
import {
    SchedulingServiceMeetingOptionsUrlExtendedProperty,
    SchedulingServiceUpdateUrlExtendedProperty,
} from '../types/OnlineMeetingServiceExtendedProperty';
import type ExtendedPropertyType from 'owa-service/lib/contract/ExtendedPropertyType';
import setItemField from 'owa-service/lib/factory/setItemField';
import extendedPropertyType from 'owa-service/lib/factory/extendedPropertyType';
import message from 'owa-service/lib/factory/message';

const getUpdatesProperties = (
    providerProperties: ProviderPropertiesEntry[]
): (SetItemField | DeleteItemField)[] => {
    const updatedExtendedProperties: (SetItemField | DeleteItemField)[] = [];
    if (providerProperties?.length > 0) {
        const meetingOptionsLink = providerProperties.find(
            property => property?.key === 'links.options'
        );
        const meetingOptionsUrlExtendedProperty: ExtendedPropertyType = {
            ExtendedFieldURI: SchedulingServiceMeetingOptionsUrlExtendedProperty,
            Value: meetingOptionsLink?.value,
        };
        //Adding setItemField for Scheduling Service Meeting Options URL Extended Property
        if (
            meetingOptionsUrlExtendedProperty.ExtendedFieldURI &&
            meetingOptionsUrlExtendedProperty.Value
        ) {
            updatedExtendedProperties.push(
                setItemField({
                    Path: meetingOptionsUrlExtendedProperty.ExtendedFieldURI,
                    Item: message({
                        ExtendedProperty: [extendedPropertyType(meetingOptionsUrlExtendedProperty)],
                    }),
                })
            );
        }

        const updateUrlLink = providerProperties.find(property => property?.key === 'links.update');
        const updateUrlExtendedProperty: ExtendedPropertyType = {
            ExtendedFieldURI: SchedulingServiceUpdateUrlExtendedProperty,
            Value: updateUrlLink?.value,
        };
        //Adding setItemField for Scheduling Service Update Url Extended Property
        if (updateUrlExtendedProperty.ExtendedFieldURI && updateUrlExtendedProperty.Value) {
            updatedExtendedProperties.push(
                setItemField({
                    Path: updateUrlExtendedProperty.ExtendedFieldURI,
                    Item: message({
                        ExtendedProperty: [extendedPropertyType(updateUrlExtendedProperty)],
                    }),
                })
            );
        }
    }
    return updatedExtendedProperties;
};

export default getUpdatesProperties;
