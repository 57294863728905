import { isFeatureEnabled } from 'owa-feature-flags';
import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';
import { isResourceBlocked } from './utils/isResourceBlocked';
import { RESOURCE_BLOCK_LIST_ENTERPRISE } from './utils/constants';

/**
 * Checks if the token fetch from MSAL is enabled for the given resource url.
 **/
export function shouldMsalFetchTokenForEnterprise(resource?: string): boolean {
    if (
        !isMsalEnabledForBusiness() &&
        !isFeatureEnabled(
            'auth-msaljs-fetchResourceToken',
            undefined /*mailboxInfo*/,
            true /*dontThrowErrorIfNotInitialized*/
        )
    ) {
        return false;
    }

    return !isResourceBlocked(resource, RESOURCE_BLOCK_LIST_ENTERPRISE);
}
