import type { MailboxInfo } from 'owa-client-types';
import type { AccountSourceType } from 'owa-account-source-list-types';
import type IdentityInfo from './IdentityInfo';

/**
 * Type for the function that will get the source type for the specified mailboxInfo
 */
type GetSourceTypeForMailboxInfoFunction = (
    mailboxInfo: MailboxInfo
) => AccountSourceType | undefined;

/**
 * Stores the function that will get the source type for the specified mailboxInfo
 */
let getSourceTypeForMailboxInfoFunc: GetSourceTypeForMailboxInfoFunction | undefined = undefined;

/**
 * Sets the function that will get the source type for the specified mailboxInfo
 * @param func Specifies the function that will get the source type for the specified mailboxInfo
 */
export function setSourceTypeForMailboxInfoFunc(func: GetSourceTypeForMailboxInfoFunction) {
    getSourceTypeForMailboxInfoFunc = func;
}

/**
 * Attempts to get the IdentityInfo associated with the specified MailboxInfo
 * @param mailboxInfo Specifies the MailboxInfo for which the Identity info is to be obtained
 * @returns IdentityInfo or undefined
 */
export default function getIdentityInfoForMailboxInfo(
    mailboxInfo: MailboxInfo
): IdentityInfo | undefined {
    if (getSourceTypeForMailboxInfoFunc) {
        const sourceType = getSourceTypeForMailboxInfoFunc(mailboxInfo);
        if (sourceType) {
            return {
                providerType: sourceType,
                userPrincipalName: mailboxInfo.userIdentity,
            };
        }
    }

    return undefined;
}
