import type { Operation } from '@apollo/client';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { ResolverContext } from 'owa-graph-schema';

export function sanitizeVariables(operation: Operation) {
    const rv = operation.variables;

    if (rv) {
        const context = operation.getContext() as ResolverContext;

        if (context.sanitizeVariables) {
            operation.variables = cloneVariablesWithoutTypenames(operation.variables);
        } else if (isFeatureEnabled('fwk-graphql-worker-link')) {
            operation.variables = cloneVariables(operation.variables);
        }
    }

    return rv;
}

function cloneVariablesWithoutTypenames(vars: Record<string, any>): Record<string, any> {
    const clone = cloneVariables(vars);
    internalStripTypenames(clone);
    return clone;
}

function cloneVariables(vars: Record<string, any>): Record<string, any> {
    let rv = vars;

    if (vars) {
        // people are passing mobx object as variables.  mobx objects do not clone properly via the
        // structured cloning algorithm, but do serialize as json
        rv = JSON.parse(JSON.stringify(vars));

        // people are also passing Blob objects as variables.  Blob primitives are not permitted in the
        // graphql specification, but it works against local resolvers (for now) because the variables
        // are not serialized to the wire.  operations using Blobs won't work against remote (app gateway)
        // resolvers.  fortunately, Blob objects are properly cloned via the structured cloning algorithm,
        // so we can just copy them over and the worker will receive them.
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion.
         *	> Forbidden non-null assertion. */
        copyBlobs(vars!, rv!);
    }

    return rv;
}

function copyBlobs(original: Record<string, any>, copy: Record<string, any>) {
    original = original || {};
    copy = copy || {};

    Object.keys(original).forEach(k => {
        // note: we need to check the constructor name because, for example, in projections the instance
        // of test doesn't always work correctly.
        if (original[k] instanceof Blob || original[k]?.['constructor']?.['name'] === 'Blob') {
            copy[k] = original[k];
        } else if (typeof original[k] === 'object') {
            copyBlobs(original[k], copy[k]);
        }
    });
}

type TypeWithoutTypenames<T> = {
    [P in keyof T as Exclude<P, '__typename'>]: TypeWithoutTypenames<T>;
};

type TypeWithTypename = {
    __typename?: string;
};

/**
 * Do an inplace removal of all __typename fields.  Note, this method ASSUMES the object passed to it is a plain old
 * flat data object without cycles, shared objects, etc.  For example, this method should only be called on an object
 * after JSON.stringify/JSON.parse.
 *
 * @param v the value to operate on
 * @returns the value without __typenames
 */
function internalStripTypenames<T>(v: T): TypeWithoutTypenames<T> {
    const rv: any = v;

    if (v && typeof v == 'object') {
        if (hasTypeName(v)) {
            delete rv.__typename;
        }

        for (const [key, val] of Object.entries(rv)) {
            rv[key] = internalStripTypenames(val);
        }
    }

    return rv as TypeWithoutTypenames<T>;
}

function hasTypeName(o: any): o is TypeWithTypename {
    return o.hasOwnProperty('__typename');
}
