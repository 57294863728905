import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarServices" */ './lazyIndex')
);

export const lazyGetCalendarViewService = new LazyAction(lazyModule, m => m.getCalendarViewService);

export const lazyGetBirthdayCalendarViewService = new LazyAction(
    lazyModule,
    m => m.getBirthdayCalendarViewService
);

export const lazyGetEventByUIdRestService = new LazyAction(
    lazyModule,
    m => m.getEventByUIdRestService
);

export const lazyGetExpandCalendarEventService = new LazyAction(
    lazyModule,
    m => m.getExpandedCalendarEventService
);

export const lazyGetCalendarFoldersService = new LazyAction(
    lazyModule,
    m => m.getCalendarFoldersService
);

export const lazyLoadCalendarEvent = new LazyAction(lazyModule, m => m.loadCalendarEvent);

export const lazyImportCalendarEventService = new LazyAction(
    lazyModule,
    m => m.importCalendarEventService
);

export const lazyGetTeamsCalendarViewService = new LazyAction(
    lazyModule,
    m => m.getTeamsCalendarViewService
);

export const lazyGetBirthdayEventService = new LazyAction(
    lazyModule,
    m => m.getBirthdayEventService
);

export const lazyGetMeetingOptionsService = new LazyAction(lazyModule, m => m.getMeetingOptions);

export const lazyUpdateMeetingOptionsService = new LazyAction(
    lazyModule,
    m => m.updateMeetingOptions
);

export type { default as CreateCalendarUsingRestServiceRequest } from './schema/CreateCalendarUsingRestServiceRequest';
export type { ImportEventsFromIcsFileResult } from './services/importCalendarEventService';
export type { BirthdayEventServiceResponse } from './services/getBirthdayEventService';
export type { MeetingOption } from './schema/MeetingOptionsData';
