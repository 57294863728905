import type { PerformanceDatapoint } from 'owa-analytics';
import { getGuid } from 'owa-guid';
import SearchProvider from '../data/schema/SearchProvider';
import type SearchRequestInstrumentation from '../data/schema/SearchRequestInstrumentation';
import { makePostRequest } from 'owa-ows-gateway';
import { getConfig } from 'owa-service/lib/config';
import type ExecuteSearchJsonRequest from 'owa-service/lib/contract/ExecuteSearchJsonRequest';
import type ExecuteSearchJsonResponse from 'owa-service/lib/contract/ExecuteSearchJsonResponse';
import createFetchOptions from 'owa-service/lib/createFetchOptions';
import fetchWithRetry from 'owa-service/lib/fetchWithRetry';
import type { RequestOptions, InternalRequestOptions } from 'owa-service/lib/RequestOptions';
import * as trace from 'owa-trace';
import type SubstrateSearchScenario from '../data/schema/SubstrateSearchScenario';
import { getClientId, getClientVersion, getApp } from 'owa-config';

const SUBSTRATE_SEARCH_QUERY_URL: string = 'ows/beta/substratesearch/query';

const MAX_HTTP_HEADER_LENGTH = 2048;

type SearchFetchCallback = (
    actionName: string,
    pageNumber: number,
    jsonRequest: ExecuteSearchJsonRequest,
    searchQueryId: string,
    fetchOptions: RequestOptions,
    substrateSearchScenario: SubstrateSearchScenario
) => Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]>;

export default function makeSubstrateSearchRequest(
    jsonRequest: ExecuteSearchJsonRequest,
    pageNumber: number,
    searchQueryId: string,
    executeSearchDatapoint: PerformanceDatapoint | null,
    actionSource: string,
    substrateSearchScenario: SubstrateSearchScenario
): Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]> {
    return internalMakeSearchRequest(
        jsonRequest,
        pageNumber,
        searchQueryId,
        executeSearchDatapoint,
        makeOwsPrimeRequest,
        actionSource,
        substrateSearchScenario
    );
}

export function makeExecuteSearchRequest(
    jsonRequest: ExecuteSearchJsonRequest,
    pageNumber: number,
    searchQueryId: string,
    executeSearchDatapoint: PerformanceDatapoint | null,
    actionSource: string,
    substrateSearchScenario: SubstrateSearchScenario
): Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]> {
    return internalMakeSearchRequest(
        jsonRequest,
        pageNumber,
        searchQueryId,
        executeSearchDatapoint,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (64,9): Argument of type '(actionName: string, pageNumber: number, jsonRequest: ExecuteSearchJsonRequest, searchQueryId: string, fetchOptions: InternalRequestOptions, substrateSearchScenario: SubstrateSearchScenario) => Promise<...>' is not assignable to parameter of type 'SearchFetchCallback'.
        // @ts-expect-error
        makeOwsRequest,
        actionSource,
        substrateSearchScenario
    );
}

/**
 * Make search request
 * @param actionName - The action name
 * @param pageNumber - Page number for search results
 * @param searchQueryId - The search query id used to identify search for a specific query
 * @param executeSearchDatapoint - the E2E execute search datapoint
 * @returns The promise which resolves after the server response containing:
 * @returns [0] - The ExecuteSearchJsonResponse
 * @returns [1] - The SearchRequestInstrumentation object for this request
 * @returns [2] - The URL of the request
 */
function internalMakeSearchRequest(
    jsonRequest: ExecuteSearchJsonRequest,
    pageNumber: number,
    searchQueryId: string,
    executeSearchDatapoint: PerformanceDatapoint | null,
    fetch: SearchFetchCallback,
    actionSource: string,
    substrateSearchScenario: SubstrateSearchScenario
): Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]> {
    const actionName = 'ExecuteSearch';
    return createFetchOptions().then(fetchOptions => {
        const headers = fetchOptions.headers;

        if (actionSource) {
            headers.append('X-OWA-ActionSource', actionSource);
        }

        const fetchPromise: Promise<
            [ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]
        > = fetch(
            actionName,
            pageNumber,
            jsonRequest,
            searchQueryId,
            fetchOptions,
            substrateSearchScenario
        );

        // If a execute search E2E datapoint was specified, we want to add a checkmark to record the
        // pre-request JavaScript time, and request JS thread time
        let preRequestJSTime = 0;
        let requestJSThreadTime = 0;
        if (executeSearchDatapoint) {
            preRequestJSTime = executeSearchDatapoint.addCheckmark('ClientJSPreRequestTime');

            Promise.resolve().then(() => {
                requestJSThreadTime = executeSearchDatapoint.addCheckmark('ClientJSRequestThread');
            });
        }

        if (!fetchPromise) {
            return Promise.reject(new Error('Client-side issue prevented request from being sent'));
        }

        return fetchPromise.then(
            (result: [ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]) => {
                const jsonResponse = result[0];
                const searchInstrumentation = result[1];
                const url = result[2];

                const returnValue: [
                    ExecuteSearchJsonResponse,
                    SearchRequestInstrumentation,
                    string
                ] = [jsonResponse, searchInstrumentation, url];

                searchInstrumentation.ClientJSPreRequestTime = preRequestJSTime;
                searchInstrumentation.ClientJSRequestThreadTime = requestJSThreadTime;
                return returnValue;
            }
        );
    });
}

/**
 * Make search request using OWS
 * @param actionName - The action name
 * @param pageNumber - Page number for search results
 * @param jsonRequest - The JSON request to send
 * @param searchQueryId - The search query id used to identify search for a specific query
 * @param fetchOptions - RequestOptions to set on the header
 * @returns The promise which resolves after the server response containing:
 * [0] The ExecuteSearchJsonResponse
 * [1] SearchRequestInstrumentation for this request
 * [2] The URL of the request
 */
function makeOwsRequest(
    actionName: string,
    pageNumber: number,
    jsonRequest: ExecuteSearchJsonRequest,
    searchQueryId: string,
    fetchOptions: InternalRequestOptions,
    substrateSearchScenario: SubstrateSearchScenario
): Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]> {
    const serializedRequestBody = JSON.stringify(jsonRequest);
    const urlEncodedSerializedRequestBody = encodeURIComponent(serializedRequestBody);
    let shouldAddEmptyPostMarker = false;
    const headers = fetchOptions.headers;
    headers.append('Content-Type', 'application/json; charset=utf-8');

    fetchOptions.returnFullResponseOnSuccess = true;

    // Check whether to send the payload on the body or on the header
    if (urlEncodedSerializedRequestBody.length > MAX_HTTP_HEADER_LENGTH) {
        fetchOptions.body = serializedRequestBody;
    } else {
        shouldAddEmptyPostMarker = true;
        headers.append('X-OWA-UrlPostData', urlEncodedSerializedRequestBody);
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (182,9): Type 'null' is not assignable to type 'string | undefined'.
        // @ts-expect-error
        fetchOptions.body = null;
    }
    const requestUrl = getOwsRequestUrl(actionName, shouldAddEmptyPostMarker);

    const clientRequestStart = new Date();
    const fetchPromise = fetchWithRetry('ExecuteSearch', requestUrl, 1, fetchOptions);
    return fetchPromise.then(async response => {
        const responseBody = <ExecuteSearchJsonResponse>await response.json();
        const result: [ExecuteSearchJsonResponse, SearchRequestInstrumentation, string] = [
            responseBody,
            buildInstrumentation(
                response,
                jsonRequest.Body.Query,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2345 (198,17): Argument of type 'string | null' is not assignable to parameter of type 'string'.
                // @ts-expect-error
                response.status == 200
                    ? null
                    : responseBody
                    ? JSON.stringify(responseBody.Body)
                    : null,
                SearchProvider.ExecuteSearch,
                clientRequestStart.getTime(),
                new Date().getTime(),
                searchQueryId,
                <Headers>fetchOptions.headers,
                pageNumber,
                substrateSearchScenario
            ),
            response.url,
        ];
        return result;
    });
}

/**
 * Make search request using OWS prime
 * @param actionName - The action name
 * @param pageNumber - Page number for search results
 * @param jsonRequest - The JSON request to send
 * @param searchQueryId - The search query id used to identify search for a specific query
 * @param fetchOptions - RequestOptions to set on the header
 * @param substrateSearchScenario - Identifer for the scenario for 3S
 * @returns The promise which resolves after the server response containing:
 * [0] The ExecuteSearchJsonResponse
 * [1] SearchRequestInstrumentation for this request
 * [2] The URL of the request
 */
function makeOwsPrimeRequest(
    _actionName: string,
    pageNumber: number,
    jsonRequest: ExecuteSearchJsonRequest,
    searchQueryId: string,
    fetchOptions: RequestOptions,
    substrateSearchScenario: SubstrateSearchScenario
): Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]> {
    if (!searchQueryId) {
        trace.errorThatWillCauseAlert(
            'SearchQueryId must be specified when calling makeExecuteSearchRequest!'
        );
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (245,9): Type 'null' is not assignable to type 'Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]>'.
        // @ts-expect-error
        return null;
    }

    const headers = {};
    (<Headers>fetchOptions.headers).forEach((val, key) => {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (253,9): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        // @ts-expect-error
        headers[key] = val;
    });

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7053 (259,5): Element implicitly has an 'any' type because expression of type '"X-Search-Griffin-Version"' can't be used to index type '{}'.
    // @ts-expect-error
    headers['X-Search-Griffin-Version'] = 'GWSv2';

    const clientRequestStart = new Date();

    // our translation layer in OWS' expects the SessionId to be the QueryId
    jsonRequest.Body.SearchSessionId = searchQueryId;

    jsonRequest.Body.Scenario = substrateSearchScenario;

    /* eslint-disable owa-custom-rules/require-mailboxInfoInOwsRequest --
     * MailboxInfo must be providded to OWS calls, see https://aka.ms/multiaccountlinter
     *	> All OWS calls must pass in a MailboxInfo or OwsRequestOptions obtained via getOwsMailboxRequestOptions. */

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (271,5): Type 'Promise<any[] | null>' is not assignable to type 'Promise<[ExecuteSearchJsonResponse, SearchRequestInstrumentation, string]>'.
    // @ts-expect-error
    return makePostRequest(
        /* eslint-enable owa-custom-rules/require-mailboxInfoInOwsRequest */
        SUBSTRATE_SEARCH_QUERY_URL,
        jsonRequest,
        getGuid() /* correlationId (client-request-id) */,
        true /* returnFullResponse */,
        headers
    )
        .then(async response => {
            const responseBody = <ExecuteSearchJsonResponse>await response.json();
            return [
                responseBody,
                buildInstrumentation(
                    response,
                    jsonRequest.Body.Query,
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2345 (288,21): Argument of type 'string | null' is not assignable to parameter of type 'string'.
                    // @ts-expect-error
                    response.status == 200
                        ? null
                        : responseBody
                        ? JSON.stringify(responseBody.Body)
                        : null,
                    SearchProvider.Substrate,
                    clientRequestStart.getTime(),
                    new Date().getTime(),
                    searchQueryId,
                    <Headers>fetchOptions.headers,
                    pageNumber,
                    substrateSearchScenario
                ),
                response.url,
            ];
        })
        .catch(err => {
            trace.trace.warn(err);
            return null;
        });
}

/**
 * Build the instrumentation data for a specific search request
 * @param response - The Response object
 * @param jsonRequest - The JSON request
 * @param errorMessage - The Error message in the body (if any)
 * @param clientRequestStart - Start time for when client initiated the network request
 * @param clientRequestFinish - End time for when the client received network response
 * @param clientQueryId - The search query id
 * @param pageNumber - Page number for search results
 */
export const buildInstrumentation = (
    response: Response,
    query: string,
    errorMessage: string | undefined,
    provider: SearchProvider,
    clientRequestStart: number,
    clientRequestFinish: number,
    searchQueryId: string,
    clientRequestHeaders: Headers,
    pageNumber: number,
    substrateSearchScenario: SubstrateSearchScenario
): SearchRequestInstrumentation => {
    const responseHeader = response.headers || new Headers();

    const searchRequestInstrumentation: SearchRequestInstrumentation = {
        ClientSearchProvider: provider,
        PageNumber: pageNumber,
        ClientNetworkTime: clientRequestFinish - clientRequestStart,
        ClientJSEndToEndTime: 0, // Populated separately later
        ClientJSPreRequestTime: 0, // Populated separately later
        ClientJSRequestThreadTime: 0, // Populated separately later
        ClientResponseProcessTime: 0, // Populated separately later
        ClientJSRenderingTime: 0, // Populated separately later
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (346,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchDateTime: responseHeader.get('date'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (350,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchBETarget: responseHeader.get('x-calculatedbetarget'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (354,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchFETarget: responseHeader.get('x-calculatedfetarget'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (358,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchBEHttpStatus: responseHeader.get('x-backendhttpstatus'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (362,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchMsEdgeRef: responseHeader.get('x-msedge-ref'),
        SearchErrorContent: errorMessage,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (367,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchTraceID: responseHeader.get('request-id'),
        SearchQueryId: searchQueryId,
        ClientBuildNumber: getClientVersion(),
        ClientHttpStatus: response.status || -1, // Ensure ClientHttpStatus isn't undefined.
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (376,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchClientRequestID: clientRequestHeaders.get('client-request-id'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (380,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        SearchClientSessionID: getClientId(),
        ClientName: substrateSearchScenario,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (385,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        DiagnosticsCheckpoints: responseHeader.get('x-ms-diagnostics-checkpoints'),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (389,9): Type 'null' is not assignable to type 'CalculatedResourceTimings'.
        // @ts-expect-error
        ResourceTimingEntry: null,
        PIIData: {
            queryText: query,
        },
        LogicalId: '',
    };

    return searchRequestInstrumentation;
};

/**
 * Get the request URL for OWS request
 * @param actionName - The action name
 * @param shouldAddEmptyPostMarker - Whether we should add empty post marker to the request
 */
function getOwsRequestUrl(actionName: string, shouldAddEmptyPostMarker: boolean): string {
    const config = getConfig();
    const baseActionUrl = config.baseUrl
        ? `${config.baseUrl}/service.svc?action=`
        : '/owa/service.svc?action=';
    let url = baseActionUrl + actionName + `&app=${getApp()}`;

    if (shouldAddEmptyPostMarker) {
        url += '&EP=1';
    }

    // Appending a guid to distinguish all the search request and be able to log resource time for all
    // the requests
    url += '&id=' + getGuid();

    return url;
}
