export const deleteButtonDisabledInfoLabel = "z_ytEd";
export const deleteButtonLabel = "$tOVPb";
export const editButtonDisabledInfoLabel = "kgzcvb";
export const editButtonLabel = "Up5Jae";
export const unlinkButtonLabel = "zXszoe";
export const managePersonSourcesHeading = "EzJCK";
export const managePersonSourcesHeadingMoreInfoLabel = "FLEw4b";
export const managePersonSourcesSubHeading = "WOKvkb";
export const personSourcesLearnMoreLabel = "ckKkA";
export const dismissLabel = "urz8Td";
export default {deleteButtonDisabledInfoLabel,deleteButtonLabel,editButtonDisabledInfoLabel,editButtonLabel,unlinkButtonLabel,managePersonSourcesHeading,managePersonSourcesHeadingMoreInfoLabel,managePersonSourcesSubHeading,personSourcesLearnMoreLabel,dismissLabel};