import classnames from 'owa-classnames';
import { FluentButton } from 'owa-fluent-v9-shims';
import { observer } from 'owa-mobx-react';
import { useBooleanState } from 'owa-react-hooks/lib/useBooleanState';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getPalette } from 'owa-theme';
import React from 'react';
import { FontWeights } from '@fluentui/style-utilities';
import { EmptySpace_MainMessageElementId } from '../constants';
import {
    button as styles_button,
    detailsMessageText,
    emptyStateContainer,
    mainMessageText,
    showEmptyStateContainerAnimation,
} from './EmptyState.scss';
import type { IButtonStyles } from '@fluentui/react/lib/Button';

export interface EmptyStateClassNames {
    container?: string;
    mainMessage?: string;
    detailsMessage?: string;
    button?: string;
}

export enum ComponentOrder {
    Newsletters,
}

export interface EmptyStateProps {
    icon?: string | null;
    mainMessage: string | null;
    detailMessage?: string | (string | JSX.Element)[] | null;
    ctaMessage?: string | null; // 'Call to action' text. Text for an action button below the detail message
    onCtaClick?: (() => void) | null;
    ctaAppearance?: 'primary' | 'transparent' | 'icon' | 'command' | 'default';
    classNames?: EmptyStateClassNames;
    iconSize?: number;
    ctaTitle?: string;
    buttonStyles?: IButtonStyles;
    ctaButtonProps?: any;
    styles?: React.CSSProperties;
    componentOrder?: ComponentOrder;
}

const EmptyState = observer(function EmptyState(props: EmptyStateProps) {
    const {
        icon,
        mainMessage,
        detailMessage,
        ctaAppearance,
        ctaMessage,
        onCtaClick,
        iconSize,
        ctaTitle,
        buttonStyles,
        ctaButtonProps,
        componentOrder,
    } = props;

    const {
        container: containerClassName,
        mainMessage: mainMessageClassName,
        detailsMessage: detailsMessageClassName,
        button: buttonClassName,
    } = props.classNames || { container: null, mainMessage: null };

    const imgSize = iconSize || 200;

    const imageStyles = React.useMemo(() => {
        return { width: imgSize, height: imgSize };
    }, [imgSize]);

    const ctaButtonStyles = useComputedValue(() => {
        return { ...getButtonStyles(), ...buttonStyles };
    }, [buttonStyles]);

    const [isImageLoaded, setImageLoaded] = useBooleanState(!icon);

    React.useEffect(() => {
        if (!icon) {
            setImageLoaded();
        }
    }, [icon]);

    const iconComponent = icon && (
        <img
            key="icon"
            style={imageStyles}
            src={icon}
            alt=""
            onLoad={setImageLoaded}
            onError={setImageLoaded}
        />
    );

    const mainMessageComponent = mainMessage && (
        <span
            key="mainMessage"
            className={classnames(mainMessageText, mainMessageClassName)}
            id={EmptySpace_MainMessageElementId}
        >
            {mainMessage}
        </span>
    );

    const detailMessageComponent = detailMessage && (
        <span
            key="detailMessage"
            className={classnames(detailsMessageText, detailsMessageClassName)}
        >
            {detailMessage}
        </span>
    );

    const buttons = ctaMessage && onCtaClick && (
        <FluentButton
            appearance={ctaAppearance == 'default' ? undefined : ctaAppearance ?? 'transparent'}
            className={classnames(styles_button, buttonClassName)}
            title={ctaTitle}
            styles={ctaButtonStyles}
            onClick={onCtaClick}
            {...ctaButtonProps}
        >
            {ctaMessage}
        </FluentButton>
    );

    function getComponentOrder() {
        switch (componentOrder) {
            case ComponentOrder.Newsletters:
                return [mainMessageComponent, detailMessageComponent, iconComponent, buttons];
            default:
                return [iconComponent, mainMessageComponent, detailMessageComponent, buttons];
        }
    }

    const componentsToRender = getComponentOrder();

    return (
        <div
            className={classnames(
                emptyStateContainer,
                containerClassName,
                isImageLoaded && showEmptyStateContainerAnimation
            )}
            style={props.styles}
        >
            {componentsToRender}
        </div>
    );
}, 'EmptyState');
export default EmptyState;

function getButtonStyles(): IButtonStyles {
    const defaultPalette = getPalette();

    return {
        root: {
            color: defaultPalette.themeDarkAlt,
        },
        rootHovered: {
            color: defaultPalette.themeDark,
        },
        rootPressed: {
            color: defaultPalette.themeDarker,
        },
        label: {
            fontWeight: FontWeights.semibold,
            fontSize: '12px',
        },
    };
}
