import { default as isRowSortKeyEqual } from './helpers/isSortKeyEqual';
import isMessageListOutOfOrder from './helpers/isMessageListOutOfOrder';
import shouldSkipProcessRow from './helpers/shouldSkipProcessRow';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyFetchRichContentForRows, lazyReloadTable } from '../index';
import { logVerboseUsage, logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import TableOperations from 'owa-mail-list-table-operations';
import { doesRowBelongToNudgeSection } from 'owa-mail-nudge-store';
import { errorThatWillCauseAlert } from 'owa-trace';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import type { MailListRowDataType, TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import {
    getRowKeyFromListViewType,
    MailRowDataPropertyGetter,
    type SortColumn,
} from 'owa-mail-list-store';
import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { onMergeRowResponseFromTopInTableComplete } from './onMergeRowResponseFromTopInTableComplete';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { ConversationType, ItemRow } from 'owa-graph-schema';
import { getCopilotPrioritizeSettings } from 'owa-mail-copilot-prioritize-settings';

export default action('mergeRowResponseFromTop')(
    /**
     * Process FindConversation/FindItem response and merge them to the top of the given TableView
     * @param rows to be merged
     * @param tableView to merge results in
     * @param totalRowsInViewInResponse - total rows in the table returned from server
     * @param removeRemainingRowsAfterMerge - flag indicating whether to remove the remaining rows after the merge
     * @param shouldLoadIsdataUptodate - flag indicating whether to log the isDateUptodate datapoint
     * @param source - the source of this action
     * @param isManualSync whether we are reloading as part of manual sync
     */
    function mergeRowResponseFromTop(
        rows: MailListRowDataType[],
        tableView: TableView,
        totalRowsInViewInResponse: number,
        removeRemainingRowsAfterMerge: boolean,
        shouldLogIsDataUptodate: boolean,
        source: string,
        isManualSync: boolean = false
    ) {
        if (!rows) {
            // VSO 1790: We may have no rows if the server returns an error like:
            // "The mailbox database is temporarily unavailable". Do not throw. Just trace this.
            errorThatWillCauseAlert('We need to have a valid list of rows');
            return;
        }

        if (shouldLogIsDataUptodate) {
            logVerboseUsage('TnS_MergeFindResponse', [isDataUpToDate(tableView, rows)]);
        }

        if (isManualSync && !isDataUpToDate(tableView, rows)) {
            const mailboxInfo = getMailboxInfo(tableView);
            const account = getAccountByMailboxInfo(mailboxInfo);
            logUsage('MailManualSync_OutOfDate', {
                mailboxType: mailboxInfo.type,
                accountType: account?.sourceType,
            });
        }

        let insertIndex = 0;
        let failedCount = 0;
        const listViewType = tableView.tableQuery.listViewType;
        const folderName = folderIdToName(tableView.tableQuery.folderId);

        if (
            (tableView?.tableQuery as MailFolderTableQuery)?.scenarioType ===
                'highPriorityViewFilter' &&
            rows.length > 0
        ) {
            const mailboxInfo = getMailboxInfo(tableView);
            const { highPriorityScores } = getCopilotPrioritizeSettings(mailboxInfo);

            if (listViewType === 1) {
                const rowsAsItems = rows as ItemRow[];

                rows = rowsAsItems.filter((row: ItemRow) => {
                    return (
                        row.CopilotInboxScore && highPriorityScores?.includes(row.CopilotInboxScore)
                    );
                });
            } else {
                const rowsAsConversations = rows as ConversationType[];

                rows = rowsAsConversations.filter((row: ConversationType) => {
                    return (
                        row.ConversationCopilotInboxScore &&
                        highPriorityScores?.includes(row.ConversationCopilotInboxScore)
                    );
                });
            }
        }

        if (
            (tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn === 16 &&
            rows.length > 0
        ) {
            const mailboxInfo = getMailboxInfo(tableView);
            const { highPriorityScores, lowPriorityScores } =
                getCopilotPrioritizeSettings(mailboxInfo);

            let highPriorityRows: MailListRowDataType[] = [];
            let lowPriorityRows: MailListRowDataType[] = [];
            let otherRows: MailListRowDataType[] = [];

            if (listViewType === 1) {
                const rowsAsItems = rows as ItemRow[];

                highPriorityRows = rowsAsItems.filter((row: ItemRow) => {
                    return (
                        row.CopilotInboxScore &&
                        highPriorityScores &&
                        highPriorityScores.includes(row.CopilotInboxScore)
                    );
                });

                lowPriorityRows = rowsAsItems.filter((row: ItemRow) => {
                    return (
                        row.CopilotInboxScore &&
                        lowPriorityScores &&
                        lowPriorityScores.includes(row.CopilotInboxScore)
                    );
                });

                otherRows = rowsAsItems.filter((row: ItemRow) => {
                    return (
                        !row.CopilotInboxScore ||
                        row.CopilotInboxScore < 0 ||
                        (row.CopilotInboxScore &&
                            !highPriorityScores?.includes(row.CopilotInboxScore) &&
                            !lowPriorityScores?.includes(row.CopilotInboxScore))
                    );
                });
            } else {
                const rowsAsConversations = rows as ConversationType[];

                highPriorityRows = rowsAsConversations.filter((row: ConversationType) => {
                    return (
                        row.ConversationCopilotInboxScore &&
                        highPriorityScores &&
                        highPriorityScores.includes(row.ConversationCopilotInboxScore)
                    );
                });

                lowPriorityRows = rowsAsConversations.filter((row: ConversationType) => {
                    return (
                        row.ConversationCopilotInboxScore &&
                        lowPriorityScores &&
                        lowPriorityScores.includes(row.ConversationCopilotInboxScore)
                    );
                });

                otherRows = rowsAsConversations.filter((row: ConversationType) => {
                    return (
                        !row.ConversationCopilotInboxScore ||
                        row.ConversationCopilotInboxScore < 0 ||
                        (row.ConversationCopilotInboxScore &&
                            !highPriorityScores?.includes(row.ConversationCopilotInboxScore) &&
                            !lowPriorityScores?.includes(row.ConversationCopilotInboxScore))
                    );
                });
            }

            const sortDirection = (tableView?.tableQuery as MailFolderTableQuery)?.sortBy
                ?.sortDirection;

            if (sortDirection === 'Descending') {
                rows = highPriorityRows.concat(otherRows).concat(lowPriorityRows);
            } else {
                rows = lowPriorityRows.concat(otherRows).concat(highPriorityRows);
            }
        }

        for (const row of rows) {
            // Check if row should be processed
            if (shouldSkipProcessRow(row, tableView)) {
                continue;
            }

            const rowKey = getRowKeyFromListViewType(row, listViewType);
            let wasSuccessful = true;
            if (TableOperations.containsRowKey(tableView, rowKey)) {
                const currentIndex = tableView.rowKeys.indexOf(rowKey);
                if (currentIndex < insertIndex) {
                    const isInstanceKeyEmpty = !rowKey;

                    logUsage('MergeRowResponseFromTop_DuplicateRowsInResponse', {
                        currentIndex,
                        insertIndex,
                        tableType: tableView.tableQuery.type,
                        listViewType,
                        folderName,
                        rowKey,
                        isInstanceKeyEmpty,
                        source,
                    });
                    // If we're moving an existing row to a position further down in the list (which can only happen in the case where the service returns multiple rows
                    // with the same InstanceKey), we decrease insertIndex by 1 to account for the row being removed from a position further up in the list prior to being inserted at its new position.
                    insertIndex--;
                }
                TableOperations.updateRow(insertIndex, row, tableView, source);
            } else {
                // Otherwise add new row to the table
                wasSuccessful = TableOperations.addRow(insertIndex, row, tableView, source);
            }

            // Increment index if the operation was successful else
            // always increment.
            if (wasSuccessful) {
                insertIndex++;
            } else {
                failedCount++;
            }
        }

        // VSO - 20814 - Add datapoint to log the freshness of cached table on switch mail folder
        // removeRemainingRowsAfterMerge would be true in case of ReloadTable.
        // For such scenario, it generally indicates that we're out of sync with server, so we need to invalidate
        // remaining items we had after merging the top items
        const isPriorityFilterOrSort =
            (tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn === 16 ||
            (tableView?.tableQuery as MailFolderTableQuery)?.scenarioType ===
                'highPriorityViewFilter';
        if (removeRemainingRowsAfterMerge || isPriorityFilterOrSort) {
            const processedRowCount = insertIndex;
            const rowKeysLength = tableView.rowKeys.length;
            for (let i = rowKeysLength - 1; i >= processedRowCount; i--) {
                // Remove remaining rows from table except for nudged rows
                // We will keep them as reloads can happen any time as as its not due to user initiated actions
                // removing nudge row can cause nudge disappearing
                const rowKey = tableView.rowKeys[i];
                if (
                    !doesRowBelongToNudgeSection(
                        rowKey,
                        tableView.id,
                        MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(rowKey, tableView)
                    )
                ) {
                    const rowId = MailRowDataPropertyGetter.getRowIdString(rowKey, tableView);
                    const rowKeysForRowId: string[] = tableView.rowIdToRowKeyMap.get(rowId) ?? [];

                    if (!isPriorityFilterOrSort) {
                        logUsage('MergeRowResponseFromTop_RemoveRow', {
                            tableType: tableView.tableQuery.type,
                            listViewType,
                            folderName,
                            rowKey,
                            rowId,
                            rowKeysForRowIdHasItems: rowKeysForRowId.length > 0,
                        });
                    }

                    TableOperations.removeRow(rowKey, tableView, source + '_RemoveAfterMerge');
                }
            }
        }

        // There have been a few occasions where existing rows that are meant to be in the top fetched rows are omitted from the query result due to other bugs that may happen.
        // When this happens, such rows get pushed down to insertIndex and below and are out of order. insertIndex is the index immediately after the last row inserted in this function.
        // We are checking the row at insertIndex to see if it is out of order compared to the row at the indexInsert - 1 index, and if so, we reload the table which fetches the rows again.
        if (!removeRemainingRowsAfterMerge && isMessageListOutOfOrder(tableView, insertIndex)) {
            logUsage('MergeRowResponseFromTop_OutOfOrderItems', {
                tableType: tableView.tableQuery.type,
                listViewType,
                folderName,
            });

            if (isFeatureEnabled('tri-checkMLItemsOutOfOrder')) {
                lazyReloadTable.importAndExecute(tableView, true);
                return;
            }
        }

        // raise merge row response complete
        onMergeRowResponseFromTopInTableComplete(tableView);

        // Always update totalRowsInView after making a find request, to allow us to calculate whether this table can load more
        tableView.totalRowsInView = totalRowsInViewInResponse - failedCount;

        // FetchRichContentForRows
        lazyFetchRichContentForRows.importAndExecute(tableView);
    }
);

function isDataUpToDate(tableView: TableView, rows: MailListRowDataType[]): boolean {
    // We only want to compare first 25 rows from the response to determine if
    // the data we have is up-to-date. Even though this is not 100% correct
    // it's a guesstimate that the data would probably be up-to-date if first rows are.
    for (let i = 0; i < Math.min(tableView.rowKeys.length, 25, rows.length); i++) {
        if (tableView.rowKeys[i] != rows[i].InstanceKey || !isRowSortKeyEqual(rows[i], tableView)) {
            return false;
        }
    }

    return true;
}
