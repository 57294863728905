import type { AccountConfigurationOrigin } from '../types/AccountConfigurationOrigin';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { logStartUsage } from 'owa-analytics-start';
import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';

const accountConfigurationSetCount = new Map<string, number>();

/**
 * Logs information about setting of account configuration information, in particular we want to
 * make sure know
 * @param configurationOrigin
 * @param mailboxInfo
 * @param forceIsGlobalAccountMailboxInfoResult
 */
export function logSetAccountConfiguration(
    configurationOrigin: AccountConfigurationOrigin,
    mailboxInfo: MailboxInfo,
    forceIsGlobalAccountMailboxInfoResult: boolean
) {
    // build a unique key for the account configuration data being set
    const key = `${forceIsGlobalAccountMailboxInfoResult}:${getIndexerValueForMailboxInfo(
        mailboxInfo
    )}`;

    const setCount = (accountConfigurationSetCount.get(key) || 0) + 1;
    accountConfigurationSetCount.set(key, setCount);

    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const globalSettingsAccountIndexer = getGlobalSettingsAccountIndexer(
        false /*throwIfNotInitialized*/
    );

    logStartUsage('SetAccountConfiguration', {
        origin: configurationOrigin,
        setCount,
        isForceGlobal: forceIsGlobalAccountMailboxInfoResult,
        globalSettingsIndexerLength: globalSettingsAccountIndexer?.length || 0,
        isGlobalSettingsAccount: indexer === globalSettingsAccountIndexer,
    });
}
