//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MailFolderFromServiceFragment = {
    __typename?: 'MailFolder';
    type?: string | null;
    id: string;
    changeKey?: string | null;
    parentFolderId: string;
    displayName: string;
    distinguishedFolderType?: string | null;
    FolderClass?: string | null;
    UnreadCount: number;
    totalMessageCount: number;
    childFolderCount: number;
    childFolderIds?: Array<string> | null;
    messageSizeInBytes?: string | null;
    mailboxInfo: any;
    replicaList?: Array<string | null> | null;
    SearchFolderTemplateId?: number | null;
    sortParentId?: string | null;
    sortPosition?: string | null;
    remoteFolderInfo?: {
        __typename?: 'RemoteFolderInfo';
        remoteFolderDisplayName?: string | null;
        remoteDistinguishedFolderType?: Types.RemoteDistinguishedFolderType | null;
    } | null;
    ArchiveTag?: {
        __typename?: 'RetentionTagType';
        IsExplicit?: boolean | null;
        Value: string;
    } | null;
    PolicyTag?: {
        __typename?: 'RetentionTagType';
        IsExplicit?: boolean | null;
        Value: string;
    } | null;
    EffectiveRights?: {
        __typename?: 'EffectiveRightsType';
        CreateAssociated?: boolean | null;
        CreateContents?: boolean | null;
        CreateHierarchy?: boolean | null;
        Delete?: boolean | null;
        Modify?: boolean | null;
        Read?: boolean | null;
        ViewPrivateItems?: boolean | null;
    } | null;
};
export const MailFolderFromServiceFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MailFolderFromService' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailFolder' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'changeKey' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'parentFolderId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'distinguishedFolderType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'FolderClass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'UnreadCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'totalMessageCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'childFolderCount' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childFolderIds' },
                        directives: [
                            { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
                        ],
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'messageSizeInBytes' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteFolderInfo' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remoteFolderDisplayName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remoteDistinguishedFolderType' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ArchiveTag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'IsExplicit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PolicyTag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'IsExplicit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Value' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mailboxInfo' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'replicaList' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'SearchFolderTemplateId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EffectiveRights' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'CreateAssociated' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'CreateContents' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'CreateHierarchy' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Delete' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Modify' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'Read' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ViewPrivateItems' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'sortParentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sortPosition' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MailFolderFromServiceFragment, unknown>;
