import { addFdListener, FreezeDryItemTypes, EventTypes, RestoreMotive } from 'owa-freeze-dry';
import type { FreezeDryItem, MainWindow, FdEventContext } from 'owa-freeze-dry';
import { getFocusedFilterForTable, getSelectedTableView } from 'owa-mail-list-store';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { singleSelectRow } from 'owa-mail-actions/lib/mailListSelectionActions';
import selectFolderWithFallbackInFolderForest from 'owa-mail-folder-forest-actions/lib/actions/selectFolderWithFallbackInFolderForest';
import { Module } from 'owa-workloads';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { selectFocusedViewFilter } from 'owa-mail-triage-table-utils';
import { lazyLoadMoreInTable } from 'owa-mail-triage-table-load-extra';
import sleep from 'owa-sleep';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getMailListLoadStateFromTableView } from 'owa-mail-list-store/lib/utils/getMailListLoadState';

const MAX_LOAD_MORE_TRIES = 3;

let fdDatapoint: PerformanceDatapoint | undefined;
let loadingMore = 0;
const scrollPosItemsRestored = {
    folIdRes: false,
    focFilRes: false,
    rowKeyRes: false,
    loadingMore,
    resSPRes: false,
};
let tableViewLoadWaitTime = 5000;

async function handleRestoreScrollPosition(
    mainWindow: FreezeDryItem | undefined,
    context: FdEventContext | undefined
) {
    fdDatapoint = new PerformanceDatapoint('fdHandleRestoreMail');
    if (
        isFeatureEnabled('nh-scrollPositionTableLoadWaitTimeTwoSec') ||
        context?.restoreMotive === RestoreMotive.RecallFeature
    ) {
        tableViewLoadWaitTime = 2000;
    }
    fdDatapoint?.addCustomData({ sec: tableViewLoadWaitTime });
    if (mainWindow) {
        mainWindow = mainWindow as MainWindow;
        const rowKey = mainWindow.rowKey;
        const folderId = mainWindow.folderId;
        const module = mainWindow.module;
        const focusedViewFilter = mainWindow.focusedViewFilter;
        const scrollPosItemsToRestore = {
            mod: module,
            folId: !!folderId,
            focFil: focusedViewFilter,
            rowKey: !!rowKey,
        };
        fdDatapoint?.addCustomData(scrollPosItemsToRestore);
        if (module === Module.Mail) {
            if (folderId !== undefined) {
                // If folderId is not currentFolder, switch to that folderId
                const currentFolder = getSelectedNode().id;
                if (folderId && folderId !== currentFolder) {
                    selectFolderWithFallbackInFolderForest(folderId, 'FreezeDry');
                }
                // In the case of unsucessful folder selection due to folder not being loaded yet,
                // we'll return to prevent unnecessary loadMore calls
                if (folderId !== getSelectedNode().id) {
                    fdDatapoint?.endWithError(
                        DatapointStatus.ClientError,
                        Error('Failed to select folder')
                    );
                } else {
                    scrollPosItemsRestored.folIdRes = true;
                    if (focusedViewFilter !== undefined) {
                        // If focusedViewFilter is not currentFocusedViewFilter, switch to that focusedViewFilter
                        let tableView = getSelectedTableView();
                        if (getFocusedFilterForTable(tableView) !== focusedViewFilter) {
                            selectFocusedViewFilter(focusedViewFilter, 'FreezeDry');
                            tableView = getSelectedTableView();
                        }

                        if (getFocusedFilterForTable(tableView) !== focusedViewFilter) {
                            fdDatapoint?.endWithError(
                                DatapointStatus.ClientError,
                                Error('Failed to select focusedViewFilter')
                            );
                        } else {
                            scrollPosItemsRestored.focFilRes = true;
                            if (rowKey !== undefined) {
                                if (!(await selectRow(tableView, rowKey))) {
                                    // Try to load more items and select row up to MAX_LOAD_MORE_TRIES times
                                    for (let i = 0; i < MAX_LOAD_MORE_TRIES; i++) {
                                        loadingMore = i + 1;
                                        if (await loadMoreAndTrySelectRow(tableView, rowKey)) {
                                            break;
                                        }
                                    }
                                    scrollPosItemsRestored.loadingMore = loadingMore;
                                    if (loadingMore === MAX_LOAD_MORE_TRIES) {
                                        fdDatapoint?.endWithError(
                                            DatapointStatus.ClientError,
                                            Error(
                                                'Failed to select row after loading more items ' +
                                                    loadingMore +
                                                    ' times'
                                            )
                                        );
                                    }
                                }
                            } else {
                                scrollPosItemsRestored.rowKeyRes = true;
                            }
                        }
                    } else {
                        scrollPosItemsRestored.focFilRes = true;
                    }
                }
            }
            if (
                scrollPosItemsRestored.folIdRes &&
                scrollPosItemsRestored.focFilRes &&
                scrollPosItemsRestored.rowKeyRes
            ) {
                scrollPosItemsRestored.resSPRes = true;
            }
            fdDatapoint?.addCustomData(scrollPosItemsRestored);
        }
    }
    fdDatapoint?.end();
}

function loadMoreAndTrySelectRow(tableView: TableView, rowKey: string): Promise<boolean> {
    lazyLoadMoreInTable.importAndExecute(tableView);
    return selectRow(tableView, rowKey);
}

async function selectRow(tableView: TableView, rowKey: string): Promise<boolean> {
    // We need to wait for isInitialLoadComplete to be true. Since we don't have a way to know when the table is loaded, we'll wait for tableViewLoadWaitTime seconds.
    await sleep(tableViewLoadWaitTime);
    fdDatapoint?.addCustomData({ mlState: getMailListLoadStateFromTableView(tableView) });
    if (rowKey && tableView.rowKeys.includes(rowKey)) {
        singleSelectRow(
            tableView,
            rowKey as string,
            false /* isUserNavigation */,
            MailListItemSelectionSource.MailListItemBody
        );
        scrollPosItemsRestored.rowKeyRes = true;
        return true;
    } else {
        return false;
    }
}

// Add the listener to 'fdEventManager'
export function initializeRestoreMailListener() {
    addFdListener(EventTypes.Restore, FreezeDryItemTypes.MainWindow, handleRestoreScrollPosition);
}
