import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import mailStore from 'owa-mail-store/lib/store/Store';

export function hasCollapsedItemsRollUp(
    conversationReadingPaneViewState: ConversationReadingPaneViewState
): boolean {
    return (
        conversationReadingPaneViewState?.conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP &&
        conversationReadingPaneViewState.conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP
            .length > 0
    );
}

export function hasSeeMoreButton(
    conversationReadingPaneViewState: ConversationReadingPaneViewState
): boolean {
    const conversationItemParts = mailStore.conversations.get(
        conversationReadingPaneViewState.conversationId.Id
    );

    // Should show see more button if collapsed items roll up exists,
    // or the conversation could load more
    return (
        hasCollapsedItemsRollUp(conversationReadingPaneViewState) ||
        !!conversationItemParts?.canLoadMore
    );
}

export function isItemPartInCollapsedItemsRollUp(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    itemPart: ItemPartViewState
): boolean {
    return (
        conversationReadingPaneViewState?.conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP &&
        itemPart &&
        conversationReadingPaneViewState.conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP.includes(
            itemPart.conversationNodeId
        )
    );
}

export function getItemsCountInCollapsedItemsRollUp(
    conversationReadingPaneViewState: ConversationReadingPaneViewState
): number {
    if (!hasCollapsedItemsRollUp(conversationReadingPaneViewState)) {
        return 0;
    }

    const { conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP, itemPartsMap } =
        conversationReadingPaneViewState;
    let itemsCount = conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP.length;

    // Calculate the oof messages in the message count
    conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP.forEach(nodeId => {
        const itemPartViewState = itemPartsMap.get(nodeId);

        if (itemPartViewState && itemPartViewState.oofRollUpViewState.oofReplyNodeIds) {
            itemsCount += itemPartViewState.oofRollUpViewState.oofReplyNodeIds.length;
        }
    });

    return itemsCount;
}
