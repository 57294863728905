import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyUnsubscribeEmailWeb = createLazyResolver(
    'MUTATION_ITEM_UNSUBSCRIBE_EMAIL_WEB',
    () =>
        import(
            /* webpackChunkName: "ItemUnsubscribeEmailMutationWebResolver"*/ './unsubscribeEmailWeb'
        ),
    m => m.unsubscribeEmailWeb
);
