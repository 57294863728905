import { action } from 'satcheljs';
import type { SearchScenarioId } from 'owa-search-store';

export const setSelectedSuggestionQuickActionSelected = action(
    'SET_SELECTED_SUGGESTION_QUICKACTION_SELECTED',
    (quickActionSelected: boolean, scenarioId: SearchScenarioId) => ({
        quickActionSelected,
        scenarioId,
    })
);
