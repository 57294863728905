import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyFlagConversationMutationWeb = createLazyResolver(
    'FLAGCONVERSATION_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "FlagConversationMutationWeb"*/ './flagConversationMutationWeb'
        ),
    m => m.flagConversationMutationWeb
);
