import { getLaunchTilesDefaultBehavior } from './getLaunchTilesDefaultBehavior';
import { getAppAcquisitionLinkDefaultBehavior } from './getAppAcquisitionLinkDefaultBehavior';
import { getFlyoutSearchDefaultBehavior } from './getFlyoutSearchDefaultBehavior';
import { moreApps, noApps } from 'owa-locstrings/lib/strings/moreapps.locstring.json';
import type { StrictNovaControlModelQuery } from '../types';
import loc from 'owa-localize';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import type { LaunchTileBehaviorParams } from '../launchTileBehaviorParams';

export function getControlBehavior(
    launchTileParams?: LaunchTileBehaviorParams
): StrictNovaControlModelQuery {
    const isBleedThroughSupported = isCapabilitySupported(bleedThroughCapability);
    return {
        __typename: 'Query',
        views: {
            __typename: 'ViewData',
            appBarControl: {
                __typename: 'AppBarControl',
                id: 'AppBarControl',
                rootNavigationAriaLabel: 'left-rail-appbar',
                animateSelection: true,
                enableMicaV2DesktopStyles:
                    isFeatureEnabled('mon-transparency-usingSystemBackdrop') ||
                    isBleedThroughSupported,
                enableMicaV2WebStyles: isBleedThroughSupported,
                enableOptimisticMutations: true,
                tileBehavior: getLaunchTilesDefaultBehavior(launchTileParams),
                flyoutBehavior: {
                    __typename: 'FlyoutBehavior',
                    flyoutSize: 'Large',
                    searchBehavior: getFlyoutSearchDefaultBehavior(),
                },
                appAcquisitionLinkBehavior: getAppAcquisitionLinkDefaultBehavior(),
                localizedStrings: {
                    __typename: 'LocalizedStrings',
                    flyoutButtonTitle: loc(moreApps),
                    flyoutButtonText: loc(moreApps),
                    flyoutEmptyStateMessage: loc(noApps),
                },
                flyoutIcon: {
                    __typename: 'InternalIcon',
                    name: 'StoreIcon',
                },
                flyoutIconSelected: {
                    __typename: 'InternalIcon',
                    name: 'StoreIconSelected',
                },
            },
            messageExtensionFlyoutControl: {
                __typename: 'MessageExtensionFlyoutControl',
                id: 'MessageExtensionFlyoutControl',
                zIndexOverride: 0,
                flyoutAriaLabel: 'message-extensions-flyout',

                // TODO: Properly localize this (bug #148579)
                flyoutEmptyStateMessage: '',
                tileBehavior: getLaunchTilesDefaultBehavior(),
                searchBehavior: getFlyoutSearchDefaultBehavior(),
            },
        },
    };
}
