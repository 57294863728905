//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MailFolderNodeTreeFragmentDoc } from 'owa-mail-folder-view-graphql/lib/graphql/__generated__/MailFolderNodeTree.interface';
export type MailFolderNodeTreeQueryVariables = Types.Exact<{
    id: Types.Scalars['ID'];
    mailboxInfo: Types.MailboxInfoInput;
}>;
export type MailFolderNodeTreeQuery = {
    __typename?: 'Query';
    folder?: {
        __typename?: 'MailFolder';
        displayName: string;
        distinguishedFolderType?: string | null;
        childFolderIds?: Array<string> | null;
        mailboxInfo: any;
        parentFolderId: string;
        totalMessageCount: number;
        UnreadCount: number;
        type?: string | null;
        remoteFolderInfo?: {
            __typename?: 'RemoteFolderInfo';
            remoteFolderDisplayName?: string | null;
        } | null;
        EffectiveRights?: {
            __typename?: 'EffectiveRightsType';
            Modify?: boolean | null;
            Delete?: boolean | null;
        } | null;
    } | null;
};
export const MailFolderNodeTreeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'MailFolderNodeTree' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'folder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'options' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'mailboxInfo' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'mailboxInfo' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderNodeTree' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...MailFolderNodeTreeFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MailFolderNodeTreeQuery, MailFolderNodeTreeQueryVariables>;
