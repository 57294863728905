import { isSameMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type { ResolverContext } from 'owa-graph-schema';
import { trace, errorThatWillCauseAlert } from 'owa-trace';
import { logUsage } from 'owa-analytics';
import type { Operation } from '../types/Operation';

// 'Strict' version of getMailboxInfoFromOperation that returns undefined if a mailboxInfo is not
// found on the operation (getMailboxInfoFromOperation falls back to the global account mailboxInfo)
export function getMailboxInfoFromOperationStrict(operation: Operation): MailboxInfo | undefined {
    const mailboxInfo: MailboxInfo | undefined =
        (operation.getContext() as ResolverContext)?.requestOptions?.mailboxInfo ||
        getMailboxInfoFromVariables(operation);

    return mailboxInfo;
}

type kv = {
    key: string;
    val: any;
};

// do a simple BFS through the variables looking for a field named mailboxinfo
// note: it's not enough just to look at the toplevel fields in 'variables' because,
// for example, it's very common for graphql inputs to have a single input object that roots
// the actual runtime variables.
function getMailboxInfoFromVariables(operation: Operation) {
    const vars = operation.variables || {};
    const worklist: kv[] = [{ key: 'variables', val: vars }];
    let sanity = 1024;
    let rv: MailboxInfo | undefined;

    while (worklist.length > 0 && --sanity > 0) {
        const work = worklist.shift();
        if (work) {
            if (work.key === 'mailboxinfo') {
                const info: MailboxInfo = work.val;
                if (rv === undefined) {
                    rv = work.val;
                } else {
                    // notify if there's a single operation trying to access two different remote mailboxes
                    // the gateway currently assumes a single routing header and auth context per request so
                    // to support this, we'd need to break the request into separate requests based on MI and
                    // route separately and reassemble the results.
                    if (!isSameMailboxInfo(rv, info)) {
                        const str = `Operation ${
                            operation.operationName
                        } is attempting a remote query for two different mailbox identities ${JSON.stringify(
                            rv
                        )} and ${JSON.stringify(info)}.  This is not currently supported.`;
                        trace.warn(str);
                        errorThatWillCauseAlert(str);
                    }
                }
            } else if (work.val) {
                for (const key of Object.keys(work.val)) {
                    const val = work.val[key];
                    if (typeof val === 'object') {
                        worklist.push({ key: key.toString().toLowerCase(), val });
                    }
                }
            }
        }
    }

    if (!rv && sanity <= 0) {
        // if this is hit, we searched far more fields than we'd expect to be in an input variable.
        // we'll use a MI if it was found, otherwise just default to the global acct
        // if a secondary account feature needs this working, they can set it on the context or move the mailboxinfo earlier
        // in the tree.
        trace.warn("couldn't infer mailboxinfo from variabes.  Object too complex");
        logUsage('get_MI_Op_Complex', { name: operation.operationName });
    }

    return rv;
}
