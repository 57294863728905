import type {
    RibbonControlGroupDefinitionMLR,
    RibbonControlGroupDefinitionSLR,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { Multiline, SingleLine, constructKeytip } from 'owa-command-ribbon';
import type { RibbonTabId, RibbonGroupId } from 'owa-ribbon-ids';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { bottomBarOverflowMenuLabel } from 'owa-locstrings/lib/strings/commandRibbon.locstring.json';
import { loc } from 'owa-localize/lib/loc';

/**
 * Helper function to be used for creating ribbon groups in MLR.
 * @param tabId is the id of the current tab
 * @param groupId is the RibbonGroupId for this group. Must be unique, @see {RibbonGroupId}.
 * @param editorId is the id of the current editorViewState
 * @returns RibbonControlGroupDefinitionSLR object. Searchable in 1JS Repo, in `Ribbon.Props.ts`
 */
export const createRibbonGroupMLR = (
    tabId: RibbonTabId,
    groupId: RibbonGroupId,
    editorId?: string
): RibbonControlGroupDefinitionMLR => {
    return {
        type: Multiline,
        id: getComposeRibbonId(groupId, editorId).toString(),
        title: '',
        controls: [],
        layouts: [],
        commonGroupFlyout: undefined,
        keytipProps: constructKeytip([tabId], tabId),
    };
};

/**
 * Helper function to be used for creating ribbon groups in SLR.
 * Importantly:
 *   - `hasOwnOverflow` should be false and
 *   - `shouldAllowAllControlsInOverflow` should be true and
 *   - keytipProps matching the tabId needs to be provided
 * Otherwise, Customizer gets into mismatched states with the Ribbon.
 * @param tabId is the id of the current tab
 * @param groupId is the RibbonGroupId for this group. Must be unique, @see {RibbonGroupId}.
 * @param editorId is the id of the current editorViewState
 * @returns RibbonControlGroupDefinitionSLR object. Searchable in 1JS Repo, in `Ribbon.Props.ts`
 */
export const createRibbonGroupSLR = (
    tabId: RibbonTabId,
    groupId: RibbonGroupId,
    editorId?: string
): RibbonControlGroupDefinitionSLR => {
    return {
        type: SingleLine,
        id: getComposeRibbonId(groupId, editorId).toString(),
        title: '',
        overflowMenuLauncherLabel: loc(bottomBarOverflowMenuLabel),
        hasOwnOverflow: false,
        shouldAllowAllControlsInOverflow: true,
        controls: [],
        keytipProps: constructKeytip([tabId], tabId),
    };
};

/**
 * Helper function to be used for creating ribbon groups.
 * As there is only one group, `hasOwnOverflow` is set to true.
 * @param tabId is the id of the current tab
 * @param groupId is the RibbonGroupId for this group. Must be unique, @see {RibbonGroupId}.
 * @param editorId is the id of the current editorViewState
 * @returns RibbonControlGroupDefinitionSLR object. Searchable in 1JS Repo, in `Ribbon.Props.ts`
 */
export const createRibbonGroupToolbar = (
    groupId: RibbonGroupId,
    editorId?: string
): RibbonControlGroupDefinitionSLR => {
    return {
        type: SingleLine,
        id: getComposeRibbonId(groupId, editorId).toString(),
        title: '',
        overflowMenuLauncherLabel: loc(bottomBarOverflowMenuLabel),
        hasOwnOverflow: true,
        shouldAllowAllControlsInOverflow: true,
        controls: [],
        keytipProps: undefined,
    };
};
