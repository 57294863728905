import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter, type TableQueryType } from 'owa-mail-list-store';
import { doesRowBelongToNudgeSection, getNudgeItemId } from 'owa-mail-nudge-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import { getAggregationBucket } from 'owa-analytics-actions';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { userDate, differenceInDays, now } from 'owa-datetime';
import { getShouldAnimate } from 'owa-mail-list-item-animation-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointGetterAndSetter';

export function addCustomDataToSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string
) {
    const dp = getSelectMailItemDatapoint();
    if (dp) {
        const timeStamp = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
            rowKey,
            tableView
        );
        const isNudge = doesRowBelongToNudgeSection(rowKey, tableView.id, timeStamp);

        let nudgeItemId = null;
        if (isNudge) {
            nudgeItemId = getNudgeItemId(rowKey);
        }

        const daysAgo = timeStamp ? Math.max(0, differenceInDays(now(), userDate(timeStamp))) : -1;
        const autoSelectRow = getUserConfiguration()?.UserOptions?.ShowReadingPaneOnFirstLoad;
        const isConversationOnReadingPane =
            tableView.tableQuery.listViewType == 1 ? false : isReadingPaneConversationEnabled();

        dp.addCustomData([
            mailListItemSelectionSource,
            tableView.tableQuery.listViewType,
            tableView.tableQuery.type,
            tableView.tableQuery.type === 1 && (tableView.tableQuery as any).searchScope.kind,
            0, // deprecated valid coupon indexes
            isNudge,
            !isReadingPanePositionOff(),
            getAggregationBucket({
                value: tableView.rowKeys.indexOf(rowKey),
                buckets: [10, 25, 50, 75, 100, 150, 200, 250, 300, 500],
                exactMatches: [-1, 0],
            }), // Row key index
            nudgeItemId, // Logged only if row was nudged.
            isConversationOnReadingPane,
            tableView.tableQuery.type === 0 && folderIdToName(tableView.tableQuery.folderId),
            daysAgo,
            getShouldAnimate(),
            autoSelectRow,
        ]);

        dp.addUnifiedTelemetryData({ eventName: 'ViewMessage' });
    }
}
