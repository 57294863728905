import type { AccountSource } from 'owa-account-source-list-store';
import { logCoreUsageFromAccounts, logUsageFromAccounts } from 'owa-account-analytics';
import {
    getGlobalSettingsAccountIndexer,
    accountRankTypeChecker,
} from 'owa-account-source-list-store';
import { areApplicationSettingsInitialized } from 'owa-application-settings';
import { areFeatureFlagsInitialized } from 'owa-feature-flags';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import registerForAccountStartupComplete from '../accountStateChanged/registerForAccountStartupComplete';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Called when a coprincipal account in the owa-account-source-list-store has completed it's startup. This
 * function checks and makes sure that the expected initialziation of the feature flags and appplication
 * settings has taken place for the mailbox.
 *
 * Note, we do not check owa-session-store because it does not export a function to check if the accounts
 * settings have been initialized for a specific account.
 */
function accountStartupCheck(account: AccountSource) {
    const gsaIndexer = getGlobalSettingsAccountIndexer(false /*throwIfNotInitialzied*/);
    const indexer = getIndexerValueForMailboxInfo(account.mailboxInfo);

    const type = account.sourceType.toString();
    const isGsa = gsaIndexer == indexer;
    const bootState = accountRankTypeChecker.isCoprincipal(account)
        ? account.bootState.toString()
        : '<na>';

    const features = areFeatureFlagsInitialized(account.mailboxInfo);
    const appsettings = areApplicationSettingsInitialized(account.mailboxInfo);

    const accountSettings = getAccountScopeUserSettings(account.mailboxInfo);
    const isSmtpUserEmail =
        account.mailboxInfo?.mailboxSmtpAddress ==
        accountSettings?.SessionSettings?.UserEmailAddress;
    const isUIDUserLogon =
        account.mailboxInfo?.userIdentity == accountSettings?.SessionSettings?.LogonEmailAddress;

    const miscData = {
        type,
        isGsa,
        bootState,
        features,
        appsettings,
        isSmtpUserEmail,
        isUIDUserLogon,
    };

    if (!features || !appsettings) {
        // if features or settings are missing log at core level
        logCoreUsageFromAccounts('AcctStartupCheck', miscData);
    } else {
        logUsageFromAccounts('AcctStartupCheck', miscData);
    }
}

/**
 * Registers the callback for confirming the startup state of the account
 */
export function enableAccountStartupCheck() {
    registerForAccountStartupComplete(accountStartupCheck);
}
