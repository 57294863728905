import { LazyAction, createLazyComponent } from 'owa-bundling';
import { lazyModule } from './lazyFunctions';

// Export components
export const AnswersContainer = createLazyComponent(lazyModule, m => m.AnswersContainer);
export const LoadMoreTopResultsComponent = createLazyComponent(
    lazyModule,
    m => m.LoadMoreTopResultsComponent
);
export const SearchHeader = createLazyComponent(lazyModule, m => m.SearchHeader);

// Export store stuff
export type { default as MailSearchStore } from './store/schema/MailSearchStore';
export { SUPPORTED_FILESHUB_SUGGESTION_KIND_LIST } from './store/schema/constants';
export type { SupportedFilesHubSuggestionKindType } from './store/schema/constants';
export { default as mailSearchStore, getStore } from './store/store';

// Export actions
export {
    clearSearchScope,
    setStaticSearchScope,
    onFolderScopeUpdate,
    onAnswerRendered,
    expandTopResults,
} from './actions/publicActions';

// Export utils
export { default as isAnswerFeatureEnabled } from './utils/isAnswerFeatureEnabled';

export const lazyInitializeSearchAnswers = new LazyAction(
    lazyModule,
    m => m.initializeAndWarmupAnswers
);

// Export selectors
export { default as isSearchRefinerApplied } from './selectors/isSearchRefinerApplied';
export { default as getCountOfHiddenTopResults } from './selectors/getCountOfHiddenTopResults';
export {
    SearchHeaderFirstRowContent,
    lazyModule,
    lazySetStaticSearchScopeData,
    lazyStartSearchWithCategory,
    lazyFindEmailFromSender,
    lazyFindEmailFromConversation,
    lazyStoreTopResults,
    lazySetNumberOfTopResultsShown,
    lazyInitializeSearch,
    lazyPrimeMailSearchZeroQueryCache,
    CopilotContainer,
} from './lazyFunctions';
