import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostQueryWeb" */ './lazyIndex')
);

export const lazyAppDefinitionCatalogQueryWeb = createLazyResolver(
    'APPHOST_APP_DEFINITION_CATALOG_QUERY_WEB',
    lazyModule,
    m => m.appDefinitionCatalogQueryWeb
);

export const lazyM365AcquisitionsQueryWeb = createLazyResolver(
    'APPHOST_M365_ACQUISITIONS_QUERY_WEB',
    lazyModule,
    m => m.m365AcquisitionsQueryWeb
);

export const lazyM365TitleLaunchInfoQueryWeb = createLazyResolver(
    'APPHOST_M365_TITLE_LAUNCH_INFO_QUERY_WEB',
    lazyModule,
    m => m.m365TitleLaunchInfoQueryWeb
);

export const lazyM365DomainToTitlesMappingQueryWeb = createLazyResolver(
    'M365_DOMAIN_TO_TITLES_MAPPING_QUERY_WEB',
    lazyModule,
    m => m.m365DomainToTitlesMappingQueryWeb
);
