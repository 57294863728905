import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type { UserConfigurationArgs } from 'owa-graph-schema';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';

export function createUserConfigurationUpdateFunction(updates: UserConfigurationArgs) {
    return (userConfig: OwaUserConfiguration) => {
        internalUserConfigurationUpdateFunction(userConfig, updates);
    };
}

function internalUserConfigurationUpdateFunction(
    userConfig: OwaUserConfiguration,
    updates: UserConfigurationArgs
) {
    Object.keys(updates).forEach(key => {
        const configKey = key as keyof UserConfigurationArgs;
        const internalUserConfig = userConfig[configKey];
        const internalUpdate = updates[configKey];
        if (internalUserConfig && internalUpdate) {
            Object.keys(internalUpdate).forEach(subKey => {
                const configSubKey = subKey as never;
                const value = applyUpdateConversionIfNecessary(
                    configSubKey,
                    internalUpdate[configSubKey]
                );
                if (value !== undefined && value !== null) {
                    internalUserConfig[configSubKey] = value as never;
                }
            });
        }
    });
}

function applyUpdateConversionIfNecessary(configKey: string, value: any) {
    if (configKey === 'KeyboardShortcutsMode' && typeof value === 'string') {
        switch (value) {
            case 'Gmail':
                return 3;
            case 'Hotmail':
                return 0;
            case 'Off':
                return 1;
            case 'Owa':
                return 4;
            case 'Yahoo':
                return 2;
        }
    }

    return value;
}
