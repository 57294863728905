import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';

const mailboxIndexerToCopilotPrioritizeSettings = new Map<string, CopilotPrioritizeSettingsStore>();

interface CopilotPrioritizeSettingsStore {
    isInitialized: boolean;
    settings: CopilotPrioritizeSettings;
}

export default function getCopilotPrioritizeSettingsStore(
    mailboxInfo: MailboxInfo
): CopilotPrioritizeSettingsStore {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_copilotPrioritizeSettingsStore';
    let cachedStore = mailboxIndexerToCopilotPrioritizeSettings.get(name);
    if (!cachedStore) {
        const defaultSettings: CopilotPrioritizeSettings = {
            version: 1,
            // Default to false even though service default is true to avoid showing
            // UI the user has turned off momentarily before settings are fetched.
            prioritizationEnabled: false,
            useServicePrompt: false,
            moreImportantRules: [],
            lessImportantRules: [],
            miscellaneousRules: [],
            userPrompt: null,
            // Default to false even though service default is true to avoid showing
            // UI the user has turned off momentarily before settings are fetched.
            applyHighPriorityLabel: false,
            highPriorityScores: [4, 5],
            applyLowPriorityLabel: false,
            lowPriorityScores: [1, 2],
            experimentalSetting: null,
        };

        const defaultStore: CopilotPrioritizeSettingsStore = {
            isInitialized: false,
            settings: defaultSettings,
        };

        const store = createStore<CopilotPrioritizeSettingsStore>(name, defaultStore)();
        mailboxIndexerToCopilotPrioritizeSettings.set(name, store);
        cachedStore = store;
    }
    return cachedStore;
}
