import updateTableCurrentLoadedIndex from 'owa-mail-triage-table-utils/lib/actions/updateTableCurrentLoadedIndex';
import { getStore } from 'owa-mail-list-store';
import { mutator } from 'satcheljs';

export default mutator(updateTableCurrentLoadedIndex, actionMessage => {
    const tableView = getStore().tableViews.get(actionMessage.tableViewId);
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2532 (10,5): Object is possibly 'undefined'.
    // @ts-expect-error
    tableView.currentLoadedIndex = actionMessage.currentLoadedIndexToSet;
});
