import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import { default as getPendingAccountStore } from '../store/pendingAccountsStore';
import { default as reloadStore } from '../utils/reloadStore';

let initialLoadPromise: Promise<void>;

/**
 * Makes sure the store has been loaded at least once and returns the
 * list of ALL pending accounts, i.e. both email accounts and data files
 */
export default function getPendingAccountsFromStore(): PendingAccountInfo[] {
    if (!initialLoadPromise) {
        initialLoadPromise = reloadStore();
    }

    return getPendingAccountStore().pendingAccounts;
}
