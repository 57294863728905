import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Function name: getGroupsAppIsOptedIn
 * Description: Gets the value of the GroupsAppIsOptedIn flag from the user configuration.
 * @param mailboxInfo: MailboxInfo
 */
export default function getGroupsAppIsOptedIn(mailboxInfo: MailboxInfo): undefined | boolean {
    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    return userConfiguration?.ViewStateConfiguration?.GroupsAppIsOptedIn;
}
