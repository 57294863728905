import { logStartUsage } from 'owa-analytics-start';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';

/**
 * The persistence id prefix that is used for the default account in OWA
 */
const defaultAccountPersistenceIdPrefix = 'DEFDEFDE-FDEF-DEFD-EFDE-FDEFDEFDEFDE';

/**
 * OWA can have accounts open in multiple tabs, we need to make sure that we have
 * a unique persistence id for each tab. We use the user's puid and tenant guid, or
 * email address (when puid and tenant id is not available) to create a unique
 * persistence id suffix.
 * @param userConfiguration The owa user configuration associated with the default account
 */
export function getPersistenceIdForDefaultAccount(userConfiguration: OwaUserConfiguration): string {
    const tenant = userConfiguration.SessionSettings?.ExternalDirectoryTenantGuid;
    const puid = userConfiguration.SessionSettings?.UserPuid;
    const mailboxGuid = userConfiguration.SessionSettings?.MailboxGuid;
    if (!!userConfiguration?.SessionSettings?.IsExplicitLogon) {
        if (!!tenant && !!mailboxGuid) {
            // explicit logon accounts can return the same PUID as the user account, in this
            // case we want to use the mailbox guid to differentiate the accounts
            return `${defaultAccountPersistenceIdPrefix}-${mailboxGuid}@${tenant}`;
        }

        // We did not get the suffix using the PUID and tenant guid, we want to log that
        logStartUsage('InitOwaAccount-MissingMbxGuidOrTenantGuid', {
            tenantType: typeof tenant,
            mbxGuidType: typeof mailboxGuid,
            puidType: typeof puid,
        });
    }

    // Because we are in OWA we know we only connect to M365 sources and can used the tenant
    // guid and user PUID to identity the account. This will be stable with the possible exception
    // of a cloud cache account that is deleted and recreated, which should be infrequent and
    // we can tolerate those id changing sometimes.
    if (!!tenant && !!puid) {
        return `${defaultAccountPersistenceIdPrefix}-${puid}@${tenant}`;
    }

    // Fallback to using the user email address
    const userEmailAddress = userConfiguration.SessionSettings?.UserEmailAddress;
    const hasSuffix = !!userEmailAddress;

    // We did not get the suffix using the PUID and tenant guid, we want to log that
    logStartUsage('InitOwaAccount-MissingPuidOrTenantGuid', {
        hasSuffix,
        tenantType: typeof tenant,
        puidType: typeof puid,
        emailType: typeof userEmailAddress,
    });

    return hasSuffix
        ? `${defaultAccountPersistenceIdPrefix}-${userEmailAddress}`
        : defaultAccountPersistenceIdPrefix;
}
