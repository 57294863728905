import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import getIsCurrentThemeModernImage from 'owa-theme/lib/selectors/getIsCurrentThemeModernImage';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const bleedThroughCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return getIsCurrentThemeModernImage();
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabled('fwk-bleed-through-themes');
    },
};
