import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { getStore } from '../store/store';

export default function folderIdToName(folderId: string): DistinguishedFolderIdName {
    const store = getStore();

    if (!folderId) {
        return 'none';
    }
    return <DistinguishedFolderIdName>store.defaultFolderIdToNameMap?.get(folderId) || 'none';
}
