import { createLazyResolver } from 'owa-lazy-resolver';
import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarEventsQueryWeb" */ './lazyIndex')
);

export const lazyCalendarEventsQueryWeb = createLazyResolver(
    'CALENDAREVENTSRANGE_QUERY_WEB',
    lazyModule,
    m => m.calendarEventsQueryWeb
);

export const lazyCalendarEventQueryWeb = createLazyResolver(
    'CALENDAREVENT_QUERY_WEB',
    lazyModule,
    m => m.calendarEventQueryWeb
);

export const lazyGetEventsForAnyCalendarType = new LazyAction(
    lazyModule,
    m => m.getEventsForAnyCalendarType
);
