import MailInboxRegular from 'owa-fluent-icons-svg/lib/icons/MailInboxRegular';
import DraftsRegular from 'owa-fluent-icons-svg/lib/icons/DraftsRegular';
import FolderRegular from 'owa-fluent-icons-svg/lib/icons/FolderRegular';
import SendRegular from 'owa-fluent-icons-svg/lib/icons/SendRegular';
import DeleteRegular from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import ArchiveRegular from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import FolderProhibitedRegular from 'owa-fluent-icons-svg/lib/icons/FolderProhibitedRegular';
import NoteRegular from 'owa-fluent-icons-svg/lib/icons/NoteRegular';
import ClockRegular from 'owa-fluent-icons-svg/lib/icons/ClockRegular';
import FolderSearchRegular from 'owa-fluent-icons-svg/lib/icons/FolderSearchRegular';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import MailInboxArrowUpRegular from 'owa-fluent-icons-svg/lib/icons/MailInboxArrowUpRegular';

/**
 * Get custom icon for special folders like inbox, drafts, sent, deleted, etc.
 * @param showNonSpecialFolderIcon Whether to show generic folder icon
 */
function getCustomIcon(folderId: string, showNonSpecialFolderIcon: boolean): string {
    const folderName = folderId && folderIdToName(folderId);
    switch (folderName) {
        case 'inbox':
            return MailInboxRegular;

        case 'sentitems':
            return SendRegular;

        case 'drafts':
            return DraftsRegular;

        case 'deleteditems':
            return DeleteRegular;

        case 'archive':
            return ArchiveRegular;

        case 'junkemail':
            return FolderProhibitedRegular;

        case 'scheduled':
            return ClockRegular;

        case 'notes':
            return NoteRegular;

        case 'outbox':
            return MailInboxArrowUpRegular;

        case SEARCH_FOLDER_ROOT_DISTINGUISHED_ID:
            return FolderSearchRegular;

        default:
            break;
    }

    if (showNonSpecialFolderIcon) {
        return FolderRegular;
    }

    return '';
}

export default getCustomIcon;
