import type { OwaPalette } from 'owa-theme-shared';
import type { BrandVariants } from '@fluentui/react-components';
import { fetchThemeFile, ThemeConstants } from 'owa-theme-shared';
import { isTeamsThemeId } from 'owa-teams-theme-utils';
import { getThemeDataName } from '../utils/getThemeDataName';
import loadTheme from '../actions/loadTheme';
import { loadThemeBackgroundImage } from '../actions/loadThemeBackgroundImage';
import { normalizeThemeRequest } from '../utils/normalizeThemeId';
import { setItem } from 'owa-local-storage';
import { store } from '../store/store';
import { addThemeData } from '../mutators/addThemeData';
import { mergeThemePalette } from '../mutators/mergeThemePalette';
import setUseSystemDarkModeSettings from '../mutators/setUseSystemDarkModeSettings';
import { registerOnSystemDarkModeChangeListener } from '../utils/registerOnSystemDarkModeChangeListener';
import { isFeatureEnabled } from 'owa-feature-flags';
import getIsCurrentThemeModernImage from '../selectors/getIsCurrentThemeModernImage';

export async function loadInitialTheme(
    themeStorageId: string | undefined,
    darkMode: boolean,
    themesAllowed: boolean,
    useSystemDarkModeSettings: boolean,
    palette?: OwaPalette,
    brandVariants?: BrandVariants
) {
    const { themeId, isDarkTheme } = normalizeThemeRequest(themeStorageId, darkMode, themesAllowed);
    const themeDataName = getThemeDataName(themeId, isDarkTheme);

    setItem(self, ThemeConstants.LOCAL_STORAGE_KEY, themeDataName);

    let themeResources = await fetchThemeFile(themeDataName, 'sd');

    if (themeResources === null) {
        const baseResource =
            // fall back to using the preloaded dark or base themes, if available.
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            store.themeData.get(
                isDarkTheme ? ThemeConstants.BASE_DARK_THEME_ID : ThemeConstants.BASE_THEME_ID
            )!;

        // If the theme is a Teams theme, we only want to copy the themePalette from the base theme
        themeResources = isTeamsThemeId(themeDataName)
            ? { themePalette: { ...baseResource.themePalette } }
            : baseResource;
    }

    if (palette) {
        const themeResourcesFromStore = store.themeData.get(themeDataName);
        mergeThemePalette(
            themeResourcesFromStore ? themeResourcesFromStore : themeResources,
            palette
        );
    }

    if (isFeatureEnabled('fwk-fluent-v9-color-ramps') && brandVariants) {
        themeResources.brandVariants = brandVariants;
    }

    // When using a modern theme that includes images, it is essential to also retrieve the inverted theme resources.
    // This is crucial since these themes contain significant elements such as images and background fallback colours.
    if (getIsCurrentThemeModernImage(themeId)) {
        const themeDataNameInverted = getThemeDataName(themeId, !isDarkTheme);
        const themeResourcesInverted = await fetchThemeFile(themeDataNameInverted, 'sd');

        if (themeResourcesInverted !== null) {
            addThemeData(themeDataNameInverted, themeResourcesInverted);
        }
    }

    addThemeData(themeDataName, themeResources);
    loadThemeBackgroundImage(themeResources);
    loadTheme(themeId, isDarkTheme, themesAllowed);
    setUseSystemDarkModeSettings(useSystemDarkModeSettings);
    registerOnSystemDarkModeChangeListener();
}
