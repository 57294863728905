import { getComputedIsEverythingSelectedInTable } from 'owa-mail-list-store';
import loc, { format } from 'owa-localize';

import Archive from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import ArchiveSettings from 'owa-fluent-icons-svg/lib/icons/ArchiveSettingsRegular';
import ArrowForward from 'owa-fluent-icons-svg/lib/icons/ArrowForwardRegular';
import ArrowReply from 'owa-fluent-icons-svg/lib/icons/ArrowReplyRegular';
import ArrowReplyAll from 'owa-fluent-icons-svg/lib/icons/ArrowReplyAllRegular';
import CalendarReply from 'owa-fluent-icons-svg/lib/icons/CalendarReplyRegular';
import CalendarSparkleRegular from 'owa-fluent-icons-svg/lib/icons/CalendarSparkleRegular';
import CopyArrowRightRegular from 'owa-fluent-icons-svg/lib/icons/CopyArrowRightRegular';
import type { ControlInfo } from './controlInfo';
import Delete from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import DeleteStrings from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { messageRecall } from 'owa-locstrings/lib/strings/messagerecall.locstring.json';
import Flag from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import FolderArrowRight from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import FolderMail from 'owa-fluent-icons-svg/lib/icons/FolderMailRegular';
import ImmersiveReader from 'owa-fluent-icons-svg/lib/icons/ImmersiveReaderRegular';
import JunkStrings from 'owa-locstrings/lib/strings/junk.locstring.json';
import MailAttachRegular from 'owa-fluent-icons-svg/lib/icons/MailAttachRegular';
import MailCheckmarkRegular from 'owa-fluent-icons-svg/lib/icons/MailCheckmarkRegular';
import MailRead from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import MailReadMultipleRegular from 'owa-fluent-icons-svg/lib/icons/MailReadMultipleRegular';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { MailboxInfo } from 'owa-client-types';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import NeverBlockSenderStrings from 'owa-locstrings/lib/strings/neverblocksender.locstring.json';
import NotJunkStrings from 'owa-locstrings/lib/strings/notjunk.locstring.json';
import PersonAvailableRegular from 'owa-fluent-icons-svg/lib/icons/PersonAvailableRegular';
import PersonProhibitedRegular from 'owa-fluent-icons-svg/lib/icons/PersonProhibitedRegular';
import Print from 'owa-fluent-icons-svg/lib/icons/PrintRegular';
import Prohibited from 'owa-fluent-icons-svg/lib/icons/ProhibitedRegular';
import ReportJunkStrings from 'owa-locstrings/lib/strings/reportjunk.locstring.json';
import ReportPhishingStrings from 'owa-locstrings/lib/strings/reportphishing.locstring.json';
import type {
    RibbonControlDefinitionProps,
    ReadOnlyRibbonControlDefProps,
} from './RibbonControlDefinitionProps';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getHoverTooltip } from './HoverTooltips/hoverTooltipConfig';
import Settings from 'owa-fluent-icons-svg/lib/icons/SettingsRegular';
import ShieldErrorRegular from 'owa-fluent-icons-svg/lib/icons/ShieldErrorRegular';
import SplitButtonStrings from './ribbon_mail_split_buttons.locstring.json';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import StringsToolTip from './HoverTooltips/hoverTooltipDesc.locstring.json';
import Tag from 'owa-fluent-icons-svg/lib/icons/TagRegular';
import MailRewindRegular from 'owa-fluent-icons-svg/lib/icons/MailRewindRegular';
import { owaComputedFn } from 'owa-computed-fn';
import { getComputedEmptyFolderText } from 'owa-mail-list-store/lib/utils/getEmptyFolderText';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getRibbonMailboxInfo } from './getRibbonMailboxInfo';
import isReplyAllTheDefaultResponse from 'owa-mail-reading-pane-store/lib/utils/isReplyAllTheDefaultResponse';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import ZoomInRegular from 'owa-fluent-icons-svg/lib/icons/ZoomInRegular';
import { isMultiLineRibbon } from 'owa-command-ribbon/lib/util/isMultiLineRibbon';
import EyeFilled from 'owa-fluent-icons-svg/lib/icons/EyeFilled';
import { trackReadReceipts } from 'owa-track-read-receipts/lib/strings/trackReadReceipts.locstring.json';
import ChatRegular from 'owa-fluent-icons-svg/lib/icons/ChatRegular';
import { TeamsColor } from 'owa-ribbon-brand-icons/lib/icons/TeamsColor';
import RibbonMailStrings from './ribbon_mail_strings.locstring.json';
import { getItem } from './getItem';
import { isItemInFolder } from './isItemInFolder';
import type { ClientItem } from 'owa-mail-store';
import type { ClientItemId } from 'owa-client-ids';
import { resend } from 'owa-message-resend/lib/strings/resend.locstring.json';
import MailArrowClockwiseRegular from 'owa-fluent-icons-svg/lib/icons/MailArrowClockwiseRegular';
import AppFolderRegular from 'owa-fluent-icons-svg/lib/icons/AppFolderRegular';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';

/**
 * Retrieves the display information pertaining to a given mail ribbon
 * control
 * @param controlId for which to retrieve the display information for
 * @param props the definition props
 * @returns display information for passed in control
 */
export function getSharedControlInfo(
    controlId: RibbonControlId,
    props: RibbonControlDefinitionProps
): ControlInfo {
    const mailboxInfo = getRibbonMailboxInfo(props);
    switch (controlId) {
        //home tab controls
        case 679:
        case 556: {
            const controlName: string = loc(Strings.replyToEmail_Button);

            return {
                iconName: ArrowReply,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 519: {
            let controlName: string;
            let tooltip: string | undefined;
            if (props.isPopout) {
                controlName = loc(DeleteStrings.deleteItem);
                tooltip = getHoverTooltip(controlId, controlName);
            } else {
                const isEverythingSelectedInTable = getComputedIsEverythingSelectedInTable();
                if (isEverythingSelectedInTable || getMailMenuItemShouldShow(13, mailboxInfo)) {
                    controlName = getComputedEmptyFolderText();
                    tooltip = getHoverTooltip(controlId, controlName, {
                        description: loc(StringsToolTip.deleteEmptyFolderButtonDesc),
                    });
                } else {
                    controlName = loc(DeleteStrings.deleteItem);
                    tooltip = getHoverTooltip(controlId, controlName);
                }
            }

            const controlSplitBtn: string = loc(SplitButtonStrings.delete_SplitButton);

            return {
                iconName: Delete,
                text: controlName,
                ariaText: controlSplitBtn,
                tooltip,
                hasChevron: true,
            };
        }

        case 505: {
            const controlName: string = loc(Strings.archiveEmail_Button);

            return {
                iconName: Archive,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 535: {
            // Determine if current folder is other than Junk
            const itemId: ClientItemId | undefined = (props as ReadOnlyRibbonControlDefProps)
                ?.itemId;
            const item: ClientItem | undefined = getItem(itemId);

            const notIsJunkFolder = props.isPopout
                ? !item || !isItemInFolder(item, ['junkemail'])
                : getMailMenuItemShouldShow(24, mailboxInfo) ||
                  !getMailMenuItemShouldShow(55, mailboxInfo);

            // If we are in the Junk Folder (i.e. notIsJunkFolder is false) the label "Not junk" will be shown.
            const controlName: string = notIsJunkFolder
                ? loc(ReportJunkStrings.reportJunk)
                : loc(Strings.notJunk_Button);

            let iconName;
            let tooltip;

            if (notIsJunkFolder) {
                iconName = Prohibited;
                tooltip = getHoverTooltip(535, controlName);
                if (tooltip) {
                    tooltip = format(tooltip, loc(JunkStrings.junk).toLowerCase());
                }
            } else {
                iconName = MailCheckmarkRegular;

                tooltip = format(
                    `${loc(Strings.notJunk_Button)}\n\n${loc(StringsToolTip.junkButtonDesc)}`,
                    loc(NotJunkStrings.notJunk).toLowerCase()
                );
            }

            return {
                iconName,
                text: controlName,
                ariaText: undefined,
                tooltip,
                hasChevron: false,
            };
        }

        case 657: {
            const isCloudCache =
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Adding IsShadowMailbox to restricted properties/methods.
                 *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
                !!getAccountScopeUserSettings(mailboxInfo)?.SessionSettings?.IsShadowMailbox;

            const controlName: string = loc(Strings.report_Button);

            let tooltip = isCloudCache
                ? getHoverTooltip(535, controlName)
                : getHoverTooltip(657, controlName);

            if (tooltip) {
                tooltip = format(tooltip, loc(JunkStrings.junk).toLowerCase());
            }

            const reportControlAria: string = loc(SplitButtonStrings.report_SplitButton);

            return {
                iconName: ShieldErrorRegular,
                text: controlName,
                ariaText: reportControlAria,
                tooltip,
                hasChevron: true,
            };
        }

        case 547: {
            const controlName: string = loc(ReportPhishingStrings.reportPhishing);
            const tooltip = getHoverTooltip(controlId, controlName);
            return {
                iconName: ShieldErrorRegular,
                text: controlName,
                ariaText: undefined,
                tooltip,
                hasChevron: false,
            };
        }

        case 507: {
            const controlName: string = loc(Strings.blockSender_Button);

            return {
                iconName: PersonProhibitedRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 658: {
            const controlName: string = loc(Strings.block_Button);
            const blockControlAria: string = loc(SplitButtonStrings.block_SplitButton);

            return {
                iconName: PersonProhibitedRegular,
                text: controlName,
                ariaText: blockControlAria,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 659: {
            const controlName: string = loc(NeverBlockSenderStrings.neverBlockSender);

            return {
                iconName: PersonAvailableRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 559: {
            const controlName: string = loc(Strings.rules_FlyoutButton);

            return {
                iconName: FolderMail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 557:
        case 681: {
            const controlName: string = loc(Strings.replyAllToEmail_Button);

            return {
                iconName: ArrowReplyAll,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 678: {
            const isReplyAll = isReplyAllTheDefaultResponse();
            const controlSplitBtn: string = loc(SplitButtonStrings.respond_SplitButton);

            return {
                iconName: isReplyAll ? ArrowReplyAll : ArrowReply,
                text: isReplyAll
                    ? loc(Strings.replyAllToEmail_Button)
                    : loc(Strings.replyToEmail_Button),
                ariaText: controlSplitBtn,
                tooltip: isReplyAll
                    ? getHoverTooltip(557, loc(Strings.replyAllToEmail_Button))
                    : getHoverTooltip(556, loc(Strings.replyToEmail_Button)),
                hasChevron: true,
            };
        }

        case 555:
        case 680: {
            const controlName: string = loc(Strings.replyForwardEmail_Button);
            const isRespondForwardControl = controlId === 555;
            const controlSplitBtn: string = loc(SplitButtonStrings.respondForward_SplitButton);

            return {
                iconName: ArrowForward,
                text: controlName,
                ariaText: isRespondForwardControl ? controlSplitBtn : undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: isRespondForwardControl,
            };
        }
        case 651: {
            const controlName: string = loc(Strings.replyForwardEmailAsAttachment_Button);

            return {
                iconName: MailAttachRegular,
                text: controlName,
                ariaText: controlName,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 700:
        case 553: {
            const controlName: string = loc(Strings.replyMeetingToEmail_Button);

            const tooltipText = getHoverTooltip(controlId, controlName);

            return {
                iconName: CalendarReply,
                text: controlName,
                ariaText: undefined,
                tooltip: tooltipText,
                hasChevron: false,
            };
        }

        case 699:
        case 698: {
            const controlName: string = loc(Strings.replyCopilotMeetingToEmail_Button);
            const tooltipText = `${loc(Strings.replyCopilotMeetingToEmail_Button)}\n\n${loc(
                Strings.replyCopilotMeetingToEmail_Button_Tooltip
            )}`;
            const ariaText: string = loc(SplitButtonStrings.replyCopilotMeetingToEmail_SplitButton);
            return {
                iconName: CalendarSparkleRegular,
                text: controlName,
                ariaText,
                tooltip: tooltipText,
                hasChevron: true,
            };
        }

        case 696: {
            const controlName: string = loc(Strings.replyCopilotMeetingToEmail_Button);
            const tooltipText = `${loc(Strings.replyCopilotMeetingToEmail_Button)}\n\n${loc(
                Strings.replyCopilotMeetingToEmail_Button_Tooltip
            )}`;
            return {
                iconName: CalendarSparkleRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: tooltipText,
                hasChevron: false,
            };
        }

        case 552: {
            let controlName: string;
            let iconName: string;
            let tooltip: string | undefined;

            if (!props.isPopout && shouldShowMarkAllAsRead(mailboxInfo)) {
                controlName = loc(Strings.markAllAsRead_Button);
                iconName = MailReadMultipleRegular;
                tooltip = `${loc(Strings.markAllAsRead_Button)}\n\n${loc(
                    StringsToolTip.markAllAsReadDesc
                )}`;
            } else {
                controlName = loc(Strings.readUnread_Button);
                iconName = MailRead;
                tooltip = getHoverTooltip(controlId, controlName);
            }

            return {
                iconName,
                text: controlName,
                ariaText: undefined,
                tooltip,
                hasChevron: false,
            };
        }

        case 509: {
            const controlName: string = loc(Strings.categorize_FlyoutButton);

            return {
                iconName: Tag,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 516: {
            const controlName: string = loc(Strings.createRule_Button);

            return {
                iconName: FolderMail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 537: {
            const controlName: string = loc(Strings.manageRules_Button);

            return {
                iconName: Settings,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        //shared controls
        case 549: {
            const controlName: string = loc(Strings.printMail_Button);

            return {
                iconName: Print,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 532: {
            const controlName: string = loc(Strings.immersiveReader_Button);

            return {
                iconName: ImmersiveReader,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 616: {
            const controlName = loc(Strings.assignPolicy_FlyoutButton);

            const controlText = isMultiLineRibbon()
                ? loc(Strings.assignPolicy_FlyoutButton_MLR)
                : controlName;

            return {
                iconName: ArchiveSettings,
                text: controlText,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 539: {
            const controlName: string = loc(Strings.moreRetentionPoliciesOptions_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 604: {
            const controlName: string = loc(Strings.flagClearFlag_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 598: {
            const controlName: string = loc(Strings.flagCompleteToday_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 599: {
            const controlName: string = loc(Strings.flagCompleteTomorrow_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 600: {
            const controlName: string = loc(Strings.flagCompleteThisWeek_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 601: {
            const controlName: string = loc(Strings.flagCompleteNextWeek_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 602: {
            const controlName: string = loc(Strings.flagCompleteNoDate_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 603: {
            const controlName: string = loc(Strings.flagMarkComplete_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 704: {
            const controlName: string = loc(Strings.flagCompleteCustom_Button);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 527: {
            const controlName = loc(Strings.flagUnflag_Button);
            const controlSplitBtn = loc(SplitButtonStrings.flagUnflag_SplitButton);

            const controlText = isMultiLineRibbon()
                ? loc(Strings.flagUnflag_Button_MLR)
                : controlName;

            return {
                iconName: Flag,
                text: controlText,
                ariaText: controlSplitBtn,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 540: {
            const controlName = loc(Strings.move_FlyoutButton);

            const controlText = isMultiLineRibbon()
                ? loc(Strings.move_FlyoutButton_MLR)
                : controlName;

            return {
                iconName: FolderArrowRight,
                text: controlText,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 686: {
            const controlName: string = loc(messageRecall);

            return {
                iconName: MailRewindRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 688: {
            const controlName: string = loc(Strings.zoom_Button);

            return {
                iconName: ZoomInRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 697: {
            const controlName: string = loc(trackReadReceipts);

            return {
                iconName: EyeFilled,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 702: {
            const controlName: string = loc(RibbonMailStrings.teams_ChatAroundEmailButton);
            const controlSplitBtn: string = loc(RibbonMailStrings.teams_ChatAroundEmailSplitButton);

            return {
                iconName: ChatRegular,
                text: controlName,
                ariaText: controlSplitBtn,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 703: {
            const controlName: string = loc(RibbonMailStrings.teams_ChatAroundEmailAllButton);

            return {
                iconName: ChatRegular,
                text: controlName,
                ariaText: controlName,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 709: {
            const controlName: string = loc(RibbonMailStrings.teams_ShareToTeamsChatButton);

            return {
                iconName: TeamsColor,
                text: controlName,
                ariaText: controlName,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 705: {
            const controlName: string = loc(RibbonMailStrings.copyToButton);

            return {
                iconName: CopyArrowRightRegular,
                text: controlName,
                ariaText: controlName,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 712: {
            const controlName: string = loc(resend);

            return {
                iconName: MailArrowClockwiseRegular,
                text: controlName,
                ariaText: controlName,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 7016: {
            const controlName: string = loc(Strings.getMessageExtensions_Button);

            return {
                iconName: AppFolderRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(
                'The ribbon id ' +
                    controlId +
                    ' was not added to the switch case in getSharedControlInfo.ts'
            );
    }
}

const shouldShowMarkAllAsRead = owaComputedFn(function shouldShowMarkAllAsRead(
    mailboxInfo: MailboxInfo
) {
    return getMailMenuItemShouldShow(23, mailboxInfo);
});
