import { LazyModule } from 'owa-bundling-light';
import type { OneJs_LivePersonaEditorViewDataResolvers } from 'owa-graph-schema';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "LivePersonaEditorViewSchemaResolvers" */ './lazyIndex')
);

const lazyLivePersonaEditorConfiguration = createLazyResolver(
    'LIVE_PERSONA_EDITOR_CONFIGURATION',
    lazyModule,
    m => m.configuration
);

const lazyLivePersonaEditorExpandedViewModel = createLazyResolver(
    'LIVE_PERSONA_EDITOR_EXPANDED_VIEW_MODEL',
    lazyModule,
    m => m.expandedViewModel
);

const lazyLivePersonaEditorMiniViewModel = createLazyResolver(
    'LIVE_PERSONA_EDITOR_MINI_VIEW_MODEL',
    lazyModule,
    m => m.miniViewModel
);

export const OneJS_LivePersonaEditorViewData: OneJs_LivePersonaEditorViewDataResolvers = {
    configuration: lazyLivePersonaEditorConfiguration,
    expandedViewModel: lazyLivePersonaEditorExpandedViewModel,
    miniViewModel: lazyLivePersonaEditorMiniViewModel,
};
