import { Icon } from '@fluentui/react/lib/Icon';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getDensityModeCssClass, getIsDarkTheme } from 'owa-fabric-theme';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import React from 'react';
import { categorySizeFull, categorySizeMediumOrCompact } from '../scss/CategorySharedStyles.scss';
import getCategoryColorsForCategory, {
    getDefaultCategoryColor,
} from '../utils/getCategoryColorsForCategory';
import getMasterCategoryList from '../utils/getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';
import { lightColorCodeValueMap } from '../store/helpers/getLightThemeColorCodeValueMap';
import { darkColorCodeValueMap } from '../store/helpers/getDarkThemeColorCodeValueMap';
import { TagDuoToneIcon, getDuoToneIconStyles } from './TagDuoToneIcon';

export interface CategoryIconProps {
    categoryName?: string;
    categoryList?: CategoryType[];
    categoryColorId?: string;
    mailboxInfo?: MailboxInfo;
    iconClassName: string;
}

const CategoryIcon = observer(function CategoryIcon(props: CategoryIconProps) {
    let categoryColor;

    /**
     * Try to get category color from the category name
     * If the name is not specified (in case of new category creation) check if color id is specified and get color from the colorValueMap
     * Else use the default color
     */
    if (props.categoryName && props.mailboxInfo) {
        categoryColor = getCategoryColorsForCategory(
            props.categoryName,
            props.categoryList ? props.categoryList : getMasterCategoryList(props.mailboxInfo),
            props.mailboxInfo
        );
    } else if (props.categoryColorId) {
        categoryColor = getIsDarkTheme()
            ? darkColorCodeValueMap[props.categoryColorId]
            : lightColorCodeValueMap[props.categoryColorId];
    } else {
        categoryColor = getDefaultCategoryColor();
    }

    const getIconStyles = getDuoToneIconStyles(categoryColor);

    const iconClass = classnames(
        props.iconClassName,
        getDensityModeCssClass(
            categorySizeFull,
            categorySizeMediumOrCompact,
            categorySizeMediumOrCompact
        )
    );

    const iconFilledProperties = {
        iconName: TagDuoToneIcon,
        className: iconClass,
        styles: {
            root: getIconStyles,
        },
    };

    return <Icon {...iconFilledProperties} />;
}, 'CategoryIcon');
export default CategoryIcon;
