import { createLazyResolver } from 'owa-lazy-resolver';

export const lazySaveAutomaticRepliesConfigMutationWeb = createLazyResolver(
    'SAVEAUTOMATICREPLIESCONFIG_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "SaveAutomaticRepliesConfigMutationWeb" */ './saveAutomaticRepliesConfigMutationWeb'
        ),
    m => m.saveAutomaticRepliesConfigMutationWeb
);
