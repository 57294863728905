import { isFirstLevelExpanded } from '../selectors/isConversationExpanded';
import getListViewState from '../selectors/getListViewState';
import { mutatorAction } from 'satcheljs';
import type { ConversationFork } from 'owa-graph-schema';

/**
 * When user has a row selected and a new message arrives on the conversation,
 * the currently selected item should not change for the user on reading pane.
 * Therefore, this newly arrived message should be temporarily added as a fork on the conversation.
 */
export const addNodeAsFork = mutatorAction('addNodeAsFork', (rowKey: string, itemIds: string[]) => {
    const nodeId = itemIds.shift();
    if (isFirstLevelExpanded(rowKey) && nodeId) {
        const listViewState = getListViewState();

        const forks: ConversationFork[] | null = listViewState.expandedConversationViewState.forks;

        // If forks is null, then the conversation is collapsed and we don't need
        // to do anything.
        if (!forks) {
            return;
        }

        const forkIds = forks.map(fork => fork.id);
        if (forkIds.length <= 1) {
            // There is a single thread. We need to add the existing thread and the new incoming one as separate forks
            const selectedNodeId = itemIds[0];
            forks.unshift({
                id: selectedNodeId,
                displayNames: [], // unique senders on the conversation,
                ancestorIds: itemIds, // all nodes
            });
            // Also this should stay selected
            listViewState.expandedConversationViewState.selectedNodeIds.push(selectedNodeId);
        }
        // If the node doesn't already exist, add it to the forks
        // TODO: rationalize if node ids are in different formats EWS vs immutableId
        if (forkIds.indexOf(nodeId) == -1) {
            // Since it is a temporary fork, it is shown as a single message with no ancestors and a single sender
            forks.unshift({
                id: nodeId,
                displayNames: [], // MailListItemPart handles this as we get this from the item corresponding to the nodeId
                ancestorIds: [nodeId], // Since it is a temporary fork, it is shown as a single message
            });
        }
    }
});
