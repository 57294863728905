import type {
    MutationRenameCalendarArgs,
    ResolverContext,
    CalendarEntry,
    RenameCalendarMutationWeb_CalendarEntry,
    CalendarsUnionType,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';
import renameCalendarOperation from 'owa-service/lib/operation/renameCalendarOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import itemId from 'owa-service/lib/factory/itemId';
import type CalendarActionFolderIdResponse from 'owa-service/lib/contract/CalendarActionFolderIdResponse';

/**
 * Mutation Web resolver responsible for renameCalendar
 */
export const renameCalendarMutationWeb = async (
    _parent: any,
    args: MutationRenameCalendarArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarEntry> => {
    const { calendarId, newCalendarName, calendarTypename } = args.input;

    const response: CalendarActionFolderIdResponse = await renameCalendarOperation(
        {
            itemId: itemId({
                Id: calendarId.id,
                ChangeKey: calendarId.changeKey ?? undefined,
            }),
            newCalendarName,
        },
        getMailboxRequestOptions(calendarId.mailboxInfo)
    );

    // response.WasSuccessful should be enough but there's a bug in ecosystem where a failed rename call will
    // still return wasSuccessful=true, bug 378078
    const successfulButErrorExists = response.WasSuccessful && response.ErrorCode !== 0;
    if (response == null || !response.WasSuccessful || successfulButErrorExists) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `renameCalendarMutationWeb: failed to rename calendar. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }

    const partialCalendarEntry: RenameCalendarMutationWeb_CalendarEntry = {
        __typename: calendarTypename as CalendarsUnionType['__typename'],
        calendarId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            id: response!.NewCalendarEntryId!.Id,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            changeKey: response!.NewCalendarEntryId!.ChangeKey,
        },
        FolderId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            Id: response!.NewFolderId!.Id,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            ChangeKey: response!.NewFolderId!.ChangeKey,
        },
    };

    return partialCast<CalendarEntry>(partialCalendarEntry);
};

export default renameCalendarMutationWeb;
