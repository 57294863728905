import {
    firstLineTextUnread,
    firstLineTextRead,
} from 'owa-mail-listitem-styles/lib/scss/MailListItemFirstLine.scss';

/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { MailListItemTypePlaceholder } from '../index';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { highlightTermsInHtmlElement } from 'owa-highlight';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import React from 'react';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';

export interface MailListItemFirstLineTextProps {
    highlightTerms: string[];
    showDraftsIndicator: boolean;
    isInOutboxFolder: boolean;
    unreadCount: number;
    text: string;
    tooltipText: string;
    isSingleLineView?: boolean;
    highlightAccuracy?: Accuracy;
}

export default observer(function MailListItemFirstLineText(props: MailListItemFirstLineTextProps) {
    const {
        highlightTerms: _highlightTerms,
        highlightAccuracy,
        showDraftsIndicator,
        isInOutboxFolder,
        text,
        tooltipText,
        unreadCount,
        isSingleLineView = false, // Special case is for SLV, so assume false by default and require caller to opt in
    } = props;

    const firstLineTextClasses = classnames(
        unreadCount > 0 ? firstLineTextUnread : firstLineTextRead,
        useMessageListTextStyle('Major', true /* isFirstLine */, isSingleLineView)
    );

    const highlightTerms = React.useCallback(
        (element: HTMLElement) => {
            highlightTermsInHtmlElement(
                element,
                _highlightTerms,
                false /* separateWordSearch */,
                highlightAccuracy /* highlightAccuracy */
            );
        },
        [_highlightTerms]
    );

    return (
        <div className={firstLineTextClasses}>
            {showDraftsIndicator && <MailListItemTypePlaceholder messageType={'Draft'} />}
            {!showDraftsIndicator && isInOutboxFolder && (
                <MailListItemTypePlaceholder messageType={'Queued'} />
            )}
            <span title={tooltipText} ref={highlightTerms}>
                {text}
            </span>
        </div>
    );
}, 'MailListItemFirstLineText');
