import type TableView from '../store/schema/TableView';
import { getUserConfiguration } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export default function shouldFirstRowBeSelectedOnLoad(tableView: TableView) {
    // First row should only be selected on load when the user option is set
    // and the user is not in the junk folder (don't want to accidentally open
    // malicious email). Also, if OWA policy setting MessagePreviewsDisabled
    // is set to true, disable selecting first row on load.
    const userConfig = getUserConfiguration();
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2532 (14,37): Object is possibly 'undefined'.
    // @ts-expect-error
    const disabledByPolicySetting = userConfig.PolicySettings.MessagePreviewsDisabled == true;
    return (
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (19,9): Object is possibly 'undefined'.
        // @ts-expect-error
        userConfig.UserOptions.ShowReadingPaneOnFirstLoad &&
        !disabledByPolicySetting &&
        tableView?.tableQuery?.folderId &&
        folderIdToName(tableView.tableQuery.folderId) !== 'junkemail'
    );
}
