import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "WebpushNotifications" */ './lazyIndex')
);

export const lazyUserInitiatedWebPushSetupWorkflow = new LazyImport(
    lazyModule,
    m => m.userInitiatedWebPushSetupWorkflow
);

export const lazyUserInitiatedWebPushDisableWorkflow = new LazyImport(
    lazyModule,
    m => m.userInitiatedWebPushDisableWorkflow
);

export const lazyLoadWebPushOptions = new LazyImport(lazyModule, m => m.loadWebPushOptions);

export const WebPushDiscovery = createLazyComponent(lazyModule, m => m.WebPushDiscovery);
