import {
    onMessageExtensionTrigger,
    onAddinTrigger,
    onAppBarTrigger,
    onOpenAboutDialog,
    onOpenAppStoreAddIns,
    onOpenAppStoreLaunchPage,
    onOpenAppStoreMessageExtension,
    onSettingsContextMenuTrigger,
    onUninstallMetaOsApp,
    onMessageExtensionAddinsTrigger,
    onPinTaskbarShortcutTrigger,
    onOpenLPC,
    onFlyoutButtonHover,
    onPinMessageExtensionTrigger,
    onOpenLivePersonaEditor,
    onLivePersonaEditorInternal,
    onOpenPersonSources,
    onPersonSourcesInternal,
    onInitializeLPC,
    onImportContacts,
    onExportContacts,
    onStartCallViaSipProtocol,
    onStartChatViaSipProtocol,
    onShowToast,
} from 'owa-nova-actions';
import { isLivePersonaEditorEvent as isLivePersonaEditorInternalEvent } from '@1js/lpe-hostapp-loader';
import { isPersonSourcesEvent as isPersonSourcesInternalEvent } from '@1js/person-sources-hostapp-loader';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { NovaEventTypes } from 'owa-nova-actions/lib/types';
import { LaunchPageAppId, AddInsAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { PlatformType } from 'owa-graph-schema';
import type { NovaEventUnion } from 'owa-nova-actions/lib/types';

type UnionOfArray<T> = T extends ArrayLike<infer E> ? E : never;

const StoreIds = [
    LaunchPageAppId.AppStore,
    LaunchPageAppId.AppStoreModal,
    AddInsAppId.ClientStore,
] as const;

function resolveLaunchEvent(platformType: PlatformType | undefined) {
    switch (platformType) {
        case 'm365_app':
        case 'm365_native_app':
        case 'outlook_app':
            return onAppBarTrigger;
        case 'm365_message_extension':
            return onMessageExtensionTrigger;
        case 'm365_message_extension_addin':
            return onMessageExtensionAddinsTrigger;
        case 'm365_addin':
        case 'm365_addin_subcommands':
            return onAddinTrigger;
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(`[owa-nova] Unknown platformType ${platformType}`);
}

function resolveAppAcquisitionLinkEvent(appId: UnionOfArray<typeof StoreIds> | undefined) {
    const moduleMailboxInfo = isAddinMultiAccountEnabled()
        ? getModuleContextMailboxInfo()
        : undefined;

    switch (appId) {
        case LaunchPageAppId.AppStore:
            return onOpenAppStoreLaunchPage;
        case LaunchPageAppId.AppStoreModal:
            return isMOS3AppServiceAvailable(moduleMailboxInfo) &&
                isFeatureEnabled('mos-inContextStore')
                ? onOpenAppStoreMessageExtension
                : onOpenAppStoreLaunchPage;
        case AddInsAppId.ClientStore:
            return isMOS3AppServiceAvailable(moduleMailboxInfo) &&
                isFeatureEnabled('mos-inContextStore')
                ? onOpenAppStoreMessageExtension
                : onOpenAppStoreAddIns;
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(
        `[owa-nova] Tried to resolve an appAcquisitionLink that is not an App Store: ${appId}`
    );
}

function notImplementedEvent(event: NovaEventUnion) {
    return {
        owaType: 'notImplemented',
        data: () => event,
    };
}

export function parseNovaEventToOwaAction(event: NovaEventUnion) {
    if (isLivePersonaEditorInternalEvent(event)) {
        return onLivePersonaEditorInternal;
    } else if (isPersonSourcesInternalEvent(event)) {
        return onPersonSourcesInternal;
    }

    switch (event.type) {
        case NovaEventTypes.launch:
            const tileData = event.data?.();
            return resolveLaunchEvent(tileData?.platformType as PlatformType);
        case NovaEventTypes.appAcquisitionLinkClick:
            const appId = event.data?.().linkID;
            return resolveAppAcquisitionLinkEvent(appId as UnionOfArray<typeof StoreIds>);
        case NovaEventTypes.moreInfo:
            return onOpenAboutDialog;
        case NovaEventTypes.uninstall:
            return onUninstallMetaOsApp;
        case NovaEventTypes.settings:
            return onSettingsContextMenuTrigger;
        case NovaEventTypes.pinTaskbarShortcut:
            return onPinTaskbarShortcutTrigger;
        case NovaEventTypes.openLPC:
            return onOpenLPC;
        case NovaEventTypes.initializeLPC:
            return onInitializeLPC;
        case NovaEventTypes.flyoutButtonHover:
            return onFlyoutButtonHover;
        case NovaEventTypes.pinMessageExtension:
            return onPinMessageExtensionTrigger;
        case NovaEventTypes.openLivePersonaEditor:
            return onOpenLivePersonaEditor;
        case NovaEventTypes.openPersonSources:
            return onOpenPersonSources;
        case NovaEventTypes.startCallViaSipProtocol:
            return onStartCallViaSipProtocol;
        case NovaEventTypes.startChatViaSipProtocol:
            return onStartChatViaSipProtocol;
        case NovaEventTypes.importContacts:
            return onImportContacts;
        case NovaEventTypes.exportContacts:
            return onExportContacts;
        case NovaEventTypes.showToast:
            return onShowToast;
        case NovaEventTypes.flyoutOpened:
        case NovaEventTypes.appBarImpression:
        default:
            // TODO 171322 MOS3 Instrumentation: Add support for flyoutOpened, appBarImpression and renderError
            return notImplementedEvent;
    }
}
