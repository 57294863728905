import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getPrimaryFolderTreeRootFolder from '../selectors/getPrimaryFolderTreeRootFolder';
import getFolderTable from '../selectors/getFolderTable';
import getArchiveFolderTreeRootFolder from '../selectors/getArchiveFolderTreeRootFolder';
import type { MailFolder, MailboxType } from 'owa-graph-schema';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import getSharedFolderRoot from '../selectors/getSharedFolderRoot';
import { getFoldersToShowFirst } from 'owa-folders-data-utils';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { isPstFileAccountInfraEnabled } from 'owa-account-source-list/lib/flights';
import { isCoprincipalMailbox } from 'owa-account-source-list-store';
import { getIsCustomSortEnabled } from '../selectors/getIsCustomSortEnabled';

export function getFolderByDistinguishedId(
    distinguishedId: DistinguishedFolderIdName,
    mailboxInfo: MailboxInfo
): MailFolder | undefined {
    const folderTable = getFolderTable();
    const folderId = folderNameToId(distinguishedId, mailboxInfo);
    return folderId ? folderTable.get(folderId) : undefined;
}

/**
 * get root folder of mailbox
 * @return root MailFolder
 */
export function getRootFolderForMailboxInfo(mailboxInfo: MailboxInfo): MailFolder | undefined {
    // If someone wants to get child folders of a shared folder root,
    // then it must pass mailboxSmtpAddress OR throw error.
    if (mailboxInfo.type === 'SharedMailbox' && !mailboxInfo.mailboxSmtpAddress) {
        throw new Error('To get all the shared folders you must pass principalSMTPAddress');
    }

    switch (mailboxInfo.type) {
        case 'ArchiveMailbox':
            return getArchiveFolderTreeRootFolder(mailboxInfo);
        case 'SharedMailbox':
            return getSharedFolderRoot(mailboxInfo.mailboxSmtpAddress, mailboxInfo);
        default:
            return getPrimaryFolderTreeRootFolder(mailboxInfo);
    }
}

/**
 * get all child folders of a mailbox
 * @return child folder ids
 */
export function getMailRootFolderChildIds(mailboxInfo: MailboxInfo): string[] {
    const rootFolder = getRootFolderForMailboxInfo(mailboxInfo);
    const folderIndexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const isCustomSortEnabled = getIsCustomSortEnabled(mailboxInfo);
    const foldersToShowFirst = isCustomSortEnabled ? [] : getFoldersToShowFirst(mailboxInfo);
    const isPrimaryMailbox = isPstFileAccountInfraEnabled()
        ? isCoprincipalMailbox(mailboxInfo)
        : mailboxInfo.type == 'UserMailbox';
    let childFolderIds: string[] = [];

    // First add all the special folders if mailbox is primary and moveto context is primary
    if (
        rootFolder &&
        isPrimaryMailbox &&
        rootFolder.distinguishedFolderType ==
            getPrimaryFolderTreeRootFolder(mailboxInfo)?.distinguishedFolderType &&
        rootFolder.id // FolderId may be undefined if we fail to load data for folder hierarchy from session data.
    ) {
        const folders = foldersToShowFirst
            .map(distinguishedId => getFolderByDistinguishedId(distinguishedId, mailboxInfo))
            .filter(folder => folder && folder.parentFolderId === rootFolder?.id);

        childFolderIds = folderIndexer
            ? folders
                  .filter(
                      folder =>
                          folder?.mailboxInfo &&
                          getIndexerValueForMailboxInfo(folder?.mailboxInfo) === folderIndexer
                  )
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion -- (https://aka.ms/OWALintWiki)
                   * Baseline. DO NOT COPY AND PASTE!
                   *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
                  .map(folder => folder?.id!)
            : /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion -- (https://aka.ms/OWALintWiki)
               * Baseline. DO NOT COPY AND PASTE!
               *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
              folders.map(folder => folder?.id!);
    }

    // Get child folder ids for root folder that can be displayed to user
    // Root folders are unique per account, so are their childrenFolderIds
    const childFolderIdsThatCanBeDisplayed = rootFolder?.childFolderIds ?? [];

    // Add all the remaining folders that are not part of the special list above.
    for (let i = 0; i < childFolderIdsThatCanBeDisplayed.length; i++) {
        const childFolderId = childFolderIdsThatCanBeDisplayed[i];
        if (childFolderId && foldersToShowFirst.indexOf(folderIdToName(childFolderId)) === -1) {
            childFolderIds.push(childFolderId);
        }
    }

    return childFolderIds;
}

/**
 * Function calculates and compares if the mailboxType of a given folder id is same as given mailboxType
 * @param mailboxType - mailboxType which needs to be checked
 * @param folderId - folderId
 */
export function isFolderInMailboxType(folderId: string, mailboxType: MailboxType): boolean {
    const folderTable = getFolderTable();
    const folder = folderTable.get(folderId);
    return folder?.mailboxInfo.type === mailboxType;
}

/**
 * Function compares the mailboxType of 2 folders
 * @param folderId1 - folderId of first folder
 * @param folderId2 - folderId of second folder
 */
export function areFoldersInSameMailboxType(folderId1: string, folderId2: string): boolean {
    const folderTable = getFolderTable();
    const folder1 = folderTable.get(folderId1);
    const folder2 = folderTable.get(folderId2);
    if (
        folder1?.mailboxInfo.type === 'ArchiveMailbox' &&
        folder2?.mailboxInfo.type === 'ArchiveMailbox'
    ) {
        // If the MailboxGuid are the same, then it is the same mailbox
        return (
            folder1?.mailboxInfo.auxiliaryMailboxGuid === folder2?.mailboxInfo.auxiliaryMailboxGuid
        );
    }
    return folder1?.mailboxInfo.type === folder2?.mailboxInfo.type;
}
