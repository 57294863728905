import type { RibbonControlId } from 'owa-ribbon-ids';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';

export function modifyIdsForCompose(
    idList: RibbonControlId[],
    editorId?: string
): (number | string)[] {
    if (editorId === undefined) {
        return idList;
    }

    const returnIdList: (number | string)[] = [];

    idList.forEach(id => {
        returnIdList.push(getComposeRibbonId(id, editorId));
    });
    return returnIdList;
}
