import type { OneJs_PersonSourcesViewDataResolvers } from 'owa-graph-schema';
import { personCardActionsModel } from './OneJS_PersonCardActionsModel';
import { configuration } from './OneJS_PersonSourcesConfiguration';
import { personSourcesModel } from './OneJS_PersonSourcesModel';

// TODO add Lazy imports when the module size increases beyond 1.3kb. Current (.8kb) Read more: https://aka.ms/owabundlechecks

export const OneJS_PersonSourcesViewData: OneJs_PersonSourcesViewDataResolvers = {
    configuration,
    personCardActionsModel,
    personSourcesModel,
};
