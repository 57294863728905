import { sanitize } from 'dompurify';
import type { Config } from 'dompurify';

let owaTrustedTypesPolicy: TrustedTypePolicy | undefined;
if (window.trustedTypes) {
    owaTrustedTypesPolicy = window.trustedTypes.createPolicy('owaTrustedTypesPolicy', {
        createHTML: (html: string, extendedConfig: Config): string => {
            return sanitize(html, {
                ...extendedConfig,
                RETURN_TRUSTED_TYPE: false,
            }).toString();
        },
        createScriptURL: (url: string): string => {
            // Regex to match either /mail/sw.js or /mail/<any string>/sw.js
            const regex = /^\/mail\/([^\/]+\/)?sw\.js/;
            if (regex.test(url)) {
                return url;
            } else {
                // The URL is not meeting the conditions for a safe URL so return empty string
                return '';
            }
        },
        createScript: (script: string): string => script,
    });
}

export { owaTrustedTypesPolicy };
