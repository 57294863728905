import { owaComputedFn } from 'owa-computed-fn';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxInfoForGroup from 'owa-mailbox-info/lib/getMailboxInfoForGroup';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export const getGroupsPanelMailboxInfo = owaComputedFn((groupId: string): MailboxInfo => {
    return isMailboxPackageFlightEnabled()
        ? getMailboxInfoForGroup(getModuleContextMailboxInfo(), groupId)
        : {
              type: 'GroupMailbox',
              userIdentity: getModuleContextMailboxInfo().userIdentity,
              mailboxSmtpAddress: transformGroupSmtp(groupId),
          };
});
