import { isSuccessStatusCode } from 'owa-http-status-codes';
import { makePostRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { SETTINGS_URL } from './constants';
import onCopilotPrioritizeSettingsSaved from '../mutators/onCopilotPrioritizeSettingsSaved';
import getCopilotPrioritizeSettingsStore from '../store/store';

export default async function saveCopilotPrioritizeSettingsToService(
    settings: CopilotPrioritizeSettings,
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    const previousSettings = getCopilotPrioritizeSettingsStore(mailboxInfo).settings;

    // Update the store with the new settings (optimistically)
    onCopilotPrioritizeSettingsSaved(mailboxInfo, settings);

    let response: Response;
    try {
        response = await makePostRequest(
            SETTINGS_URL /* requestUrl */,
            settings /* requestObject */,
            undefined /* correlationId */,
            true /* returnFullResponse */,
            undefined /* customHeaders */,
            true /* throwServiceError */,
            true /* sendPayloadAsBody */,
            false /* includecredentials */,
            undefined /* actionName */,
            undefined /* datapoint */,
            mailboxInfo /* mailboxInfo */
        );
    } catch (error) {
        // Revert the store to the previous settings
        onCopilotPrioritizeSettingsSaved(mailboxInfo, previousSettings);
        return false;
    }

    if (!!response && isSuccessStatusCode(response.status)) {
        return true;
    } else {
        // Revert the store to the previous settings
        onCopilotPrioritizeSettingsSaved(mailboxInfo, previousSettings);
        return false;
    }
}
