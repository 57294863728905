import getExtensibilityState from 'owa-addins-store/lib/store/getExtensibilityState';
import { translatorAddinId } from './constants';
import type { MailboxInfo } from 'owa-client-types';

export default function isTranslatorEnabled(mailboxInfo: MailboxInfo): boolean {
    const state = getExtensibilityState(mailboxInfo);
    const context = state ? state.Context : null;
    const installedExts = context
        ? // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
          // -> Error TS2532 (11,11): Object is possibly 'undefined'.
          // @ts-expect-error
          context.Extensions.filter(ext => ext.Id == translatorAddinId)
        : [];
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type 'boolean | undefined' is not assignable to type 'boolean'.
    // @ts-expect-error
    return installedExts.length > 0 ? installedExts[0].Enabled : false;
}
