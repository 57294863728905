import type DocLink from 'owa-service/lib/contract/DocLink';
import type { DocLink as Schema_DocLink } from 'owa-graph-schema';
import { attachmentPermissionType } from './attachmentPermissionType';
import { bindTypename } from 'bind-typenames';

export function convertDocLinkToGql(docLink: DocLink): Schema_DocLink {
    /* eslint-disable @typescript-eslint/no-shadow --
     * See https://typescript-eslint.io/rules/no-shadow
     *	> 'docLink' is already declared in the upper scope. */
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (10,5): Type '({ PermissionType: AttachmentPermission | null; __type?: string | undefined; ProviderType?: string | undefined; Url?: string | undefined; } & { __typename: "DocLink"; }) | null' is not assignable to type 'DocLink'.
    // @ts-expect-error
    return bindTypename(docLink, 'DocLink', docLink => ({
        /* eslint-enable @typescript-eslint/no-shadow */
        ProviderType: docLink.ProviderType,
        Url: docLink.Url,
        PermissionType: attachmentPermissionType(docLink.PermissionType),
    }));
}
