import { logStartGreyError, logStartUsage } from 'owa-analytics-start';
import { setTryLookupIndexerFunc } from 'owa-client-types/lib/tryLookupIndexer';
import {
    getCoprincipalAccounts,
    getAccountsRemovedThisSession,
    IndexerMatchType,
} from 'owa-account-source-list-store';
import getIndexerAndMatchInfoByUserIdentity from 'owa-account-source-list-store/lib/selectors/getIndexerAndMatchInfoByUserIdentity';
import { coprincipalAccountIndexerFromSourceId } from 'owa-client-types/lib/coprincipalAccountIndexerFromSourceId';
import type { MailboxInfo } from 'owa-client-types';
import type { TryLookupIndexerFunc } from 'owa-client-types/lib/tryLookupIndexer';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';

let currentLogCount = 0;
const maxLogCount = 3;

/**
 * Allow the tests to reset the log count
 */
export function resetLogCount() {
    currentLogCount = 0;
}

/**
 * Checkes to see if the user identity is for a coprincipal account or a removed account, if it
 * is we can use the user identity as the indexer value
 * @param userIdentity Specifies the user identity to be checked
 * @returns True if userIdentity matches an account or removed account
 */
function isUserIdentityForCoprincipalAccountOrRemovedAccount(userIdentity: string): boolean {
    const matchingAccounts = getCoprincipalAccounts().filter(
        account => account.mailboxInfo.userIdentity == userIdentity
    );

    if (matchingAccounts.length > 0) {
        return true;
    }

    // Check if the user identity matches any of the removed accounts
    const matchingRemovedAccounts = getAccountsRemovedThisSession().filter(
        account => account.mailboxInfo.userIdentity == userIdentity
    );

    return matchingRemovedAccounts.length > 0;
}

/**
 * The map of known indexers based on the user identity value
 */
const knownUserIdentitiesByAlias = new Set<string>();

// Allow the unit tests to clear this cache
export function resetKnownUserIdentitiesByAlias() {
    knownUserIdentitiesByAlias.clear();
}

/**
 * Attempts to lookup the indexer value for the supplied MailboxInfo
 *
 * Note, that any changes to this function need to be reflected in the buildIndexerByUserIdentityMap
 * function in the shared/internal/owa-account-source-list-store/src/utils/buildIndexerByUserIdentityMap.ts
 * @param mailboxInfo Identifies a mailbox within an account for which the indexer value is to be returned
 * @returns Indexer value if one can be found or undefined if we cannot determine the indexer value
 */
export function tryLookupUserIdentityBasedIndexer(mailboxInfo: MailboxInfo): string | undefined {
    const userIdentity = mailboxInfo.userIdentity;
    if (!userIdentity) {
        // user identity is required to lookup the indexer value
        return undefined;
    }

    if (isUserIdentityForCoprincipalAccountOrRemovedAccount(userIdentity)) {
        // We found a matching user identity, either in a coprincipal accounts or
        // an account that was removed this session, so we can use userIdentity
        // as the indexer value.
        return userIdentity;
    }

    const accountUserIdentityByAlias = getCoprincipalAccounts()
        .filter(account => account.aliases.includes(userIdentity))
        .map(account => account.mailboxInfo.userIdentity)[0];
    if (!!accountUserIdentityByAlias) {
        // We have found the account by looking at the aliases of the existing accounts, use
        // the userIdentity of the account as the indexer value
        const foundByAlias = `${userIdentity}=>${accountUserIdentityByAlias}`;
        if (!knownUserIdentitiesByAlias.has(foundByAlias)) {
            // We have not reported on this mapping before so log that it was found
            knownUserIdentitiesByAlias.add(foundByAlias);
            const error = new Error('Found indexer by alias of an account');
            const info = {
                totalFound: knownUserIdentitiesByAlias.size,
                mailboxType: mailboxInfo.type,
                diagnosticData: mailboxInfo.diagnosticData ?? '<>',
            };
            logStartGreyError('IncorrectMailboxInfoUserIdentity', error, info);
        }

        return accountUserIdentityByAlias;
    }

    // We were unable to determine the indexer value. This can happen if the indexer value is
    // requested before the account source list store is initialized or if the MailboxInfo is
    // not associated with any account.
    return undefined;
}

let indexerFromStoreLogCount = 0;
const maxIndexerFromStoreLogCount = 5;

export function resetIndexerFromStoreLogCount() {
    indexerFromStoreLogCount = 0;
}

// Checked aliases for the user identity
const previouslyCheckedAliases = new Set<string>();

// Allow the unit tests to clear this cache
export function resetPreviouslyCheckedAliases() {
    previouslyCheckedAliases.clear();
}

export function checkIndexerFromStore(mailboxInfo: MailboxInfo, indexer: string | undefined) {
    const mailboxType = mailboxInfo.type;
    const userIdentity = mailboxInfo.userIdentity;
    const diagnosticData = mailboxInfo.diagnosticData ?? '<>';
    const indexerFromStore = getIndexerAndMatchInfoByUserIdentity(userIdentity);
    if (
        indexerFromStore?.indexer != indexer &&
        indexerFromStoreLogCount < maxIndexerFromStoreLogCount
    ) {
        ++indexerFromStoreLogCount;
        const error = new Error('IndexerFromStoreMismatch');
        const data = {
            indexerFromStoreType: typeof indexerFromStore?.indexer,
            indexerFromStoreLength: indexerFromStore?.indexer.length,
            indexerType: typeof indexer,
            indexerLength: indexer?.length,
            mailboxType,
            diagnosticData,
        };

        logStartGreyError('IndexerFromStoreMismatch', error, data);
    } else if (
        indexerFromStore?.matchType == IndexerMatchType.AccountAlias &&
        !previouslyCheckedAliases.has(userIdentity)
    ) {
        // Record that we fouund the same matching indexer from an alias in the store this will
        // let us know that the account matchng is working correctly
        previouslyCheckedAliases.add(userIdentity);
        logStartUsage('FoundMatchingIndexerFromStoreAlias', {
            accountIndex: indexerFromStore?.accountIndex,
            mailboxType,
            diagnosticData,
        });
    }
}

/**
 * When sourceId is not present in a MailboxInfo we need to fallback to looking up the indexer
 * from the user identity. This function will lookup the user identity from the indexer value
 * @param mailboxInfo Specifies the mailbox info for which the indexer value is to be returned
 * @returns Indexer value to be used
 */
const tryLookupIndexer: TryLookupIndexerFunc = (mailboxInfo: MailboxInfo) => {
    if (!isPersistenceIdIndexerEnabled()) {
        const indexer = tryLookupUserIdentityBasedIndexer(mailboxInfo);
        if (!!mailboxInfo.userIdentity) {
            checkIndexerFromStore(mailboxInfo, indexer);
        }

        return indexer;
    }

    const matchingSourceIds = getCoprincipalAccounts()
        .filter(account => account.mailboxInfo.userIdentity == mailboxInfo.userIdentity)
        .map(account => account.sourceId);

    const firstMatchingIndexer = coprincipalAccountIndexerFromSourceId(matchingSourceIds[0]);

    // We want to know when we are looking up indexer values by user identity but to not
    // want to overwhelm the telemetry system so we only log a set number of lookup
    if (currentLogCount < maxLogCount) {
        ++currentLogCount;

        const errorForStack = new Error('TryLookupIndexerCalled');
        const hasSourceId: boolean = !!mailboxInfo.sourceId;
        const foundIndexer: boolean = !!firstMatchingIndexer;
        const matches: number = !!matchingSourceIds ? matchingSourceIds.length : 0;
        const data = {
            hasSourceId,
            foundIndexer,
            matches,
        };

        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(errorForStack.message, errorForStack, data);
    }

    return firstMatchingIndexer;
};

export function initializeTryLookupIndexer() {
    logStartUsage('initializeTryLookupIndexer');

    setTryLookupIndexerFunc(tryLookupIndexer);
}
