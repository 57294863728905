import { FluentButton } from 'owa-fluent-v9-shims';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { Module } from 'owa-workloads';
import { UNIQUE_MESSAGE_BODY_SELECTOR } from 'owa-controls-content-handler/lib/constants';

import { skipToMainContentLink } from './SkipLinkControl.scss';

const skipLinkNameAttribute = 'data-skip-link-name';

export interface SkipLinkControlProps {
    currentModule: Module;
}

export default observer(function SkipLinkControl(props: SkipLinkControlProps) {
    const { currentModule } = props;

    // The element that the skip link will focus on
    const [skipLinkTarget, setSkipLinkTarget] = React.useState<HTMLElement | null>(null);

    const onSkipLinkButtonClicked = React.useCallback(() => {
        skipLinkTarget?.focus();
    }, [skipLinkTarget]);

    const [buttonText, setButtonText] = React.useState<string>('');
    React.useEffect(() => {
        if (skipLinkTarget) {
            const skipLinkName = skipLinkTarget.getAttribute(skipLinkNameAttribute);
            if (skipLinkName) {
                setButtonText(skipLinkName);
            }
        }
    }, [skipLinkTarget]);

    // Callback function for when the skip link button is focused
    //
    // Modules can register logic to select the appropriate skip link target elemen
    // (its main content available at time of focus).
    const onSkipLinkButtonFocused = React.useCallback(() => {
        if (currentModule === Module.Mail) {
            const skipLinkTargetElements = document.querySelectorAll(`[${skipLinkNameAttribute}]`);

            // If there are 2 elements with a data-skip-link-name attribute, then
            // we need to determine if we should be targeting the reading pane
            // (higher priority) or the message list (lower priority). If the RP
            // is open, then we should target it (item at index 1). Otherwise, we
            // should target the message list (item at index 0).
            if (skipLinkTargetElements?.length === 2) {
                const conversationReadingPaneElement = document.getElementById(
                    'ConversationReadingPaneContainer'
                );
                const itemReadingPaneElement = document.getElementById('ItemReadingPaneContainer');
                // If either element exists, it means there's content in the RP
                // and it should be targeted. Otherwise, the message list should
                // be targeted.
                // If the RP is open, we should target the message body element
                // Otherwise target the reading pane container
                if (conversationReadingPaneElement || itemReadingPaneElement) {
                    const rpContainer = skipLinkTargetElements.item(1);
                    const messageBodyElement = tryGetMessageBodySkipLinkTarget(
                        conversationReadingPaneElement,
                        itemReadingPaneElement
                    );

                    if (messageBodyElement) {
                        setButtonText(rpContainer.getAttribute(skipLinkNameAttribute) ?? '');
                        setSkipLinkTarget(messageBodyElement as HTMLElement);
                    } else {
                        setSkipLinkTarget(rpContainer as HTMLElement);
                    }
                } else {
                    handleMessageListSkipLink(skipLinkTargetElements.item(0) as HTMLElement);
                }
            } else if (skipLinkTargetElements?.length === 1) {
                // If there's only one element with a data-skip-link-name attribute,
                // then it should be targeted (it's the ML in the case of RP off).
                handleMessageListSkipLink(skipLinkTargetElements.item(0) as HTMLElement);
            } else {
                setSkipLinkTarget(null);
            }

            return;
        } else {
            // The default behavior is to check if the existing skipLinkTarget
            // still exists on the page. If it does, then no action is needed.
            // If it does not, we need to select a new skip link target and
            // update the state.
            const skipLinkTargetElementId = skipLinkTarget?.getAttribute('id');
            const currentSkipLinkTargetElement = document.getElementById(
                skipLinkTargetElementId ?? ''
            );

            if (!currentSkipLinkTargetElement) {
                const skipLinkTargetElement = document
                    .querySelectorAll(`[${skipLinkNameAttribute}]`)
                    ?.item(0);

                if (skipLinkTargetElement) {
                    setSkipLinkTarget(skipLinkTargetElement as HTMLElement);
                } else {
                    setSkipLinkTarget(null);
                }
            }
        }
    }, [skipLinkTarget, currentModule]);

    // Try to get the message body element as the skip link target, otherwise use the reading pane container
    const tryGetMessageBodySkipLinkTarget = (
        conversationReadingPaneElement: HTMLElement | null,
        itemReadingPaneElement: HTMLElement | null
    ) => {
        let messageBodySkipLinkTarget;
        if (conversationReadingPaneElement) {
            messageBodySkipLinkTarget = conversationReadingPaneElement.querySelector(
                '#focused ' + UNIQUE_MESSAGE_BODY_SELECTOR
            );
        } else if (itemReadingPaneElement) {
            messageBodySkipLinkTarget = itemReadingPaneElement.querySelector(
                UNIQUE_MESSAGE_BODY_SELECTOR
            );
        }

        return messageBodySkipLinkTarget;
    };

    const handleMessageListSkipLink = (targetElement: HTMLElement) => {
        const ml = targetElement;

        // If there is a focusable row in the message list, we should target it.
        const targetRow = ml.querySelectorAll('[data-focusable-row=true]').item(0) as HTMLElement;

        // If there is a focusable row, we should set the button text to the
        // value of the data-skip-link-name attribute on the parent container (ML).
        if (targetRow) {
            setButtonText(ml.getAttribute(skipLinkNameAttribute) ?? '');
        }

        // If there is no focusable row, we should target the message list itself.
        setSkipLinkTarget(targetRow ?? ml);
    };

    const skipLink = React.useMemo(() => {
        return (
            <FluentButton
                className={skipToMainContentLink}
                onClick={onSkipLinkButtonClicked}
                onFocus={onSkipLinkButtonFocused}
                role="link"
            >
                {buttonText}
            </FluentButton>
        );
    }, [onSkipLinkButtonClicked, onSkipLinkButtonFocused, skipLinkTarget, buttonText]);

    return skipLink;
}, 'SkipLinkControl');
