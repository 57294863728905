import { mutatorAction } from 'satcheljs';
import copilotPrioritizeSettingsStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'onCopilotPrioritizeSettingsFetched',
    (mailboxInfo: MailboxInfo, response: any) => {
        if (response.version === 1) {
            const store = copilotPrioritizeSettingsStore(mailboxInfo);

            store.settings = response;
            store.isInitialized = true;
        }
    }
);
