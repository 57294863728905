import type {
    SearchResultTeamsMessage,
    TeamsMessage,
    SenderProfileUser,
} from '../../data/schema/SubstrateSearchResponse';
import type SubstrateSearchRequest from '../../data/schema/SubstrateSearchRequest';
import { convertUtcDateTimeToRequestTimeZone } from './converterHelpers';

export default function convertSearchResultTeamsMessageToTeamsMessageType(
    searchResultTeamsMessage: SearchResultTeamsMessage,
    request: SubstrateSearchRequest
): TeamsMessage {
    return {
        ItemId: searchResultTeamsMessage.InternetMessageId,
        content: searchResultTeamsMessage.Preview,
        id: searchResultTeamsMessage.InternetMessageId,
        composeTime: convertUtcDateTimeToRequestTimeZone(
            searchResultTeamsMessage.DateTimeSent,
            request
        ),
        originalArrivalTime: convertUtcDateTimeToRequestTimeZone(
            searchResultTeamsMessage.DateTimeReceived,
            request
        ),
        containerId: searchResultTeamsMessage.ClientConversationId,
        imDisplayName: searchResultTeamsMessage.Sender.EmailAddress.Name,
        senderProfile: {
            displayName: searchResultTeamsMessage.Sender.EmailAddress.Name,
            email: searchResultTeamsMessage.Sender.EmailAddress.Address,
        } as SenderProfileUser,
        displayTo: searchResultTeamsMessage.DisplayTo,
        messageType:
            searchResultTeamsMessage.Extensions.SkypeSpaces_ConversationPost_Extension_ThreadType,
        chatName: searchResultTeamsMessage.Extensions.SkypeSpaces_ConversationPost_Extension_Topic,
        topicGroupId:
            searchResultTeamsMessage.Extensions.SkypeSpaces_ConversationPost_Extension_SkypeGroupId,
        isExpanded: false,
        clientThreadId: searchResultTeamsMessage.ClientThreadId,
        InstanceKey: searchResultTeamsMessage.ReferenceId,
    };
}
