import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotInbox"*/ './lazyIndex')
);

export const lazyCopilotInboxProcessor = new LazyAction(lazyModule, m => m.copilotInboxProcessor);

export const lazyCopilotInboxProcessorForSelectedRows = new LazyAction(
    lazyModule,
    m => m.copilotInboxProcessorForSelectedRows
);

export const lazyFetchCopilotInboxHeadline = new LazyAction(
    lazyModule,
    m => m.fetchCopilotInboxHeadline
);

export { default as rowHasPendingCopilotInboxRequest } from './selectors/rowHasPendingCopilotInboxRequest';
