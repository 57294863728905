import { Cache } from 'memory-cache';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type {
    EnterpriseTokenRequestParams,
    TokenRequestParams,
} from '../schema/TokenRequestParams';
import type { ITokenCache } from './ITokenCache';
import addMinutes from 'owa-date-utc-fn/lib/addMinutes';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import addSeconds from 'owa-date-utc-fn/lib/addSeconds';
import {
    MIN_TIME_TO_EXPIRY_IN_MINUTES,
    TIME_BEFORE_EXPIRY_TO_REFRESH_IN_MILLISECONDS,
} from '../const/constants';
import { ScenarioType } from '../schema/Scenario';

export class TokenCache implements ITokenCache {
    private tokenCache: Cache<string, TokenResponse>;

    public constructor() {
        this.tokenCache = new Cache();
    }

    /* getCachedToken gets token from tokenCache for Enterprise, Consumer and Gmail scenarios
     */
    public getCachedToken(cacheKey: string): TokenResponse | undefined {
        const cachedToken: TokenResponse = this.tokenCache.get(cacheKey) as TokenResponse;
        let tokenResponse: TokenResponse | undefined = undefined;

        if (cachedToken?.AccessTokenExpiry && cachedToken.AccessToken) {
            const expiryDateTimeWithMargin = addMinutes(
                new Date(cachedToken.AccessTokenExpiry),
                -MIN_TIME_TO_EXPIRY_IN_MINUTES
            );

            // Check that token is valid for at least 1 minute.
            if (expiryDateTimeWithMargin > new Date(Date.now())) {
                tokenResponse = cachedToken;
            }
        }

        return tokenResponse;
    }

    /* putCachedToken populates token to tokenCache for Enterprise, Consumer and Gmail scenarios
       It also takes a callback (cacheTimeoutCallback) method to be called when token has expired
    */
    public putCachedToken(
        cacheKey: string,
        requestParams: TokenRequestParams,
        tokenResponse: TokenResponse,
        cacheTimeoutCallback?: () => Promise<TokenResponse>
    ) {
        if (
            tokenResponse &&
            tokenResponse.TokenResultCode == 0 &&
            tokenResponse.AccessTokenExpiry
        ) {
            if (
                requestParams.scenarioType == ScenarioType.Enterprise &&
                tokenResponse.ExpiresIn &&
                tokenResponse.ExpiresIn > 0
            ) {
                tokenResponse.AccessTokenExpiry = addSeconds(
                    Date.now(),
                    tokenResponse.ExpiresIn
                ).toISOString();
            }

            const cacheTimeout: number =
                new Date(tokenResponse.AccessTokenExpiry).getTime() -
                Date.now() -
                TIME_BEFORE_EXPIRY_TO_REFRESH_IN_MILLISECONDS;

            if (cacheTimeout > 0) {
                this.tokenCache.put(cacheKey, tokenResponse, cacheTimeout, cacheTimeoutCallback);
            }
        }
    }

    /* GetCacheKey currently takes into account all the request
        params and gets back the key to be used for all of Enterprise,
        Consumer & Gmail scenarios
    */
    public getCacheKey(params: TokenRequestParams): string {
        let key: string = params.resource ?? '';

        if (params.scenarioType == ScenarioType.Enterprise) {
            const enterpriseParams = params as EnterpriseTokenRequestParams;
            if (enterpriseParams.targetTenantId) {
                key += '|' + enterpriseParams.targetTenantId;
            }

            if (enterpriseParams.preferIdpToken) {
                key += '|IdpToken';
            }
        }

        const indexer = params.mailboxInfo
            ? getIndexerValueForMailboxInfo(params.mailboxInfo)
            : undefined;

        if (indexer) {
            key += '|' + indexer;
        }

        if (params.scope) {
            key += '|' + params.scope;
        }

        return key;
    }

    /* deleteCachedToken deletes token from tokenCache for Enterprise, Consumer and Gmail scenarios
     */
    public deleteCachedToken(cacheKey: string) {
        this.tokenCache.del(cacheKey);
    }

    /* getAllCacheKeys returns all the keys in the tokenCache
     */
    public getAllCacheKeys(): string[] {
        return this.tokenCache.keys();
    }

    /* clears out all the cache key that corresponds to this mailboxInfo
     */
    public clearCacheForMailboxInfo(mailboxInfo?: MailboxInfo) {
        const indexer = mailboxInfo ? getIndexerValueForMailboxInfo(mailboxInfo) : undefined;
        if (indexer) {
            const cacheKeys = this.getAllCacheKeys();
            cacheKeys.forEach(key => {
                if (key.includes(indexer)) {
                    this.deleteCachedToken(key);
                }
            });
        }
    }
}
