import { useState, useEffect } from 'react';
import { isWin10, isWin11, isMac } from 'owa-user-agent';

export type OperatingSystemName = 'Windows' | 'Mac' | null;

// Custom Hook to get the operating system of the user
export default function useOperatingSystemName(): OperatingSystemName {
    const [os, setOs] = useState<OperatingSystemName>(null);

    useEffect(() => {
        const getOperatingSystem = async () => {
            if (isWin10() || (await isWin11())) {
                setOs('Windows');
            } else if (isMac()) {
                setOs('Mac');
            }
        };
        getOperatingSystem();
    }, []);
    return os;
}
