import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyUpdateCalendarCharmMutationWeb = createLazyResolver(
    'UPDATECALENDARCHARM_MUTATION_WEB',
    () =>
        import(
            /* webpackChunkName: "UpdateCalendarCharmMutationWeb" */ './updateCalendarCharmMutationWeb'
        ),
    m => m.updateCalendarCharmMutationWeb
);
