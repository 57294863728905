import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import onFavoritesLoaded from 'owa-favorites/lib/actions/onFavoritesLoaded';

// Import mutators
import './mutators/addPublicFolderToStoreMutator';
import './mutators/removeFavoriteFromPublicFolderStoreMutator';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PublicFolderFavorite" */ './lazyIndex'),
    { name: 'PublicFolderFavorite' }
);

// Register lazy orchestrators
registerLazyOrchestrator(
    onFavoritesLoaded,
    lazyModule,
    m => m.loadPublicFolderFavoritesOchestrator
);

// Create lazy components
export const PostedToLine = createLazyComponent(lazyModule, m => m.PostedToLine);

export { default as getPublicFolderMailboxInfoForSmtpAddress } from './services/utils/getPublicFolderMailboxInfoForSmtpAddress';
export { default as publicFolderFavoriteStore } from './store/publicFolderFavoriteStore';
