import type {
    RecurrencePatternInput,
    RecurrencePatternType,
    AbsoluteYearlyRecurrence,
    AbsoluteMonthlyRecurrence,
    WeeklyRecurrence,
    DailyRecurrence,
    RegeneratingPatternBaseType,
    RelativeYearlyRecurrence,
    RelativeMonthlyRecurrence,
    RecurrenceRangeInput,
    RecurrenceRangeType,
    EndDateRecurrence,
    NoEndRecurrence,
    NumberedRecurrence,
    RecurrenceInput,
    RecurrenceType,
} from 'owa-graph-schema';
import type {
    GqlRecurrenceNameStringType,
    GqlRecurrenceRangeNameStringType,
} from 'owa-recurrence-types';

/**
 * Maps from the schema RecurrencePatternType to the OWS RecurrencePatternBaseType. We need to handle this manually because
 * RecurrencePatternBaseType is an empty object that any number of classes may subclass, but Schema.RecurrencePatternType is
 * a union of possible object types.
 */
function recurrencePattern(pattern: RecurrencePatternInput): RecurrencePatternType {
    let convertedType = null;

    switch (pattern.RecurrencePatternTypename as GqlRecurrenceNameStringType) {
        case 'AbsoluteYearlyRecurrence': {
            convertedType = pattern as AbsoluteYearlyRecurrence;
            break;
        }
        case 'AbsoluteMonthlyRecurrence': {
            convertedType = pattern as AbsoluteMonthlyRecurrence;
            break;
        }
        case 'WeeklyRecurrence': {
            convertedType = pattern as WeeklyRecurrence;
            break;
        }
        case 'DailyRecurrence': {
            convertedType = pattern as DailyRecurrence;
            break;
        }
        case 'RegeneratingPatternBaseType': {
            convertedType = pattern as RegeneratingPatternBaseType;
            break;
        }
        case 'RelativeYearlyRecurrence': {
            convertedType = pattern as RelativeYearlyRecurrence;
            break;
        }
        case 'RelativeMonthlyRecurrence': {
            convertedType = pattern as RelativeMonthlyRecurrence;
            break;
        }
        default: {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(`unhandled recurrence pattern ${pattern.RecurrencePatternTypename}`);
        }
    }

    return {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (57,9): Type 'string' is not assignable to type '"DailyRecurrence" | "WeeklyRecurrence" | "RelativeMonthlyRecurrence" | "AbsoluteMonthlyRecurrence" | "RelativeYearlyRecurrence" | "AbsoluteYearlyRecurrence" | "RegeneratingPatternBaseType" | undefined'.
        // @ts-expect-error
        __typename: pattern.RecurrencePatternTypename,
        ...convertedType,
    };
}

/**
 * Maps from the union of supported subtypes from the Schema to the known implementers of the
 * OWS base class RecurrenceRangeBaseType
 *
 * See the docstring on recurrencePattern() for details
 */
function recurrenceRange(range: RecurrenceRangeInput): RecurrenceRangeType {
    let convertedType = null;

    switch (range.RecurrenceRangeTypename as GqlRecurrenceRangeNameStringType) {
        case 'EndDateRecurrence': {
            convertedType = range as EndDateRecurrence;
            break;
        }
        case 'NoEndRecurrence': {
            convertedType = range as NoEndRecurrence;
            break;
        }
        case 'NumberedRecurrence': {
            convertedType = range as NumberedRecurrence;
            break;
        }
        default:
            throw new Error('unhandled recurrence range');
    }

    return {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (92,9): Type 'string' is not assignable to type '"EndDateRecurrence" | "NoEndRecurrence" | "NumberedRecurrence" | undefined'.
        // @ts-expect-error
        __typename: range.RecurrenceRangeTypename,
        ...convertedType,
    };
}

export function convertRecurrenceGqlInputToGqlType(
    recurrenceType: RecurrenceInput
): RecurrenceType {
    return {
        __typename: 'RecurrenceType',
        RecurrencePattern: recurrencePattern(recurrenceType.RecurrencePattern),
        RecurrenceRange: recurrenceRange(recurrenceType.RecurrenceRange),
    };
}
