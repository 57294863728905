import { makeGetRequest } from 'owa-ows-gateway';
import type { UserOutlookClients } from '../store/schema/UserOutlookClients';
const ENDPOINT_TRACKER_URL: string = 'ows/api/v1/EndpointTracker/allendpoints';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export function getUserOutlookClients(): Promise<UserOutlookClients> {
    return makeGetRequest(
        ENDPOINT_TRACKER_URL,
        undefined, // correlationId
        false, // returnFullResponse
        undefined, //customHeaders
        true, // throwOnServiceError
        undefined, // includeCredentials
        undefined, // action
        getModuleContextMailboxInfo()
    );
}
