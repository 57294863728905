import { mutatorAction } from 'satcheljs';
import copilotPrioritizeSettingsStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'onCopilotPrioritizeSettingsSaved',
    (mailboxInfo: MailboxInfo, response: any) => {
        if (response.version === 1) {
            copilotPrioritizeSettingsStore(mailboxInfo).settings = response;
        }
    }
);
