import type { ApolloError } from '@apollo/client';
import type { TraceErrorObject } from 'owa-trace';

export function apolloErrorToResponseCode(error: ApolloError): string {
    let responseCode: string = '';

    if (error.graphQLErrors) {
        error.graphQLErrors.forEach(graphQlError => {
            if (graphQlError.extensions?.responseCode) {
                responseCode = graphQlError.extensions.responseCode;
            } else if (graphQlError.originalError) {
                const traceError = graphQlError.originalError as TraceErrorObject;
                if (traceError.responseCode) {
                    responseCode = traceError.responseCode;
                }
            }
        });
    }

    return responseCode;
}
