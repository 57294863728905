import { mutatorAction } from 'satcheljs';
import { default as getPendingAccountStore } from '../store/pendingAccountsStore';

const removePendingAccount = mutatorAction(
    'REMOVE_PENDING_ACCOUNT',
    function removePendingAccount(emailAddress: string) {
        getPendingAccountStore().pendingAccounts = getPendingAccountStore().pendingAccounts.filter(
            pendingAccount => pendingAccount.emailAddress !== emailAddress
        );
    }
);

export default removePendingAccount;
