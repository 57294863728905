import getStore from './store/store';
import type { OWAConnectedAccount, AccountProviderType } from './store/schema/OWAConnectedAccount';
import { OWAConnectedAccountState } from './store/schema/OWAConnectedAccount';
import type { AccountsStoreState } from './store/schema/AccountsStoreState';
import { assertNever } from 'owa-assert';
import { isConnectedAccount } from './utils/isConnectedAccount';
import assert from 'owa-assert/lib/assert';
import { isMonarchMultipleAccountsEnabled } from './utils/flights';
import { getIndexerForConnectedAccount } from './utils/getIndexerForConnectedAccount';

export function getOWAConnectedAccounts(): OWAConnectedAccount[] {
    return [...getStore().accountsMap.values()];
}

export function getOWAConnectedAccount(indexerOrUserIdentity: string): OWAConnectedAccount {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (21,5): Type 'OWAConnectedAccount | undefined' is not assignable to type 'OWAConnectedAccount'.
    // @ts-expect-error
    return getStore().accountsMap.get(getIndexerForConnectedAccount(indexerOrUserIdentity));
}

export function getDefaultConnectedAccount(): OWAConnectedAccount {
    if (isMonarchMultipleAccountsEnabled()) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (29,9): Type 'OWAConnectedAccount | null' is not assignable to type 'OWAConnectedAccount'.
        // @ts-expect-error
        return getOWAConnectedAccounts()?.length > 1 ? getOWAConnectedAccounts()[1] : null;
    } else {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (34,9): Type 'OWAConnectedAccount | null' is not assignable to type 'OWAConnectedAccount'.
        // @ts-expect-error
        return getOWAConnectedAccounts()?.length > 0 ? getOWAConnectedAccounts()[0] : null;
    }
}

export function getAccountsInErrorState(): OWAConnectedAccount[] {
    return getOWAConnectedAccounts().filter(
        account => account.accountState != OWAConnectedAccountState.Valid
    );
}

export function getAccountsInState(
    accountStates: OWAConnectedAccountState[]
): OWAConnectedAccount[] {
    return getOWAConnectedAccounts().filter(account =>
        accountStates.includes(account.accountState)
    );
}

export function getOWAConnectedAccountsForProvider(
    accountProviderType: AccountProviderType
): OWAConnectedAccount[] {
    return getOWAConnectedAccounts().filter(a => a.accountProviderType == accountProviderType);
}

/**
 * Gets the AccountProviderType for an account.
 * Returns the AccountProviderType if the account is a connected account, otherwise returns null
 * @param indexerOrUserIdentity account address
 */
export function getOWAAccountProvider(indexerOrUserIdentity: string): AccountProviderType | null {
    return isConnectedAccount(indexerOrUserIdentity)
        ? getOWAConnectedAccount(indexerOrUserIdentity).accountProviderType
        : null;
}

export function getOWAConnectedAccountTokenString(account: OWAConnectedAccount): string {
    if (isMonarchMultipleAccountsEnabled()) {
        return account.token;
    }

    if (account.accountProviderType == 'Outlook') {
        return `MSAuth1.0 usertoken="${account.token}", type="MSACT"`;
    } else if (
        account.accountProviderType == 'Google' ||
        account.accountProviderType == 'ICloud' ||
        account.accountProviderType == 'Yahoo'
    ) {
        return `Bearer ${account.token}`;
    } else if (account.accountProviderType == 'Office365') {
        assert(false, 'Office365 accounts should not use this function.');
        return '';
    } else if (account.accountProviderType == 'IMAP' || account.accountProviderType == 'POP3') {
        assert(false, 'IMAP/POP3 accounts should only be present in Monarch and not used in OWA.');
        return '';
    } else {
        return assertNever(account.accountProviderType);
    }
}

function getPropFromOWAConnectedAccount<K extends keyof OWAConnectedAccount>(
    propertyName: K,
    defaultValue: OWAConnectedAccount[K]
) {
    return function (indexerOrUserIdentity: string) {
        const connectedAccount = getOWAConnectedAccount(indexerOrUserIdentity);
        return connectedAccount ? connectedAccount[propertyName] : defaultValue;
    };
}

export const getOWAConnectedAccountStatus = getPropFromOWAConnectedAccount(
    'accountState',
    OWAConnectedAccountState.Valid
);

export const hasInvalidConnectedAccount = () =>
    getOWAConnectedAccounts().some(account => !isOWAConnectedAccountValid(account.mailboxIndexer));

const getOWAConnectedAccountProviderType = getPropFromOWAConnectedAccount(
    'accountProviderType',
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (118,5): Argument of type 'null' is not assignable to parameter of type 'AccountProviderType'.
    // @ts-expect-error
    null
);
export const isOutlookConsumerAccount = (indexerOrUserIdentity: string) =>
    getOWAConnectedAccountProviderType(indexerOrUserIdentity) == 'Outlook';

export const isOWAConnectedAccountValid = (indexerOrUserIdentity: string) =>
    getOWAConnectedAccountStatus(indexerOrUserIdentity) == OWAConnectedAccountState.Valid;

export const hasConnectedAccounts = (): boolean => getOWAConnectedAccounts().length > 0;

export function getAccountsStoreState(): AccountsStoreState {
    return getStore().accountsLoadedState;
}
