import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import type { SearchRefinersState } from '../store/schema/SearchRefinersState';
import { getSearchRefinersStore } from '../store/store';

/**
 * Returns the refiners store instance for the given scenario ID. If no instance
 * exists for the given ID, return a default refiners store instance object.
 * @param scenarioId
 */
export default function getSearchRefinersState(scenarioId: SearchScenarioId): SearchRefinersState {
    const refinerState = getSearchRefinersStore().refiners.get(scenarioId);

    if (!refinerState) {
        const defaultSearchRefinersState: SearchRefinersState = {
            appliedRefiners: [],
            suggestedRefiners: [],
            selectedQfRefiners: [],
            refinersInstrumentationContext: undefined,
        };

        //If the refinerState for the scenario doesn't exist, Initialize the store with default state
        getSearchRefinersStore().refiners.set(scenarioId, defaultSearchRefinersState);

        return defaultSearchRefinersState;
    }

    return refinerState;
}
