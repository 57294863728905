import type { AnalyticsOptions } from 'owa-analytics-types';

export function getAnalyticOptions(): AnalyticsOptions {
    return {
        flightControls: {
            client_event: {
                flight: 'ana-client-event',
                rate: 5,
            },
            client_grey_error: {
                flight: 'ana-client-event',
                rate: 5,
            },
            client_error: {
                flight: 'ana-client-error',
            },
            client_verbose: {
                flight: 'ana-client-verbose',
                rate: 1,
            },
            client_network_request: {
                flight: 'ana-client-network-request',
                rate: 1,
            },
        },
        verboseWhiteListEvents: ['AttachmentRefreshDownloadToken', 'TabViewActivateTab'],
    };
}
