import type AutoCorrectOptions from './schema/options/AutoCorrectOptions';
import { ObservableMap } from 'mobx';
import type { OwsOptionsStoreMap } from './schema/OwsOptionsStoreMap';
import { getCoprincipalAccounts } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type SmartSuggestionsOptions from './schema/options/SmartSuggestionsOptions';
import { OwsOptionsFeatureType } from './schema/OwsOptionsFeatureType';
import type OwsOptionsStore from './schema/OwsOptionsStore';
import type DiverseEmojisOptions from './schema/options/DiverseEmojisOptions';
import type EUEmailAdsOptions from './schema/options/EUEmailAdsOptions';
import type GdprAds2023Options from './schema/options/GdprAds2023Options';
import type GdprAdsV3Options from './schema/options/GdprAdsV3Options';
import type SkypeNotificationOptions from './schema/options/SkypeNotificationOptions';
import type SurfaceActionsOptions from './schema/options/SurfaceActionsOptions';
import type WebPushNotificationsOptions from './schema/options/WebPushNotificationsOptions';
import type ConfettiOptions from './schema/options/ConfettiOptions';
import type BoostOptions from './schema/options/BoostOptions';
import type CalendarSurfaceAddinsOptions from './schema/options/CalendarSurfaceAddinsOptions';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type ExternalImagesOptions from './schema/options/ExternalImagesOptions';
import type EventCaptureOptions from './schema/options/EventCaptureOptions';
import type LinkedInViewProfileOptions from './schema/options/LinkedInViewProfileOptions';
import type TranslationOptions from './schema/options/TranslationOptions';
import type CalendarSurfaceOptions from './schema/options/CalendarSurfaceOptions';
import type MentionEventNotificationsOptions from './schema/options/MentionEventNotificationsOptions';
import type ComposeAssistanceOptions from './schema/options/ComposeAssistanceOptions';
import type ActivityFeedOptions from './schema/options/ActivityFeedOptions';
import type CalendarHelpOptions from './schema/options/CalendarHelpOptions';
import type OwsOptionsBase from './schema/OwsOptionsBase';
import type ProofingOptions from './schema/options/ProofingOptions';
import type ActiveProxyAddressOptions from './schema/options/ActiveProxyAddressOptions';
import type SxSOptions from './schema/options/SxSOptions';
import { EditOptionsCommand } from './schema/options/SxSOptions';
import type ReadingPaneConversationOptions from './schema/options/ReadingPaneConversationOptions';
import type SearchOptions from './schema/options/SearchOptions';
import type StartUpOptions from './schema/options/StartUpOptions';
import type KeyboardShortcutsOptions from './schema/options/KeyboardShortcutsOptions';
import { MonarchKeyboardShortcutsMode } from './schema/options/KeyboardShortcutsOptions';
import type EmailHeaderContentOptions from './schema/options/EmailHeaderContentOptions';
import type PinnedPaneOptions from './schema/options/PinnedPaneOptions';
import { PinnablePane } from './schema/options/PinnedPaneOptions';
import type NativeAdsRemoveUpsellOptions from './schema/options/NativeAdsRemoveUpsellOptions';
import type EditorAutoFormatOptions from './schema/options/EditorAutoFormatOptions';
import type PremiumStatusInPrimarySettingsOptions from './schema/options/PremiumStatusInPrimarySettingsOptions';
import type UseSystemDarkModeOptions from './schema/options/UseSystemDarkModeOptions';
import type ComposeFormInPopOutOptions from './schema/options/ComposeFormInPopOutOptions';
import type DictationOptions from './schema/options/DictationOptions';
import { PasteType } from './schema/options/DefaultPasteTypeOption';
import type DefaultPasteTypeOption from './schema/options/DefaultPasteTypeOption';
import type CopilotChatFREOptions from './schema/options/CopilotChatFREOptions';

const fluidOption = {
    feature: OwsOptionsFeatureType.Bohemia,
} as OwsOptionsBase;

const owsOptionsStoreMap = createStore<OwsOptionsStoreMap>('owsOptionsStore', {
    accounts: createInitialStores(),
})();

function createInitialStores() {
    const accountStores = new ObservableMap<string, OwsOptionsStore>();
    getCoprincipalAccounts().every(account => {
        const key = getIndexerValueForMailboxInfo(account.mailboxInfo);
        accountStores.set(key, getInitialState());
    });
    return accountStores;
}

export function getInitialState(): OwsOptionsStore {
    return {
        options: {
            /**
             * Initial values for options in the store should be "fail-safe" values in the
             *  event that the service call to fetch the options has not yet completed or failed.
             *
             * For example, while the default setting for a feature for a user that has made no
             * changes to the options may be to have it enabled, to increase coverage and
             * discoverability, we don't want to aggravate users by potentially having it
             * enabled after they explicitly disabled it.
             * These should be nulls/falses/empty arrays and the call to LoadOptions (LoadOptions.ts)
             * should be in charge to apply defaults for non-set options and fail cases
             */
            [OwsOptionsFeatureType.SmartSuggestions]: {
                feature: OwsOptionsFeatureType.SmartSuggestions,
                smartSuggestionsEnabled: false,
                browserLocationEnabled: false,
            } as SmartSuggestionsOptions,
            [OwsOptionsFeatureType.DiverseEmojis]: {
                feature: OwsOptionsFeatureType.DiverseEmojis,
                diverseEmojisSelectedSkinTone: '',
            } as DiverseEmojisOptions,
            [OwsOptionsFeatureType.SurfaceActions]: {
                feature: OwsOptionsFeatureType.SurfaceActions,
                readSurfaceActions: [],
                readSurfaceAddins: [],
                composeSurfaceActions: [],
                composeSurfaceAddins: [],
            } as SurfaceActionsOptions,
            [OwsOptionsFeatureType.SkypeNotifications]: {
                feature: OwsOptionsFeatureType.SkypeNotifications,
                skypeMessageNotification: 1, // Toast and Sound
                skypeCallingNotification: 1, // Toast and Sound
            } as SkypeNotificationOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (73,55): Conversion of type '{ feature: OwsOptionsFeatureType.WebPushNotifications; enabled: false; applicationServerKey: null; }' to type 'WebPushNotificationsOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.WebPushNotifications]: {
                feature: OwsOptionsFeatureType.WebPushNotifications,
                enabled: false,
                applicationServerKey: null,
            } as WebPushNotificationsOptions,
            [OwsOptionsFeatureType.Confetti]: {
                feature: OwsOptionsFeatureType.Confetti,
                confettiEnabled: false,
            } as ConfettiOptions,
            [OwsOptionsFeatureType.Boost]: {
                feature: OwsOptionsFeatureType.Boost,
                boostEnabled: false,
                keywordsEnabled: false,
                mailOpenBehavior: 0,
            } as BoostOptions,
            [OwsOptionsFeatureType.EUEmailAds]: {
                feature: OwsOptionsFeatureType.EUEmailAds,
                optInBit: 0,
                hasSuggestNativeAdsDimissed: false,
            } as EUEmailAdsOptions,
            [OwsOptionsFeatureType.GdprAds2023]: {
                feature: OwsOptionsFeatureType.GdprAds2023,
                encodedTCString: '',
                allowP1StoreAndAccessDevice: false,
                allowP2LimitedDataSelect: false,
                allowP3CreateProfilesPersonalised: false,
                allowP4UseProfilesPersonalised: false,
                allowP5CreateProfilesContent: false,
                allowP6UseProfilesContent: false,
                allowP7MeasureAdvPerf: false,
                allowP8MeasureContentPerf: false,
                allowP9Audiences: false,
                allowP10DevelopServices: false,
                allowP11LimitedDataSelectContent: false,
                allowSf1PreciseGeoData: false,
                allowSf2ScanDevice: false,
                allowStatck42PurposesPersonalizedAd: false,
                allowStatck1SFGeoDevice: false,
                allowLegitimateInterestPurpose: false,
                allowFirstPartyCookie: false,
                disselectedVendorId: [],
                selectedVendorId: [],
                vendorListVersion: 0,
                lastConsentDateUtc: '',
            } as GdprAds2023Options,
            [OwsOptionsFeatureType.AutoCorrect]: {
                feature: OwsOptionsFeatureType.AutoCorrect,
                isAutoCorrectEnabled: true,
                isAutoCapitalizationEnabled: true,
            } as AutoCorrectOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (85,44): Conversion of type '{ feature: OwsOptionsFeatureType.GdprAdsV3; encodedTCString: null; allStoreAndAccessDevice: false; allowDevelopAndImproveProduct: false; allowPersonalisedAds: false; ... 12 more ...; selectedVendorId: never[]; }' to type 'GdprAdsV3Options' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.GdprAdsV3]: {
                feature: OwsOptionsFeatureType.GdprAdsV3,
                encodedTCString: null,
                allStoreAndAccessDevice: false,
                allowDevelopAndImproveProduct: false,
                allowPersonalisedAds: false,
                allowPersonalisedAdsSelectBasicAd: false,
                allowPersonalisedAdsApplyMarketResearch: false,
                allowPersonalisedAdsMeasureContentPerformance: false,
                allowPersonalisedAdsSelectPersonalisedAds: false,
                allowPersonalisedAdsMeasureAdPerf: false,
                allowPersonalisedAdsSelectPersonalisedContent: false,
                allowPersonalisedAdsCreatePersonalisedContentProfile: false,
                allowPersonalisedAdsCreatePersonalisedAdsProfile: false,
                allowPreciseGeoDataAndIdentifyDevice: false,
                allowPreciseGeoDataAndIdentifyDeviceScanDevice: false,
                allowPreciseGeoDataAndIdentifyDeviceGeoData: false,
                disselectedVendorId: [],
                selectedVendorId: [],
            } as GdprAdsV3Options,
            [OwsOptionsFeatureType.CalendarSurfaceAddins]: {
                feature: OwsOptionsFeatureType.CalendarSurfaceAddins,
                calendarSurfaceAddins: [],
            } as CalendarSurfaceAddinsOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (112,49): Conversion of type '{ feature: OwsOptionsFeatureType.ExternalImages; externalImagesSelectedOption: null; }' to type 'ExternalImagesOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.ExternalImages]: {
                feature: OwsOptionsFeatureType.ExternalImages,
                externalImagesSelectedOption: null,
            } as ExternalImagesOptions,
            [OwsOptionsFeatureType.EventCapture]: {
                autoCollectionEnabled: false,
            } as EventCaptureOptions,
            [OwsOptionsFeatureType.Translation]: {
                feature: OwsOptionsFeatureType.Translation,
                translationMode: 0,
                targetLanguage: '',
                excludedLanguages: [],
            } as TranslationOptions,
            [OwsOptionsFeatureType.CalendarSurfaceOptions]: {
                feature: OwsOptionsFeatureType.CalendarSurfaceOptions,
                /** default to undefined to distinguish between null (unset) and pending value fetch as we only
                 * want to display notifcation if we're certain we're in a new time zone */
                lastKnownRoamingTimeZone: undefined,
                /** default to undefined distinguish between unset (true/false) and pending value fetch */
                roamingTimeZoneNotificationsIsDisabled: undefined,
                allDayWellHeight: 0 /** default AllDayWellHeight to 0 as we want the panel to only exist if user
                manually expands it*/,
                numDaysInDayRange: 1 /** default NumDaysInRange to 1 as that should be the default Day View state
                unless the user manually changes preference */,
                timeScaleSetting: 30 /** default TimeScaleSetting to 30 */,
                isDynamicColumnWidthEnabled: true,
            } as CalendarSurfaceOptions,
            [OwsOptionsFeatureType.MentionEventNotifications]: {
                feature: OwsOptionsFeatureType.MentionEventNotifications,
                enabled: false,
            } as MentionEventNotificationsOptions,
            [OwsOptionsFeatureType.ComposeAssistance]: {
                feature: OwsOptionsFeatureType.ComposeAssistance,
                composeAssistanceEnabled: false,
            } as ComposeAssistanceOptions,
            [OwsOptionsFeatureType.ActivityFeed]: {
                feature: OwsOptionsFeatureType.ActivityFeed,
                OWASurfaceOptions: {
                    SupportedTypes: [],
                    DisabledInFeedPanel: [],
                },
            } as ActivityFeedOptions,
            [OwsOptionsFeatureType.LinkedInViewProfile]: {
                dismissed: false,
            } as LinkedInViewProfileOptions,
            [OwsOptionsFeatureType.CalendarHelp]: {
                feature: OwsOptionsFeatureType.CalendarHelp,
                calendarHelpEnabled: false,
            } as CalendarHelpOptions,
            // Bohemia is deprecated, but it's not safe to remove it from the code because each feature type is enumarated and might be stored
            // keeping it with OwsOptionsBase
            [OwsOptionsFeatureType.Bohemia]: fluidOption,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (183,43): Conversion of type '{ feature: OwsOptionsFeatureType.Proofing; spellCheckEnabled: true; grammarEnabled: true; writingRefinementsEnabled: false; proofingLocale: undefined; overriddenOptions: undefined; isTonalFreExperienceEnabled: false; }' to type 'ProofingOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.Proofing]: {
                feature: OwsOptionsFeatureType.Proofing,
                spellCheckEnabled: true,
                grammarEnabled: true,
                writingRefinementsEnabled: false,
                proofingLocale: undefined,
                overriddenOptions: undefined,
                isTonalFreExperienceEnabled: false,
                isEditorIndicatorEnabled: false,
                isToneEnabledInEditorIndicator: false,
                shouldShowIndicatorButton: false,
                areMxLanguagesSeeded: true,
                isLanguageAutoDetectEnabled: false,
            } as ProofingOptions,
            [OwsOptionsFeatureType.ActiveProxyAddress]: {
                feature: OwsOptionsFeatureType.ActiveProxyAddress,
                activeProxyAddresses: [],
            } as ActiveProxyAddressOptions,
            [OwsOptionsFeatureType.SxS]: {
                feature: OwsOptionsFeatureType.SxS,
                hideReadingPane: false,
                defaultEditCommand: EditOptionsCommand.EditInBrowser,
            } as SxSOptions,
            [OwsOptionsFeatureType.ReadingPaneConversation]: {
                feature: OwsOptionsFeatureType.ReadingPaneConversation,
                conversationEnabled: true,
                conversationEnabledNativeHost: false,
            } as ReadingPaneConversationOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (209,41): Conversion of type '{ feature: OwsOptionsFeatureType.Search; defaultSearchScope: undefined; }' to type 'SearchOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.Search]: {
                feature: OwsOptionsFeatureType.Search,
                defaultSearchScope: undefined,
            } as SearchOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (216,42): Conversion of type '{ feature: OwsOptionsFeatureType.StartUp; defaultReopenItems: undefined; }' to type 'StartUpOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.StartUp]: {
                feature: OwsOptionsFeatureType.StartUp,
                defaultReopenItems: undefined,
            } as StartUpOptions,
            [OwsOptionsFeatureType.KeyboardShortcuts]: {
                feature: OwsOptionsFeatureType.KeyboardShortcuts,
                keyboardShortcutsMode: MonarchKeyboardShortcutsMode.OutlookForWindows,
            } as KeyboardShortcutsOptions,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2352 (227,53): Conversion of type '{ feature: OwsOptionsFeatureType.EmailHeaderContent; inboxShoppingEnabled: undefined; }' to type 'EmailHeaderContentOptions' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
            // @ts-expect-error
            [OwsOptionsFeatureType.EmailHeaderContent]: {
                feature: OwsOptionsFeatureType.EmailHeaderContent,
                inboxShoppingEnabled: undefined,
            } as EmailHeaderContentOptions,
            [OwsOptionsFeatureType.PinnedPane]: {
                feature: OwsOptionsFeatureType.PinnedPane,
                pinnedPane: PinnablePane.None,
            } as PinnedPaneOptions,
            [OwsOptionsFeatureType.NativeAdsRemoveUpsell]: {
                feature: OwsOptionsFeatureType.NativeAdsRemoveUpsell,
                currentSessionNumber: 0,
            } as NativeAdsRemoveUpsellOptions,
            [OwsOptionsFeatureType.EditorAutoFormat]: {
                feature: OwsOptionsFeatureType.EditorAutoFormat,
                autoBulletEnabled: true,
                autoNumberingEnabled: true,
                autoFractionsEnabled: true,
                autoHyphenEnabled: true,
                autoOrdinalsEnabled: true,
            } as EditorAutoFormatOptions,
            [OwsOptionsFeatureType.PremiumStatusInPrimarySettings]: {
                feature: OwsOptionsFeatureType.PremiumStatusInPrimarySettings,
                overallPremiumStatusBit: 0,
                premiumCloudCacheAccounts: [],
            } as PremiumStatusInPrimarySettingsOptions,
            [OwsOptionsFeatureType.UseSystemDarkMode]: {
                feature: OwsOptionsFeatureType.UseSystemDarkMode,
                useSystemDarkMode: false,
            } as UseSystemDarkModeOptions,
            [OwsOptionsFeatureType.ComposeFormInPopOut]: {
                feature: OwsOptionsFeatureType.ComposeFormInPopOut,
                composeFormInPopOutEnabled: false,
            } as ComposeFormInPopOutOptions,
            [OwsOptionsFeatureType.Dictation]: {
                feature: OwsOptionsFeatureType.Dictation,
                isAutoPunctuationEnabled: null,
                dictationLanguage: null,
            } as DictationOptions,
            [OwsOptionsFeatureType.DefaultPasteType]: {
                feature: OwsOptionsFeatureType.DefaultPasteType,
                pasteFromEmailsAndEvents: PasteType.KeepSourceFormatting,
                pasteFromOtherPrograms: PasteType.MergeFormatting,
            } as DefaultPasteTypeOption,
            [OwsOptionsFeatureType.CopilotChatFREOptions]: {
                feature: OwsOptionsFeatureType.CopilotChatFREOptions,
                shouldCopilotPaneAutoOpen: false,
            } as CopilotChatFREOptions,
        },
    };
}

export default () => owsOptionsStoreMap;
