import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isMsalEnabledForBusiness } from 'owa-msaljs/lib/isMsalEnabledForBusiness';
import { isMsalEnabledForConsumer } from 'owa-msaljs/lib/isMsalEnabledForConsumer';
import {
    getTokenApi as getTokenApiUsingServer,
    getTokenApiUsingMSAL,
    getTokenApiUsingHostApp,
} from './initialize';
import type {
    TokenRequestParams,
    EnterpriseTokenRequestParams,
    ConsumerTokenRequestParams,
} from './schema/TokenRequestParams';
import type { AuthTokenResponse } from './schema/AuthTokenResponse';
import type { IGetTokenApi } from './IGetTokenApi';
import { ScenarioType } from './schema/Scenario';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Remove reference to getHostValue
 *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
import { getHostValue } from 'owa-config';
import { shouldFetchFromSTS } from './utils/shouldFetchFromSTS';

export async function getToken(params: TokenRequestParams): Promise<AuthTokenResponse | undefined> {
    if (params.scenarioType == ScenarioType.Enterprise) {
        return getTokenForEnterprise(params as EnterpriseTokenRequestParams);
    } else {
        return getTokenForConsumer(params as ConsumerTokenRequestParams);
    }
}

function getTokenForEnterprise(
    params: EnterpriseTokenRequestParams
): Promise<AuthTokenResponse | undefined> {
    let authApi: IGetTokenApi;

    if (shouldFetchFromSTS(params.resource)) {
        authApi = getTokenApiUsingServer();
    }
    // Fetch token from HostApp
    else if (isHostAppFeatureEnabled('resourceTokenFromHost')) {
        const hostApp = getHostValue();
        authApi = getTokenApiUsingHostApp(hostApp);
    }
    // Fetch token from MSAL
    else if (isMsalEnabledForBusiness()) {
        authApi = getTokenApiUsingMSAL();
    }
    // Fetch token from Server as a fallback
    else {
        authApi = getTokenApiUsingServer();
    }

    return authApi.getToken(params);
}

function getTokenForConsumer(params: TokenRequestParams): Promise<AuthTokenResponse | undefined> {
    let authApi: IGetTokenApi;

    if (params.resource && shouldFetchFromSTS(params.resource)) {
        authApi = getTokenApiUsingServer();
    }
    // Fetch token from HostApp
    else if (isHostAppFeatureEnabled('resourceTokenFromHost')) {
        const hostApp = getHostValue();
        authApi = getTokenApiUsingHostApp(hostApp);
    }
    // Fetch token from MSAL
    else if (isMsalEnabledForConsumer(params.mailboxInfo, params.resource)) {
        authApi = getTokenApiUsingMSAL();
    }
    // Fetch token from Server as a fallback
    else {
        authApi = getTokenApiUsingServer();
    }

    return authApi.getToken(params);
}
