import type { NewOutlookToggleState } from 'owa-client-pie/lib/outlookwebcontext.g';
import {
    LegacyApp,
    ToggleProgressState,
    setNewOutlookToggleState,
    setToggleProgressState,
} from 'owa-client-pie/lib/outlookwebcontext.g';
import { isPieNamespaceGTE } from './isPieNamespaceGTE';
import { lazyGetToggleState } from './lazyGetToggleState';

/**
 * The OutlookToggle class is responsible for getting, setting, and caching the
 * toggle information.
 */
export class OutlookToggleImpl {
    private state: NewOutlookToggleState | undefined;
    private getStatePromise: Promise<NewOutlookToggleState> | undefined;
    constructor() {
        this.state = undefined;
    }

    // Gets the toggle state, we only need to get this once per session
    public async get(): Promise<NewOutlookToggleState> {
        if (this.state !== undefined) {
            // we already have the state just return the value
            return this.state;
        }

        if (this.getStatePromise === undefined) {
            this.getStatePromise = lazyGetToggleState.importAndExecute();
        }

        this.state = await this.getStatePromise;

        return this.state;
    }

    public async set(newState: NewOutlookToggleState): Promise<void> {
        // We must always get the state before we set the state
        await this.get();

        // If user has toggled in from Win32, we disregard the last toggled in app.
        // So that user will always go back to Win32 if user toggled in from both apps (Win32 and Universal).
        if (
            this.state?.isToggleOn &&
            this.state?.legacyApp === LegacyApp.OutlookDesktop &&
            newState.isToggleOn
        ) {
            this.state = {
                isToggleOn: true,
                legacyApp: LegacyApp.OutlookDesktop,
            };
        } else {
            this.state = newState;
        }

        if (await isPieNamespaceGTE('OutlookWebContext', 1.09)) {
            return setToggleProgressState(
                this.state.isToggleOn
                    ? ToggleProgressState.ToggledIn
                    : ToggleProgressState.ToggledOut,
                this.state.legacyApp
            );
        }

        return setNewOutlookToggleState(newState);
    }
}
