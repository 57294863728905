import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getApplicationSettings } from 'owa-application-settings';
import { getGuid } from 'owa-guid';
import { getAccessTokenforResourceAsLazy } from 'owa-tokenprovider';

// The resource is needed for pinning apps to and unpinning apps from the App Launcher.
const webShellApiName = 'fetchWebShellForShell';
// The resource is needed for getting the profile picture of Me Control.
const graphApiName = 'fetchGraphForShell';

// The resource is needed for getting MRU document list in the App Launcher.
const officeApiName = 'fetchOfficeApiForShell';

type TokenRequestDictionary = {
    [resource: string]: Promise<string> | (() => Promise<string>);
};

async function fetchShellToken(apiName: string): Promise<string> {
    let resource: string = '';

    // getApplicationSettings has to be in the function to avoid it is called too early.
    switch (apiName) {
        case webShellApiName:
            resource = getApplicationSettings('PreAuthShellResourceUrls').webShellResourceUrl;
            break;
        case graphApiName:
            resource = getApplicationSettings('PreAuthShellResourceUrls').graphResourceUrl;
            break;
        case officeApiName:
            resource = getApplicationSettings('PreAuthShellResourceUrls').officeApiResourceUrl;
            break;
    }

    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        resource,
        apiName /* apiName */,
        getGuid() /* requestId */,
        undefined /* targetTenantId */,
        undefined /* wwwAuthenticateHeader */,
        undefined /* showFullTokenResponse */,
        undefined /* preferIdpToken */,
        getGlobalSettingsAccountMailboxInfo() /*mailbox info*/
    );
    if (!token) {
        token = (await tokenPromise) as string;
    }

    if (!!token) {
        if (typeof token === 'object' && token?.accessToken) {
            return token.accessToken;
        }
        if (typeof token === 'string') {
            return token;
        }
    }

    return '';
}

export const fetchTokenForShell: () => TokenRequestDictionary = () => {
    // The resource URL in the keys must be the actual value of the resource URL rather than the variable name.
    return {
        'https://webshell.suite.office.com': () => {
            return fetchShellToken(webShellApiName);
        },
        'https://graph.microsoft.com': () => {
            return fetchShellToken(graphApiName);
        },
        'https://api.office.net': () => {
            return fetchShellToken(officeApiName);
        },
    };
};
