//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MailFolderNodeFragmentDoc } from './MailFolderNode.interface';
export type MailFolderNodeTreeFragment = {
    __typename?: 'MailFolder';
    displayName: string;
    distinguishedFolderType?: string | null;
    childFolderIds?: Array<string> | null;
    mailboxInfo: any;
    parentFolderId: string;
    totalMessageCount: number;
    UnreadCount: number;
    type?: string | null;
    remoteFolderInfo?: {
        __typename?: 'RemoteFolderInfo';
        remoteFolderDisplayName?: string | null;
    } | null;
    EffectiveRights?: {
        __typename?: 'EffectiveRightsType';
        Modify?: boolean | null;
        Delete?: boolean | null;
    } | null;
};
export const MailFolderNodeTreeFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MailFolderNodeTree' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailFolder' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'distinguishedFolderType' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childFolderIds' },
                        directives: [
                            { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
                        ],
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteFolderInfo' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remoteFolderDisplayName' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mailboxInfo' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailFolderNode' } },
                ],
            },
        },
        ...MailFolderNodeFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MailFolderNodeTreeFragment, unknown>;
