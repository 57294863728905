import isTeamsProCustomizationUser from './isTeamsProCustomizationUser';
import isTeamsProManagementUser from './isTeamsProManagementUser';
import isTeamsProProtectionUser from './isTeamsProProtectionUser ';
import isTeamsProVirtualAppointmentsUser from './isTeamsProVirtualAppointmentsUser';
import isTeamsProWebinarUser from './isTeamsProWebinarUser';

function isTeamsPremiumUser() {
    return (
        isTeamsProCustomizationUser() ||
        isTeamsProManagementUser() ||
        isTeamsProProtectionUser() ||
        isTeamsProVirtualAppointmentsUser() ||
        isTeamsProWebinarUser()
    );
}

export default isTeamsPremiumUser;
