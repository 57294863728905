import type { ApolloLink } from '@apollo/client';
import { onErrorLink } from '../links/onErrorLink';
import { ThrottleOperationLink } from '../links/ThrottleOperationLink';
import type { ResolverImports } from './createGraphServerLink';
import { createGraphServerLink } from './createGraphServerLink';
import sanitizeVariablesLink from '../links/sanitizeVariablesLink';
import { analyticsLink } from '../links/analyticsLink';
import { remoteGraphLink } from '../links/remoteGraphLink';

/**
 * These links are used on the main renderer.
 */
export function createMainLinks(
    createDataWorkerLink: () => ApolloLink,
    context: Record<string, any>,
    resolverImports: ResolverImports
): Array<ApolloLink> {
    // Start downloading all the javascript at once for performance
    const graphServerLink = createGraphServerLink(context, resolverImports, remoteGraphLink());
    return [
        // after the policy loader, the first link is the data worker link.  if the data worker flight is ON, the worker link will forked the request over to the
        // worker for execution.  if the flight is OFF, then the worker link will forward to the remaining links below for execution on main
        createDataWorkerLink(),
        // *** The links below will not execute if the request is forked over the worker, above ***/
        onErrorLink,
        // All operations marked throttled will execute one-by-one
        new ThrottleOperationLink(),
        // Link to make a copy of variables, convert mobx objects to plain old data, remove extra fields like __typename, etc.
        sanitizeVariablesLink(),
        // analytics link
        analyticsLink(),
        graphServerLink,
    ];
}
