import getLocalFieldsOrder from './getLocalFieldsOrder';
import type { PersonaPostalAddress } from 'owa-graph-schema';
import { isNullOrWhiteSpace } from 'owa-string-utils';

const CommaSeparator = ', ';

/**
 * Returns a string representaion of the postal address.
 * @param field1, field2, field3, field4, field5: the fields in the postal address. theu can be null or empty.
 * @param divider: the separator between the address fiends
 */
function computeAddress(
    field1?: string | null,
    field2?: string | null,
    field3?: string | null,
    field4?: string | null,
    field5?: string | null,
    divider?: string
): string[] {
    const address: string[] = [];

    // Field1 is in the first line
    if (field1 && !isNullOrWhiteSpace(field1)) {
        address[address.length] = field1;
    }

    // Field2, Field3 and Field4 are all in the second line
    let row2 = '';

    if (field2 && !isNullOrWhiteSpace(field2)) {
        row2 = field2;
    }

    if (field3 && !isNullOrWhiteSpace(field3)) {
        if (isNullOrWhiteSpace(row2)) {
            row2 = field3;
        } else {
            row2 = row2 + divider + field3;
        }
    }

    // (n-1)th field
    if (field4 && !isNullOrWhiteSpace(field4)) {
        if (isNullOrWhiteSpace(row2)) {
            row2 = field4;
        } else {
            row2 = row2 + divider + field4;
        }
    }

    if (!isNullOrWhiteSpace(row2)) {
        address[address.length] = row2;
    }

    // Last Field
    if (field5 && !isNullOrWhiteSpace(field5)) {
        address[address.length] = field5;
    }

    return address;
}

/**
 * Gets the display text from the location postal address detail
 * @param postalAddress: the location postal address
 */
export function getLocalizedAddressFromDetails(
    street?: string | null,
    city?: string | null,
    postalCode?: string | null,
    state?: string | null,
    country?: string | null,
    rowDivider?: string
): string {
    let displayAddress = '';
    const fieldsOrder = getLocalFieldsOrder();
    let address: string[] = [];
    const divider = CommaSeparator;

    switch (fieldsOrder) {
        case 'a_c_pc_pr_ctry':
            address = computeAddress(street, city, postalCode, state, country, divider);
            break;

        case 'a_c_pr_ctry_pc':
            address = computeAddress(street, city, state, country, postalCode, divider);
            break;

        case 'a_c_pr_pc_ctry':
            address = computeAddress(street, city, state, postalCode, country, divider);
            break;

        case 'a_ctry_c_pr_pc':
            address = computeAddress(street, country, city, state, postalCode, divider);
            break;

        case 'a_pc_c_pr_ctry':
            address = computeAddress(street, postalCode, city, state, country, divider);
            break;

        case 'a_pc_pr_c_ctry':
            address = computeAddress(street, postalCode, state, city, country, divider);
            break;

        case 'c_a_pc_pr_ctry':
            address = computeAddress(city, street, postalCode, state, country, divider);
            break;

        case 'ctry_pc_pr_c_a':
            address = computeAddress(country, postalCode, state, city, street, divider);
            break;

        case 'ctry_pr_c_pc_a':
            address = computeAddress(country, state, city, postalCode, street, divider);
            break;

        case 'pc_ctry_pr_c_a':
            address = computeAddress(postalCode, country, state, city, street, divider);
            break;

        case 'pc_pr_c_a_ctry':
            address = computeAddress(postalCode, state, city, street, country, divider);
            break;
    }

    displayAddress = address.join(rowDivider ?? CommaSeparator);

    return displayAddress;
}

/**
 * Gets the display text from the location postal address
 * @param postalAddress: the location postal address
 */
export function getLocalizedAddress(postalAddress: PersonaPostalAddress): string {
    if (postalAddress) {
        return getLocalizedAddressFromDetails(
            postalAddress.Street,
            postalAddress.City,
            postalAddress.PostalCode,
            postalAddress.State,
            postalAddress.Country
        );
    }

    return '';
}
