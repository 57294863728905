import { updateUserConfigurationAndService } from 'owa-userconfiguration/lib/utils/updateUserConfigurationAndService';
import { frequentlyUsedFolders } from '../store/store';
import { trace } from 'owa-trace';

// save frequently used folder list to the server
export default function saveFrequentlyUsedFolders() {
    const serializedFolders: string[] = [];
    frequentlyUsedFolders.forEach(folder => {
        serializedFolders.push(JSON.stringify(folder));
    });

    updateUserConfigurationAndService({
        UserOptions: {
            FrequentlyUsedFolders: serializedFolders,
        },
    }).catch(error => {
        // no-op if FUF fails to update
        trace.warn(`saveFrequentlyUsedFolders: ${error}`);
    });
}
