import { isAttachmentOfReferenceType } from 'owa-attachment-type/lib/isAttachmentOfReferenceType';
import getDataProviderInfo from '../../utils/DataProviderInfo/getDataProviderInfo';
import type { AttachmentPolicyInfo } from 'owa-attachment-policy';
import { AttachmentPolicyLevel } from 'owa-attachment-policy';
import type AttachmentType from 'owa-service/lib/contract/AttachmentType';
import type ReferenceAttachment from 'owa-service/lib/contract/ReferenceAttachment';
import { isFluidFile } from 'owa-file/lib/utils/isFluidFile';
import type { MailboxInfo } from 'owa-client-types';

export default function getAllowDownload(
    attachmentPolicyInfo: AttachmentPolicyInfo,
    attachment: AttachmentType,
    extension: string | undefined
): boolean {
    let downloadAllowed =
        attachmentPolicyInfo.level !== AttachmentPolicyLevel.Block &&
        attachmentPolicyInfo.directFileAccessEnabled;
    if (downloadAllowed && isAttachmentOfReferenceType(attachment)) {
        downloadAllowed = isReferenceAttachmentDownloadable(
            attachment,
            extension,
            attachmentPolicyInfo.mailboxInfo
        );
    }

    return downloadAllowed;
}

// The guest invitation is something groups team did when they share a file with an external recipient,
// and that url cannot be downloaded as it goes through multiple redirects in order to access the file.
const GUEST_INVITATION_URL_KEYWORD: string = 'b7780972f918485c8cfc32b1ba7438eb';

function dataProviderSupportDownload(providerType: string, mailboxInfo: MailboxInfo): boolean {
    const dataProviderInfo = getDataProviderInfo(providerType, mailboxInfo);
    return dataProviderInfo?.supportDownload;
}

function mightBeOneNoteNotebook(
    extension: string | undefined,
    contentType: string | undefined
): boolean {
    // Notebooks don't have a file extension.
    // If the content type is set to OneNote and no extension, it's a OneNote notebook.
    // If the content type is not set or is set to the default "image/png", it could be an old attachment or is created by another client,
    // so we still need to assume that it's a notebook.
    // [ VSO - 17242 Add contentType property to the files folder for reference attachments.
    // Till then we should explicictly check if its undefined.
    // If its null we are making an assumption that it could be a one note.]
    return (
        !extension &&
        (!contentType || contentType === 'application/onenote' || contentType === 'image/png')
    ); // We set "image/png" as default value in order not to show reference attachment in down-level client
}

export function isReferenceAttachmentDownloadable(
    attachment: ReferenceAttachment,
    extension: string | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    if (attachment.AttachLongPathName) {
        const parser = document.createElement('a');
        parser.href = attachment.AttachLongPathName;

        if (parser.search.indexOf(GUEST_INVITATION_URL_KEYWORD, 0) > 0) {
            return false;
        }
    }

    // VSO - 17242 Add AttachmentIsFolder property to the files folder for reference attachments.
    if (
        attachment.ProviderType &&
        dataProviderSupportDownload(attachment.ProviderType, mailboxInfo) &&
        !attachment.AttachmentIsFolder
    ) {
        if (isFluidFile(extension)) {
            return false;
        }

        return !mightBeOneNoteNotebook(extension, attachment.ContentType);
    }

    return false;
}
