import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostActions"*/ './lazyIndex')
);

export const lazyFetchAndOpenLaunchPageMetaOsApp = new LazyAction(
    lazyModule,
    m => m.fetchAndOpenLaunchPageMetaOsApp
);
