import { type HttpStatusCode } from 'owa-http-status-codes';
import { logUsage } from 'owa-analytics';

// 1 minute
const DEFAULT_TIMEOUT_MS: number = 60000;
const MAX_RETRIES: number = 3;

/**
 * Method to fetch data with timeout.
 * @param url
 * @param requestInit
 * @param timeoutMS
 * @param retries
 * @returns Promise<string>
 */

export interface LogData {
    logIdentifier: string;
    customData: {
        [key: string]: string | number | boolean;
    };
}

export const fetchWithTimeoutAndRetries = async (
    url: string,
    logData: LogData,
    requestInit: RequestInit = {},
    timeoutMS: number = DEFAULT_TIMEOUT_MS,
    retries: number = MAX_RETRIES
): Promise<string | null> => {
    if (retries < 0) {
        return null;
    }

    const { logIdentifier, customData } = logData;
    let controller: AbortController | undefined;

    if ('AbortController' in window) {
        controller = new AbortController();
        requestInit.signal = controller.signal;
    }

    const timerHandle: number = window.setTimeout(() => {
        if (controller) {
            window.clearTimeout(timerHandle);
            controller.abort();
            return fetchWithTimeoutAndRetries(url, logData, requestInit, timeoutMS, retries - 1);
        } else {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logUsage(`${logIdentifier}_FetchTimeout`, customData);
            return null;
        }
    }, timeoutMS);

    try {
        const response = await fetch(url, requestInit);
        window.clearTimeout(timerHandle);

        // If the response is OK, return the blob
        if (response?.status && [200].indexOf(response.status) !== -1) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logUsage(`${logIdentifier}_FetchSuccess`, {
                ...customData,
                retries: MAX_RETRIES - retries,
            });
            return objectURL;
        }
        // If the response is not OK, retry
        return fetchWithTimeoutAndRetries(url, logData, requestInit, timeoutMS, retries - 1);
    } catch {
        // Keep retrying until we run out of retries
        return fetchWithTimeoutAndRetries(url, logData, requestInit, timeoutMS, retries - 1);
    }
};
