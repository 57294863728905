import type DocLink from 'owa-service/lib/contract/DocLink';
import type { DocLink as Schema_DocLink } from 'owa-graph-schema';
import { attachmentPermissionTypeToOws } from './attachmentPermissionType';

export function convertDocLinkToOws(docLink: Schema_DocLink): DocLink {
    return {
        __type: 'DocLink',
        ...docLink,
        ProviderType: docLink.ProviderType || undefined,
        Url: docLink.Url || undefined,
        PermissionType: attachmentPermissionTypeToOws(docLink.PermissionType),
    };
}
