import { createStore } from 'satcheljs';
import type NudgeStore from './schema/NudgeStore';

const nudgeStore: NudgeStore = {
    nudgedRows: [],
};

const store = createStore<NudgeStore>('nudgeStore', nudgeStore)();
export default store;
export const getStore = () => store;
