import React from 'react';

type EventHandlerFunction = (event: MouseEvent) => void;

type EventSubscriberMap = Map<React.RefObject<any>, EventHandlerFunction>;

const eventSubscriberMapByEventTarget = new Map<EventTarget, EventSubscriberMap>();

const eventTargetMapByContainerReference = new Map<React.RefObject<EventTarget>, EventTarget>();

const contextMenuEventName = 'contextmenu';

function subscribeToContextMenuEvent(
    containerReference: React.RefObject<EventTarget>,
    handlerRef: React.RefObject<null>,
    handler: (event: MouseEvent) => void
) {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const containerTarget = containerReference.current!;
    const subscribers = getEventSubscribers(containerTarget);
    if (subscribers.size === 0) {
        containerTarget.addEventListener(contextMenuEventName, onEvent);
        eventTargetMapByContainerReference.set(containerReference, containerTarget);
    }
    subscribers.set(handlerRef, handler);
}

function getEventSubscribers(containerReference: EventTarget): EventSubscriberMap {
    if (!eventSubscriberMapByEventTarget.has(containerReference)) {
        eventSubscriberMapByEventTarget.set(
            containerReference,
            new Map<React.RefObject<any>, EventHandlerFunction>()
        );
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return eventSubscriberMapByEventTarget.get(containerReference)!;
}

function onEvent(event: Event) {
    const containerReference = event.currentTarget ?? window;
    const eventSubscriberMap = eventSubscriberMapByEventTarget.get(containerReference);

    if (eventSubscriberMap) {
        const values = eventSubscriberMap?.values();
        if (values) {
            for (const handler of values) {
                handler(event as any);
            }
        }
    }
}

function unsubscribeToContextMenuEvent(
    containerReference: React.RefObject<EventTarget>,
    handlerRef: React.RefObject<any>
) {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const containerTarget = eventTargetMapByContainerReference.get(containerReference)!;
    const subscribers = getEventSubscribers(containerTarget);
    subscribers.delete(handlerRef);
    if (subscribers.size === 0) {
        containerTarget.removeEventListener(contextMenuEventName, onEvent);
        eventTargetMapByContainerReference.delete(containerReference);

        cleanUpeventSubscriberMapByEventTargetIfNecessary(containerTarget);
    }
}

function cleanUpeventSubscriberMapByEventTargetIfNecessary(containerTarget: EventTarget) {
    const eventSubscriberToEventTargetMap = eventSubscriberMapByEventTarget.get(containerTarget);

    if (eventSubscriberToEventTargetMap) {
        let isNoMoreSubscriberToEventTarget = true;

        for (const [_, subscribers] of Object.entries(eventSubscriberToEventTargetMap)) {
            if (subscribers.size > 0) {
                isNoMoreSubscriberToEventTarget = false;
                break;
            }
        }

        if (isNoMoreSubscriberToEventTarget) {
            eventSubscriberMapByEventTarget.delete(containerTarget);
        }
    }
}

// Creates a single event handler for the context menu event (right click) and reuses it instead of
// creating a new handler to the DOM for each component using this hook.
export function useContextMenuEvent(
    handler: (event: MouseEvent) => void,
    containerReference: React.RefObject<EventTarget>
): void {
    const handlerRef = React.useRef(null);

    React.useEffect(() => {
        if (containerReference?.current) {
            subscribeToContextMenuEvent(containerReference, handlerRef, handler);
        }

        return () => {
            if (eventTargetMapByContainerReference.get(containerReference)) {
                unsubscribeToContextMenuEvent(containerReference, handlerRef);
            }
        };
    }, [containerReference]);
}
