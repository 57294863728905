import type { IContextualMenuItem, Point } from '@fluentui/react';
import { createStore } from 'satcheljs';

interface AppContextMenuState {
    point?: Point;
    contextualMenuItems: IContextualMenuItem[];
    showContextMenu: boolean;
}

const appContextMenuStateStore = createStore<AppContextMenuState>('appContextMenuState', {
    point: undefined,
    contextualMenuItems: [],
    showContextMenu: false,
})();

const getAppContextMenuStateStore = () => appContextMenuStateStore;
export default getAppContextMenuStateStore;
