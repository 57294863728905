import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ItemCopyMutationWeb"*/ './lazyIndex')
);

export const lazyCopyItemMutationWeb = createLazyResolver(
    'MOVEITEM_MUTATION_WEB',
    lazyModule,
    m => m.copyItemMutationWeb
);
