import type {
    QueryFullBirthdayCalendarEventArgs,
    ResolverContext,
    GraphQLResolveInfo,
    FullCalendarEventResult,
} from 'owa-graph-schema';
import { lazyGetBirthdayEventService } from 'owa-calendar-services';
import { mapBirthdayCalendarItemToGql } from 'convert-calendar-item';

export const birthdayCalendarEventQueryWeb = async (
    _parent: {},
    args: QueryFullBirthdayCalendarEventArgs,
    _context: ResolverContext,
    _info: GraphQLResolveInfo
): Promise<FullCalendarEventResult> => {
    const { id, parentFolderId, dateRange } = args.input;
    try {
        const idInput = {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            Id: id.Id!,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            mailboxInfo: id.mailboxInfo!,
            ChangeKey: id.ChangeKey ?? undefined,
        };
        const response = await lazyGetBirthdayEventService.importAndExecute(idInput);
        return {
            ...response,
            calendarEvent: response.calendarEvent
                ? mapBirthdayCalendarItemToGql(
                      response.calendarEvent,
                      parentFolderId,
                      dateRange,
                      id.mailboxInfo ?? undefined
                  )
                : null,
        };
    } catch (error) {
        return { error: `[birthdayCalendarEventQueryWeb]  ${error}` };
    }
};
