import { mutator } from 'satcheljs';
import onAppAddSearchEventListener from '../actions/onAppAddSearchEventListener';
import onAppClearSearchListeners from '../actions/onAppClearSearchListeners';
import getStore from '../store/appHostSearchStore';
import { onOpenMetaOsApp } from 'owa-app-host-actions';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onAppAddSearchEventListener, actionMessage => {
    getStore().eventRegistrationsMap.set(actionMessage.eventName, actionMessage.eventListener);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onAppClearSearchListeners, () => {
    getStore().eventRegistrationsMap.clear();
});

mutator(onOpenMetaOsApp, () => {
    getStore().eventRegistrationsMap.clear();
});
