import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { updateUserConfiguration } from 'owa-session-store/lib/actions/updateUserConfiguration';
export type FavoritesBitFlagsMasks = any;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
export function getIsBitEnabled(bitMask: FavoritesBitFlagsMasks): boolean {
    const favoritesBitFlags = getUserConfiguration().UserOptions?.FavoritesBitFlags;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2532 (27,25): Object is possibly 'undefined'.
    // @ts-expect-error
    return !!(bitMask & favoritesBitFlags);
}

/**
 * Set the n th bit value in local user configuration to 1 or 0 depends on the value
 * @param value set to 1 if true, 0 otherwise
 * @param bitMask represents the nth bit
 */
export function setBit(value: boolean, bitMask: FavoritesBitFlagsMasks) {
    updateUserConfiguration(userConfig => {
        if (value) {
            // Set the bit to 1
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (42,13): Object is possibly 'undefined'.
            // @ts-expect-error
            userConfig.UserOptions.FavoritesBitFlags |= bitMask;
        } else {
            // Clear the bit to 0
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (48,13): Object is possibly 'undefined'.
            // @ts-expect-error
            userConfig.UserOptions.FavoritesBitFlags &= ~bitMask;
        }
    });
}
