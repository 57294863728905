import type ApplicationSettingsStore from './schema/ApplicationSettingsStore';

import { defaultApplicationSettings } from './applicationSettingsDefinitions';

import { getLocalOverrides } from '../utils/localOverrides';
import type { MailboxInfo } from 'owa-client-types';
import { getFeatureFlagsIndexerForMailboxInfo } from 'owa-feature-flags';

const settingsByAccount: Map<string, ApplicationSettingsStore> = new Map();

function getApplicationSettingsStore(
    mailboxInfo: MailboxInfo | undefined
): ApplicationSettingsStore {
    return {
        settings: defaultApplicationSettings,
        overrides: getLocalOverrides(mailboxInfo),
        configIDs: undefined,
        lastError: undefined,
        initialized: false,
        report: undefined,
        searchKey: undefined,
    };
}

export default function getStore(mailboxInfo: MailboxInfo | undefined) {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    let rv = settingsByAccount.get(key);

    if (!rv) {
        const store = getApplicationSettingsStore(mailboxInfo);
        settingsByAccount.set(key, store);
        rv = store;
    }

    return rv;
}

export function areApplicationSettingsInitialized(mailboxInfo: MailboxInfo) {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    const settings = settingsByAccount.get(key);
    return settings?.initialized;
}

// Allow the unit tests to reset the store
export function resetStoreForUnitTests() {
    settingsByAccount.clear();
}

// Allow the unit tests to get the values by key
export function getApplicationsSettingsByKeyForUnitTests(
    key: string
): ApplicationSettingsStore | undefined {
    return settingsByAccount.get(key);
}
