import { mutatorAction } from 'satcheljs';

import { default as getPendingAccountStore } from '../store/pendingAccountsStore';
import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import { setItem } from 'owa-local-storage';
import { ACCOUNTS_MIGRATION_PENDING_ACCOUNTS_POST_IMPORT_KEY } from '../utils/constants';

/**
 * Sets the pending accounts in the store
 */
const setPendingAccounts = mutatorAction(
    'SET_PENDING_ACCOUNTS',
    function setPendingAccounts(pendingAccounts: PendingAccountInfo[]) {
        setItem(
            window,
            ACCOUNTS_MIGRATION_PENDING_ACCOUNTS_POST_IMPORT_KEY,
            JSON.stringify(pendingAccounts)
        );

        getPendingAccountStore().pendingAccounts = pendingAccounts;
    }
);

export default setPendingAccounts;
