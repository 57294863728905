import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import type { RequestOptions } from 'owa-service/lib/RequestOptions';
import { isMailboxRequestOptions } from 'owa-service/lib/MailboxRequestOptions';
import { getExplicitLogonSync } from 'owa-anchormailbox/lib/getExplicitLogon';
import { logGreyError } from 'owa-analytics';

const ExplicitLogonUserHeader = 'x-owa-explicitlogonuser';

/**
 * When a coprincipal account is a shared/delegate mailbox, we need to include the SMTP address as
 * the explicit logon in the request. This function will set the explicit logon user in the request
 * headers if needed.
 * @param options
 */
export default function setCoprincipalAccountExplicitLogonUserIfNeeded(
    options?: RequestOptions | MailboxRequestOptions
) {
    try {
        if (
            options?.headers &&
            options?.headers?.get &&
            typeof options.headers.get === 'function' &&
            options.headers.get(ExplicitLogonUserHeader) == null &&
            isMailboxRequestOptions(options)
        ) {
            const explicitLogonUser = getExplicitLogonSync(options.mailboxInfo);
            if (!!explicitLogonUser) {
                // We are doing an explicit logon for a coprincipal account, set the header
                // and mark that we should retry auth for request if it fails
                options.headers.append(ExplicitLogonUserHeader, explicitLogonUser);
                options.retryAuthForExplicitLogon = true;
            }
        }
    } catch (e) {
        // this is not expected, but rather than crashing the request, log the error and continue
        logGreyError('ErrorGettingAccountExplicitUser', e);
    }
}
