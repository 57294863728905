import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ItemMoveMutationWeb"*/ './lazyIndex')
);

export const lazyMoveItemMutationWeb = createLazyResolver(
    'MOVEITEM_MUTATION_WEB',
    lazyModule,
    m => m.moveItemMutationWeb
);

export const lazyUndoMoveItemMutationWeb = createLazyResolver(
    'UNDOMOVEITEM_MUTATION_WEB',
    lazyModule,
    m => m.undoMoveItemMutationWeb
);
