import type CalendarEvent from 'owa-calendar-types/lib/types/CalendarEvent';
import type { ProviderPropertiesEntry } from 'owa-graph-schema';
import { getCurrentCulture } from 'owa-localize';
import { getAccountScopeUserSettings } from 'owa-session-store';

const getCoordinatesForMeetingOptions = (event: CalendarEvent | null): string | null => {
    if (!event) {
        return null;
    }
    // Online meetings created using the new experience will have meeting options url as part of the response.
    const providerProperties = event.OnlineMeeting?.providerProperties as ProviderPropertiesEntry[];
    if (providerProperties?.length > 0) {
        const meetingOtionsLink = providerProperties.find(
            property => property?.key === 'links.options'
        )?.value as string;

        // Get the query string part of the meeting options url as coordinates.
        if (meetingOtionsLink) {
            return new URL(meetingOtionsLink).searchParams.toString();
        }
    }

    // Fall back to the old way of getting coordinates.
    return getCoordinatesFallback(event);
};

const getCoordinatesFallback = (event: CalendarEvent): string | null => {
    // TODO: this may need to be changed based on ongoing discussions, but keeping as is because it's a fallback.
    const sessionSettings = getAccountScopeUserSettings(event.ParentFolderId.mailboxInfo);

    const tenantId = sessionSettings?.SessionSettings?.ExternalDirectoryTenantGuid;
    const userId = sessionSettings?.SessionSettings?.ExternalDirectoryUserGuid;
    const threadId = event.SkypeTeamsProperties?.cid;
    const messageId: number = event.SkypeTeamsProperties?.mid || 0;

    if (!tenantId || !userId || !threadId) {
        return null;
    }

    return `organizerId=${userId}&tenantId=${tenantId}&threadId=${threadId}&messageId=${messageId}&language=${getCurrentCulture()}`;
};

export default getCoordinatesForMeetingOptions;
