import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getParseFolderViewStateWithSortConversionToString } from '../utils/folderViewStateUtils';
import { MailSortHelper } from 'owa-mail-list-store';
import getDefaultFolderView from '../selectors/getDefaultFolderView';
import { getFolderTable } from 'owa-folders';
import debounce from 'lodash-es/debounce';
import { logCoreUsage } from 'owa-analytics';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import type { QueuedActionOptions } from 'owa-queued-actions';
import { mutate } from 'owa-apollo';
import type { MutationFunctionOptions } from '@apollo/client';
import {
    UpdateFolderViewStateDocument,
    type UpdateFolderViewStateMutation,
    type UpdateFolderViewStateMutationVariables,
} from '../graphql/__generated__/UpdateFolderViewState.interface';

const updateUserConfigurationDebounced = debounce(
    (serializedFolderViewState, mailboxInfo) => {
        logCoreUsage('UpdateFolderViewState', {});
        const mutationOptions: MutationFunctionOptions<
            UpdateFolderViewStateMutation,
            UpdateFolderViewStateMutationVariables
        > &
            QueuedActionOptions = {
            variables: {
                mailboxInfo,
                updates: {
                    ViewStateConfiguration: {
                        FolderViewState: serializedFolderViewState,
                    },
                },
            },
            queuedAction: {
                blockingKeys: [],
                resultProcessor: 'Default',
                flight: 'fwk-aq-userConfig',
                replacesKey: 'FolderViewState',
            },
        };

        mutate(UpdateFolderViewStateDocument, mutationOptions);
    },
    5000,
    { leading: true, trailing: true }
);

/**
 * Delay update the folder view state
 */
export default function updateFolderViewState(folderViewState: string[], mailboxInfo: MailboxInfo) {
    const serializedFolderViewState: string[] = [];
    const defaultSortBy = MailSortHelper.getDefaultSortBy();

    for (let i = 0; i < folderViewState.length; i++) {
        // It is not necessary to store default view state on server, we are filtering folders with
        // default view state before updating server.
        const parsedFolderViewState = getParseFolderViewStateWithSortConversionToString(
            folderViewState[i]
        );

        const mailFolder = getFolderTable().get(parsedFolderViewState.FolderId.Id);

        // Only consider IsExpanded for mail folder that has children. If the mail folder cannot be found
        // for any reason, we do not skip its state.
        const ignoreIsExpandedFromFolderViewState =
            !!!mailFolder || mailFolder.childFolderCount > 0
                ? !parsedFolderViewState.IsExpanded
                : true;

        const viewStateMailboxInfo = mailFolder?.mailboxInfo;
        // Check to see if the folder is from a different mailbox, if so don't save if
        // if there is no information, don't remove it, as the folder may just not be in the folder table yet
        const isDifferentMailbox = viewStateMailboxInfo
            ? getIndexerValueForMailboxInfo(viewStateMailboxInfo) !==
              getIndexerValueForMailboxInfo(mailboxInfo)
            : false;

        if (
            isDifferentMailbox ||
            (ignoreIsExpandedFromFolderViewState &&
                parsedFolderViewState.SortColumn === defaultSortBy.sortColumn &&
                parsedFolderViewState.SortOrder === defaultSortBy.sortDirection &&
                parsedFolderViewState.ReadingPanePosition ===
                    getUserConfiguration().UserOptions?.GlobalReadingPanePositionReact &&
                parsedFolderViewState.View ===
                    getDefaultFolderView(parsedFolderViewState.FolderId.Id))
        ) {
            continue;
        }

        serializedFolderViewState.push(folderViewState[i]);
    }

    updateUserConfigurationDebounced(serializedFolderViewState, mailboxInfo);
}
