import setMsHighContrastMode from '../mutators/setMsHighContrastMode';
import { type MsHighContrastMode } from '../store/schema/MsHighContrastMode';
import { getStore } from '../store/store';

/**
 * We cannot use addEventListener until macOS BigSur+ is minimum for OWA. Before that,
 * MediaQueryList does not inherit from EventTarget. See PR 71489 for how to make
 * the change when it does.
 */
export default function initializeIsMsHighContrastState() {
    const mediaQuery = window.matchMedia(
        'screen and (-ms-high-contrast: active), screen and (forced-colors: active)'
    );
    mediaQuery.addListener(handleHighContrastActiveChange);
    handleHighContrastActiveChange.call(mediaQuery);

    const mediaQueryHcWhiteOnBlack = window.matchMedia(
        'screen and (-ms-high-contrast: white-on-black), screen and (forced-colors: active) and (prefers-color-scheme: dark)'
    );
    mediaQueryHcWhiteOnBlack.addListener(handleHighContrastWhiteOnBlackChange);
    handleHighContrastWhiteOnBlackChange.call(mediaQueryHcWhiteOnBlack);

    const mediaQueryHcBlackOnWhite = window.matchMedia(
        'screen and (-ms-high-contrast: black-on-white), screen and (forced-colors: active) and (prefers-color-scheme: light)'
    );
    mediaQueryHcBlackOnWhite.addListener(handleHighContrastBlackOnWhiteChange);
    handleHighContrastBlackOnWhiteChange.call(mediaQueryHcBlackOnWhite);
}

// media query change
function handleHighContrastActiveChange(this: MediaQueryList, _?: MediaQueryListEvent) {
    // toggle store value if media query match is different than current state
    if (!this.matches && getStore().mode !== 0) {
        setMsHighContrastMode(0);
    }
}

function handleHighContrastWhiteOnBlackChange(this: MediaQueryList, _?: MediaQueryListEvent) {
    // toggle store value if media query match is different than current state
    if (this.matches && getStore().mode !== 2) {
        setMsHighContrastMode(2);
    }
}

function handleHighContrastBlackOnWhiteChange(this: MediaQueryList, _?: MediaQueryListEvent) {
    // toggle store value if media query match is different than current state
    if (this.matches && getStore().mode !== 1) {
        setMsHighContrastMode(1);
    }
}
