import { getStore } from 'owa-mail-store/lib/store/Store';
import { mutatorAction } from 'satcheljs';
import { isFeatureEnabled } from 'owa-feature-flags';

export const updateShouldOpportunisticallyFetch = mutatorAction(
    'updateShouldOpportunisticallyFetch',
    function updateShouldOpportunisticallyFetch(allowOpportunisticFetch: boolean) {
        if (
            isFeatureEnabled('rp-fetchOnDemand') &&
            getStore().shouldOpportunisticallyFetch != allowOpportunisticFetch
        ) {
            getStore().shouldOpportunisticallyFetch = allowOpportunisticFetch;
        }
    }
);
