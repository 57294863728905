import { observer } from 'owa-mobx-react';
import FavoriteCategoryNode from '../FavoriteCategoryNode';
import FavoriteFolderNode from '../FavoriteFolderNode';
import FavoriteGroupNode from '../FavoriteGroupNode';
import FavoritePersonaNode from '../FavoritePersonaNode';
import type { FavoriteNodeCommonProps } from '../FavoriteNode';
import { getStore as getSharedFavoritesStore } from 'owa-favorites';
import folderStore from 'owa-folders';
import { getFavoriteNodeViewStateFromId } from 'owa-mail-favorites-store';
import React from 'react';
import type {
    FavoriteFolderData,
    FavoriteGroupData,
    FavoriteCategoryData,
    FavoritePersonaData,
    FavoritePublicFolderData,
} from 'owa-favorites-types';
import { getAccountAuthStateForMailboxInfo, StateTypeEnum } from 'owa-account-authstate-store';

export default observer(function FavoriteNodeV2(props: FavoriteNodeCommonProps) {
    const createFolderFavorite = (favoriteFolderData: FavoriteFolderData): JSX.Element | null => {
        const folderId = favoriteFolderData.folderId;
        const folder = folderStore.folderTable.get(folderId);
        if (folder) {
            return (
                <FavoriteFolderNode
                    key={props.favoriteId}
                    folderId={folderId}
                    ellipsesOnHover={props.ellipsesOnHover}
                    {...props}
                />
            );
        }
        return null;
    };
    const createGroupFavorite = (favoriteGroupData: FavoriteGroupData) => {
        return (
            <FavoriteGroupNode
                key={props.favoriteId}
                displayName={favoriteGroupData.displayName}
                groupId={favoriteGroupData.groupId}
                mailboxInfo={favoriteGroupData.mailboxInfo}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const createCategoryFavorite = (favoriteCategoryData: FavoriteCategoryData) => {
        return (
            <FavoriteCategoryNode
                key={props.favoriteId}
                categoryId={favoriteCategoryData.categoryId}
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (65,17): Type 'DropViewState | undefined' is not assignable to type 'DropViewState'.
                // @ts-expect-error
                dropViewState={getFavoriteNodeViewStateFromId(props.favoriteId).drop}
                mailboxInfo={favoriteCategoryData.mailboxInfo}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const createPersonaFavorite = (favoritePersonaData: FavoritePersonaData) => {
        if (favoritePersonaData.mainEmailAddress) {
            return (
                <FavoritePersonaNode
                    key={props.favoriteId}
                    displayName={favoritePersonaData.displayName}
                    emailAddress={favoritePersonaData.mainEmailAddress}
                    personaId={favoritePersonaData.personaId ?? ''}
                    searchFolderId={favoritePersonaData.searchFolderId ?? ''}
                    isSearchFolderPopulated={favoritePersonaData.isSearchFolderPopulated}
                    mailboxInfo={favoritePersonaData.mailboxInfo}
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2322 (89,21): Type 'DropViewState | undefined' is not assignable to type 'DropViewState'.
                    // @ts-expect-error
                    dropViewState={getFavoriteNodeViewStateFromId(props.favoriteId).drop}
                    isJustAdded={false}
                    ellipsesOnHover={props.ellipsesOnHover}
                    {...props}
                />
            );
        }
        return null;
    };
    const createPublicFolderFavorite = (favoritePublicFolderData: FavoritePublicFolderData) => {
        return (
            <FavoriteFolderNode
                key={props.favoriteId}
                folderId={favoritePublicFolderData.publicFolderId}
                isPublicFolder={true}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const favoritesStore = getSharedFavoritesStore();
    if (favoritesStore.outlookFavorites.has(props.favoriteId)) {
        const favoriteData = favoritesStore.outlookFavorites.get(props.favoriteId);
        if (
            favoriteData &&
            getAccountAuthStateForMailboxInfo(favoriteData.mailboxInfo).stateType ===
                StateTypeEnum.NoError
        ) {
            switch (favoriteData?.type) {
                case 'folder':
                    return createFolderFavorite(favoriteData as FavoriteFolderData);
                case 'group':
                    return createGroupFavorite(favoriteData as FavoriteGroupData);
                case 'category':
                    return createCategoryFavorite(favoriteData as FavoriteCategoryData);
                case 'persona':
                    return createPersonaFavorite(favoriteData as FavoritePersonaData);
                case 'publicFolder':
                    return createPublicFolderFavorite(favoriteData as FavoritePublicFolderData);
                default:
                    throw new Error('FavoriteNodeV2 - favorite kind not supported');
            }
        }
    }
    // Invalid unknown data
    return null;
}, 'FavoriteNodeV2');
