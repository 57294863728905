import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "DataWorkerClient" */ './lazyIndex'),
    { name: 'DataWorkerClient' }
);

export const lazyExecuteRequest = new LazyAction(lazyModule, m => m.executeRequest);
export const lazyUnsubscribe = new LazyAction(lazyModule, m => m.unsubscribe);
export const lazySetDisableAllRequests = new LazyAction(lazyModule, m => m.setDisableAllRequests);
export const lazyFlushSyncLogs = new LazyAction(lazyModule, m => m.flushSyncLogs);
export const lazyOnTableReloaded = new LazyAction(lazyModule, m => m.onTableReloaded);
export const lazyOnManualSyncMail = new LazyAction(lazyModule, m => m.onManualSyncMail);
export const lazySaveOfflineSessionData = new LazyAction(lazyModule, m => m.saveOfflineSessionData);
export const lazyGetOfflineTimeZones = new LazyAction(lazyModule, m => m.getOfflineTimeZones);
export const lazyGetOfflineTimeZoneOffsets = new LazyAction(
    lazyModule,
    m => m.getOfflineTimeZoneOffsets
);
export const lazyScheduleHierarchySync = new LazyAction(lazyModule, m => m.scheduleHierarchySync);
export const lazyOnManualSyncCalendar = new LazyAction(lazyModule, m => m.onManualSyncCalendar);
export const lazyApplyCalendarEventsSyncLieForRange = new LazyAction(
    lazyModule,
    m => m.applyCalendarEventsSyncLieForRange
);
