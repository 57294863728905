import { lazyGetFolderMenuOptionsSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onFolderMenuOptionsMenuClicked = () => {
    lazyGetFolderMenuOptionsSections.import().then(getFolderMenuOptions => {
        setMenuDefinition(
            'FolderMenuOptionsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getFolderMenuOptions(),
                };
            }
        );
    });
};
