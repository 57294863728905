import { LazyAction, LazyImport, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "Notification"*/ './lazyIndex'),
    {
        name: 'Notification',
    }
);

// Lazy functions for notification consumers
export const lazySubscribe = new LazyAction(lazyModule, m => m.subscribe);
export const lazyUnsubscribe = new LazyAction(lazyModule, m => m.unsubscribe);
export const lazyRetryAllDisconnectionsWithMinimumReconnectInterval = new LazyAction(
    lazyModule,
    m => m.retryAllDisconnectionsWithMinimumReconnectInterval
);
export const lazyRetryDisconnection = new LazyAction(
    lazyModule,
    m => m.NotificationManager.retryDisconnection
);
export const lazySetup = new LazyAction(lazyModule, m => m.setup);
export const lazyHandleNotification = new LazyAction(lazyModule, m => m.handleNotification);
export const lazyStopAllConnections = new LazyAction(
    lazyModule,
    m => m.NotificationManager.stopAllConnections
);

// Lazy functions for channel initialization
export const lazyInitializeNotificationManager = new LazyAction(
    lazyModule,
    m => m.initializeNotificationManager
);

export const lazyLogNotificationConnectionStatus = new LazyAction(
    lazyModule,
    m => m.NotificationManager.getConnectionStatusesForAllAccounts
);
export const lazyGetNotificationEmitter = new LazyImport(lazyModule, m => m.getNotificationEmitter);

// lazy export for default session handler to reduce boot size increase.
export const lazyDefaultSessionTimeoutHandler = new LazyAction(
    lazyModule,
    m => m.defaultSessionTimeoutHandler
);
export const lazyInitializeNotificationForAccounts = new LazyAction(
    lazyModule,
    m => m.initializeNotificationsForAccounts
);

export type {
    default as NotificationSubscription,
    MailboxNotificationSubscription,
    AllMailboxNotificationSubscription,
} from './schema/NotificationSubscription';

export { default as NotificationEventType } from './schema/NotificationEventType';

export type { default as NotificationCallback } from './schema/NotificationCallback';

export { SubscriptionStatus, type SubscriptionState } from './schema/SubscriptionTrackerState';

export type { NotificationPayloadWithDiagnostics } from './schema/NotificationPayloadWithDiagnostics';
