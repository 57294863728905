// documentation for adding colors: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/4626/Colors
export const DARK_THEME_NEUTRALS = {
    black: '#FFFFFF',
    blackTranslucent50: 'rgba(255, 255, 255, 0.5)',
    blackTranslucent40: 'rgba(255, 255, 255, 0.4)',
    blackTranslucent20: 'rgba(255, 255, 255, 0.2)',
    blackTranslucent10: 'rgba(255, 255, 255, 0.1)',
    white: '#000000',
    whiteTranslucent40: 'rgba(0, 0, 0, 0.4)',
    whiteTranslucent50: 'rgba(0, 0, 0, 0.5)',
    whiteTranslucent70: 'rgba(0, 0, 0, 0.7)',
    whiteTranslucent90: 'rgba(0, 0, 0, 0.9)',
    neutralDark: '#FFFFFF',
    neutralPrimary: '#D6D6D6',
    neutralPrimaryAlt: '#ADADAD',
    neutralPrimarySurface: '#292929',
    neutralSecondary: '#858585',
    neutralSecondaryAlt: '#979593',
    neutralSecondarySurface: '#141414',
    neutralTertiary: '#666666',
    neutralTertiaryAlt: '#525252',
    neutralTertiarySurface: '#0A0A0A',
    neutralQuaternary: '#2E2E2E',
    neutralLight: '#3D3D3D',
    neutralQuaternaryAlt: '#333333',
    neutralLighter: '#141414',
    neutralLighterAlt: '#1F1F1F',
    redDark: '#B94D52',
    green20: '#6BA02B',
    purple: '#B65FC2',
    suiteuxNeutralLight: '#555555',
    suiteuxNeutralSecondary: '#FFFFFF',

    flaggedMessage: '#242100',
    richUserContentBackground: '#EBEBEB',
    composeNeutralBackground: '#242424',
    composeNeutralLighterBackground: '#fff',
    readingPaneCardBorder: '#000000',
    readingPaneCardFocusBorder: '#D1D1D1',

    oobeWhite: '#ffffff',
    oobePrimary: '#0078d4',
    oobeDarkAlt: '#106EBE',
    oobeThemeLighter: '#DEECF9',

    cardStyleWhite: '#484644',
    freeBusyAwayColor: '#F500D8',

    // https://www.figma.com/file/c5TF4jwqrpms2PSQH3EXtFTc/Fluent-Color?node-id=3301%3A115
    messageWebWarning: '#4d3a0c',
    messageMobileWarningPrimary: '#ffd335',
    messageMobileWarningShade20: '#ffdd15',
    messageMobileWarningShade30: '#ffdd87',
    messageMobileWarningTint30: '#4d3a0c',
    messageMobileWarningTint40: '#291F07',
    communicationBlueWebPrimary: '#2899f5',
    messageMobileDangerPrimary: '#e83a3a',
    messageMobileDangerShade20: '#ee6666',
    messageMobileDangerTint30: '#461111',
    messageMobileDangerTint40: '#250909',
    messageMobileSuccessPrimary: '#0eb244',
    presenceAway: '#f8d22a',
    presenceDoNotDisturb: '#d74553',
    presenceOnline: '#92c353',
    presenceInvisible: '#979593',
    presenceOOF: '#e959d9',

    // Addison component picker button gradient
    addisonComponentButtonGradientStart: '#f7f0ff',
    addisonComponentButtonGradientEnd: '#e9dfff',

    storageCritialStateColor: '#E37D80',
    storageCritialStateHoverColor: '#DC5E62',
    storageNearingStateColor: '#F2C661',
    storageNearingStateHoverColor: '#EFB839',
    storageUsageBarBorder: '#292929',

    bizBarRed: '#442726',
    msqOneDrive: '#637CEF',
    msqTeams: '#9A44FC',
    msqOutlook: '#E3008C',
    msqWindows: '#D64400',
    msqEdge: '#1BA756',
    msqLoop: '#2AA0A4',
    msqDesigner: '#9373C0',

    //Places Explore colors
    placesPrimary: '#583DFF',

    // Places linear gradient section
    placesLightBlueSolidColor: '#28DFF8',
    placesNavyBlueSolidColor: '#209DF5',

    // Places theme colors
    placesBackgroundColorPink: 'rgba(255,116,243,.2)',
    placesBackgroundColorPurple: 'rgba(205,112,255,.2)',
    placesBackgroundColorLightBlue: 'rgba(39,213,248,.2)',
    placesBackgroundColorNavy: 'rgba(9,99,121,.2)',

    // Places theme colors used in workspaces https://www.figma.com/file/3IJc3nTEB1EeHUKZjReFD8/Reserve-a-desk
    // referred to https://react.fluentui.dev/?path=/docs/theme-color--page for cross theme tokens
    placesBluePrimary: '#5caae5',
    placesBlueTint40: '#004377',
    placesLightBackground3: '#3d3d3d',
    placesLightBlueBackground: '#082338',
    placesNeutralGrey92: '#000000',

    colorBrandBackgroundInvertedSelected: '#005a9e',
    colorBrandBackgroundInvertedHover: '#004578',
    colorPaletteCornflowerBackground2: '#E1E6FC',

    placesBlueGradient1: '#2A4FFE',
    placesBlueGradient2: '#6530FC',
    placesBlueBackground1: '#E1E6FC',
    placesBlueForeground1: '#637CEF',
    placesBlueBorder1: '#2510BC',
    placesCornflowerForeground1: '#444791',
    placesCornflowerForeground2: '#93A4F4',

    placesRemoteUnspecifiedBackground1: '#4D4D4D',
    placesRemoteUnspecifiedForeground1: '#CCCCCC',
    placesOOFBackground1: '#6D2064',
    placesOOFForeground1: '#EDBBE7',

    placesRemote1: '#182047',
    placesRemoteBorder1: '#778DF1',

    // Colors for floorMap in Places https://www.figma.com/file/h7UJrieBtHnGAimIh8BjVu/Modernize-improvements
    neutralBackground6: '#333333',
    lilacBackground2: '#63276D',
    neutralForeground4: '#999999',
    neutralBackground2Pressed: '#141414',

    sharedLavenderTint10: '#8172EB',

    sharedCornflowerTint20: '#778DF1',
    sharedCornflowerTint30: '#93A4F4',
    sharedCornflowerTint50: '#E1E6FC',
    sharedCornflowerTint60: '#182047',
    sharedCornFlowerShade20: '#778DF1',

    placesPurpleOOFBackground1: '#F5DAF2',
    placesPurpleOOFForeground1: '#AF33A1',

    placesPurpleGradient1: '#6A11CB',

    boxShadowPrimary: 'rgba(0, 0, 0, 0.28)',
    boxShadowSecondary: 'rgba(0, 0, 0, 0.24)',

    places70: '#C5C1FF',
    places80: '#5B41FF',
    places80Pressed: '#4F28C1',
    places160: '#333240',

    paneLighterBackground: '#212121',

    copilotGradientStart: '#BA6DF1',
    copilotGradientEnd: '#4B9DF5',

    // TODO: These have always been the same values as light mode
    // Should they be different?
    yellow: '#ffb900',
    orange: '#d83b01',
    red: '#e81123',
    blue: '#0078d4',
    green: '107c10',

    sharedLightGreenTint50: '#CEF0CD',
    sharedDarkGreenTint30: '#4DA64D',

    // Colors for home page in Bookings
    bookingsTeal10: '#004A5A',
    bookingsTeal20: '#2E9AB3',
    bookingsTeal50: '#89CEE1',
    bookingsTeal80: '#89CEE1',
    bookingsAccentGreen10: '#052505',
    bookingsAccentGreen20: '#107C10',
    bookingsForegroundTeal60: '#263B50',
    bookingsForegroundTeal80: '#89CEE1',
    bookingsNeutralForeground1: '#FFFFFF',
    bookingsNeutralForeground2: '#E0E0E0',
    bookingsNeutralForeground4: '#E0E0E0',
    bookingsRedForeground1: '#E37D80',
    bookingsNeutralStroke1: '#616161',
    bookingsNeutralBackground1: '#333333',
    bookingsGreenConsistent: '#0EB244',
    bookingsGrayConsistent: '#424242',
    bookingsGrayBackground1: '#141414',
    bookingsGrayLightBackground1: '#F3F2F1',
    bookingsSilverSandBorder1: '#C8C6C4',
    bookingsDefaultRed: '#E37D80',

    // Colors for floor map in Places. Figma: https://www.figma.com/file/185pcbvHXHDcBbksDvc7za/Maps-work
    placesOffice: '#393F46',
    placesWalkway: '#3F454D',
    placesWall: '#787878',
    placesBlueTint50: '#273D51',
    placesLightBlueShade20: '#65ADE5',
    placesCyanTint50: '#304D57',
    placesCyanShade10: '#A4DEEB',
    placesCyanShade20: '#56BFD7',
    placesNeutralForeground2: '#FFFFFF',
    placesUnavailablePin: '#ADADAD',
    placesSelectedPin: '#E50619',
    placesSeaformShade20: '#A7E3A5',
    placesSeaformShade30: '#5EC75A',
    placesLightGreenShade20: '#A7E3A5',

    // Eventify/Workplace Events specific colors
    eventifySurveyStatusIncompleteColor: '#806a1a',
    eventifySurveyStatusIncompleteBackground: '#fff2c3',
    eventifyClosedSurveyBannerBorder: '#ffd94e',
    eventifyClosedSurveyBannerColor: '#8f761e',
    eventifySurveyListItemBorder: '#d9d9d9',
    eventifySurveyStatusCompleteBackground: '#caead8',
    eventifySurveyStatusCompleteColor: '#37a660',
    eventifyEventPreviewColor: '#7a6519',
    eventifyEventCancelledBackground: '#f9d9d9',
    eventifyEventCancelledBorder: '#e87979',
    eventifyEventCancelledColor: '#a61b1b',

    // High Contrast white and black to fix visibility issues in high contrast modes
    hcBlackOnWhite: '#000000',
    hcWhiteOnBlack: '#ffffff',

    // Outlook custom colors for Fluent v9 components.
    // Figma: https://www.figma.com/design/DYS2ZPisghfHhw6N7u0Vtj/Outlook-web-variables?node-id=8609-2615&t=3gLSNnfOLDXbJ62W-1
    // Neutral colors
    customNeutralStrokeTransparent2: '#525252',
    customNeutralStrokeOnBackground1: '#999999',
    customNeutralStrokeOnBackground1Hover: '#A8A8A8',
    customNeutralStrokeOnBackground1Pressed: '#9E9E9E',
    // Stable colors
    customStableForegroundLink: '#479EF5',
    customStableForegroundLinkHover: '#62ABF5',
    customStableForegroundLinkPressed: '#2886DE',
    customStableForegroundLinkSelected: '#479EF5',

    // Calendar Work Hours border
    calendarWorkHoursBorder: '#6b6b6b',
};

export const BASE_DARK_THEME_PALETTE = {
    ...DARK_THEME_NEUTRALS,
    themeDarker: '#B4D6FA',
    themeDark: '#77B7F7',
    themeDarkAlt: '#62ABF5',
    themePrimary: '#479EF5',
    themeSecondary: '#2B88D8',
    themeTertiary: '#115EA3',
    themeLight: '#0A2E4A',
    themeLighter: '#082338',
    themeLighterAlt: '#061724',
    themeSecondarySurfaceSelected: '#0A2E4A',
    headerBackground: '#479EF5',
    headerBackgroundSearch: '#479EF5',
    headerBrandText: '#000000',
    headerTextIcons: '#000000',
    headerSearchBoxBackground: 'rgba(33,32,33,0.7)',
    headerSearchBoxIcon: '#B4D6FA',
    headerSearchPlaceholderText: '#B4D6FA',
    headerSearchButtonBackground: '#479EF5',
    headerSearchButtonBackgroundHover: '#B4D6FA',
    headerSearchButtonIcon: '#000000',
    headerSearchFilters: '#479EF5',
    headerSearchFiltersHover: '#B4D6FA',
    headerButtonsBackground: '#479EF5',
    headerButtonsBackgroundHover: '#B4D6FA',
    headerButtonsBackgroundSearch: '#479EF5',
    headerButtonsBackgroundSearchHover: '#B4D6FA',
    headerBadgeBackground: '#B4D6FA',
    headerBadgeText: '#000000',
    headerSearchIcon: '#000000',
    searchBoxBackground: 'rgba(33,32,33,0.7)',
};
