import { getMailboxBySourceId } from './sourceList';
import getSourceIdForUserIdentity from './getSourceIdForUserIdentity';
import { getCoprincipalAccounts } from 'owa-account-source-list-store';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

import type { MailboxInfo } from 'owa-client-types';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { trace } from 'owa-trace';

// We only want to log the error once per alias
const knownUserIdentities = new Set<string>();

// Checks to see if the passed in user identity matches an alias of the account
function checkForMatchingAlias(userIdentity: string) {
    if (!knownUserIdentities.has(userIdentity)) {
        // Attempt to locate the account user identity by alias
        const accountUserIdentityByAlias = getCoprincipalAccounts()
            .filter(account => account.aliases.includes(userIdentity))
            .map(account => account.mailboxInfo.userIdentity)[0];
        if (!!accountUserIdentityByAlias) {
            // The user identity matches a known alias, report this
            knownUserIdentities.add(userIdentity);
            const error = new Error('Alias passed into GetUserMailboxInfo');
            logGreyErrorFromAccounts('IncorrectUserIdentityForGetUserMailboxInfo', error);
        }
    }
}

// Defines the alternate function signature for getting the source Id
export type AltGetSourceIdFunc = (userIdentity?: string | null | undefined) => string | undefined;

// When running in Native Host with multiple accounts enabled we need to get
// the mailbox information via the source list.
function altGetUserMailboxInfo(
    altGetSourceIdFunc: AltGetSourceIdFunc,
    userIdentity?: string | null
): MailboxInfo | undefined {
    // We need to call getOWAConnectedAccount in the event that getSourceIdForUserIdentity
    // is unable to find a source ID. This can happen when there is a mismatch between the
    // user identity passed into this function and the user identity in the source store,
    // such as an account for which the userIdentity does not match the SMTP address
    const sourceId = getSourceIdForUserIdentity(userIdentity) || altGetSourceIdFunc(userIdentity);
    if (sourceId) {
        const mailbox = getMailboxBySourceId(sourceId);
        if (mailbox) {
            return mailbox;
        }
    }

    if (!isPersistenceIdIndexerEnabled() && !!userIdentity) {
        checkForMatchingAlias(userIdentity);
    }

    trace.warn('FailedToFindMailboxViaSourceId');
    return undefined;
}

// Define the funciton signature for getting the mailbox information via user identity
type altGetUserMailboxInfoFunc = (userIdentity?: string | null) => MailboxInfo | undefined;

// The do nothing version of the altGetSourceIdFunc
function noopAltGetSourceIdFunc(_userIdentity?: string | null): string | undefined {
    return undefined;
}

/**
 * Builds the altGetUserMailboxInfo function with the provided altGetSourceIdFunc
 * @param altGetSourceIdFunc Specifies the alternate function to get the source ID
 * @returns Function for getting the mailbox information
 */
export function makeAltGetUserMailboxInfo(
    altGetSourceIdFunc: AltGetSourceIdFunc | undefined
): altGetUserMailboxInfoFunc {
    return (userIdentity?: string | null) => {
        return altGetUserMailboxInfo(altGetSourceIdFunc || noopAltGetSourceIdFunc, userIdentity);
    };
}
