import {
    getOptionsForFeature,
    OwsOptionsFeatureType,
    lazyCreateOrUpdateOptionsForFeature,
} from 'owa-outlook-service-options';
import type { PinnedPaneOptions, PinnablePane } from 'owa-outlook-service-options';

export async function savePinnedPaneSetting(newValue: PinnablePane): Promise<void> {
    const userOptions = getOptionsForFeature<PinnedPaneOptions>(OwsOptionsFeatureType.PinnedPane);
    if (userOptions.pinnedPane !== newValue) {
        return lazyCreateOrUpdateOptionsForFeature.importAndExecute(
            OwsOptionsFeatureType.PinnedPane,
            {
                ...userOptions,
                pinnedPane: newValue,
            } as PinnedPaneOptions
        );
    }
}
