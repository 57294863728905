import type { MailboxInfo } from 'owa-client-types';

// Define the sourceId used for the place holder mailbox info
const anonymousSourceId = '00000000-0000-0000-0000-000000000000';

// The one and only anonymous mailbox info
const anonymousMailboxInfo: MailboxInfo = Object.freeze({
    sourceId: anonymousSourceId,
    type: 'UserMailbox',
    userIdentity: '',
    mailboxSmtpAddress: '',
    isAnonymous: true,
    diagnosticData: 'AnonymousMailboxInfo',
});

/**
 * Gets the Anonymous MailboxInfo
 * @returns The Anonymous MailboxInfo for the application
 */
export default function getAnonymousMailboxInfo(): MailboxInfo {
    return anonymousMailboxInfo;
}
