import { MAIN_WINDOW_ID, getStore } from '../store/menuDefinitionStore';

import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { MenuDefinitionStoreNames } from '../store/menuDefinitionStore';
import { mutatorAction } from 'satcheljs';

/**
 * Setter function to set the MenuDefinitionGetter for a given storeName and <projectionTabId | "main_window">.
 */
export const setMenuDefinition = mutatorAction(
    'setMenuDefinition',
    function setMenuDefinition(
        storeName: MenuDefinitionStoreNames,
        projectionTabId: string | undefined,
        menuDefinition: () => MenuDefinition
    ) {
        const id = projectionTabId || MAIN_WINDOW_ID;

        if (!getStore().menuDefinitionMapManager.has(id)) {
            // No store created for this projectionTabId, so we exit early.
            // We don't make one as the *ProjectionRibbon.tsx manages creation + garbage collection.
            return;
        }
        getStore()
            .menuDefinitionMapManager.get(id)
            ?.MenuDefinitionMap.set(storeName, menuDefinition);
    }
);
