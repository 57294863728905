import { getScriptPath, getScriptBackupPath } from 'owa-config';
import sleep from 'owa-sleep';
import { errorThatWillCauseAlert } from 'owa-trace';
import { default as rawSchemaUri } from '../__generated__/schema.all.min.graphql';

let types: Promise<string> | undefined;
const retryDelayMs = 250;

export async function loadTypes(): Promise<string> {
    if (!types) {
        types = new Promise<string>(async (resolve, reject) => {
            try {
                const schema = await loadSchema();
                resolve(schema.text());
            } catch (e) {
                // setting undefined will retry if loadtypes is called again
                types = undefined;
                reject(e);
            }
        });
    }

    return types;
}

async function loadSchema(): Promise<Response> {
    let rv: Response | null = null;

    const RETRIES = 3;

    // try a few times against the main CDN
    for (let i = 0; i < RETRIES && rv == null; ++i) {
        rv = await internalLoadSchema(getScriptPath());
    }

    // try a few times against the backup
    for (let i = 0; i < RETRIES && rv == null; ++i) {
        // throw on the final attempt
        rv = await internalLoadSchema(getScriptBackupPath());
    }

    // try once more and throw if we fail
    if (!rv) {
        rv = await internalLoadSchema(getScriptBackupPath(), true /* fatal */);
    }

    if (!rv) {
        // unreachable because we're passing fatal to the last call but ts doesn't know that.
        throw new Error('Failed to load schema');
    }

    return rv;
}

async function internalLoadSchema(
    scriptPath: string,
    fatal: boolean = false
): Promise<Response | null> {
    let fetchPromise = null;
    let uri = rawSchemaUri?.toString() || '';
    const owaPublicPath = /^OwaPublicPath/;
    if (uri.match(owaPublicPath)) {
        // this would mean something went wrong with the OwaPublicPathPlugin/workerTemplate handshake for setting
        // the path
        uri = uri.replace(owaPublicPath, scriptPath);
        errorThatWillCauseAlert(new Error('OwaPublicPath was not set before loading types'));
    }

    try {
        setPublicPath(scriptPath);
        fetchPromise = fetch(uri);
        setPublicPath(getScriptPath());

        // await here to throw any exception, but after setting the public path back
        return await fetchPromise;
    } catch (e) {
        if (fatal) {
            throw e;
        }

        await sleep(retryDelayMs);
        return null;
    }
}

function setPublicPath(path: string): string {
    if (process.env.IS_WEBPACK) {
        __webpack_public_path__ = path;
    }
    return path;
}

export function testReset() {
    types = undefined;
}
