const MAX_PREVIEW_LENGTH = 175;
const TEAMS_BLOB_PREFIX = '_____';

export default function getDisplayPreviewText(preview: string): string {
    if (!preview) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (9,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        return null;
    }

    // Chop off Teams content from the preview text.
    // The next line is our guess about where the teams blob starts. This could potentially not work in some scenarios, but since it is just preview text
    // in the peek, it's okay to start with this
    const indexWhereTeamsBlobStarts = preview.indexOf(TEAMS_BLOB_PREFIX);

    if (indexWhereTeamsBlobStarts != -1) {
        preview = preview.substring(0, indexWhereTeamsBlobStarts);
    }

    // Trim the preview text
    preview = preview.trim();
    if (preview.length < MAX_PREVIEW_LENGTH) {
        return preview;
    }

    const displayedPreviewText = preview.substr(0, MAX_PREVIEW_LENGTH).trim();

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (32,5): Type 'string | null' is not assignable to type 'string'.
    // @ts-expect-error
    return displayedPreviewText.length > 0 ? displayedPreviewText.concat('...') : null;
}
