import { LazyModule, LazyAction, LazyImport } from 'owa-bundling-light';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaM365Acquisitions" */ './index'),
    { name: 'OwaM365Acquisitions' }
);

export const lazyGetM365Acquisitions = new LazyAction(lazyModule, m => m.getM365Acquisitions);
export const lazyGetM365Acquisition = new LazyAction(lazyModule, m => m.getM365Acquisition);
export const lazyIsAddInAppStoreEnabled = new LazyImport(lazyModule, m => m.isAddInAppStoreEnabled);
export const lazyUninstallM365Acquisition = new LazyAction(
    lazyModule,
    m => m.uninstallM365Acquisition
);
