import { mutator } from 'satcheljs';
import { getStore } from '../store/Store';
import { setShowReadingPane } from '../actions/setShowReadingPane';

/**
 * Sets showReadingPane value in store to passed in value
 * @param showReadingPane Whether to show reading pane
 */
/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setShowReadingPane, actionMessage => {
    getStore().showReadingPane = actionMessage.showReadingPane;
});
