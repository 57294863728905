import { NOTIFICATION_CHANNEL_URL, OUTLOOK_GATEWAY_URL } from './constants';

export function isResourceBlocked(
    resource: string | undefined,
    resourceBlockList: string[]
): boolean {
    if (!resource) {
        return false;
    }

    const isOutlookgatewayUrl = new RegExp(OUTLOOK_GATEWAY_URL).test(resource.toLowerCase());
    const isNotificationChannelUrl = new RegExp(NOTIFICATION_CHANNEL_URL).test(
        resource.toLowerCase()
    );

    // Urls in resource block list doesn't contain trailing slash, remove any trailing slash from input url before comparing
    const resourceUrlWithoutTrailingSlash = resource.replace(/\/+$/, '');
    const isResourceUrlBlocked = resourceBlockList.includes(
        resourceUrlWithoutTrailingSlash.toLowerCase()
    );

    return isResourceUrlBlocked || isOutlookgatewayUrl || isNotificationChannelUrl;
}
