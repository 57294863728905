import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type {
    default as PopoutData,
    DeeplinkPopoutData,
} from 'owa-popout-v2/lib/store/schema/PopoutData';

export const popoutReadingPane = action(
    'POPOUT_READING_PANE',
    (
        itemId?: string,
        conversationId?: string,
        internetMessageId?: string,
        mailboxInfo?: MailboxInfo,
        data?: DeeplinkPopoutData | PopoutData,
        freezeDryId?: string,
        isFileActivation?: boolean,
        isCachedItem?: boolean
    ) => ({
        itemId,
        conversationId,
        internetMessageId,
        mailboxInfo,
        data,
        freezeDryId,
        isFileActivation,
        isCachedItem,
    })
);

export default popoutReadingPane;
