import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver, createLazySubscriptionResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupResolverWeb" */ './lazyIndex')
);

export const lazyDeleteGroupFolderMutationWeb = createLazyResolver(
    'DELETEGROUPFOLDER_MUTATION_WEB',
    lazyModule,
    m => m.deleteGroupFolderMutationWeb
);

export const lazyCreateGroupFolderMutationWeb = createLazyResolver(
    'CREATEGROUPFOLDER_MUTATION_WEB',
    lazyModule,
    m => m.createGroupFolderMutationWeb
);

export const lazyCopyMoveGroupFolderMutationWeb = createLazyResolver(
    'COPYMOVEGROUPFOLDER_MUTATION_WEB',
    lazyModule,
    m => m.copyMoveGroupFolderMutationWeb
);

export const lazyGroupDetailsQueryWeb = createLazyResolver(
    'GROUPDETAILS_QUERY_WEB',
    lazyModule,
    m => m.groupDetailsQueryWeb
);

export const lazyGroupFoldersQueryWeb = createLazyResolver(
    'GROUPFOLDERS_QUERY_WEB',
    lazyModule,
    m => m.groupFoldersQueryWeb
);

export const lazySubscribeToGroupFolderUnreadCountNotificationWeb = createLazySubscriptionResolver(
    lazyModule,
    m => m.subscribeToGroupFolderUnreadCountNotificationWeb
);

export const lazyRenameGroupFolderMutationWeb = createLazyResolver(
    'RENAMEGROUPFOLDER_MUTATION_WEB',
    lazyModule,
    m => m.renameGroupFolderMutationWeb
);

export const lazySubscribeToGroupFolderHierarchyNotificationWeb = createLazySubscriptionResolver(
    lazyModule,
    m => m.subscribeToGroupFolderHierarchyNotificationWeb
);
