import getBasePath from './getBasePath';
import { getQueryStringParameters, stringify } from 'owa-querystring';
import { removeHostedQueryParameters } from './removeHostedQueryParameters';
import { getHostLocation } from './hostLocation';
import type { vDirType } from 'owa-vdir-types';

/**
 * Get url for popout window, e.g. /mail/deeplink/compose
 * @param vdir Vdir of the popout window, either mail or calendar
 * @param route Route of the popout window, e.g. compose
 * @param skipOptInCheck Whether skip optIn check. When set to true, a param "minus" will be added to the url
 * @param parameters Additional parameters to put into querystring
 */
export default function getPopoutUrl(
    vdir: vDirType,
    route: string,
    skipOptInCheck?: boolean,
    parameters?: Record<string, string>
) {
    if (skipOptInCheck) {
        // Adding "minus" as query parameter will skip optIn check and enable deeplink for users
        // who are opted-in to People app but not to Mail app
        parameters = parameters || {};
        parameters.minus = '';
    }

    let search = '';
    if (parameters) {
        const paramPairs = getQueryStringParameters();

        // Remove any OPX-specific parameters to allow popout deeplinks launched from OPX to work correctly
        removeHostedQueryParameters(paramPairs);

        // Add any additional parameters
        Object.keys(parameters)
            .filter(p => p)
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            .forEach(key => (paramPairs[key] = parameters![key]));
        search = '?' + stringify(paramPairs);
    }

    const isHosted = getHostLocation()?.pathname.indexOf('hosted') != -1;
    const deeplinkPrefix = isHosted ? 'hosted/' : '';
    // Deeplink url e.g.: mail/deeplink/, mail/0/deeplink/
    const deeplinkPath = getBasePath(vdir) + 'deeplink/';
    const deepLinkUrl = `/${deeplinkPrefix}${deeplinkPath}`;

    return `${deepLinkUrl}${route}${search}`;
}
