import type { MailboxInfo } from 'owa-client-types';
import { getSuffixForLocalStorage } from 'owa-feature-flags';
import { getItem } from 'owa-local-storage';

export const getLocalOverrides = (mailboxInfo: MailboxInfo | undefined) => {
    const localOverrides = getItem(self, getLocalOverrideStorageKey(mailboxInfo));

    if (localOverrides) {
        try {
            return JSON.parse(localOverrides);
        } catch (e) {
            return {};
        }
    }

    return {};
};

export function getLocalOverrideStorageKey(mailboxInfo: MailboxInfo | undefined) {
    const APPLICATION_SETTING_OVERRIDES_LOCAL_STORAGE_KEY = 'applicationSettingOverrides';
    const suffix = getSuffixForLocalStorage(mailboxInfo);
    return `${APPLICATION_SETTING_OVERRIDES_LOCAL_STORAGE_KEY}${suffix}`;
}
