import { createLazyResolver } from 'owa-lazy-resolver';

export const lazyQueryConversationWeb = createLazyResolver(
    'QUERY_CONVERSATION_WEB',
    () =>
        import(
            /* webpackChunkName: "ConversationNodesWebResolverWeb"*/ './queryConversationWebResolver'
        ),
    m => m.queryConversationWebResolver
);
