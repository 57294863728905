import { LazyImport, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailRibbonSections"*/ './lazyIndex')
);

export const lazyGetAssignPolicySections = new LazyImport(
    lazyModule,
    m => m.getAssignPolicySections
);
export const lazyGetBlockSections = new LazyImport(lazyModule, m => m.getBlockSections);
export const lazyGetCategorizeSections = new LazyImport(lazyModule, m => m.getCategorizeSections);
export const lazyGetChangeNoteColorSections = new LazyImport(
    lazyModule,
    m => m.getChangeNoteColorSections
);
export const lazyGetConversationsSections = new LazyImport(
    lazyModule,
    m => m.getConversationsSections
);
export const lazyGetDeleteSections = new LazyImport(lazyModule, m => m.getDeleteSections);
export const lazyGetDensitySections = new LazyImport(lazyModule, m => m.getDensitySections);
export const lazyGetFlagUnflagSections = new LazyImport(lazyModule, m => m.getFlagUnflagSections);
export const lazyGetFolderMenuOptionsSections = new LazyImport(
    lazyModule,
    m => m.getFolderMenuOptionsSections
);
export const lazyGetForwardSections = new LazyImport(lazyModule, m => m.getForwardSections);
export const lazyGetLayoutSections = new LazyImport(lazyModule, m => m.getLayoutSections);
export const lazyGetMessagePreviewSections = new LazyImport(
    lazyModule,
    m => m.getMessagePreviewSections
);
export const lazyGetMessagesSections = new LazyImport(lazyModule, m => m.getMessagesSections);
export const lazyGetMoveToSections = new LazyImport(lazyModule, m => m.getMoveToSections);
export const lazyGetCopyToSections = new LazyImport(lazyModule, m => m.getCopyToSections);
export const lazyGetNewGroupSections = new LazyImport(lazyModule, m => m.getNewGroupSections);
export const lazyGetQuickStepsSections = new LazyImport(lazyModule, m => m.getQuickStepsSections);
export const lazyGetReadingPaneOptionsSections = new LazyImport(
    lazyModule,
    m => m.getReadingPaneOptionsSections
);
export const lazyGetReportSections = new LazyImport(lazyModule, m => m.getReportSections);
export const lazyGetRespondSections = new LazyImport(lazyModule, m => m.getRespondSections);
export const lazyGetRulesSections = new LazyImport(lazyModule, m => m.getRulesSections);
export const lazyGetSnoozeSections = new LazyImport(lazyModule, m => m.getSnoozeSections);
export const lazyGetViewGroupsSections = new LazyImport(lazyModule, m => m.getViewGroupsSections);
export const lazyGetRibbonModeMenuSections = new LazyImport(
    lazyModule,
    m => m.getRibbonModeMenuOptionsSections
);
export const lazyGetCopilotReplyWithMeetingSections = new LazyImport(
    lazyModule,
    m => m.getCopilotReplyWithMeetingSections
);
export const lazyGetTeamsChatAroundEmailSections = new LazyImport(
    lazyModule,
    m => m.getTeamsChatAroundEmailSections
);
export const lazyGetMyDayMenuSections = new LazyImport(lazyModule, m => m.getMyDayMenuSections);
