import type { PendingAccountInfo } from '../store/schema/pendingAccounts';
import { isFeatureEnabled } from 'owa-feature-flags';
import getPendingAccountsFromStore from './getPendingAccountsFromStore';
import { isPstFileAccountType as isDataFile } from 'owa-account-source-list-internal';

/**
 * Returns the list of pending data files (i.e. PST files)
 */
export default function getPendingDataFiles(): PendingAccountInfo[] {
    if (!isFeatureEnabled('acct-pstFileSupport')) {
        return [];
    }

    return getPendingAccountsFromStore().filter(pendingAccount =>
        isDataFile(pendingAccount.sourceType)
    );
}
