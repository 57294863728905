import { createStore } from 'satcheljs';
import type { PendingAccounts } from './schema/pendingAccounts';

// The default data for the pending accounts
const defaultStore: PendingAccounts = {
    pendingAccounts: [],
};

const store = createStore<PendingAccounts>('PendingAccounts', defaultStore)();
export default () => store;
