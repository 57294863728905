import { APP_BAR_STATIC_WIDTH, APP_BAR_WITH_LABELS_STATIC_WIDTH } from 'owa-layout';
import { DEFAULT_FOLDER_PANE_WIDTH } from '../internalConstants';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

import { isAppBarDisplayModeOptionsEnabled } from 'owa-feature-flags';
import type { AppBarDisplayModeOptions } from 'owa-outlook-service-options';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-options';

/**
 * Gets the current padding left of search box.
 * @returns the number of pixels
 */
export default function getSearchBoxLeftPadding(): number {
    const navBarWidth =
        getUserConfiguration().UserOptions?.NavigationBarWidth || DEFAULT_FOLDER_PANE_WIDTH;
    const folderPanePadding = 10;

    const getAppBarWidth = () => {
        if (isAppBarDisplayModeOptionsEnabled()) {
            const userOptions = getOptionsForFeature<AppBarDisplayModeOptions>(
                OwsOptionsFeatureType.AppBarDisplayMode
            );
            if (!!userOptions?.labelsEnabled) {
                return APP_BAR_WITH_LABELS_STATIC_WIDTH;
            }
        }

        return APP_BAR_STATIC_WIDTH;
    };

    return folderPanePadding + navBarWidth + getAppBarWidth(); //list view left padding;
}
