import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';
import { type TableQueryType, getStore as getListViewStore } from 'owa-mail-list-store';
import type { ObservableMap } from 'mobx';

export interface GetAllTableViewsContainingItemsForFolderState {
    tableViews: ObservableMap<string, TableView>;
}

/**
 * Get all the table views that may contain items from the given folder
 * @param the folder id
 * @param shouldIncludeSearchTable - whether to include the search table in the table list being returned
 * @param the GetAllTableViewsContainingItemsForFolderState used for getAllTableViews
 */
export default function getAllTableViewsContainingItemsForFolder(
    folderId: string,
    shouldIncludeSearchTable: boolean,
    state: GetAllTableViewsContainingItemsForFolderState = {
        tableViews: getListViewStore().tableViews,
    }
): TableView[] {
    const { tableViews } = state;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7034 (28,11): Variable 'allTableViewsOfFolder' implicitly has type 'any[]' in some locations where its type cannot be determined.
    // @ts-expect-error
    const allTableViewsOfFolder = [];
    tableViews.forEach(tableView => {
        // Include search table if specified
        if (
            tableView.tableQuery.folderId === folderId ||
            tableView.serverFolderId === folderId ||
            (shouldIncludeSearchTable && tableView.tableQuery.type === 1)
        ) {
            allTableViewsOfFolder.push(tableView);
        } else if (shouldIncludeSearchTable) {
            // favorited category and persona folders are of mail table query type, except when it's initially
            // created, which issues a search table query as fallback instead
            // search tables are not only returned by search table query calls, they can also be returned
            // by the FindItem/FindConversation mail table query calls
            const mailTableQueryScenario = (tableView.tableQuery as MailFolderTableQuery)
                .scenarioType;
            if (
                mailTableQueryScenario === 'category' ||
                mailTableQueryScenario === 'persona' ||
                mailTableQueryScenario === 'privatedistributionlist'
            ) {
                allTableViewsOfFolder.push(tableView);
            }
        }
    });
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7005 (56,12): Variable 'allTableViewsOfFolder' implicitly has an 'any[]' type.
    // @ts-expect-error
    return allTableViewsOfFolder;
}
