import React from 'react';
import { PerformanceDatapoint, logUsage } from 'owa-analytics';
import type {
    RibbonTabDefinitionBase,
    RibbonTabDefinitionMLR,
    RibbonTabDefinitionSLR,
    RibbonTabDefinitionSet,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import {
    addComponentEndRenderingTiming,
    addComponentStartRenderingTiming,
} from 'owa-analytics-shared';
import { getIsViewModeSelected, getRibbonViewMode } from 'owa-command-ribbon-store';
import type { AppPaneUnderlayViewState } from 'owa-application/lib/store/store';
import { CommandRibbonBottomBar } from './CommandRibbonBottomBar';
import { CommandRibbonTopBar } from './CommandRibbonTopBar';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import type { ControlInMenuRendererFunction } from '@1js/acui-menu/lib/ControlInMenuRendererFunction';
import type { ControlRendererFunction } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/controlRendererFunctionTypes';
import { type DatapointVariant } from 'owa-analytics-types';
import type { GovernedTask } from 'owa-tti/lib/types/GovernedTask';
import type { IconColorMap } from '../util/getRibbonIconColor';
import type { RibbonId } from 'owa-ribbon-ids';
import { RibbonKeytipLayer } from './RibbonKeytipLayer/RibbonKeytipLayer';
import { ensureAppThemeInitialized } from 'owa-acui-theme';
import { getStringRibbonId } from 'owa-ribbon-ids';
import { initializeIconColorMap } from '../util/getRibbonIconColor';
import { invokeNewEventByKeyboard } from '../actions/invokeNewEventByKeyboard';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyGovern } from 'owa-tti';
import { observer } from 'owa-mobx-react';
import { root } from './CommandRibbon.scss';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. This usage should be removed.
 *	> 'useOrchestrator' import from 'owa-react-hooks/lib/useOrchestrator' is restricted. This unorthodox end-run around satcheljs best-practices is deprecated. */
import { useOrchestrator } from 'owa-react-hooks/lib/useOrchestrator';
import { setNextStyleIsEnabled } from '@1js/acui-theme';
import { RibbonContexts } from './RibbonContexts/RibbonContexts';

export interface CommandRibbonProps {
    getSingleLineRibbonDefinition: () => RibbonTabDefinitionSet;
    getMultiLineRibbonDefinition: () => RibbonTabDefinitionSet;
    iconColorMap: IconColorMap;
    ribbonScenarioName: string;
    controlRendererFunction: (c: ControlInMenuRendererFunction) => ControlRendererFunction;
    getDefaultSelectedTabId?: (targetWindow: Window) => RibbonId;
    onTabClick?: (tabId: string, targetWindow: Window) => void;
    getHideTopRibbon?: boolean;
    getExtraTopRibbonControlsBeforeTabs?: () => JSX.Element;
    getExtraTopRibbonControlsAfterTabs?: () => JSX.Element;
    onNewEvent?: (meetingWithAll: boolean, newEventByKeyboard: boolean) => void;
    thisWindow?: Window;
    disableAnimation?: boolean;
    /** Prevents modal dialogs from adding a second keyTipLayer */
    noKeytipLayer?: boolean;
    /** Array of tasks to be lazyGovern'd on React.useEffect() */
    governedTasks?: GovernedTask[];
    projectionUnderlay?: AppPaneUnderlayViewState;
}

export default observer(function CommandRibbon(props: CommandRibbonProps) {
    React.useEffect(() => {
        if (props.governedTasks && props.governedTasks.length > 0) {
            lazyGovern.importAndExecute(...props.governedTasks);
        }
    }, []);

    // Log initial ribbon preference and only log it once
    logUsage('RibbonPreference', { ribbonType: getRibbonViewMode() }, { variant: 2 });

    const renderCount = React.useRef<number>(0);
    let perfDatapoint: PerformanceDatapoint | undefined = undefined;

    React.useEffect(() => {
        renderCount.current++;
    });

    const shouldLogPerf =
        isFeatureEnabled('mon-ribbon-telemetry') && renderCount.current % 10 === 0;

    if (shouldLogPerf) {
        perfDatapoint = new PerformanceDatapoint('CommandRibbon', {
            sessionSampleRate: 1, // sample at 1% as ribbon re-renders quite often
        });
        addComponentStartRenderingTiming(perfDatapoint);
    }

    useOrchestrator(
        invokeNewEventByKeyboard,
        () => {
            props.onNewEvent &&
                props.onNewEvent(false /* meetingWithAll */, true /* newEventByKeyboard */);
        },
        []
    );

    if (isFeatureEnabled('mon-ribbon-fluent-v9-ribbon')) {
        setNextStyleIsEnabled(true);
    }

    // In order for dark/light mode toggle to be respected, this needs
    // to be called before the first render, so no useEffect.
    // Inside render so that it doesn't get called unless we actually render the Ribbon.
    // Only needs to be called once, so ensure pattern is used here.
    ensureAppThemeInitialized();

    // Initial rendering will occur when the ribbon definitions are created, so we
    // need to initialize the icon color map first.
    initializeIconColorMap(props.iconColorMap);

    const targetWindow: Window = props.thisWindow ?? window;
    const defaultSelectedTabId = props.getDefaultSelectedTabId
        ? getStringRibbonId(props.getDefaultSelectedTabId(targetWindow))
        : '';
    const [selectedTabId, setSelectedTabId] = React.useState(defaultSelectedTabId);

    const isSinglelineMode: boolean = getIsViewModeSelected(1);

    const isProjectionPopout: boolean = targetWindow !== window;

    if (shouldLogPerf && perfDatapoint) {
        perfDatapoint.addCustomData({
            FirstRender: renderCount.current === 0,
            Scenario: props.ribbonScenarioName,
            SingleLine: isSinglelineMode,
        });
    }

    React.useEffect(() => {
        setSelectedTabId(defaultSelectedTabId);
    }, [defaultSelectedTabId]);

    const onTabHeaderClick = React.useCallback(
        (tabId: string) => {
            if (props.onTabClick) {
                props.onTabClick(tabId, targetWindow);
            }

            setSelectedTabId(tabId);
        },
        [props.onTabClick]
    );

    const getSelectedTab = (
        tabs: RibbonTabDefinitionBase[]
    ): RibbonTabDefinitionSLR | RibbonTabDefinitionMLR => {
        const matchingTabs: RibbonTabDefinitionBase[] = tabs.filter(
            tab => getCoreTabId(tab.id) === getCoreTabId(selectedTabId)
        );

        // If there is no match for the tab, return the first tab
        const matchingTab: RibbonTabDefinitionBase =
            matchingTabs.length === 0 ? tabs[0] : matchingTabs[0];

        if (matchingTabs.length === 0) {
            onTabHeaderClick(matchingTab.id);
        }

        return matchingTab.type === 'SingleLine'
            ? (matchingTab as RibbonTabDefinitionSLR)
            : (matchingTab as RibbonTabDefinitionMLR);
    };

    const { tabs }: RibbonTabDefinitionSet = isSinglelineMode
        ? props.getSingleLineRibbonDefinition()
        : props.getMultiLineRibbonDefinition();

    const selectedTab: RibbonTabDefinitionSLR | RibbonTabDefinitionMLR = getSelectedTab(tabs);

    if (shouldLogPerf && perfDatapoint) {
        // Call the function to log the initial timing
        addComponentEndRenderingTiming(perfDatapoint as PerformanceDatapoint);
    }

    return (
        <div id="RibbonRoot" className={root}>
            <RibbonContexts appWindow={targetWindow}>
                {!props.getHideTopRibbon && (
                    <CommandRibbonTopBar
                        extraControlsBeforeTabs={props.getExtraTopRibbonControlsBeforeTabs?.()}
                        extraControlsAfterTabs={props.getExtraTopRibbonControlsAfterTabs?.()}
                        onTabHeaderClick={onTabHeaderClick}
                        ribbonTabs={tabs}
                        selectedTab={selectedTab}
                    />
                )}

                <CommandRibbonBottomBar
                    disableAnimation={props.disableAnimation}
                    isProjectionPopout={isProjectionPopout}
                    projectionUnderlay={props.projectionUnderlay}
                    selectedTab={selectedTab}
                    controlRendererFunction={props.controlRendererFunction}
                />
            </RibbonContexts>

            {/* We want to disable Keytips in popped out windows. */}
            {!isProjectionPopout && !props.noKeytipLayer && (
                <RibbonKeytipLayer selectedTabId={selectedTabId} />
            )}
        </div>
    );
}, 'CommandRibbon');

/**
 * In popouts, the `tab.id` is formatted as `<TabId>_<EditorId>`, where `TabId` is the normal enum value and `EditorId` is a unique identifier for the compose session.
 * For the purpose of determining "matching tabs", we only care about the normal `TabId` enum value.
 */
const getCoreTabId = (id: string): string => id.split('_')[0];
