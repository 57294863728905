import { orchestrator } from 'satcheljs';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { setItem, removeItem } from 'owa-local-storage';
import { getBackgroundImageData, getFallbackBackgroundColor, getCurrentThemeId } from 'owa-theme';
import { onLoadTheme } from 'owa-theme-common';
import { fetchThemeBackgroundImage, ThemeConstants } from 'owa-theme-shared';
import setBackgroundColor from '../actions/setBackgroundColor';
import setBackgroundImage from '../actions/setBackgroundImage';

orchestrator(onLoadTheme, async () => {
    const isDarkTheme = getIsDarkTheme();
    const currentThemeId = getCurrentThemeId();
    const imageUrl = getBackgroundImageData(currentThemeId, isDarkTheme);
    const backgroundColor = getFallbackBackgroundColor(currentThemeId, isDarkTheme);

    setBackgroundImage(undefined);
    setBackgroundColor(backgroundColor);

    if (!imageUrl) {
        removeItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY);
        return;
    }

    setItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY, imageUrl);

    const bgImageResponse = await fetchThemeBackgroundImage(imageUrl);

    if (bgImageResponse) {
        setBackgroundImage(bgImageResponse);
    }
});
