import bookings_new_24x from 'owa-appbar-icons/lib/bookings_new_24x.svg';
import eventify from 'owa-appbar-icons/lib/eventify.svg';
import Loop_color from 'owa-appbar-icons/lib/Loop_color.png';
import M365Chat from 'owa-appbar-icons/lib/M365Chat.svg';
import microsoftPlacesLogo from 'owa-appbar-icons/lib/microsoftPlaces.svg';
import microsoftPlacesDogfood120px from 'owa-appbar-icons/lib/microsoftPlacesDogfood120px.png';
import microsoftPlacesDogfoodOutline32px from 'owa-appbar-icons/lib/microsoftPlacesDogfoodOutline32px.png';
import Newsletters_22px from 'owa-appbar-icons/lib/Newsletters_22px.svg';
import Newsletters_Mono_22px from 'owa-appbar-icons/lib/Newsletters_Mono_22px.svg';
import onedrive_24x from 'owa-appbar-icons/lib/onedrive_24x.svg';
import onedrive_consumer_large from 'owa-appbar-icons/lib/onedrive_consumer_large.png';
import onedrive_consumer_small from 'owa-appbar-icons/lib/onedrive_consumer_small.png';
import onedrive_outline from 'owa-appbar-icons/lib/onedrive_outline.png';
import OrgExplorer_22px from 'owa-appbar-icons/lib/OrgExplorer_22px.svg';
import todo_metaos from 'owa-appbar-icons/lib/todo_metaos_32.png';
import vivaengage_clr_medium from 'owa-appbar-icons/lib/vivaengage_clr_medium.png';
import vivaengage_clr_small from 'owa-appbar-icons/lib/vivaengage_clr_small.png';
import yammer from 'owa-appbar-icons/lib/yammer_24x.svg';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import { appAddison } from 'owa-locstrings/lib/strings/appAddison.locstring.json';
import { appCopilot } from 'owa-locstrings/lib/strings/appcopilot.locstring.json';
import { appEvents } from 'owa-locstrings/lib/strings/appEventify.locstring.json';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { PLACES_BRANDING_NAME } from 'places-common-strings';
import { isAppBarDisplayModeColorfulIconsEnabled } from '../../utils/isAppBarDisplayModeColorfulIconsEnabled';
import { M365AppId } from '../M365AppId';
import { type AdditionalParameter, appendParametersToContentUrl } from './utils';
import type { AcquisitionCategoryEncoding, PlatformType, StrictTitle } from '../../types';
import type { MailboxInfo } from 'owa-client-types';
import { EPOCH_TIME } from '../constants/constants';
import { EMPTY_COPILOT_CAPABILITIES } from '../constants/emptyCapability';

const PRIMARY_COLOR = '#0078D4';

function withOutlookParams(contentUrl: string): string {
    const additionalParameters: AdditionalParameter[] = [
        ...['branch', 'features', 'appendbootflights', 'removebootflights'].map(parameter => ({
            parameter,
            isEnabled: () => hasQueryStringParameter(parameter),
            getValue: () => encodeURIComponent(getQueryStringParameter(parameter)),
        })),
    ];

    return appendParametersToContentUrl(contentUrl, additionalParameters);
}

function getOrgExplorerIcon(mailboxInfo?: MailboxInfo, useColorfulIcons?: boolean): string {
    const colorfulIcon = isFeatureEnabled('mos-coreIconRefresh', mailboxInfo)
        ? OrgExplorer_22px
        : 'OutlookOrgExplorerIcon';

    return isAppBarDisplayModeColorfulIconsEnabled(useColorfulIcons)
        ? colorfulIcon
        : 'OutlookOrgExplorerIcon';
}
/*
 * Defines 1P apps that are MetaOs apps.
 * PS: Data is encoded to be displayed on the AppBar, declared on
 * owa-appbar-boostrap/../convertToM365ApplicationType.
 */
const getOrgExplorer = (mailboxInfo?: MailboxInfo, useColorfulIcons?: boolean): StrictTitle => ({
    __typename: 'Title',
    ingestionId: M365AppId.OrgExplorer,
    name: 'Org Explorer',
    acquisitionDate: EPOCH_TIME,
    categories:
        !isAppBarDisplayModeColorfulIconsEnabled(useColorfulIcons) ||
        !isFeatureEnabled('mos-coreIconRefresh', mailboxInfo)
            ? ['m365_app', 'appbar_icon' as AcquisitionCategoryEncoding]
            : ['m365_app' as AcquisitionCategoryEncoding],
    longDescription: 'Org Explorer Office App',
    shortDescription: 'Org Explorer Office App',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: getOrgExplorerIcon(mailboxInfo, useColorfulIcons),
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: getOrgExplorerIcon(mailboxInfo, useColorfulIcons),
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'OutlookOrgExplorerIcon',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: '1',
                name: 'Main',
                scopes: ['personal'],
                websiteUrl: '',
                contentUrl:
                    'https://loki.delve.office.com/orgexplorer/?hostApp=hub&isanonymous=true&culture={locale}',
            },
        ],
    },
    accentColor: PRIMARY_COLOR,
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        '*.office.com',
        '*.office.net',
        '*.office365.com',
        '*.microsoft.com',
        '*.akamaihd.net',
    ],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '394866fc-eedb-4f01-8536-3ff84b16be2a',
        resource: 'https://loki.delve.office.com/',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

// TODO: What about `connectors`, `activities` and galleryTabs? Those are not defined in MOS3 schema.
const getYammer = (mailboxInfo?: MailboxInfo): StrictTitle =>
    isFeatureEnabled('mos-vivaEngageRebrand', mailboxInfo)
        ? {
              __typename: 'Title',

              ingestionId: M365AppId.VivaEngage,
              name: 'Viva Engage',
              acquisitionDate: EPOCH_TIME,

              categories: ['m365_app' as PlatformType],
              longDescription:
                  'Add a fully-interactive Viva Engage experience to Microsoft Teams. Viva Engage communities connect the organization, enabling you to engage with leaders, watch live events, and stay up-to-date with news and announcements. Communities also enable people to share knowledge, ask questions, get answers, and build relationships across teams and organizational silos. Add the Viva Engage app and pin it to the navigation rail. It\u2019s Viva Engage Teams.',
              shortDescription:
                  'Drive engagement in your organization through Viva Engage communities.',
              iconSmall: {
                  __typename: 'M365AcquisitionIcon',
                  uri: vivaengage_clr_small,
              },
              iconLarge: {
                  __typename: 'M365AcquisitionIcon',
                  uri: vivaengage_clr_medium,
              },
              iconOutline: {
                  __typename: 'M365AcquisitionIcon',
                  uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/955070e9-99a6-4319-b8df-32adf59949aa_smallImage.png?v=2.1.1',
              },
              elementDefinitions: {
                  __typename: 'ElementDefinition',
                  composeExtensions: [],
                  exchangeAddIns: [],
                  extensions: [],
                  staticTabs: [
                      {
                          __typename: 'LaunchPage',
                          contentUrl: 'https://web.yammer.com/teams/feed',
                          entityId: 'vivaengage',
                          name: 'Viva Engage',
                          scopes: ['personal'],
                          websiteUrl: 'https://go.microsoft.com/fwlink/?linkid=2204694',
                      },
                  ],
              },
              accentColor: '#093c7f',
              isFullTrust: false,
              isFullScreen: true,
              developerName: 'Microsoft Corporation',
              developerUrl: 'https://www.microsoft.com/',
              termsOfUseUrl: 'https://aka.ms/yammer-teams-tos',
              permissions: [],
              validDomains: ['teams.yammer.com', 'web.yammer.com', 'www.yammer.com'],
              showLoadingIndicator: true,
              blockStatus: false,
              ingestionSource: 'Hardcoded',
              scope: 'Tenant',
              webApplicationInfo: {
                  __typename: 'WebApplicationInfo',
                  id: '00000005-0000-0ff1-ce00-000000000000',
                  resource: 'https://web.yammer.com/teams',
              },
              version: '2.1.1', // NA
              cultureName: 'en-US',
              manifestVersion: '1.13',
              authorization: {
                  __typename: 'AppAuthorization',
                  permissions: {
                      __typename: 'AppPermissions',
                      orgWide: [],
                      resourceSpecific: [],
                  },
              },
              copilotEnabled: false,
              copilotSettings: {
                  __typename: 'CopilotSettings',
                  displayName: '',
                  elementType: 'Extension',
                  capabilities: EMPTY_COPILOT_CAPABILITIES,
              },
          }
        : {
              __typename: 'Title',

              ingestionId: M365AppId.Yammer,
              name: 'Communities (Dogfood)',
              acquisitionDate: EPOCH_TIME,

              categories: ['m365_app' as PlatformType],
              longDescription:
                  'Add a fully-interactive Yammer experience to Microsoft Teams. Yammer communities connect the organization, enabling you to engage with leaders, watch live events, and stay up-to-date with news and announcements. Communities also enable people to share knowledge, ask questions, get answers, and build relationships across teams and organizational silos. Add the Yammer app and pin it to the navigation rail. It\u2019s Yammer\u2014in Teams.',
              shortDescription: 'Drive engagement in your organization through Yammer communities.',
              iconSmall: {
                  __typename: 'M365AcquisitionIcon',
                  uri: yammer,
              },
              iconLarge: {
                  __typename: 'M365AcquisitionIcon',
                  uri: yammer,
              },
              iconOutline: {
                  __typename: 'M365AcquisitionIcon',
                  uri: yammer,
              },
              elementDefinitions: {
                  __typename: 'ElementDefinition',
                  composeExtensions: [],
                  exchangeAddIns: [],
                  extensions: [],
                  staticTabs: [
                      {
                          __typename: 'LaunchPage',
                          contentUrl: 'https://web.yammer.com/teams/feed?client=outlook',
                          entityId: '1',
                          name: 'Yammer',
                          scopes: ['personal'],
                          websiteUrl: 'https://www.yammer.com',
                      },
                  ],
              },
              accentColor: '#093c7f',
              isFullTrust: false,
              isFullScreen: true,
              developerName: 'Microsoft Corporation',
              developerUrl: 'https://www.microsoft.com/',
              termsOfUseUrl: 'https://aka.ms/yammer-teams-tos',
              permissions: [],
              validDomains: ['teams.yammer.com', 'web.yammer.com', 'www.yammer.com'],
              showLoadingIndicator: true,
              blockStatus: false,
              ingestionSource: 'Hardcoded',
              scope: 'Tenant',
              webApplicationInfo: {
                  __typename: 'WebApplicationInfo',
                  id: '00000005-0000-0ff1-ce00-000000000000',
                  resource: 'https://web.yammer.com/teams',
              },
              version: '2.1.1', // NA
              cultureName: 'en-US',
              manifestVersion: '1.13',
              authorization: {
                  __typename: 'AppAuthorization',
                  permissions: {
                      __typename: 'AppPermissions',
                      orgWide: [],
                      resourceSpecific: [],
                  },
              },
              copilotEnabled: false,
              copilotSettings: {
                  __typename: 'CopilotSettings',
                  displayName: '',
                  elementType: 'Extension',
                  capabilities: EMPTY_COPILOT_CAPABILITIES,
              },
          };

const getBookingsStrictTitle = (mailboxInfo?: MailboxInfo) => {
    const BookingsMWTStrictTitle: StrictTitle = {
        __typename: 'Title',

        ingestionId: M365AppId.Bookings,
        name: 'Bookings',
        acquisitionDate: EPOCH_TIME,

        categories: ['m365_app' as PlatformType],
        longDescription:
            'Enables professionals and administrators to schedule bookings with Microsoft Teams Meetings. Use this scheduling tool to send appointment reminders from your business or department to meet virtually with any attendee.',
        shortDescription: 'Drive engagement in your organization through Yammer communities.',
        iconSmall: {
            __typename: 'M365AcquisitionIcon',
            uri: 'https://shell.cdn.office.net/shellux/bookings_mono.3a4233706c113a988a2b7c2397b6bfce.svg',
        },
        iconLarge: {
            __typename: 'M365AcquisitionIcon',
            uri: 'https://shell.cdn.office.net/shellux/bookings_mono.3a4233706c113a988a2b7c2397b6bfce.svg',
        },
        iconOutline: {
            __typename: 'M365AcquisitionIcon',
            uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgB7ZfhDYIwEIWvhP8yAiO4gWyiG+gIboBs4AaOgBuoG7iBblCPQM2FlN6RtqkxfMkFeFzKy3EtBWCBQWtd63jUSmBAQ0RyaaJC6LUxJtXHmLwMErMYyLguN4lTui/KdzDfJrTNgqtFW2MUEANpafHW0fWKpsbjmDSApyVG1R2JdgltwDULdhgtxo1oDxCiGEyeayV8YTwxGqJtx0m2KsyCKynJO+gZSJ/r6oFC931QEO3EPSikAdr11aCVoQ1Il+LVcAy+Fria8Az9ovTGpr0P2gaESKrwTXSVlOTttQVuPI7cMkA7krqylzCz/NJvgffHyNdA14QNJES8KV32hH9rIPmPiaQCMWdJ0hn4G3wARW+ykLoJvakAAAAASUVORK5CYII=',
        },
        elementDefinitions: {
            __typename: 'ElementDefinition',
            composeExtensions: [],
            exchangeAddIns: [],
            extensions: [],
            staticTabs: [
                {
                    __typename: 'LaunchPage',
                    contentUrl:
                        'https://healthcare.teams.microsoft.com:443/scheduler-web?theme={theme}&hubName=outlook&isFullScreen=true',
                    entityId: 'Consults',
                    name: 'Bookings',
                    scopes: ['personal'],
                    websiteUrl: '',
                },
            ],
        },
        accentColor: '#60A18E',
        isFullTrust: false,
        isFullScreen: true,
        developerName: 'Microsoft Corporation',
        developerUrl: 'https://www.microsoft.com/',
        termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
        permissions: [],
        validDomains: ['*.microsoft.com'],
        blockStatus: false,
        ingestionSource: 'Hardcoded',
        scope: 'Tenant',
        webApplicationInfo: {
            __typename: 'WebApplicationInfo',
            id: '4c4ec2e8-4a2c-4bce-8d8f-00fc664a4e5b',
            resource: 'https://healthcare.teams.microsoft.com',
        },
        version: '1.0.3', // NA
        showLoadingIndicator: true,
        cultureName: 'en-US',
        manifestVersion: '1.13',
        authorization: {
            __typename: 'AppAuthorization',
            permissions: {
                __typename: 'AppPermissions',
                orgWide: [],
                resourceSpecific: [],
            },
        },
        copilotEnabled: false,
        copilotSettings: {
            __typename: 'CopilotSettings',
            displayName: '',
            elementType: 'Extension',
            capabilities: EMPTY_COPILOT_CAPABILITIES,
        },
    };

    const BookingsVNextStrictTitle: StrictTitle = {
        __typename: 'Title',

        ingestionId: M365AppId.Bookings,
        name: 'Bookings',
        acquisitionDate: EPOCH_TIME,

        categories: ['m365_app' as PlatformType],
        longDescription:
            "Save time when scheduling with Microsoft Bookings. Customize appointment details, booking requirements, and specify service providers to streamline the booking experience for you and your customers. Bookings is integrated with your Microsoft 365 or Office 365 calendar to help your customers quickly find available times and avoid double-booking. With Bookings, you'll spend less time scheduling and more time meeting with customers.",
        shortDescription: 'A simpler way to organize schedules and manage appointments.',
        iconSmall: {
            __typename: 'M365AcquisitionIcon',
            uri: bookings_new_24x,
        },
        iconLarge: {
            __typename: 'M365AcquisitionIcon',
            uri: bookings_new_24x,
        },
        iconOutline: {
            __typename: 'M365AcquisitionIcon',
            uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgB7ZfhDYIwEIWvhP8yAiO4gWyiG+gIboBs4AaOgBuoG7iBblCPQM2FlN6RtqkxfMkFeFzKy3EtBWCBQWtd63jUSmBAQ0RyaaJC6LUxJtXHmLwMErMYyLguN4lTui/KdzDfJrTNgqtFW2MUEANpafHW0fWKpsbjmDSApyVG1R2JdgltwDULdhgtxo1oDxCiGEyeayV8YTwxGqJtx0m2KsyCKynJO+gZSJ/r6oFC931QEO3EPSikAdr11aCVoQ1Il+LVcAy+Fria8Az9ovTGpr0P2gaESKrwTXSVlOTttQVuPI7cMkA7krqylzCz/NJvgffHyNdA14QNJES8KV32hH9rIPmPiaQCMWdJ0hn4G3wARW+ykLoJvakAAAAASUVORK5CYII=',
        },
        elementDefinitions: {
            __typename: 'ElementDefinition',
            composeExtensions: [],
            exchangeAddIns: [],
            extensions: [],
            staticTabs: [
                {
                    __typename: 'LaunchPage',
                    contentUrl:
                        'https://outlook-sdf.office.com/hosted/bookings/homepage/?hostApp=hub',
                    entityId: 'bookings',
                    name: 'Home',
                    scopes: ['personal'],
                    websiteUrl: '',
                },
            ],
        },
        accentColor: '#007F9C',
        isFullTrust: false,
        isFullScreen: true,
        developerName: 'Microsoft Corporation',
        developerUrl: 'https://www.microsoft.com/',
        termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
        permissions: [],
        validDomains: [
            '*.office.com',
            'outlook.office.com',
            'outlook-sdf.office.com',
            '*.office365.com',
            '*.microsoft365.com',
            '*.office.net',
            '*.microsoft.com',
            '*.live.com',
            'outlook.live.com',
            'outlook-sdf.live.com',
        ],
        blockStatus: false,
        ingestionSource: 'Hardcoded',
        scope: 'Tenant',
        webApplicationInfo: {
            __typename: 'WebApplicationInfo',
            id: M365AppId.Bookings,
            resource: 'https://outlook-sdf.office.com',
        },
        version: '2.0.0',
        showLoadingIndicator: false,
        cultureName: 'en-US',
        manifestVersion: '1.13',
        authorization: {
            __typename: 'AppAuthorization',
            permissions: {
                __typename: 'AppPermissions',
                orgWide: [],
                resourceSpecific: [],
            },
        },
        copilotEnabled: false,
        copilotSettings: {
            __typename: 'CopilotSettings',
            displayName: '',
            elementType: 'Extension',
            capabilities: EMPTY_COPILOT_CAPABILITIES,
        },
    };

    return isFeatureEnabled('mos-bookingsVNextApp', mailboxInfo)
        ? BookingsVNextStrictTitle
        : BookingsMWTStrictTitle;
};
const ToDoMetaOs: StrictTitle = {
    __typename: 'Title',

    ingestionId: M365AppId.ToDoMetaOs,
    name: 'To Do',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: 'ToDo MetaOs App',
    shortDescription: 'ToDo MetaOs App',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: todo_metaos,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: todo_metaos,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/0d5c91ee-5be2-4b79-81ed-23e6c4580427_smallImage.png?v=2',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                websiteUrl: '',
                contentUrl: 'https://outlook.office.com/tasks?app&hostApp=metaOSHub',
                entityId: 'ToDoId',
                name: 'To Do',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: PRIMARY_COLOR,
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.live.com',
        '*.live.com',
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.ToDoMetaOs,
        resource: 'https://outlook.office.com/',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const getMicrosoftPlaces = (_mailboxInfo?: MailboxInfo): StrictTitle => ({
    __typename: 'Title',

    ingestionId: M365AppId.MicrosoftPlaces,
    name: PLACES_BRANDING_NAME,
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: microsoftPlacesLogo,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: microsoftPlacesLogo,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://res.cdn.office.net/teamsappdata/evergreen-assets/apps/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc_smallImage.png?v=1.0.3',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: 'home',
                name: 'Places',
                contentUrl: withOutlookParams(
                    'https://outlook.office.com/hosted/places?hostApp=hub&isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: 'people', // For any changes to this, update it in CollaboratorsBar.
                name: 'Places',
                contentUrl: withOutlookParams(
                    'https://outlook.office.com/hosted/places/people?hostApp=hub&isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: true,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: ['outlook.office.com', '*.office.com', '*.microsoft.com'],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.MicrosoftPlaces,
        resource: 'https://outlook.office.com/',
    },
    version: '1.0.1',
    cultureName: 'en-US',
    manifestVersion: '1.14',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

const getMicrosoftPlacesSdf = (_mailboxInfo?: MailboxInfo): StrictTitle => ({
    __typename: 'Title',

    ingestionId: M365AppId.MicrosoftPlacesSdf,
    name: 'Places (SDF)',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: microsoftPlacesDogfood120px,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: microsoftPlacesDogfood120px,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: microsoftPlacesDogfoodOutline32px,
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: 'home',
                name: 'Places',
                contentUrl: withOutlookParams(
                    'https://outlook-sdf.office.com/hosted/places?hostApp=hub&isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: 'people',
                name: 'Places',
                contentUrl: withOutlookParams(
                    'https://outlook-sdf.office.com/hosted/places/people?hostApp=hub&isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: true,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
        'outlook-sdf.office.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.MicrosoftPlacesSdf,
        resource: 'https://outlook-sdf.office.com/',
    },
    version: '1.0.1',
    cultureName: 'en-US',
    manifestVersion: '1.14',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

const getEventsSdf = (mailboxInfo?: MailboxInfo): StrictTitle => ({
    __typename: 'Title',

    ingestionId: M365AppId.EventsSdf,
    name: loc(appEvents) || 'Events',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: eventify,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: eventify,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/260f432d-8a76-46c2-bf48-0f0748ac3eba_smallImage.png?v=1.0.4',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: M365AppId.EventsSdf,
                name: 'Events',
                contentUrl:
                    withOutlookParams(
                        'https://outlook-sdf.office.com/hosted/eventify?isanonymous=true&culture={locale}'
                    ) + (isFeatureEnabled('mos-eventsApp-gulp', mailboxInfo) ? '&gulp' : ''),
                websiteUrl: '',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: '4e392658-8f5f-488a-a476-d3db83f46131', // If updating GUID make sure to change in getNewGroup and onNewEventifyEvent. We cannot use consts there due to large boot size increase
                name: 'NewEvent',
                contentUrl:
                    withOutlookParams(
                        'https://outlook-sdf.office.com/hosted/eventify/newEvent?isanonymous=true&culture={locale}'
                    ) + (isFeatureEnabled('mos-eventsApp-gulp', mailboxInfo) ? '&gulp' : ''),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.live.com',
        '*.live.com',
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
        'outlook-sdf.office.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.EventsSdf,
        resource: 'https://outlook-sdf.office.com',
    },
    version: '1.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

const EventsProd: StrictTitle = {
    __typename: 'Title',

    ingestionId: M365AppId.EventsProd,
    /* eslint-disable-next-line owa-custom-rules/no-loc-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Calling loc() in module-scope is a localization bug. See the wiki above why this is a problem and how to fix it.
     *	> Do not call loc() in module scope. loc() should be called directly or indirectly by a React render function. */
    name: loc(appEvents) || 'Events',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: eventify,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: eventify,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/260f432d-8a76-46c2-bf48-0f0748ac3eba_smallImage.png?v=1.0.4',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: M365AppId.EventsProd,
                name: 'Events',
                contentUrl: withOutlookParams(
                    'https://outlook.office.com/hosted/eventify?isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: 'c0adaf60-11f1-4f72-bef6-f44e36877983', // If updating GUID make sure to change in getNewGroup and onNewEventifyEvent. We cannot use consts there due to large boot size increase
                name: 'NewEvent',
                contentUrl: withOutlookParams(
                    'https://outlook.office.com/hosted/eventify/newEvent?isanonymous=true&culture={locale}'
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.live.com',
        '*.live.com',
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
        'outlook-sdf.office.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.EventsProd,
        resource: 'https://outlook.office.com',
    },
    version: '1.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const Hoop: StrictTitle = {
    __typename: 'Title',

    ingestionId: M365AppId.Hoop,
    name: 'Loop',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription:
        'Microsoft Loop is a transformative co-creation experience that brings together teams, content and tasks across your tools and devices. Loop combines a powerful and flexible canvas with portable components that move freely and stay in sync across applications \u2014 enabling teams to think, plan, and create together',
    shortDescription: 'Think, plan and create together with Microsoft Loop',

    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: Loop_color,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: Loop_color,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/app-assets/ring1/4a6520d6-94a8-4730-9184-24997b9b3401/4a6520d6-94a8-4730-9184-24997b9b3401_smallImage.png?v=1.0.1',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: 'auth',
                name: 'Loop',
                contentUrl: 'https://loop.microsoft.com/hoop.html',
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#8230FF',
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/termsofuse',
    permissions: ['identity', 'messageTeamMembers'],
    validDomains: [
        'outlook.live.com',
        '*.live.com',
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
        'outlook-sdf.office.com',
        'substrate.office.com',
        'graph.microsoft.com',
        'loop.microsoft.com',
        '*.loop.microsoft.com',
        '*.blob.core.windows.net',
        'outlook-sdf.office365.com',
        'clients.config.office.net',
        'browser.events.data.microsoft.com',
        'officefluidprdeployments.azurewebsites.net',
    ],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.Hoop,
        resource: 'https://loop.microsoft.com/hoop.html',
    },
    version: '1.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.14',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const OneDriveMetaOs: StrictTitle = {
    __typename: 'Title',

    ingestionId: M365AppId.OneDriveMetaOs,
    name: 'OneDrive',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    shortDescription: 'Official OneDrive app for Microsoft 365',
    longDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_24x,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_24x,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_outline,
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: '7211f19f-262a-42eb-a02e-289956491741',
                name: 'OneDrive',
                contentUrl:
                    'https://{mySiteDomain}{mySitePath}/_layouts/15/filebrowser.aspx?app=MetaOS&fileBrowser={"sdk":"8.0","entry":{"oneDrive":{}},"metaOS":{"host":"{hostName}"},"theme":"{theme}","telemetry":{"ring":"{ringId}","correlationId":"{appSessionId}"}}&scenario=OneDrive&auth=none&locale={locale}',
                websiteUrl: 'https://go.microsoft.com/fwlink/?linkid=2225080',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#FFFFFF',
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft Corp',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: ['identity', 'messageTeamMembers'],
    validDomains: [
        '{mySiteDomain}{mySitePath}',
        'go.microsoft.com',
        '{mySiteDomain}',
        '*.sharepoint.com',
        '*.login.microsoftonline.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '00000003-0000-0ff1-ce00-000000000000',
        resource: 'https://{mySiteDomain}',
    },
    version: '1.0.0', // NA
    cultureName: 'EN-US',
    manifestVersion: 'm365DevPreview',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const OneDriveConsumerMetaOs: StrictTitle = {
    __typename: 'Title',

    ingestionId: M365AppId.OneDriveConsumerMetaOs,
    name: 'OneDrive',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    shortDescription: 'Official OneDrive app',
    longDescription: 'Easily store, access, and discover your individual and shared files.',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_consumer_small,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_consumer_large,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: onedrive_outline,
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: '8a688e1a-af75-4af8-801c-2c7f76dfb6cf',
                name: 'OneDrive',
                contentUrl:
                    'https://onedrive.live.com/browser?app=MetaOS&fileBrowser={"sdk":"8.0","entry":{"oneDrive":{}},"metaOS":{"host":"{hostName}"},"theme":"{theme}","telemetry":{"ring":"{ringId}","correlationId":"{appSessionId}"}}&scenario=OneDrive&auth=none&smkt={locale}',
                websiteUrl: 'https://onedrive.live.com',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: 'about',
                name: '',
                contentUrl: '',
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#FFFFFF',
    isFullTrust: false,
    isFullScreen: true,
    developerName: 'Microsoft',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/legal/terms-of-use',
    permissions: ['identity', 'messageTeamMembers'],
    validDomains: ['onedrive.live.com', 'login.live.com', 'api.onedrive.com'],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '865367a6-9c28-4844-88ce-259d34dbabae',
        resource: 'api://onedrive.live.com',
    },
    version: '1.0.0', // NA
    cultureName: 'EN-US',
    manifestVersion: 'm365DevPreview',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

function getNewslettersIcon(useColorfulIcons?: boolean): string {
    return isAppBarDisplayModeColorfulIconsEnabled(useColorfulIcons)
        ? Newsletters_22px
        : Newsletters_Mono_22px;
}

const getNewsletters = (mailboxInfo?: MailboxInfo, useColorfulIcons?: boolean): StrictTitle => ({
    __typename: 'Title',

    ingestionId: M365AppId.Newsletters,
    name: loc(appAddison) || 'Newsletters',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: getNewslettersIcon(useColorfulIcons),
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: Newsletters_22px,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: Newsletters_Mono_22px,
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: 'home',
                name: 'Newsletters',
                contentUrl: withOutlookParams(
                    `https://outlook${
                        isFeatureEnabled('mos-newslettersApp-sdf', mailboxInfo) ? '-sdf' : ''
                    }.office.com/hosted/newsletters?hostApp=hub&isanonymous=true&culture={locale}${
                        isFeatureEnabled('mos-newslettersApp-gulp', mailboxInfo) ? '&ori' : ''
                    }`
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
            {
                __typename: 'LaunchPage',
                entityId: 'selectTemplate',
                name: 'Create Newsletter',
                contentUrl: withOutlookParams(
                    `https://outlook${
                        isFeatureEnabled('mos-newslettersApp-sdf', mailboxInfo) ? '-sdf' : ''
                    }.office.com/hosted/newsletters/selectTemplate?hostApp=hub&isanonymous=true&culture={locale}${
                        isFeatureEnabled('mos-newslettersApp-gulp', mailboxInfo) ? '&ori' : ''
                    }`
                ),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: true,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.office.com',
        '*.office.com',
        '*.microsoft.com',
        'outlook-sdf.office.com',
    ],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.Newsletters,
        resource: `https://outlook${
            isFeatureEnabled('mos-newslettersApp-sdf', mailboxInfo) ? '-sdf' : ''
        }.office.com/`,
    },
    version: '1.0.1',
    cultureName: 'en-US',
    manifestVersion: '1.14',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

const getCopilot = (mailboxInfo?: MailboxInfo): StrictTitle => ({
    __typename: 'Title',

    ingestionId: M365AppId.ImmersiveBizChat,
    name: loc(appCopilot) || 'Copilot',
    acquisitionDate: EPOCH_TIME,

    categories: ['m365_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: M365Chat,
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: M365Chat,
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/app-assets/ring1/89fb6b53-d5d6-4077-a412-f55f1fd3830a/89fb6b53-d5d6-4077-a412-f55f1fd3830a_smallImage.png?v=6.1.6',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [
            {
                __typename: 'LaunchPage',
                entityId: M365AppId.ImmersiveBizChat,
                name: 'Copilot',
                contentUrl: (() => {
                    let contentUrl =
                        self?.location?.hostname
                            ?.toLowerCase()
                            ?.indexOf('outlook-sdf.office.com') >= 0
                            ? "https://outlook-sdf.office.com/hosted/semanticoverview/Users('OID:{user.id}@{user.tenant.id}')?hostApp=hub&isanonymous=true&culture={app.locale}&sessionId={appSessionId}&hostName={app.host.name}&hostClientType={app.host.clientType}"
                            : "https://outlook.office.com/hosted/semanticoverview/Users('OID:{user.id}@{user.tenant.id}')?hostApp=hub&isanonymous=true&culture={app.locale}&sessionId={appSessionId}&hostName={app.host.name}&hostClientType={app.host.clientType}";

                    const additionalParameters = [
                        ...[
                            'M365ChatHTTPS',
                            'M365ChatLocal',
                            'M365ChatBranch',
                            'M365ChatFeatures',
                            'M365ChatVersion',
                            'localLoki',
                            'lokiToLocalThreeS',
                            'M365ChatMode',
                            'branch',
                            'features',
                            'top',
                        ].map(parameter => ({
                            parameter,
                            isEnabled: () => hasQueryStringParameter(parameter),
                            getValue: () => encodeURIComponent(getQueryStringParameter(parameter)),
                        })),
                        {
                            parameter: 'cspoff',
                            isEnabled: () => hasQueryStringParameter('cspoff'),
                            getValue: () => '',
                        },
                        {
                            parameter: 'gulp',
                            isEnabled: () =>
                                hasQueryStringParameter('gulp') &&
                                isFeatureEnabled('immersive-bizchat-mos-gulp', mailboxInfo),
                            getValue: () =>
                                'http://localhost:3000/&esm&preSrc=http://localhost:3000/importMapShim.js',
                        },
                        {
                            parameter: 'ori',
                            isEnabled: () =>
                                hasQueryStringParameter('ori') &&
                                isFeatureEnabled('immersive-bizchat-mos-gulp', mailboxInfo),
                            getValue: () => '',
                        },
                    ];

                    for (const additionalParameter of additionalParameters) {
                        if (additionalParameter.isEnabled()) {
                            const value = additionalParameter.getValue();
                            if (value) {
                                contentUrl += '&' + additionalParameter.parameter + '=' + value;
                            } else {
                                contentUrl += '&' + additionalParameter.parameter;
                            }
                        }
                    }

                    return contentUrl;
                })(),
                websiteUrl: '',
                scopes: ['personal'],
            },
        ],
    },
    accentColor: '#60A18E',
    isFullTrust: true,
    isFullScreen: true,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/',
    termsOfUseUrl: 'https://www.microsoft.com/servicesagreement',
    permissions: [],
    validDomains: [
        'outlook.office.com',
        'outlook.office365.com',
        'outlook-sdf.office.com',
        'outlook-sdf.office365.com',
    ],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: M365AppId.ImmersiveBizChat,
        resource:
            self?.location?.hostname?.toLowerCase()?.indexOf('outlook-sdf.office.com') >= 0
                ? 'https://outlook-sdf.office.com/'
                : 'https://outlook.office.com/',
    },
    version: '1.0.1',
    cultureName: 'en-US',
    manifestVersion: '1.14',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
});

const getBookings = (mailboxInfo?: MailboxInfo) => getBookingsStrictTitle(mailboxInfo);

const getLaunchPageTitles = (
    mailboxInfo?: MailboxInfo,
    useColorfulIcons?: boolean
): StrictTitle[] => [
    getBookings(mailboxInfo),
    getOrgExplorer(mailboxInfo, useColorfulIcons),
    ToDoMetaOs,
    getEventsSdf(mailboxInfo),
    EventsProd,
    getYammer(mailboxInfo),
    getMicrosoftPlaces(mailboxInfo),
    getMicrosoftPlacesSdf(mailboxInfo),
    Hoop,
    OneDriveMetaOs,
    OneDriveConsumerMetaOs,
    getNewsletters(mailboxInfo, useColorfulIcons),
    getCopilot(mailboxInfo),
];

export { getLaunchPageTitles };
