export default function convertHexColorStringToNumber(hexColor: string): number {
    let hexInt = 0;
    const parts: string[] = hexColor.split('#').filter(option => !!option);
    if (parts.length == 1) {
        hexInt = parseInt(parts[0], 16);

        // Google's colors have alpha=0 which in Exchange is reserved for automatic color assignment.
        // Set it to a non-zero value when converting to Exchange's representation.
        hexInt |= 4278190080;
    }

    return hexInt;
}
