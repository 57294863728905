import type NoEndRecurrence from 'owa-service/lib/contract/NoEndRecurrence';
import type NumberedRecurrence from 'owa-service/lib/contract/NumberedRecurrence';
import type RecurrencePatternBaseType from 'owa-service/lib/contract/RecurrencePatternBaseType';
import type RecurrenceRangeBaseType from 'owa-service/lib/contract/RecurrenceRangeBaseType';
import type RecurrenceType from 'owa-service/lib/contract/RecurrenceType';
import type RegeneratingPatternBaseType from 'owa-service/lib/contract/RegeneratingPatternBaseType';
import type RelativeMonthlyRecurrence from 'owa-service/lib/contract/RelativeMonthlyRecurrence';
import type RelativeYearlyRecurrence from 'owa-service/lib/contract/RelativeYearlyRecurrence';
import type WeeklyRecurrence from 'owa-service/lib/contract/WeeklyRecurrence';
import type AbsoluteMonthlyRecurrence from 'owa-service/lib/contract/AbsoluteMonthlyRecurrence';
import type AbsoluteYearlyRecurrence from 'owa-service/lib/contract/AbsoluteYearlyRecurrence';
import type EndDateRecurrence from 'owa-service/lib/contract/EndDateRecurrence';
import type {
    RecurrencePatternType,
    RecurrenceRangeType,
    RecurrenceType as Schema_RecurrenceType,
} from 'owa-graph-schema';
import type DailyRecurrence from 'owa-service/lib/contract/DailyRecurrence';

// TODO VSO 117161: Pull recurrence types out of recurrence-utils for reuse in resolvers
// reuse types from shared package here

/**
 * Maps from the schema RecurrencePatternType to the OWS RecurrencePatternBaseType. We need to handle this manually because
 * RecurrencePatternBaseType is an empty object that any number of classes may subclass, but Schema.RecurrencePatternType is
 * a union of possible object types.
 */
export function recurrencePattern(
    recurrencePatternGql: RecurrencePatternType
): RecurrencePatternBaseType {
    if (!recurrencePatternGql.__typename) {
        return recurrencePatternGql as RecurrencePatternBaseType;
    }

    switch (recurrencePatternGql.__typename) {
        case 'AbsoluteYearlyRecurrence': {
            return {
                __type: 'AbsoluteYearlyRecurrence:#Exchange',
                ...(recurrencePatternGql as AbsoluteYearlyRecurrence),
            };
        }
        case 'AbsoluteMonthlyRecurrence': {
            return {
                __type: 'AbsoluteMonthlyRecurrence:#Exchange',
                ...(recurrencePatternGql as AbsoluteMonthlyRecurrence),
            };
        }
        case 'WeeklyRecurrence': {
            return {
                __type: 'WeeklyRecurrence:#Exchange',
                ...(recurrencePatternGql as WeeklyRecurrence),
            };
        }
        case 'DailyRecurrence': {
            return {
                __type: 'DailyRecurrence:#Exchange',
                ...(recurrencePatternGql as DailyRecurrence),
            };
        }
        case 'RegeneratingPatternBaseType': {
            return {
                __type: 'RegeneratingPatternBaseType:#Exchange',
                ...(recurrencePatternGql as RegeneratingPatternBaseType),
            };
        }
        case 'RelativeYearlyRecurrence': {
            return {
                __type: 'RelativeYearlyRecurrence:#Exchange',
                ...(recurrencePatternGql as RelativeYearlyRecurrence),
            };
        }
        case 'RelativeMonthlyRecurrence': {
            return {
                __type: 'RelativeMonthlyRecurrence:#Exchange',
                ...(recurrencePatternGql as RelativeMonthlyRecurrence),
            };
        }
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(`unhandled recurrence pattern ${(recurrencePatternGql as any).__typename}`);
}

/**
 * Maps from the union of supported subtypes from the Schema to the known implementers of the
 * OWS base class RecurrenceRangeBaseType
 *
 * See the docstring on recurrencePattern() for details
 */
export function recurrenceRange(recurrenceRangeGql: RecurrenceRangeType): RecurrenceRangeBaseType {
    if (!recurrenceRangeGql.__typename) {
        return recurrenceRangeGql as RecurrenceRangeBaseType;
    }

    switch (recurrenceRangeGql.__typename) {
        case 'EndDateRecurrence': {
            return {
                __type: 'EndDateRecurrence:#Exchange',
                ...(recurrenceRangeGql as EndDateRecurrence),
            };
        }
        case 'NoEndRecurrence': {
            return {
                __type: 'NoEndRecurrence:#Exchange',
                ...(recurrenceRangeGql as NoEndRecurrence),
            };
        }
        case 'NumberedRecurrence': {
            return {
                __type: 'NumberedRecurrence:#Exchange',
                ...(recurrenceRangeGql as NumberedRecurrence),
            };
        }
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    throw new Error(`unhandled recurrence pattern ${(recurrenceRangeGql as any).__typename}`);
}

export function convertRecurrenceTypeToOws(recurrenceType: Schema_RecurrenceType): RecurrenceType {
    if (!recurrenceType.__typename) {
        return recurrenceType as RecurrenceType;
    }

    return {
        __type: 'RecurrenceType:#Exchange',
        RecurrencePattern: recurrencePattern(recurrenceType.RecurrencePattern),
        RecurrenceRange: recurrenceRange(recurrenceType.RecurrenceRange),
    };
}
