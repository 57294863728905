import PropertyIcons from './PropertyIcons';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import { default as Attach } from 'owa-fluent-icons-svg/lib/icons/AttachRegular';
import { default as Mention } from 'owa-fluent-icons-svg/lib/icons/MentionRegular';
import { default as Link } from 'owa-fluent-icons-svg/lib/icons/LinkRegular';
import { default as Clock } from 'owa-fluent-icons-svg/lib/icons/ClockRegular';
import { default as ArrowReply } from 'owa-fluent-icons-svg/lib/icons/ArrowReplyRegular';
import { default as ArrowForward } from 'owa-fluent-icons-svg/lib/icons/ArrowForwardRegular';
import { default as LockClosed } from 'owa-fluent-icons-svg/lib/icons/LockClosedRegular';
import { default as CalendarEmpty } from 'owa-fluent-icons-svg/lib/icons/CalendarEmptyRegular';
import { default as CalendarCheckmark } from 'owa-fluent-icons-svg/lib/icons/CalendarCheckmarkRegular';
import { default as CalendarCancel } from 'owa-fluent-icons-svg/lib/icons/CalendarCancelRegular';
import { default as CalendarQuestionMark } from 'owa-fluent-icons-svg/lib/icons/CalendarQuestionMarkRegular';
import { default as CalendarEyeRegular } from 'owa-fluent-icons-svg/lib/icons/CalendarEyeRegular';
import { default as Voicemail } from 'owa-fluent-icons-svg/lib/icons/VoicemailRegular';
import { default as ArrowDown } from 'owa-fluent-icons-svg/lib/icons/ArrowDownRegular';
import { default as Important } from 'owa-fluent-icons-svg/lib/icons/ImportantFilled';
import { default as CheckboxChecked } from 'owa-fluent-icons-svg/lib/icons/CheckboxCheckedRegular';
import { default as CalendarSync } from 'owa-fluent-icons-svg/lib/icons/CalendarSyncRegular';
import { default as Notepad } from 'owa-fluent-icons-svg/lib/icons/NotepadRegular';
import { default as Note } from 'owa-fluent-icons-svg/lib/icons/NoteRegular';
import { default as ArrowCircleRight } from 'owa-fluent-icons-svg/lib/icons/ArrowCircleRightRegular';
import { default as DoorArrowLeft } from 'owa-fluent-icons-svg/lib/icons/DoorArrowLeftRegular';
import { default as CheckmarkCircle } from 'owa-fluent-icons-svg/lib/icons/CheckmarkCircleRegular';
import { default as MailDismiss } from 'owa-fluent-icons-svg/lib/icons/MailDismissRegular';
import { default as MailList } from 'owa-fluent-icons-svg/lib/icons/MailListRegular';

import { redDark, snooze } from './propertyIcons.scss';

export default function getPropertyIcon(propertyIcon: PropertyIcons): {
    iconClasses?: string;
    iconName: string;
} {
    switch (propertyIcon) {
        case PropertyIcons.GlobalMentionedMe:
            return {
                iconName: Mention,
            };
        case PropertyIcons.Attachment:
            return {
                iconName: Attach,
            };
        case PropertyIcons.ImportanceLow:
            return {
                iconName: ArrowDown,
            };
        case PropertyIcons.ImportanceHigh:
            return {
                iconClasses: redDark,
                iconName: Important,
            };
        case PropertyIcons.Snooze:
            return {
                iconClasses: snooze,
                iconName: Clock,
            };
        case PropertyIcons.MailListViewReplied:
            return {
                iconName: ArrowReply,
            };
        case PropertyIcons.MailListViewForwarded:
            return {
                iconName: ArrowForward,
            };
        case PropertyIcons.MailListViewIRM:
        case PropertyIcons.MailListViewIRMReplied:
        case PropertyIcons.MailListViewIRMForwarded:
            return {
                iconName: LockClosed,
            };
        case PropertyIcons.MailListViewVoiceMailItem:
            return {
                iconName: Voicemail,
            };
        case PropertyIcons.MailListViewVoiceMailReplied:
            return {
                iconName: 'VoicemailReply',
            };
        case PropertyIcons.MailListViewVoiceMailForwarded:
            return {
                iconName: 'VoicemailForward',
            };
        case PropertyIcons.MailListViewVoiceMailIRM:
            return {
                iconName: 'VoicemailIRM',
            };
        case PropertyIcons.MailListViewFaxItem:
            return {
                iconName: 'Fax',
            };
        case PropertyIcons.MailListViewAppointmentItem:
            return {
                iconName: CalendarEmpty,
            };
        case PropertyIcons.MailListViewAppointmentAccepted:
            return {
                iconName: CalendarCheckmark,
            };
        case PropertyIcons.MailListViewAppointmentDeclined:
            return {
                iconName: CalendarCancel,
            };
        case PropertyIcons.MailListViewAppointmentTentative:
            return {
                iconName: CalendarQuestionMark,
            };
        case PropertyIcons.MailListViewAppointmentFollow:
            return {
                iconName: CalendarEyeRegular,
            };
        case PropertyIcons.MailListViewAppointmentCanceled:
            return {
                iconName: CalendarCancel,
            };
        case PropertyIcons.MailListViewContactItem:
            return {
                iconName: 'ContactCard',
            };
        case PropertyIcons.MailListViewTaskItem:
            return {
                iconName: CheckboxChecked,
            };
        case PropertyIcons.MailListViewTaskReocurItem:
            return {
                iconName: CalendarSync,
            };
        case PropertyIcons.MailListViewJournalItem:
            return {
                iconName: Notepad,
            };
        case PropertyIcons.MailListViewPostItem:
            return {
                iconName: 'NotePinned',
            };
        case PropertyIcons.MailListViewNoteItem:
            return {
                iconName: Note,
            };
        case PropertyIcons.MailListViewDelivery:
            // Bug 1752 - culture specific
            return {
                iconName: ArrowCircleRight,
            };
        case PropertyIcons.MailListViewNonDelivery:
            // Bug 1752 - culture specific
            return {
                iconName: 'ReceiptReply',
            };
        case PropertyIcons.MailListViewApprovalRequest:
            // add sprite response_request.png
            return {
                iconName: 'ReceiptReply',
            };
        case PropertyIcons.MailListViewResponseApprove:
            // add sprite response_approve.png
            return {
                iconName: 'ReceiptReply',
            };
        case PropertyIcons.MailListViewResponseReject:
            // add sprite response_reject.png
            return {
                iconName: 'ReceiptReply',
            };
        case PropertyIcons.MailListViewOutOfOffice:
            // Bug 1752 - culture specific
            return {
                iconName: DoorArrowLeft,
            };
        case PropertyIcons.MailListViewReadReport:
            return {
                iconName: CheckmarkCircle,
            };
        case PropertyIcons.MailListViewReminderMessage:
            return {
                iconName: 'Ringer',
            };
        case PropertyIcons.Link:
            return {
                iconName: Link,
            };
        case PropertyIcons.MailDismiss:
            return {
                iconName: MailDismiss,
            };
        case PropertyIcons.SenderGroupings:
            return {
                iconName: MailList,
            };
        default:
            throw new Error('No CSS class icon for the given property icon');
    }
}
