import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';

export function useResizeObserver(
    source: string,
    target: React.RefObject<HTMLDivElement>,
    resizeObserverCallback: (bounds: DOMRectReadOnly) => void
) {
    React.useEffect(() => {
        if (target.current) {
            const cb = ([entry]: ResizeObserverEntry[]) => {
                if (target.current) {
                    addToTimingMap('useResizeObserver', source);
                    resizeObserverCallback(entry.contentRect);
                }
            };

            try {
                Object.defineProperty(cb, 'name', { value: source });
            } catch {
                // best effort so do nothing if there was an exception
            }

            const targetWindow = target.current.ownerDocument.defaultView;

            let resizeObserver: ResizeObserver | undefined;
            if (!targetWindow || typeof targetWindow.ResizeObserver !== 'function') {
                resizeObserver = new ResizeObserver(cb);
            } else {
                resizeObserver = new targetWindow.ResizeObserver(cb);
            }

            resizeObserver.observe(target.current);
            return () => resizeObserver?.disconnect();
        }
        return undefined;
    }, [target.current, resizeObserverCallback]);
}

export function useResizeObserverRef(
    source: string,
    target: React.RefObject<HTMLDivElement>,
    resizeObserverCallback?: (bounds: DOMRectReadOnly) => void
): React.RefObject<DOMRectReadOnly | undefined> {
    const domRectRef = React.useRef<DOMRectReadOnly>();
    const updateRef = React.useCallback((domRect: DOMRectReadOnly) => {
        domRectRef.current = domRect;
        resizeObserverCallback?.(domRect);
    }, []);
    useResizeObserver(source, target, updateRef);
    return domRectRef;
}
