import {
    previewContainer,
    previewContainerWithDismissButton,
    previewDisplayText,
    nudgedPreviewDisplayText,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';

/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { MailListItemDismissButton } from '../index';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { highlightTermsInHtmlElement } from 'owa-highlight';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';
import React from 'react';

export interface MailListItemPreviewDisplayProps {
    highlightTerms: string[];
    previewDisplay: string;
    rowKey: string;
    tableViewId: string;
    highlightAccuracy?: Accuracy;
    isNudged?: boolean; // Only used in SCL
}

export default observer(function MailListItemPreviewDisplay(
    props: MailListItemPreviewDisplayProps
) {
    const { highlightTerms, isNudged, previewDisplay, rowKey, tableViewId, highlightAccuracy } =
        props;

    const highLightTerms = React.useCallback(
        (element: HTMLElement) => {
            highlightTermsInHtmlElement(
                element,
                highlightTerms,
                false /* separateWordSearch */,
                highlightAccuracy /* highlightAccuracy */
            );
        },
        [highlightTerms, highlightAccuracy]
    );

    return (
        <div
            className={classnames(previewContainer, isNudged && previewContainerWithDismissButton)}
        >
            <span
                className={classnames(
                    previewDisplayText,
                    isNudged ? nudgedPreviewDisplayText : undefined,
                    useMessageListTextStyle('Major')
                )}
                ref={highLightTerms}
            >
                {previewDisplay}
            </span>
            {isNudged && <MailListItemDismissButton rowKey={rowKey} tableViewId={tableViewId} />}
        </div>
    );
},
'MailListItemPreviewDisplay');
