import type ExtendedPropertyUri from 'owa-service/lib/contract/ExtendedPropertyUri';
import extendedPropertyUri from 'owa-service/lib/factory/extendedPropertyUri';
import { EditOnSendType } from 'owa-editor-addin-plugin-types/lib/utils/EditOnSendType';

/** Extended property that holds the value for appendOnSend */
export const IsAppendOnSendExtendedProperty: ExtendedPropertyUri = extendedPropertyUri({
    DistinguishedPropertySetId: 'Common',
    PropertyName: EditOnSendType.AppendOnSend,
    PropertyType: 'String',
});

/** Extended property that holds the value for prependOnSend */
export const IsPrependOnSendExtendedProperty: ExtendedPropertyUri = extendedPropertyUri({
    DistinguishedPropertySetId: 'Common',
    PropertyName: EditOnSendType.PrependOnSend,
    PropertyType: 'String',
});
