import { isBootFeatureEnabled } from 'owa-metatags';
import { logUsage } from 'owa-analytics';
import type { IComputedFnOptions } from 'mobx-utils/lib/computedFn';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * 'computedFn' should only be used within this package. All other packages should import through this package. */
import { computedFn } from 'mobx-utils/lib/computedFn';

const errorName = 'ComputedFnLeak';
const MaxRunsPerFunction = 5;

// This function is a wrapper around mobx-utils computedFn. If the boot feature
// is off then it will just return the computedFn. If the boot feature is on
// we will call our own computed function that will try to find any arguments
// which instances are not the same but the deep values are the same
export function owaComputedFn<T extends (...args: any[]) => any>(
    fn: T,
    options?: IComputedFnOptions<T>
): T {
    if (isBootFeatureEnabled('disablecmpfn')) {
        return fn;
    } else if (isBootFeatureEnabled('detectcmpfnleaks')) {
        let lastArgs: Parameters<T> | undefined;
        let runs: number = 0;
        return computedFn(
            function (this: any, ...args: Parameters<T>): ReturnType<T> {
                try {
                    if (lastArgs && runs < MaxRunsPerFunction) {
                        runs++;

                        // iterate through each arg. If the last arg and the current arg
                        // are both objects and are not the same instance of object but
                        // the deep values are the same, then we might have a leak
                        for (let ii = 0; ii < args.length; ii++) {
                            const lastArg = lastArgs[ii];
                            const currentArg = args[ii];
                            const lastArgType = typeof lastArg;
                            const currentArgType = typeof currentArg;

                            // one of the args doesn't match, so we will need to compute a new value
                            if (lastArg != currentArg) {
                                // if both of the args are objects and the deep values are the same
                                // then we might have a leak
                                if (
                                    lastArgType == 'object' &&
                                    currentArgType == 'object' &&
                                    JSON.stringify(lastArg) == JSON.stringify(currentArg)
                                ) {
                                    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                                     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                                     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
                                    logUsage(errorName, {
                                        idx: ii,
                                        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                                         * Error constructor names can only be a string literals.
                                         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                                        stack: new Error(errorName).stack,
                                    });
                                } else {
                                    // if the last arg and current arge are not the same instance
                                    // and we didn't find a problem with the deep values, then
                                    // we don't need to look at the rest of the parameters
                                    break;
                                }
                            }
                        }
                    }
                } catch {
                    // do nothing
                }
                lastArgs = args;
                return fn.apply(null, args);
            } as T,
            options
        );
    } else {
        return computedFn(fn, options);
    }
}
