import mailStore from '../store/Store';
import { getItemToShowFromNodeId } from '../utils/conversationsUtils';
import type Message from 'owa-service/lib/contract/Message';

/**
 * Returns item corresponding to the id passed for the expanded thread or itempart.
 * @param id node id of itempart for second level expansion, or thread id if first level expansion
 * @param isFirstLevelExpansion
 */
export default function getItemForMailList(id: string, isFirstLevelExpansion: boolean): Message {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (14,5): Type 'ClientItem | undefined' is not assignable to type 'Message'.
    // @ts-expect-error
    return isFirstLevelExpansion ? mailStore.items.get(id) : getItemToShowFromNodeId(id);
}
