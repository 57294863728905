import {
    onReceivedColumnWidthChangedInternal,
    onSubjectColumnWidthChangedInternal,
} from '../mutators/columnWidthMutators';
import type { ListViewColumnHeadersOptions } from 'owa-outlook-service-options';
import {
    getOptionsForFeature,
    OwsOptionsFeatureType,
    lazyCreateOrUpdateOptionsForFeature,
} from 'owa-outlook-service-options';

export function onSecondColumnHandleChanged(
    subjectColumnWidth: number,
    receivedColumnWidth: number
) {
    // Get existing settings on server
    const userOptions = getOptionsForFeature<ListViewColumnHeadersOptions>(
        OwsOptionsFeatureType.ListViewColumnHeaders
    );

    // Update settings on server
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(
        OwsOptionsFeatureType.ListViewColumnHeaders,
        {
            ...userOptions,
            subjectColumnWidth,
            receivedColumnWidth,
        } as ListViewColumnHeadersOptions
    );

    // Update store
    onSubjectColumnWidthChangedInternal(subjectColumnWidth);
    onReceivedColumnWidthChangedInternal(receivedColumnWidth);
}
