import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import tokenRequest from 'owa-service/lib/factory/tokenRequest';
import getAccessTokenforResourceOperation from 'owa-service/lib/operation/getAccessTokenforResourceOperation';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import type { TokenRequestParams } from '../schema/TokenRequestParams';
import { getUserTokenIfAvailable } from './getUserTokenIfAvailable';
import { isNonTransientError } from './isNonTransientError';
import sleep from 'owa-sleep';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';
import { getOriginWithTrailingSlash } from 'owa-url/lib/getOrigin';
import type { ILogger } from './ILogger';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { shouldFetchFromSTS } from './shouldFetchFromSTS';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import { isOriginUrl } from './isOriginUrl';
export async function fetchTokenFromServerWithRetry(
    logger: ILogger,
    retryCount: number,
    retryDelay: number,
    requestParams: TokenRequestParams
): Promise<TokenResponse> {
    if (retryCount < 1) {
        const error = new Error('Max retrying limit (1) reached');
        logger.addCustomError('FetchFromServer', error);
        return Promise.reject(error);
    }

    return fetchTokenFromServer(logger, requestParams).catch(error => {
        logger.addCustomData('HttpStatusCode', error?.httpStatus);
        logger.addCustomData('cV', error?.correlationVector);
        logger.addCustomError('ServerCorrelationId', error?.correlationId);

        // Retry only on non-network related errors
        if (isNonTransientError(error)) {
            return sleep(retryDelay).then(() => {
                return fetchTokenFromServerWithRetry(logger, retryCount - 1, retryDelay * 2, {
                    ...requestParams,
                });
            });
        }

        logger.addCustomError('FetchFromServer', error);
        return Promise.reject(error);
    });
}

export async function fetchTokenFromServer(
    logger: ILogger,
    params: TokenRequestParams
): Promise<TokenResponse> {
    const resource = params.resource ?? getOriginWithTrailingSlash();
    const userToken = await getUserTokenIfAvailable(params);

    if (userToken && isOriginUrl(resource)) {
        logger.addCheckpoint('UserTokenFetched');
        return { AccessToken: userToken };
    }

    const fetchSTSToken = shouldFetchFromSTS(resource);
    const setUserTokenPayload = !fetchSTSToken;

    if (fetchSTSToken) {
        logger.addCheckpoint('FetchingSTSToken');
    }

    const options: RequestOptions = {
        isUserActivity: false,
        returnFullResponseOnSuccess: true,
        mailboxInfo: params.mailboxInfo,
    };

    if (userToken) {
        options.headers = new Headers();
        options.headers.set('authorization', buildAuthorizationHeader(userToken));
    }

    const tokenRequestParams = tokenRequest({
        Resource: params.resource,
        TargetTenantId: params.targetTenantId,
        PreferIdpToken: params.preferIdpToken,
        SendClientCapabilityClaim: params.sendClientCapabilityClaim,
    });

    if (userToken && setUserTokenPayload) {
        tokenRequestParams.UserToken = userToken;
        logger.addCheckpoint('UserTokenAttached');
    }

    const response = await (getAccessTokenforResourceOperation(
        tokenRequestParams,
        getMailboxRequestOptions(params.mailboxInfo, options)
    ) as Promise<Response>);

    if (response && isSuccessStatusCode(response.status)) {
        logger.addCustomData('ServerTokenResult', response?.headers?.get('x-tokenresult') || '');
        logger.addCustomData('ServerRequestId', response?.headers?.get('request-id') || '');
    }

    // If .json is not present it will mean that one of the underlying layers for handling the request
    // dropped the returnFullResponseOnSuccess setting and return TokenResponse directly.
    const tokenResponse = (await response.json()) as TokenResponse;
    logger.addCustomData(
        'ServerTokenStatus',
        tokenResponse?.TokenResultCode?.toString() || (2).toString()
    );

    if (!!tokenResponse?.SubErrorCode) {
        logger.addCustomData('IdpSubErrorCode', tokenResponse?.SubErrorCode ?? '');
    }

    return tokenResponse;
}
