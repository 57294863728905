import { createLazyResolver } from 'owa-lazy-resolver';

export const lazySharedCalendarAdditionalInfoQueryWeb = createLazyResolver(
    'SHAREDCALENDARS_ADDITIONALINFO_QUERY_WEB',
    () =>
        import(
            /* webpackChunkName: "SharedCalendarAdditionalInfoQueryWeb"*/ './sharedCalendarAdditionalInfoQueryWeb'
        ),
    m => m.sharedCalendarAdditionalInfoQueryWeb
);
