import {
    addPendingAnimation,
    removeActiveAnimation,
    type MailListItemAnimation,
} from 'owa-mail-list-item-animation-store';

import { invokeAnimationCallback } from './invokeAnimationCallback';
import { getNextActionId } from './getNextActionId';

export function handleAnimationComplete(animation: MailListItemAnimation, rows: Element[]) {
    const { animationAction, animationRowIds, animationCallback } = animation;
    if (animationAction == 'PinInitial' || animationAction == 'UnpinInitial') {
        rows.forEach(row => {
            if (animationRowIds?.has(row.id)) {
                (row as HTMLDivElement).style.background = 'unset';
            }
        });
        addPendingAnimation(
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            Array.from(animationRowIds!),
            getNextActionId(animationAction),
            animationCallback
        );
    } else if (animationAction == 'PinMiddle' || animationAction == 'UnpinMiddle') {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        addPendingAnimation(Array.from(animationRowIds!), getNextActionId(animationAction));
    } else if (
        animationAction == 'PinFinal' ||
        animationAction == 'UnpinFinal' ||
        animationAction == 'AddRowFinal'
    ) {
        rows.forEach(row => {
            if (animationRowIds?.has(row.id)) {
                (row as HTMLDivElement).style.background = 'unset';
            }
        });
    } else if (animationAction == 'AddRowInitial') {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        addPendingAnimation(Array.from(animationRowIds!), getNextActionId(animationAction));
    } else {
        rows.forEach(row => {
            if (animationRowIds?.has(row.id)) {
                (row as HTMLDivElement).style.opacity = '0';
            }
        });
        invokeAnimationCallback(animation);
    }
    removeActiveAnimation(animation);
}
