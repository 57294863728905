import { AttachmentSelectionSource } from '../types/AttachmentSelectionSource';

function getWDOriginFromAttachmentSelectionSource(selectionSource: AttachmentSelectionSource) {
    let sourcePart: string = '';
    switch (selectionSource) {
        case AttachmentSelectionSource.MailList:
            sourcePart = 'MESSAGES';
            break;
        case AttachmentSelectionSource.ConversationAttachmentWell:
        case AttachmentSelectionSource.ReadingPane:
            sourcePart = 'ATT';
            break;
        case AttachmentSelectionSource.FileSuggestion:
        case AttachmentSelectionSource.LinkSuggestion:
            sourcePart = 'SEARCH';
            break;
        case AttachmentSelectionSource.PhotoHub:
        case AttachmentSelectionSource.GroupFilesHub:
        case AttachmentSelectionSource.Deeplink:
        case AttachmentSelectionSource.ConsumerGroupFilesHub:
        case AttachmentSelectionSource.ComposeDocLink:
        case AttachmentSelectionSource.ReadingPaneDocLink:
        case AttachmentSelectionSource.FilesHub:
        default:
            sourcePart = 'OTHER';
    }

    return `OWA.${sourcePart}.SXS`;
}

export { getWDOriginFromAttachmentSelectionSource };
