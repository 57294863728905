import type {
    MutationCreateCalendarGroupArgs,
    ResolverContext,
    CalendarGroup,
    CreateCalendarGroupMutation_CalendarGroup,
} from 'owa-graph-schema';
import { partialCast } from 'owa-graph-schema';

import type CalendarActionGroupIdResponse from 'owa-service/lib/contract/CalendarActionGroupIdResponse';
import createCalendarGroupOperation from 'owa-service/lib/operation/createCalendarGroupOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

/**
 * mutation web resolver responsible for createCalendarGroup
 */
export const createCalendarGroupMutationWeb = async (
    _parent: any,
    args: MutationCreateCalendarGroupArgs,
    _context: ResolverContext,
    _info: any
): Promise<CalendarGroup> => {
    const response: CalendarActionGroupIdResponse = await createCalendarGroupOperation(
        {
            newGroupName: args.input.newCalendarGroupName,
        },
        getMailboxRequestOptions(args.input.mailboxInfo)
    );

    if (response == null || !response.WasSuccessful) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `createCalendarGroupMutationWeb: failed to create calendar group. Error code: ${
                response ? response.ErrorCode : 'no response'
            }`
        );
    }

    const partialCalendarGroup: CreateCalendarGroupMutation_CalendarGroup = {
        __typename: 'CalendarGroup',
        calendarGroupId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            id: response!.NewGroupItemId!.Id,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            changeKey: response!.NewGroupItemId?.ChangeKey,
        },
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion.
         *	> Forbidden non-null assertion. */
        serverGroupId: response!.NewGroupClassId!,
    };

    return partialCast<CalendarGroup>(partialCalendarGroup);
};
