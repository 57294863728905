//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MailFolderRootFragmentDoc } from './MailFolderRoot.interface';
import { MailFolderListWrapperFragmentDoc } from './MailFolderListWrapper.interface';
import { MailFolderNodeTreeFragmentDoc } from './MailFolderNodeTree.interface';
import { MailFolderFromServiceFragmentDoc } from './MailFolderFromServiceFragment.interface';
export type OnFolderHierarchyResponseFragment = {
    __typename?: 'FolderHierarchyResult';
    IncludesLastItemInRange?: boolean | null;
    offset?: number | null;
    CustomSorted?: boolean | null;
    TotalItemsInView?: number | null;
    RootFolder?: {
        __typename?: 'MailFolder';
        id: string;
        childFolderIds?: Array<string> | null;
        type?: string | null;
        changeKey?: string | null;
        parentFolderId: string;
        displayName: string;
        distinguishedFolderType?: string | null;
        FolderClass?: string | null;
        UnreadCount: number;
        totalMessageCount: number;
        childFolderCount: number;
        messageSizeInBytes?: string | null;
        mailboxInfo: any;
        replicaList?: Array<string | null> | null;
        SearchFolderTemplateId?: number | null;
        sortParentId?: string | null;
        sortPosition?: string | null;
        remoteFolderInfo?: {
            __typename?: 'RemoteFolderInfo';
            remoteFolderDisplayName?: string | null;
            remoteDistinguishedFolderType?: Types.RemoteDistinguishedFolderType | null;
        } | null;
        ArchiveTag?: {
            __typename?: 'RetentionTagType';
            IsExplicit?: boolean | null;
            Value: string;
        } | null;
        PolicyTag?: {
            __typename?: 'RetentionTagType';
            IsExplicit?: boolean | null;
            Value: string;
        } | null;
        EffectiveRights?: {
            __typename?: 'EffectiveRightsType';
            CreateAssociated?: boolean | null;
            CreateContents?: boolean | null;
            CreateHierarchy?: boolean | null;
            Delete?: boolean | null;
            Modify?: boolean | null;
            Read?: boolean | null;
            ViewPrivateItems?: boolean | null;
        } | null;
    } | null;
    Folders?: Array<{
        __typename?: 'MailFolder';
        displayName: string;
        distinguishedFolderType?: string | null;
        childFolderIds?: Array<string> | null;
        mailboxInfo: any;
        type?: string | null;
        id: string;
        changeKey?: string | null;
        parentFolderId: string;
        FolderClass?: string | null;
        UnreadCount: number;
        totalMessageCount: number;
        childFolderCount: number;
        messageSizeInBytes?: string | null;
        replicaList?: Array<string | null> | null;
        SearchFolderTemplateId?: number | null;
        sortParentId?: string | null;
        sortPosition?: string | null;
        remoteFolderInfo?: {
            __typename?: 'RemoteFolderInfo';
            remoteFolderDisplayName?: string | null;
            remoteDistinguishedFolderType?: Types.RemoteDistinguishedFolderType | null;
        } | null;
        ArchiveTag?: {
            __typename?: 'RetentionTagType';
            IsExplicit?: boolean | null;
            Value: string;
        } | null;
        PolicyTag?: {
            __typename?: 'RetentionTagType';
            IsExplicit?: boolean | null;
            Value: string;
        } | null;
        EffectiveRights?: {
            __typename?: 'EffectiveRightsType';
            CreateAssociated?: boolean | null;
            CreateContents?: boolean | null;
            CreateHierarchy?: boolean | null;
            Delete?: boolean | null;
            Modify?: boolean | null;
            Read?: boolean | null;
            ViewPrivateItems?: boolean | null;
        } | null;
    } | null> | null;
};
export const OnFolderHierarchyResponseFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OnFolderHierarchyResponse' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FolderHierarchyResult' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RootFolder' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderRoot' },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderListWrapper' },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderFromService' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Folders' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderNodeTree' },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MailFolderFromService' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'IncludesLastItemInRange' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'CustomSorted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'TotalItemsInView' } },
                ],
            },
        },
        ...MailFolderRootFragmentDoc.definitions,
        ...MailFolderListWrapperFragmentDoc.definitions,
        ...MailFolderFromServiceFragmentDoc.definitions,
        ...MailFolderNodeTreeFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<OnFolderHierarchyResponseFragment, unknown>;
