export const storeInitialAndFinalRowPositions = (
    rows: Element[],
    animationRowIds: Set<string>,
    prevChildrenPositions: Map<string, DOMRect>,
    newChildrenPositions: Map<string, DOMRect>,
    rowItemParts: Map<string, Element[]>,
    isRemovalAction: boolean
) => {
    let netHeightChange = 0;

    // Store the old and new positions of the elements
    rows.forEach(row => {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
        const { right, bottom, left, width, height, x } = row.getBoundingClientRect();
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
        prevChildrenPositions.set(row.id, row.getBoundingClientRect());

        // When a row is set to display none (as we do for a row being animated into the list view), the DOMRect's y and top value will always be 0 for that row.
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
        const newYCoordinate = row.getBoundingClientRect().y + netHeightChange;
        newChildrenPositions.set(row.id, {
            top: newYCoordinate,
            right,
            bottom,
            left,
            width,
            height,
            x,
            y: newYCoordinate,
        } as DOMRect);

        if (animationRowIds.has(row.id)) {
            if (isRemovalAction) {
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please do not copy and paste this justification
                 *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                netHeightChange -= row.getBoundingClientRect().height;
            } else {
                // When we perform a row-insertion animation into the list view, we set the row's display property to 'none'. When a row's display is set to 'none', its height
                // is 0. Since we need the height for this row to calculate the changeY for the other rows in the list view, we temporarily set the display to 'block', get the height
                // from the DOMRect, and then unset it.
                (row as HTMLDivElement).style.display = 'block';
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please do not copy and paste this justification
                 *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                netHeightChange += row.getBoundingClientRect().height;
                (row as HTMLDivElement).style.display = 'unset';
            }
        }

        // Store the old and new positions of item parts that are displayed in the list view as well.
        const itemPartWrapper = document.getElementById(`${row.id}_itemPartWrapper`);
        const itemParts = Array.from(itemPartWrapper?.children ?? []);

        if (itemParts.length > 0) {
            // If the conversation header row has shifted, apply the same net changeY to the expansion component wrapper so all the item parts are shifted along with the conversation header.
            if (netHeightChange !== 0) {
                // There is an additional wrapper around the expansion when the ML expansion animation flight is disabled so we need to grab the correct id to query.
                const expansionContainerId = `${row.id}_expansionAnimationWrapper`;
                const expansionContainer = document.getElementById(expansionContainerId);
                if (expansionContainer) {
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                    const expansionContainerDomRect = expansionContainer.getBoundingClientRect();
                    prevChildrenPositions.set(expansionContainerId, expansionContainerDomRect);

                    const newYCoordinateItemPart = expansionContainerDomRect.y + netHeightChange;
                    newChildrenPositions.set(expansionContainerId, {
                        ...expansionContainerDomRect,
                        top: newYCoordinateItemPart,
                        y: newYCoordinateItemPart,
                    } as DOMRect);

                    // If the conversation header row is one of the rows being animated out, we want to also add the expansion wrapper's height to the netHeightChange as the expansion will
                    // also be removed when the conversation header is removed. That way, the subsequent rows can be shifted up appropriately. This does not apply to the row insertion case as
                    // the conversation being inserted into the ML will not be expanded at insertion time.
                    if (animationRowIds.has(row.id) && isRemovalAction) {
                        netHeightChange -= expansionContainerDomRect.height;
                    }

                    rowItemParts.set(row.id, [expansionContainer]);
                }
            } else {
                // If one or more item parts are being removed/added, we need to animate the item parts in the expansion individually
                let itemPartNetHeightChange = 0;
                itemParts.forEach(itemPartRow => {
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                    const itemPartDomRect = itemPartRow.getBoundingClientRect();
                    prevChildrenPositions.set(itemPartRow.id, itemPartDomRect);

                    const newYCoordinateItemPart = itemPartDomRect.y + itemPartNetHeightChange;
                    newChildrenPositions.set(itemPartRow.id, {
                        ...itemPartDomRect,
                        top: newYCoordinateItemPart,
                        y: newYCoordinateItemPart,
                    } as DOMRect);

                    if (animationRowIds.has(itemPartRow.id)) {
                        const itemPartHeight = itemPartDomRect.height;
                        if (isRemovalAction) {
                            itemPartNetHeightChange -= itemPartHeight;
                            netHeightChange -= itemPartHeight;
                            // If we are removing the only item part in a conversation expansion, we should remove the conversation header too so we need to shift up the rows below it by the conversation header's height as well.
                            if (itemParts.length === 1) {
                                netHeightChange -= height;
                            }
                        } else {
                            // When we perform a row-insertion animation into the list view, we set the row's display property to 'none'. When a row's display is set to 'none', its height
                            // is 0. Since we need the height for this row to calculate the changeY for the other rows in the list view, we temporarily set the display to 'block', get the height
                            // from the DOMRect, and then unset it.
                            (itemPartRow as HTMLDivElement).style.display = 'block';
                            itemPartNetHeightChange += itemPartHeight;
                            netHeightChange += itemPartHeight;
                            (itemPartRow as HTMLDivElement).style.display = 'unset';
                        }
                    }
                });
                rowItemParts.set(row.id, itemParts);
            }
        }
    });
};
