import { emptyFocused, emptyFolder, emptyOther } from './getEmptyFolderText.locstring.json';

import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { type TableQueryType } from '../store/schema/TableQuery';
import type TableView from '../store/schema/TableView';
import { owaComputedFn } from 'owa-computed-fn';
import { emptyFolderText } from 'owa-locstrings/lib/strings/emptyfoldertext.locstring.json';
import getFocusedFilterForTable from './getFocusedFilterForTable';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import getSelectedTableView from './getSelectedTableView';
import loc from 'owa-localize';

export function getEmptyFolderText(tableView: TableView): string {
    const focusedFilter = getFocusedFilterForTable(tableView);
    const selectedNode = getSelectedNode();

    if (focusedFilter !== -1) {
        return focusedFilter === 0 ? loc(emptyFocused) : loc(emptyOther);
        // We are in search/persona/category scenario
    } else if (
        tableView.tableQuery.type === 1 ||
        selectedNode.type === 4 ||
        selectedNode.type === 1
    ) {
        // 'Delete all' (indicates that only emails in view will be deleted)
        return loc(emptyFolder);
    } else {
        // 'Empty folder' (indicates entire folders' contents will be deleted)
        return loc(emptyFolderText);
    }
}

export const getComputedEmptyFolderText = owaComputedFn(
    function getComputedEmptyFolderText(): string {
        const tableView: TableView = getSelectedTableView();
        return getEmptyFolderText(tableView);
    }
);
