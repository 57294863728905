import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import { lazyGovern, GovernPriority } from 'owa-tti';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TriageAction"*/ './lazyIndex')
);

export const lazyAddEmailAddressesToSafeSenders = new LazyAction(
    lazyModule,
    m => m.addEmailAddressesToSafeSenders
);

export const lazyAlwaysMoveRowToFocusedOrOther = new LazyAction(
    lazyModule,
    m => m.alwaysMoveRowToFocusedOrOther
);

export const lazyMoveMailListRowsToFocusedOrOther = new LazyAction(
    lazyModule,
    m => m.moveMailListRowsToFocusedOrOther
);

export const lazyDeleteMailListRows = new LazyAction(lazyModule, m => m.deleteMailListRows);

export const lazyDeleteItems = new LazyAction(lazyModule, m => m.deleteItems);
export const lazyDeleteConversationsFromConversationActionContexts = new LazyAction(
    lazyModule,
    m => m.deleteConversationsFromConversationActionContexts
);

export const lazyDeleteItemsWithoutConfirm = new LazyAction(
    lazyModule,
    m => m.deleteItemsWithoutConfirmDialog
);

export const lazyFlagItems = new LazyAction(lazyModule, m => m.flagItems);

export const lazyDeleteItemsBasedOnNodeOrThreadIds = new LazyImport(
    lazyModule,
    m => m.deleteItemsBasedOnNodeOrThreadIds
);

export const lazyRestoreAllItems = new LazyAction(lazyModule, m => m.restoreAllItems);

export const lazyToggleRowsFlagState = new LazyAction(lazyModule, m => m.toggleRowsFlagState);

export const lazySetMailListRowsFlagState = new LazyAction(
    lazyModule,
    m => m.setMailListRowsFlagState
);

export const lazySetItemsFlagStateFromItemIds = new LazyAction(
    lazyModule,
    m => m.setItemsFlagStateFromItemIds
);

export const lazyMoveMultipleItemsFromItemIds = new LazyAction(
    lazyModule,
    m => m.moveMultipleItemsFromItemIds
);
export const lazyMoveConversationsFromConversationIds = new LazyAction(
    lazyModule,
    m => m.moveConversationsFromConversationIds
);
export const lazyMoveItemsBasedOnNodeIds = new LazyImport(
    lazyModule,
    m => m.moveItemsBasedOnNodeIds
);
export const lazyMoveItems = new LazyImport(lazyModule, m => m.moveItems);
export const lazyMoveMailListRows = new LazyAction(lazyModule, m => m.moveMailListRows);
export const lazyCopyMailListRows = new LazyAction(lazyModule, m => m.copyMailListRows);
export const lazyCopyMailListItems = new LazyAction(lazyModule, m => m.copyMailListItems);
export const lazyCopyGroupMailListRows = new LazyAction(lazyModule, m => m.copyGroupMailListRows);
export const lazyArchiveMailListRows = new LazyAction(
    lazyModule,
    m => m.archiveMailListRowsFromTable
);
export const lazyArchiveMailSingleItem = new LazyAction(lazyModule, m => m.archiveMailSingleItem);
export const lazyScheduleRows = new LazyImport(lazyModule, m => m.scheduleRows);
export const lazyScheduleItems = new LazyImport(lazyModule, m => m.scheduleItems);
export const lazyToggleRowPinnedState = new LazyAction(lazyModule, m => m.toggleRowPinnedState);
export const lazyMarkRowsPinnedUnpinned = new LazyAction(lazyModule, m => m.markRowsPinnedUnpinned);
export const lazyPinUnpinItems = new LazyAction(lazyModule, m => m.pinUnpinItems);
export const lazyMarkRowsJunkNotJunk = new LazyAction(
    lazyModule,
    m => m.markRowsJunkNotJunkFromTable
);
export const lazyMarkItemsJunkNotJunkBasedOnNodeIds = new LazyImport(
    lazyModule,
    m => m.markItemsJunkNotJunkBasedOnNodeIds
);
export const lazyMarkItemJunkNotJunkFromReadingPane = new LazyAction(
    lazyModule,
    m => m.markItemJunkNotJunkFromReadingPane
);

export const lazyOnKeyboardMarkAsJunkNotJunk = new LazyAction(
    lazyModule,
    m => m.onKeyboardMarkAsJunkNotJunk
);

export const lazyOnKeyboardToggleFlagState = new LazyAction(
    lazyModule,
    m => m.onKeyboardToggleFlagState
);

export const lazyMarkRowsAsPhishingFromTable = new LazyAction(
    lazyModule,
    m => m.markRowsAsPhishingFromTable
);

export const lazyMarkItemAsPhishingFromReadingPane = new LazyAction(
    lazyModule,
    m => m.markItemAsPhishingFromReadingPane
);

export const lazyReportItems = new LazyAction(lazyModule, m => m.reportItems);

export const lazyRestoreMailListRowsAction = new LazyAction(lazyModule, m => m.restoreMailListRows);
export const lazyUnsubscribeEmail = new LazyAction(lazyModule, m => m.unsubscribeEmail);
export const lazyUnsubscribeEmailV2 = new LazyImport(lazyModule, m => m.unsubscribeEmailV2);
export const lazyBlockLastSendersFromTable = new LazyAction(
    lazyModule,
    m => m.blockLastSendersFromTable
);

export const lazyBlockLastSenderFromReadingPane = new LazyAction(
    lazyModule,
    m => m.blockLastSenderFromReadingPane
);

export const lazyAddSafeSenderFromReadingPane = new LazyAction(
    lazyModule,
    m => m.addSafeSenderFromReadingPane
);

export const lazyAddSafeSenderFromTable = new LazyAction(lazyModule, m => m.addSafeSenderFromTable);

export const lazyAddCategoriesFromTable = new LazyAction(lazyModule, m => m.addCategoriesFromTable);
export const lazyRemoveCategoriesFromTable = new LazyAction(
    lazyModule,
    m => m.removeCategoriesFromTable
);
export const lazyClearCategoriesFromTable = new LazyAction(
    lazyModule,
    m => m.clearCategoriesFromTable
);

export const lazyAddCategoriesFromItem = new LazyAction(lazyModule, m => m.addCategoriesFromItem);
export const lazyRemoveCategoriesFromItem = new LazyAction(
    lazyModule,
    m => m.removeCategoriesFromItem
);
export const lazyClearCategoriesFromItem = new LazyAction(
    lazyModule,
    m => m.clearCategoriesFromItem
);
export const lazyAddCategoriesFromItems = new LazyAction(lazyModule, m => m.addCategoriesFromItems);
export const lazyClearCategoriesFromItems = new LazyAction(
    lazyModule,
    m => m.clearCategoriesFromItems
);
export const lazyAddCategoriesFromConversationIds = new LazyAction(
    lazyModule,
    m => m.addCategoriesFromConversationIds
);
export const lazyClearCategoriesFromConversationIds = new LazyAction(
    lazyModule,
    m => m.clearCategoriesFromConversationIds
);

export const lazySweepConversations = new LazyAction(lazyModule, m => m.sweepConversations);
export const lazyEmptyTableView = new LazyAction(lazyModule, m => m.emptyTableView);
export const lazyToggleIgnoreConversations = new LazyImport(
    lazyModule,
    m => m.toggleIgnoreConversations
);
export const lazyIgnoreConversationItems = new LazyAction(
    lazyModule,
    m => m.ignoreConversationItems
);
export const lazyMoveSharedFolderItems = new LazyImport(lazyModule, m => m.moveSharedFolderItems);
export const lazyMoveSharedFolderConversations = new LazyImport(
    lazyModule,
    m => m.moveSharedFolderConversations
);
export const lazyMessageRecall = new LazyAction(lazyModule, m => m.messageRecall);

export const lazyDismissNudge = new LazyImport(lazyModule, m => m.dismissNudge);
export const lazyOnRemoveMeeting = new LazyImport(lazyModule, m => m.onRemoveMeeting);
export const lazyTranslateFromTable = new LazyAction(lazyModule, m => m.translateFromTable);
export const lazyLogTriageData = new LazyAction(lazyModule, m => m.logTriageData);
export const lazyShowNotificationOnSyncMailbox = new LazyAction(
    lazyModule,
    m => m.showNotificationOnSyncMailbox
);
export const lazyTryDismissSyncMailboxNotification = new LazyAction(
    lazyModule,
    m => m.tryDismissSyncMailboxNotification
);

export const lazyMoveSingleItemFromItemId = new LazyAction(
    lazyModule,
    m => m.moveSingleItemFromItemId
);

export const lazyBlockSenderService = new LazyAction(lazyModule, m => m.blockSenderService);

lazyGovern.importAndExecute({
    task: () => lazyModule.importModule('prefetchTriageActions'),
    priority: GovernPriority.Messages,
});
